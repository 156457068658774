import { useEffect } from 'react';

import { useObject } from '../lib/hooks';
import useDarkMode from './useDarkMode';

export type Theme = 'light' | 'dark';

export const useTheme = (): { theme: Theme; toggleTheme: () => void } => {
  const { isDarkMode, toggle: toggleTheme } = useDarkMode();

  useEffect(() => {
    const themeVal = isDarkMode ? 'dark' : 'light';
    if (document.documentElement.classList.contains(themeVal)) return;
    document.documentElement.classList.remove('light', 'dark');
    document.documentElement.classList.add(themeVal);
  }, [isDarkMode]);

  // NOTE: Use option-t to toggle theme
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'KeyT' && event.altKey) {
        toggleTheme();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [toggleTheme]);

  return useObject({ theme: isDarkMode ? 'dark' : 'light', toggleTheme });
};
