import React from 'react';
import Text from '../Text';

function CryptoPayment() {
  return (
    <div
      className="my-2"
      style={{
        lineBreak: 'anywhere',
      }}
    >
      <div className="text-2xl mb-6">Pay with crypto</div>
      <div className="flex">
        <Text variant="muted" className="w-12 flex-shrink-0">
          ETH
        </Text>
        <div>0x36Be0924F90eD967a4057a292E7662cE5B31C0b6</div>
      </div>

      <div className="flex">
        <Text variant="muted" className="w-12 flex-shrink-0">
          ENS
        </Text>
        <div>tokentax.eth</div>
      </div>

      <div className="flex">
        <Text variant="muted" className="w-12 flex-shrink-0">
          BTC
        </Text>
        <div>38QWR7ejahHamRV8b5BW9uPZtdzLu97pdo</div>
      </div>
    </div>
  );
}

export default React.memo(CryptoPayment);
