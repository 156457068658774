import React, { useContext } from 'react';
import { UserContext } from '../../../contexts';

import { RecalcContext } from '../../../contexts';
import { LoadingToast, ErrorToast, SuccessToast } from '../../Toast';
import { formatDuration } from '../../../lib/formatters';
import TextLink from '../../TextLink';

export function NoTaxYearsToast({ onClick }: { onClick: () => void }) {
  return (
    <ErrorToast canHideSelf>
      Nothing to recalculate.
      <TextLink variant="secondary" className="ml-2 font-semibold" onClick={onClick}>
        Recalculate anyway
      </TextLink>
    </ErrorToast>
  );
}

function RecalculateToast() {
  const { isTokenTaxAdmin = false } = useContext(UserContext);
  const { isRecalculating, latestJobState, currentJobDurationObject } = useContext(RecalcContext);
  const displayDuration =
    isTokenTaxAdmin &&
    currentJobDurationObject &&
    Object.values(currentJobDurationObject).some((length) => length > 0);

  if (isRecalculating) {
    return (
      <LoadingToast>
        Recalculating your data.{' '}
        {displayDuration && <>The job has been running for {formatDuration(currentJobDurationObject)}.</>}
      </LoadingToast>
    );
  }

  if (latestJobState === 'failed') {
    return (
      <ErrorToast canHideSelf>
        There was an error calculating your data. Please contact customer support.
      </ErrorToast>
    );
  }

  if (latestJobState === 'completed') {
    return <SuccessToast canHideSelf>Successfully recalculated your data.</SuccessToast>;
  }

  return null;
}

export default React.memo(RecalculateToast);
