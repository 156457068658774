import React, { Component, ErrorInfo, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import Blankslate from '../../components-v2/Blankslate';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo); // eslint-disable-line no-console
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback || <ErrorSlate message={this.state.error?.message} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

export const ErrorSlate = ({ message = 'Something went wrong', buttonText = 'Go back', linkTo = '/' }) => {
  const history = useHistory();
  const handleOnClick = () => {
    history.push(linkTo);
    // Force reload to help clear any issues
    window.location.reload();
  };

  return (
    <div className="w-full">
      <Blankslate title={message} cta={buttonText} onClick={handleOnClick} card />
    </div>
  );
};
