import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  borders,
  boxShadow,
  color,
  display,
  minHeight,
  height,
  width,
  maxWidth,
  space,
  position,
  top,
  flex,
  alignSelf,
  justifyContent,
  alignItems,
} from 'styled-system';

/**
  Token Tax Card component
 */
const Card = styled.div`
  background-color: white;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => (props.large ? '2rem' : '1rem')};
  box-shadow: ${(props) => (props.hideShadow ? 'none' : props.theme.shadow)};
  height: ${(props) => (props.stretch ? '100%' : 'inherit')};
  ${alignSelf};
  ${color};
  ${minHeight};
  ${height};
  ${space};
  ${display};
  ${justifyContent};
  ${alignItems};
  ${borders};
  ${boxShadow};
  ${position};
  ${top};
  ${flex};
  ${width};
  ${maxWidth};
`;

Card.propTypes = {
  /** Whether card should be displayed with extra padding 1rem vs 2rem */
  large: PropTypes.bool,
  /** Whether card should stretch to fill height of surrounding div (height: 100%) */
  stretch: PropTypes.bool,
  /** Whether card is being used with tabs above it and the left most tab is selected  */
  tabLeftActive: PropTypes.bool,
};

Card.defaultProps = {
  large: false,
  stretch: false,
  tabLeftActive: false,
};

Card.displayName = 'Card';

export default Card;
