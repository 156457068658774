import { partition } from 'lodash';
import { CATEGORY_DATA } from '../../Wizard/constants';
import { Side } from '../../Wizard/types';
import { buildDraftFromTransfers } from '../buildDraftFromTransfers';
import { ComplexTreatmentResult, Props } from './types';

export const swapWithIncome = (props: Props): ComplexTreatmentResult => {
  const { category, txn, lpTokenTickersByAddress, sentTxns, receivedTxns } = props;
  const { tokenParam1, txns } = txn;

  if (!tokenParam1 || !tokenParam1.length) return { drafts: null };

  const [matchingDeposits, otherDeposits] = partition(
    receivedTxns,
    ({ txnType, buyCurrency }) =>
      txnType === 'deposit' && tokenParam1.map((t) => t.toLowerCase()).includes(buyCurrency.toLowerCase()),
  );
  if (matchingDeposits.length !== 1) return { drafts: null };

  const matchingDeposit = matchingDeposits[0]!;

  const incomeDraft = {
    received: {
      grouped: false as const,
      txns: [
        {
          id: matchingDeposit.id,
          type: CATEGORY_DATA.income.receivedType!,
          currency: lpTokenTickersByAddress[matchingDeposit.buyAddress!] ?? matchingDeposit.buyCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
          tokenId: matchingDeposit.buyTokenId,
          quantity: matchingDeposit.buyQuantity,
          price: matchingDeposit.buyPrice,
        },
      ],
    },
  };

  const { sent, received } = Side;

  const txnsCanBeMerged =
    (otherDeposits.length === 1 && sentTxns.length >= 1) ||
    (otherDeposits.length > 1 && sentTxns.length === 1);

  const txnsMap = { sent: sentTxns, received: receivedTxns };

  const drafts = [
    incomeDraft,
    ...(txnsCanBeMerged
      ? buildDraftFromTransfers({ category, lpTokenTickersByAddress, txns })
      : [sent, received].flatMap((side) =>
          txnsMap[side].flatMap((txn) =>
            // this will break out each txn ("transfer") into its own draft
            buildDraftFromTransfers({ category, lpTokenTickersByAddress, txns: [txn] }),
          ),
        )),
  ];

  return { drafts };
};
