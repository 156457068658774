import { chain, difference, isEmpty, pick, uniq } from 'lodash';
import {
  BooleanFilter,
  MultiSelectIntFilter,
  SpecIdMatchSide,
  TxnCurrencyFilter,
  TxnFilterQuery,
  TxnSearchFilter,
  TxnSortOptions,
} from '../../graphql/types';
import { getFilteredTableActions, setPaginationState, setSortingState } from '../FilteredTable';
import { defaultSortState, getDefaultHiddenColumns, pageSize } from './defaults';
import { filtersQueryParamsConf } from './queryParams';
import type { FiltersState, IdType } from './types';
import { AllTransactionsQueryParams } from './types';
import { vars } from './vars';

const sortOptionsArray = Object.keys(TxnSortOptions) as TxnSortOptions[];

export const hasAnyValueSet = (filterSet: Record<string, unknown>) =>
  chain(filterSet).values().some(Boolean).value();

const { filtersVar, txnIdsLinkedToSpecIdVar, paginationVar } = vars;
const filteredTableActions = getFilteredTableActions<TxnSortOptions, FiltersState, IdType>({
  defaultSortState,
  vars,
  pageSize,
  defaultHiddenColumns: getDefaultHiddenColumns(),
});

const setFilter = (filter: FiltersState) => {
  const newFilterState = { ...filtersVar(), ...filter };
  const { filterQuery, ...restFilters } = newFilterState;
  const hasAnyFilterApplied =
    hasAnyValueSet(JSON.parse(filterQuery ?? '{}')) || hasAnyValueSet(restFilters as Record<string, unknown>);
  if (!hasAnyFilterApplied && vars.multiSelectVar().selectionSpan === 'FilterSet') {
    // we don't allow selecting all transactions across pages when no filters are applied
    filteredTableActions.selectMany([], null);
  }
  paginationVar({ page: 1, pageSize }); // reset pagination options back to page 1 when the filters change
  filtersVar(newFilterState);
};

export const getFilterQuery = () => {
  const filtersState = filtersVar();
  return JSON.parse(filtersState.filterQuery || '{}') as TxnFilterQuery;
};

export const actions = {
  ...filteredTableActions,
  setSpecIdTxn: (sellId: string, inNewTab = false) => {
    filteredTableActions.selectMany([], null);
    const filter = { specId: { sellId } };
    if (inNewTab) {
      window.open(`/all-transactions?filterQuery=${JSON.stringify(filter)}`, '_blank');
      return;
    }
    actions.setTxnFilterQuery(filter);
  },
  clearSpecIdTxn: () => {
    filteredTableActions.selectMany([], null);
    actions.setTxnFilterQuery(undefined);
  },
  setStateFromQuery: (query: AllTransactionsQueryParams) => {
    setPaginationState<TxnSortOptions, FiltersState, IdType>({ query, vars, pageSize });
    setSortingState<TxnSortOptions, FiltersState, IdType>({ query, vars, sortOptionsArray });
    vars.filtersVar(pick(query, Object.keys(filtersQueryParamsConf)) as FiltersState);
  },
  setTxnFilterQuery: (filterQuery?: TxnFilterQuery) => {
    setFilter({ filterQuery: filterQuery ? JSON.stringify(filterQuery) : undefined });
  },
  setFilters: (filters: Partial<TxnFilterQuery>) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      ...filters,
    };
    actions.setTxnFilterQuery(newFilters);
  },
  addCurrencyFilter: (filter: TxnCurrencyFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      currency: filterQuery.currency ? [...filterQuery.currency, filter] : [filter],
    };
    actions.setTxnFilterQuery(newFilters);
  },
  editCurrencyFilter: (index: number, filter: TxnCurrencyFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      currency: filterQuery.currency
        ? [...filterQuery.currency.slice(0, index), filter, ...filterQuery.currency.slice(index + 1)]
        : [filter],
    };
    actions.setTxnFilterQuery(newFilters);
  },
  addSearchFilter: (filter: TxnSearchFilter) => {
    const filterQuery = getFilterQuery();
    const { key } = filter;
    const value = filterQuery[key];
    const newFilters = {
      ...filterQuery,
      [key]: value ? [...value, filter] : [filter],
    };
    actions.setTxnFilterQuery(newFilters);
  },
  editSearchFilter: (index: number, filter: TxnSearchFilter) => {
    const { key } = filter;
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      [key]: filterQuery[key]
        ? [
            ...(filterQuery[key] as TxnSearchFilter[]).slice(0, index),
            filter,
            ...(filterQuery[key] as TxnSearchFilter[]).slice(index + 1),
          ]
        : [filter],
    };
    actions.setTxnFilterQuery(newFilters);
  },
  addBooleanFilter: (filter: BooleanFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      boolean: filterQuery.boolean ? [...filterQuery.boolean, filter] : [filter],
    };
    actions.setTxnFilterQuery(newFilters);
  },
  editBooleanFilter: (index: number, filter: BooleanFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      boolean: filterQuery.boolean
        ? [...filterQuery.boolean.slice(0, index), filter, ...filterQuery.boolean.slice(index + 1)]
        : [filter],
    };
    actions.setTxnFilterQuery(newFilters);
  },
  removeFilter: (key: keyof TxnFilterQuery, index?: number) => {
    const filterQuery = getFilterQuery();
    const value = filterQuery[key];

    if (index !== undefined && Array.isArray(value)) {
      const newValue = [...value].filter((_, i) => i !== index);
      const newFilters = {
        ...filterQuery,
        [key]: newValue,
      };
      if (newValue.length === 0) {
        delete newFilters[key];
      }
      actions.setTxnFilterQuery(isEmpty(newFilters) ? undefined : newFilters);
      return;
    }

    const newFilters = { ...filterQuery };
    delete newFilters[key];
    actions.setTxnFilterQuery(isEmpty(newFilters) ? undefined : newFilters);
  },
  setVendorsFilter: (filter: MultiSelectIntFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      vendors: filter,
    };
    actions.setTxnFilterQuery(newFilters);
  },
  setAccountCreditsFilter: (filter: MultiSelectIntFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      accountCredits: filter,
    };
    actions.setTxnFilterQuery(newFilters);
  },
  setAccountDebitsFilter: (filter: MultiSelectIntFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      accountDebits: filter,
    };
    actions.setTxnFilterQuery(newFilters);
  },
  setSpecIdSide: (side: SpecIdMatchSide) => {
    const filterQuery = getFilterQuery();
    const { specId } = filterQuery;
    const newFilters = {
      ...filterQuery,
      specId: {
        ...specId,
        side,
      },
    };
    actions.setTxnFilterQuery(newFilters);
  },
  unsetSpecIdSide: () => {
    const filterQuery = getFilterQuery();
    const { specId } = filterQuery;
    const newFilters = {
      ...filterQuery,
      specId: {
        ...specId,
        side: undefined,
      },
    };
    actions.setTxnFilterQuery(newFilters);
  },
  markAsLinkedSpecId: (txnIds: IdType[]) => {
    txnIdsLinkedToSpecIdVar(uniq([...txnIdsLinkedToSpecIdVar(), ...txnIds]));
  },
  unmarkAsLinkedSpecId: (txnIds: IdType[]) => {
    txnIdsLinkedToSpecIdVar(difference(txnIdsLinkedToSpecIdVar(), txnIds));
  },
  resetState: () => {
    filteredTableActions.resetState();
    txnIdsLinkedToSpecIdVar([]);
  },
};

export type AllTransactionsActions = typeof actions;
