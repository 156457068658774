import React from 'react';
import { flatten } from 'lodash';

import { useTableContext } from '../context';

function Footer<T extends Record<string, unknown>>() {
  const { gridStyle, instance, windowedScrollBarPadding = 0 } = useTableContext<T>();

  if (instance === undefined) {
    return null;
  }

  const { footerGroups } = instance;

  // we don't use/support headerGroups as shown in https://react-table.tanstack.com/docs/examples/grouping-column
  // no need to complicate the markup for now
  const footerCells = flatten(footerGroups.map(({ headers }) => headers));
  const paddingRight = `calc(${gridStyle?.paddingRight ?? 0} + ${windowedScrollBarPadding ?? 0}px)`;

  return (
    <div
      className="py-3 font-bold border-t"
      style={{
        ...gridStyle,
        paddingRight,
      }}
    >
      {footerCells.map((column) => {
        return <div key={column.id}>{column.render('Footer')}</div>;
      })}
    </div>
  );
}

export default React.memo(Footer);
