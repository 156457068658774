import { useReactiveVar } from '@apollo/client';
import { startCase } from 'lodash';
import { useContext } from 'react';
import { IntegrationsContextType } from '../../contexts/IntegrationsContext';
import { AllCredentialsQuery, BkpAccountsVendorsQuery, TxnCountQuery } from '../../graphql/types';
import { getCredentialDescription, getCredentialName } from '../Import/formatters';
import { getTotalPages } from '../utils/pagination';
import { AllTransactionsContext } from './Provider/context';
import { pageSize } from './defaults';
import { vars } from './vars';

type ImportTypeCounts = TxnCountQuery['userTxnCount']['txnImportTypeCounts'][0];
type TxnTypeCounts = TxnCountQuery['userTxnCount']['txnTypeCounts'][0];
type Credential = AllCredentialsQuery['credentials']['edges'][0];

const getImportTypeValue = (importType: string) => {
  let value = importType;
  if (importType === 'Manual Import') {
    value = 'form';
  }
  if (importType === 'Other') {
    value = '';
  }
  return value.toLowerCase();
};

export const getReviewedFilterOptions = (
  totalCount: number | undefined,
  reviewedCount: number | undefined,
) => {
  return [
    {
      value: 'reviewed',
      label: 'Reviewed',
      count: reviewedCount || 0,
    },
    {
      value: 'notReviewed',
      label: 'Unreviewed',
      count: (totalCount || 0) - (reviewedCount || 0),
    },
  ];
};

export const getImportTypeFilterOptions = (counts?: ImportTypeCounts[]) =>
  counts
    ? counts.map(({ importType, count }) => ({
        value: getImportTypeValue(importType),
        label: importType,
        count,
      }))
    : [];

export const getTxnTypeFilterOptions = (counts?: TxnTypeCounts[]) =>
  counts
    ? counts.map(({ type: txnType, count }) => ({
        value: txnType,
        label: startCase(txnType),
        count,
      }))
    : [];

export const getCredentialsOptions = (
  credentials: Credential[],
  integrationsContext: IntegrationsContextType,
) => {
  return credentials?.map((credential) => ({
    id: credential.id,
    label: getCredentialName(credential, integrationsContext),
    subLabel: getCredentialDescription(credential) || credential.address,
    metadata: credential.address,
  }));
};

export const getVendorsOptions = (vendors: BkpAccountsVendorsQuery['bkpVendors']) => {
  return vendors?.map((vendor) => ({
    id: vendor.id,
    label: vendor.bkpIntegrationDisplayName || vendor.name,
    subLabel: null,
    metadata: null,
  }));
};

export const getAccountOptions = (accounts: BkpAccountsVendorsQuery['bkpAccounts']) => {
  return accounts?.map((acct) => ({
    id: acct.id,
    label: acct.name,
    subLabel: null,
    metadata: null,
  }));
};

export const getPageCounts = (
  txnTotalCount: number | undefined,
  filteredCount: number | undefined,
  lastFilteredCount: number | undefined,
  loading: boolean,
) => {
  let visibleCount = filteredCount == null ? txnTotalCount : filteredCount;

  // if we are loading and we have a lastFilteredCount then use that
  if (loading && lastFilteredCount) {
    visibleCount = lastFilteredCount;
  }

  const totalPages = getTotalPages({ totalCount: visibleCount || 0, pageSize });

  return { totalPages, visibleCount };
};

export const useIsFilterQueryEmpty = () => {
  const { filtersVar, paginationVar } = vars;
  const filtersState = useReactiveVar(filtersVar);
  const { counts, tableData } = useContext(AllTransactionsContext);
  const { page } = useReactiveVar(paginationVar);
  const hasFiltersOrIsPaginated = !!(filtersState.filterQuery || page > 1);
  const dataIsEmpty = tableData?.edges.length === 0;
  const hasTxns = counts?.totalCount && counts.totalCount > 0;
  return Boolean(hasFiltersOrIsPaginated && dataIsEmpty && hasTxns);
};
