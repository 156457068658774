import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { MovementMatchConstraintsQuery } from '../types';

const MOVEMENT_MATCH_CONSTRAINTS = gql`
  query MovementMatchConstraints {
    movementMatchConstraints {
      quantityLeewayPercentage
      timestampLeewayDays
    }
  }
`;

export const useMovementMatchConstraints = (
  options: QueryHookOptions<MovementMatchConstraintsQuery> = {},
) => {
  return useQuery(MOVEMENT_MATCH_CONSTRAINTS, {
    ...options,
  });
};
