import { gql, useQuery } from '@apollo/client';
import { LatestTxnReportsQuery } from '../types';

export const LATEST_TXN_REPORTS = gql`
  query LatestTxnReports {
    latestTxnReports {
      latestTxnReportsByYear {
        year
        taxDetail {
          id
          plan
          fullFilingPlan
          baseCurrency
          country {
            id
            shorthand
            name
            taxMethods {
              id
              label
            }
          }
          dateRange {
            startDate
            endDate
          }
          filingStatus
          state
          method
          taxMethod {
            id
            label
          }
          marginalTaxRate
          longTermCapitalGainsRate
        }
        reports {
          report {
            id
            year
            startDate
            endDate
            taxMethod {
              id
              label
            }
            userTaxDetailId
            fiatCurrency
          }
          values {
            gainLoss
            shortTermGainLoss
            longTermGainLoss
            income
            taxLiability
          }
          lineItemCount
          missingCostBasisCount
          zeroProceedsCount
        }
        isOverTxnLimit
        hasNoPlan
      }
      hasData
    }
  }
`;

export const useLatestTxnReports = () => {
  return useQuery<LatestTxnReportsQuery>(LATEST_TXN_REPORTS, { fetchPolicy: 'cache-and-network' });
};
