import classNames from 'classnames';
import React from 'react';
import { useUserContext } from '../../contexts';
import { useActionItems } from './actionItems';
import NavDropdown from './NavDropdown';
import { navItems } from './navItems';

type Props = {
  isAdminPage: boolean;
};
export default function DesktopDropdown({ isAdminPage }: Props) {
  const { user } = useUserContext();
  const actionItems = useActionItems({ isAdminPage });

  return (
    <NavDropdown
      items={[...(isAdminPage ? [] : [navItems.upgrade, navItems.accountSettings]), ...actionItems]}
      dropdownBody={
        <button
          className={classNames(
            'flex items-center cursor-pointer leading-3]',
            user?.secretMode ? 'text-blur-white' : 'text-white',
          )}
        >
          <span className="text-base">{user?.email as string}</span>
          <span className="text-white material-icons text-[18px] ml-1">arrow_drop_down</span>
        </button>
      }
    />
  );
}
