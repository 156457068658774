import React from 'react';
import { LineItemsFilterQuery } from '../../../graphql/types';
import { Toolbar } from '../../Table';
import Filters from './Filters';

interface Props {
  filterQuery: LineItemsFilterQuery;
  loading: boolean;
  visibleCount: number;
}

export default function CombinedToolbar({ filterQuery, loading, visibleCount }: Props) {
  return (
    <>
      <Toolbar roundedTop className="min-h-[56px] text-sm">
        <Filters filterQuery={filterQuery} />
      </Toolbar>
      <Toolbar loading={loading} background="shade" className="min-h-[56px] text-sm">
        <div className="py-1">
          <span>
            {visibleCount} Taxable Event{visibleCount !== 1 ? 's' : ''}
          </span>
        </div>
      </Toolbar>
    </>
  );
}
