import { gql, useQuery } from '@apollo/client';
import { MissingExchangesQuery } from '../../../../graphql/types';
import { useIntegrationsContext } from '../../../../providers/IntegrationsProvider';

const MISSING_EXCHANGES = gql`
  query MissingExchanges {
    missingExchanges {
      edges {
        name
        txns {
          id
          credential {
            credentialType
          }
          blocksvcHash
          txnTimestamp
          blocksvcFromAddress
          blocksvcToAddress
          sellQuantity
          buyQuantity
          sellCurrency
          buyCurrency
        }
      }
    }
  }
`;

export function useMissingExchanges() {
  const { data, loading, ...rest } = useQuery<MissingExchangesQuery>(MISSING_EXCHANGES, {
    fetchPolicy: 'cache-and-network',
  });
  const { supportedIntegrations } = useIntegrationsContext();

  const filteredData = data?.missingExchanges?.edges?.filter((edge) => {
    return supportedIntegrations.some((i) => i.id === edge.name);
  });

  return { ...rest, data: filteredData, loading: !data && loading };
}
