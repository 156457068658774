import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import {
  LatestReconGuideTreatmentsJobQuery,
  LatestReconGuideTreatmentsJobQueryVariables,
  TreatmentsQuery,
} from '../types';

const TREATMENTS = gql`
  query Treatments {
    reconGuideTreatments {
      id
      name
      description
      reconOrder
      notes
    }
  }
`;

export function useGetTreatments() {
  return useQuery<TreatmentsQuery>(TREATMENTS);
}

export const LATEST_RECON_GUIDE_TREATMENTS_JOB = gql`
  query LatestReconGuideTreatmentsJob {
    latestReconGuideTreatmentsJob {
      id
      status
      createdAt
      updatedAt
      jobData {
        appliedTreatments {
          treatmentId
          treatmentName
          count
        }
        summary {
          duration
          txnsReconciled
          txnTotal
        }
      }
    }
  }
`;

export const useLatestReconGuideTreatmentsJob = (
  options: QueryHookOptions<LatestReconGuideTreatmentsJobQuery, LatestReconGuideTreatmentsJobQueryVariables>,
) =>
  useQuery<LatestReconGuideTreatmentsJobQuery, LatestReconGuideTreatmentsJobQueryVariables>(
    LATEST_RECON_GUIDE_TREATMENTS_JOB,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    },
  );
