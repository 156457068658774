import React from 'react';
import Helmet from 'react-helmet';
import { theme } from '../../tailwind.config';
import { Theme } from './useTheme';

const themeColorMap = {
  light: theme.extend.backgroundColor['light-site-bg'],
  dark: theme.extend.backgroundColor['dark-site-bg'],
};

const ThemeMetaTags = ({ theme }: { theme: Theme }) => {
  const c = themeColorMap[theme];
  return (
    <Helmet>
      <meta name="theme-color" content={c} />
      <meta name="msapplication-navbutton-color" content={c} />
      <meta name="apple-mobile-web-app-status-bar-style" content={c} />
    </Helmet>
  );
};

export default React.memo(ThemeMetaTags);
