import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  alignSelf,
  borderColor,
  borderRadius,
  borders,
  bottom,
  boxShadow,
  color,
  display,
  flexBasis,
  flexDirection,
  flexWrap,
  flex,
  fontFamily,
  fontSize,
  fontWeight,
  height,
  justifyContent,
  left,
  lineHeight,
  maxWidth,
  minHeight,
  minWidth,
  opacity,
  overflow,
  padding,
  position,
  right,
  space,
  textAlign,
  top,
  width,
  zIndex,
  background,
} from 'styled-system';
import { Flex, Box } from 'grid-styled';
import { media } from '../../css/theme';

export const StyledDiv = styled.div`
  ${(p) =>
    p.sticky
      ? css`
          position: -webkit-sticky;
          position: sticky;
          top: -1px;
        `
      : null};
  ${alignSelf};
  ${background};
  ${borders};
  ${borderColor};
  ${borderRadius};
  ${bottom};
  ${color};
  ${display};
  ${fontWeight};
  ${flexBasis};
  ${flexDirection};
  ${flex};
  ${fontSize};
  ${justifyContent};
  ${left};
  ${lineHeight};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${opacity};
  ${padding};
  ${position};
  ${right};
  ${space};
  ${textAlign};
  ${top};
  ${width};
  ${height};
  ${zIndex};
`;

export const StyledNav = styled.nav`
  ${alignSelf};
  ${borders};
  ${borderColor};
  ${borderRadius};
  ${bottom};
  ${color};
  ${display};
  ${flexBasis};
  ${flexDirection};
  ${fontSize};
  ${justifyContent};
  ${left};
  ${lineHeight};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${position};
  ${right};
  ${space};
  ${textAlign};
  ${top};
  ${width};
  ${height};
`;

export const StyledBox = styled(Box)`
  ${color};
  ${display};
  ${maxWidth};
  ${minWidth};
  ${minHeight};
  ${textAlign};
  ${space};
  ${width};
  ${height};
  ${borders};
  ${borderColor};
  ${borderRadius};
  ${position};
  ${opacity};
  ${zIndex};
  ${top};
`;

StyledBox.propTypes = {
  blacklist: PropTypes.arrayOf(PropTypes.string),
};

StyledBox.defaultProps = {
  blacklist: [
    ...Object.keys(Box.propTypes),
    'maxWidth',
    'minWidth',
    'minHeight',
    'textAlign',
    'borderTop',
    'borderBottom',
    'borderRight',
    'borderLeft',
    'borderColor',
    'borderRadius',
    'zIndex',
  ],
};

export const StyledFlex = styled(Flex)`
  ${borderColor};
  ${borderRadius};
  ${borders};
  ${boxShadow};
  ${display};
  ${flexBasis};
  ${height};
  ${overflow};
  ${maxWidth};
  ${minHeight};
  ${position};
  ${space};
  ${minWidth};
  ${textAlign};
  ${position};
  ${top};
  ${bottom};
  ${right};
  ${lineHeight}
  ${fontSize};
  ${width};
  ${textAlign};
  ${opacity};
  ${flexWrap};
`;

StyledFlex.propTypes = {
  blacklist: PropTypes.arrayOf(PropTypes.string),
};

StyledFlex.defaultProps = {
  blacklist: [
    ...Object.keys(Flex.propTypes),
    'borderRadius',
    'minHeight',
    'maxWidth',
    'borderTop',
    'borderBottom',
    'borderRight',
    'borderLeft',
    'borderWidth',
    'borderColor',
    'minWidth',
    'textAlign',
  ],
};

export const StyledUl = styled.ul`
  ${height};
  ${width};
  ${space};
  ${maxWidth};
  ${display};
  ${color};
  ${fontSize};
  ${borders};
  ${top};
  ${left};
  ${bottom};
  ${right};
  ${borderRadius};
  ${overflow};
  ${position};
  ${boxShadow}
`;

export const StyledOl = styled.ol`
  ${height};
  ${width};
  ${space};
  ${display};
  ${flexWrap};
`;

export const StyledLi = styled.li`
  list-style: ${(p) => (p.listStyle ? p.listStyle : 'none')};
  ${height};
  ${space};
  ${display};
  ${borders};
  ${fontSize};
  ${fontWeight};
  ${width};
  ${opacity};
  ${color};
`;

export const Img = styled.img`
  ${maxWidth};
  ${display};
  ${opacity};
`;

export const StyledP = styled.p`
  ${borders};
  ${color};
  ${display};
  ${fontFamily};
  ${fontSize};
  ${fontWeight};
  ${height};
  ${lineHeight};
  ${maxWidth};
  ${opacity};
  ${space};
  ${textAlign};
  ${width};
  ${position};
  ${top};
  ${bottom};
  ${left};
  ${right};
`;

StyledP.displayName = 'StyledP';

export const StyledLink = styled(Link)`
  color: black;
  text-decoration: ${(p) => (p.underline ? 'underline' : 'none')};
  ${color};
  ${display};
  ${fontFamily};
  ${fontSize};
  ${fontWeight};
  ${height};
  ${lineHeight};
  ${maxWidth};
  ${position};
  ${space};
  ${textAlign};
  ${top};
  ${width};
  ${opacity};
`;

export const StyledSpan = styled.span`
  text-decoration: ${(p) => (p.underline ? 'underline' : 'none')};
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${fontFamily};
  ${maxWidth};
  ${lineHeight};
  ${display};
  ${position};
  ${opacity};
  ${maxWidth};
  ${height};
  ${width};
  ${borderRadius};
  ${borders};
  ${top};
  ${left};
`;
StyledSpan.displayName = 'StyledSpan';

export const StyledA = styled.a`
  color: ${(props) => props.theme.linkColor};
  text-decoration: none;
  cursor: pointer;
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${fontFamily};
  ${maxWidth};
  ${lineHeight};
  ${display};
  ${opacity};
`;
StyledA.displayName = 'StyledA';

export const StyledH1 = styled.h1`
  font-size: ${(props) => props.theme.fontSizes[5]};
  font-weight: ${(props) => props.theme.fontWeights[2]};
  ${color};
  ${fontFamily};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${maxWidth};
  ${space};
  ${textAlign};
`;
StyledH1.displayName = 'StyledH1';

export const StyledH2 = styled.h2`
  font-size: ${(props) => props.theme.fontSizes[4]};
  font-weight: ${(props) => props.theme.fontWeights[3]};
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${maxWidth};
  ${lineHeight};
  ${width};
`;
StyledH2.displayName = 'StyledH2';

export const StyledH3 = styled.h3`
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${opacity}
  ${fontFamily};
  ${borders};
  ${lineHeight};
  ${opacity};
`;

export const StyledH4 = styled.h4`
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${opacity};
`;

export const StyledH5 = styled.h5`
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
`;

export const StyledH6 = styled.h6`
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
`;

export const StyledIcon = styled.i`
  opacity: 0.4;
  cursor: pointer;
  position: relative;
  top: 5px;
  font-size: ${(props) => props.theme.fontSizes[3]};
  ${opacity};
  ${space};
  ${color};
  ${fontSize};
  ${lineHeight};
  ${top};
`;
StyledIcon.displayName = 'StyledIcon';

export const StyledImg = styled.img`
  ${height};
  ${maxWidth};
  ${opacity};
  ${space};
  ${width};
  ${borderRadius};
  ${display};
`;

export const StyledLabel = styled.label`
  display: block;
  ${space};
`;

export const StyledClickArea = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border-style: none;
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${borders};
  ${borderRadius};
  ${textAlign};
  ${fontFamily};
  ${maxWidth};
  ${width};
  ${height};
  ${lineHeight};
  ${display};
  ${position};
  ${top};
`;

export const Container = styled(Box)`
  width: 100%;
  ${maxWidth};
`;

Container.propTypes = {
  blacklist: PropTypes.arrayOf(PropTypes.string),
};

Container.defaultProps = {
  blacklist: [
    ...Object.keys(Container.propTypes),
    'maxWidth',
    'minWidth',
    'minHeight',
    'textAlign',
    'borderTop',
    'borderBottom',
    'borderRight',
    'borderLeft',
  ],
  mx: 'auto',
};

export const Card = styled.div`
  background-color: white;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => (props.large ? '2rem' : '1rem')};
  box-shadow: ${(props) => (props.hideShadow ? 'none' : props.theme.shadow)};
  height: ${(props) => (props.stretch ? '100%' : 'inherit')};
  ${width};
  ${color};
  ${minHeight};
  ${space};
  ${display};
  ${borders};
  ${boxShadow};
  ${position};
`;

export const Subtitle = styled.h4`
  font-family: 'Messina';
  font-weight: 400;
  color: gray;
  margin: 0;
`;

export const CardTitle = styled.h3`
  font-family: 'Messina';
  font-weight: 400;
  color: #000;
  font-size: 12px;
  margin: 0;
  font-weight: 600;
  margin-bottom: 0.75rem;
`;

export const LoaderWrapper = styled.div`
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  min-height: ${(props) => (props.padding ? props.padding : '200px')};
  justify-content: center;
`;

export const BetaBadge = styled.span`
  font-weight: 600;
  font-size: 12px;
  ${color};
`;

export const GradientWrapper = styled(StyledDiv)`
  background: linear-gradient(0deg, #f7f8fb 26.44%, rgba(247, 248, 251, 0) 100%);
  ${space};
`;

export const Section = styled.section`
  ${textAlign};
  ${color};
  ${space};
  ${maxWidth};

  ${(props) =>
    props.cutoff &&
    css`
      ${media.giant`
        max-height: 477px;
      `};
    `};
`;
