import Tippy, { TippyProps } from '@tippyjs/react';
import cx from 'classnames';
import type { MaterialIcon as MaterialIconOriginal } from 'material-icons';
import React, { ReactElement, useMemo } from 'react';
import Icon, { IconType, isIcon } from '../Icon';
import { textVariantClassnames } from '../Text';
import './tippy.css';

type MaterialIcon = MaterialIconOriginal | 'info_outline';

interface Props extends Omit<TippyProps, 'children'> {
  /** tooltip content */
  children: React.ReactNode;
  /** name of icon to render, or ReactElement */
  icon?: IconType | MaterialIcon | ReactElement;
  /** custon classNames for icon */
  iconClassNames?: string;
  /** cb when icon is clicked */
  onClick?: () => void;
  /** custom classNames for react tooltip */
  tooltipClassNames?: string;
  /** optional placement for the tooltip */
  place?: 'top' | 'right' | 'bottom' | 'left';
  /** if true, the mouse can be moved into the tooltip */
  interactive?: boolean;
  /** if true - make the tooltip reference element invisible */
  invisible?: boolean;
  /** optional margin classname */
  margin?: string;
  /* if defined, the tooltip will have this maximum width */
  maxWidth?: string;
  /** optional icon color definition */
  color?: 'gray' | 'red';
  /** text variant */
  variant?: keyof typeof textVariantClassnames;
}

const isReactElement = (icon: Props['icon']): icon is ReactElement => {
  return React.isValidElement(icon);
};

function Tooltip({
  children,
  icon,
  iconClassNames,
  onClick,
  place = 'top',
  interactive = false,
  invisible = false,
  maxWidth = '24rem',
  margin,
  color,
  variant = 'muted',
  ...restProps
}: Props) {
  const renderedIcon = useMemo(() => {
    const baseClassname = cx(iconClassNames, invisible && 'invisible', 'relative');

    if (!icon) {
      return <></>;
    }

    if (isReactElement(icon)) {
      return icon;
    }

    // use an inline-rendered SVG
    if (isIcon(icon)) {
      return (
        <Icon
          variant={variant}
          className={cx(baseClassname, margin ?? 'ml-2')}
          onClick={onClick}
          type={icon}
        />
      );
    }

    // use a material icon
    return (
      <i
        className={cx(
          baseClassname,
          'cursor-pointer text-base material-icons',
          margin ?? 'ml-2',
          color === 'gray' && textVariantClassnames.muted,
          color === 'red' && textVariantClassnames.critical,
        )}
        onClick={onClick}
      >
        {icon}
      </i>
    );
  }, [iconClassNames, invisible, icon, margin, color, onClick, variant]);

  return (
    <Tippy
      maxWidth={maxWidth}
      content={children}
      placement={place}
      zIndex={1000000}
      interactive={interactive}
      {...restProps}
    >
      {renderedIcon}
    </Tippy>
  );
}

export default React.memo(Tooltip);
