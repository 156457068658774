import cx from 'classnames';
import React, { useContext } from 'react';
import Helmet from 'react-helmet';

import meta from '../../../constants/metaTags';
import { UserContext } from '../../../contexts';
import Spinner from '../../Spinner';

interface SeoProps {
  /** article type */
  article?: string;
  /** description of site */
  description?: string;
  /** image content */
  image?: string;
  /** keywords for search engines */
  keywords?: string;
  /** title of site*/
  title?: string;
}

interface PageProps extends SeoProps {
  /** content children */
  children?: React.ReactNode;
  /** displays loading spinner */
  loading?: boolean;
  scrollable?: boolean | 'mobileOnly';
}

function Seo({
  title = meta.HOME.title,
  description = meta.HOME.description,
  image = meta.HOME.image,
  keywords = '',
  article = '',
}: SeoProps) {
  const { user } = useContext(UserContext);
  return (
    <Helmet>
      <title>{user ? `${user.email}` : title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:url" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={article} />
      <meta property="og:site_name" content="TokenTax" />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:width" content="1500" />
      <meta property="og:image:height" content="1125" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@tokentax" />
      <meta name="twitter:creator" content="@tokentax" />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
}

//TODO: add media queries
function Page({ children, loading, scrollable = true, ...props }: PageProps) {
  if (loading) {
    return (
      <div className="px-4 pt-6 flex items-center justify-center min-h-[calc(100vh-52px)] w-screen mx-auto">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <>
      <Seo {...props} />
      <div
        className={cx(
          'box-border pb-4 pt-6 px-4 flex flex-col flex-grow w-full mx-auto',
          scrollable === 'mobileOnly' ? 'md:overflow-hidden' : !scrollable && 'overflow-hidden',
          !scrollable ? 'h-[calc(100vh-52px)]' : 'min-h-[calc(100vh-52px)]',
        )}
      >
        {children}
      </div>
    </>
  );
}

export default Page;
