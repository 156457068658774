import { CredentialSource, CredentialsQuery } from '../../graphql/types';
import { IntegrationsContextType } from '../../contexts/IntegrationsContext';

type Credential = CredentialsQuery['credentials']['edges'][number];

export function formatCredentialSource(source: CredentialSource) {
  switch (source) {
    case 'api':
      return 'API';
    case 'csv':
      return 'CSV';
    case 'form':
      return 'Manual Entry';
    case 'unreconciled':
      return 'Unreconciled';
    default:
      return '';
  }
}

// maps e.g. `coinbasepro` -> `Coinbase Pro` via the `supportedCredentials` returned from the exchange service via the GraphQL API
export function formatLocation(location: string | null, integrationsContext: IntegrationsContextType) {
  const { allIntegrations = [] } = integrationsContext;
  const exchangeServiceData = allIntegrations.find(({ id }) => id?.toLowerCase() === location?.toLowerCase());
  return exchangeServiceData?.name ?? location;
}

export function getCredentialName(
  credential: Pick<Credential, 'credentialType' | 'source'>,
  integrationsContext: IntegrationsContextType,
) {
  const { credentialType, source } = credential;

  if (source === 'api') {
    // credential type is a location like `ETH` or `coinbasepro`: use the human-readable version as the name
    return formatLocation(credentialType, integrationsContext);
  }

  // for manual imports and csv's, `credentialType` is the credential's name
  return credentialType;
}

export const getCredentialDescription = (credential: Pick<Credential, 'name'>) => credential.name;
