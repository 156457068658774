import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { StyledFlex, StyledH1, StyledP, StyledLink, StyledSpan, Container } from '../../General';
import theme from '../../../css/theme';
import Card from '../../Card/components';

const Status = ({ code, children }) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
};

Status.propTypes = {
  code: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const PageNotFound = () => {
  let currentPath = typeof window !== 'undefined' ? `“` + window.location.pathname + `”` : '';

  return (
    <Status code={404}>
      <Container>
        <Card>
          <StyledFlex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            minHeight="70vh"
            p="25px"
            textAlign="center"
          >
            <StyledH1 color={theme.colors.textBlack} fontSize={[`28px`, `48px`]} mt={0} mb={[1]}>
              404: Cannot find {currentPath}
            </StyledH1>
            <StyledP color={theme.colors.textBlack} fontSize={[`16px`, `18px`]}>
              <StyledSpan opacity={0.5}>Cannot find what you are looking for? </StyledSpan>
              <StyledLink to="/" color="linkColor">
                Go to the homepage
              </StyledLink>
            </StyledP>
          </StyledFlex>
        </Card>
      </Container>
    </Status>
  );
};

export default PageNotFound;
