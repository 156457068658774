import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Turnstile, { useTurnstile } from 'react-turnstile';
import 'url-search-params-polyfill';
import { TURNSTILE_SITE_KEY } from '../../lib/constants';
import ActivatePlanNotice from '../ActivatePlan/ActivatePlanNotice';
import { getPlanActivationEmail } from '../ActivatePlan/helpers';
import Button from '../Button';
import Card from '../Card';
import { FormStatus } from '../FormStatus';
import GoogleSignIn from '../GoogleSignIn';
import Input from '../Input';
import PageSeo, { meta } from '../PageSeo';
import PortalLayout from '../PortalLayout';
import TextLink from '../TextLink';
import { useTheme } from '../../theme/useTheme';

declare global {
  interface Window {
    Intercom: Intercom_.IntercomStatic;
  }
}

type LocationProps = {
  email?: string;
};

export type FormProps = {
  email: string;
  password: string;
  captchaResponse: string;
};

type Props = {
  onSubmit: (props: FormProps) => Promise<void>;
  isSubmitting: boolean;
};

const getEmail = (queryEmail: string | null, state: LocationProps) => {
  if (state?.email) {
    return state?.email;
  }

  if (queryEmail && queryEmail !== 'null') {
    return decodeURIComponent(queryEmail);
  }

  return '';
};

export default function Signup({ onSubmit, isSubmitting }: Props) {
  const location = useLocation<LocationProps>();
  const [query] = useState(new URLSearchParams(location.search));
  const [registerError, setRegisterError] = useState<string>();
  const emailFromQuery = getEmail(query.get('email'), location.state);
  const planActivationEmail = getPlanActivationEmail();
  const [email, setEmail] = useState(emailFromQuery || planActivationEmail || '');
  const [password, setPassword] = useState<string>('');
  const [isClean, setIsClean] = useState(true);
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const turnstile = useTurnstile();

  const { theme } = useTheme();

  const onChange = useCallback(() => {
    setRegisterError(undefined);
    if (isClean) {
      setIsClean(false);
      window.analytics.track('sign_up_started', { location: 'sign_up' });
    }
  }, [isClean]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      if (!captchaResponse) throw new Error('Invalid captcha response');

      try {
        await onSubmit({ email, password, captchaResponse });
      } catch (e) {
        turnstile.reset();
        setRegisterError(e instanceof Error ? e.message : 'Unable to create a user with those credentials');
      }
    },
    [email, password, onSubmit, captchaResponse, turnstile],
  );

  return (
    <PortalLayout>
      <div className="mx-auto mt-10 px-4 rounded text-center">
        <PageSeo title={meta.SIGNUP.title} description={meta.SIGNUP.description} />
        <Card className="py-4 px-6 max-w-lg mx-auto">
          <div className="my-5 mb-8">
            <h1 className="text-3xl">Create an account</h1>
          </div>
          {registerError && <FormStatus type="error">{registerError}</FormStatus>}
          <ActivatePlanNotice />
          <form data-cy="signup-form" onSubmit={(event) => handleSubmit(event)}>
            <Input
              defaultValue={email}
              className="mb-4"
              placeholder="Email"
              onChange={(event) => {
                onChange();
                setEmail(event.target.value.toLowerCase());
              }}
              name="email"
            />
            <Input
              className="mb-4"
              type="password"
              onChange={(event) => {
                onChange();
                setPassword(event.target.value);
              }}
              name="password"
              placeholder="Password"
            />

            <Turnstile
              sitekey={TURNSTILE_SITE_KEY}
              size="flexible"
              theme={theme}
              onVerify={(token) => {
                setCaptchaResponse(token);
              }}
            />

            <Button
              large
              fullWidth
              type="submit"
              loading={isSubmitting}
              className="mt-3 mb-4"
              disabled={!captchaResponse}
            >
              Sign up
            </Button>
          </form>
          <div className="mb-4">
            Already have an account? <TextLink to="/login"> Sign in</TextLink>
          </div>
        </Card>
        <GoogleSignIn type="signup" showError={(error) => setRegisterError(error ?? undefined)} />
      </div>
    </PortalLayout>
  );
}
