import { gql, useMutation } from '@apollo/client';
import { SaveSpecIdMutation, SaveSpecIdMutationVariables } from '../../../graphql/types';

const SAVE_SPEC_ID = gql`
  mutation SaveSpecId($sellId: String!, $componentIds: [String!]!) {
    saveSpecId(sellId: $sellId, componentIds: $componentIds)
  }
`;

export const useSaveSpecId = () => {
  return useMutation<SaveSpecIdMutation, SaveSpecIdMutationVariables>(SAVE_SPEC_ID);
};
