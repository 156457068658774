import React from 'react';
import Pagination from '../../Pagination';
import { useTableContext } from '../context';
import { HandlePageChange } from '../types';

function TablePagination() {
  const { controlledState, handlers } = useTableContext();

  const countsLoading = controlledState?.countsLoading;
  const currentPage = controlledState?.page || 1;
  const totalPages = controlledState?.totalPages || 1;
  const handlePageChange = handlers?.handlePageChange as HandlePageChange;

  if (!countsLoading && totalPages === 1) {
    return null; // no need to show any buttons if there's only one page
  }

  return (
    // pagination component is sticky to the bottom of the table
    // 'short:relative' turns off sticky for very short displays (avoids pagination covering the header)
    <div className="sticky short:relative inline-flex p-4 z-30 bg-light-base dark:bg-dark-base border-t">
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        loading={countsLoading}
      />
    </div>
  );
}

export default React.memo(TablePagination);
