import { ApolloClient, MutationHookOptions, gql, useMutation } from '@apollo/client';
import { Optional } from 'utility-types';
import { RecalcData } from '../../contexts';
import {
  AddManualTxnsMutation,
  AddManualTxnsMutationVariables,
  CreatedTxnInput,
  DeleteCredentialsMutation,
  DeleteCredentialsMutationVariables,
  MarkTxnsSpamMutation,
  MarkTxnsSpamMutationVariables,
  RecalculateMutation,
  RecalculateMutationVariables,
  ReviewTxnsMutation,
  ReviewTxnsMutationVariables,
  Txn,
  UpdateTaxDetailsMutation,
  UpdateTaxDetailsMutationVariables,
  UpdateTxnsMutation,
  UpdateTxnsMutationVariables,
} from '../../graphql/types';
import Auth from '../../lib/Auth';
import { TXN } from '../fragments';

export * from './changeUserData';
export * from './createMovement';
export * from './getUploadUrl';
export * from './uploadFile';

const ADD_MANUAL_TXNS = gql`
  mutation AddManualTxns($txns: [CreatedTxnInput!]!) {
    addTransactions(txns: $txns) {
      credential {
        id
      }
      exchangeServiceRequest {
        status
      }
    }
  }
`;

export const useAddManualTxns = () =>
  useMutation<AddManualTxnsMutation, AddManualTxnsMutationVariables>(ADD_MANUAL_TXNS);

export const addTxns = async (client: ApolloClient<unknown>, txns: CreatedTxnInput[]) => {
  const res = await client.mutate<AddManualTxnsMutation, AddManualTxnsMutationVariables>({
    mutation: ADD_MANUAL_TXNS,
    variables: {
      txns,
    },
  });
  return res.data;
};

const REVIEW_TXNS = gql`
  mutation ReviewTxns($ids: [String!]!, $reviewed: Boolean!, $isAdmin: Boolean!) {
    reviewTxns(ids: $ids, reviewed: $reviewed) {
      count
      errors {
        transactionId
        error
      }
      updatedTxns {
        ...Txn
      }
    }
  }
  ${TXN}
`;

export const reviewTxns = async (
  client: ApolloClient<unknown>,
  txns: UpdateableTransaction[],
  reviewed: boolean,
) => {
  const optimisticResponse: ReviewTxnsMutation = {
    reviewTxns: {
      __typename: 'UpdatedTxnResponse',
      count: txns.length,
      errors: [],
      updatedTxns: txns.map((txn) => ({
        ...txn,
        reviewed,
      })) as ReviewTxnsMutation['reviewTxns']['updatedTxns'],
    },
  };

  const res = await client.mutate<ReviewTxnsMutation, ReviewTxnsMutationVariables>({
    mutation: REVIEW_TXNS,
    optimisticResponse,
    variables: {
      ids: txns.map(({ id }) => id),
      reviewed,
      isAdmin: Auth.tokenIsForAdmin(),
    },
  });

  return res.data;
};

const MARK_SPAM_TXNS = gql`
  mutation MarkTxnsSpam($ids: [String!]!, $isSpam: Boolean!, $isAdmin: Boolean!) {
    markTxnsSpam(ids: $ids, isSpam: $isSpam) {
      count
      errors {
        transactionId
        error
      }
      updatedTxns {
        ...Txn
      }
    }
  }
  ${TXN}
`;

export const markTxnsSpam = async (
  client: ApolloClient<unknown>,
  txns: UpdateableTransaction[],
  isSpam: boolean,
) => {
  const res = await client.mutate<MarkTxnsSpamMutation, MarkTxnsSpamMutationVariables>({
    mutation: MARK_SPAM_TXNS,
    variables: {
      ids: txns.map(({ id }) => id),
      isSpam,
      isAdmin: Auth.tokenIsForAdmin(),
    },
  });

  return res.data;
};

const UPDATE_TXNS = gql`
  mutation UpdateTxns($txns: [TxnInput!]!, $isAdmin: Boolean!) {
    updateTxns(txns: $txns) {
      count
      errors {
        transactionId
        error
      }
      updatedTxns {
        ...Txn
      }
    }
  }
  ${TXN}
`;

type UpdateableTransaction = Optional<
  Pick<
    Txn,
    | 'id'
    | 'buyCurrency'
    | 'buyTokenId'
    | 'buyQuantity'
    | 'buyPrice'
    | 'description'
    | 'exchangeName'
    | 'sellCurrency'
    | 'sellTokenId'
    | 'sellQuantity'
    | 'feeCurrency'
    | 'feeTokenId'
    | 'feeQuantity'
    | 'feePrice'
    | 'sellPrice'
    | 'priceFetchingSide'
    | 'txnTimestamp'
    | 'reviewed'
    | 'txnType'
    | 'bkpVendorId'
    | 'bkpAccountDebitId'
    | 'bkpAccountCreditId'
    | 'reviewed'
    | 'isSpam'
    | 'accountId'
    | 'toAccountId'
  >,
  'txnTimestamp' | 'description' | 'feeCurrency' | 'feeQuantity' | 'buyTokenId' | 'sellTokenId' | 'feeTokenId'
>;

export const updateTxns = async (
  client: ApolloClient<unknown>,
  txns: UpdateableTransaction[],
  refetchNeedsRecalc: RecalcData['refetchNeedsRecalc'],
) => {
  const optimisticResponse: UpdateTxnsMutation = {
    updateTxns: {
      __typename: 'UpdatedTxnResponse',
      count: txns.length,
      errors: [],
      updatedTxns: txns as UpdateTxnsMutation['updateTxns']['updatedTxns'],
    },
  };

  const res = await client.mutate<UpdateTxnsMutation, UpdateTxnsMutationVariables>({
    mutation: UPDATE_TXNS,
    optimisticResponse,
    variables: {
      txns: txns.map((txn) => ({
        id: txn.id,
        buyCurrency: txn.buyCurrency,
        buyTokenId: txn.buyTokenId,
        buyQuantity: txn.buyQuantity,
        buyPrice: txn.buyPrice,
        description: txn.description,
        location: txn.exchangeName,
        sellCurrency: txn.sellCurrency,
        sellTokenId: txn.sellTokenId,
        sellQuantity: txn.sellQuantity,
        feeCurrency: txn.feeCurrency,
        feeTokenId: txn.feeTokenId,
        feeQuantity: txn.feeQuantity === null ? null : String(txn.feeQuantity),
        feePrice: txn.feePrice,
        sellPrice: txn.sellPrice,
        txnTimestamp: txn.txnTimestamp,
        txnType: txn.txnType,
        priceFetchingSide: txn.priceFetchingSide,
        bkpVendorId: txn.bkpVendorId,
        bkpAccountDebitId: txn.bkpAccountDebitId,
        bkpAccountCreditId: txn.bkpAccountCreditId,
        reviewed: txn.reviewed,
        isSpam: txn.isSpam,
        accountId: txn.accountId,
        toAccountId: txn.toAccountId,
      })),
      isAdmin: Auth.tokenIsForAdmin(),
    },
  });

  refetchNeedsRecalc();

  return res.data;
};

export const EXPORT_TXNS = gql`
  mutation exportTxns(
    $sortBy: TxnSortOptions!
    $sortDirection: SortDirectionOptions!
    $filterQuery: TxnFilterQuery!
  ) {
    exportTxns(txnTableInput: { sortBy: $sortBy, sortDirection: $sortDirection, filterQuery: $filterQuery })
  }
`;

export const RECALCULATE = gql`
  mutation recalculate($options: RecalculateOptions) {
    recalculateTxnReports(options: $options) {
      id
    }
  }
`;

export const useRecalculate = (
  props?: MutationHookOptions<RecalculateMutation, RecalculateMutationVariables>,
) => useMutation<RecalculateMutation>(RECALCULATE, props);

const DELETE_CREDENTIAL = gql`
  mutation DeleteCredentials($ids: [Int!]!) {
    deleteCredentials(ids: $ids)
  }
`;

export function useDeleteCredentials(
  options?: MutationHookOptions<DeleteCredentialsMutation, DeleteCredentialsMutationVariables>,
) {
  return useMutation<DeleteCredentialsMutation, DeleteCredentialsMutationVariables>(
    DELETE_CREDENTIAL,
    options,
  );
}

export const UPDATE_TAX_DETAILS = gql`
  mutation UpdateTaxDetails($taxDetails: [UpdateTaxDetailsInput!]!, $userId: Int!) {
    updateTaxDetails(taxDetails: $taxDetails, userId: $userId)
  }
`;

export const emptyTaxYear = {
  taxYear: null,
  calculateAsMargin: false,
  likeKind: false,
  useHoursMinsComparison: false,
  locked: false,
  isEngaged: false,
  plan: null,
  fullFilingPlan: null,
};

export const INPUT_PROPS = Object.keys(emptyTaxYear);

export const useUpdateTaxDetails = () =>
  useMutation<UpdateTaxDetailsMutation, UpdateTaxDetailsMutationVariables>(UPDATE_TAX_DETAILS);
