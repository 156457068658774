import classNames from 'classnames';
import React, { useCallback } from 'react';
import Icon from '../../Icon';
import './FilterableCellWrapper.css';

type Props = {
  onFilter: () => void;
  filterUrl: string;
  value?: string | number;
  children: React.ReactNode;
  rowIsDisabled?: boolean;
};

const FilterableCellWrapper: React.FC<Props> = ({ onFilter, filterUrl, value, children, rowIsDisabled }) => {
  return (
    <div className="filterable_cell_wrapper relative flex items-center h-full bg-inherit">
      <span
        className={classNames(
          'filterable_cell_wrapper--icon absolute right-0',
          value === undefined && 'hidden',
          rowIsDisabled && '!invisible',
        )}
      >
        <a
          className="py-0 pl-2 pr-1 -mt-0.5 leading-none"
          href={filterUrl}
          onClick={useCallback(
            (e) => {
              if (e.ctrlKey || e.metaKey) return;
              e.preventDefault();
              onFilter();
            },
            [onFilter],
          )}
        >
          <Icon type="filter" className="cursor-pointer inline px-1" title={`Filter by ${value}`} />
        </a>
      </span>

      {children}
    </div>
  );
};

export default React.memo(FilterableCellWrapper) as typeof FilterableCellWrapper;
