import { useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import { TxnFragment } from '../../../graphql/types';
import { showEditTxnPanel } from '../../EditTxnPanel';
import { vars } from '../vars';
import { AllTransactionsContextType } from './context';

export const useOnEdit = ({
  refetch,
  onDelete,
  visibleCount,
}: Required<Pick<AllTransactionsContextType, 'refetch' | 'onDelete' | 'visibleCount'>>) => {
  const { multiSelectVar, filtersVar } = vars;
  const { selectionSpan } = useReactiveVar(multiSelectVar);

  const onEdit = useCallback(
    (txns: TxnFragment[]) => {
      const isMultiPageBatchEdit = selectionSpan === 'FilterSet';
      const isEditingSingleTransaction = txns.length === 1;
      showEditTxnPanel({
        onSuccess: refetch,
        ...(isMultiPageBatchEdit && !isEditingSingleTransaction // don't batch edit if user clicked on pencil icon - even if "all for filter" is selected
          ? {
              txns: [],
              isEditingMultiPageBatch: true,
              multiPageBatchCount: visibleCount,
              filtersVar,
            }
          : { txns, onDelete }),
      });
    },
    [selectionSpan, onDelete, refetch, visibleCount, filtersVar],
  );

  return onEdit;
};
