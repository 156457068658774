import React, { useContext } from 'react';
import { TokensContext } from '../../contexts/TokensContext';

import checkIcon from '../../images/check-green.svg';
import TextLink from '../TextLink';
import Text from '../Text';

interface Props {
  onUnlink: () => void;
  tokenId: string;
}

function SelectedToken({ tokenId, onUnlink }: Props) {
  const tokensContext = useContext(TokensContext);
  const { tokensById } = tokensContext;
  const token = tokensById[tokenId];

  return (
    <div className="relative flex mt-3 items-center" data-testid="SelectedToken">
      <img src={checkIcon} alt="check" className="w-4 absolute ml-3" />
      <div
        className="bg-light-selected dark:bg-dark-selected pr-4 pl-8 py-2 w-full rounded"
        data-testid="SelectedToken-tokenName"
      >
        <Text variant="muted">
          {token?.name} ({token?.symbol})
        </Text>
      </div>
      <div className="absolute top-0 mt-2 mr-3 right-0">
        <TextLink testId="SelectedToken-unlink" onClick={onUnlink}>
          Unlink
        </TextLink>
      </div>
    </div>
  );
}

export default React.memo(SelectedToken);
