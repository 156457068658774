import React, { useCallback, useContext, useState } from 'react';
import { injectStripe } from 'react-stripe-elements';
import { UserContext } from '../../../contexts';

import Button from '../../Button';

interface PayButtonProps {
  onPay: (stripeToken: string) => Promise<void>;
  onError: (error: string) => void;
}

const useFullName = () => {
  const { user } = useContext(UserContext);
  if (!user) return '';

  const { firstName, lastName } = user;
  if (!(firstName && lastName)) return '';

  return `${firstName} ${lastName}`;
};

function PayButtonWithoutStripeInjected({
  stripe,
  onPay,
  onError,
}: PayButtonProps & {
  stripe: stripe.Stripe;
}) {
  const fullName = useFullName();
  const [paymentIsInProgress, setPaymentIsInProgress] = useState(false);

  const onPayButtonClick = useCallback(async () => {
    setPaymentIsInProgress(true);

    try {
      // type definitions for `stripe` don't match the version we're using
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { token, error } = await stripe.createToken({ type: 'card', name: fullName });

      if (error || !token) {
        onError(error?.message || 'We could not process your payment. Please try again later.');
        throw new Error('Error processing payment');
      }

      await onPay(token.id);
    } finally {
      setPaymentIsInProgress(false);
    }
  }, [stripe, onPay, fullName, onError]);

  return (
    <Button
      fullWidth
      large
      className="mt-4 mb-2"
      onClick={onPayButtonClick}
      loading={paymentIsInProgress}
      disabled={paymentIsInProgress}
    >
      Pay
    </Button>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default injectStripe<PayButtonProps>(PayButtonWithoutStripeInjected);
