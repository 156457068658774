import { useReactiveVar } from '@apollo/client';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { UserContext, useVersionsContext } from '../../../contexts';
import { usePrevious } from '../../../hooks/usePrevious';
import { useObject } from '../../../lib/hooks';
import { usePageCounts } from '../../FilteredTable';
import { actions, getFilterQuery } from '../actions';
import { pageSize } from '../defaults';
import { useTxns, useTxnsCounts } from '../queries';
import { getSpecIdQuantities } from '../specId';
import { vars } from '../vars';
import { useCheckSpecIdDelinkRequirement } from './checkSpecIdDelinkRequirement';
import { AllTransactionsContext } from './context';
import { useOnCreateMovement } from './onCreateMovement';
import { useOnDelete } from './onDelete';
import { useOnDuplicate } from './onDuplicate';
import { useOnEdit } from './onEdit';
import { useOnEditCell } from './onEditCell';
import { useOnMarkSpam } from './onMarkSpam';
import { useOnMerge } from './onMerge';
import { useOnReview } from './onReview';
import { useCredentialsBeingReconciled, useSpecIdTxn } from './queries';
import { TxnWithOptionalSpecIdQuantities } from './types';

const emptyTxnsArray: TxnWithOptionalSpecIdQuantities[] = [];

function AllTransactionsContextProvider({ children }: React.PropsWithChildren<unknown>) {
  const { paginationVar, multiSelectVar, sortVar, filtersVar } = vars;
  const paginationState = useReactiveVar(paginationVar);
  const sortState = useReactiveVar(sortVar);
  const filtersState = useReactiveVar(filtersVar);
  const multiSelectState = useReactiveVar(multiSelectVar);
  const { txnEditingIsLocked } = useContext(UserContext);
  const filterQuery = getFilterQuery();
  const specIdSellId = filterQuery?.specId?.sellId;

  const specIdTxnResponse = useSpecIdTxn({
    skip: !specIdSellId,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      sellId: specIdSellId!,
    },
  });

  const { data: counts, loading: countsLoading, refetch: refetchCountData } = useTxnsCounts();
  const credentialsBeingReconciled = useCredentialsBeingReconciled();
  const {
    data: tableData,
    loading: tableLoading,
    refetch: refetchTableData,
  } = useTxns({ paginationState, sortState, filtersState });

  const txnEditingWasLocked = usePrevious(txnEditingIsLocked);
  useEffect(() => {
    if (txnEditingWasLocked && !txnEditingIsLocked) {
      refetchCountData();
      refetchTableData();
    }
  }, [refetchCountData, refetchTableData, txnEditingIsLocked, txnEditingWasLocked]);

  const totalCount = counts?.totalCount;
  const { totalPages, visibleCount = 0 } = usePageCounts({
    pageInfo: tableData?.pageInfo,
    totalCount,
    loading: tableLoading,
    pageSize,
  });

  const txns = useMemo(() => {
    const result = tableData?.edges;
    if (!result?.length) {
      return emptyTxnsArray;
    }
    return result.map(
      (txn) =>
        ({
          ...txn,
          ...(specIdSellId ? getSpecIdQuantities({ txn, specIdSellId }) : {}),
        }) as TxnWithOptionalSpecIdQuantities,
    );
  }, [specIdSellId, tableData?.edges]);

  const { page } = paginationState;
  useEffect(() => {
    if (tableData && tableData.edges.length === 0 && page > 1) {
      actions.setPage(page - 1);
    }
  }, [page, tableData]);

  const { selected: selectedIds, selectionSpan } = multiSelectState;
  const isMultiPageSelection = selectionSpan === 'FilterSet';
  const selectedTxns = useMemo(() => {
    const result = txns.filter(({ id }) => isMultiPageSelection || selectedIds.includes(id));
    if (!result.length) {
      return emptyTxnsArray;
    }
    return result;
  }, [isMultiPageSelection, selectedIds, txns]);

  const refetch = useCallback(() => {
    refetchCountData();
    refetchTableData();
  }, [refetchCountData, refetchTableData]);

  const { isRestoringOrRefetching } = useVersionsContext();
  const loading = tableLoading || isRestoringOrRefetching;
  const checkSpecIdDelinkRequirement = useCheckSpecIdDelinkRequirement({ txns, refetch });
  const onDelete = useOnDelete({ checkSpecIdDelinkRequirement, refetch });
  const onEdit = useOnEdit({ refetch, onDelete, visibleCount });
  const onEditCell = useOnEditCell({ refetch, txns, checkSpecIdDelinkRequirement });
  const onReview = useOnReview({ refetch });
  const onDuplicate = useOnDuplicate({ refetch });
  const { txnsBeingMerged, onMerge } = useOnMerge({ refetch });
  const { txnsUsedInMovement, onCreateMovement } = useOnCreateMovement({ refetch });
  const onMarkSpam = useOnMarkSpam({ refetch });

  const txnsBeingProcessed = useMemo(
    () => [...txnsBeingMerged, ...txnsUsedInMovement],
    [txnsBeingMerged, txnsUsedInMovement],
  );

  const value = useObject({
    totalPages,
    visibleCount,
    counts,
    txns,
    tableData,
    refetch,
    onCreateMovement,
    onDelete,
    onEdit,
    onEditCell,
    onDuplicate,
    onReview,
    onMerge,
    onMarkSpam,
    txnsBeingProcessed,
    loading,
    countsLoading,
    selectedTxns,
    specIdSellId,
    specIdTxnResponse,
    checkSpecIdDelinkRequirement,
    credentialsBeingReconciled,
  });

  return <AllTransactionsContext.Provider value={{ ...value }}>{children}</AllTransactionsContext.Provider>;
}

export default React.memo(AllTransactionsContextProvider);

export const useAllTransactionsContext = () => useContext(AllTransactionsContext);
