import React from 'react';
import cx from 'classnames';

import { ColumnInstance, TableType } from '../types';
import { SortDirectionOptions } from '../../../graphql/types';

interface HeaderCellProps<T extends Record<string, unknown>> {
  data: T[];
  column: ColumnInstance<T>;
  sorted: SortDirectionOptions | null;
  onSort?: () => void;
  tableType: TableType;
}

function HeaderCell<T extends Record<string, unknown>>(props: HeaderCellProps<T>): React.ReactElement {
  const { column, sorted, onSort, tableType } = props;
  const { sortable = true, rightAligned, isIndexColumn, render, getHeaderProps, className, padding } = column;

  const iconClasses = cx(
    sorted === 'desc' && 'transform rotate-180',
    sorted === null && 'opacity-0 invisible',
  );

  const headerClasses = cx(
    'font-semibold inline-flex items-center',
    padding ?? 'py-3',
    rightAligned && 'justify-end',
    sortable && 'cursor-pointer',
    className,
  );

  const header = render('Header');
  const title = typeof header === 'string' ? header : '';

  return (
    <div className={headerClasses} {...getHeaderProps()} onClick={sortable ? onSort : undefined}>
      <div className="label whitespace-nowrap" title={title}>
        {header}
      </div>

      {tableType === 'controlled' && !isIndexColumn && (
        <div className="leading-3 relative" style={{ top: 2, left: 2 }}>
          <i className={`material-icons text-lg leading-3 ${iconClasses}`}>arrow_drop_down</i>
        </div>
      )}
    </div>
  );
}

export default React.memo(HeaderCell) as unknown as typeof HeaderCell;
