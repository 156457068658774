import Big from 'big.js';

const isEmpty = (value?: string) => value === undefined || value === null || value === '' || value === '0';

interface BuyPriceProps {
  sellPrice?: string;
  buyQuantity?: string;
  sellQuantity?: string;
}
export const calculateBuyPrice = ({ sellPrice, buyQuantity, sellQuantity }: BuyPriceProps): string => {
  return [sellPrice, sellQuantity, buyQuantity].some(isEmpty)
    ? '0'
    : Big(sellPrice!).times(sellQuantity!).div(buyQuantity!).toString(); // eslint-disable-line @typescript-eslint/no-non-null-assertion
};

interface SellPriceProps {
  buyPrice?: string;
  buyQuantity?: string;
  sellQuantity?: string;
}

export const calculateSellPrice = ({ buyPrice, buyQuantity, sellQuantity }: SellPriceProps): string => {
  return [buyPrice, sellQuantity, buyQuantity].some(isEmpty)
    ? '0'
    : Big(buyPrice!).times(buyQuantity!).div(sellQuantity!).toString(); // eslint-disable-line @typescript-eslint/no-non-null-assertion
};

export const TRANSACTION_TYPES_WITH_BOTH_BUY_AND_SELL_PRICES = ['trade', 'migration'];

export const isTradeLike = ({ txnType }: { txnType: string }) =>
  TRANSACTION_TYPES_WITH_BOTH_BUY_AND_SELL_PRICES.includes(txnType);
