import { TREATMENTS } from '../types';
import { borrowWithExclusion } from './borrowWithExclusion';
import { incomeWithExclusion } from './incomeWithExclusion';
import { incomeWithWithdrawal } from './incomeWithWithdrawal';
import { repayWithExclusion } from './repayWithExclusion';
import { repayWithIncome } from './repayWithIncome';
import { swapWithIncome } from './swapWithIncome';
import { ComplexTreatmentResult, Props } from './types';
import { wrap } from './wrap';

export const TREATMENT_NOT_APPLICABLE = Symbol('TREATMENT_NOT_APPLICABLE');

export function getComplexTreatment(props: Props): ComplexTreatmentResult {
  const { category, txn } = props;
  const { treatment } = txn;

  if (!category) return { drafts: null };

  if (category === 'income' && treatment === TREATMENTS.incomeWithExclusion) {
    return incomeWithExclusion(props);
  }

  if (category === 'income' && treatment === TREATMENTS.swapWithIncome) {
    return swapWithIncome(props);
  }

  if (category === 'income' && treatment === TREATMENTS.incomeWithWithdrawal) {
    return incomeWithWithdrawal(props);
  }

  if (category === 'borrow' && treatment === TREATMENTS.borrowWithExclusion) {
    return borrowWithExclusion(props);
  }

  if (category === 'trade' && treatment === TREATMENTS.wrap) {
    return wrap(props);
  }

  if (category === 'repay' && treatment === TREATMENTS.repayWithExclusion) {
    return repayWithExclusion(props);
  }

  if (category === 'repay' && treatment === TREATMENTS.repayWithIncome) {
    return repayWithIncome(props);
  }

  // _weighted_ LP entrance/exits need no particular change to the draft that would normally be created
  // when choosing the LP entrance/exit categories.
  // it's the API that changes the merge strategy to be based on "weight" when these categories are chosen,
  // if there is a matching instruction to make them weighted.
  // see details in https://linear.app/tokentax/issue/TOK-2624/update-unreconciled-tx-drafts-with-new-evm-data

  return { drafts: null };
}
