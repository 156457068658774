import React from 'react';

const Breadcrumb = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="relative">
      <div className="p-1 px-3 whitespace-nowrap bg-light-selected dark:bg-dark-selected border border-light-control dark:border-dark-control rounded">
        {children}
      </div>
    </div>
  );
};

export default Breadcrumb;
