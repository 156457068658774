import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { RetrieveCheckoutQuery, RetrieveCheckoutQueryVariables } from '../../graphql/types';

const RETRIEVE_CHECKOUT = gql`
  query RetrieveCheckout($checkoutSessionId: String!) {
    retrieveCheckout(checkoutSessionId: $checkoutSessionId) {
      email
      activated
      plan
      emailHasMatchingAccount
    }
  }
`;

export const useRetrieveCheckout = (
  options: QueryHookOptions<RetrieveCheckoutQuery, RetrieveCheckoutQueryVariables>,
) => useQuery<RetrieveCheckoutQuery, RetrieveCheckoutQueryVariables>(RETRIEVE_CHECKOUT, options);
