import React, { useContext } from 'react';

import Spinner from '../Spinner';
import { VersionsContext } from '../../contexts';

export default function RestoreInProgress() {
  const { versions, isRestoringOrRefetching } = useContext(VersionsContext);
  const { restoredVersionNumber } = versions?.status ?? {};

  if (!isRestoringOrRefetching) {
    return null;
  }

  return (
    <div className="flex width-full justify-center h-full items-center" style={{ minHeight: '200px' }}>
      <Spinner size="md" />
      <span className="ml-4 text-2xl">Version {restoredVersionNumber} is being restored</span>
    </div>
  );
}
