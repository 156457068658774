import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { reviewTxns } from '../../../graphql/mutations';
import { TxnFragment } from '../../../graphql/types';
import { showErrorFlash } from '../../Flash';
import { actions } from '../actions';
import { AllTransactionsContextType } from './context';

export const useOnReview = ({ refetch }: Required<Pick<AllTransactionsContextType, 'refetch'>>) => {
  const apolloClient = useApolloClient();

  const onReview = useCallback(
    async (txns: TxnFragment[], reviewed: boolean) => {
      try {
        await reviewTxns(apolloClient, txns, reviewed);
        actions.deselect(txns.map(({ id }) => id));
        refetch?.();
      } catch (error) {
        showErrorFlash('The transactions could not be marked as reviewed.', { error });
      }
    },
    [apolloClient, refetch],
  );

  return onReview;
};
