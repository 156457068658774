export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  DateTime: { input: any; output: any };
  Json: { input: any; output: any };
};

export type Account = {
  __typename?: 'Account';
  address?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  integrationId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type AccountBalanceEntry = {
  __typename?: 'AccountBalanceEntry';
  account: Account;
  accountId: Scalars['Int']['output'];
  balance: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  usdValue?: Maybe<Scalars['Float']['output']>;
};

export type AccountBalanceMap = {
  __typename?: 'AccountBalanceMap';
  accountId: Scalars['Int']['output'];
  entries: Array<BalanceEntry>;
};

export type AccountBalancesInput = {
  accountIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  currencies?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  includeZeroBalances?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountsData = {
  __typename?: 'AccountsData';
  edges: Array<Account>;
};

export type Addon = {
  __typename?: 'Addon';
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  product: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AddressWithCurrencyInput = {
  address: Scalars['String']['input'];
  currency: Scalars['String']['input'];
};

export type AnalyticsProductData = {
  __typename?: 'AnalyticsProductData';
  is_upgrade?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  product_id: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  /** @deprecated This field is not used as of 2023-06-21. Use product_id instead. */
  sku: Scalars['String']['output'];
};

export type ApplyReconGuideTreatmentsResult = {
  __typename?: 'ApplyReconGuideTreatmentsResult';
  id: Scalars['String']['output'];
};

export type BalanceEntry = {
  __typename?: 'BalanceEntry';
  balance: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  date: Scalars['String']['output'];
  diff: Scalars['Float']['output'];
};

export type BkpAccount = {
  __typename?: 'BkpAccount';
  active?: Maybe<Scalars['Boolean']['output']>;
  bkpIntegrationAccountType?: Maybe<Scalars['String']['output']>;
  bkpIntegrationAcctNum?: Maybe<Scalars['String']['output']>;
  bkpIntegrationClassification?: Maybe<Scalars['String']['output']>;
  bkpIntegrationId?: Maybe<Scalars['String']['output']>;
  bkpIntegrationParentRefId?: Maybe<Scalars['String']['output']>;
  bkpIntegrationSyncToken?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type BkpVendor = {
  __typename?: 'BkpVendor';
  active?: Maybe<Scalars['Boolean']['output']>;
  bkpIntegrationDisplayName?: Maybe<Scalars['String']['output']>;
  bkpIntegrationId?: Maybe<Scalars['String']['output']>;
  bkpIntegrationSyncToken?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type BooleanFilter = {
  key: BooleanFilterTypes;
  value: Scalars['Boolean']['input'];
};

export enum BooleanFilterTypes {
  automaticallyEdited = 'automaticallyEdited',
  hasComments = 'hasComments',
  hasMovement = 'hasMovement',
  isSpam = 'isSpam',
  manuallyEdited = 'manuallyEdited',
  missingCostBasis = 'missingCostBasis',
  missingPrice = 'missingPrice',
  reviewed = 'reviewed',
  zeroProceeds = 'zeroProceeds',
}

export type BuyOrSellPrice = {
  __typename?: 'BuyOrSellPrice';
  buyPrice?: Maybe<Scalars['Float']['output']>;
  sellPrice?: Maybe<Scalars['Float']['output']>;
};

export type BuyProductsResponse = {
  __typename?: 'BuyProductsResponse';
  analyticsProducts: Array<AnalyticsProductData>;
  couponCode?: Maybe<Scalars['String']['output']>;
  couponPercentOff?: Maybe<Scalars['Int']['output']>;
  invoiceId: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export enum CategoryInput {
  borrow = 'borrow',
  bridgeIn = 'bridgeIn',
  bridgeOut = 'bridgeOut',
  freeMint = 'freeMint',
  income = 'income',
  lost = 'lost',
  lpEntrance = 'lpEntrance',
  lpExit = 'lpExit',
  mint = 'mint',
  other = 'other',
  repay = 'repay',
  spam = 'spam',
  spend = 'spend',
  trade = 'trade',
  transferIn = 'transferIn',
  transferOut = 'transferOut',
}

export type Chain = {
  __typename?: 'Chain';
  credentialType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ecosystem: Ecosystem;
  ecosystemId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  shortName: Scalars['String']['output'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  activated: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  emailHasMatchingAccount: Scalars['Boolean']['output'];
  plan: Scalars['String']['output'];
};

export type CloneAccountJob = {
  __typename?: 'CloneAccountJob';
  cloneId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  sourceId: Scalars['Int']['output'];
  status: EnumGenericJobStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ClonedAccountResponse = {
  __typename?: 'ClonedAccountResponse';
  cloneAccountJobId: Scalars['Int']['output'];
};

export type Coin = {
  __typename?: 'Coin';
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  author: User;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isPrivate: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
  userIdTopic: Scalars['Int']['output'];
};

export type Country = {
  __typename?: 'Country';
  endDateDay: Scalars['Int']['output'];
  endDateMonth: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  methods: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  shorthand: Scalars['String']['output'];
  startDateDay: Scalars['Int']['output'];
  startDateMonth: Scalars['Int']['output'];
  taxMethods: Array<TaxMethod>;
};

export type CouponValidationResponse = {
  __typename?: 'CouponValidationResponse';
  percentOff?: Maybe<Scalars['Int']['output']>;
  valid: Scalars['Boolean']['output'];
};

export type CreatedCredential = {
  __typename?: 'CreatedCredential';
  credential: Credential;
  exchangeServiceRequest: ExchangeServiceRequest;
};

export type CreatedCredentials = {
  __typename?: 'CreatedCredentials';
  credentials: Scalars['Int']['output'];
  duplicates: Scalars['Int']['output'];
  exchangeServiceRequests: Scalars['Int']['output'];
};

export type CreatedTxnInput = {
  buyCurrency: Scalars['String']['input'];
  buyQuantity: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  feeCurrency?: InputMaybe<Scalars['String']['input']>;
  feeQuantity?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  sellCurrency: Scalars['String']['input'];
  sellQuantity: Scalars['String']['input'];
  txnTimestamp?: InputMaybe<Scalars['DateTime']['input']>;
  txnType: TxnType;
};

export type CreateMovementsJob = {
  __typename?: 'CreateMovementsJob';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  status?: Maybe<EnumGenericJobStatus>;
};

export type Credential = {
  __typename?: 'Credential';
  accountId?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  coefficientPercent?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  credentials?: Maybe<Scalars['Json']['output']>;
  credentialType: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  earliestTransaction?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  ensAlias?: Maybe<Scalars['String']['output']>;
  errorRemediation?: Maybe<Scalars['String']['output']>;
  filterSpam?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  integrationId?: Maybe<Scalars['String']['output']>;
  isOauth: Scalars['Boolean']['output'];
  lastRefreshError?: Maybe<Scalars['String']['output']>;
  latestTransaction?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  reconcileOnSync?: Maybe<Scalars['Boolean']['output']>;
  source: CredentialSource;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<EnumExchangeServiceRequestStatus>;
  txnCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploads: Array<Upload>;
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
};

export type CredentialFilters = {
  address?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  sources?: InputMaybe<Array<CredentialSource>>;
  years?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CredentialLocationCount = {
  __typename?: 'CredentialLocationCount';
  count: Scalars['Int']['output'];
  location: Scalars['String']['output'];
};

export type Credentials = {
  __typename?: 'Credentials';
  csv?: Maybe<Scalars['String']['output']>;
  edges: Array<Credential>;
  pageInfo: PageInfo;
};

export type CredentialsCount = {
  __typename?: 'CredentialsCount';
  credentialLocationCounts: Array<CredentialLocationCount>;
  credentialSourceCounts: Array<CredentialSourceCount>;
  totalCount: Scalars['Int']['output'];
  transactionCountsPerYear: Array<TransactionCountPerYear>;
};

export enum CredentialSortOptions {
  address = 'address',
  dateRange = 'dateRange',
  description = 'description',
  ensAlias = 'ensAlias',
  source = 'source',
  status = 'status',
  transactionCount = 'transactionCount',
  type = 'type',
  updatedAt = 'updatedAt',
}

export enum CredentialSource {
  api = 'api',
  csv = 'csv',
  form = 'form',
  unreconciled = 'unreconciled',
}

export type CredentialSourceCount = {
  __typename?: 'CredentialSourceCount';
  count: Scalars['Int']['output'];
  source: CredentialSource;
};

export type CredentialTableInput = {
  filters: CredentialFilters;
  isCSV?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<PaginationOptions>;
  sortBy: CredentialSortOptions;
  sortDirection: SortDirectionOptions;
};

export type CredentialTransactions = {
  __typename?: 'CredentialTransactions';
  count: Scalars['Int']['output'];
  rangeEnd?: Maybe<Scalars['String']['output']>;
  rangeStart?: Maybe<Scalars['String']['output']>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  addressZip?: Maybe<Scalars['String']['output']>;
  brand: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export type CsvUploadStatus = {
  __typename?: 'CsvUploadStatus';
  credentialId?: Maybe<Scalars['Int']['output']>;
  status: EnumExchangeServiceRequestStatus;
  unsupportedKnownCsv?: Maybe<UnsupportedKnownCsv>;
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String']['output'];
  countryId?: Maybe<Scalars['Int']['output']>;
};

export enum CurrencyFilterTypes {
  BuyCurrency = 'BuyCurrency',
  Currency = 'Currency',
  FeeCurrency = 'FeeCurrency',
  SellCurrency = 'SellCurrency',
}

export type DateObject = {
  day: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type DateRange = {
  __typename?: 'DateRange';
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

export type DateRangeFilter = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type DocFilterQuery = {
  date?: InputMaybe<DateRangeFilter>;
};

export enum DocSortOptions {
  createdAt = 'createdAt',
  method = 'method',
  reportType = 'reportType',
  status = 'status',
  taxYear = 'taxYear',
}

export type DocTableInput = {
  filterQuery?: InputMaybe<DocFilterQuery>;
  pagination?: InputMaybe<PaginationOptions>;
  sortBy: DocSortOptions;
  sortDirection: SortDirectionOptions;
};

export type Documents = {
  __typename?: 'Documents';
  edges: Array<Report>;
  pageInfo: PageInfo;
};

export type DocumentsCount = {
  __typename?: 'DocumentsCount';
  recommendedDocumentCounts: Array<YearCount>;
  totalCount: Scalars['Int']['output'];
  yearCounts: Array<YearCount>;
};

export type DocumentToggleVisibility = {
  __typename?: 'DocumentToggleVisibility';
  edges: Array<Report>;
};

export type DocumentUrl = {
  __typename?: 'DocumentUrl';
  id: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type DocumentUrls = {
  __typename?: 'DocumentUrls';
  edges: Array<DocumentUrl>;
};

export type Draft = {
  __typename?: 'Draft';
  received?: Maybe<TransfersGroup>;
  sent?: Maybe<TransfersGroup>;
};

export type DraftInput = {
  received?: InputMaybe<TransfersGroupInput>;
  sent?: InputMaybe<TransfersGroupInput>;
};

export type Ecosystem = {
  __typename?: 'Ecosystem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum EnumExchangeServiceRequestStatus {
  canceled = 'canceled',
  completed = 'completed',
  created = 'created',
  failed = 'failed',
  fetchingPrices = 'fetchingPrices',
  inReview = 'inReview',
  reconciling = 'reconciling',
  running = 'running',
}

export enum EnumGenericJobStatus {
  created = 'created',
  failed = 'failed',
  running = 'running',
  successful = 'successful',
}

export enum EnumInviteStatus {
  accepted = 'accepted',
  failed = 'failed',
  invited = 'invited',
  revoked = 'revoked',
}

export enum EnumOrganizationStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum EnumTaxMethod {
  AVERAGE_COST = 'AVERAGE_COST',
  FIFO = 'FIFO',
  LIFO = 'LIFO',
  MINIMIZATION = 'MINIMIZATION',
}

export enum EnumTxnGroupComponent {
  deposit = 'deposit',
  movement = 'movement',
  withdrawal = 'withdrawal',
}

export enum EnumTxnReportMonth {
  april = 'april',
  august = 'august',
  december = 'december',
  february = 'february',
  january = 'january',
  july = 'july',
  june = 'june',
  march = 'march',
  may = 'may',
  november = 'november',
  october = 'october',
  september = 'september',
}

export type EvmInstruction = {
  __typename?: 'EvmInstruction';
  blockSvcMethodId: Scalars['Int']['output'];
  contractId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  pred1?: Maybe<Scalars['Int']['output']>;
  pred2?: Maybe<Scalars['Int']['output']>;
  proba1?: Maybe<Scalars['Float']['output']>;
  proba2?: Maybe<Scalars['Float']['output']>;
  publicNotes?: Maybe<Scalars['String']['output']>;
  tokenParam1?: Maybe<Array<Scalars['String']['output']>>;
  tokenParam2?: Maybe<Array<Scalars['String']['output']>>;
  treatment?: Maybe<Treatment>;
  treatmentId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ExchangeCredentials = {
  __typename?: 'ExchangeCredentials';
  id: Scalars['String']['output'];
  schema: Scalars['Json']['output'];
};

export type ExchangeNameCount = {
  __typename?: 'ExchangeNameCount';
  count: Scalars['Int']['output'];
  exchangeName: Scalars['String']['output'];
};

export type ExchangeServiceRequest = {
  __typename?: 'ExchangeServiceRequest';
  createdAt: Scalars['DateTime']['output'];
  credentialId: Scalars['Int']['output'];
  hardResync: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  requestId: Scalars['String']['output'];
  result?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EnumExchangeServiceRequestStatus>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type ExistsForChainInput = {
  address: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ExistsForChainObject = {
  __typename?: 'ExistsForChainObject';
  address: Scalars['String']['output'];
  chains: Array<Scalars['String']['output']>;
};

export type ExistsForChainResponse = {
  __typename?: 'ExistsForChainResponse';
  results: Array<ExistsForChainObject>;
};

export type FailedTransactionUpdate = {
  __typename?: 'FailedTransactionUpdate';
  error: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
};

export type FederalTax = {
  __typename?: 'FederalTax';
  bracketMax?: Maybe<Scalars['Float']['output']>;
  bracketMin?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deduction?: Maybe<Scalars['Float']['output']>;
  filingStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  personalExemption?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum FilingStatusOptions {
  headOfHousehold = 'headOfHousehold',
  marriedFilingJointly = 'marriedFilingJointly',
  single = 'single',
}

export enum FilterOperatorTypes {
  Ctns = 'Ctns',
  DNCtn = 'DNCtn',
  Is = 'Is',
  IsNot = 'IsNot',
}

export type GenerateAccountsJob = {
  __typename?: 'GenerateAccountsJob';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  status?: Maybe<EnumGenericJobStatus>;
};

export type GenerateReportResult = {
  __typename?: 'GenerateReportResult';
  jobId?: Maybe<Scalars['String']['output']>;
  report?: Maybe<Report>;
};

export type GoogleLoginOrSignupResponse = {
  __typename?: 'GoogleLoginOrSignupResponse';
  isSignup: Scalars['Boolean']['output'];
  user: User;
  userHash: Scalars['String']['output'];
};

export type IdentifierBasedInstruction = {
  __typename?: 'IdentifierBasedInstruction';
  id: Scalars['Int']['output'];
  identifier: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  publicName?: Maybe<Scalars['String']['output']>;
  publicNotes?: Maybe<Scalars['String']['output']>;
  tokenParam1?: Maybe<Array<Scalars['String']['output']>>;
  tokenParam2?: Maybe<Array<Scalars['String']['output']>>;
  treatment?: Maybe<Treatment>;
  treatmentId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Instruction = EvmInstruction | IdentifierBasedInstruction;

export type Invoice = {
  __typename?: 'Invoice';
  amountDue: Scalars['Float']['output'];
  amountRemaining: Scalars['Float']['output'];
  created: Scalars['Int']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  cryptocurrency?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  customerEmail?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['Int']['output']>;
  hideDefaultCrypto: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lines: Array<Line>;
  newUser?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  paid: Scalars['Boolean']['output'];
  paidAt?: Maybe<Scalars['Int']['output']>;
  paymentMethod?: Maybe<InvoicePaymentMethod>;
  products?: Maybe<Products>;
  txnHash?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Int']['output'];
  voided: Scalars['Boolean']['output'];
};

export type InvoiceEvent = {
  __typename?: 'InvoiceEvent';
  timestamp: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type InvoiceEvents = {
  __typename?: 'InvoiceEvents';
  invoiceEvents: Array<InvoiceEvent>;
};

export type InvoiceLine = {
  description: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  unitAmount: Scalars['Int']['input'];
};

export enum InvoicePaymentMethod {
  bank = 'bank',
  card = 'card',
  crypto = 'crypto',
}

export type InvoicePlanInput = {
  planId: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type InvoiceProductsInput = {
  cryptoOnlyPlans: Array<InvoicePlanInput>;
  fullFilingPlans: Array<InvoicePlanInput>;
};

export type Invoices = {
  __typename?: 'Invoices';
  edges: Array<Invoice>;
  userId: Scalars['Int']['output'];
};

export type LatestCreateMovementsJob = {
  __typename?: 'LatestCreateMovementsJob';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  modifiedTxnsLength?: Maybe<Scalars['Int']['output']>;
  status: EnumGenericJobStatus;
};

export type LatestMissingPricesJob = {
  __typename?: 'LatestMissingPricesJob';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  modifiedTxnDetails?: Maybe<Array<ModifiedTxnDetail>>;
  modifiedTxnsLength?: Maybe<Scalars['Int']['output']>;
  status: EnumGenericJobStatus;
};

export type LatestTxnReport = {
  __typename?: 'LatestTxnReport';
  lineItemCount: Scalars['Int']['output'];
  missingCostBasisCount: Scalars['Int']['output'];
  report: TxnReport;
  values: TxnReportValues;
  zeroProceedsCount: Scalars['Int']['output'];
};

export type LatestTxnReports = {
  __typename?: 'LatestTxnReports';
  hasData: Scalars['Boolean']['output'];
  latestTxnReportsByYear: Array<LatestTxnReportsByYear>;
};

export type LatestTxnReportsByYear = {
  __typename?: 'LatestTxnReportsByYear';
  hasNoPlan: Scalars['Boolean']['output'];
  isOverTxnLimit: Scalars['Boolean']['output'];
  reports?: Maybe<Array<LatestTxnReport>>;
  taxDetail: UserTaxDetail;
  year: Scalars['Int']['output'];
};

export type Line = {
  __typename?: 'Line';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  unitAmount?: Maybe<Scalars['Float']['output']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['Int']['output']>;
  buyCurrency?: Maybe<Scalars['String']['output']>;
  buyDate?: Maybe<Scalars['DateTime']['output']>;
  buyId?: Maybe<Scalars['String']['output']>;
  costBasis?: Maybe<Scalars['Float']['output']>;
  costBasisFeeAddition?: Maybe<Scalars['Float']['output']>;
  costBasisIncludingFees: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  deductibleAmount?: Maybe<Scalars['Float']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  feeCurrency?: Maybe<Scalars['String']['output']>;
  feeUnitsSold?: Maybe<Scalars['Float']['output']>;
  gainLoss?: Maybe<Scalars['Float']['output']>;
  gainLossIncludingFees: Scalars['Float']['output'];
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  isFee?: Maybe<Scalars['Boolean']['output']>;
  likeKindCryptoBuyDate?: Maybe<Scalars['DateTime']['output']>;
  likeKindCryptoTrade?: Maybe<Scalars['Boolean']['output']>;
  likeKindForwardedBuyId?: Maybe<Scalars['String']['output']>;
  lineItemType: Scalars['String']['output'];
  missingCostBasis?: Maybe<Scalars['Boolean']['output']>;
  proceeds?: Maybe<Scalars['Float']['output']>;
  proceedsFeeDeduction?: Maybe<Scalars['Float']['output']>;
  proceedsIncludingFees: Scalars['Float']['output'];
  sellCurrency?: Maybe<Scalars['String']['output']>;
  sellDate?: Maybe<Scalars['DateTime']['output']>;
  sellId?: Maybe<Scalars['String']['output']>;
  splitBuyId?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
  txnLineItemBuyIdTotxn?: Maybe<Txn>;
  txnLineItemLikeKindForwardedBuyIdTotxn?: Maybe<Txn>;
  txnLineItemSellIdTotxn?: Maybe<Txn>;
  txnReport: TxnReport;
  txnReportId: Scalars['Int']['output'];
  unitsBought?: Maybe<Scalars['Float']['output']>;
  unitsSold?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type LineItemAuditDetail = {
  __typename?: 'LineItemAuditDetail';
  boughtAmount: Scalars['Float']['output'];
  boughtCurrency: Scalars['String']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  feeAmount: Scalars['Float']['output'];
  feeCurrency: Scalars['String']['output'];
  location: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  soldAmount: Scalars['Float']['output'];
  soldCurrency: Scalars['String']['output'];
  txn: Txn;
  txnType: TxnType;
};

export type LineItemDetail = {
  __typename?: 'LineItemDetail';
  averageCost?: Maybe<Scalars['Float']['output']>;
  bnbMatchType?: Maybe<Scalars['String']['output']>;
  coefficientPercent?: Maybe<Scalars['Float']['output']>;
  costBasis: Scalars['Float']['output'];
  costBasisFeeAddition: Scalars['Float']['output'];
  costBasisFeeAdditionCurrency: Scalars['String']['output'];
  costBasisFeeAdditionUnits: Scalars['Float']['output'];
  costBasisFlat: Scalars['Float']['output'];
  costBasisFromSellFee: Scalars['Float']['output'];
  exitDetail: LineItemAuditDetail;
  gainLoss: Scalars['Float']['output'];
  lineItemTrace: Array<LineItem>;
  migrationDetail?: Maybe<LineItemAuditDetail>;
  missingCostBasis: Scalars['Boolean']['output'];
  missingCostBasisDetail?: Maybe<LineItemMissingCostBasisDetail>;
  originDetail?: Maybe<LineItemAuditDetail>;
  proceeds: Scalars['Float']['output'];
  proceedsFeeDeduction: Scalars['Float']['output'];
  proceedsFeeDeductionCurrency: Scalars['String']['output'];
  proceedsFeeDeductionUnits: Scalars['Float']['output'];
  proceedsFlat: Scalars['Float']['output'];
  proceedsFromSellFee: Scalars['Float']['output'];
  soldAmountFlat: Scalars['Float']['output'];
  specIdMatch?: Maybe<Scalars['Boolean']['output']>;
  term?: Maybe<Scalars['String']['output']>;
};

export type LineItemDetailInput = {
  lineItemId: Scalars['BigInt']['input'];
};

export type LineItemInput = {
  filterDust?: Scalars['Boolean']['input'];
  filterQuery?: InputMaybe<LineItemsFilterQuery>;
  pagination: PaginationOptions;
  sortBy: LineItemSortOptions;
  sortDirection: SortDirectionOptions;
  txnReportId: Scalars['Int']['input'];
};

export type LineItemMissingCostBasisDetail = {
  __typename?: 'LineItemMissingCostBasisDetail';
  missingAmount: Scalars['Float']['output'];
  missingCurrency: Scalars['String']['output'];
};

export type LineItems = {
  __typename?: 'LineItems';
  edges: Array<LineItem>;
  pageInfo: PageInfo;
};

export type LineItemsFilterQuery = {
  account?: InputMaybe<MultiSelectIntFilter>;
  boolean?: InputMaybe<Array<BooleanFilter>>;
  currency?: InputMaybe<Array<TxnCurrencyFilter>>;
  date?: InputMaybe<DateRangeFilter>;
};

export enum LineItemSortOptions {
  accountId = 'accountId',
  buyCurrency = 'buyCurrency',
  buyDate = 'buyDate',
  costBasisIncludingFees = 'costBasisIncludingFees',
  gainLossIncludingFees = 'gainLossIncludingFees',
  proceedsIncludingFees = 'proceedsIncludingFees',
  sellCurrency = 'sellCurrency',
  sellDate = 'sellDate',
  term = 'term',
  unitsSold = 'unitsSold',
}

export type LoginOrSignupResponse = {
  __typename?: 'LoginOrSignupResponse';
  user: User;
  userHash: Scalars['String']['output'];
};

export type LpToken = {
  __typename?: 'LpToken';
  address: Scalars['String']['output'];
  ticker: Scalars['String']['output'];
};

export type MissingExchange = {
  __typename?: 'MissingExchange';
  name: Scalars['String']['output'];
  txns: Array<Txn>;
};

export type MissingExchanges = {
  __typename?: 'MissingExchanges';
  edges: Array<MissingExchange>;
};

export type MissingPricesDetail = {
  __typename?: 'MissingPricesDetail';
  count: Scalars['Int']['output'];
  type: MissingPricesTxnTypes;
};

export enum MissingPricesTxnTypes {
  airdrop = 'airdrop',
  borrow = 'borrow',
  fork = 'fork',
  income = 'income',
  migration = 'migration',
  mining = 'mining',
  staking = 'staking',
  trade = 'trade',
}

export type MissingWallet = {
  __typename?: 'MissingWallet';
  address: Scalars['String']['output'];
  chain: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type MissingWallets = {
  __typename?: 'MissingWallets';
  edges: Array<MissingWallet>;
};

export type MissingWalletValidationInput = {
  address: Scalars['String']['input'];
  chain: Scalars['String']['input'];
};

export type MissingWalletWithTxnValidation = {
  __typename?: 'MissingWalletWithTxnValidation';
  address: Scalars['String']['output'];
  chain: Scalars['String']['output'];
  hasTransactions: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type ModifiedTxnDetail = {
  __typename?: 'ModifiedTxnDetail';
  count: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type Movement = {
  __typename?: 'Movement';
  deposit: MovementComponent;
  id: Scalars['Int']['output'];
  movement: MovementComponent;
  withdrawal: MovementComponent;
};

export type MovementComponent = {
  __typename?: 'MovementComponent';
  component: EnumTxnGroupComponent;
  credential: Credential;
  currency?: Maybe<Scalars['String']['output']>;
  fromAccount?: Maybe<Account>;
  id: Scalars['Int']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  soldCurrency?: Maybe<Scalars['String']['output']>;
  soldQuantity?: Maybe<Scalars['Float']['output']>;
  toAccount?: Maybe<Account>;
  txnHash?: Maybe<Scalars['String']['output']>;
  txnId: Scalars['String']['output'];
  txnTimestamp: Scalars['DateTime']['output'];
};

export type MovementCurrencyFilter = {
  operator: FilterOperatorTypes;
  side: MovementCurrencyFilterTypes;
  symbols: Array<Scalars['String']['input']>;
};

export enum MovementCurrencyFilterTypes {
  Currency = 'Currency',
}

export type MovementFilterQuery = {
  accounts?: InputMaybe<MultiSelectIntFilter>;
  currency?: InputMaybe<Array<MovementCurrencyFilter>>;
  date?: InputMaybe<DateRangeFilter>;
};

export type MovementMatchConstraint = {
  __typename?: 'MovementMatchConstraint';
  quantityLeewayPercentage: Scalars['Int']['output'];
  timestampLeewayDays: Scalars['Int']['output'];
};

export type MovementMergeCandidate = {
  __typename?: 'MovementMergeCandidate';
  account?: Maybe<Account>;
  credential: Credential;
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  soldCurrency?: Maybe<Scalars['String']['output']>;
  soldQuantity?: Maybe<Scalars['Float']['output']>;
  txnHash?: Maybe<Scalars['String']['output']>;
  txnTimestamp: Scalars['DateTime']['output'];
  txnType: Scalars['String']['output'];
};

export type MovementMergeCandidates = {
  __typename?: 'MovementMergeCandidates';
  edges: Array<MovementMergeCandidate>;
  pageInfo: PageInfo;
};

export type Movements = {
  __typename?: 'Movements';
  edges: Array<Movement>;
  pageInfo: PageInfo;
};

export type MovementTableInput = {
  filterQuery?: InputMaybe<MovementFilterQuery>;
  pagination?: InputMaybe<PaginationOptions>;
  sortDirection: SortDirectionOptions;
};

export type MultiSelectIntFilter = {
  operator: FilterOperatorTypes;
  values: Array<Scalars['Int']['input']>;
};

export type MultiSelectStringFilter = {
  operator: FilterOperatorTypes;
  values: Array<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateAccount: LoginOrSignupResponse;
  activatePlanFromCheckout: PlanActivationData;
  addApiIntegration: CreatedCredential;
  addComment: Scalars['Boolean']['output'];
  addMissingPrices: Scalars['String']['output'];
  addOauthIntegration: CreatedCredential;
  addTransactions: CreatedCredential;
  addUserProfile: Scalars['Boolean']['output'];
  addWallets: CreatedCredentials;
  applyReconGuideTreatments: ApplyReconGuideTreatmentsResult;
  batchDeleteTxns: Scalars['Boolean']['output'];
  batchSaveVersionForUsers?: Maybe<Scalars['Boolean']['output']>;
  batchUpdateTxns: Scalars['Boolean']['output'];
  bulkCreateUsers: Scalars['Boolean']['output'];
  buyProducts: BuyProductsResponse;
  cancelCredentialSync: Scalars['Boolean']['output'];
  changePassword: Scalars['Boolean']['output'];
  changeUserData: Scalars['Boolean']['output'];
  cloneAccount: ClonedAccountResponse;
  createAccountRow: Account;
  createInvoice: Scalars['String']['output'];
  createMovement: Scalars['Boolean']['output'];
  deleteAccount: Scalars['Boolean']['output'];
  deleteAccountRow: Scalars['Boolean']['output'];
  deleteComment: Scalars['Boolean']['output'];
  deleteCredentials: Scalars['Int']['output'];
  deleteDocuments: Scalars['Boolean']['output'];
  deleteMovements: Scalars['Boolean']['output'];
  deleteTxns: Scalars['Int']['output'];
  delinkSpecIds: Scalars['Boolean']['output'];
  duplicateTxns: Scalars['Boolean']['output'];
  editAccountRow: Account;
  editApiIntegration: UpdatedCredential;
  editWallet: UpdatedCredential;
  evmGuideEditInstruction: Scalars['Boolean']['output'];
  evmGuideNewInstruction: Scalars['Boolean']['output'];
  exportTxns?: Maybe<Scalars['String']['output']>;
  generateReport: GenerateReportResult;
  generateTaxDetail: Scalars['Boolean']['output'];
  getUploadURL: UploadUrlData;
  ibcGuideEditInstruction: Scalars['Boolean']['output'];
  ibcGuideNewInstruction: Scalars['Boolean']['output'];
  login: LoginOrSignupResponse;
  loginOrSignupWithGoogle: GoogleLoginOrSignupResponse;
  logout: Scalars['Boolean']['output'];
  markAssetLost: CreatedCredential;
  markInvoiceAsPaid: Scalars['Boolean']['output'];
  markInvoiceAsVoid: Scalars['Boolean']['output'];
  markTxnsSpam: UpdatedTxnResponse;
  mergeTxns: Scalars['Boolean']['output'];
  passwordForgot: Scalars['Boolean']['output'];
  passwordReset: Scalars['Boolean']['output'];
  payInvoice: Scalars['Boolean']['output'];
  processUpload: ProcessUploadResponse;
  quickbooksOauth: Scalars['Boolean']['output'];
  quickbooksSyncData: Scalars['Boolean']['output'];
  recalculateTxnReports?: Maybe<TxnReportJob>;
  refreshToken: Scalars['Boolean']['output'];
  regenCoinbaseCredentials: Scalars['String']['output'];
  resolveUnreconciledTransactions: ResolveUnreconciledTransactionResponse;
  restoreSnapshots: Scalars['Boolean']['output'];
  restoreVersion?: Maybe<Scalars['Boolean']['output']>;
  resyncCredentials: Scalars['Boolean']['output'];
  revertMissingPricesJob: Scalars['Boolean']['output'];
  revertTransactionReconciliation: RevertTransactionReconciliationResponse;
  reviewTxns: UpdatedTxnResponse;
  runCreateAccountsJob: GenerateAccountsJob;
  runCreateMovementsJob: CreateMovementsJob;
  saveCurrentVersion?: Maybe<Scalars['Boolean']['output']>;
  saveSnapshots: Scalars['Boolean']['output'];
  saveSpecId: Scalars['Boolean']['output'];
  sendSupportEmail: Scalars['Boolean']['output'];
  setClientPassword: Scalars['Boolean']['output'];
  signup: LoginOrSignupResponse;
  solanaGuideEditInstruction: Scalars['Boolean']['output'];
  solanaGuideNewInstruction: Scalars['Boolean']['output'];
  syncCovalentWallets: CreatedCredentials;
  toggleDocumentVisibility?: Maybe<DocumentToggleVisibility>;
  updateComment: Scalars['Boolean']['output'];
  updateOauthIntegration: Credential;
  updateReportDetail: UserTaxDetail;
  updateTaxDetails: Scalars['Boolean']['output'];
  updateTxns: UpdatedTxnResponse;
  updateUserTaxDetail: UserTaxDetail;
  updateVersion?: Maybe<UpdateVersionResponse>;
  validateCoupon: CouponValidationResponse;
};

export type MutationActivateAccountArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationActivatePlanFromCheckoutArgs = {
  checkoutSessionId: Scalars['String']['input'];
};

export type MutationAddApiIntegrationArgs = {
  api: Scalars['String']['input'];
  credentials: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddCommentArgs = {
  content: Scalars['String']['input'];
  isPrivate?: Scalars['Boolean']['input'];
  userId: Scalars['Int']['input'];
};

export type MutationAddMissingPricesArgs = {
  credentialIds: Array<Scalars['Int']['input']>;
  plusOrMinusDays?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddOauthIntegrationArgs = {
  api: Scalars['String']['input'];
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type MutationAddTransactionsArgs = {
  forceCreateNewCredential?: InputMaybe<Scalars['Boolean']['input']>;
  txns: Array<CreatedTxnInput>;
};

export type MutationAddUserProfileArgs = {
  userProfileInputData: UserProfileInputData;
};

export type MutationAddWalletsArgs = {
  addresses: Array<AddressWithCurrencyInput>;
  filterSpam?: InputMaybe<Scalars['Boolean']['input']>;
  incomingType: TxnType;
  name?: InputMaybe<Scalars['String']['input']>;
  outgoingType: TxnType;
  reconcileOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationBatchDeleteTxnsArgs = {
  filterQuery?: InputMaybe<TxnFilterQuery>;
};

export type MutationBatchSaveVersionForUsersArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  userIds: Array<Scalars['Int']['input']>;
};

export type MutationBatchUpdateTxnsArgs = {
  filterQuery?: InputMaybe<TxnFilterQuery>;
  txnInput: PartialTxnInput;
};

export type MutationBulkCreateUsersArgs = {
  countryId: Scalars['Int']['input'];
  cryptoOnlyPlan?: InputMaybe<Scalars['String']['input']>;
  fullFilingPlan?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  usernames: Array<Scalars['String']['input']>;
};

export type MutationBuyProductsArgs = {
  addons: Array<ProductInput>;
  cardHolderName?: InputMaybe<Scalars['String']['input']>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  cryptoOnlyPlans: Array<ProductInput>;
  existingSourceId?: InputMaybe<Scalars['String']['input']>;
  fpTrackId?: InputMaybe<Scalars['String']['input']>;
  fullFilingPlans: Array<ProductInput>;
  newSourceTokenId?: InputMaybe<Scalars['String']['input']>;
  subTotal: Scalars['Int']['input'];
};

export type MutationCancelCredentialSyncArgs = {
  credentialIds: Array<Scalars['Int']['input']>;
};

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type MutationChangeUserDataArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  recalcByAccount?: InputMaybe<Scalars['Boolean']['input']>;
  recalcByAccountStartYear?: InputMaybe<Scalars['Int']['input']>;
  reconMode?: InputMaybe<Scalars['Boolean']['input']>;
  secretMode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCloneAccountArgs = {
  userId: Scalars['Int']['input'];
};

export type MutationCreateAccountRowArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationCreateInvoiceArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['Int']['input'];
  hideDefaultCrypto?: InputMaybe<Scalars['Boolean']['input']>;
  lines: Array<InvoiceLine>;
  newUserEmail?: InputMaybe<Scalars['String']['input']>;
  products: InvoiceProductsInput;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateMovementArgs = {
  amount: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  fromAccountId: Scalars['Int']['input'];
  toAccountId: Scalars['Int']['input'];
  txnIds: Array<Scalars['String']['input']>;
};

export type MutationDeleteAccountArgs = {
  userId: Scalars['Int']['input'];
};

export type MutationDeleteAccountRowArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteCommentArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteCredentialsArgs = {
  ids: Array<Scalars['Int']['input']>;
};

export type MutationDeleteDocumentsArgs = {
  documentIds: Array<Scalars['Int']['input']>;
};

export type MutationDeleteMovementsArgs = {
  txnGroupIds: Array<Scalars['Int']['input']>;
};

export type MutationDeleteTxnsArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationDelinkSpecIdsArgs = {
  componentId?: InputMaybe<Scalars['String']['input']>;
  sellId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDuplicateTxnsArgs = {
  count?: Scalars['Int']['input'];
  ids: Array<Scalars['String']['input']>;
};

export type MutationEditAccountRowArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEditApiIntegrationArgs = {
  accountId?: InputMaybe<Scalars['Int']['input']>;
  api?: InputMaybe<Scalars['String']['input']>;
  credentialId: Scalars['Int']['input'];
  credentials?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  skipResync?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationEditWalletArgs = {
  accountId?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  credentialId: Scalars['Int']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Int']['input']>;
  filterSpam?: InputMaybe<Scalars['Boolean']['input']>;
  incomingType?: InputMaybe<TxnType>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  outgoingType?: InputMaybe<TxnType>;
  reconcileOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  skipResync?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationEvmGuideEditInstructionArgs = {
  instructionId: Scalars['Int']['input'];
  newNotes?: InputMaybe<Scalars['String']['input']>;
  newTreatmentId: Scalars['Int']['input'];
};

export type MutationEvmGuideNewInstructionArgs = {
  chain: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
  methodId: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  treatmentId: Scalars['Int']['input'];
};

export type MutationExportTxnsArgs = {
  txnTableInput: TxnTableInput;
};

export type MutationGenerateReportArgs = {
  report: ReportCreateInput;
};

export type MutationGenerateTaxDetailArgs = {
  baseCurrency: Scalars['String']['input'];
  countryId: Scalars['Int']['input'];
  filingStatus?: InputMaybe<FilingStatusOptions>;
  grossIncome?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<StateOptions>;
  taxYear: Scalars['Int']['input'];
};

export type MutationGetUploadUrlArgs = {
  fileName: Scalars['String']['input'];
};

export type MutationIbcGuideEditInstructionArgs = {
  credentialType: Scalars['String']['input'];
  instructionId: Scalars['Int']['input'];
  newNotes?: InputMaybe<Scalars['String']['input']>;
  newTreatmentId: Scalars['Int']['input'];
  transactionHash: Scalars['String']['input'];
};

export type MutationIbcGuideNewInstructionArgs = {
  credentialType: Scalars['String']['input'];
  identifier: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  transactionHash: Scalars['String']['input'];
  treatmentId: Scalars['Int']['input'];
};

export type MutationLoginArgs = {
  captchaResponse: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationLoginOrSignupWithGoogleArgs = {
  token: Scalars['String']['input'];
};

export type MutationMarkAssetLostArgs = {
  nftAddress?: InputMaybe<Scalars['String']['input']>;
  unsoldBuyReportId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationMarkInvoiceAsPaidArgs = {
  cryptocurrency?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['String']['input'];
  method: InvoicePaymentMethod;
  txnHash?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMarkInvoiceAsVoidArgs = {
  invoiceId: Scalars['String']['input'];
};

export type MutationMarkTxnsSpamArgs = {
  ids: Array<Scalars['String']['input']>;
  isSpam: Scalars['Boolean']['input'];
};

export type MutationMergeTxnsArgs = {
  txnIds: Array<Scalars['String']['input']>;
};

export type MutationPasswordForgotArgs = {
  captchaResponse: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type MutationPasswordResetArgs = {
  captchaResponse: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationPayInvoiceArgs = {
  invoiceId: Scalars['String']['input'];
  method: InvoicePaymentMethod;
  stripeToken: Scalars['String']['input'];
};

export type MutationProcessUploadArgs = {
  Bucket: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  Key: Scalars['String']['input'];
  replacedFileId?: InputMaybe<Scalars['Int']['input']>;
  timezone: Scalars['String']['input'];
};

export type MutationQuickbooksOauthArgs = {
  code: Scalars['String']['input'];
  realmId: Scalars['String']['input'];
  redirectPath: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type MutationRecalculateTxnReportsArgs = {
  options?: InputMaybe<RecalculateOptions>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationRegenCoinbaseCredentialsArgs = {
  credentialId: Scalars['Int']['input'];
};

export type MutationResolveUnreconciledTransactionsArgs = {
  unreconciledTransactionInputs: Array<UnreconciledTransactionInput>;
};

export type MutationRestoreSnapshotsArgs = {
  txnReportIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MutationRestoreVersionArgs = {
  adminNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  versionId: Scalars['String']['input'];
};

export type MutationResyncCredentialsArgs = {
  credentialIds: Array<Scalars['Int']['input']>;
  hardResync?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationRevertMissingPricesJobArgs = {
  missingPricesJobId: Scalars['String']['input'];
};

export type MutationRevertTransactionReconciliationArgs = {
  reconciliationId: Scalars['Int']['input'];
};

export type MutationReviewTxnsArgs = {
  ids: Array<Scalars['String']['input']>;
  reviewed: Scalars['Boolean']['input'];
};

export type MutationSaveCurrentVersionArgs = {
  adminNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveSnapshotsArgs = {
  chained?: InputMaybe<Scalars['Boolean']['input']>;
  purgeReportsWhenDone?: InputMaybe<Scalars['Boolean']['input']>;
  txnReportIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MutationSaveSpecIdArgs = {
  componentIds: Array<Scalars['String']['input']>;
  sellId: Scalars['String']['input'];
};

export type MutationSendSupportEmailArgs = {
  exchanges: Array<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  hasStaking?: InputMaybe<Scalars['Boolean']['input']>;
  hasWallet?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['Int']['input'];
};

export type MutationSetClientPasswordArgs = {
  clientId: Scalars['Int']['input'];
  organizationId: Scalars['Int']['input'];
  password: Scalars['String']['input'];
};

export type MutationSignupArgs = {
  captchaResponse: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationSolanaGuideEditInstructionArgs = {
  instructionId: Scalars['Int']['input'];
  newNotes?: InputMaybe<Scalars['String']['input']>;
  newTreatmentId: Scalars['Int']['input'];
  transactionHash: Scalars['String']['input'];
};

export type MutationSolanaGuideNewInstructionArgs = {
  identifier: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  transactionHash: Scalars['String']['input'];
  treatmentId: Scalars['Int']['input'];
};

export type MutationSyncCovalentWalletsArgs = {
  addresses: Array<Scalars['String']['input']>;
  chainIds: Array<Scalars['String']['input']>;
};

export type MutationToggleDocumentVisibilityArgs = {
  documentIds: Array<Scalars['Int']['input']>;
  hidden: Scalars['Boolean']['input'];
};

export type MutationUpdateCommentArgs = {
  content: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  isPrivate?: Scalars['Boolean']['input'];
};

export type MutationUpdateOauthIntegrationArgs = {
  code: Scalars['String']['input'];
  credentialId: Scalars['Int']['input'];
  redirectUri: Scalars['String']['input'];
};

export type MutationUpdateReportDetailArgs = {
  id: Scalars['Int']['input'];
  settings: Settings;
};

export type MutationUpdateTaxDetailsArgs = {
  taxDetails: Array<UpdateTaxDetailsInput>;
  userId: Scalars['Int']['input'];
};

export type MutationUpdateTxnsArgs = {
  txns: Array<TxnInput>;
};

export type MutationUpdateUserTaxDetailArgs = {
  countryId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  locked: Scalars['Boolean']['input'];
  longTermCapitalGainsRate: Scalars['Float']['input'];
  marginalTaxRate: Scalars['Float']['input'];
  method?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateVersionArgs = {
  adminNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  versionId: Scalars['String']['input'];
};

export type MutationValidateCouponArgs = {
  code: Scalars['String']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  colorLink?: Maybe<Scalars['String']['output']>;
  colorPrimary?: Maybe<Scalars['String']['output']>;
  colorSecondary?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  credentials: Array<Credential>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  includeDemoUser?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EnumOrganizationStatus>;
  txnReports: Array<TxnReport>;
  updatedAt: Scalars['DateTime']['output'];
  userOrgRoles: Array<UserOrgRole>;
  users: Array<User>;
};

export enum OtherFilters {
  automaticallyEdited = 'automaticallyEdited',
  hasComments = 'hasComments',
  isSpam = 'isSpam',
  manuallyEdited = 'manuallyEdited',
  missingPrice = 'missingPrice',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  filteredCount?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PaginationOptions = {
  first: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
};

export type PartialTxnInput = {
  accountCredentialId?: InputMaybe<Scalars['Int']['input']>;
  accountId?: InputMaybe<Scalars['Int']['input']>;
  bkpAccountCreditId?: InputMaybe<Scalars['Int']['input']>;
  bkpAccountDebitId?: InputMaybe<Scalars['Int']['input']>;
  bkpVendorId?: InputMaybe<Scalars['Int']['input']>;
  buyAddress?: InputMaybe<Scalars['String']['input']>;
  buyCurrency?: InputMaybe<Scalars['String']['input']>;
  buyPrice?: InputMaybe<Scalars['String']['input']>;
  buyQuantity?: InputMaybe<Scalars['String']['input']>;
  buyTokenId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  feeCurrency?: InputMaybe<Scalars['String']['input']>;
  feePrice?: InputMaybe<Scalars['String']['input']>;
  feeQuantity?: InputMaybe<Scalars['String']['input']>;
  feeTokenId?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isSpam?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  priceFetchingSide?: InputMaybe<PriceFetchingSide>;
  reviewed?: InputMaybe<Scalars['Boolean']['input']>;
  sellAddress?: InputMaybe<Scalars['String']['input']>;
  sellCurrency?: InputMaybe<Scalars['String']['input']>;
  sellPrice?: InputMaybe<Scalars['String']['input']>;
  sellQuantity?: InputMaybe<Scalars['String']['input']>;
  sellTokenId?: InputMaybe<Scalars['String']['input']>;
  toAccountId?: InputMaybe<Scalars['Int']['input']>;
  toCredentialId?: InputMaybe<Scalars['Int']['input']>;
  toIntegrationId?: InputMaybe<Scalars['String']['input']>;
  txnTimestamp?: InputMaybe<Scalars['DateTime']['input']>;
  txnType?: InputMaybe<TxnType>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  products: Products;
  timestamp: Scalars['DateTime']['output'];
};

export type Payments = {
  __typename?: 'Payments';
  edges: Array<Payment>;
  userId: Scalars['Int']['output'];
};

export type Plan = {
  __typename?: 'Plan';
  planId: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type PlanActivationData = {
  __typename?: 'PlanActivationData';
  plan: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export enum PriceFetchingSide {
  buy = 'buy',
  sell = 'sell',
}

export type ProcessUploadResponse = {
  __typename?: 'ProcessUploadResponse';
  requestId: Scalars['String']['output'];
};

export type ProductInput = {
  planId: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type Products = {
  __typename?: 'Products';
  addons?: Maybe<Array<Plan>>;
  cryptoOnlyPlans?: Maybe<Array<Plan>>;
  fullFilingPlans?: Maybe<Array<Plan>>;
};

export type Query = {
  __typename?: 'Query';
  accountBalances: Array<AccountBalanceEntry>;
  accounts: AccountsData;
  available8949PdfYears: Array<Scalars['Int']['output']>;
  bkpAccounts: Array<BkpAccount>;
  bkpVendors: Array<BkpVendor>;
  clients: Array<User>;
  comments: Array<Comment>;
  countries: Array<Country>;
  credential: Credential;
  credentialDownloadUrl: Scalars['String']['output'];
  credentials: Credentials;
  credentialsBeingReconciled: Array<Credential>;
  credentialsCount: CredentialsCount;
  csvUploadStatus: CsvUploadStatus;
  currencies: Array<Currency>;
  dailyBalances: Array<AccountBalanceMap>;
  documentDownloadUrls: DocumentUrls;
  documents: Documents;
  documentsCount: DocumentsCount;
  evmGuideChains: Array<Chain>;
  evmGuideInstruction?: Maybe<EvmInstruction>;
  existsForChain: ExistsForChainResponse;
  findUsers: Array<User>;
  getLatestUserProfile?: Maybe<UserProfileData>;
  getLpTokens: Array<LpToken>;
  hasMovementTxns: Scalars['Boolean']['output'];
  hijackerUser?: Maybe<User>;
  ibcGuideInstruction?: Maybe<IdentifierBasedInstruction>;
  incomeDashboard?: Maybe<Txns>;
  invoice: Invoice;
  invoiceEvents: InvoiceEvents;
  invoices: Invoices;
  isPasswordResetTokenValid: Scalars['Boolean']['output'];
  /** @deprecated This can be done entirely on the client. Please remove when possible */
  isTokenTaxAdmin: Scalars['Boolean']['output'];
  latestBatchDeleteTxnsJobStatus?: Maybe<EnumGenericJobStatus>;
  latestBatchUpdateTxnsJobStatus?: Maybe<EnumGenericJobStatus>;
  latestCloneAccountJob?: Maybe<CloneAccountJob>;
  latestCreateMovementsJob?: Maybe<LatestCreateMovementsJob>;
  latestGenerateAccountsJob?: Maybe<GenerateAccountsJob>;
  latestMissingPricesJob?: Maybe<LatestMissingPricesJob>;
  latestReconGuideTreatmentsJob?: Maybe<ReconGuideTreatmentsJob>;
  latestSnapshotJob?: Maybe<SnapshotJob>;
  latestTxnReportJob?: Maybe<TxnReportJob>;
  latestTxnReports?: Maybe<LatestTxnReports>;
  lineItemDetails: LineItemDetail;
  lineItems: LineItems;
  missingExchanges: MissingExchanges;
  missingPricesData: Array<MissingPricesDetail>;
  missingWallets: MissingWallets;
  missingWalletTxnCount?: Maybe<Array<WalletTxnCount>>;
  movementMatchConstraints: MovementMatchConstraint;
  movementMergeCandidates: MovementMergeCandidates;
  movements: Movements;
  needsRecalc: Scalars['Boolean']['output'];
  payments: Payments;
  paymentSources: Array<CreditCard>;
  price?: Maybe<Scalars['Float']['output']>;
  priceForTxn?: Maybe<BuyOrSellPrice>;
  quickbooksAuthUrl: Scalars['String']['output'];
  reconGuideTreatments: Array<Treatment>;
  report?: Maybe<Report>;
  retrieveCheckout?: Maybe<CheckoutSession>;
  solanaGuideInstruction?: Maybe<IdentifierBasedInstruction>;
  supportedCredentials: SupportedCredentials;
  supportedSelfReconCredentialsCount: SupportedSelfReconCredentialCount;
  symbols: Array<Coin>;
  taxLossHarvestingAssets: TaxLossHarvestingAssets;
  taxLossHarvestingTaxLots: TaxLossHarvestingTaxLots;
  tokenOverridesList: Array<TokenOverrideConf>;
  txn?: Maybe<Txn>;
  txnAllowanceForYear: TxnAllowanceForYear;
  txnExchangeNameCount: Array<TxnExchangeNameCount>;
  txns: Txns;
  unbusyCredentials: Array<Credential>;
  /** @deprecated This query is deprecated. Use `/user/unreconciledTransactions` instead. */
  unreconciledTransactions: UnreconciledTransactions;
  unreconciledTxnsData: Array<UnreconciledTxnsData>;
  uploadDownloadUrl: Scalars['String']['output'];
  uploads: Uploads;
  user?: Maybe<User>;
  userTaxMethods: UserTaxMethods;
  userTxnCount: TxnCount;
  validateMissingWallets: ValidatedMissingWallets;
  versions: VersionsResponse;
};

export type QueryAccountBalancesArgs = {
  input?: InputMaybe<AccountBalancesInput>;
};

export type QueryClientsArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCommentsArgs = {
  userId: Scalars['Int']['input'];
};

export type QueryCredentialArgs = {
  id: Scalars['Int']['input'];
};

export type QueryCredentialDownloadUrlArgs = {
  credentialId: Scalars['Int']['input'];
};

export type QueryCredentialsArgs = {
  credentialTableInput: CredentialTableInput;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCsvUploadStatusArgs = {
  requestId: Scalars['String']['input'];
};

export type QueryDailyBalancesArgs = {
  input?: InputMaybe<AccountBalancesInput>;
};

export type QueryDocumentDownloadUrlsArgs = {
  documentIds: Array<Scalars['Int']['input']>;
};

export type QueryDocumentsArgs = {
  docTableInput: DocTableInput;
};

export type QueryEvmGuideInstructionArgs = {
  chain: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
  methodId: Scalars['String']['input'];
};

export type QueryExistsForChainArgs = {
  addresses: Array<ExistsForChainInput>;
};

export type QueryFindUsersArgs = {
  input: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryHasMovementTxnsArgs = {
  credentialIds: Array<Scalars['Int']['input']>;
};

export type QueryIbcGuideInstructionArgs = {
  identifier: Scalars['String']['input'];
};

export type QueryInvoiceArgs = {
  invoiceId: Scalars['String']['input'];
};

export type QueryInvoiceEventsArgs = {
  invoiceId: Scalars['String']['input'];
};

export type QueryInvoicesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};

export type QueryIsPasswordResetTokenValidArgs = {
  token: Scalars['String']['input'];
};

export type QueryLineItemDetailsArgs = {
  lineItemDetailInput: LineItemDetailInput;
};

export type QueryLineItemsArgs = {
  lineItemInput: LineItemInput;
};

export type QueryMissingWalletTxnCountArgs = {
  wallets: Array<WalletTxnCountInput>;
};

export type QueryMovementMergeCandidatesArgs = {
  movementTableInput: MovementTableInput;
};

export type QueryMovementsArgs = {
  movementTableInput: MovementTableInput;
};

export type QueryPaymentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};

export type QueryPriceArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  credentialType?: InputMaybe<Scalars['String']['input']>;
  date: DateObject;
  symbol: Scalars['String']['input'];
  tokenId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPriceForTxnArgs = {
  buyAddress?: InputMaybe<Scalars['String']['input']>;
  buyTokenId?: InputMaybe<Scalars['String']['input']>;
  credentialType: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  date: DateObject;
  priceFetchingSide?: InputMaybe<PriceFetchingSide>;
  sellAddress?: InputMaybe<Scalars['String']['input']>;
  sellTokenId?: InputMaybe<Scalars['String']['input']>;
  soldCurrency: Scalars['String']['input'];
};

export type QueryQuickbooksAuthUrlArgs = {
  redirectPath: Scalars['String']['input'];
};

export type QueryReportArgs = {
  id: Scalars['Int']['input'];
};

export type QueryRetrieveCheckoutArgs = {
  checkoutSessionId: Scalars['String']['input'];
};

export type QuerySolanaGuideInstructionArgs = {
  identifier: Scalars['String']['input'];
};

export type QueryTaxLossHarvestingAssetsArgs = {
  taxLossHarvestingAssetsInput: TaxLossHarvestingAssetsInput;
};

export type QueryTaxLossHarvestingTaxLotsArgs = {
  taxLossHarvestingTaxLotsInput: TaxLossHarvestingTaxLotsInput;
};

export type QueryTxnArgs = {
  txnId: Scalars['String']['input'];
};

export type QueryTxnAllowanceForYearArgs = {
  year: Scalars['Int']['input'];
};

export type QueryTxnExchangeNameCountArgs = {
  filterQuery?: InputMaybe<TxnFilterQuery>;
};

export type QueryTxnsArgs = {
  txnTableInput: TxnTableInput;
};

export type QueryUnreconciledTransactionsArgs = {
  hashes?: InputMaybe<Array<Scalars['String']['input']>>;
  unreconciledTransactionsTableInput?: InputMaybe<UnreconciledTransactionsTableInput>;
};

export type QueryUnreconciledTxnsDataArgs = {
  credentialType: Scalars['String']['input'];
  txnHashes: Array<Scalars['String']['input']>;
};

export type QueryUploadDownloadUrlArgs = {
  id: Scalars['Int']['input'];
};

export type QueryUploadsArgs = {
  csvOnly?: InputMaybe<Scalars['Boolean']['input']>;
  uploadTableInput: UploadTableInput;
};

export type QueryUserArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryValidateMissingWalletsArgs = {
  missingWallets: Array<MissingWalletValidationInput>;
};

export type RecalculateOptions = {
  allYearOverride?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioOverride?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReconGuideTreatmentsJob = {
  __typename?: 'ReconGuideTreatmentsJob';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  creator: User;
  id: Scalars['String']['output'];
  jobData?: Maybe<ReconGuideTreatmentsJobResults>;
  status: EnumGenericJobStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['Int']['output'];
};

export type ReconGuideTreatmentsJobData = {
  __typename?: 'ReconGuideTreatmentsJobData';
  count: Scalars['Int']['output'];
  treatmentId: Scalars['Int']['output'];
  treatmentName: Scalars['String']['output'];
};

export type ReconGuideTreatmentsJobResults = {
  __typename?: 'ReconGuideTreatmentsJobResults';
  appliedTreatments: Array<ReconGuideTreatmentsJobData>;
  summary?: Maybe<ReconGuideTreatmentsJobResultsSummary>;
};

export type ReconGuideTreatmentsJobResultsSummary = {
  __typename?: 'ReconGuideTreatmentsJobResultsSummary';
  duration: Scalars['Int']['output'];
  txnsReconciled: Scalars['Int']['output'];
  txnTotal: Scalars['Int']['output'];
};

export type Report = {
  __typename?: 'Report';
  createdAt: Scalars['DateTime']['output'];
  createdByAdmin?: Maybe<Scalars['Boolean']['output']>;
  createdById?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  extraCols?: Maybe<Scalars['Boolean']['output']>;
  failedReason?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  margin?: Maybe<Scalars['Boolean']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  reportType: Scalars['String']['output'];
  status: Scalars['String']['output'];
  taxYear?: Maybe<Scalars['String']['output']>;
  txnReport?: Maybe<TxnReport>;
  txnReportId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type ReportCreateInput = {
  aggregated?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  extraCols?: InputMaybe<Scalars['Boolean']['input']>;
  fiatCurrency?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  includeAddresses?: InputMaybe<Scalars['Boolean']['input']>;
  includeUsd?: InputMaybe<Scalars['Boolean']['input']>;
  margin?: InputMaybe<Scalars['Boolean']['input']>;
  method?: InputMaybe<EnumTaxMethod>;
  reportType: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  taxYear?: InputMaybe<Scalars['Int']['input']>;
  useExchangeNames?: InputMaybe<Scalars['Boolean']['input']>;
  useXlsx?: InputMaybe<Scalars['Boolean']['input']>;
  withCurrencies?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ResolutionError = {
  __typename?: 'ResolutionError';
  message: Scalars['String']['output'];
  unreconciledTransactionId: Scalars['String']['output'];
};

export type ResolveUnreconciledTransactionResponse = {
  __typename?: 'ResolveUnreconciledTransactionResponse';
  errors: Array<ResolutionError>;
  resolutions: Array<UnreconciledTransactionResolution>;
};

export type RevertTransactionReconciliationResponse = {
  __typename?: 'RevertTransactionReconciliationResponse';
  txns: Array<Txn>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  roleType: Scalars['String']['output'];
};

export type Settings = {
  baseCurrency?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  filingStatus?: InputMaybe<Scalars['String']['input']>;
  longTermCapitalGainsRate?: InputMaybe<Scalars['Float']['input']>;
  marginalTaxRate?: InputMaybe<Scalars['Float']['input']>;
  method?: InputMaybe<EnumTaxMethod>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type SnapshotJob = {
  __typename?: 'SnapshotJob';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  status: EnumGenericJobStatus;
  txnReportIds: Array<Scalars['Int']['output']>;
  type: SnapshotJobType;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export enum SnapshotJobType {
  restore = 'restore',
  save = 'save',
}

export enum SortDirectionOptions {
  asc = 'asc',
  desc = 'desc',
}

export type SpecIdFilter = {
  sellId?: InputMaybe<Scalars['String']['input']>;
  side?: InputMaybe<SpecIdMatchSide>;
};

export type SpecIdMatch = {
  __typename?: 'SpecIdMatch';
  component: Txn;
  componentId: Scalars['String']['output'];
  componentOrder: Scalars['Int']['output'];
  componentQuantity: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  sell: Txn;
  sellId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export enum SpecIdMatchSide {
  buy = 'buy',
  either = 'either',
  sell = 'sell',
}

export enum StateOptions {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  NYC = 'NYC',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

export type StateTax = {
  __typename?: 'StateTax';
  bracketMax?: Maybe<Scalars['Float']['output']>;
  bracketMin?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deduction?: Maybe<Scalars['Float']['output']>;
  filingStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  personalExemption?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SupportedCredentials = {
  __typename?: 'SupportedCredentials';
  evmCredentialTypes: Array<Scalars['String']['output']>;
  exchangeCredentials: Array<ExchangeCredentials>;
  reconCredentialTypes: Array<Scalars['String']['output']>;
  solanaCredentialTypes: Array<Scalars['String']['output']>;
  supportedWallets: Array<Scalars['String']['output']>;
};

export enum SupportedEcosystem {
  EVM = 'EVM',
  IBC = 'IBC',
  Solana = 'Solana',
}

export type SupportedSelfReconCredentialCount = {
  __typename?: 'SupportedSelfReconCredentialCount';
  count: Scalars['Int']['output'];
};

export type TaxLossHarvestingAsset = {
  __typename?: 'TaxLossHarvestingAsset';
  address?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Float']['output'];
  costBasis: Scalars['Float']['output'];
  currentUnitPrice?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isNft: Scalars['Boolean']['output'];
  lossesAmount?: Maybe<Scalars['Float']['output']>;
  pricePaidPerUnit: Scalars['Float']['output'];
  taxLots: Scalars['Float']['output'];
  token: Scalars['String']['output'];
  totalMarketValue?: Maybe<Scalars['Float']['output']>;
  unrealizedGainLoss?: Maybe<Scalars['Float']['output']>;
};

export type TaxLossHarvestingAssets = {
  __typename?: 'TaxLossHarvestingAssets';
  csv?: Maybe<Scalars['String']['output']>;
  edges: Array<TaxLossHarvestingAsset>;
  pageInfo: PageInfo;
};

export type TaxLossHarvestingAssetsFilters = {
  currency?: InputMaybe<Scalars['String']['input']>;
  gainLoss?: InputMaybe<TaxLossHarvestingGainLossFilterOptions>;
  longShort?: InputMaybe<TaxLossHarvestingLongShortFilterOptions>;
};

export type TaxLossHarvestingAssetsInput = {
  customLinkedNftAddresses?: InputMaybe<Scalars['Json']['input']>;
  customLinkedSymbols?: InputMaybe<Scalars['Json']['input']>;
  customNftPrices?: InputMaybe<Scalars['Json']['input']>;
  customPrices?: InputMaybe<Scalars['Json']['input']>;
  filters?: InputMaybe<TaxLossHarvestingAssetsFilters>;
  isCSV?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<PaginationOptions>;
  sortBy: TaxLossHarvestingAssetsSortOptions;
  sortDirection: SortDirectionOptions;
};

export enum TaxLossHarvestingAssetsSortOptions {
  amount = 'amount',
  costBasis = 'costBasis',
  currentUnitPrice = 'currentUnitPrice',
  lossesAmount = 'lossesAmount',
  pricePaidPerUnit = 'pricePaidPerUnit',
  taxLots = 'taxLots',
  token = 'token',
  totalMarketValue = 'totalMarketValue',
  unrealizedGainLoss = 'unrealizedGainLoss',
}

export enum TaxLossHarvestingGainLossFilterOptions {
  gain = 'gain',
  loss = 'loss',
}

export enum TaxLossHarvestingLongShortFilterOptions {
  long = 'long',
  short = 'short',
}

export type TaxLossHarvestingTaxLot = {
  __typename?: 'TaxLossHarvestingTaxLot';
  amount: Scalars['Float']['output'];
  costBasis: Scalars['Float']['output'];
  currentUnitPrice?: Maybe<Scalars['Float']['output']>;
  dateAcquired: Scalars['DateTime']['output'];
  id: Scalars['BigInt']['output'];
  potentialSavings?: Maybe<Scalars['Float']['output']>;
  timeToLongTerm?: Maybe<Scalars['Float']['output']>;
  token: Scalars['String']['output'];
  unrealizedGainLoss?: Maybe<Scalars['Float']['output']>;
};

export type TaxLossHarvestingTaxLots = {
  __typename?: 'TaxLossHarvestingTaxLots';
  csv?: Maybe<Scalars['String']['output']>;
  edges: Array<TaxLossHarvestingTaxLot>;
  filteredTotals: TaxLossHarvestingTaxLotsFilteredTotals;
  pageInfo: PageInfo;
  totals: TaxLossHarvestingTaxLotsTotals;
};

export type TaxLossHarvestingTaxLotsFilteredTotals = {
  __typename?: 'TaxLossHarvestingTaxLotsFilteredTotals';
  costBasis?: Maybe<Scalars['Float']['output']>;
  potentialSavings?: Maybe<Scalars['Float']['output']>;
  unrealizedGainLoss?: Maybe<Scalars['Float']['output']>;
};

export type TaxLossHarvestingTaxLotsFilters = {
  currency?: InputMaybe<Scalars['String']['input']>;
  gainLoss?: InputMaybe<TaxLossHarvestingGainLossFilterOptions>;
  longShort?: InputMaybe<TaxLossHarvestingLongShortFilterOptions>;
};

export type TaxLossHarvestingTaxLotsInput = {
  customLinkedToken?: InputMaybe<Scalars['String']['input']>;
  customPrice?: InputMaybe<Scalars['Float']['input']>;
  filters?: InputMaybe<TaxLossHarvestingTaxLotsFilters>;
  isCSV?: InputMaybe<Scalars['Boolean']['input']>;
  nftAddress?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<PaginationOptions>;
  sortBy: TaxLossHarvestingTaxLotsSortOptions;
  sortDirection: SortDirectionOptions;
  unsoldBuyReportId?: InputMaybe<Scalars['Int']['input']>;
};

export enum TaxLossHarvestingTaxLotsSortOptions {
  amount = 'amount',
  costBasis = 'costBasis',
  currentUnitPrice = 'currentUnitPrice',
  dateAcquired = 'dateAcquired',
  potentialSavings = 'potentialSavings',
  timeToLongTerm = 'timeToLongTerm',
  token = 'token',
  unrealizedGainLoss = 'unrealizedGainLoss',
}

export type TaxLossHarvestingTaxLotsTotals = {
  __typename?: 'TaxLossHarvestingTaxLotsTotals';
  currentUnitPrice?: Maybe<Scalars['Float']['output']>;
  totalCostBasis?: Maybe<Scalars['Float']['output']>;
  totalMarketValue?: Maybe<Scalars['Float']['output']>;
  totalUnrealizedGainLoss?: Maybe<Scalars['Float']['output']>;
};

export type TaxMethod = {
  __typename?: 'TaxMethod';
  id: EnumTaxMethod;
  label: Scalars['String']['output'];
};

export type TokenOverrideConf = {
  __typename?: 'TokenOverrideConf';
  id: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

export type TransactionCountPerYear = {
  __typename?: 'TransactionCountPerYear';
  count: Scalars['Int']['output'];
  year: Scalars['String']['output'];
};

export type Transfer = {
  __typename?: 'Transfer';
  currency: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['String']['output'];
  tokenId?: Maybe<Scalars['String']['output']>;
  type: TransferType;
};

export type TransferData = {
  __typename?: 'TransferData';
  amount: Scalars['String']['output'];
  contractAddress?: Maybe<Scalars['String']['output']>;
  from: Scalars['String']['output'];
  to: Scalars['String']['output'];
  tokenLogoUrl?: Maybe<Scalars['String']['output']>;
  tokenName?: Maybe<Scalars['String']['output']>;
  tokenSymbol: Scalars['String']['output'];
};

export type TransferInput = {
  currency: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['String']['input'];
  tokenId?: InputMaybe<Scalars['String']['input']>;
  type: TransferType;
};

export type TransfersGroup = {
  __typename?: 'TransfersGroup';
  txns: Array<Maybe<Transfer>>;
};

export type TransfersGroupInput = {
  txns: Array<InputMaybe<TransferInput>>;
};

export enum TransferType {
  borrowed = 'borrowed',
  bought = 'bought',
  bridgedIn = 'bridgedIn',
  bridgedOut = 'bridgedOut',
  earned = 'earned',
  lost = 'lost',
  lpContribution = 'lpContribution',
  lpDistribution = 'lpDistribution',
  lpReceived = 'lpReceived',
  lpWithdrawn = 'lpWithdrawn',
  minted = 'minted',
  paid = 'paid',
  received = 'received',
  repaid = 'repaid',
  sent = 'sent',
  sold = 'sold',
  spam = 'spam',
  spent = 'spent',
}

export type Treatment = {
  __typename?: 'Treatment';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  reconOrder?: Maybe<Scalars['Int']['output']>;
};

export type Txn = {
  __typename?: 'Txn';
  accountCredentialId?: Maybe<Scalars['Int']['output']>;
  accountId?: Maybe<Scalars['Int']['output']>;
  associatedExchangeAddress?: Maybe<Scalars['String']['output']>;
  bkpAccountCredit?: Maybe<BkpAccount>;
  bkpAccountCreditId?: Maybe<Scalars['Int']['output']>;
  bkpAccountDebit?: Maybe<BkpAccount>;
  bkpAccountDebitId?: Maybe<Scalars['Int']['output']>;
  bkpVendor?: Maybe<BkpVendor>;
  bkpVendorId?: Maybe<Scalars['Int']['output']>;
  blocksvcFromAddress?: Maybe<Scalars['String']['output']>;
  blocksvcHash?: Maybe<Scalars['String']['output']>;
  blocksvcMethodId?: Maybe<Scalars['String']['output']>;
  blocksvcProtocol?: Maybe<Scalars['String']['output']>;
  blocksvcToAddress?: Maybe<Scalars['String']['output']>;
  blocksvcWallet?: Maybe<Scalars['String']['output']>;
  buyAddress?: Maybe<Scalars['String']['output']>;
  buyCurrency: Scalars['String']['output'];
  buyNftId?: Maybe<Scalars['String']['output']>;
  buyPrice: Scalars['String']['output'];
  buyQuantity: Scalars['String']['output'];
  buyTokenId?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  credential: Credential;
  credentialId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  editedByReconGuideJob: Scalars['Boolean']['output'];
  exchangeId?: Maybe<Scalars['String']['output']>;
  exchangeName?: Maybe<Scalars['String']['output']>;
  feeAddress?: Maybe<Scalars['String']['output']>;
  feeCurrency?: Maybe<Scalars['String']['output']>;
  feePrice: Scalars['String']['output'];
  feeQuantity?: Maybe<Scalars['Float']['output']>;
  feeTokenId?: Maybe<Scalars['String']['output']>;
  hasMovement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  integrationId?: Maybe<Scalars['String']['output']>;
  isEdited?: Maybe<Scalars['Boolean']['output']>;
  isSpam: Scalars['Boolean']['output'];
  lastUpdatedBy?: Maybe<Scalars['Int']['output']>;
  marginTrade?: Maybe<Scalars['Boolean']['output']>;
  priceFetchingSide?: Maybe<PriceFetchingSide>;
  quantity?: Maybe<Scalars['Float']['output']>;
  reconIdentifier?: Maybe<Scalars['String']['output']>;
  reviewed?: Maybe<Scalars['Boolean']['output']>;
  sellAddress?: Maybe<Scalars['String']['output']>;
  sellCurrency: Scalars['String']['output'];
  sellNftId?: Maybe<Scalars['String']['output']>;
  sellPrice: Scalars['String']['output'];
  sellQuantity: Scalars['String']['output'];
  sellTokenId?: Maybe<Scalars['String']['output']>;
  soldCurrency?: Maybe<Scalars['String']['output']>;
  soldQuantity?: Maybe<Scalars['Float']['output']>;
  specIdMatchesAsComponent: Array<SpecIdMatch>;
  specIdMatchesAsSell: Array<SpecIdMatch>;
  toAccountId?: Maybe<Scalars['Int']['output']>;
  toCredentialId?: Maybe<Scalars['Int']['output']>;
  toIntegrationId?: Maybe<Scalars['String']['output']>;
  txnTimestamp: Scalars['DateTime']['output'];
  txnType: TxnType;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usdSpotPrice?: Maybe<Scalars['Float']['output']>;
  userId: Scalars['Int']['output'];
};

export type TxnAllowanceForYear = {
  __typename?: 'TxnAllowanceForYear';
  allowed: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
};

export type TxnCount = {
  __typename?: 'TxnCount';
  reviewedCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  txnImportTypeCounts: Array<TxnImportTypeCount>;
  txnOtherFiltersCounts: Array<TxnOtherFiltersCount>;
  txnTypeCounts: Array<TxnTypeCount>;
};

export type TxnCurrencyFilter = {
  operator: FilterOperatorTypes;
  side: CurrencyFilterTypes;
  symbols: Array<Scalars['String']['input']>;
};

export type TxnExchangeNameCount = {
  __typename?: 'TxnExchangeNameCount';
  counts: Array<ExchangeNameCount>;
  total: Scalars['Int']['output'];
  year: Scalars['String']['output'];
};

export type TxnFilterQuery = {
  accountCredits?: InputMaybe<MultiSelectIntFilter>;
  accountDebits?: InputMaybe<MultiSelectIntFilter>;
  boolean?: InputMaybe<Array<BooleanFilter>>;
  currency?: InputMaybe<Array<TxnCurrencyFilter>>;
  date?: InputMaybe<DateRangeFilter>;
  editedAt?: InputMaybe<DateRangeFilter>;
  fromAddress?: InputMaybe<Array<TxnSearchFilter>>;
  imports?: InputMaybe<MultiSelectIntFilter>;
  importType?: InputMaybe<MultiSelectStringFilter>;
  location?: InputMaybe<Array<TxnSearchFilter>>;
  methodId?: InputMaybe<Array<TxnSearchFilter>>;
  reconIdentifier?: InputMaybe<Array<TxnSearchFilter>>;
  specId?: InputMaybe<SpecIdFilter>;
  toAddress?: InputMaybe<Array<TxnSearchFilter>>;
  txnHash?: InputMaybe<Array<TxnSearchFilter>>;
  txnType?: InputMaybe<MultiSelectStringFilter>;
  vendors?: InputMaybe<MultiSelectIntFilter>;
};

export type TxnImportTypeCount = {
  __typename?: 'TxnImportTypeCount';
  count: Scalars['Int']['output'];
  importType: Scalars['String']['output'];
};

export type TxnInput = {
  accountCredentialId?: InputMaybe<Scalars['Int']['input']>;
  accountId?: InputMaybe<Scalars['Int']['input']>;
  bkpAccountCreditId?: InputMaybe<Scalars['Int']['input']>;
  bkpAccountDebitId?: InputMaybe<Scalars['Int']['input']>;
  bkpVendorId?: InputMaybe<Scalars['Int']['input']>;
  buyAddress?: InputMaybe<Scalars['String']['input']>;
  buyCurrency: Scalars['String']['input'];
  buyPrice?: InputMaybe<Scalars['String']['input']>;
  buyQuantity: Scalars['String']['input'];
  buyTokenId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  feeCurrency?: InputMaybe<Scalars['String']['input']>;
  feePrice?: InputMaybe<Scalars['String']['input']>;
  feeQuantity?: InputMaybe<Scalars['String']['input']>;
  feeTokenId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isSpam?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  priceFetchingSide?: InputMaybe<PriceFetchingSide>;
  reviewed?: InputMaybe<Scalars['Boolean']['input']>;
  sellAddress?: InputMaybe<Scalars['String']['input']>;
  sellCurrency: Scalars['String']['input'];
  sellPrice?: InputMaybe<Scalars['String']['input']>;
  sellQuantity: Scalars['String']['input'];
  sellTokenId?: InputMaybe<Scalars['String']['input']>;
  toAccountId?: InputMaybe<Scalars['Int']['input']>;
  toCredentialId?: InputMaybe<Scalars['Int']['input']>;
  toIntegrationId?: InputMaybe<Scalars['String']['input']>;
  txnTimestamp: Scalars['DateTime']['input'];
  txnType: TxnType;
};

export type TxnOtherFiltersCount = {
  __typename?: 'TxnOtherFiltersCount';
  count: Scalars['Int']['output'];
  type: OtherFilters;
};

export type TxnReport = {
  __typename?: 'TxnReport';
  costBasisFeeAdditionsLongTerm?: Maybe<Scalars['Float']['output']>;
  costBasisFeeAdditionsShortTerm?: Maybe<Scalars['Float']['output']>;
  costBasisLongTerm?: Maybe<Scalars['Float']['output']>;
  costBasisShortTerm?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  credential?: Maybe<Credential>;
  credentialId?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  enterpriseUserId?: Maybe<Scalars['Int']['output']>;
  exchangeCount?: Maybe<Scalars['Json']['output']>;
  extra?: Maybe<Scalars['Json']['output']>;
  fiatCurrency?: Maybe<Scalars['String']['output']>;
  gainLossIncludingFeesLongTerm?: Maybe<Scalars['Float']['output']>;
  gainLossIncludingFeesShortTerm?: Maybe<Scalars['Float']['output']>;
  gainLossLongTerm?: Maybe<Scalars['Float']['output']>;
  gainLossShortTerm?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  isMargin?: Maybe<Scalars['Boolean']['output']>;
  jobErrorMessage?: Maybe<Scalars['String']['output']>;
  jobProgress?: Maybe<Scalars['Int']['output']>;
  jobStatus?: Maybe<Scalars['String']['output']>;
  likeKind?: Maybe<Scalars['Boolean']['output']>;
  lineItems: Array<LineItem>;
  method: Scalars['String']['output'];
  miningIncome?: Maybe<Scalars['Float']['output']>;
  month?: Maybe<EnumTxnReportMonth>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  proceedsFeeDeductionsLongTerm?: Maybe<Scalars['Float']['output']>;
  proceedsFeeDeductionsShortTerm?: Maybe<Scalars['Float']['output']>;
  proceedsLongTerm?: Maybe<Scalars['Float']['output']>;
  proceedsShortTerm?: Maybe<Scalars['Float']['output']>;
  reports: Array<Report>;
  reportType?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  taxMethod: TaxMethod;
  txnCount?: Maybe<Scalars['Int']['output']>;
  txnReportJob?: Maybe<TxnReportJob>;
  txnReportJobId?: Maybe<Scalars['Int']['output']>;
  txnReportTxnReportJobs: Array<TxnReportTxnReportJob>;
  unsoldBuys: Array<UnsoldBuy>;
  unsoldBuySequence?: Maybe<UnsoldBuySequence>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']['output']>;
  userTaxDetail?: Maybe<UserTaxDetail>;
  userTaxDetailId: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type TxnReportJob = {
  __typename?: 'TxnReportJob';
  createdAt: Scalars['DateTime']['output'];
  credential?: Maybe<Credential>;
  credentialId?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  enterpriseUserId?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  exchangeCount?: Maybe<Scalars['Json']['output']>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  includesDailyUnsoldBuyReport?: Maybe<Scalars['Boolean']['output']>;
  jobStatus: TxnReportJobStatus;
  status?: Maybe<Scalars['String']['output']>;
  txnCount?: Maybe<Scalars['Int']['output']>;
  txnReports: Array<TxnReport>;
  txnReportTxnReportJobs: Array<TxnReportTxnReportJob>;
  unsoldBuySequences: Array<UnsoldBuySequence>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export enum TxnReportJobStatus {
  completed = 'completed',
  created = 'created',
  failed = 'failed',
}

export type TxnReportTxnReportJob = {
  __typename?: 'TxnReportTxnReportJob';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  txnReport: TxnReport;
  txnReportId: Scalars['Int']['output'];
  txnReportJob: TxnReportJob;
  txnReportJobId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TxnReportValues = {
  __typename?: 'TxnReportValues';
  gainLoss: Scalars['Float']['output'];
  income: Scalars['Float']['output'];
  longTermGainLoss: Scalars['Float']['output'];
  shortTermGainLoss: Scalars['Float']['output'];
  taxLiability: Scalars['Float']['output'];
};

export type Txns = {
  __typename?: 'Txns';
  csv?: Maybe<Scalars['String']['output']>;
  edges: Array<Txn>;
  pageInfo: PageInfo;
};

export type TxnSearchFilter = {
  key: TxnSearchFilterKeys;
  operator: FilterOperatorTypes;
  value: Scalars['String']['input'];
};

export enum TxnSearchFilterKeys {
  fromAddress = 'fromAddress',
  location = 'location',
  methodId = 'methodId',
  reconIdentifier = 'reconIdentifier',
  toAddress = 'toAddress',
  txnHash = 'txnHash',
}

export enum TxnSortOptions {
  blocksvcHash = 'blocksvcHash',
  blocksvcMethodId = 'blocksvcMethodId',
  blocksvcToAddress = 'blocksvcToAddress',
  credentialName = 'credentialName',
  currency = 'currency',
  exchangeName = 'exchangeName',
  feeCurrency = 'feeCurrency',
  feeQuantity = 'feeQuantity',
  quantity = 'quantity',
  reconIdentifier = 'reconIdentifier',
  soldCurrency = 'soldCurrency',
  soldQuantity = 'soldQuantity',
  txnTimestamp = 'txnTimestamp',
  txnType = 'txnType',
  unitPrice = 'unitPrice',
  updatedAt = 'updatedAt',
  usdSpotPrice = 'usdSpotPrice',
}

export type TxnTableInput = {
  filterQuery?: InputMaybe<TxnFilterQuery>;
  pagination?: InputMaybe<PaginationOptions>;
  sortBy: TxnSortOptions;
  sortDirection: SortDirectionOptions;
};

export enum TxnType {
  airdrop = 'airdrop',
  borrow = 'borrow',
  deposit = 'deposit',
  fork = 'fork',
  gift = 'gift',
  income = 'income',
  lost = 'lost',
  migration = 'migration',
  mining = 'mining',
  movement = 'movement',
  repay = 'repay',
  spend = 'spend',
  staking = 'staking',
  stolen = 'stolen',
  trade = 'trade',
  withdrawal = 'withdrawal',
}

export type TxnTypeCount = {
  __typename?: 'TxnTypeCount';
  count: Scalars['Int']['output'];
  type: TxnType;
};

export type UnreconciledTransaction = {
  __typename?: 'UnreconciledTransaction';
  contractPublicName?: Maybe<Scalars['String']['output']>;
  credentials: Array<UnreconciledTxnCredential>;
  ecosystem: SupportedEcosystem;
  editedAt?: Maybe<Scalars['DateTime']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  id: Scalars['String']['output'];
  instruction?: Maybe<Instruction>;
  invalidated: Scalars['Boolean']['output'];
  method: UnreconciledTransactionMethod;
  receivedTxnsCount: Scalars['Int']['output'];
  reconIdentifier?: Maybe<Scalars['String']['output']>;
  resolutionCategory?: Maybe<Scalars['String']['output']>;
  resolutionId?: Maybe<Scalars['Int']['output']>;
  resolved: Scalars['Boolean']['output'];
  sentTxnsCount: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
  to?: Maybe<Scalars['String']['output']>;
  transfers?: Maybe<Array<TransferData>>;
};

export type UnreconciledTransactionInput = {
  blocksvcHash: Scalars['String']['input'];
  category: CategoryInput;
  credentialType: Scalars['String']['input'];
  drafts: Array<DraftInput>;
  edited: Scalars['Boolean']['input'];
};

export type UnreconciledTransactionMethod = {
  __typename?: 'UnreconciledTransactionMethod';
  hash: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UnreconciledTransactionResolution = {
  __typename?: 'UnreconciledTransactionResolution';
  appliedAt: Scalars['DateTime']['output'];
  blocksvcHash: Scalars['String']['output'];
  category: Scalars['String']['output'];
  credentialType: Scalars['String']['output'];
  drafts: Array<Draft>;
  edited: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  invalidatedAt?: Maybe<Scalars['DateTime']['output']>;
  revertedAt?: Maybe<Scalars['DateTime']['output']>;
  unreconciledTransactionId: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type UnreconciledTransactions = {
  __typename?: 'UnreconciledTransactions';
  edges: Array<UnreconciledTransaction>;
  pageInfo: PageInfo;
};

export enum UnreconciledTransactionsSortOptions {
  credential = 'credential',
  editedAt = 'editedAt',
  hash = 'hash',
  priority = 'priority',
  status = 'status',
  timestamp = 'timestamp',
  wallet = 'wallet',
}

export type UnreconciledTransactionsTableInput = {
  filterQuery?: InputMaybe<UnreconciledTxnsFiltersQuery>;
  pagination?: InputMaybe<PaginationOptions>;
  sortBy: UnreconciledTransactionsSortOptions;
  sortDirection: SortDirectionOptions;
};

export type UnreconciledTxnCredential = {
  __typename?: 'UnreconciledTxnCredential';
  address?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  source: CredentialSource;
  type: Scalars['String']['output'];
};

export type UnreconciledTxnsData = {
  __typename?: 'UnreconciledTxnsData';
  ecosystem: SupportedEcosystem;
  txnHash: Scalars['String']['output'];
  txns: Array<Txn>;
};

export type UnreconciledTxnsFiltersQuery = {
  date?: InputMaybe<DateRangeFilter>;
  imports?: InputMaybe<MultiSelectIntFilter>;
  status?: InputMaybe<MultiSelectStringFilter>;
};

export type UnsoldBuy = {
  __typename?: 'UnsoldBuy';
  buyDate: Scalars['DateTime']['output'];
  buyId: Scalars['String']['output'];
  coefficientPercent?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['BigInt']['output'];
  txn: Txn;
  txnReport?: Maybe<TxnReport>;
  txnReportId?: Maybe<Scalars['Int']['output']>;
  unitsRemaining: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  usdEquivalentRemaining: Scalars['Float']['output'];
};

export type UnsoldBuyReport = {
  __typename?: 'UnsoldBuyReport';
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  unsoldBuySequence?: Maybe<UnsoldBuySequence>;
  unsoldBuySequenceId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UnsoldBuySequence = {
  __typename?: 'UnsoldBuySequence';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  marginTradeAccount?: Maybe<Scalars['Boolean']['output']>;
  method: Scalars['String']['output'];
  txnReport: TxnReport;
  txnReportId: Scalars['Int']['output'];
  txnReportJob: TxnReportJob;
  txnReportJobId: Scalars['Int']['output'];
  unsoldBuyReports: Array<UnsoldBuyReport>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type UnsupportedKnownCsv = {
  __typename?: 'UnsupportedKnownCsv';
  exchange: Scalars['String']['output'];
  expectedFile: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type UpdatedCredential = {
  __typename?: 'UpdatedCredential';
  credential: Credential;
  exchangeServiceRequest?: Maybe<ExchangeServiceRequest>;
};

export type UpdatedTxnResponse = {
  __typename?: 'UpdatedTxnResponse';
  count: Scalars['Int']['output'];
  errors: Array<FailedTransactionUpdate>;
  updatedTxns: Array<Txn>;
};

export type UpdateTaxDetailsInput = {
  calculateAsMargin?: InputMaybe<Scalars['Boolean']['input']>;
  fullFilingPlan?: InputMaybe<Scalars['String']['input']>;
  isEngaged?: InputMaybe<Scalars['Boolean']['input']>;
  likeKind?: InputMaybe<Scalars['Boolean']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  taxYear: Scalars['Int']['input'];
  useHoursMinsComparison?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateVersionResponse = {
  __typename?: 'UpdateVersionResponse';
  items: Array<Version>;
};

export type Upload = {
  __typename?: 'Upload';
  createdAt: Scalars['DateTime']['output'];
  credential?: Maybe<Credential>;
  credentialId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isHidden: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploadType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type Uploads = {
  __typename?: 'Uploads';
  csv?: Maybe<Scalars['String']['output']>;
  edges: Array<Upload>;
  pageInfo: PageInfo;
};

export enum UploadSortOptions {
  createdAt = 'createdAt',
  name = 'name',
}

export type UploadTableInput = {
  pagination?: InputMaybe<PaginationOptions>;
  sortBy: UploadSortOptions;
  sortDirection: SortDirectionOptions;
};

export type UploadUrlData = {
  __typename?: 'UploadUrlData';
  Bucket: Scalars['String']['output'];
  Key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  accountYears: Array<Scalars['Int']['output']>;
  airtableId?: Maybe<Scalars['String']['output']>;
  clonedUser?: Maybe<User>;
  clones?: Maybe<Array<User>>;
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  includeFees?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastSuccessfulCharge?: Maybe<Scalars['DateTime']['output']>;
  lockedTilDate?: Maybe<Scalars['DateTime']['output']>;
  loginCount?: Maybe<Scalars['Int']['output']>;
  marginTradeAccount?: Maybe<Scalars['Boolean']['output']>;
  minimizationForAllYears?: Maybe<Scalars['Boolean']['output']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  orgDemoUser?: Maybe<Scalars['Boolean']['output']>;
  orgRoles: Array<UserOrgRole>;
  phone?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  recalcByAccount?: Maybe<Scalars['Boolean']['output']>;
  recalcByAccountStartYear?: Maybe<Scalars['Int']['output']>;
  reconMode?: Maybe<Scalars['Boolean']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  resetPasswordExpires?: Maybe<Scalars['DateTime']['output']>;
  resetPasswordToken?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  secretMode?: Maybe<Scalars['Boolean']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  taxDetails: Array<UserTaxDetail>;
  updatedAt: Scalars['DateTime']['output'];
  useHoursMinsComparison?: Maybe<Scalars['Boolean']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

export type UserOrgRole = {
  __typename?: 'UserOrgRole';
  createdAt: Scalars['DateTime']['output'];
  organization: Organization;
  organizationId: Scalars['Int']['output'];
  role: Role;
  roleId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Int']['output'];
};

export type UserProfileData = {
  __typename?: 'UserProfileData';
  data: Scalars['Json']['output'];
  touchedSections: Array<Scalars['String']['output']>;
};

export type UserProfileInputData = {
  data: Scalars['Json']['input'];
  touchedSections: Array<Scalars['String']['input']>;
};

export type UserTaxDetail = {
  __typename?: 'UserTaxDetail';
  addons: Array<UserTaxDetailAddon>;
  baseCurrency?: Maybe<Scalars['String']['output']>;
  calculateAsMargin?: Maybe<Scalars['Boolean']['output']>;
  country: Country;
  countryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  dateRange?: Maybe<DateRange>;
  filingStatus?: Maybe<Scalars['String']['output']>;
  fullFilingPlan?: Maybe<Scalars['String']['output']>;
  grossIncome?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  isEngaged: Scalars['Boolean']['output'];
  likeKind?: Maybe<Scalars['Boolean']['output']>;
  locked: Scalars['Boolean']['output'];
  longTermCapitalGainsRate?: Maybe<Scalars['Float']['output']>;
  marginalTaxRate?: Maybe<Scalars['Float']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  needsFbar?: Maybe<Scalars['Boolean']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  setFilingMethodSkipped?: Maybe<Scalars['Boolean']['output']>;
  skipped?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  taxMethod?: Maybe<TaxMethod>;
  taxYear: Scalars['Int']['output'];
  ukBnbRule?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  useHoursMinsComparison?: Maybe<Scalars['Boolean']['output']>;
  userId: Scalars['Int']['output'];
};

export type UserTaxDetailAddon = {
  __typename?: 'UserTaxDetailAddon';
  addon: Addon;
  addonId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userTaxDetail: UserTaxDetail;
  userTaxDetailId: Scalars['Int']['output'];
};

export type UserTaxMethods = {
  __typename?: 'UserTaxMethods';
  methods: Array<TaxMethod>;
};

export type ValidatedMissingWallets = {
  __typename?: 'ValidatedMissingWallets';
  edges: Array<MissingWalletWithTxnValidation>;
};

export type Version = {
  __typename?: 'Version';
  adminNotes?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  txnReportDataAverageCost?: Maybe<Scalars['Json']['output']>;
  txnReportDataFifo?: Maybe<Scalars['Json']['output']>;
  txnReportDataLifo?: Maybe<Scalars['Json']['output']>;
  txnReportDataMinimization?: Maybe<Scalars['Json']['output']>;
};

export type VersionsResponse = {
  __typename?: 'VersionsResponse';
  items: Array<Version>;
  status: VersionsStatus;
};

export type VersionsStatus = {
  __typename?: 'VersionsStatus';
  isRestoringVersion: Scalars['Boolean']['output'];
  isSavingCurrentVersion: Scalars['Boolean']['output'];
  lastRestoredAt?: Maybe<Scalars['DateTime']['output']>;
  latestStatus?: Maybe<EnumGenericJobStatus>;
  restoredVersionNumber?: Maybe<Scalars['Int']['output']>;
};

export type WalletTxnCount = {
  __typename?: 'WalletTxnCount';
  address: Scalars['String']['output'];
  chain: Scalars['String']['output'];
  count?: Maybe<Scalars['Int']['output']>;
  earliestTxnDate?: Maybe<Scalars['String']['output']>;
  latestTxnDate?: Maybe<Scalars['String']['output']>;
};

export type WalletTxnCountInput = {
  address: Scalars['String']['input'];
  chain: Scalars['String']['input'];
};

export type YearCount = {
  __typename?: 'YearCount';
  count: Scalars['Int']['output'];
  taxYear: Scalars['String']['output'];
};

export type IsPasswordResetTokenValidQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type IsPasswordResetTokenValidQuery = { __typename?: 'Query'; isPasswordResetTokenValid: boolean };

export type ActivateAccountMutationVariables = Exact<{
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;

export type ActivateAccountMutation = {
  __typename?: 'Mutation';
  activateAccount: {
    __typename?: 'LoginOrSignupResponse';
    userHash: string;
    user: { __typename?: 'User'; id: number };
  };
};

export type ActivatePlanFromCheckoutMutationVariables = Exact<{
  checkoutSessionId: Scalars['String']['input'];
}>;

export type ActivatePlanFromCheckoutMutation = {
  __typename?: 'Mutation';
  activatePlanFromCheckout: { __typename?: 'PlanActivationData'; plan: string; year: number; price: number };
};

export type RetrieveCheckoutQueryVariables = Exact<{
  checkoutSessionId: Scalars['String']['input'];
}>;

export type RetrieveCheckoutQuery = {
  __typename?: 'Query';
  retrieveCheckout?: {
    __typename?: 'CheckoutSession';
    email: string;
    activated: boolean;
    plan: string;
    emailHasMatchingAccount: boolean;
  } | null;
};

export type GetUserInfoQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type GetUserInfoQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: number;
    accountYears: Array<number>;
    airtableId?: string | null;
    createdAt: any;
    customerId?: string | null;
    deletedAt?: any | null;
    email: string;
    firstName?: string | null;
    includeFees?: boolean | null;
    secretMode?: boolean | null;
    isTest?: boolean | null;
    lastName?: string | null;
    lastSuccessfulCharge?: any | null;
    lockedTilDate?: any | null;
    loginCount?: number | null;
    marginTradeAccount?: boolean | null;
    minimizationForAllYears?: boolean | null;
    orgDemoUser?: boolean | null;
    organizationId?: number | null;
    phone?: string | null;
    plan?: string | null;
    recalcByAccount?: boolean | null;
    recalcByAccountStartYear?: number | null;
    reconMode?: boolean | null;
    resetPasswordExpires?: any | null;
    resetPasswordToken?: string | null;
    role?: string | null;
    subscriptionId?: string | null;
    updatedAt: any;
    useHoursMinsComparison?: boolean | null;
    verified?: boolean | null;
    clonedUser?: { __typename?: 'User'; id: number; email: string } | null;
    clones?: Array<{ __typename?: 'User'; id: number; email: string; createdAt: any }> | null;
    orgRoles: Array<{
      __typename?: 'UserOrgRole';
      userId: number;
      roleId: number;
      organizationId: number;
      createdAt: any;
      updatedAt: any;
      organization: {
        __typename?: 'Organization';
        id: number;
        name?: string | null;
        slug?: string | null;
        status?: EnumOrganizationStatus | null;
        includeDemoUser?: boolean | null;
        discount?: number | null;
        logo?: string | null;
        colorPrimary?: string | null;
        colorSecondary?: string | null;
        colorLink?: string | null;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
      };
      role: { __typename?: 'Role'; name: string };
    }>;
    taxDetails: Array<{
      __typename?: 'UserTaxDetail';
      id: number;
      baseCurrency?: string | null;
      calculateAsMargin?: boolean | null;
      createdAt: any;
      filingStatus?: string | null;
      fullFilingPlan?: string | null;
      grossIncome?: number | null;
      likeKind?: boolean | null;
      longTermCapitalGainsRate?: number | null;
      marginalTaxRate?: number | null;
      method?: string | null;
      needsFbar?: boolean | null;
      plan?: string | null;
      setFilingMethodSkipped?: boolean | null;
      skipped?: boolean | null;
      state?: string | null;
      taxYear: number;
      ukBnbRule?: boolean | null;
      updatedAt: any;
      useHoursMinsComparison?: boolean | null;
      userId: number;
      locked: boolean;
      isEngaged: boolean;
      addons: Array<{
        __typename?: 'UserTaxDetailAddon';
        id: number;
        addon: {
          __typename?: 'Addon';
          createdAt: any;
          displayName: string;
          price?: number | null;
          product: string;
          sku: string;
          updatedAt: any;
        };
      }>;
      country: {
        __typename?: 'Country';
        id: number;
        endDateDay: number;
        endDateMonth: number;
        name: string;
        shorthand: string;
        methods: Array<string>;
        startDateDay: number;
        startDateMonth: number;
        taxMethods: Array<{ __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string }>;
      };
      dateRange?: { __typename?: 'DateRange'; startDate: string; endDate: string } | null;
      taxMethod?: { __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string } | null;
    }>;
  } | null;
};

export type BatchSaveVersionForUsersMutationVariables = Exact<{
  userIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type BatchSaveVersionForUsersMutation = {
  __typename?: 'Mutation';
  batchSaveVersionForUsers?: boolean | null;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  __typename?: 'Query';
  countries: Array<{ __typename?: 'Country'; id: number; name: string; shorthand: string }>;
};

export type BulkCreateUsersMutationVariables = Exact<{
  usernames: Array<Scalars['String']['input']> | Scalars['String']['input'];
  password: Scalars['String']['input'];
  cryptoOnlyPlan?: InputMaybe<Scalars['String']['input']>;
  fullFilingPlan?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['Int']['input'];
}>;

export type BulkCreateUsersMutation = { __typename?: 'Mutation'; bulkCreateUsers: boolean };

export type CommentsQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type CommentsQuery = {
  __typename?: 'Query';
  comments: Array<{
    __typename?: 'Comment';
    id: number;
    userId: number;
    userIdTopic: number;
    content: string;
    createdAt: any;
    updatedAt: any;
    isPrivate: boolean;
    author: { __typename?: 'User'; id: number; email: string };
  }>;
};

export type AddCommentMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  content: Scalars['String']['input'];
  isPrivate: Scalars['Boolean']['input'];
}>;

export type AddCommentMutation = { __typename?: 'Mutation'; addComment: boolean };

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  content: Scalars['String']['input'];
  isPrivate: Scalars['Boolean']['input'];
}>;

export type UpdateCommentMutation = { __typename?: 'Mutation'; updateComment: boolean };

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteCommentMutation = { __typename?: 'Mutation'; deleteComment: boolean };

export type SendSupportEmailMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  exchanges: Array<Scalars['String']['input']> | Scalars['String']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  hasWallet?: InputMaybe<Scalars['Boolean']['input']>;
  hasStaking?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SendSupportEmailMutation = { __typename?: 'Mutation'; sendSupportEmail: boolean };

export type CloneAccountMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type CloneAccountMutation = {
  __typename?: 'Mutation';
  cloneAccount: { __typename?: 'ClonedAccountResponse'; cloneAccountJobId: number };
};

export type DeleteAccountMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type DeleteAccountMutation = { __typename?: 'Mutation'; deleteAccount: boolean };

export type LatestCloneAccountJobQueryVariables = Exact<{ [key: string]: never }>;

export type LatestCloneAccountJobQuery = {
  __typename?: 'Query';
  latestCloneAccountJob?: {
    __typename?: 'CloneAccountJob';
    id: number;
    sourceId: number;
    cloneId?: number | null;
    status: EnumGenericJobStatus;
    createdAt: any;
    updatedAt?: any | null;
  } | null;
};

export type InvoicesQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type InvoicesQuery = {
  __typename?: 'Query';
  invoices: {
    __typename?: 'Invoices';
    edges: Array<{
      __typename?: 'Invoice';
      id: string;
      userId: number;
      number?: string | null;
      created: number;
      dueDate?: number | null;
      currency: string;
      paid: boolean;
      voided: boolean;
      amountRemaining: number;
      description?: string | null;
      createdBy?: string | null;
    }>;
  };
};

export type PaymentsQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type PaymentsQuery = {
  __typename?: 'Query';
  payments: {
    __typename?: 'Payments';
    edges: Array<{
      __typename?: 'Payment';
      id: string;
      timestamp: any;
      amount: number;
      products: {
        __typename?: 'Products';
        cryptoOnlyPlans?: Array<{ __typename?: 'Plan'; planId: string; year: number }> | null;
        fullFilingPlans?: Array<{ __typename?: 'Plan'; planId: string; year: number }> | null;
        addons?: Array<{ __typename?: 'Plan'; planId: string; year: number }> | null;
      };
    }>;
  };
};

export type FindUsersQueryVariables = Exact<{
  input: Scalars['String']['input'];
}>;

export type FindUsersQuery = {
  __typename?: 'Query';
  findUsers: Array<{
    __typename?: 'User';
    id: number;
    accountYears: Array<number>;
    airtableId?: string | null;
    createdAt: any;
    customerId?: string | null;
    deletedAt?: any | null;
    email: string;
    firstName?: string | null;
    includeFees?: boolean | null;
    secretMode?: boolean | null;
    isTest?: boolean | null;
    lastName?: string | null;
    lastSuccessfulCharge?: any | null;
    lockedTilDate?: any | null;
    loginCount?: number | null;
    marginTradeAccount?: boolean | null;
    minimizationForAllYears?: boolean | null;
    orgDemoUser?: boolean | null;
    organizationId?: number | null;
    phone?: string | null;
    plan?: string | null;
    recalcByAccount?: boolean | null;
    recalcByAccountStartYear?: number | null;
    reconMode?: boolean | null;
    resetPasswordExpires?: any | null;
    resetPasswordToken?: string | null;
    role?: string | null;
    subscriptionId?: string | null;
    updatedAt: any;
    useHoursMinsComparison?: boolean | null;
    verified?: boolean | null;
    clonedUser?: { __typename?: 'User'; id: number; email: string } | null;
    clones?: Array<{ __typename?: 'User'; id: number; email: string; createdAt: any }> | null;
    orgRoles: Array<{
      __typename?: 'UserOrgRole';
      userId: number;
      roleId: number;
      organizationId: number;
      createdAt: any;
      updatedAt: any;
      organization: {
        __typename?: 'Organization';
        id: number;
        name?: string | null;
        slug?: string | null;
        status?: EnumOrganizationStatus | null;
        includeDemoUser?: boolean | null;
        discount?: number | null;
        logo?: string | null;
        colorPrimary?: string | null;
        colorSecondary?: string | null;
        colorLink?: string | null;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
      };
      role: { __typename?: 'Role'; name: string };
    }>;
    taxDetails: Array<{
      __typename?: 'UserTaxDetail';
      id: number;
      baseCurrency?: string | null;
      calculateAsMargin?: boolean | null;
      createdAt: any;
      filingStatus?: string | null;
      fullFilingPlan?: string | null;
      grossIncome?: number | null;
      likeKind?: boolean | null;
      longTermCapitalGainsRate?: number | null;
      marginalTaxRate?: number | null;
      method?: string | null;
      needsFbar?: boolean | null;
      plan?: string | null;
      setFilingMethodSkipped?: boolean | null;
      skipped?: boolean | null;
      state?: string | null;
      taxYear: number;
      ukBnbRule?: boolean | null;
      updatedAt: any;
      useHoursMinsComparison?: boolean | null;
      userId: number;
      locked: boolean;
      isEngaged: boolean;
      addons: Array<{
        __typename?: 'UserTaxDetailAddon';
        id: number;
        addon: {
          __typename?: 'Addon';
          createdAt: any;
          displayName: string;
          price?: number | null;
          product: string;
          sku: string;
          updatedAt: any;
        };
      }>;
      country: {
        __typename?: 'Country';
        id: number;
        endDateDay: number;
        endDateMonth: number;
        name: string;
        shorthand: string;
        methods: Array<string>;
        startDateDay: number;
        startDateMonth: number;
        taxMethods: Array<{ __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string }>;
      };
      dateRange?: { __typename?: 'DateRange'; startDate: string; endDate: string } | null;
      taxMethod?: { __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string } | null;
    }>;
  }>;
};

export type DuplicateTxnsMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  count: Scalars['Int']['input'];
}>;

export type DuplicateTxnsMutation = { __typename?: 'Mutation'; duplicateTxns: boolean };

export type SpecIdTxnQueryVariables = Exact<{
  sellId: Scalars['String']['input'];
}>;

export type SpecIdTxnQuery = {
  __typename?: 'Query';
  txn?: {
    __typename?: 'Txn';
    id: string;
    sellQuantity: string;
    sellCurrency: string;
    sellPrice: string;
    feeQuantity?: number | null;
    feeCurrency?: string | null;
    feePrice: string;
    txnTimestamp: any;
    blocksvcHash?: string | null;
    specIdMatchesAsSell: Array<{
      __typename?: 'SpecIdMatch';
      componentId: string;
      sellId: string;
      componentQuantity: number;
      componentOrder: number;
      component: {
        __typename?: 'Txn';
        buyCurrency: string;
        buyQuantity: string;
        buyPrice: string;
        txnTimestamp: any;
        feeCurrency?: string | null;
        feeQuantity?: number | null;
        feePrice: string;
        specIdMatchesAsComponent: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
      };
    }>;
  } | null;
};

export type CredentialsBeingReconciledQueryVariables = Exact<{ [key: string]: never }>;

export type CredentialsBeingReconciledQuery = {
  __typename?: 'Query';
  credentialsBeingReconciled: Array<{ __typename?: 'Credential'; id: number }>;
};

export type SaveSpecIdMutationVariables = Exact<{
  sellId: Scalars['String']['input'];
  componentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type SaveSpecIdMutation = { __typename?: 'Mutation'; saveSpecId: boolean };

export type CountsQueryVariables = Exact<{
  filterQuery: TxnFilterQuery;
}>;

export type CountsQuery = {
  __typename?: 'Query';
  txnExchangeNameCount: Array<{
    __typename?: 'TxnExchangeNameCount';
    year: string;
    total: number;
    counts: Array<{ __typename?: 'ExchangeNameCount'; count: number; exchangeName: string }>;
  }>;
};

export type DeleteTxnsMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type DeleteTxnsMutation = { __typename?: 'Mutation'; deleteTxns: number };

export type BatchDeleteTxnsMutationVariables = Exact<{
  filterQuery: TxnFilterQuery;
}>;

export type BatchDeleteTxnsMutation = { __typename?: 'Mutation'; batchDeleteTxns: boolean };

export type TxnsQueryVariables = Exact<{
  pagination: PaginationOptions;
  sortBy: TxnSortOptions;
  sortDirection: SortDirectionOptions;
  filterQuery: TxnFilterQuery;
  isAdmin: Scalars['Boolean']['input'];
}>;

export type TxnsQuery = {
  __typename?: 'Query';
  txns: {
    __typename?: 'Txns';
    pageInfo: { __typename?: 'PageInfo'; filteredCount?: number | null };
    edges: Array<{
      __typename?: 'Txn';
      id: string;
      associatedExchangeAddress?: string | null;
      blocksvcHash?: string | null;
      credentialId?: number | null;
      buyCurrency: string;
      buyTokenId?: string | null;
      buyAddress?: string | null;
      buyNftId?: string | null;
      description?: string | null;
      exchangeId?: string | null;
      exchangeName?: string | null;
      feeCurrency?: string | null;
      feeTokenId?: string | null;
      feeQuantity?: number | null;
      feePrice: string;
      feeAddress?: string | null;
      buyPrice: string;
      sellPrice: string;
      buyQuantity: string;
      sellCurrency: string;
      sellTokenId?: string | null;
      sellAddress?: string | null;
      sellNftId?: string | null;
      sellQuantity: string;
      txnTimestamp: any;
      txnType: TxnType;
      unitPrice?: number | null;
      priceFetchingSide?: PriceFetchingSide | null;
      usdSpotPrice?: number | null;
      blocksvcMethodId?: string | null;
      blocksvcToAddress?: string | null;
      blocksvcFromAddress?: string | null;
      createdAt: any;
      updatedAt: any;
      isEdited?: boolean | null;
      isSpam: boolean;
      reviewed?: boolean | null;
      editedByReconGuideJob: boolean;
      reconIdentifier?: string | null;
      bkpVendorId?: number | null;
      integrationId?: string | null;
      toIntegrationId?: string | null;
      bkpAccountDebitId?: number | null;
      bkpAccountCreditId?: number | null;
      accountId?: number | null;
      toAccountId?: number | null;
      hasMovement?: boolean | null;
      bkpVendor?: {
        __typename?: 'BkpVendor';
        id: number;
        bkpIntegrationId?: string | null;
        bkpIntegrationDisplayName?: string | null;
      } | null;
      bkpAccountDebit?: {
        __typename?: 'BkpAccount';
        id: number;
        bkpIntegrationId?: string | null;
        name?: string | null;
      } | null;
      bkpAccountCredit?: {
        __typename?: 'BkpAccount';
        id: number;
        bkpIntegrationId?: string | null;
        name?: string | null;
      } | null;
      credential: {
        __typename?: 'Credential';
        accountId?: number | null;
        credentialType: string;
        source: CredentialSource;
        name?: string | null;
        address?: string | null;
        integrationId?: string | null;
      };
      specIdMatchesAsComponent: Array<{
        __typename?: 'SpecIdMatch';
        componentId: string;
        sellId: string;
        componentQuantity: number;
        componentOrder: number;
      }>;
      specIdMatchesAsSell: Array<{
        __typename?: 'SpecIdMatch';
        componentId: string;
        sellId: string;
        componentQuantity: number;
        componentOrder: number;
      }>;
    }>;
  };
};

export type TxnCountQueryVariables = Exact<{ [key: string]: never }>;

export type TxnCountQuery = {
  __typename?: 'Query';
  userTxnCount: {
    __typename?: 'TxnCount';
    totalCount: number;
    reviewedCount: number;
    txnTypeCounts: Array<{ __typename?: 'TxnTypeCount'; count: number; type: TxnType }>;
    txnOtherFiltersCounts: Array<{ __typename?: 'TxnOtherFiltersCount'; count: number; type: OtherFilters }>;
    txnImportTypeCounts: Array<{ __typename?: 'TxnImportTypeCount'; count: number; importType: string }>;
  };
};

export type MergeTxnsMutationVariables = Exact<{
  txnIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type MergeTxnsMutation = { __typename?: 'Mutation'; mergeTxns: boolean };

export type GetCsvUploadsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCsvUploadsQuery = {
  __typename?: 'Query';
  uploads: {
    __typename?: 'Uploads';
    edges: Array<{
      __typename?: 'Upload';
      id: number;
      userId?: number | null;
      name: string;
      url?: string | null;
      createdAt: any;
      updatedAt: any;
      isHidden: boolean;
      uploadType?: string | null;
      credentialId?: number | null;
      credential?: {
        __typename?: 'Credential';
        id: number;
        name?: string | null;
        deletedAt?: any | null;
      } | null;
    }>;
  };
};

export type GetCsvUploadStatusQueryVariables = Exact<{
  requestId: Scalars['String']['input'];
}>;

export type GetCsvUploadStatusQuery = {
  __typename?: 'Query';
  csvUploadStatus: {
    __typename?: 'CsvUploadStatus';
    status: EnumExchangeServiceRequestStatus;
    unsupportedKnownCsv?: {
      __typename?: 'UnsupportedKnownCsv';
      expectedFile: string;
      message: string;
      exchange: string;
    } | null;
  };
};

export type UserQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user?: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; email: string } | null;
};

export type CreateInvoiceMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']['input']>;
  newUserEmail?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['Int']['input'];
  lines: Array<InvoiceLine> | InvoiceLine;
  products: InvoiceProductsInput;
  hideDefaultCrypto?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateInvoiceMutation = { __typename?: 'Mutation'; createInvoice: string };

export type ToggleDocumentVisibilityMutationVariables = Exact<{
  documentIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  hidden: Scalars['Boolean']['input'];
}>;

export type ToggleDocumentVisibilityMutation = {
  __typename?: 'Mutation';
  toggleDocumentVisibility?: {
    __typename?: 'DocumentToggleVisibility';
    edges: Array<{
      __typename?: 'Report';
      id: number;
      reportType: string;
      createdAt: any;
      createdById?: number | null;
      createdByAdmin?: boolean | null;
      status: string;
      taxYear?: string | null;
      method?: string | null;
      url?: string | null;
      failedReason?: string | null;
      hidden?: boolean | null;
      margin?: boolean | null;
      extraCols?: boolean | null;
    }>;
  } | null;
};

export type DeleteDocumentsMutationVariables = Exact<{
  documentIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type DeleteDocumentsMutation = { __typename?: 'Mutation'; deleteDocuments: boolean };

export type TxnAllowanceForYearQueryVariables = Exact<{
  year: Scalars['Int']['input'];
}>;

export type TxnAllowanceForYearQuery = {
  __typename?: 'Query';
  txnAllowanceForYear: { __typename?: 'TxnAllowanceForYear'; allowed: number; count: number };
};

export type DocumentsQueryVariables = Exact<{
  pagination: PaginationOptions;
  sortBy: DocSortOptions;
  sortDirection: SortDirectionOptions;
  filterQuery: DocFilterQuery;
}>;

export type DocumentsQuery = {
  __typename?: 'Query';
  documents: {
    __typename?: 'Documents';
    pageInfo: { __typename?: 'PageInfo'; filteredCount?: number | null };
    edges: Array<{
      __typename?: 'Report';
      id: number;
      reportType: string;
      createdAt: any;
      createdById?: number | null;
      createdByAdmin?: boolean | null;
      status: string;
      taxYear?: string | null;
      method?: string | null;
      url?: string | null;
      failedReason?: string | null;
      hidden?: boolean | null;
      margin?: boolean | null;
      extraCols?: boolean | null;
    }>;
  };
};

export type DocumentsCountQueryVariables = Exact<{ [key: string]: never }>;

export type DocumentsCountQuery = {
  __typename?: 'Query';
  documentsCount: {
    __typename?: 'DocumentsCount';
    totalCount: number;
    yearCounts: Array<{ __typename?: 'YearCount'; taxYear: string; count: number }>;
    recommendedDocumentCounts: Array<{ __typename?: 'YearCount'; taxYear: string; count: number }>;
  };
};

export type DocumentDownloadUrlsQueryVariables = Exact<{
  documentIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type DocumentDownloadUrlsQuery = {
  __typename?: 'Query';
  documentDownloadUrls: {
    __typename?: 'DocumentUrls';
    edges: Array<{ __typename?: 'DocumentUrl'; id: number; url: string }>;
  };
};

export type Available8949PdfYearsQueryVariables = Exact<{ [key: string]: never }>;

export type Available8949PdfYearsQuery = { __typename?: 'Query'; available8949PdfYears: Array<number> };

export type BatchUpdateTxnsMutationVariables = Exact<{
  txnInput: PartialTxnInput;
  filterQuery?: InputMaybe<TxnFilterQuery>;
}>;

export type BatchUpdateTxnsMutation = { __typename?: 'Mutation'; batchUpdateTxns: boolean };

export type BkpAccountsVendorsQueryVariables = Exact<{ [key: string]: never }>;

export type BkpAccountsVendorsQuery = {
  __typename?: 'Query';
  bkpVendors: Array<{
    __typename?: 'BkpVendor';
    id: number;
    name?: string | null;
    active?: boolean | null;
    bkpIntegrationId?: string | null;
    bkpIntegrationDisplayName?: string | null;
    bkpIntegrationSyncToken?: string | null;
    createdAt: any;
    updatedAt: any;
  }>;
  bkpAccounts: Array<{
    __typename?: 'BkpAccount';
    id: number;
    name?: string | null;
    active?: boolean | null;
    bkpIntegrationId?: string | null;
    bkpIntegrationClassification?: string | null;
    bkpIntegrationAccountType?: string | null;
    bkpIntegrationSyncToken?: string | null;
    bkpIntegrationAcctNum?: string | null;
    bkpIntegrationParentRefId?: string | null;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type PriceQueryVariables = Exact<{
  symbol: Scalars['String']['input'];
  tokenId?: InputMaybe<Scalars['String']['input']>;
  date: DateObject;
  address?: InputMaybe<Scalars['String']['input']>;
  credentialType?: InputMaybe<Scalars['String']['input']>;
}>;

export type PriceQuery = { __typename?: 'Query'; price?: number | null };

export type PriceForTxnQueryVariables = Exact<{
  currency: Scalars['String']['input'];
  soldCurrency: Scalars['String']['input'];
  buyTokenId?: InputMaybe<Scalars['String']['input']>;
  sellTokenId?: InputMaybe<Scalars['String']['input']>;
  buyAddress?: InputMaybe<Scalars['String']['input']>;
  sellAddress?: InputMaybe<Scalars['String']['input']>;
  credentialType: Scalars['String']['input'];
  priceFetchingSide?: InputMaybe<PriceFetchingSide>;
  date: DateObject;
}>;

export type PriceForTxnQuery = {
  __typename?: 'Query';
  priceForTxn?: { __typename?: 'BuyOrSellPrice'; buyPrice?: number | null; sellPrice?: number | null } | null;
};

export type PasswordForgotMutationVariables = Exact<{
  email: Scalars['String']['input'];
  captchaResponse: Scalars['String']['input'];
}>;

export type PasswordForgotMutation = { __typename?: 'Mutation'; passwordForgot: boolean };

export type LoginOrSignupWithGoogleMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type LoginOrSignupWithGoogleMutation = {
  __typename?: 'Mutation';
  loginOrSignupWithGoogle: {
    __typename?: 'GoogleLoginOrSignupResponse';
    userHash: string;
    isSignup: boolean;
    user: {
      __typename?: 'User';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      id: number;
    };
  };
};

export type CredentialDownloadUrlQueryVariables = Exact<{
  credentialId: Scalars['Int']['input'];
}>;

export type CredentialDownloadUrlQuery = { __typename?: 'Query'; credentialDownloadUrl: string };

export type GetCredentialsCsvQueryVariables = Exact<{
  sortBy: CredentialSortOptions;
  sortDirection: SortDirectionOptions;
  filters: CredentialFilters;
}>;

export type GetCredentialsCsvQuery = {
  __typename?: 'Query';
  credentials: { __typename?: 'Credentials'; csv?: string | null };
};

export type ResyncCredentialsMutationVariables = Exact<{
  credentialIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  hardResync?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ResyncCredentialsMutation = { __typename?: 'Mutation'; resyncCredentials: boolean };

export type CancelCredentialSyncMutationVariables = Exact<{
  credentialIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type CancelCredentialSyncMutation = { __typename?: 'Mutation'; cancelCredentialSync: boolean };

export type AddOauthIntegrationMutationVariables = Exact<{
  api: Scalars['String']['input'];
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
}>;

export type AddOauthIntegrationMutation = {
  __typename?: 'Mutation';
  addOauthIntegration: {
    __typename?: 'CreatedCredential';
    exchangeServiceRequest: {
      __typename?: 'ExchangeServiceRequest';
      status?: EnumExchangeServiceRequestStatus | null;
      credentialId: number;
    };
    credential: { __typename?: 'Credential'; id: number; status?: EnumExchangeServiceRequestStatus | null };
  };
};

export type UpdateOauthIntegrationMutationVariables = Exact<{
  credentialId: Scalars['Int']['input'];
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
}>;

export type UpdateOauthIntegrationMutation = {
  __typename?: 'Mutation';
  updateOauthIntegration: {
    __typename?: 'Credential';
    id: number;
    status?: EnumExchangeServiceRequestStatus | null;
  };
};

export type RegenCoinbaseCredentialsMutationVariables = Exact<{
  credentialId: Scalars['Int']['input'];
}>;

export type RegenCoinbaseCredentialsMutation = { __typename?: 'Mutation'; regenCoinbaseCredentials: string };

export type AddApiIntegrationMutationVariables = Exact<{
  api: Scalars['String']['input'];
  credentials: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddApiIntegrationMutation = {
  __typename?: 'Mutation';
  addApiIntegration: {
    __typename?: 'CreatedCredential';
    exchangeServiceRequest: {
      __typename?: 'ExchangeServiceRequest';
      status?: EnumExchangeServiceRequestStatus | null;
      credentialId: number;
    };
    credential: { __typename?: 'Credential'; id: number; status?: EnumExchangeServiceRequestStatus | null };
  };
};

export type EditApiIntegrationMutationVariables = Exact<{
  credentialId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Int']['input']>;
  skipResync?: InputMaybe<Scalars['Boolean']['input']>;
  accountId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type EditApiIntegrationMutation = {
  __typename?: 'Mutation';
  editApiIntegration: {
    __typename?: 'UpdatedCredential';
    exchangeServiceRequest?: {
      __typename?: 'ExchangeServiceRequest';
      status?: EnumExchangeServiceRequestStatus | null;
      credentialId: number;
    } | null;
    credential: { __typename?: 'Credential'; id: number; status?: EnumExchangeServiceRequestStatus | null };
  };
};

export type AddWalletsIntegrationMutationVariables = Exact<{
  addresses: Array<AddressWithCurrencyInput> | AddressWithCurrencyInput;
  incomingType: TxnType;
  outgoingType: TxnType;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Int']['input']>;
  filterSpam?: InputMaybe<Scalars['Boolean']['input']>;
  reconcileOnSync?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AddWalletsIntegrationMutation = {
  __typename?: 'Mutation';
  addWallets: {
    __typename?: 'CreatedCredentials';
    credentials: number;
    exchangeServiceRequests: number;
    duplicates: number;
  };
};

export type EditWalletIntegrationMutationVariables = Exact<{
  credentialId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Int']['input']>;
  skipResync?: InputMaybe<Scalars['Boolean']['input']>;
  filterSpam?: InputMaybe<Scalars['Boolean']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  reconcileOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  accountId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type EditWalletIntegrationMutation = {
  __typename?: 'Mutation';
  editWallet: {
    __typename?: 'UpdatedCredential';
    exchangeServiceRequest?: {
      __typename?: 'ExchangeServiceRequest';
      status?: EnumExchangeServiceRequestStatus | null;
      credentialId: number;
    } | null;
    credential: {
      __typename?: 'Credential';
      id: number;
      status?: EnumExchangeServiceRequestStatus | null;
      name?: string | null;
    };
  };
};

export type AddMissingPricesMutationVariables = Exact<{
  credentialIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  plusOrMinusDays?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AddMissingPricesMutation = { __typename?: 'Mutation'; addMissingPrices: string };

export type UploadsQueryVariables = Exact<{
  pagination: PaginationOptions;
  sortBy: UploadSortOptions;
  sortDirection: SortDirectionOptions;
  csvOnly: Scalars['Boolean']['input'];
}>;

export type UploadsQuery = {
  __typename?: 'Query';
  uploads: {
    __typename?: 'Uploads';
    pageInfo: { __typename?: 'PageInfo'; hasNext?: boolean | null; filteredCount?: number | null };
    edges: Array<{
      __typename?: 'Upload';
      id: number;
      userId?: number | null;
      name: string;
      url?: string | null;
      createdAt: any;
      updatedAt: any;
      isHidden: boolean;
      uploadType?: string | null;
      credentialId?: number | null;
      credential?: {
        __typename?: 'Credential';
        id: number;
        name?: string | null;
        deletedAt?: any | null;
      } | null;
    }>;
  };
};

export type CredentialsQueryVariables = Exact<{
  pagination: PaginationOptions;
  sortBy: CredentialSortOptions;
  sortDirection: SortDirectionOptions;
  filters: CredentialFilters;
}>;

export type CredentialsQuery = {
  __typename?: 'Query';
  credentials: {
    __typename?: 'Credentials';
    pageInfo: { __typename?: 'PageInfo'; filteredCount?: number | null };
    edges: Array<{
      __typename?: 'Credential';
      id: number;
      source: CredentialSource;
      name?: string | null;
      address?: string | null;
      startDate?: any | null;
      endDate?: any | null;
      filterSpam?: boolean | null;
      txnCount: number;
      credentialType: string;
      latestTransaction?: any | null;
      earliestTransaction?: any | null;
      createdAt: any;
      updatedAt: any;
      status?: EnumExchangeServiceRequestStatus | null;
      lastRefreshError?: string | null;
      errorRemediation?: string | null;
      integrationId?: string | null;
      isOauth: boolean;
      reconcileOnSync?: boolean | null;
      accountId?: number | null;
      ensAlias?: string | null;
    }>;
  };
};

export type CredentialsCountQueryVariables = Exact<{ [key: string]: never }>;

export type CredentialsCountQuery = {
  __typename?: 'Query';
  credentialsCount: {
    __typename?: 'CredentialsCount';
    totalCount: number;
    credentialSourceCounts: Array<{
      __typename?: 'CredentialSourceCount';
      source: CredentialSource;
      count: number;
    }>;
    credentialLocationCounts: Array<{
      __typename?: 'CredentialLocationCount';
      location: string;
      count: number;
    }>;
    transactionCountsPerYear: Array<{ __typename?: 'TransactionCountPerYear'; year: string; count: number }>;
  };
};

export type ExistsForChainQueryVariables = Exact<{
  addresses: Array<ExistsForChainInput> | ExistsForChainInput;
}>;

export type ExistsForChainQuery = {
  __typename?: 'Query';
  existsForChain: {
    __typename?: 'ExistsForChainResponse';
    results: Array<{ __typename?: 'ExistsForChainObject'; address: string; chains: Array<string> }>;
  };
};

export type LatestMissingPricesJobLazyQueryVariables = Exact<{ [key: string]: never }>;

export type LatestMissingPricesJobLazyQuery = {
  __typename?: 'Query';
  latestMissingPricesJob?: {
    __typename?: 'LatestMissingPricesJob';
    id: string;
    status: EnumGenericJobStatus;
    modifiedTxnsLength?: number | null;
  } | null;
};

export type HasMovementTxnsQueryVariables = Exact<{
  credentialIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type HasMovementTxnsQuery = { __typename?: 'Query'; hasMovementTxns: boolean };

export type IncomeDashboardQueryVariables = Exact<{ [key: string]: never }>;

export type IncomeDashboardQuery = {
  __typename?: 'Query';
  incomeDashboard?: {
    __typename?: 'Txns';
    edges: Array<{
      __typename?: 'Txn';
      quantity?: number | null;
      currency?: string | null;
      usdSpotPrice?: number | null;
      unitPrice?: number | null;
      sellQuantity: string;
      sellCurrency: string;
      txnTimestamp: any;
    }>;
  } | null;
};

export type PayInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  stripeToken: Scalars['String']['input'];
  method: InvoicePaymentMethod;
}>;

export type PayInvoiceMutation = { __typename?: 'Mutation'; payInvoice: boolean };

export type MarkInvoiceAsPaidMutationVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  method: InvoicePaymentMethod;
  cryptocurrency?: InputMaybe<Scalars['String']['input']>;
  txnHash?: InputMaybe<Scalars['String']['input']>;
}>;

export type MarkInvoiceAsPaidMutation = { __typename?: 'Mutation'; markInvoiceAsPaid: boolean };

export type MarkInvoiceAsVoidMutationVariables = Exact<{
  invoiceId: Scalars['String']['input'];
}>;

export type MarkInvoiceAsVoidMutation = { __typename?: 'Mutation'; markInvoiceAsVoid: boolean };

export type InvoiceEventsQueryVariables = Exact<{
  invoiceId: Scalars['String']['input'];
}>;

export type InvoiceEventsQuery = {
  __typename?: 'Query';
  invoiceEvents: {
    __typename?: 'InvoiceEvents';
    invoiceEvents: Array<{ __typename?: 'InvoiceEvent'; timestamp: number; type: string }>;
  };
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  captchaResponse: Scalars['String']['input'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginOrSignupResponse';
    userHash: string;
    user: { __typename?: 'User'; id: number };
  };
};

export type SyncCovalentWalletsMutationVariables = Exact<{
  addresses: Array<Scalars['String']['input']> | Scalars['String']['input'];
  chainIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type SyncCovalentWalletsMutation = {
  __typename?: 'Mutation';
  syncCovalentWallets: {
    __typename?: 'CreatedCredentials';
    credentials: number;
    exchangeServiceRequests: number;
    duplicates: number;
  };
};

export type BuyProductsMutationVariables = Exact<{
  existingSourceId?: InputMaybe<Scalars['String']['input']>;
  newSourceTokenId?: InputMaybe<Scalars['String']['input']>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  cryptoOnlyPlans: Array<ProductInput> | ProductInput;
  fullFilingPlans: Array<ProductInput> | ProductInput;
  addons: Array<ProductInput> | ProductInput;
  subTotal: Scalars['Int']['input'];
  fpTrackId?: InputMaybe<Scalars['String']['input']>;
  cardHolderName: Scalars['String']['input'];
}>;

export type BuyProductsMutation = {
  __typename?: 'Mutation';
  buyProducts: {
    __typename?: 'BuyProductsResponse';
    invoiceId: string;
    total: number;
    couponCode?: string | null;
    couponPercentOff?: number | null;
    analyticsProducts: Array<{
      __typename?: 'AnalyticsProductData';
      name: string;
      price: number;
      product_id: string;
      quantity: number;
      is_upgrade?: boolean | null;
    }>;
  };
};

export type ValidateCouponMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type ValidateCouponMutation = {
  __typename?: 'Mutation';
  validateCoupon: { __typename?: 'CouponValidationResponse'; valid: boolean; percentOff?: number | null };
};

export type EvmGuideSubmitEditInstructionMutationVariables = Exact<{
  instructionId: Scalars['Int']['input'];
  newTreatmentId: Scalars['Int']['input'];
  newNotes?: InputMaybe<Scalars['String']['input']>;
}>;

export type EvmGuideSubmitEditInstructionMutation = {
  __typename?: 'Mutation';
  evmGuideEditInstruction: boolean;
};

export type EvmGuideSubmitNewInstructionMutationVariables = Exact<{
  methodId: Scalars['String']['input'];
  chain: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
  treatmentId: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
}>;

export type EvmGuideSubmitNewInstructionMutation = {
  __typename?: 'Mutation';
  evmGuideNewInstruction: boolean;
};

export type SolanaGuideSubmitEditInstructionMutationVariables = Exact<{
  instructionId: Scalars['Int']['input'];
  newTreatmentId: Scalars['Int']['input'];
  newNotes?: InputMaybe<Scalars['String']['input']>;
  transactionHash: Scalars['String']['input'];
}>;

export type SolanaGuideSubmitEditInstructionMutation = {
  __typename?: 'Mutation';
  solanaGuideEditInstruction: boolean;
};

export type SolanaGuideSubmitNewInstructionMutationVariables = Exact<{
  identifier: Scalars['String']['input'];
  treatmentId: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  transactionHash: Scalars['String']['input'];
}>;

export type SolanaGuideSubmitNewInstructionMutation = {
  __typename?: 'Mutation';
  solanaGuideNewInstruction: boolean;
};

export type IbcGuideSubmitEditInstructionMutationVariables = Exact<{
  instructionId: Scalars['Int']['input'];
  newTreatmentId: Scalars['Int']['input'];
  newNotes?: InputMaybe<Scalars['String']['input']>;
  credentialType: Scalars['String']['input'];
  transactionHash: Scalars['String']['input'];
}>;

export type IbcGuideSubmitEditInstructionMutation = {
  __typename?: 'Mutation';
  ibcGuideEditInstruction: boolean;
};

export type IbcGuideSubmitNewInstructionMutationVariables = Exact<{
  identifier: Scalars['String']['input'];
  treatmentId: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  credentialType: Scalars['String']['input'];
  transactionHash: Scalars['String']['input'];
}>;

export type IbcGuideSubmitNewInstructionMutation = {
  __typename?: 'Mutation';
  ibcGuideNewInstruction: boolean;
};

export type AddUserProfileMutationVariables = Exact<{
  userProfileInputData: UserProfileInputData;
}>;

export type AddUserProfileMutation = { __typename?: 'Mutation'; addUserProfile: boolean };

export type GetLatestUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestUserProfileQuery = {
  __typename?: 'Query';
  getLatestUserProfile?: { __typename?: 'UserProfileData'; data: any; touchedSections: Array<string> } | null;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countries: Array<{
    __typename?: 'Country';
    id: number;
    name: string;
    shorthand: string;
    methods: Array<string>;
  }>;
};

export type GetAccountYearsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountYearsQuery = {
  __typename?: 'Query';
  user?: { __typename?: 'User'; taxDetails: Array<{ __typename?: 'UserTaxDetail'; taxYear: number }> } | null;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: { __typename?: 'User'; id: number; email: string } | null;
};

export type PasswordResetMutationVariables = Exact<{
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
  captchaResponse: Scalars['String']['input'];
}>;

export type PasswordResetMutation = { __typename?: 'Mutation'; passwordReset: boolean };

export type AccountBalancesQueryVariables = Exact<{
  input?: InputMaybe<AccountBalancesInput>;
}>;

export type AccountBalancesQuery = {
  __typename?: 'Query';
  accountBalances: Array<{
    __typename?: 'AccountBalanceEntry';
    accountId: number;
    currency: string;
    balance: number;
    usdValue?: number | null;
  }>;
};

export type DailyBalancesQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  accountIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  currencies?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type DailyBalancesQuery = {
  __typename?: 'Query';
  dailyBalances: Array<{
    __typename?: 'AccountBalanceMap';
    accountId: number;
    entries: Array<{
      __typename?: 'BalanceEntry';
      date: string;
      currency: string;
      balance: number;
      diff: number;
    }>;
  }>;
};

export type EditAccountRowMutationVariables = Exact<{
  editAccountRowId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type EditAccountRowMutation = {
  __typename?: 'Mutation';
  editAccountRow: {
    __typename?: 'Account';
    id: number;
    address?: string | null;
    name: string;
    integrationId?: string | null;
  };
};

export type DeleteAccountRowMutationVariables = Exact<{
  deleteAccountRowId: Scalars['Int']['input'];
}>;

export type DeleteAccountRowMutation = { __typename?: 'Mutation'; deleteAccountRow: boolean };

export type CreateAccountRowMutationVariables = Exact<{
  name: Scalars['String']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['String']['input'];
}>;

export type CreateAccountRowMutation = {
  __typename?: 'Mutation';
  createAccountRow: {
    __typename?: 'Account';
    id: number;
    address?: string | null;
    name: string;
    integrationId?: string | null;
  };
};

export type RunCreateAccountsJobMutationVariables = Exact<{ [key: string]: never }>;

export type RunCreateAccountsJobMutation = {
  __typename?: 'Mutation';
  runCreateAccountsJob: { __typename?: 'GenerateAccountsJob'; id: string };
};

export type LatestGenerateAccountsJobQueryVariables = Exact<{ [key: string]: never }>;

export type LatestGenerateAccountsJobQuery = {
  __typename?: 'Query';
  latestGenerateAccountsJob?: {
    __typename?: 'GenerateAccountsJob';
    id: string;
    status?: EnumGenericJobStatus | null;
    createdAt?: any | null;
  } | null;
};

export type MovementsQueryVariables = Exact<{
  movementTableInput: MovementTableInput;
}>;

export type MovementsQuery = {
  __typename?: 'Query';
  movements: {
    __typename?: 'Movements';
    edges: Array<{
      __typename?: 'Movement';
      id: number;
      movement: {
        __typename?: 'MovementComponent';
        id: number;
        component: EnumTxnGroupComponent;
        txnId: string;
        txnTimestamp: any;
        txnHash?: string | null;
        currency?: string | null;
        soldCurrency?: string | null;
        quantity?: number | null;
        soldQuantity?: number | null;
        credential: { __typename?: 'Credential'; id: number; name?: string | null; address?: string | null };
        fromAccount?: {
          __typename?: 'Account';
          id: number;
          name: string;
          address?: string | null;
          integrationId?: string | null;
        } | null;
        toAccount?: {
          __typename?: 'Account';
          id: number;
          name: string;
          address?: string | null;
          integrationId?: string | null;
        } | null;
      };
      deposit: {
        __typename?: 'MovementComponent';
        id: number;
        component: EnumTxnGroupComponent;
        txnId: string;
        txnTimestamp: any;
        txnHash?: string | null;
        currency?: string | null;
        quantity?: number | null;
        credential: { __typename?: 'Credential'; id: number; name?: string | null; address?: string | null };
      };
      withdrawal: {
        __typename?: 'MovementComponent';
        id: number;
        component: EnumTxnGroupComponent;
        txnId: string;
        txnTimestamp: any;
        txnHash?: string | null;
        soldCurrency?: string | null;
        soldQuantity?: number | null;
        credential: { __typename?: 'Credential'; id: number; name?: string | null; address?: string | null };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; filteredCount?: number | null };
  };
};

export type LatestCreateMovementsJobQueryVariables = Exact<{ [key: string]: never }>;

export type LatestCreateMovementsJobQuery = {
  __typename?: 'Query';
  latestCreateMovementsJob?: {
    __typename?: 'LatestCreateMovementsJob';
    id: string;
    status: EnumGenericJobStatus;
    createdAt: any;
    modifiedTxnsLength?: number | null;
  } | null;
};

export type RunCreateMovementsJobMutationVariables = Exact<{ [key: string]: never }>;

export type RunCreateMovementsJobMutation = {
  __typename?: 'Mutation';
  runCreateMovementsJob: { __typename?: 'CreateMovementsJob'; id: string };
};

export type MovementMergeCandidatesQueryVariables = Exact<{
  movementTableInput: MovementTableInput;
}>;

export type MovementMergeCandidatesQuery = {
  __typename?: 'Query';
  movementMergeCandidates: {
    __typename?: 'MovementMergeCandidates';
    edges: Array<{
      __typename?: 'MovementMergeCandidate';
      id: string;
      txnType: string;
      txnTimestamp: any;
      txnHash?: string | null;
      currency?: string | null;
      soldCurrency?: string | null;
      quantity?: number | null;
      soldQuantity?: number | null;
      account?: { __typename?: 'Account'; id: number; name: string } | null;
      credential: { __typename?: 'Credential'; id: number; name?: string | null; address?: string | null };
    }>;
    pageInfo: { __typename?: 'PageInfo'; filteredCount?: number | null };
  };
};

export type DeleteMovementsMutationVariables = Exact<{
  txnGroupIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type DeleteMovementsMutation = { __typename?: 'Mutation'; deleteMovements: boolean };

export type MissingExchangesQueryVariables = Exact<{ [key: string]: never }>;

export type MissingExchangesQuery = {
  __typename?: 'Query';
  missingExchanges: {
    __typename?: 'MissingExchanges';
    edges: Array<{
      __typename?: 'MissingExchange';
      name: string;
      txns: Array<{
        __typename?: 'Txn';
        id: string;
        blocksvcHash?: string | null;
        txnTimestamp: any;
        blocksvcFromAddress?: string | null;
        blocksvcToAddress?: string | null;
        sellQuantity: string;
        buyQuantity: string;
        sellCurrency: string;
        buyCurrency: string;
        credential: { __typename?: 'Credential'; credentialType: string };
      }>;
    }>;
  };
};

export type MissingWalletsQueryVariables = Exact<{ [key: string]: never }>;

export type MissingWalletsQuery = {
  __typename?: 'Query';
  missingWallets: {
    __typename?: 'MissingWallets';
    edges: Array<{ __typename?: 'MissingWallet'; id: string; chain: string; address: string }>;
  };
};

export type MissingWalletTxnCountQueryVariables = Exact<{
  wallets: Array<WalletTxnCountInput> | WalletTxnCountInput;
}>;

export type MissingWalletTxnCountQuery = {
  __typename?: 'Query';
  missingWalletTxnCount?: Array<{
    __typename?: 'WalletTxnCount';
    chain: string;
    address: string;
    count?: number | null;
    earliestTxnDate?: string | null;
    latestTxnDate?: string | null;
  }> | null;
};

export type ValidateMissingWalletsQueryVariables = Exact<{
  missingWallets: Array<MissingWalletValidationInput> | MissingWalletValidationInput;
}>;

export type ValidateMissingWalletsQuery = {
  __typename?: 'Query';
  validateMissingWallets: {
    __typename?: 'ValidatedMissingWallets';
    edges: Array<{ __typename?: 'MissingWalletWithTxnValidation'; id: string; hasTransactions: boolean }>;
  };
};

export type MissingPricesDataQueryVariables = Exact<{ [key: string]: never }>;

export type MissingPricesDataQuery = {
  __typename?: 'Query';
  missingPricesData: Array<{
    __typename?: 'MissingPricesDetail';
    type: MissingPricesTxnTypes;
    count: number;
  }>;
};

export type LatestMissingPricesJobQueryVariables = Exact<{ [key: string]: never }>;

export type LatestMissingPricesJobQuery = {
  __typename?: 'Query';
  latestMissingPricesJob?: {
    __typename?: 'LatestMissingPricesJob';
    id: string;
    status: EnumGenericJobStatus;
    createdAt: any;
    modifiedTxnsLength?: number | null;
    modifiedTxnDetails?: Array<{ __typename?: 'ModifiedTxnDetail'; token: string; count: number }> | null;
  } | null;
};

export type UnbusyCredentialsQueryVariables = Exact<{ [key: string]: never }>;

export type UnbusyCredentialsQuery = {
  __typename?: 'Query';
  unbusyCredentials: Array<{ __typename?: 'Credential'; id: number }>;
};

export type ResolveUnreconciledTransactionsMutationVariables = Exact<{
  unreconciledTransactionInputs: Array<UnreconciledTransactionInput> | UnreconciledTransactionInput;
}>;

export type ResolveUnreconciledTransactionsMutation = {
  __typename?: 'Mutation';
  resolveUnreconciledTransactions: {
    __typename?: 'ResolveUnreconciledTransactionResponse';
    errors: Array<{ __typename?: 'ResolutionError'; unreconciledTransactionId: string; message: string }>;
    resolutions: Array<{
      __typename?: 'UnreconciledTransactionResolution';
      id: number;
      unreconciledTransactionId: string;
      userId: number;
      credentialType: string;
      blocksvcHash: string;
      category: string;
      edited: boolean;
      appliedAt: any;
      invalidatedAt?: any | null;
      revertedAt?: any | null;
      drafts: Array<{
        __typename?: 'Draft';
        sent?: {
          __typename?: 'TransfersGroup';
          txns: Array<{
            __typename?: 'Transfer';
            id?: string | null;
            currency: string;
            tokenId?: string | null;
            quantity: string;
            type: TransferType;
          } | null>;
        } | null;
        received?: {
          __typename?: 'TransfersGroup';
          txns: Array<{
            __typename?: 'Transfer';
            id?: string | null;
            currency: string;
            tokenId?: string | null;
            quantity: string;
            type: TransferType;
          } | null>;
        } | null;
      }>;
    }>;
  };
};

export type UnreconciledTransactionsQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationOptions>;
  sortBy: UnreconciledTransactionsSortOptions;
  sortDirection: SortDirectionOptions;
  filterQuery?: InputMaybe<UnreconciledTxnsFiltersQuery>;
  hashes?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type UnreconciledTransactionsQuery = {
  __typename?: 'Query';
  unreconciledTransactions: {
    __typename?: 'UnreconciledTransactions';
    edges: Array<{
      __typename?: 'UnreconciledTransaction';
      id: string;
      hash: string;
      sentTxnsCount: number;
      receivedTxnsCount: number;
      timestamp: any;
      editedAt?: any | null;
      from?: string | null;
      to?: string | null;
      contractPublicName?: string | null;
      resolved: boolean;
      invalidated: boolean;
      resolutionCategory?: string | null;
      resolutionId?: number | null;
      ecosystem: SupportedEcosystem;
      reconIdentifier?: string | null;
      credentials: Array<{
        __typename?: 'UnreconciledTxnCredential';
        id: number;
        source: CredentialSource;
        type: string;
        name?: string | null;
        address?: string | null;
      }>;
      method: {
        __typename?: 'UnreconciledTransactionMethod';
        id?: number | null;
        hash: string;
        name?: string | null;
      };
      instruction?:
        | {
            __typename?: 'EvmInstruction';
            id: number;
            contractId: number;
            notes?: string | null;
            proba1?: number | null;
            pred1?: number | null;
            publicNotes?: string | null;
            tokenParam1?: Array<string> | null;
            tokenParam2?: Array<string> | null;
            treatment?: { __typename?: 'Treatment'; id: number; description?: string | null } | null;
          }
        | {
            __typename?: 'IdentifierBasedInstruction';
            id: number;
            notes?: string | null;
            publicNotes?: string | null;
            tokenParam1?: Array<string> | null;
            tokenParam2?: Array<string> | null;
            treatment?: { __typename?: 'Treatment'; id: number; description?: string | null } | null;
          }
        | null;
    }>;
    pageInfo: { __typename?: 'PageInfo'; filteredCount?: number | null };
  };
};

export type GetLpTokensQueryVariables = Exact<{ [key: string]: never }>;

export type GetLpTokensQuery = {
  __typename?: 'Query';
  getLpTokens: Array<{ __typename?: 'LpToken'; address: string; ticker: string }>;
};

export type UnreconciledTransactionDataFragmentFragment = {
  __typename?: 'Txn';
  id: string;
  txnType: TxnType;
  buyAddress?: string | null;
  buyCurrency: string;
  buyPrice: string;
  buyQuantity: string;
  buyTokenId?: string | null;
  sellAddress?: string | null;
  sellCurrency: string;
  sellPrice: string;
  sellQuantity: string;
  sellTokenId?: string | null;
  feeCurrency?: string | null;
  feeTokenId?: string | null;
  feeQuantity?: number | null;
  feePrice: string;
  blocksvcToAddress?: string | null;
  blocksvcFromAddress?: string | null;
};

export type UnreconciledTxnsDataQueryVariables = Exact<{
  txnHashes: Array<Scalars['String']['input']> | Scalars['String']['input'];
  credentialType: Scalars['String']['input'];
}>;

export type UnreconciledTxnsDataQuery = {
  __typename?: 'Query';
  unreconciledTxnsData: Array<{
    __typename?: 'UnreconciledTxnsData';
    txnHash: string;
    ecosystem: SupportedEcosystem;
    txns: Array<{
      __typename?: 'Txn';
      id: string;
      txnType: TxnType;
      buyAddress?: string | null;
      buyCurrency: string;
      buyPrice: string;
      buyQuantity: string;
      buyTokenId?: string | null;
      sellAddress?: string | null;
      sellCurrency: string;
      sellPrice: string;
      sellQuantity: string;
      sellTokenId?: string | null;
      feeCurrency?: string | null;
      feeTokenId?: string | null;
      feeQuantity?: number | null;
      feePrice: string;
      blocksvcToAddress?: string | null;
      blocksvcFromAddress?: string | null;
    }>;
  }>;
};

export type SupportedSelfReconCredentialsCountQueryVariables = Exact<{ [key: string]: never }>;

export type SupportedSelfReconCredentialsCountQuery = {
  __typename?: 'Query';
  supportedSelfReconCredentialsCount: { __typename?: 'SupportedSelfReconCredentialCount'; count: number };
};

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword: boolean };

export type QuickbooksAuthUrlQueryVariables = Exact<{
  redirectPath: Scalars['String']['input'];
}>;

export type QuickbooksAuthUrlQuery = { __typename?: 'Query'; quickbooksAuthUrl: string };

export type QuickbooksOauthMutationVariables = Exact<{
  code: Scalars['String']['input'];
  state: Scalars['String']['input'];
  realmId: Scalars['String']['input'];
  redirectPath: Scalars['String']['input'];
}>;

export type QuickbooksOauthMutation = { __typename?: 'Mutation'; quickbooksOauth: boolean };

export type QuickbooksSyncMutationVariables = Exact<{ [key: string]: never }>;

export type QuickbooksSyncMutation = { __typename?: 'Mutation'; quickbooksSyncData: boolean };

export type UpdateUserTaxDetailMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  countryId: Scalars['Int']['input'];
  locked: Scalars['Boolean']['input'];
  longTermCapitalGainsRate: Scalars['Float']['input'];
  marginalTaxRate: Scalars['Float']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateUserTaxDetailMutation = {
  __typename?: 'Mutation';
  updateUserTaxDetail: {
    __typename?: 'UserTaxDetail';
    id: number;
    state?: string | null;
    locked: boolean;
    marginalTaxRate?: number | null;
    longTermCapitalGainsRate?: number | null;
    taxYear: number;
    taxMethod?: { __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string } | null;
    country: { __typename?: 'Country'; name: string; shorthand: string; id: number };
  };
};

export type SignupMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  captchaResponse: Scalars['String']['input'];
}>;

export type SignupMutation = {
  __typename?: 'Mutation';
  signup: {
    __typename?: 'LoginOrSignupResponse';
    userHash: string;
    user: { __typename?: 'User'; id: number };
  };
};

export type AuditDetailFragment = {
  __typename?: 'LineItemAuditDetail';
  date?: any | null;
  txnType: TxnType;
  location: string;
  soldCurrency: string;
  boughtCurrency: string;
  feeCurrency: string;
  soldAmount: number;
  boughtAmount: number;
  feeAmount: number;
  price: number;
  txn: {
    __typename?: 'Txn';
    id: string;
    associatedExchangeAddress?: string | null;
    blocksvcHash?: string | null;
    credentialId?: number | null;
    buyCurrency: string;
    buyTokenId?: string | null;
    buyAddress?: string | null;
    buyNftId?: string | null;
    description?: string | null;
    exchangeId?: string | null;
    exchangeName?: string | null;
    feeCurrency?: string | null;
    feeTokenId?: string | null;
    feeQuantity?: number | null;
    feePrice: string;
    feeAddress?: string | null;
    buyPrice: string;
    sellPrice: string;
    buyQuantity: string;
    sellCurrency: string;
    sellTokenId?: string | null;
    sellAddress?: string | null;
    sellNftId?: string | null;
    sellQuantity: string;
    txnTimestamp: any;
    txnType: TxnType;
    unitPrice?: number | null;
    priceFetchingSide?: PriceFetchingSide | null;
    usdSpotPrice?: number | null;
    blocksvcMethodId?: string | null;
    blocksvcToAddress?: string | null;
    blocksvcFromAddress?: string | null;
    createdAt: any;
    updatedAt: any;
    isEdited?: boolean | null;
    isSpam: boolean;
    reviewed?: boolean | null;
    editedByReconGuideJob: boolean;
    reconIdentifier?: string | null;
    bkpVendorId?: number | null;
    integrationId?: string | null;
    toIntegrationId?: string | null;
    bkpAccountDebitId?: number | null;
    bkpAccountCreditId?: number | null;
    accountId?: number | null;
    toAccountId?: number | null;
    hasMovement?: boolean | null;
    bkpVendor?: {
      __typename?: 'BkpVendor';
      id: number;
      bkpIntegrationId?: string | null;
      bkpIntegrationDisplayName?: string | null;
    } | null;
    bkpAccountDebit?: {
      __typename?: 'BkpAccount';
      id: number;
      bkpIntegrationId?: string | null;
      name?: string | null;
    } | null;
    bkpAccountCredit?: {
      __typename?: 'BkpAccount';
      id: number;
      bkpIntegrationId?: string | null;
      name?: string | null;
    } | null;
    credential: {
      __typename?: 'Credential';
      accountId?: number | null;
      credentialType: string;
      source: CredentialSource;
      name?: string | null;
      address?: string | null;
      integrationId?: string | null;
    };
    specIdMatchesAsComponent: Array<{
      __typename?: 'SpecIdMatch';
      componentId: string;
      sellId: string;
      componentQuantity: number;
      componentOrder: number;
    }>;
    specIdMatchesAsSell: Array<{
      __typename?: 'SpecIdMatch';
      componentId: string;
      sellId: string;
      componentQuantity: number;
      componentOrder: number;
    }>;
  };
};

export type ExpandedRowQueryVariables = Exact<{
  lineItemId: Scalars['BigInt']['input'];
  isAdmin: Scalars['Boolean']['input'];
}>;

export type ExpandedRowQuery = {
  __typename?: 'Query';
  lineItemDetails: {
    __typename?: 'LineItemDetail';
    missingCostBasis: boolean;
    term?: string | null;
    averageCost?: number | null;
    soldAmountFlat: number;
    costBasis: number;
    costBasisFlat: number;
    costBasisFromSellFee: number;
    costBasisFeeAddition: number;
    costBasisFeeAdditionUnits: number;
    costBasisFeeAdditionCurrency: string;
    proceeds: number;
    proceedsFlat: number;
    proceedsFromSellFee: number;
    proceedsFeeDeduction: number;
    proceedsFeeDeductionUnits: number;
    proceedsFeeDeductionCurrency: string;
    gainLoss: number;
    bnbMatchType?: string | null;
    specIdMatch?: boolean | null;
    originDetail?: {
      __typename?: 'LineItemAuditDetail';
      date?: any | null;
      txnType: TxnType;
      location: string;
      soldCurrency: string;
      boughtCurrency: string;
      feeCurrency: string;
      soldAmount: number;
      boughtAmount: number;
      feeAmount: number;
      price: number;
      txn: {
        __typename?: 'Txn';
        id: string;
        associatedExchangeAddress?: string | null;
        blocksvcHash?: string | null;
        credentialId?: number | null;
        buyCurrency: string;
        buyTokenId?: string | null;
        buyAddress?: string | null;
        buyNftId?: string | null;
        description?: string | null;
        exchangeId?: string | null;
        exchangeName?: string | null;
        feeCurrency?: string | null;
        feeTokenId?: string | null;
        feeQuantity?: number | null;
        feePrice: string;
        feeAddress?: string | null;
        buyPrice: string;
        sellPrice: string;
        buyQuantity: string;
        sellCurrency: string;
        sellTokenId?: string | null;
        sellAddress?: string | null;
        sellNftId?: string | null;
        sellQuantity: string;
        txnTimestamp: any;
        txnType: TxnType;
        unitPrice?: number | null;
        priceFetchingSide?: PriceFetchingSide | null;
        usdSpotPrice?: number | null;
        blocksvcMethodId?: string | null;
        blocksvcToAddress?: string | null;
        blocksvcFromAddress?: string | null;
        createdAt: any;
        updatedAt: any;
        isEdited?: boolean | null;
        isSpam: boolean;
        reviewed?: boolean | null;
        editedByReconGuideJob: boolean;
        reconIdentifier?: string | null;
        bkpVendorId?: number | null;
        integrationId?: string | null;
        toIntegrationId?: string | null;
        bkpAccountDebitId?: number | null;
        bkpAccountCreditId?: number | null;
        accountId?: number | null;
        toAccountId?: number | null;
        hasMovement?: boolean | null;
        bkpVendor?: {
          __typename?: 'BkpVendor';
          id: number;
          bkpIntegrationId?: string | null;
          bkpIntegrationDisplayName?: string | null;
        } | null;
        bkpAccountDebit?: {
          __typename?: 'BkpAccount';
          id: number;
          bkpIntegrationId?: string | null;
          name?: string | null;
        } | null;
        bkpAccountCredit?: {
          __typename?: 'BkpAccount';
          id: number;
          bkpIntegrationId?: string | null;
          name?: string | null;
        } | null;
        credential: {
          __typename?: 'Credential';
          accountId?: number | null;
          credentialType: string;
          source: CredentialSource;
          name?: string | null;
          address?: string | null;
          integrationId?: string | null;
        };
        specIdMatchesAsComponent: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
        specIdMatchesAsSell: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
      };
    } | null;
    migrationDetail?: {
      __typename?: 'LineItemAuditDetail';
      date?: any | null;
      txnType: TxnType;
      location: string;
      soldCurrency: string;
      boughtCurrency: string;
      feeCurrency: string;
      soldAmount: number;
      boughtAmount: number;
      feeAmount: number;
      price: number;
      txn: {
        __typename?: 'Txn';
        id: string;
        associatedExchangeAddress?: string | null;
        blocksvcHash?: string | null;
        credentialId?: number | null;
        buyCurrency: string;
        buyTokenId?: string | null;
        buyAddress?: string | null;
        buyNftId?: string | null;
        description?: string | null;
        exchangeId?: string | null;
        exchangeName?: string | null;
        feeCurrency?: string | null;
        feeTokenId?: string | null;
        feeQuantity?: number | null;
        feePrice: string;
        feeAddress?: string | null;
        buyPrice: string;
        sellPrice: string;
        buyQuantity: string;
        sellCurrency: string;
        sellTokenId?: string | null;
        sellAddress?: string | null;
        sellNftId?: string | null;
        sellQuantity: string;
        txnTimestamp: any;
        txnType: TxnType;
        unitPrice?: number | null;
        priceFetchingSide?: PriceFetchingSide | null;
        usdSpotPrice?: number | null;
        blocksvcMethodId?: string | null;
        blocksvcToAddress?: string | null;
        blocksvcFromAddress?: string | null;
        createdAt: any;
        updatedAt: any;
        isEdited?: boolean | null;
        isSpam: boolean;
        reviewed?: boolean | null;
        editedByReconGuideJob: boolean;
        reconIdentifier?: string | null;
        bkpVendorId?: number | null;
        integrationId?: string | null;
        toIntegrationId?: string | null;
        bkpAccountDebitId?: number | null;
        bkpAccountCreditId?: number | null;
        accountId?: number | null;
        toAccountId?: number | null;
        hasMovement?: boolean | null;
        bkpVendor?: {
          __typename?: 'BkpVendor';
          id: number;
          bkpIntegrationId?: string | null;
          bkpIntegrationDisplayName?: string | null;
        } | null;
        bkpAccountDebit?: {
          __typename?: 'BkpAccount';
          id: number;
          bkpIntegrationId?: string | null;
          name?: string | null;
        } | null;
        bkpAccountCredit?: {
          __typename?: 'BkpAccount';
          id: number;
          bkpIntegrationId?: string | null;
          name?: string | null;
        } | null;
        credential: {
          __typename?: 'Credential';
          accountId?: number | null;
          credentialType: string;
          source: CredentialSource;
          name?: string | null;
          address?: string | null;
          integrationId?: string | null;
        };
        specIdMatchesAsComponent: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
        specIdMatchesAsSell: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
      };
    } | null;
    exitDetail: {
      __typename?: 'LineItemAuditDetail';
      date?: any | null;
      txnType: TxnType;
      location: string;
      soldCurrency: string;
      boughtCurrency: string;
      feeCurrency: string;
      soldAmount: number;
      boughtAmount: number;
      feeAmount: number;
      price: number;
      txn: {
        __typename?: 'Txn';
        id: string;
        associatedExchangeAddress?: string | null;
        blocksvcHash?: string | null;
        credentialId?: number | null;
        buyCurrency: string;
        buyTokenId?: string | null;
        buyAddress?: string | null;
        buyNftId?: string | null;
        description?: string | null;
        exchangeId?: string | null;
        exchangeName?: string | null;
        feeCurrency?: string | null;
        feeTokenId?: string | null;
        feeQuantity?: number | null;
        feePrice: string;
        feeAddress?: string | null;
        buyPrice: string;
        sellPrice: string;
        buyQuantity: string;
        sellCurrency: string;
        sellTokenId?: string | null;
        sellAddress?: string | null;
        sellNftId?: string | null;
        sellQuantity: string;
        txnTimestamp: any;
        txnType: TxnType;
        unitPrice?: number | null;
        priceFetchingSide?: PriceFetchingSide | null;
        usdSpotPrice?: number | null;
        blocksvcMethodId?: string | null;
        blocksvcToAddress?: string | null;
        blocksvcFromAddress?: string | null;
        createdAt: any;
        updatedAt: any;
        isEdited?: boolean | null;
        isSpam: boolean;
        reviewed?: boolean | null;
        editedByReconGuideJob: boolean;
        reconIdentifier?: string | null;
        bkpVendorId?: number | null;
        integrationId?: string | null;
        toIntegrationId?: string | null;
        bkpAccountDebitId?: number | null;
        bkpAccountCreditId?: number | null;
        accountId?: number | null;
        toAccountId?: number | null;
        hasMovement?: boolean | null;
        bkpVendor?: {
          __typename?: 'BkpVendor';
          id: number;
          bkpIntegrationId?: string | null;
          bkpIntegrationDisplayName?: string | null;
        } | null;
        bkpAccountDebit?: {
          __typename?: 'BkpAccount';
          id: number;
          bkpIntegrationId?: string | null;
          name?: string | null;
        } | null;
        bkpAccountCredit?: {
          __typename?: 'BkpAccount';
          id: number;
          bkpIntegrationId?: string | null;
          name?: string | null;
        } | null;
        credential: {
          __typename?: 'Credential';
          accountId?: number | null;
          credentialType: string;
          source: CredentialSource;
          name?: string | null;
          address?: string | null;
          integrationId?: string | null;
        };
        specIdMatchesAsComponent: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
        specIdMatchesAsSell: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
      };
    };
    missingCostBasisDetail?: {
      __typename?: 'LineItemMissingCostBasisDetail';
      missingCurrency: string;
      missingAmount: number;
    } | null;
    lineItemTrace: Array<{
      __typename?: 'LineItem';
      id: any;
      buyId?: string | null;
      sellId?: string | null;
      sellCurrency?: string | null;
      buyCurrency?: string | null;
      unitsSold?: number | null;
      buyDate?: any | null;
      sellDate?: any | null;
      proceedsIncludingFees: number;
      costBasisIncludingFees: number;
      gainLossIncludingFees: number;
      term?: string | null;
      missingCostBasis?: boolean | null;
      splitBuyId?: string | null;
      txnLineItemSellIdTotxn?: {
        __typename?: 'Txn';
        id: string;
        associatedExchangeAddress?: string | null;
        blocksvcHash?: string | null;
        credentialId?: number | null;
        buyCurrency: string;
        buyTokenId?: string | null;
        buyAddress?: string | null;
        buyNftId?: string | null;
        description?: string | null;
        exchangeId?: string | null;
        exchangeName?: string | null;
        feeCurrency?: string | null;
        feeTokenId?: string | null;
        feeQuantity?: number | null;
        feePrice: string;
        feeAddress?: string | null;
        buyPrice: string;
        sellPrice: string;
        buyQuantity: string;
        sellCurrency: string;
        sellTokenId?: string | null;
        sellAddress?: string | null;
        sellNftId?: string | null;
        sellQuantity: string;
        txnTimestamp: any;
        txnType: TxnType;
        unitPrice?: number | null;
        priceFetchingSide?: PriceFetchingSide | null;
        usdSpotPrice?: number | null;
        blocksvcMethodId?: string | null;
        blocksvcToAddress?: string | null;
        blocksvcFromAddress?: string | null;
        createdAt: any;
        updatedAt: any;
        isEdited?: boolean | null;
        isSpam: boolean;
        reviewed?: boolean | null;
        editedByReconGuideJob: boolean;
        reconIdentifier?: string | null;
        bkpVendorId?: number | null;
        integrationId?: string | null;
        toIntegrationId?: string | null;
        bkpAccountDebitId?: number | null;
        bkpAccountCreditId?: number | null;
        accountId?: number | null;
        toAccountId?: number | null;
        hasMovement?: boolean | null;
        bkpVendor?: {
          __typename?: 'BkpVendor';
          id: number;
          bkpIntegrationId?: string | null;
          bkpIntegrationDisplayName?: string | null;
        } | null;
        bkpAccountDebit?: {
          __typename?: 'BkpAccount';
          id: number;
          bkpIntegrationId?: string | null;
          name?: string | null;
        } | null;
        bkpAccountCredit?: {
          __typename?: 'BkpAccount';
          id: number;
          bkpIntegrationId?: string | null;
          name?: string | null;
        } | null;
        credential: {
          __typename?: 'Credential';
          accountId?: number | null;
          credentialType: string;
          source: CredentialSource;
          name?: string | null;
          address?: string | null;
          integrationId?: string | null;
        };
        specIdMatchesAsComponent: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
        specIdMatchesAsSell: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
      } | null;
    }>;
  };
};

export type LineItemsQueryVariables = Exact<{
  txnReportId: Scalars['Int']['input'];
  pagination: PaginationOptions;
  sortBy: LineItemSortOptions;
  sortDirection: SortDirectionOptions;
  filterQuery?: InputMaybe<LineItemsFilterQuery>;
  isAdmin: Scalars['Boolean']['input'];
  filterDust?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LineItemsQuery = {
  __typename?: 'Query';
  lineItems: {
    __typename?: 'LineItems';
    pageInfo: { __typename?: 'PageInfo'; filteredCount?: number | null };
    edges: Array<{
      __typename?: 'LineItem';
      id: any;
      buyId?: string | null;
      sellId?: string | null;
      sellCurrency?: string | null;
      buyCurrency?: string | null;
      unitsSold?: number | null;
      feeUnitsSold?: number | null;
      feeCurrency?: string | null;
      buyDate?: any | null;
      sellDate?: any | null;
      proceedsIncludingFees: number;
      costBasisIncludingFees: number;
      gainLossIncludingFees: number;
      term?: string | null;
      missingCostBasis?: boolean | null;
      splitBuyId?: string | null;
      txnReportId: number;
      isFee?: boolean | null;
      accountId?: number | null;
      account?: { __typename?: 'Account'; id: number; name: string } | null;
      txnLineItemSellIdTotxn?: {
        __typename?: 'Txn';
        id: string;
        associatedExchangeAddress?: string | null;
        blocksvcHash?: string | null;
        credentialId?: number | null;
        buyCurrency: string;
        buyTokenId?: string | null;
        buyAddress?: string | null;
        buyNftId?: string | null;
        description?: string | null;
        exchangeId?: string | null;
        exchangeName?: string | null;
        feeCurrency?: string | null;
        feeTokenId?: string | null;
        feeQuantity?: number | null;
        feePrice: string;
        feeAddress?: string | null;
        buyPrice: string;
        sellPrice: string;
        buyQuantity: string;
        sellCurrency: string;
        sellTokenId?: string | null;
        sellAddress?: string | null;
        sellNftId?: string | null;
        sellQuantity: string;
        txnTimestamp: any;
        txnType: TxnType;
        unitPrice?: number | null;
        priceFetchingSide?: PriceFetchingSide | null;
        usdSpotPrice?: number | null;
        blocksvcMethodId?: string | null;
        blocksvcToAddress?: string | null;
        blocksvcFromAddress?: string | null;
        createdAt: any;
        updatedAt: any;
        isEdited?: boolean | null;
        isSpam: boolean;
        reviewed?: boolean | null;
        editedByReconGuideJob: boolean;
        reconIdentifier?: string | null;
        bkpVendorId?: number | null;
        integrationId?: string | null;
        toIntegrationId?: string | null;
        bkpAccountDebitId?: number | null;
        bkpAccountCreditId?: number | null;
        accountId?: number | null;
        toAccountId?: number | null;
        hasMovement?: boolean | null;
        bkpVendor?: {
          __typename?: 'BkpVendor';
          id: number;
          bkpIntegrationId?: string | null;
          bkpIntegrationDisplayName?: string | null;
        } | null;
        bkpAccountDebit?: {
          __typename?: 'BkpAccount';
          id: number;
          bkpIntegrationId?: string | null;
          name?: string | null;
        } | null;
        bkpAccountCredit?: {
          __typename?: 'BkpAccount';
          id: number;
          bkpIntegrationId?: string | null;
          name?: string | null;
        } | null;
        credential: {
          __typename?: 'Credential';
          accountId?: number | null;
          credentialType: string;
          source: CredentialSource;
          name?: string | null;
          address?: string | null;
          integrationId?: string | null;
        };
        specIdMatchesAsComponent: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
        specIdMatchesAsSell: Array<{
          __typename?: 'SpecIdMatch';
          componentId: string;
          sellId: string;
          componentQuantity: number;
          componentOrder: number;
        }>;
      } | null;
    }>;
  };
};

export type UpdateReportDetailMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  settings: Settings;
}>;

export type UpdateReportDetailMutation = {
  __typename?: 'Mutation';
  updateReportDetail: {
    __typename?: 'UserTaxDetail';
    id: number;
    baseCurrency?: string | null;
    countryId: number;
    filingStatus?: string | null;
    method?: string | null;
    state?: string | null;
    marginalTaxRate?: number | null;
    longTermCapitalGainsRate?: number | null;
    taxYear: number;
  };
};

export type GetTaxLossHarvestingAssetsCsvQueryVariables = Exact<{
  taxLossHarvestingAssetsInput: TaxLossHarvestingAssetsInput;
}>;

export type GetTaxLossHarvestingAssetsCsvQuery = {
  __typename?: 'Query';
  taxLossHarvestingAssets: { __typename?: 'TaxLossHarvestingAssets'; csv?: string | null };
};

export type TaxLossHarvestingAssetsQueryVariables = Exact<{
  taxLossHarvestingAssetsInput: TaxLossHarvestingAssetsInput;
}>;

export type TaxLossHarvestingAssetsQuery = {
  __typename?: 'Query';
  taxLossHarvestingAssets: {
    __typename?: 'TaxLossHarvestingAssets';
    edges: Array<{
      __typename?: 'TaxLossHarvestingAsset';
      amount: number;
      currentUnitPrice?: number | null;
      id: number;
      taxLots: number;
      token: string;
      costBasis: number;
      totalMarketValue?: number | null;
      unrealizedGainLoss?: number | null;
      pricePaidPerUnit: number;
      lossesAmount?: number | null;
      isNft: boolean;
      address?: string | null;
    }>;
    pageInfo: { __typename?: 'PageInfo'; filteredCount?: number | null; totalCount?: number | null };
  };
};

export type GetTaxLossHarvestingTaxLotsCsvQueryVariables = Exact<{
  taxLossHarvestingTaxLotsInput: TaxLossHarvestingTaxLotsInput;
}>;

export type GetTaxLossHarvestingTaxLotsCsvQuery = {
  __typename?: 'Query';
  taxLossHarvestingTaxLots: { __typename?: 'TaxLossHarvestingTaxLots'; csv?: string | null };
};

export type TaxLossHarvestingTaxLotsQueryVariables = Exact<{
  taxLossHarvestingTaxLotsInput: TaxLossHarvestingTaxLotsInput;
}>;

export type TaxLossHarvestingTaxLotsQuery = {
  __typename?: 'Query';
  taxLossHarvestingTaxLots: {
    __typename?: 'TaxLossHarvestingTaxLots';
    edges: Array<{
      __typename?: 'TaxLossHarvestingTaxLot';
      amount: number;
      costBasis: number;
      currentUnitPrice?: number | null;
      dateAcquired: any;
      id: any;
      potentialSavings?: number | null;
      timeToLongTerm?: number | null;
      token: string;
      unrealizedGainLoss?: number | null;
    }>;
    pageInfo: { __typename?: 'PageInfo'; filteredCount?: number | null; totalCount?: number | null };
    totals: {
      __typename?: 'TaxLossHarvestingTaxLotsTotals';
      currentUnitPrice?: number | null;
      totalMarketValue?: number | null;
      totalCostBasis?: number | null;
      totalUnrealizedGainLoss?: number | null;
    };
    filteredTotals: {
      __typename?: 'TaxLossHarvestingTaxLotsFilteredTotals';
      costBasis?: number | null;
      unrealizedGainLoss?: number | null;
      potentialSavings?: number | null;
    };
  };
};

export type MarkAssetLostMutationVariables = Exact<{
  unsoldBuyReportId?: InputMaybe<Scalars['Int']['input']>;
  nftAddress?: InputMaybe<Scalars['String']['input']>;
}>;

export type MarkAssetLostMutation = {
  __typename?: 'Mutation';
  markAssetLost: { __typename?: 'CreatedCredential'; credential: { __typename?: 'Credential'; id: number } };
};

export type ClientsQueryVariables = Exact<{ [key: string]: never }>;

export type ClientsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'User';
    id: number;
    email: string;
    organization?: { __typename?: 'Organization'; name?: string | null } | null;
  }>;
};

export type SetClientPasswordMutationVariables = Exact<{
  clientId: Scalars['Int']['input'];
  password: Scalars['String']['input'];
  organizationId: Scalars['Int']['input'];
}>;

export type SetClientPasswordMutation = { __typename?: 'Mutation'; setClientPassword: boolean };

export type GenerateTaxDetailMutationVariables = Exact<{
  grossIncome?: InputMaybe<Scalars['Int']['input']>;
  countryId: Scalars['Int']['input'];
  baseCurrency: Scalars['String']['input'];
  taxYear: Scalars['Int']['input'];
  filingStatus?: InputMaybe<FilingStatusOptions>;
  state?: InputMaybe<StateOptions>;
}>;

export type GenerateTaxDetailMutation = { __typename?: 'Mutation'; generateTaxDetail: boolean };

export type AccountFragment = {
  __typename?: 'Account';
  id: number;
  userId: number;
  name: string;
  address?: string | null;
  createdAt: any;
  updatedAt: any;
  integrationId?: string | null;
};

export type CredentialFragment = {
  __typename?: 'Credential';
  id: number;
  source: CredentialSource;
  name?: string | null;
  address?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  filterSpam?: boolean | null;
  txnCount: number;
  credentialType: string;
  latestTransaction?: any | null;
  earliestTransaction?: any | null;
  createdAt: any;
  updatedAt: any;
  status?: EnumExchangeServiceRequestStatus | null;
  lastRefreshError?: string | null;
  errorRemediation?: string | null;
  integrationId?: string | null;
  isOauth: boolean;
  reconcileOnSync?: boolean | null;
  accountId?: number | null;
  ensAlias?: string | null;
};

export type DocumentFragment = {
  __typename?: 'Report';
  id: number;
  reportType: string;
  createdAt: any;
  createdById?: number | null;
  createdByAdmin?: boolean | null;
  status: string;
  taxYear?: string | null;
  method?: string | null;
  url?: string | null;
  failedReason?: string | null;
  hidden?: boolean | null;
  margin?: boolean | null;
  extraCols?: boolean | null;
};

export type InvoiceFragmentFragment = {
  __typename?: 'Invoice';
  id: string;
  userId: number;
  number?: string | null;
  created: number;
  dueDate?: number | null;
  currency: string;
  paid: boolean;
  voided: boolean;
  amountRemaining: number;
  description?: string | null;
  createdBy?: string | null;
};

export type OrganizationFragment = {
  __typename?: 'Organization';
  id: number;
  name?: string | null;
  slug?: string | null;
  status?: EnumOrganizationStatus | null;
  includeDemoUser?: boolean | null;
  discount?: number | null;
  logo?: string | null;
  colorPrimary?: string | null;
  colorSecondary?: string | null;
  colorLink?: string | null;
  createdAt: any;
  updatedAt: any;
  deletedAt?: any | null;
};

export type PlanFragment = { __typename?: 'Plan'; planId: string; year: number };

export type ProductsFragment = {
  __typename?: 'Products';
  cryptoOnlyPlans?: Array<{ __typename?: 'Plan'; planId: string; year: number }> | null;
  fullFilingPlans?: Array<{ __typename?: 'Plan'; planId: string; year: number }> | null;
  addons?: Array<{ __typename?: 'Plan'; planId: string; year: number }> | null;
};

export type SpecIdMatchFragment = {
  __typename?: 'SpecIdMatch';
  componentId: string;
  sellId: string;
  componentQuantity: number;
  componentOrder: number;
};

export type TaxDetailFragment = {
  __typename?: 'UserTaxDetail';
  id: number;
  baseCurrency?: string | null;
  calculateAsMargin?: boolean | null;
  createdAt: any;
  filingStatus?: string | null;
  fullFilingPlan?: string | null;
  grossIncome?: number | null;
  likeKind?: boolean | null;
  longTermCapitalGainsRate?: number | null;
  marginalTaxRate?: number | null;
  method?: string | null;
  needsFbar?: boolean | null;
  plan?: string | null;
  setFilingMethodSkipped?: boolean | null;
  skipped?: boolean | null;
  state?: string | null;
  taxYear: number;
  ukBnbRule?: boolean | null;
  updatedAt: any;
  useHoursMinsComparison?: boolean | null;
  userId: number;
  locked: boolean;
  isEngaged: boolean;
  addons: Array<{
    __typename?: 'UserTaxDetailAddon';
    id: number;
    addon: {
      __typename?: 'Addon';
      createdAt: any;
      displayName: string;
      price?: number | null;
      product: string;
      sku: string;
      updatedAt: any;
    };
  }>;
  country: {
    __typename?: 'Country';
    id: number;
    endDateDay: number;
    endDateMonth: number;
    name: string;
    shorthand: string;
    methods: Array<string>;
    startDateDay: number;
    startDateMonth: number;
    taxMethods: Array<{ __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string }>;
  };
  dateRange?: { __typename?: 'DateRange'; startDate: string; endDate: string } | null;
  taxMethod?: { __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string } | null;
};

export type TxnFragment = {
  __typename?: 'Txn';
  id: string;
  associatedExchangeAddress?: string | null;
  blocksvcHash?: string | null;
  credentialId?: number | null;
  buyCurrency: string;
  buyTokenId?: string | null;
  buyAddress?: string | null;
  buyNftId?: string | null;
  description?: string | null;
  exchangeId?: string | null;
  exchangeName?: string | null;
  feeCurrency?: string | null;
  feeTokenId?: string | null;
  feeQuantity?: number | null;
  feePrice: string;
  feeAddress?: string | null;
  buyPrice: string;
  sellPrice: string;
  buyQuantity: string;
  sellCurrency: string;
  sellTokenId?: string | null;
  sellAddress?: string | null;
  sellNftId?: string | null;
  sellQuantity: string;
  txnTimestamp: any;
  txnType: TxnType;
  unitPrice?: number | null;
  priceFetchingSide?: PriceFetchingSide | null;
  usdSpotPrice?: number | null;
  blocksvcMethodId?: string | null;
  blocksvcToAddress?: string | null;
  blocksvcFromAddress?: string | null;
  createdAt: any;
  updatedAt: any;
  isEdited?: boolean | null;
  isSpam: boolean;
  reviewed?: boolean | null;
  editedByReconGuideJob: boolean;
  reconIdentifier?: string | null;
  bkpVendorId?: number | null;
  integrationId?: string | null;
  toIntegrationId?: string | null;
  bkpAccountDebitId?: number | null;
  bkpAccountCreditId?: number | null;
  accountId?: number | null;
  toAccountId?: number | null;
  hasMovement?: boolean | null;
  bkpVendor?: {
    __typename?: 'BkpVendor';
    id: number;
    bkpIntegrationId?: string | null;
    bkpIntegrationDisplayName?: string | null;
  } | null;
  bkpAccountDebit?: {
    __typename?: 'BkpAccount';
    id: number;
    bkpIntegrationId?: string | null;
    name?: string | null;
  } | null;
  bkpAccountCredit?: {
    __typename?: 'BkpAccount';
    id: number;
    bkpIntegrationId?: string | null;
    name?: string | null;
  } | null;
  credential: {
    __typename?: 'Credential';
    accountId?: number | null;
    credentialType: string;
    source: CredentialSource;
    name?: string | null;
    address?: string | null;
    integrationId?: string | null;
  };
  specIdMatchesAsComponent: Array<{
    __typename?: 'SpecIdMatch';
    componentId: string;
    sellId: string;
    componentQuantity: number;
    componentOrder: number;
  }>;
  specIdMatchesAsSell: Array<{
    __typename?: 'SpecIdMatch';
    componentId: string;
    sellId: string;
    componentQuantity: number;
    componentOrder: number;
  }>;
};

export type UnreconciledTransactionResolutionFragment = {
  __typename?: 'UnreconciledTransactionResolution';
  id: number;
  unreconciledTransactionId: string;
  userId: number;
  credentialType: string;
  blocksvcHash: string;
  category: string;
  edited: boolean;
  appliedAt: any;
  invalidatedAt?: any | null;
  revertedAt?: any | null;
  drafts: Array<{
    __typename?: 'Draft';
    sent?: {
      __typename?: 'TransfersGroup';
      txns: Array<{
        __typename?: 'Transfer';
        id?: string | null;
        currency: string;
        tokenId?: string | null;
        quantity: string;
        type: TransferType;
      } | null>;
    } | null;
    received?: {
      __typename?: 'TransfersGroup';
      txns: Array<{
        __typename?: 'Transfer';
        id?: string | null;
        currency: string;
        tokenId?: string | null;
        quantity: string;
        type: TransferType;
      } | null>;
    } | null;
  }>;
};

export type UploadFragment = {
  __typename?: 'Upload';
  id: number;
  userId?: number | null;
  name: string;
  url?: string | null;
  createdAt: any;
  updatedAt: any;
  isHidden: boolean;
  uploadType?: string | null;
  credentialId?: number | null;
  credential?: { __typename?: 'Credential'; id: number; name?: string | null; deletedAt?: any | null } | null;
};

export type UserInfoFragment = {
  __typename?: 'User';
  id: number;
  accountYears: Array<number>;
  airtableId?: string | null;
  createdAt: any;
  customerId?: string | null;
  deletedAt?: any | null;
  email: string;
  firstName?: string | null;
  includeFees?: boolean | null;
  secretMode?: boolean | null;
  isTest?: boolean | null;
  lastName?: string | null;
  lastSuccessfulCharge?: any | null;
  lockedTilDate?: any | null;
  loginCount?: number | null;
  marginTradeAccount?: boolean | null;
  minimizationForAllYears?: boolean | null;
  orgDemoUser?: boolean | null;
  organizationId?: number | null;
  phone?: string | null;
  plan?: string | null;
  recalcByAccount?: boolean | null;
  recalcByAccountStartYear?: number | null;
  reconMode?: boolean | null;
  resetPasswordExpires?: any | null;
  resetPasswordToken?: string | null;
  role?: string | null;
  subscriptionId?: string | null;
  updatedAt: any;
  useHoursMinsComparison?: boolean | null;
  verified?: boolean | null;
  orgRoles: Array<{
    __typename?: 'UserOrgRole';
    userId: number;
    roleId: number;
    organizationId: number;
    createdAt: any;
    updatedAt: any;
    organization: {
      __typename?: 'Organization';
      id: number;
      name?: string | null;
      slug?: string | null;
      status?: EnumOrganizationStatus | null;
      includeDemoUser?: boolean | null;
      discount?: number | null;
      logo?: string | null;
      colorPrimary?: string | null;
      colorSecondary?: string | null;
      colorLink?: string | null;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
    };
    role: { __typename?: 'Role'; name: string };
  }>;
  taxDetails: Array<{
    __typename?: 'UserTaxDetail';
    id: number;
    baseCurrency?: string | null;
    calculateAsMargin?: boolean | null;
    createdAt: any;
    filingStatus?: string | null;
    fullFilingPlan?: string | null;
    grossIncome?: number | null;
    likeKind?: boolean | null;
    longTermCapitalGainsRate?: number | null;
    marginalTaxRate?: number | null;
    method?: string | null;
    needsFbar?: boolean | null;
    plan?: string | null;
    setFilingMethodSkipped?: boolean | null;
    skipped?: boolean | null;
    state?: string | null;
    taxYear: number;
    ukBnbRule?: boolean | null;
    updatedAt: any;
    useHoursMinsComparison?: boolean | null;
    userId: number;
    locked: boolean;
    isEngaged: boolean;
    addons: Array<{
      __typename?: 'UserTaxDetailAddon';
      id: number;
      addon: {
        __typename?: 'Addon';
        createdAt: any;
        displayName: string;
        price?: number | null;
        product: string;
        sku: string;
        updatedAt: any;
      };
    }>;
    country: {
      __typename?: 'Country';
      id: number;
      endDateDay: number;
      endDateMonth: number;
      name: string;
      shorthand: string;
      methods: Array<string>;
      startDateDay: number;
      startDateMonth: number;
      taxMethods: Array<{ __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string }>;
    };
    dateRange?: { __typename?: 'DateRange'; startDate: string; endDate: string } | null;
    taxMethod?: { __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string } | null;
  }>;
};

export type ChangeUserDataMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  secretMode?: InputMaybe<Scalars['Boolean']['input']>;
  reconMode?: InputMaybe<Scalars['Boolean']['input']>;
  recalcByAccount?: InputMaybe<Scalars['Boolean']['input']>;
  recalcByAccountStartYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ChangeUserDataMutation = { __typename?: 'Mutation'; changeUserData: boolean };

export type CreateMovementMutationVariables = Exact<{
  txnIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  currency: Scalars['String']['input'];
  amount: Scalars['String']['input'];
  toAccountId: Scalars['Int']['input'];
  fromAccountId: Scalars['Int']['input'];
}>;

export type CreateMovementMutation = { __typename?: 'Mutation'; createMovement: boolean };

export type DelinkSpecIdsMutationVariables = Exact<{
  sellId?: InputMaybe<Scalars['String']['input']>;
  componentId?: InputMaybe<Scalars['String']['input']>;
}>;

export type DelinkSpecIdsMutation = { __typename?: 'Mutation'; delinkSpecIds: boolean };

export type GenerateReportMutationVariables = Exact<{
  report: ReportCreateInput;
}>;

export type GenerateReportMutation = {
  __typename?: 'Mutation';
  generateReport: {
    __typename?: 'GenerateReportResult';
    jobId?: string | null;
    report?: {
      __typename?: 'Report';
      id: number;
      userId?: number | null;
      txnReportId?: number | null;
      reportType: string;
      extraCols?: boolean | null;
      margin?: boolean | null;
      hidden?: boolean | null;
      url?: string | null;
      taxYear?: string | null;
      status: string;
      failedReason?: string | null;
      method?: string | null;
      createdAt: any;
      updatedAt: any;
      txnReport?: { __typename?: 'TxnReport'; id: number } | null;
      user?: { __typename?: 'User'; id: number } | null;
    } | null;
  };
};

export type GetUploadUrlMutationVariables = Exact<{
  fileName: Scalars['String']['input'];
}>;

export type GetUploadUrlMutation = {
  __typename?: 'Mutation';
  getUploadURL: { __typename?: 'UploadUrlData'; Bucket: string; Key: string; url: string };
};

export type AddManualTxnsMutationVariables = Exact<{
  txns: Array<CreatedTxnInput> | CreatedTxnInput;
}>;

export type AddManualTxnsMutation = {
  __typename?: 'Mutation';
  addTransactions: {
    __typename?: 'CreatedCredential';
    credential: { __typename?: 'Credential'; id: number };
    exchangeServiceRequest: {
      __typename?: 'ExchangeServiceRequest';
      status?: EnumExchangeServiceRequestStatus | null;
    };
  };
};

export type ReviewTxnsMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  reviewed: Scalars['Boolean']['input'];
  isAdmin: Scalars['Boolean']['input'];
}>;

export type ReviewTxnsMutation = {
  __typename?: 'Mutation';
  reviewTxns: {
    __typename?: 'UpdatedTxnResponse';
    count: number;
    errors: Array<{ __typename?: 'FailedTransactionUpdate'; transactionId: string; error: string }>;
    updatedTxns: Array<{
      __typename?: 'Txn';
      id: string;
      associatedExchangeAddress?: string | null;
      blocksvcHash?: string | null;
      credentialId?: number | null;
      buyCurrency: string;
      buyTokenId?: string | null;
      buyAddress?: string | null;
      buyNftId?: string | null;
      description?: string | null;
      exchangeId?: string | null;
      exchangeName?: string | null;
      feeCurrency?: string | null;
      feeTokenId?: string | null;
      feeQuantity?: number | null;
      feePrice: string;
      feeAddress?: string | null;
      buyPrice: string;
      sellPrice: string;
      buyQuantity: string;
      sellCurrency: string;
      sellTokenId?: string | null;
      sellAddress?: string | null;
      sellNftId?: string | null;
      sellQuantity: string;
      txnTimestamp: any;
      txnType: TxnType;
      unitPrice?: number | null;
      priceFetchingSide?: PriceFetchingSide | null;
      usdSpotPrice?: number | null;
      blocksvcMethodId?: string | null;
      blocksvcToAddress?: string | null;
      blocksvcFromAddress?: string | null;
      createdAt: any;
      updatedAt: any;
      isEdited?: boolean | null;
      isSpam: boolean;
      reviewed?: boolean | null;
      editedByReconGuideJob: boolean;
      reconIdentifier?: string | null;
      bkpVendorId?: number | null;
      integrationId?: string | null;
      toIntegrationId?: string | null;
      bkpAccountDebitId?: number | null;
      bkpAccountCreditId?: number | null;
      accountId?: number | null;
      toAccountId?: number | null;
      hasMovement?: boolean | null;
      bkpVendor?: {
        __typename?: 'BkpVendor';
        id: number;
        bkpIntegrationId?: string | null;
        bkpIntegrationDisplayName?: string | null;
      } | null;
      bkpAccountDebit?: {
        __typename?: 'BkpAccount';
        id: number;
        bkpIntegrationId?: string | null;
        name?: string | null;
      } | null;
      bkpAccountCredit?: {
        __typename?: 'BkpAccount';
        id: number;
        bkpIntegrationId?: string | null;
        name?: string | null;
      } | null;
      credential: {
        __typename?: 'Credential';
        accountId?: number | null;
        credentialType: string;
        source: CredentialSource;
        name?: string | null;
        address?: string | null;
        integrationId?: string | null;
      };
      specIdMatchesAsComponent: Array<{
        __typename?: 'SpecIdMatch';
        componentId: string;
        sellId: string;
        componentQuantity: number;
        componentOrder: number;
      }>;
      specIdMatchesAsSell: Array<{
        __typename?: 'SpecIdMatch';
        componentId: string;
        sellId: string;
        componentQuantity: number;
        componentOrder: number;
      }>;
    }>;
  };
};

export type MarkTxnsSpamMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  isSpam: Scalars['Boolean']['input'];
  isAdmin: Scalars['Boolean']['input'];
}>;

export type MarkTxnsSpamMutation = {
  __typename?: 'Mutation';
  markTxnsSpam: {
    __typename?: 'UpdatedTxnResponse';
    count: number;
    errors: Array<{ __typename?: 'FailedTransactionUpdate'; transactionId: string; error: string }>;
    updatedTxns: Array<{
      __typename?: 'Txn';
      id: string;
      associatedExchangeAddress?: string | null;
      blocksvcHash?: string | null;
      credentialId?: number | null;
      buyCurrency: string;
      buyTokenId?: string | null;
      buyAddress?: string | null;
      buyNftId?: string | null;
      description?: string | null;
      exchangeId?: string | null;
      exchangeName?: string | null;
      feeCurrency?: string | null;
      feeTokenId?: string | null;
      feeQuantity?: number | null;
      feePrice: string;
      feeAddress?: string | null;
      buyPrice: string;
      sellPrice: string;
      buyQuantity: string;
      sellCurrency: string;
      sellTokenId?: string | null;
      sellAddress?: string | null;
      sellNftId?: string | null;
      sellQuantity: string;
      txnTimestamp: any;
      txnType: TxnType;
      unitPrice?: number | null;
      priceFetchingSide?: PriceFetchingSide | null;
      usdSpotPrice?: number | null;
      blocksvcMethodId?: string | null;
      blocksvcToAddress?: string | null;
      blocksvcFromAddress?: string | null;
      createdAt: any;
      updatedAt: any;
      isEdited?: boolean | null;
      isSpam: boolean;
      reviewed?: boolean | null;
      editedByReconGuideJob: boolean;
      reconIdentifier?: string | null;
      bkpVendorId?: number | null;
      integrationId?: string | null;
      toIntegrationId?: string | null;
      bkpAccountDebitId?: number | null;
      bkpAccountCreditId?: number | null;
      accountId?: number | null;
      toAccountId?: number | null;
      hasMovement?: boolean | null;
      bkpVendor?: {
        __typename?: 'BkpVendor';
        id: number;
        bkpIntegrationId?: string | null;
        bkpIntegrationDisplayName?: string | null;
      } | null;
      bkpAccountDebit?: {
        __typename?: 'BkpAccount';
        id: number;
        bkpIntegrationId?: string | null;
        name?: string | null;
      } | null;
      bkpAccountCredit?: {
        __typename?: 'BkpAccount';
        id: number;
        bkpIntegrationId?: string | null;
        name?: string | null;
      } | null;
      credential: {
        __typename?: 'Credential';
        accountId?: number | null;
        credentialType: string;
        source: CredentialSource;
        name?: string | null;
        address?: string | null;
        integrationId?: string | null;
      };
      specIdMatchesAsComponent: Array<{
        __typename?: 'SpecIdMatch';
        componentId: string;
        sellId: string;
        componentQuantity: number;
        componentOrder: number;
      }>;
      specIdMatchesAsSell: Array<{
        __typename?: 'SpecIdMatch';
        componentId: string;
        sellId: string;
        componentQuantity: number;
        componentOrder: number;
      }>;
    }>;
  };
};

export type UpdateTxnsMutationVariables = Exact<{
  txns: Array<TxnInput> | TxnInput;
  isAdmin: Scalars['Boolean']['input'];
}>;

export type UpdateTxnsMutation = {
  __typename?: 'Mutation';
  updateTxns: {
    __typename?: 'UpdatedTxnResponse';
    count: number;
    errors: Array<{ __typename?: 'FailedTransactionUpdate'; transactionId: string; error: string }>;
    updatedTxns: Array<{
      __typename?: 'Txn';
      id: string;
      associatedExchangeAddress?: string | null;
      blocksvcHash?: string | null;
      credentialId?: number | null;
      buyCurrency: string;
      buyTokenId?: string | null;
      buyAddress?: string | null;
      buyNftId?: string | null;
      description?: string | null;
      exchangeId?: string | null;
      exchangeName?: string | null;
      feeCurrency?: string | null;
      feeTokenId?: string | null;
      feeQuantity?: number | null;
      feePrice: string;
      feeAddress?: string | null;
      buyPrice: string;
      sellPrice: string;
      buyQuantity: string;
      sellCurrency: string;
      sellTokenId?: string | null;
      sellAddress?: string | null;
      sellNftId?: string | null;
      sellQuantity: string;
      txnTimestamp: any;
      txnType: TxnType;
      unitPrice?: number | null;
      priceFetchingSide?: PriceFetchingSide | null;
      usdSpotPrice?: number | null;
      blocksvcMethodId?: string | null;
      blocksvcToAddress?: string | null;
      blocksvcFromAddress?: string | null;
      createdAt: any;
      updatedAt: any;
      isEdited?: boolean | null;
      isSpam: boolean;
      reviewed?: boolean | null;
      editedByReconGuideJob: boolean;
      reconIdentifier?: string | null;
      bkpVendorId?: number | null;
      integrationId?: string | null;
      toIntegrationId?: string | null;
      bkpAccountDebitId?: number | null;
      bkpAccountCreditId?: number | null;
      accountId?: number | null;
      toAccountId?: number | null;
      hasMovement?: boolean | null;
      bkpVendor?: {
        __typename?: 'BkpVendor';
        id: number;
        bkpIntegrationId?: string | null;
        bkpIntegrationDisplayName?: string | null;
      } | null;
      bkpAccountDebit?: {
        __typename?: 'BkpAccount';
        id: number;
        bkpIntegrationId?: string | null;
        name?: string | null;
      } | null;
      bkpAccountCredit?: {
        __typename?: 'BkpAccount';
        id: number;
        bkpIntegrationId?: string | null;
        name?: string | null;
      } | null;
      credential: {
        __typename?: 'Credential';
        accountId?: number | null;
        credentialType: string;
        source: CredentialSource;
        name?: string | null;
        address?: string | null;
        integrationId?: string | null;
      };
      specIdMatchesAsComponent: Array<{
        __typename?: 'SpecIdMatch';
        componentId: string;
        sellId: string;
        componentQuantity: number;
        componentOrder: number;
      }>;
      specIdMatchesAsSell: Array<{
        __typename?: 'SpecIdMatch';
        componentId: string;
        sellId: string;
        componentQuantity: number;
        componentOrder: number;
      }>;
    }>;
  };
};

export type ExportTxnsMutationVariables = Exact<{
  sortBy: TxnSortOptions;
  sortDirection: SortDirectionOptions;
  filterQuery: TxnFilterQuery;
}>;

export type ExportTxnsMutation = { __typename?: 'Mutation'; exportTxns?: string | null };

export type RecalculateMutationVariables = Exact<{
  options?: InputMaybe<RecalculateOptions>;
}>;

export type RecalculateMutation = {
  __typename?: 'Mutation';
  recalculateTxnReports?: { __typename?: 'TxnReportJob'; id: number } | null;
};

export type DeleteCredentialsMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type DeleteCredentialsMutation = { __typename?: 'Mutation'; deleteCredentials: number };

export type UpdateTaxDetailsMutationVariables = Exact<{
  taxDetails: Array<UpdateTaxDetailsInput> | UpdateTaxDetailsInput;
  userId: Scalars['Int']['input'];
}>;

export type UpdateTaxDetailsMutation = { __typename?: 'Mutation'; updateTaxDetails: boolean };

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type ApplyReconGuideTreatmentsMutationVariables = Exact<{ [key: string]: never }>;

export type ApplyReconGuideTreatmentsMutation = {
  __typename?: 'Mutation';
  applyReconGuideTreatments: { __typename?: 'ApplyReconGuideTreatmentsResult'; id: string };
};

export type ProcessUploadMutationVariables = Exact<{
  Bucket: Scalars['String']['input'];
  Key: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  replacedFileId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProcessUploadMutation = {
  __typename?: 'Mutation';
  processUpload: { __typename?: 'ProcessUploadResponse'; requestId: string };
};

export type RestoreVersionMutationVariables = Exact<{
  versionId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  adminNotes?: InputMaybe<Scalars['String']['input']>;
}>;

export type RestoreVersionMutation = { __typename?: 'Mutation'; restoreVersion?: boolean | null };

export type SaveCurrentVersionMutationVariables = Exact<{
  description?: InputMaybe<Scalars['String']['input']>;
  adminNotes?: InputMaybe<Scalars['String']['input']>;
}>;

export type SaveCurrentVersionMutation = { __typename?: 'Mutation'; saveCurrentVersion?: boolean | null };

export type UpdateVersionMutationVariables = Exact<{
  versionId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  adminNotes?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateVersionMutation = {
  __typename?: 'Mutation';
  updateVersion?: {
    __typename?: 'UpdateVersionResponse';
    items: Array<{
      __typename?: 'Version';
      id: string;
      description?: string | null;
      adminNotes?: string | null;
    }>;
  } | null;
};

export type AllAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type AllAccountsQuery = {
  __typename?: 'Query';
  accounts: {
    __typename?: 'AccountsData';
    edges: Array<{
      __typename?: 'Account';
      id: number;
      userId: number;
      name: string;
      address?: string | null;
      createdAt: any;
      updatedAt: any;
      integrationId?: string | null;
    }>;
  };
};

export type AllCredentialsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']['input']>;
  extended: Scalars['Boolean']['input'];
  credentialsAdmin?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AllCredentialsQuery = {
  __typename?: 'Query';
  credentials: {
    __typename?: 'Credentials';
    edges: Array<{
      __typename?: 'Credential';
      id: number;
      name?: string | null;
      source: CredentialSource;
      address?: string | null;
      credentialType: string;
      accountId?: number | null;
      status?: EnumExchangeServiceRequestStatus | null;
      createdAt?: any;
      lastRefreshError?: string | null;
      credentials?: any | null;
      uploads?: Array<{ __typename?: 'Upload'; id: number }>;
    }>;
  };
};

export type CredentialByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type CredentialByIdQuery = {
  __typename?: 'Query';
  credential: {
    __typename?: 'Credential';
    id: number;
    source: CredentialSource;
    name?: string | null;
    address?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    filterSpam?: boolean | null;
    txnCount: number;
    credentialType: string;
    latestTransaction?: any | null;
    earliestTransaction?: any | null;
    createdAt: any;
    updatedAt: any;
    status?: EnumExchangeServiceRequestStatus | null;
    lastRefreshError?: string | null;
    errorRemediation?: string | null;
    integrationId?: string | null;
    isOauth: boolean;
    reconcileOnSync?: boolean | null;
    accountId?: number | null;
    ensAlias?: string | null;
  };
};

export type EvmGuideChainsQueryVariables = Exact<{ [key: string]: never }>;

export type EvmGuideChainsQuery = {
  __typename?: 'Query';
  evmGuideChains: Array<{
    __typename?: 'Chain';
    id: number;
    shortName: string;
    description?: string | null;
    credentialType?: string | null;
  }>;
};

export type EvmGuideInstructionQueryVariables = Exact<{
  methodId: Scalars['String']['input'];
  chain: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
}>;

export type EvmGuideInstructionQuery = {
  __typename?: 'Query';
  evmGuideInstruction?: {
    __typename?: 'EvmInstruction';
    id: number;
    contractId: number;
    treatmentId: number;
    blockSvcMethodId: number;
    notes?: string | null;
    updatedAt?: any | null;
    treatment?: {
      __typename?: 'Treatment';
      id: number;
      name: string;
      description?: string | null;
      reconOrder?: number | null;
    } | null;
  } | null;
};

export type FormOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type FormOptionsQuery = {
  __typename?: 'Query';
  countries: Array<{ __typename?: 'Country'; id: number; name: string }>;
  currencies: Array<{ __typename?: 'Currency'; countryId?: number | null; code: string }>;
};

export type IbcGuideInstructionQueryVariables = Exact<{
  identifier: Scalars['String']['input'];
}>;

export type IbcGuideInstructionQuery = {
  __typename?: 'Query';
  ibcGuideInstruction?: {
    __typename?: 'IdentifierBasedInstruction';
    id: number;
    identifier: string;
    treatmentId: number;
    notes?: string | null;
    publicName?: string | null;
    treatment?: {
      __typename?: 'Treatment';
      id: number;
      name: string;
      description?: string | null;
      reconOrder?: number | null;
    } | null;
  } | null;
};

export type InvoiceQueryVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  withProducts: Scalars['Boolean']['input'];
}>;

export type InvoiceQuery = {
  __typename?: 'Query';
  invoice: {
    __typename?: 'Invoice';
    newUser?: boolean | null;
    customerEmail?: string | null;
    amountDue: number;
    paidAt?: number | null;
    hideDefaultCrypto: boolean;
    paymentMethod?: InvoicePaymentMethod | null;
    cryptocurrency?: string | null;
    txnHash?: string | null;
    id: string;
    userId: number;
    number?: string | null;
    created: number;
    dueDate?: number | null;
    currency: string;
    paid: boolean;
    voided: boolean;
    amountRemaining: number;
    description?: string | null;
    createdBy?: string | null;
    lines: Array<{
      __typename?: 'Line';
      id: string;
      amount: number;
      currency: string;
      description?: string | null;
      unitAmount?: number | null;
      quantity?: number | null;
    }>;
    products?: {
      __typename?: 'Products';
      cryptoOnlyPlans?: Array<{ __typename?: 'Plan'; planId: string; year: number }> | null;
      fullFilingPlans?: Array<{ __typename?: 'Plan'; planId: string; year: number }> | null;
      addons?: Array<{ __typename?: 'Plan'; planId: string; year: number }> | null;
    } | null;
  };
};

export type LatestTxnReportsQueryVariables = Exact<{ [key: string]: never }>;

export type LatestTxnReportsQuery = {
  __typename?: 'Query';
  latestTxnReports?: {
    __typename?: 'LatestTxnReports';
    hasData: boolean;
    latestTxnReportsByYear: Array<{
      __typename?: 'LatestTxnReportsByYear';
      year: number;
      isOverTxnLimit: boolean;
      hasNoPlan: boolean;
      taxDetail: {
        __typename?: 'UserTaxDetail';
        id: number;
        plan?: string | null;
        fullFilingPlan?: string | null;
        baseCurrency?: string | null;
        filingStatus?: string | null;
        state?: string | null;
        method?: string | null;
        marginalTaxRate?: number | null;
        longTermCapitalGainsRate?: number | null;
        country: {
          __typename?: 'Country';
          id: number;
          shorthand: string;
          name: string;
          taxMethods: Array<{ __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string }>;
        };
        dateRange?: { __typename?: 'DateRange'; startDate: string; endDate: string } | null;
        taxMethod?: { __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string } | null;
      };
      reports?: Array<{
        __typename?: 'LatestTxnReport';
        lineItemCount: number;
        missingCostBasisCount: number;
        zeroProceedsCount: number;
        report: {
          __typename?: 'TxnReport';
          id: number;
          year: number;
          startDate?: any | null;
          endDate?: any | null;
          userTaxDetailId: number;
          fiatCurrency?: string | null;
          taxMethod: { __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string };
        };
        values: {
          __typename?: 'TxnReportValues';
          gainLoss: number;
          shortTermGainLoss: number;
          longTermGainLoss: number;
          income: number;
          taxLiability: number;
        };
      }> | null;
    }>;
  } | null;
};

export type MovementMatchConstraintsQueryVariables = Exact<{ [key: string]: never }>;

export type MovementMatchConstraintsQuery = {
  __typename?: 'Query';
  movementMatchConstraints: {
    __typename?: 'MovementMatchConstraint';
    quantityLeewayPercentage: number;
    timestampLeewayDays: number;
  };
};

export type TreatmentsQueryVariables = Exact<{ [key: string]: never }>;

export type TreatmentsQuery = {
  __typename?: 'Query';
  reconGuideTreatments: Array<{
    __typename?: 'Treatment';
    id: number;
    name: string;
    description?: string | null;
    reconOrder?: number | null;
    notes?: string | null;
  }>;
};

export type LatestReconGuideTreatmentsJobQueryVariables = Exact<{ [key: string]: never }>;

export type LatestReconGuideTreatmentsJobQuery = {
  __typename?: 'Query';
  latestReconGuideTreatmentsJob?: {
    __typename?: 'ReconGuideTreatmentsJob';
    id: string;
    status: EnumGenericJobStatus;
    createdAt: any;
    updatedAt?: any | null;
    jobData?: {
      __typename?: 'ReconGuideTreatmentsJobResults';
      appliedTreatments: Array<{
        __typename?: 'ReconGuideTreatmentsJobData';
        treatmentId: number;
        treatmentName: string;
        count: number;
      }>;
      summary?: {
        __typename?: 'ReconGuideTreatmentsJobResultsSummary';
        duration: number;
        txnsReconciled: number;
        txnTotal: number;
      } | null;
    } | null;
  } | null;
};

export type SolanaGuideInstructionQueryVariables = Exact<{
  identifier: Scalars['String']['input'];
}>;

export type SolanaGuideInstructionQuery = {
  __typename?: 'Query';
  solanaGuideInstruction?: {
    __typename?: 'IdentifierBasedInstruction';
    id: number;
    identifier: string;
    treatmentId: number;
    notes?: string | null;
    publicName?: string | null;
    treatment?: {
      __typename?: 'Treatment';
      id: number;
      name: string;
      description?: string | null;
      reconOrder?: number | null;
    } | null;
  } | null;
};

export type UploadDownloadUrlQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type UploadDownloadUrlQuery = { __typename?: 'Query'; uploadDownloadUrl: string };

export type VersionsQueryVariables = Exact<{ [key: string]: never }>;

export type VersionsQuery = {
  __typename?: 'Query';
  versions: {
    __typename?: 'VersionsResponse';
    items: Array<{
      __typename?: 'Version';
      description?: string | null;
      adminNotes?: string | null;
      id: string;
      createdAt: any;
      number: number;
      txnReportDataFifo?: any | null;
      txnReportDataLifo?: any | null;
      txnReportDataAverageCost?: any | null;
      txnReportDataMinimization?: any | null;
    }>;
    status: {
      __typename?: 'VersionsStatus';
      isSavingCurrentVersion: boolean;
      isRestoringVersion: boolean;
      restoredVersionNumber?: number | null;
      lastRestoredAt?: any | null;
      latestStatus?: EnumGenericJobStatus | null;
    };
  };
};

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshTokenMutation = { __typename?: 'Mutation'; refreshToken: boolean };

export type SupportedCredentialsQueryVariables = Exact<{ [key: string]: never }>;

export type SupportedCredentialsQuery = {
  __typename?: 'Query';
  supportedCredentials: {
    __typename?: 'SupportedCredentials';
    supportedWallets: Array<string>;
    reconCredentialTypes: Array<string>;
    evmCredentialTypes: Array<string>;
    solanaCredentialTypes: Array<string>;
    exchangeCredentials: Array<{ __typename?: 'ExchangeCredentials'; id: string; schema: any }>;
  };
};

export type PaymentSourcesQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentSourcesQuery = {
  __typename?: 'Query';
  paymentSources: Array<{
    __typename?: 'CreditCard';
    id: string;
    addressZip?: string | null;
    brand: string;
    expMonth: number;
    expYear: number;
    last4: string;
  }>;
};

export type NeedsRecalcQueryVariables = Exact<{ [key: string]: never }>;

export type NeedsRecalcQuery = { __typename?: 'Query'; needsRecalc: boolean };

export type LatestTxnReportJobQueryVariables = Exact<{ [key: string]: never }>;

export type LatestTxnReportJobQuery = {
  __typename?: 'Query';
  latestTxnReportJob?: {
    __typename?: 'TxnReportJob';
    createdAt: any;
    error?: string | null;
    errorMessage?: string | null;
    finishedAt?: any | null;
    jobStatus: TxnReportJobStatus;
  } | null;
};

export type TokenOverridesQueryVariables = Exact<{ [key: string]: never }>;

export type TokenOverridesQuery = {
  __typename?: 'Query';
  tokenOverridesList: Array<{ __typename?: 'TokenOverrideConf'; symbol: string; id: string }>;
};

export type FetchUserQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: number;
    accountYears: Array<number>;
    airtableId?: string | null;
    createdAt: any;
    customerId?: string | null;
    deletedAt?: any | null;
    email: string;
    firstName?: string | null;
    includeFees?: boolean | null;
    secretMode?: boolean | null;
    isTest?: boolean | null;
    lastName?: string | null;
    lastSuccessfulCharge?: any | null;
    lockedTilDate?: any | null;
    loginCount?: number | null;
    marginTradeAccount?: boolean | null;
    minimizationForAllYears?: boolean | null;
    orgDemoUser?: boolean | null;
    organizationId?: number | null;
    phone?: string | null;
    plan?: string | null;
    recalcByAccount?: boolean | null;
    recalcByAccountStartYear?: number | null;
    reconMode?: boolean | null;
    resetPasswordExpires?: any | null;
    resetPasswordToken?: string | null;
    role?: string | null;
    subscriptionId?: string | null;
    updatedAt: any;
    useHoursMinsComparison?: boolean | null;
    verified?: boolean | null;
    orgRoles: Array<{
      __typename?: 'UserOrgRole';
      userId: number;
      roleId: number;
      organizationId: number;
      createdAt: any;
      updatedAt: any;
      organization: {
        __typename?: 'Organization';
        id: number;
        name?: string | null;
        slug?: string | null;
        status?: EnumOrganizationStatus | null;
        includeDemoUser?: boolean | null;
        discount?: number | null;
        logo?: string | null;
        colorPrimary?: string | null;
        colorSecondary?: string | null;
        colorLink?: string | null;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
      };
      role: { __typename?: 'Role'; name: string };
    }>;
    taxDetails: Array<{
      __typename?: 'UserTaxDetail';
      id: number;
      baseCurrency?: string | null;
      calculateAsMargin?: boolean | null;
      createdAt: any;
      filingStatus?: string | null;
      fullFilingPlan?: string | null;
      grossIncome?: number | null;
      likeKind?: boolean | null;
      longTermCapitalGainsRate?: number | null;
      marginalTaxRate?: number | null;
      method?: string | null;
      needsFbar?: boolean | null;
      plan?: string | null;
      setFilingMethodSkipped?: boolean | null;
      skipped?: boolean | null;
      state?: string | null;
      taxYear: number;
      ukBnbRule?: boolean | null;
      updatedAt: any;
      useHoursMinsComparison?: boolean | null;
      userId: number;
      locked: boolean;
      isEngaged: boolean;
      addons: Array<{
        __typename?: 'UserTaxDetailAddon';
        id: number;
        addon: {
          __typename?: 'Addon';
          createdAt: any;
          displayName: string;
          price?: number | null;
          product: string;
          sku: string;
          updatedAt: any;
        };
      }>;
      country: {
        __typename?: 'Country';
        id: number;
        endDateDay: number;
        endDateMonth: number;
        name: string;
        shorthand: string;
        methods: Array<string>;
        startDateDay: number;
        startDateMonth: number;
        taxMethods: Array<{ __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string }>;
      };
      dateRange?: { __typename?: 'DateRange'; startDate: string; endDate: string } | null;
      taxMethod?: { __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string } | null;
    }>;
  } | null;
};

export type LatestBatchUpdateTxnsJobStatusQueryVariables = Exact<{ [key: string]: never }>;

export type LatestBatchUpdateTxnsJobStatusQuery = {
  __typename?: 'Query';
  latestBatchUpdateTxnsJobStatus?: EnumGenericJobStatus | null;
};

export type LatestBatchDeleteTxnsJobStatusQueryVariables = Exact<{ [key: string]: never }>;

export type LatestBatchDeleteTxnsJobStatusQuery = {
  __typename?: 'Query';
  latestBatchDeleteTxnsJobStatus?: EnumGenericJobStatus | null;
};

export type UserTaxMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type UserTaxMethodsQuery = {
  __typename?: 'Query';
  userTaxMethods: {
    __typename?: 'UserTaxMethods';
    methods: Array<{ __typename?: 'TaxMethod'; id: EnumTaxMethod; label: string }>;
  };
};
