import React, { useContext } from 'react';
import RecalculateToast, { NoTaxYearsToast } from './RecalculateToast';
import { RecalcContext, useVersionsContext } from '../../contexts';

import { LoadingToast } from '../Toast';

function StatusToast() {
  const { versions, isRestoringOrRefetching } = useVersionsContext();
  const { isRecalculating, recalculate, latestJobState, noTaxYearsToRecalculate } = useContext(RecalcContext);
  const { restoredVersionNumber } = versions?.status ?? {};

  if (isRestoringOrRefetching) {
    return <LoadingToast>Version {restoredVersionNumber} is being restored</LoadingToast>;
  }

  if (noTaxYearsToRecalculate) {
    return (
      <NoTaxYearsToast
        onClick={() => {
          recalculate({ allYearOverride: true });
        }}
      />
    );
  }

  if (isRecalculating || latestJobState) {
    return <RecalculateToast />;
  }

  return null;
}

export default React.memo(StatusToast);
