import { CATEGORY_DATA } from '../../Wizard/constants';
import { ComplexTreatmentResult, Props } from './types';

export const repayWithIncome = (props: Props): ComplexTreatmentResult => {
  const { txn, lpTokenTickersByAddress, sentTxns, receivedTxns } = props;
  const { tokenParam1, tokenParam2 } = txn;

  if (!tokenParam1?.length && !tokenParam2?.length) return { drafts: null };

  const matchingWithdrawals = sentTxns.filter(
    ({ txnType, sellCurrency }) =>
      txnType === 'withdrawal' &&
      tokenParam1?.map((t) => t.toLowerCase()).includes(sellCurrency.toLowerCase()),
  );

  const matchingDeposits = sentTxns.filter(
    ({ txnType, buyCurrency }) =>
      txnType === 'deposit' && tokenParam2?.map((t) => t.toLowerCase()).includes(buyCurrency.toLowerCase()),
  );

  if ([matchingDeposits, matchingWithdrawals].some((arr) => arr.length > 1)) {
    // Cannot apply treatment "Repay with Income": multiple matching txns found
    return {
      drafts: null,
    };
  }

  const drafts = [
    ...sentTxns.map((txn) => ({
      sent: {
        grouped: false as const,
        txns: [
          {
            id: txn.id,
            type: txn.id === matchingWithdrawals[0]?.id ? CATEGORY_DATA.repay.sentType! : ('sent' as const),
            currency: lpTokenTickersByAddress[txn.sellAddress!] ?? txn.sellCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            tokenId: txn.sellTokenId,
            quantity: txn.sellQuantity,
            price: txn.sellPrice,
          },
        ],
      },
    })),
    ...receivedTxns.map((txn) => ({
      received: {
        grouped: false as const,
        txns: [
          {
            id: txn.id,
            type:
              txn.id === matchingDeposits[0]?.id ? CATEGORY_DATA.income.receivedType! : ('received' as const),
            currency: lpTokenTickersByAddress[txn.buyAddress!] ?? txn.buyCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            tokenId: txn.buyTokenId,
            quantity: txn.buyQuantity,
            price: txn.buyPrice,
          },
        ],
      },
    })),
  ];

  return { drafts };
};
