import { default as cx } from 'classnames';
import React, {
  ChangeEventHandler,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import Label from './Label';
import baseClassName from './styles';

export { default as CheckboxInput } from './Checkbox';
export { default as Label } from './Label';
export { default as NumberInput } from './Number';
export { default as RadioInput } from './Radio';

export type InputVariant = 'success' | 'critical';

export const inputVarantClassMap: Record<InputVariant, string> = {
  success: '!border-light-alert-success !dark:border-dark-alert-success',
  critical: '!border-light-alert-critical !dark:border-dark-alert-critical',
};

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'tabIndex'> {
  /** label for input */
  label?: string;
  /** tab order */
  tabIndex?: number;
  /* if true, the input will be focused after mounting */
  initiallyFocused?: boolean;
  /* called when the enter/return key is pressed */
  onEnterPressed?: (value?: string) => void;
  padding?: string;
  renderSideElement?: (input: React.RefObject<HTMLInputElement>) => ReactNode;
  /* like onChange, but this one is passed a string instead of the event */
  onValueChange?: (value: string) => void;
  name?: string;
  transparent?: boolean;
  variant?: InputVariant;
}

export type InputHandle = {
  focus: () => void;
  blur: () => void;
  setValue: (value: string) => void;
  getValue: () => string | undefined;
};

export default forwardRef<InputHandle, InputProps>(function Input(
  {
    label,
    className,
    tabIndex,
    value,
    padding = 'p-4',
    initiallyFocused = false,
    onEnterPressed,
    renderSideElement,
    onChange,
    onValueChange,
    ...props
  }: InputProps,
  forwardedRef,
) {
  const inputRef = useRef<HTMLInputElement>(null);
  const appliedClassName = props.transparent
    ? cx('bg-transparent w-full outline-none', className, padding)
    : cx(className, baseClassName, padding);

  const variantClass = inputVarantClassMap[props.variant as InputVariant] ?? '';

  useEffect(() => {
    if (initiallyFocused) {
      inputRef.current?.focus();
    }
  }, [initiallyFocused]);

  useImperativeHandle(forwardedRef, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
    blur: () => {
      inputRef.current?.blur();
    },
    setValue: (value: string) => {
      inputRef.current!.value = value; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    },
    getValue: () => inputRef.current?.value,
  }));

  return (
    <>
      {label && <Label htmlFor={props.id || label}>{label}</Label>}
      <div className="inline-block w-full relative">
        <input
          ref={inputRef}
          tabIndex={tabIndex}
          value={value}
          onKeyPress={(evt) => {
            props?.onKeyPress?.(evt);
            if (evt.key === 'Enter') {
              onEnterPressed?.(inputRef.current?.value);
            }
          }}
          className={cx(appliedClassName, variantClass)}
          onChange={useCallback<ChangeEventHandler<HTMLInputElement>>(
            (evt) => {
              onChange?.(evt);
              onValueChange?.(evt.target.value);
            },
            [onChange, onValueChange],
          )}
          {...props}
        />

        <div className="absolute right-0 top-0 h-full mt-px mr-0.5">{renderSideElement?.(inputRef)}</div>
      </div>
    </>
  );
});
