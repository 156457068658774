import cx from 'classnames';
import React, { ComponentProps } from 'react';
import NumberFormat, { ColoredNumberFormat, NumberFormatProps } from '../../NumberFormat';
import { useTableContext } from '../context';
import { CellProps } from '../types';

type CurrencyType = number | null | undefined;

interface Props<T extends Record<string, unknown>> extends CellProps<T, CurrencyType> {
  formatProps?: NumberFormatProps;
  Component?: React.ComponentType<ComponentProps<typeof NumberFormat>>;
  className?: string;
}

function CurrencyCell<T extends Record<string, unknown>>(props: Props<T>): React.ReactElement {
  const { cell, children, className, column } = props;
  const { rightAligned } = column;
  const { baseCurrency } = useTableContext();

  const formatProps = {
    currency: baseCurrency || 'USD',
    comma: true,
    ...props.formatProps,
  };

  const { value } = cell;
  const Component = props.Component || NumberFormat;

  return (
    <div
      className={className || cx('flex', rightAligned ? 'justify-end' : 'justify-start')}
      style={{ paddingRight: 16 }}
    >
      {children && children}
      <Component value={value} {...formatProps} />
    </div>
  );
}

export default React.memo(CurrencyCell) as unknown as typeof CurrencyCell;

export function ColoredCurrencyCell<T extends Record<string, unknown>>(props: Omit<Props<T>, 'Component'>) {
  return <CurrencyCell<T> {...props} Component={ColoredNumberFormat} />;
}
