import { gql, MutationHookOptions, useMutation, useQuery } from '@apollo/client';
import { Required } from 'utility-types';
import { TXN } from '../../graphql/fragments';
import { EXPORT_TXNS } from '../../graphql/mutations';
import {
  ExportTxnsMutation,
  ExportTxnsMutationVariables,
  MergeTxnsMutation,
  MergeTxnsMutationVariables,
  SortDirectionOptions,
  TxnCountQuery,
  TxnSortOptions,
  TxnsQuery,
  TxnsQueryVariables,
} from '../../graphql/types';
import { getPaginationOptions, PaginationState } from '../utils/pagination';
import type { FiltersState } from './types';
import Auth from '../../lib/Auth';

export const TXNS = gql`
  query txns(
    $pagination: PaginationOptions!
    $sortBy: TxnSortOptions!
    $sortDirection: SortDirectionOptions!
    $filterQuery: TxnFilterQuery!
    $isAdmin: Boolean!
  ) {
    txns(
      txnTableInput: {
        pagination: $pagination
        sortBy: $sortBy
        sortDirection: $sortDirection
        filterQuery: $filterQuery
      }
    ) {
      pageInfo {
        filteredCount
      }
      edges {
        ...Txn
      }
    }
  }
  ${TXN}
`;

interface UseTxnProps {
  sortState: {
    sortBy: TxnSortOptions;
    sortDir: SortDirectionOptions;
  };
  filtersState: FiltersState;
  paginationState: PaginationState;
}

export function useTxns({ paginationState, sortState, filtersState }: UseTxnProps) {
  const { filterQuery } = filtersState;
  const { data, loading, ...rest } = useQuery<TxnsQuery, TxnsQueryVariables>(TXNS, {
    variables: {
      pagination: getPaginationOptions(paginationState),
      sortBy: sortState.sortBy,
      sortDirection: sortState.sortDir,
      filterQuery: JSON.parse(filterQuery || '{}'),
      isAdmin: Auth.tokenIsForAdmin(),
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first', // don't refetch if a mutation (e.g. updateTxns) updates the cache
  });

  return { ...rest, data: data?.txns, loading: !data?.txns.edges.length && loading };
}

type UseTxnsCSVExportProps = Required<
  MutationHookOptions<ExportTxnsMutation, ExportTxnsMutationVariables>,
  'variables'
>;

export function useTxnsCSVExport({ ...props }: UseTxnsCSVExportProps) {
  return useMutation<ExportTxnsMutation, ExportTxnsMutationVariables>(EXPORT_TXNS, {
    ...props,
  });
}

export const TXN_COUNT_DATA = gql`
  query txnCount {
    userTxnCount {
      totalCount
      reviewedCount
      txnTypeCounts {
        count
        type
      }
      txnOtherFiltersCounts {
        count
        type
      }
      txnImportTypeCounts {
        count
        importType
      }
    }
  }
`;

export function useTxnsCounts() {
  const { data, loading, ...rest } = useQuery<TxnCountQuery>(TXN_COUNT_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  return { ...rest, data: data?.userTxnCount, loading: !data && loading };
}

const MERGE_TRANSACTIONS = gql`
  mutation mergeTxns($txnIds: [String!]!) {
    mergeTxns(txnIds: $txnIds)
  }
`;

export function useMergeTxns(options?: MutationHookOptions<MergeTxnsMutation, MergeTxnsMutationVariables>) {
  return useMutation<MergeTxnsMutation, MergeTxnsMutationVariables>(MERGE_TRANSACTIONS, options);
}
