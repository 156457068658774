import { makeVar } from '@apollo/client';
import { defaultPaginationOptions, defaultSortState } from './defaults';
import { PaginationState } from '../../utils/pagination';
import { sortState } from './types';

export const sortVar = makeVar<sortState>(defaultSortState);
export const paginationVar = makeVar<PaginationState>(defaultPaginationOptions);
export const filterQueryVar = makeVar<string | undefined>(undefined);
export const initialLoadingVar = makeVar<boolean>(true);
export const initialOptionsSetVar = makeVar<boolean>(false);
export const initialQuerySetVar = makeVar<boolean>(false);
export const expandedIdVar = makeVar<number | undefined>(undefined);
