import { gql } from '@apollo/client';

export const UPLOAD = gql`
  fragment Upload on Upload {
    id
    userId
    name
    url
    createdAt
    updatedAt
    isHidden
    uploadType
    credentialId
    credential {
      id
      name
      deletedAt
    }
  }
`;
