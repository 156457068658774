import React from 'react';
import { FilterOperatorTypes } from '../../../graphql/types';
import Filter from '../Filter';
import { FilterProps } from '../types';
import SearchInput from './SearchInput';
import SearchPill, { SearchPillProps } from './SearchPill';
import { BaseSearchFilter } from './types';

type PillProps<FilterType extends BaseSearchFilter> = Pick<SearchPillProps<FilterType>, 'filterKey'>;

const { Is, IsNot, Ctns, DNCtn } = FilterOperatorTypes;

export { default as SearchInput } from './SearchInput';

function SearchFilter<FilterType extends BaseSearchFilter>(
  props: FilterProps<FilterType> & PillProps<FilterType>,
) {
  const { filterKey, ...rest } = props;

  return (
    <Filter<FilterType, PillProps<FilterType>>
      Input={SearchInput}
      Pill={SearchPill}
      props={{ filterKey }}
      operators={[Is, IsNot, Ctns, DNCtn]}
      {...rest}
    />
  );
}

export default React.memo(SearchFilter) as typeof SearchFilter;
