import React, { useCallback, useEffect, useState } from 'react';
import { DeepNonNullable } from 'utility-types';
import { InvoiceQuery } from '../../graphql/types';
import TextLink from '../TextLink';
import LineItem, { LineItemData } from './LineItem';

type Line = InvoiceQuery['invoice']['lines'][0];

interface Props {
  updateInvoice: (lines: DeepNonNullable<Line>[]) => void;
}

function LineItems({ updateInvoice }: Props) {
  const [lineItems, setLineItems] = useState<LineItemData[]>([]);

  useEffect(() => {
    updateInvoice(
      lineItems.map((lineItem, index) => ({
        ...lineItem,
        __typename: 'Line' as const,
        id: `lineItem_${index}`,
        amount: lineItem.quantity * lineItem.unitAmount,
        currency: 'USD',
      })),
    );
  }, [lineItems, updateInvoice]);

  const addLineItem = useCallback(() => {
    setLineItems((lineItems) => [
      ...lineItems,
      {
        description: '',
        unitAmount: 100,
        quantity: 1,
      },
    ]);
  }, []);

  // adds the first line item when the component is loaded
  useEffect(addLineItem, [addLineItem]);

  const removeLineItem = useCallback(
    (index: number) => {
      setLineItems(lineItems.filter((_el, arrIndex) => arrIndex !== index));
    },
    [lineItems],
  );

  const onChange = useCallback(
    (index: number, column: string, value: string | number) => {
      setLineItems(
        lineItems.map((el, arrIndex) => {
          if (arrIndex === index) {
            return {
              ...el,
              [column]: value,
            };
          }

          return el;
        }),
      );
    },
    [lineItems],
  );

  return (
    <div
      className="block sm:grid"
      style={{
        gridTemplateColumns: '4fr 1.5fr 2fr 1fr',
      }}
    >
      <div className="hidden sm:block">Description</div>
      <div className="hidden sm:block">Qty</div>
      <div className="hidden sm:block">Price</div>
      <div />

      <div className="divide-y sm:contents">
        {lineItems.map((lineItem, index) => (
          <LineItem key={index} {...{ lineItem, onChange, index, removeLineItem }} />
        ))}
      </div>

      <TextLink className="mt-4 text-left" onClick={addLineItem}>
        Add line item
      </TextLink>
    </div>
  );
}

export default React.memo(LineItems);
