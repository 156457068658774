import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { PickByValue } from 'utility-types';
import Icon from '../../Icon';
import { Cell } from '../types';

type CellValueType = string | null;

interface DateCellProps<T extends Record<string, unknown>, Prop extends keyof PickByValue<T, CellValueType>> {
  cell: Cell<T, Extract<T[Prop], CellValueType>>;
  rowIsDisabled?: boolean;
  filter?: Filter;
  relativeDate?: boolean; // e.g. '1 year, 2 days ago'
}

type Filter = {
  url: string; // e.g. `${pathname}?txnHash=${blocksvcHash}`
  name: string; // e.g. 'Method ID'
  onFilter: () => void;
};

function DateCell<T extends Record<string, unknown>, Prop extends keyof PickByValue<T, CellValueType>>(
  props: DateCellProps<T, Prop>,
): React.ReactElement {
  const {
    cell: { value, column },
    filter,
    relativeDate = false,
  } = props;

  const { fullTimestamp, dateFormat } = column;

  let isDate = false;
  let dateFormatted;
  let dateMoment;

  if (value) {
    dateMoment = moment(value);
    if (dateMoment.isValid()) {
      if (relativeDate) {
        dateFormatted = dateMoment.fromNow();
      } else {
        dateFormatted = dateMoment.utc().format(dateFormat ?? `MM/DD/YYYY${fullTimestamp ? ' h:mm A' : ''}`);
      }
      isDate = true;
    }
  }

  return (
    <div className="flex">
      {isDate ? (
        <div className="flex-shrink truncate" title={dateMoment?.utc().format('MM/DD/YYYY h:mm A UTC')}>
          {dateFormatted}
        </div>
      ) : (
        '-'
      )}
      {filter && (
        <div className={classNames('row_visible_if_hovered relative')} style={{ minWidth: '18px' }}>
          <a
            href={filter.url}
            onClick={(e) => {
              if (e.ctrlKey || e.metaKey) return;
              e.preventDefault();
              filter.onFilter();
            }}
          >
            <Icon
              type="filter"
              className="cursor-pointer inline-block px-1"
              title={`Filter by ${filter.name}`}
            />
          </a>
        </div>
      )}
    </div>
  );
}

export default React.memo(DateCell) as unknown as typeof DateCell;
