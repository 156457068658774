import classNames from 'classnames';
import React from 'react';
import { textLinkVariantClassnames } from '../TextLink';
import { useTheme } from '../../theme/useTheme';

import darkSvg from './svg/logo-white.svg';
import lightSvg from './svg/logo-black.svg';

const TrustBox = () => {
  const { theme } = useTheme();
  const src = theme === 'dark' ? darkSvg : lightSvg;

  return (
    <div className="w-[120px] m-auto">
      <a
        href="https://www.trustpilot.com/review/tokentax.co"
        target="_blank"
        rel="noreferrer noopener"
        className={classNames(textLinkVariantClassnames.primary)}
      >
        <img src={src} alt="Trustpilot logo" />
      </a>
    </div>
  );
};

export default TrustBox;
