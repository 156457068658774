import React, { ReactNode } from 'react';
import Text from '../Text';

const PillBody: React.FC<{ label?: string; onDelete: () => void; children: ReactNode }> = ({
  label,
  onDelete,
  children,
}) => {
  return (
    <div className="flex text-sm border border-light-control dark:border-dark-control bg-light-selected dark:bg-dark-selected rounded">
      {label && (
        <div className="p-1 px-2 border-r border-light-control dark:border-dark-control rounded-l flex items-center">
          <Text variant="muted"> {label}</Text>
        </div>
      )}
      {children}
      <button
        onClick={onDelete}
        className="px-2 border-l border-light-control dark:border-dark-control hover:bg-light-selected-faint dark:hover:bg-dark-selected-faint rounded-r flex items-center"
      >
        <span className="material-icons" style={{ fontSize: '14px' }}>
          close
        </span>
      </button>
    </div>
  );
};

export default React.memo(PillBody);
