import React, { useCallback } from 'react';
import { showExportCSVModal } from '../ExportCSVModal';
import { SortState, FiltersState } from '../types';
import TextLink from '../../TextLink';

interface Props {
  sortState: SortState;
  filtersState: FiltersState;
}

function ExportAction({ filtersState, sortState }: Props) {
  return (
    <TextLink
      className="flex items-center"
      onClick={useCallback(
        () =>
          showExportCSVModal({
            filtersState,
            sortState,
          }),
        [filtersState, sortState],
      )}
    >
      <span className="material-icons relative top-[3px] mr-1" style={{ fontSize: 14 }}>
        south
      </span>
      Export
    </TextLink>
  );
}

export default React.memo(ExportAction);
