export const CALC_BY_ACCOUNT_START_YEAR = 2025;
export const FAILED_JOB_RELEVANCY_TIMEFAME_MS = 1000 * 60 * 60 * 24 * 2; // 2 days

export type ReconRoute = {
  id: string;
  name: string;
  path: string;
  children?: ReconRoute[];
};

export const helpCenterArticles = {
  addMissingExchanges: 'https://help.tokentax.co/en/articles/9067329-missing-exchanges',
  calcByAccount: 'https://help.tokentax.co/en/', // TODO- set link when article is live
};

export const missingPriceTxnTypes = [
  'airdrop',
  'borrow',
  'fork',
  'income',
  'migration',
  'mining',
  'staking',
  'trade',
];

export const reconPageBodyText = {
  automatedTransactions: {
    title: 'TokenTax Automated Resolution',
    subtitle: 'We recognized some transaction types in your account',
    statusHeader: {
      recon: 'Reconciling your transactions...',
      accounts: 'Generating Accounts...',
      movements: 'Creating movements...',
    },
  },
  missingExchanges: {
    title: 'Missing exchanges',
    subtitle: 'We recognized transfers in and out of an exchange but no corresponding exchange imports',
  },
  missingWallets: {
    title: 'Missing wallets',
    subtitle: 'These wallets have transactions on other chains. Would you like to import them?',
  },
  pricing: {
    title: 'Missing prices',
    subtitle: 'Some of the transactions in your account are missing pricing data',
    statusHeader: 'Fetching pricing from ±0 days...',
    processingText: 'Scanning...',
  },
  costBasis: {
    title: 'Cost basis',
    subtitle: 'Reconciliation issues by priority in your account',
  },
  movements: {
    title: 'Movements',
    subtitle: 'Represent the transfer of cost basis from one account to another',
  },
};

export const selfReconRoutes = {
  overview: {
    name: 'Overview',
  },
  preparation: {
    name: 'Preparation',
    children: [
      { id: 'missing-wallets', name: 'Import missing wallets', path: '/recon/imports/missing-wallets' },
      { id: 'missing-exchanges', name: 'Sync missing exchanges', path: '/recon/imports/missing-exchanges' },
    ],
  },
  reconciliation: {
    name: 'Reconciliation',
    children: [
      {
        id: 'automated-transactions',
        name: 'Automatic reconciliation',
        path: '/recon/transactions/automated',
      },
      {
        id: 'unreconciled-transactions',
        name: 'Reconciliation wizard',
        path: '/recon/transactions/unreconciled',
      },
    ],
  },
  pricing: {
    name: 'Pricing',
    children: [
      {
        id: 'automated-pricing',
        name: 'Missing Prices',
        path: '/recon/pricing/automated',
      },
    ],
  },
  costBasis: {
    name: 'Cost Basis',
    children: [
      {
        id: 'tracking-method',
        name: 'Accounts',
        path: '/recon/cost-basis/accounts',
      },
      {
        id: 'movements',
        name: 'Movements',
        path: '/recon/cost-basis/movements',
      },
      {
        id: 'balances',
        name: 'Balances',
        path: '/recon/cost-basis/balances',
      },
      {
        id: 'running-balance',
        name: 'Running balance',
        path: '/recon/cost-basis/running-balance',
      },
    ],
  },
};
