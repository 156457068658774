import { gql } from '@apollo/client';
import { PasswordResetMutation, PasswordResetMutationVariables } from '../../graphql/types';
import { client } from '../../lib/apollo';

const RESET_PASSWORD = gql`
  mutation PasswordReset($password: String!, $token: String!, $captchaResponse: String!) {
    passwordReset(password: $password, token: $token, captchaResponse: $captchaResponse)
  }
`;

export const passwordReset = async (variables: PasswordResetMutationVariables) => {
  await client.mutate<PasswordResetMutation, PasswordResetMutationVariables>({
    mutation: RESET_PASSWORD,
    variables,
  });
};
