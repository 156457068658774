import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { markTxnsSpam } from '../../../graphql/mutations';
import { TxnFragment } from '../../../graphql/types';
import { showErrorFlash } from '../../Flash';
import { AllTransactionsContextType } from './context';

export const useOnMarkSpam = ({ refetch }: Required<Pick<AllTransactionsContextType, 'refetch'>>) => {
  const apolloClient = useApolloClient();

  const onMarkSpam = useCallback(
    async (txns: TxnFragment[], isSpam: boolean) => {
      try {
        await markTxnsSpam(apolloClient, txns, isSpam);

        refetch?.();
      } catch (error) {
        showErrorFlash('The transactions could not be updated.', { error });
      }
    },
    [apolloClient, refetch],
  );

  return onMarkSpam;
};
