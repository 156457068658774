import cx from 'classnames';
import React from 'react';
import Card from '../Card/index';
import Tab from './Tab';

export type TabType = {
  /** unique tab identifier */
  key: string;
  /** visible label */
  label: string;
  /** set active to true when the tab when tab is selected */
  active: boolean;
};

export interface TabbedCardProps {
  /** key of selected tab */
  activeTab: string;
  /** custom classnames */
  className?: string;
  /** list of all tabs */
  tabs: TabType[];
  /** callback when tab is selected, takes key for that tab */
  onTabChange: (tabKey: string) => void;
  /** TODO: render side content */
  renderFloatRightContent?: () => void;
  /** content in tabbed card */
  children: React.ReactNode;
  /** optional padding className(s) */
  padding?: string;
  /** if the tabbed card is rendered on a parent which changes background colors, pass true */
  themedBackground?: boolean;
  noCardBorder?: boolean;
}

function TabbedCard({
  activeTab,
  children,
  className,
  onTabChange,
  tabs,
  padding,
  themedBackground,
  noCardBorder = false,
}: TabbedCardProps) {
  const containerClasses = `flex flex-col flex-no-wrap justify-start ${className}`;
  return (
    <div className={containerClasses}>
      {/* classnames: grid-cols-1, grid-cols-2, grid-cols-3, grid-cols-4, grid-cols-5, grid-cols-6, grid-cols-7, grid-cols-8 */}
      <div className={`flex-initial grid-cols-${tabs.length}`}>
        {tabs.map((t) => (
          <div className="inline-block cursor-pointer" key={t.key}>
            <Tab
              active={t.key === activeTab}
              label={t.label}
              tabKey={t.key}
              onTabChange={onTabChange}
              themedBackground={themedBackground}
            />
          </div>
        ))}
      </div>
      <Card
        className={cx('flex-1 rounded-tl-none', noCardBorder && 'border-t border-l-0 border-r-0 border-b-0')}
        sectioned
        padding={padding}
      >
        {children}
      </Card>
    </div>
  );
}

export default React.memo(TabbedCard);
