import { startCase } from 'lodash';
import React, { FormEvent, useCallback, useRef, useState } from 'react';
import { SingleValue } from 'react-select';
import { CurrencyFilterTypes, FilterOperatorTypes, TxnCurrencyFilter } from '../../../graphql/types';
import { showErrorFlash } from '../../Flash';
import Input, { InputHandle } from '../../Input';
import Select, { OptionType } from '../../Select';
import { BaseFilterProps } from '../types';

export type CurrencyInputProps = BaseFilterProps<TxnCurrencyFilter> & {
  omit?: CurrencyFilterTypes[];
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({ filter, onChange, omit = [] }) => {
  const defaultCurrencyFilterType = filter?.side || CurrencyFilterTypes.Currency;
  const [currencyFilterType, setCurrencyFilterType] =
    useState<CurrencyFilterTypes>(defaultCurrencyFilterType);
  const [currencyTicker, setCurrencyTicker] = useState<string>(filter?.symbols[0] || '');
  const inputRef = useRef<InputHandle>(null);

  const handleFilterTypeChange = useCallback(
    (filterTypeOption: SingleValue<OptionType<CurrencyFilterTypes>>) => {
      // casts the value type to CurrencyFilterTypes... if we get an array back from select it will set state to default
      const newFilterType =
        (Array.isArray(filterTypeOption)
          ? undefined
          : (filterTypeOption as OptionType<CurrencyFilterTypes>)) || undefined;
      setCurrencyFilterType(newFilterType?.value || defaultCurrencyFilterType);
    },
    [defaultCurrencyFilterType],
  );

  const currencyFilterOptions = Object.values(CurrencyFilterTypes)
    .sort((a, b) => (a.length > b.length ? 1 : -1))
    .map((filterType: CurrencyFilterTypes) => ({
      value: filterType,
      label: startCase(filterType),
    }))
    .filter((option) => !omit.includes(option.value));

  const selectedFilterOption = currencyFilterType
    ? {
        value: currencyFilterType,
        label: startCase(currencyFilterType),
      }
    : undefined;

  const handleSubmit = () => {
    if (!currencyTicker) return showErrorFlash('Please add a currency ticker');
    onChange({
      ...(filter || { operator: FilterOperatorTypes.Is }),
      side: currencyFilterType,
      symbols: [currencyTicker],
    });
  };

  return (
    <div style={{ width: 280 }}>
      <div className="flex items-center p-1 px-2 gap-2">
        <div style={{ width: 240 }}>
          {currencyFilterOptions.length > 1 ? (
            <Select
              ariaLabel="select-currency-filter"
              onChange={handleFilterTypeChange}
              options={currencyFilterOptions}
              value={selectedFilterOption}
              isDisabled={false}
              isSearchable={false}
              justify={false}
              variant="inline"
            />
          ) : (
            <div className="p-1">{selectedFilterOption?.label}</div>
          )}
        </div>
        <Input
          ref={inputRef}
          placeholder="BTC"
          value={currencyTicker}
          onChange={(event: FormEvent<HTMLInputElement>) => {
            setCurrencyTicker(event.currentTarget.value);
          }}
          padding="p-1"
          autoFocus
          onEnterPressed={(val) => {
            if (val?.trim()) {
              handleSubmit();
            }
          }}
        />
      </div>
      <button
        onClick={handleSubmit}
        className="text-center w-full p-1 border-t border-light-control dark:border-dark-control text-sm font-semibold"
      >
        Apply
      </button>
    </div>
  );
};

export default React.memo(CurrencyInput) as typeof CurrencyInput;
