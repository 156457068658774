import { Theme } from '../../../theme/useTheme';
import { SelectVariant, Styles } from '../types';
import { getDefaultStyles } from './default';
import { getInlineBrandStyles, getInlineStyles } from './inline';
import { getTitleStyles } from './title';
import { getTitleMediumStyles } from './title-medium';

export function getVariantStyles<Option>(
  variant: SelectVariant,
  theme: Theme,
  hasError?: boolean,
): Styles<Option> {
  switch (variant) {
    case 'default':
      return getDefaultStyles<Option>(theme, hasError);
    case 'inline':
      return getInlineStyles<Option>(theme);
    case 'inline-brand':
      return getInlineBrandStyles<Option>(theme);
    case 'title':
      return getTitleStyles<Option>(theme);
    case 'title-medium':
      return getTitleMediumStyles<Option>(theme);
  }
}
