import { useReactiveVar } from '@apollo/client';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { NumberParam, useQueryParams } from 'use-query-params';

import { useUserContext } from '../../contexts';
import { useLatestTxnReports } from '../../graphql/queries';
import Blankslate from '../Blankslate';
import Button from '../Button';
import Card from '../Card';
import Page from '../Layout/Page';
import PageSeo from '../PageSeo';
import Select, { OptionType } from '../Select';
import SelfReconProvider from '../SelfRecon/SelfReconProvider';
import selectors from '../TaxDashboard/selectors';
import { actions } from '../TaxDashboard/state';
import { LatestTxnReportsByYearType } from '../TaxDashboard/types';
import { yearVar } from '../TaxDashboard/vars';
import Text from '../Text';
import TextLink from '../TextLink';
import { taxGuideBlogLinks } from './consts';
import { TaxGuideStep } from './TaxGuideStep';
import { TaxGuideStepType } from './types';
import { useTaxGuideSteps } from './useTaxGuideSteps';

/** YearSelector Component:
 *  - Renders the year dropdown and the associated date range.
 */
const YearSelector = React.memo(function YearSelector({
  yearOptions,
  handleReportYearChange,
  start,
  end,
  value,
}: {
  yearOptions: OptionType<number>[];
  handleReportYearChange: (option: SingleValue<OptionType<number>>) => void;
  start: string;
  end: string;
  value: OptionType<number>;
}) {
  return (
    <div className="relative z-50 max-w-[280px]">
      <div className="flex flex-col mb-6">
        <Select
          className="mb-2"
          isDisabled={false}
          onChange={handleReportYearChange}
          options={[{ value: -1, label: '+ Add new year' }, ...yearOptions]}
          placeholder="year"
          creatable={false}
          isSearchable={false}
          variant="title"
          value={value}
        />
        {start && end && (
          <Text variant="muted">
            {start} - {end}
          </Text>
        )}
      </div>
    </div>
  );
});

/** TaxGuideStepsList Component:
 *  - Displays the list of steps to complete the tax guide.
 */
const TaxGuideStepsList = React.memo(function TaxGuideStepsList({
  steps,
  nextToCompleteIndex,
}: {
  steps: Array<TaxGuideStepType>;
  nextToCompleteIndex?: number;
}) {
  return (
    <Card className="py-6 flex flex-col grow gap-4">
      <div className="flex flex-col gap-1 mb-2">
        <h1 className="font-normal text-2xl">Steps to complete your crypto taxes</h1>
        <Text variant="muted" className="text-lg">
          Follow the steps below to prepare and file
        </Text>
      </div>
      <div className="flex w-full gap-4">
        <div className="grow">
          <Card padding="none">
            <div className="flex flex-col">
              {steps.map((step, index) => (
                <TaxGuideStep
                  key={step.title}
                  index={index}
                  isNextToComplete={index === nextToCompleteIndex}
                  {...step}
                />
              ))}
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
});

/** UpgradePlan Component:
 *  - Shown when there is no plan for the selected year.
 */
const UpgradePlan = React.memo(function UpgradePlan({ yearState }: { yearState: number }) {
  const history = useHistory();
  return (
    <div className="w-full">
      <Blankslate
        title="Upgrade your plan to use the Tax Guide"
        subtitle="With a TokenTax plan, you can view gains and losses, see estimated tax liability, download tax forms, and strategically harvest tax losses"
        cta="Upgrade"
        onClick={() => history.push(`/purchase?year=${yearState}`)}
      />
    </div>
  );
});

/** LockedTaxGuide Component:
 *  - Shown when the user's access is locked to earlier years.
 */
const LockedTaxGuide = React.memo(function LockedTaxGuide({ lockedTilYear }: { lockedTilYear: number }) {
  return (
    <div className="w-full">
      <Blankslate
        title="Tax Guide is locked"
        subtitle={`You can only access tax guides for years after ${lockedTilYear}`}
      />
    </div>
  );
});

/** Sidebar Component:
 *  - Contains the VIP promotion card and resource links.
 */
const Sidebar = React.memo(function Sidebar({ yearState }: { yearState: number }) {
  return (
    <div className="w-full lg:w-[320px] shrink-0 flex flex-col gap-4">
      <Card>
        <h2 className="text-xl mb-2">Let us do the work with VIP</h2>
        <div>
          <Text variant="muted">
            Simply upload your data and we'll take care of the rest. A dedicated TokenTax Pro will reconcile
            your transactions and resolve errors like missing cost basis.
          </Text>
        </div>
        <Button className="mt-8" href={`/purchase?year=${yearState}`}>
          Upgrade Plan
        </Button>
      </Card>
      <Card>
        <h2 className="text-xl mb-2">Just getting started?</h2>
        <Text variant="muted">Here are some resources to help you out</Text>
        <div className="flex flex-col mt-6 gap-2">
          {taxGuideBlogLinks.map((link) => (
            <TextLink key={link.title} to={link.link} external>
              {link.title}
            </TextLink>
          ))}
        </div>
      </Card>
    </div>
  );
});

/** Main TaxGuide Component:
 *  - Uses the subcomponents and handles the logic related to year selection,
 *    step tracking, and conditional rendering based on plan status.
 */
function TaxGuide({ reports }: { reports?: LatestTxnReportsByYearType[] | null }) {
  const yearState = useReactiveVar(yearVar);
  const history = useHistory();
  const { user } = useUserContext();

  const steps = useTaxGuideSteps({ year: yearState });

  const [query, setQuery] = useQueryParams({
    year: NumberParam,
  });

  useEffect(() => {
    if (query.year !== yearState) {
      // Inherit the year set on the Tax Dashboard
      setQuery({ year: yearState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearState]);

  const handleReportYearChange = useCallback(
    (option: SingleValue<OptionType<number>>) => {
      if (option && 'value' in option && option.value === -1) {
        history.push('/purchase');
        return;
      }
      if (Array.isArray(option)) return;
      if (option && 'value' in option) {
        actions.setYear(option.value);
      }
    },
    [history],
  );

  const yearOptions = useMemo(() => selectors.getYearOptions(reports, 'Tax Guide'), [reports]);
  const taxDetail = useMemo(() => selectors.getDetail(reports, yearState), [reports, yearState]);
  const reportForYear = useMemo(() => reports?.find(({ year }) => year === yearState), [reports, yearState]);
  const hasPlanForSelectedYear = !reportForYear?.hasNoPlan;

  const start = moment(taxDetail?.dateRange?.startDate)
    .utc()
    .format('MMM D YYYY');
  const end = moment(taxDetail?.dateRange?.endDate)
    .utc()
    .format('MMM D YYYY');

  const value = useMemo(() => ({ value: yearState, label: `${yearState} Tax Guide` }), [yearState]);
  const nextToCompleteIndex = useMemo(() => steps?.findIndex((step) => !step.completed), [steps]);

  // Determine if the Tax Guide is locked
  const lockedTilYear = user?.lockedTilDate ? Number(user.lockedTilDate.split('-')[0]) - 1 : null;
  const showLocked = lockedTilYear && yearState <= lockedTilYear;

  if (!yearOptions.length) {
    return (
      <Blankslate
        title="No plans found"
        subtitle="Purchase a tax plan to get started"
        cta="View plans"
        linkTo="/purchase"
      />
    );
  }

  if (!steps) {
    return <Page loading />;
  }

  return (
    <>
      <YearSelector
        yearOptions={yearOptions}
        handleReportYearChange={handleReportYearChange}
        start={start}
        end={end}
        value={value}
      />
      <div className="flex flex-wrap lg:flex-nowrap gap-4">
        {hasPlanForSelectedYear && !showLocked && (
          <TaxGuideStepsList steps={steps} nextToCompleteIndex={nextToCompleteIndex} />
        )}
        {!hasPlanForSelectedYear && !showLocked && <UpgradePlan yearState={yearState} />}
        {showLocked && lockedTilYear && <LockedTaxGuide lockedTilYear={lockedTilYear} />}
        <Sidebar yearState={yearState} />
      </div>
    </>
  );
}

/** Container Component:
 *  - Fetches data and wraps the TaxGuide view with needed context/layout.
 */
function Container() {
  const { data, loading } = useLatestTxnReports();
  const { latestTxnReportsByYear: reports } = data?.latestTxnReports ?? {};

  if (loading || !reports) {
    return <Page loading />;
  }

  return (
    <SelfReconProvider>
      <Page>
        <PageSeo title="Tax Guide" />
        <div className="flex flex-col flex-grow overflow-hidden">
          <TaxGuide reports={reports} />
        </div>
      </Page>
    </SelfReconProvider>
  );
}

export default React.memo(Container);
