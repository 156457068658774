import React from 'react';
import { CostBasisProps } from './types';
import Tooltip from '../../Tooltip';
import CostBasisTooltipContent from './CostBasisTooltipContent';

function CostBasisTooltip({ ...props }: CostBasisProps) {
  return (
    <Tooltip
      icon="report_problem"
      iconClassNames="text-light-alert-critical dark:text-dark-alert-critical"
      interactive
    >
      <CostBasisTooltipContent {...props} />
    </Tooltip>
  );
}

export default React.memo(CostBasisTooltip);
