import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import Auth from '../../lib/Auth';
import { EvmGuideChainsQuery, EvmGuideInstructionQuery, EvmGuideInstructionQueryVariables } from '../types';

const EVM_GUIDE_CHAINS = gql`
  query EvmGuideChains {
    evmGuideChains {
      id
      shortName
      description
      credentialType
    }
  }
`;

export function useGetEvmGuideChains() {
  return useQuery<EvmGuideChainsQuery>(EVM_GUIDE_CHAINS, {
    skip: !Auth.tokenIsForAdmin(),
  });
}

const EVM_GUIDE_INSTRUCTION = gql`
  query EvmGuideInstruction($methodId: String!, $chain: String!, $contractAddress: String!) {
    evmGuideInstruction(methodId: $methodId, chain: $chain, contractAddress: $contractAddress) {
      id
      contractId
      treatmentId
      treatment {
        id
        name
        description
        reconOrder
      }
      blockSvcMethodId
      notes
      updatedAt
    }
  }
`;

export function useGetEvmGuideInstruction(
  options?: QueryHookOptions<EvmGuideInstructionQuery, EvmGuideInstructionQueryVariables>,
) {
  return useQuery(EVM_GUIDE_INSTRUCTION, options);
}
