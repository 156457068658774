import { useReactiveVar } from '@apollo/client';
import { chain, isEqual, partition, sortBy } from 'lodash';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { PlansContext } from '../contexts/PlansContext';
import { tokenDataVar } from '../lib/Auth';
import { ContentfulAddonData, ContentfulPlanData, usePlansData } from '../lib/contentfulClient';
import { PlansDataQuery } from '../lib/contentfulClient/types';
import { useObject } from '../lib/hooks';

interface Props {
  children: ReactNode;
}

const parsePlans = (data?: PlansDataQuery) => {
  const { addOnCollection, planCollection } = data ?? {};

  const allAddons =
    addOnCollection?.items
      .filter((addon): addon is ContentfulAddonData => addon?.id !== null)
      .filter(({ deprecated }) => !deprecated) ?? [];
  const [fullFilingPlans, addons] = partition(allAddons, 'isFullFilingPlan');

  const cryptoOnlyPlans =
    planCollection?.items
      .filter((plan): plan is ContentfulPlanData => plan?.id !== null)
      .filter(({ deprecated }) => !deprecated) ?? [];

  return { fullFilingPlans, addons, cryptoOnlyPlans };
};

const getPlanIdsAndPrices = (parsedPlans: ReturnType<typeof parsePlans>) =>
  chain(parsedPlans)
    .toPairs()
    .map(([type, plans]) => plans.map(({ id, price }) => ({ type, id, price })))
    .flatten()
    .filter(({ price }) => price !== null)
    .value();

export default function PlansProvider({ children }: Props) {
  // we're not getting isLoggedIn from UserProvider as that component is using this provider
  const isLoggedIn = Boolean(useReactiveVar(tokenDataVar));
  const { data, refetch, loading } = usePlansData({
    skip: !isLoggedIn,
  });

  const parsedPlans = useMemo(() => parsePlans(data), [data]);
  const { fullFilingPlans, addons, cryptoOnlyPlans } = parsedPlans;

  const confirmPlanPricesAreStillValid = useCallback(async () => {
    const { data: newData } = await refetch();
    const newParsedPlans = parsePlans(newData);

    const previousPlanIdsAndPrices = getPlanIdsAndPrices(parsedPlans);
    const newPlanIdsAndPrices = getPlanIdsAndPrices(newParsedPlans);

    return isEqual(
      sortBy(previousPlanIdsAndPrices, ['type', 'id']),
      sortBy(newPlanIdsAndPrices, ['type', 'id']),
    );
  }, [parsedPlans, refetch]);

  const context = useObject({
    loading,
    refetch,
    confirmPlanPricesAreStillValid,
    addons,
    fullFilingPlans,
    cryptoOnlyPlans,
  });

  return <PlansContext.Provider value={context}>{children}</PlansContext.Provider>;
}
