import React, { useCallback, useState } from 'react';
import { PromiseType } from 'utility-types';
import Auth from '../../lib/Auth';
import Login, { FormProps } from './Login';
import { login } from './mutations';

export default function LoginContainer(): React.ReactElement {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(async ({ email, password, captchaResponse }: FormProps) => {
    setIsSubmitting(true);

    let response: PromiseType<ReturnType<typeof login>>;
    try {
      response = await login({ email, password, captchaResponse });
    } finally {
      setIsSubmitting(false);
    }

    const { user, userHash } = response;
    const { id } = user;

    Auth.saveTokenData();

    // We're aliasing here first to merge Vero
    // profiles if the user already signed up for our
    // mailing list before signing up for the app.
    // https://segment.com/docs/connections/destinations/catalog/vero/#alias
    window.analytics.alias(String(id), email);

    window.analytics.identify(
      String(id),
      { email },
      {
        integrations: {
          Intercom: {
            user_hash: userHash,
          },
        },
      },
    );
  }, []);

  return <Login {...{ isSubmitting, onSubmit }} />;
}
