import { initial } from 'lodash';
import { ReactElement, cloneElement } from 'react';
import { modalsVar } from '../../variables';
import { ModalProps } from './Modal';
export * from './Modal';
export { default } from './Modal';

export function showModal(modal: ReactElement<ModalProps>) {
  const {
    props: { onClose },
  } = modal;

  modalsVar([
    ...modalsVar(),
    cloneElement(modal, {
      key: `${+new Date()}`,
      onClose: () => {
        modalsVar(initial(modalsVar()));
        onClose?.();
      },
    }),
  ]);
}

export function closeModals() {
  modalsVar([]);
}
