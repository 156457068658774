import moment, { Moment } from 'moment';
import React, { ElementRef, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import ReactDateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './DateTimePicker.css';

import Input, { InputProps as OriginalInputProps } from '../Input';
import TextLink from '../TextLink';

const TAB_KEY_CODE = 9;

interface DateInputProps {
  id?: string;
  /* value is an ISO8601 string */
  onChange: (value: string | undefined) => void;
  utc?: boolean;
  placeholder?: string;
  value?: string;
  label?: string;
  dateOnly?: boolean;
  inputProps?: InputProps;
  closeOnSelect?: boolean;
  allowClear?: boolean;
  showClearButton?: boolean;
}

type InputHandle = ElementRef<typeof Input>;
type InputProps = Pick<OriginalInputProps, 'padding' | 'onEnterPressed' | 'className' | 'transparent'>;

const DATE_ONLY_STRING_LENGTH = 10; // e.g. 2020-01-01

function DateTimePicker({
  id,
  onChange,
  utc,
  value,
  placeholder,
  label,
  dateOnly = false,
  inputProps,
  closeOnSelect,
  allowClear,
  showClearButton,
}: DateInputProps) {
  const date = useMemo(() => (value ? moment.utc(value) : undefined), [value]);
  const inputRef = useRef<InputHandle>(null);
  const [_unused, setForceRenderTimestamp] = useState(new Date());
  const DATE_FORMAT = `MM/DD/yyyy${dateOnly ? '' : ' hh:mm A'}`;

  const formatDateAsISO = useCallback(
    (date: Date | Moment) => date.toISOString().substring(0, dateOnly ? DATE_ONLY_STRING_LENGTH : undefined),
    [dateOnly],
  );

  useLayoutEffect(() => {
    if (!date) {
      return inputRef.current?.setValue(''); // Clear form if no date
    }
    inputRef.current?.setValue(date.format(DATE_FORMAT));
  }, [date, DATE_FORMAT]);

  const updateValue = useCallback(
    (newValue: string) => {
      const parsedValue = moment.utc(newValue);

      if (parsedValue.isValid()) {
        onChange(formatDateAsISO(parsedValue));
      } else if (!allowClear) {
        setForceRenderTimestamp(new Date()); // forces a re-render to immediately display the previously shown value
      } else {
        onChange(undefined);
      }
    },
    [allowClear, formatDateAsISO, onChange],
  );

  return (
    <div className="relative">
      <ReactDateTimePicker
        value={date}
        onChange={useCallback(
          (newValue) => {
            if (typeof newValue === 'string') return;
            onChange(formatDateAsISO(newValue));
          },
          [onChange, formatDateAsISO],
        )}
        utc={utc}
        timeFormat={!dateOnly}
        closeOnSelect={closeOnSelect}
        className={inputProps?.transparent ? undefined : 'bg-light-base dark:bg-dark-base'}
        // the typescript definitions in react-datetime@2.16.3 don't fully match the actual API
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        renderInput={useCallback(
          (_props: Record<string, unknown>, openCalendar: () => void, closeCalendar: () => void) => (
            <Input
              autoComplete="off"
              ref={inputRef}
              id={id}
              onBlur={(event) => {
                updateValue(event.target.value);
              }}
              onFocus={() => {
                openCalendar();
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  updateValue(event.currentTarget.value);
                } else if (event.keyCode === TAB_KEY_CODE) {
                  closeCalendar();
                }
              }}
              placeholder={placeholder}
              label={label}
              {...inputProps}
            />
          ),
          [id, inputProps, label, placeholder, updateValue],
        )}
      />
      {showClearButton && value && (
        <div className="absolute right-3 top-1/2 translate-y-[-50%]">
          <TextLink onClick={() => onChange(undefined)}>Clear</TextLink>
        </div>
      )}
    </div>
  );
}

export default React.memo(DateTimePicker);
