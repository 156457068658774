import { gql, useQuery } from '@apollo/client';
import { TXN } from '../../../graphql/fragments';
import {
  LineItemSortOptions,
  LineItemsQuery,
  LineItemsQueryVariables,
  SortDirectionOptions,
} from '../../../graphql/types';
import Auth from '../../../lib/Auth';
import { PaginationState, getPaginationOptions } from '../../utils/pagination';

const LINE_ITEMS_DATA = gql`
  query lineItems(
    $txnReportId: Int!
    $pagination: PaginationOptions!
    $sortBy: LineItemSortOptions!
    $sortDirection: SortDirectionOptions!
    $filterQuery: LineItemsFilterQuery
    $isAdmin: Boolean!
    $filterDust: Boolean
  ) {
    lineItems(
      lineItemInput: {
        txnReportId: $txnReportId
        pagination: $pagination
        sortBy: $sortBy
        sortDirection: $sortDirection
        filterQuery: $filterQuery
        filterDust: $filterDust
      }
    ) {
      pageInfo {
        filteredCount
      }
      edges {
        id
        buyId
        sellId
        sellCurrency
        buyCurrency
        unitsSold
        feeUnitsSold
        feeCurrency
        buyDate
        sellDate
        proceedsIncludingFees
        costBasisIncludingFees
        gainLossIncludingFees
        term
        missingCostBasis
        splitBuyId
        txnReportId
        isFee
        accountId
        account {
          id
          name
        }
        txnLineItemSellIdTotxn {
          ...Txn
        }
      }
    }
  }
  ${TXN}
`;

interface UseLineItemsProps {
  sortState: {
    sortBy: LineItemSortOptions;
    sortDir: SortDirectionOptions;
  };
  paginationState: PaginationState;
  filterQueryState: string | undefined;
  txnReportId: number | undefined;
  filterDust?: boolean;
}

export function useLineItems({
  paginationState,
  sortState,
  filterQueryState,
  txnReportId,
  filterDust,
}: UseLineItemsProps) {
  return useQuery<LineItemsQuery, LineItemsQueryVariables>(LINE_ITEMS_DATA, {
    variables: {
      txnReportId: txnReportId as number,
      pagination: getPaginationOptions(paginationState),
      sortBy: sortState.sortBy,
      sortDirection: sortState.sortDir,
      filterQuery: JSON.parse(filterQueryState || '{}'),
      isAdmin: Auth.tokenIsForAdmin(),
      filterDust,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: txnReportId === undefined,
  });
}
