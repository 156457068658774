import { MutationHookOptions, gql, useMutation } from '@apollo/client';
import { ChangeUserDataMutation, ChangeUserDataMutationVariables } from '../types';

const CHANGE_USER_DATA = gql`
  mutation ChangeUserData(
    $firstName: String!
    $lastName: String!
    $email: String!
    $secretMode: Boolean
    $reconMode: Boolean
    $recalcByAccount: Boolean
    $recalcByAccountStartYear: Int
  ) {
    changeUserData(
      firstName: $firstName
      lastName: $lastName
      email: $email
      secretMode: $secretMode
      reconMode: $reconMode
      recalcByAccount: $recalcByAccount
      recalcByAccountStartYear: $recalcByAccountStartYear
    )
  }
`;

export function useChangeUserData(
  options?: MutationHookOptions<ChangeUserDataMutation, ChangeUserDataMutationVariables>,
) {
  return useMutation<ChangeUserDataMutation, ChangeUserDataMutationVariables>(CHANGE_USER_DATA, options);
}
