import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import React, { ReactElement, ReactNode } from 'react';
import { Txn, TxnType } from '../../../graphql/types';
import { BUY_PRICE_ONLY_TYPES, SELL_PRICE_ONLY_TYPES } from '../../../lib/constants';
import { Label } from '../../Input';
import Tooltip from '../../Tooltip';
import { useEditTxnFormContext } from './EditTxnFormProvider';
import { DIFFERENT_VALUES, MULTIPLE_VALUES, Value, getDisplayedValueAndPlaceholder } from './formValues';
import { ValueProp, Values } from './types';

const { trade, migration, income, borrow, deposit, spend, repay, withdrawal } = TxnType;

const specIdInfluencingProps: Array<keyof Txn> = [
  'usdSpotPrice',
  'unitPrice',
  'sellPrice',
  'buyPrice',
  'feePrice',
  'buyCurrency',
  'sellCurrency',
  'feeCurrency',
  'buyQuantity',
  'sellQuantity',
  'feeQuantity',
  'txnTimestamp',
  'txnType',
];

const getClassName = (name: string, txnType?: TxnType, isMovementWithSellCurrency?: boolean) => {
  let res = 'pb-4';

  // show all fields for these txn types
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if ([trade, migration, DIFFERENT_VALUES, MULTIPLE_VALUES].includes(txnType!)) {
    return res;
  }

  res += ' col-span-2';

  // hide the 'sell' side fields for these txn types
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (BUY_PRICE_ONLY_TYPES.includes(txnType!)) {
    // sellCurrency is still displayed if isMovementWithSellCurrency
    res +=
      name.startsWith('sell') && !(isMovementWithSellCurrency && name === 'sellCurrency') ? ' hidden' : '';
  }

  // hide the 'buy' side fields for these txn types
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (SELL_PRICE_ONLY_TYPES.includes(txnType!)) {
    res += name.startsWith('buy') ? ' hidden' : '';
  }

  // hide sellAmount/Price for movement - sellCurrency is still shown
  if (txnType === 'movement') {
    res += name === 'sellQuantity' || name === 'sellPrice' ? ' hidden' : '';
  }

  return res;
};

const getBuyFieldLabel = (name: string, txnType?: TxnType): string => {
  const isCurrency = name.endsWith('Currency');

  switch (txnType) {
    case 'trade':
      return isCurrency ? 'Buy Currency' : 'Buy Amount';
    case 'income':
      return isCurrency ? 'Currency Received' : 'Amount Received';
    case 'borrow':
      return isCurrency ? 'Currency Borrowed' : 'Amount Borrowed';
    case 'deposit':
      return isCurrency ? 'Currency Deposited' : 'Amount Deposited';
    case 'airdrop':
      return isCurrency ? 'Currency Airdropped' : 'Amount Airdropped';
    case 'movement':
      return isCurrency ? 'Currency Moved' : 'Amount Moved';
    default:
      return isCurrency ? 'Buy Currency' : 'Buy Amount';
  }
};

const getSellFieldLabel = (name: string, txnType?: TxnType) => {
  const isCurrency = name.endsWith('Currency');

  switch (txnType) {
    case 'lost':
      return isCurrency ? 'Currency Lost' : 'Currency Amount';
    case 'repay':
      return isCurrency ? 'Currency Repaid' : 'Currency Amount';
    case 'gift':
      return isCurrency ? 'Currency Gifted' : 'Amount Gifted';
    case 'spend':
      return isCurrency ? 'Currency Spent' : 'Amount Spent';
    case 'withdrawal':
      return isCurrency ? 'Currency Withdrawn' : 'Amount Withdrawn';
    case 'movement':
      return isCurrency ? 'Currency Moved' : 'Amount Moved';
    default:
      return isCurrency ? 'Sell Currency' : 'Sell Amount';
  }
};

const getPriceFieldLabel = (name: string, txnType?: TxnType) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if ([income, borrow, spend, deposit, withdrawal, repay].includes(txnType!)) {
    return 'USD Price Of Currency';
  }

  return name.startsWith('sell') ? 'Fiat Price Of Sell Currency' : 'Fiat Price Of Buy Currency';
};

const humanizedLabelNames = {
  txnTimestamp: 'Date & Time',
  txnType: 'Transaction Type',
  exchangeName: 'Location',
  description: 'Description',
  feeCurrency: 'Fee Currency',
  feeQuantity: 'Fee Quantity',
  feePrice: 'Fee Price',
  bkpVendorId: 'Vendor',
  bkpAccountCreditId: 'Credit Account',
  bkpAccountDebitId: 'Debit Account',
  reviewed: 'Reviewed',
  isSpam: 'Spam',
  accountId: 'Account',
};

const humanizedLabelNamesForMovementTypes = {
  accountId: 'From Account',
  toAccountId: 'To Account',
};

export const getLabelText = (name: string, txnType?: TxnType, isMovementWithSellCurrency?: boolean) => {
  if (name.endsWith('TokenId')) name = `${name.split('TokenId')[0]}Currency`;

  if (name === 'txnType') return 'Transaction Type';
  if (name === 'txnTimestamp') return 'Date & Time';

  if (['sellPrice', 'buyPrice'].includes(name)) {
    return getPriceFieldLabel(name, txnType);
  }

  // override movement buyCurrency label if sellCurrency is set as well (Moved => Moved To)
  if (name === 'buyCurrency' && isMovementWithSellCurrency) {
    return getBuyFieldLabel(name, txnType) + ' To';
  }

  if (name.startsWith('buy')) {
    return getBuyFieldLabel(name, txnType);
  }

  if (name.startsWith('sell')) {
    return getSellFieldLabel(name, txnType);
  }

  //** NOTE: We want to override some labels if the Txn is "movement" */
  if (
    txnType === TxnType.movement &&
    humanizedLabelNamesForMovementTypes[name as keyof typeof humanizedLabelNamesForMovementTypes]
  ) {
    return humanizedLabelNamesForMovementTypes[name as keyof typeof humanizedLabelNamesForMovementTypes];
  }

  return humanizedLabelNames[name as keyof typeof humanizedLabelNames];
};

const SHOULD_VALIDATE = true;

type SinglePropRenderProps<Prop extends ValueProp> = {
  value: Values[Prop];
  originalValue: Values[Prop];
  placeholder: string;
  setValue: (value: Values[Prop]) => void;
  setTouched: () => void;
};

type RenderProps = {
  [Property in ValueProp]: SinglePropRenderProps<Property>;
};

type Props = {
  name: string;
  txnType?: TxnType;
  className?: string;
  tooltipText: string;
  disableFocusOnClick?: boolean;
  useAriaLabel?: boolean;
  isDisabled?: boolean;
  render: (props: RenderProps) => ReactElement;
  renderSideLabel?: (props: RenderProps) => ReactNode;
};

export default function FormInput({
  name,
  txnType,
  className,
  tooltipText,
  disableFocusOnClick,
  useAriaLabel,
  isDisabled = false,
  render,
  renderSideLabel,
}: Props) {
  const labelId = `editTxnForm-${name}`;
  const { values } = useFormikContext<Values>();

  const valuesAndSetters = Object.fromEntries(
    Object.keys(values).map((property) => [property, useField(property)]), // eslint-disable-line react-hooks/rules-of-hooks
  );

  const renderProps = Object.fromEntries(
    Object.entries(valuesAndSetters).map(
      ([property, [{ value: originalValue }, _metaProps, { setValue, setTouched }]]) => {
        const { value, placeholder } = getDisplayedValueAndPlaceholder(originalValue as Value);

        return [
          property,
          {
            value,
            originalValue, // this will be !== value if value is DIFFERENT_VALUES or MULTIPLE_VALUES
            placeholder,
            setValue,
            setTouched: () => setTouched(true, SHOULD_VALIDATE),
          },
        ];
      },
    ),
  ) as RenderProps;

  const renderedInput = render(renderProps);
  const { txns, hasSpecIdMatches } = useEditTxnFormContext();
  const isEditingSingleTxnWithSpecIdMatches = txns.length === 1 && hasSpecIdMatches;

  const isMovementWithSellCurrency = values.txnType === TxnType.movement && !!values.sellCurrency;

  return (
    <div
      className={className || getClassName(name, txnType, isMovementWithSellCurrency)}
      data-testid={`FormInput-${name}`}
    >
      <Label htmlFor={name} onClick={(e) => disableFocusOnClick && e.preventDefault()} id={labelId}>
        {getLabelText(name, txnType, isMovementWithSellCurrency)}

        <Tooltip icon="info_outline" iconClassNames="align-bottom">
          {tooltipText}
        </Tooltip>

        {renderSideLabel?.(renderProps)}
      </Label>

      <div
        aria-labelledby={useAriaLabel ? labelId : undefined}
        className={classNames(
          isEditingSingleTxnWithSpecIdMatches &&
            specIdInfluencingProps.includes(name as keyof Txn) &&
            'opacity-50 pointer-events-none',
          isDisabled && 'opacity-50 pointer-events-none',
        )}
      >
        {renderedInput}
      </div>
    </div>
  );
}
