import React, { ReactNode } from 'react';
import cx from 'classnames';

import { toastBackgroundClasses } from '../Toast';

type FormStatusType = 'success' | 'warning' | 'error' | 'info';

const getColorClassName = (type: FormStatusType) => {
  switch (type) {
    case 'success':
      return toastBackgroundClasses.success;
    case 'warning':
      return toastBackgroundClasses.warning;
    case 'error':
      return toastBackgroundClasses.critical;
    case 'info':
      return toastBackgroundClasses.info;
  }
};

interface Props {
  children: ReactNode;
  type: FormStatusType;
  className?: string;
}

export function FormStatus({ children, type, className }: Props) {
  return (
    <div
      className={cx(
        'p-4 mb-4 rounded border-light-alert dark:border-dark-alert text-light-base dark:text-dark-base',
        getColorClassName(type),
        className,
      )}
    >
      {children}
    </div>
  );
}
