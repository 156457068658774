export interface PaginationState {
  page: number;
  pageSize: number;
}

export const getPaginationOptions = ({ page, pageSize }: PaginationState) => {
  return {
    first: pageSize,
    skip: (page - 1) * pageSize,
  };
};

interface GetTotalPagesProps {
  totalCount: number;
  pageSize: number;
}

export const getTotalPages = ({ totalCount, pageSize }: GetTotalPagesProps) => {
  return Math.ceil(totalCount / pageSize);
};
