import Big from 'big.js';
import { CreateMovementMutationVariables, TxnFragment } from '../../../graphql/types';
import { areWithinPercentage, areWithinTimestampLeeway } from '../../../helpers/validators';

type ValidationResult = {
  isValid: boolean;
  errorMessage: string | null;
};

type MovementConstraints = {
  quantityLeewayPercentage: number | undefined;
  timestampLeewayDays: number | undefined;
};

// I don't think we need the capability to display multiple error messages at once
// since it's a relatively simple modal and multi errors are not super likely
export const validate = (
  formData: CreateMovementMutationVariables,
  txns: TxnFragment[],
  constraints: MovementConstraints,
): ValidationResult => {
  const txnDates: Date[] = txns.map((txn) => txn.txnTimestamp);
  const txnQuantities = txns
    .map((txn) => {
      if (txn.txnType === 'deposit') {
        return Big(txn.buyQuantity || 0);
      } else if (txn.txnType === 'withdrawal') {
        return Big(txn.sellQuantity || 0);
      } else {
        return null;
      }
    })
    .filter((txn): txn is Big => Boolean(txn))
    .map((txn) => txn.toNumber());

  if (!constraints || !constraints.quantityLeewayPercentage || !constraints.timestampLeewayDays) {
    return {
      isValid: false,
      errorMessage: 'Error loading movement data. Please contact support if the issue persists',
    };
  }

  if (!formData.date) {
    return { isValid: false, errorMessage: 'Date is required' };
  }

  if (!areWithinTimestampLeeway([formData.date, ...txnDates], constraints.timestampLeewayDays)) {
    return {
      isValid: false,
      errorMessage: `Movement date must be within ${constraints.timestampLeewayDays} days of transfer transactions`,
    };
  }

  if (!formData.fromAccountId || !formData.toAccountId) {
    return { isValid: false, errorMessage: 'From Account and To Account are required' };
  }

  if (formData.fromAccountId === formData.toAccountId) {
    return { isValid: false, errorMessage: 'From Account and To Account must be different' };
  }

  if (!formData.currency?.trim()) {
    return { isValid: false, errorMessage: 'Currency is required' };
  }

  if (!formData.amount || Big(formData.amount).toNumber() <= 0) {
    return { isValid: false, errorMessage: 'Nonzero amount is required' };
  }

  if (
    !areWithinPercentage(
      [...txnQuantities, Big(formData.amount).toNumber()],
      constraints.quantityLeewayPercentage,
    )
  ) {
    return {
      isValid: false,
      errorMessage: `Movement amount must be within ${constraints.quantityLeewayPercentage}% of each transfer amount`,
    };
  }

  return { isValid: true, errorMessage: null };
};
