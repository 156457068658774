import { gql, useMutation } from '@apollo/client';
import { UpdateUserTaxDetailMutation, UpdateUserTaxDetailMutationVariables } from '../../../graphql/types';

const UPDATE_USER_TAX_DETAIL = gql`
  mutation UpdateUserTaxDetail(
    $id: Int!
    $countryId: Int!
    $locked: Boolean!
    $longTermCapitalGainsRate: Float!
    $marginalTaxRate: Float!
    $state: String
    $method: String
  ) {
    updateUserTaxDetail(
      id: $id
      countryId: $countryId
      locked: $locked
      longTermCapitalGainsRate: $longTermCapitalGainsRate
      marginalTaxRate: $marginalTaxRate
      state: $state
      method: $method
    ) {
      id
      state
      locked
      marginalTaxRate
      longTermCapitalGainsRate
      taxYear
      taxMethod {
        id
        label
      }
      country {
        name
        shorthand
        id
      }
    }
  }
`;

export function useUpdateUserTaxDetail() {
  return useMutation<UpdateUserTaxDetailMutation, UpdateUserTaxDetailMutationVariables>(
    UPDATE_USER_TAX_DETAIL,
  );
}
