import React from 'react';

import Option from './Option';
import { ChoiceProps } from './types';

export default function FileWith({
  hasFFP,
  isUSBased,
  send,
}: Omit<ChoiceProps, 'current'> & {
  hasFFP?: boolean;
  isUSBased: boolean;
}) {
  return (
    <div className="pt-4">
      <Option
        option={{
          label: 'With TurboTax, TaxAct, or other tax software',
          value: 'FILE_WITH_SOFTWARE',
        }}
        next="CHOOSE_SOFTWARE"
        send={send}
      />
      {isUSBased && (
        <Option
          option={{
            label: 'With TokenTax',
            value: 'FILE_WITH_TOKENTAX',
          }}
          next={hasFFP ? 'CHOOSE_YEAR' : 'UPSELL'}
          send={send}
        />
      )}

      <Option
        option={{
          label: 'With a CPA',
          value: 'FILE_WITH_CPA',
        }}
        next="CHOOSE_METHOD"
        send={send}
      />
    </div>
  );
}
