import React from 'react';

type ArrowButtonDirection = 'left' | 'right';

interface ArrowButtonProps {
  direction: ArrowButtonDirection;
  disabled: boolean;
  onClick?: () => void;
}

const arrowButtonClasses =
  'mr-2 border border-light-control dark:border-dark-control rounded h-full px-1 flex items-center justify-center';
const arrowButtonEnabledClasses = 'hover:bg-light-hover dark:hover:bg-dark-hover';
const arrowButtonDisabledClasses = 'opacity-25 cursor-not-allowed pointer-events-none select-none';
function ArrowButton({ direction, disabled, onClick }: ArrowButtonProps) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`${arrowButtonClasses} ${disabled ? arrowButtonDisabledClasses : arrowButtonEnabledClasses}`}
      onClick={onClick}
    >
      <i className="material-icons relative" style={{ fontSize: 20 }}>{`chevron_${direction}`}</i>
    </button>
  );
}

export default React.memo(ArrowButton);
