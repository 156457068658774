import React from 'react';
import { PickByValue } from 'utility-types';

import CopyableExplorerLink from '../../Copyable/CopyableExplorerLink';
import { Cell } from '../types';

type CellValueType = string | null | undefined;

interface AddressCellProps<
  T extends Record<string, unknown>,
  Prop extends keyof PickByValue<T, CellValueType>,
> {
  cell: Cell<T, Extract<T[Prop], CellValueType>>;
  isTxn?: boolean;
  credentialType?: string | null;
  exchangeName?: string;
  onFilter?: () => void;
  blocksvcHash?: string | null;
  blocksvcMethodId?: string | null;
  blocksvcToAddress?: string | null;
  reconIdentifier?: string | null;
  rowIsDisabled?: boolean;
  isTokenTaxAdmin?: boolean;
  specIdSellId?: string | null;
  trailChars?: number;
  leadChars?: number;
}

const isBase58 = (value: string) => /^[1-9A-HJ-NP-Za-km-z]+$/.test(value);

const LEAD_CHARS = 5;
const LEAD_CHARS_FOR_WIDE_TEXT = 4;
const TRAIL_CHARS = 3;

function AddressCell<T extends Record<string, unknown>, Prop extends keyof PickByValue<T, CellValueType>>({
  cell,
  isTxn,
  credentialType,
  exchangeName,
  onFilter,
  blocksvcHash,
  blocksvcMethodId,
  blocksvcToAddress,
  reconIdentifier,
  isTokenTaxAdmin,
  specIdSellId,
  trailChars = TRAIL_CHARS,
  leadChars = LEAD_CHARS,
}: AddressCellProps<T, Prop>) {
  const { value } = cell;

  if (!value) {
    return <>{'-'}</>;
  }

  // Formatting fix: base58 numbers (e.g. Solana hashes) look bad with 5 lead chars,
  // since the uppercase letters are wider. We'll use 4 lead chars for these addresses.
  if (isBase58(value)) {
    leadChars = LEAD_CHARS_FOR_WIDE_TEXT;
  }

  return (
    <div className="w-full flex justify-start">
      <CopyableExplorerLink
        value={value}
        {...{
          isTxn,
          credentialType,
          exchangeName,
          onFilter,
          blocksvcHash,
          blocksvcMethodId,
          toAddress: blocksvcToAddress,
          reconIdentifier,
          hideChainExplorer: Boolean(blocksvcMethodId) || Boolean(reconIdentifier),
          isTokenTaxAdmin,
          specIdSellId,
        }}
      >
        {`${value.slice(0, leadChars - (onFilter ? 1 : 0))}...${value.slice(-trailChars)}`}
      </CopyableExplorerLink>
    </div>
  );
}

export default React.memo(AddressCell) as unknown as typeof AddressCell;
