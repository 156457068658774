import { mapValues } from 'lodash';

import Auth from '../../lib/Auth';
import { SortDirectionOptions, TxnSortOptions } from '../../graphql/types';
import { filtersQueryParamsConf } from './queryParams';

export const pageSize = 100;

export const defaultSortState = {
  sortBy: TxnSortOptions.txnTimestamp,
  sortDir: SortDirectionOptions.asc,
};

export const defaultQueryState = {
  ...mapValues(filtersQueryParamsConf, () => undefined), // all filters unset
  sortBy: defaultSortState.sortBy,
  sortDir: defaultSortState.sortDir,
  page: 1,
};

export const defaultHiddenColumnsStorageKey = 'all-txns-hidden-cols';

export const getDefaultHiddenColumns = () => {
  const defaultHiddenColumns = [
    'updatedAt',
    ...(Auth.tokenIsForAdmin()
      ? [
          'usdSpotPrice',
          'unitPrice',
          'credentialName',
          'bkpVendorName',
          'bkpAccountDebitName',
          'bkpAccountCreditName',
        ]
      : []),
  ];
  const storedColumnVar = localStorage.getItem(defaultHiddenColumnsStorageKey);
  if (storedColumnVar) {
    try {
      const storedColumnState = JSON.parse(storedColumnVar);
      return storedColumnState as string[];
    } catch (e) {
      return defaultHiddenColumns;
    }
  }
  return defaultHiddenColumns;
};
