import { SideOrBoth } from './Wizard/types';
import { Draft } from './store/types';

const { sent, received, both } = SideOrBoth;

export const getDraftSide = (draft: Draft): SideOrBoth => {
  const sentTxnsLength = draft?.sent?.txns?.length ?? 0;
  const receivedTxnsLength = draft?.received?.txns?.length ?? 0;

  if (sentTxnsLength === 0 && receivedTxnsLength === 0) throw new Error('Draft has no transactions');
  if (sentTxnsLength > 0 && receivedTxnsLength > 0) return both;

  return sentTxnsLength > 0 ? sent : received;
};
