import { useEffect, useState } from 'react';
import { PageInfo } from './types';
import { getPageCounts } from './selectors';

interface Props {
  pageInfo?: PageInfo;
  totalCount?: number;
  loading: boolean;
  pageSize: number;
}

// TODO see if this can be circumented entirely. was this just to avoid the pagination jumping up and down?
export function usePageCounts({ pageInfo, totalCount, loading, pageSize }: Props) {
  const filteredCount = pageInfo?.filteredCount ?? undefined;

  // cache the last filtered count so that we keep track of how many pages there are
  const [lastFilteredCount, setLastFilteredCount] = useState<number | undefined>(filteredCount);
  useEffect(() => {
    if (!loading) {
      setLastFilteredCount(filteredCount);
    }
  }, [loading, filteredCount]);

  return getPageCounts({ totalCount, filteredCount, lastFilteredCount, loading, pageSize });
}
