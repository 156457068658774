import React, { useMemo } from 'react';
import FiltersDropdown from './FiltersDropdown';
import { FilterConfig } from './types';

export { default as CurrencyPill } from './CurrencyFilter/CurrencyPill';
export { default as DateRangePill } from './DateRangeFilter/DateRangePill';
export { default as DropdownPill } from './DropdownPill';
export { default as MultiSearchPill } from './MultiSearchFilter/MultiSearchPill';
export { default as MultiSelectPill } from './MultiSelectFilter/MultiSelectPill';
export { default as SearchPill } from './SearchFilter/SearchPill';

type FilterBuilderProps<FilterQueryType> = {
  filterQuery: FilterQueryType;
  children: React.ReactNode;
  filtersMap: Record<string, FilterConfig>;
  renderSelectedInput?: ({ selectedInput }: { selectedInput: string }) => JSX.Element | null;
  inputMenuWidth?: string;
};

function FilterBuilder<QueryType>({
  filterQuery,
  children,
  filtersMap,
  renderSelectedInput,
}: FilterBuilderProps<QueryType>) {
  const filtersArray = useMemo(
    () => Object.entries(filtersMap).map(([key, value]) => ({ key, ...value })),
    [filtersMap],
  );

  return (
    <div className="flex items-center flex-wrap gap-3">
      {children}

      {renderSelectedInput && (
        <FiltersDropdown
          filterQuery={filterQuery}
          filtersMap={filtersMap}
          filtersArray={filtersArray}
          renderSelectedInput={renderSelectedInput}
        />
      )}
    </div>
  );
}

export default React.memo(FilterBuilder) as typeof FilterBuilder;
