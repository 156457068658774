import React, { CSSProperties } from 'react';
import Text from '../Text';

interface Props {
  label: string;
  text?: string | null;
  style?: CSSProperties;
}

function InvoiceRow({ label, text, style }: Props) {
  if (!text) return null;

  return (
    <div className="flex" style={style}>
      <label className="w-40 inline-block flex-shrink-0">
        <Text variant="muted">{label}</Text>
      </label>
      {text}
    </div>
  );
}

export default React.memo(InvoiceRow);
