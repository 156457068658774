enum ClientEvents {
  UNAUTHORIZED = 'UNAUTHORIZED',
  NETWORK_ERROR = 'NETWORK_ERROR',
}

export class NetworkErrorEvent extends Event {
  constructor(public error: unknown) {
    super(ClientEvents.NETWORK_ERROR);
  }

  toString() {
    return `NetworkErrorEvent: ${this.error}`;
  }
}

// this event bus can be used to listen to events triggered by the apollo client
// using this pattern can be useful to avoid circular dependencies
const clientEvents = new EventTarget();

export const onUnauthorized = (func: () => void) => {
  clientEvents.addEventListener(ClientEvents.UNAUTHORIZED, func);
};

export const dispatchUnauthorized = () => {
  clientEvents.dispatchEvent(new Event(ClientEvents.UNAUTHORIZED));
};

export const onNetworkError = (func: (errorEvent: Event) => void) => {
  clientEvents.addEventListener(ClientEvents.NETWORK_ERROR, func);
};

export const dispatchNetworkError = (error: unknown) => {
  clientEvents.dispatchEvent(new NetworkErrorEvent(error));
};
