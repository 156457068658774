import { ReactiveVar, useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import { differenceBy, noop, pickBy, set, uniq } from 'lodash';
import type { Dispatch, SetStateAction } from 'react';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RecalcContext, UserContext } from '../../../contexts';
import { updateTxns } from '../../../graphql/mutations';
import { useGetSolanaGuideInstruction } from '../../../graphql/queries';
import { useGetEvmGuideChains, useGetEvmGuideInstruction } from '../../../graphql/queries/evmGuide';
import { useGetIbcGuideInstruction } from '../../../graphql/queries/ibcGuide';
import { TxnFilterQuery, TxnFragment, TxnType, UpdateTxnsMutation } from '../../../graphql/types';
import { checkEqualAndVal } from '../../../helpers/equality';
import { usePrevious } from '../../../hooks/usePrevious';
import { BUY_PRICE_ONLY_TYPES, SELL_PRICE_ONLY_TYPES } from '../../../lib/constants';
import { getApolloErrorMessageCodes, getApolloErrorMessages } from '../../../lib/errors';
import pluralize from '../../../lib/pluralize';
import { FiltersState } from '../../AllTransactions/types';
import CopyableExplorerLink from '../../Copyable/CopyableExplorerLink';
import { closeModals } from '../../Modal';
import { showConfirmationDialog } from '../../Modal/ConfirmationDialog';
import { showReconGuideContributionModal } from '../../ReconGuideContributionModal';
import TextLink from '../../TextLink';
import Toast, { ToastType } from '../../Toast';
import EditTxnForm from './EditTxnForm';
import EditTxnFormProvider from './EditTxnFormProvider';
import { getLabelText } from './FormInput';
import {
  MULTIPLE_VALUES,
  NEW_VALUES,
  defaultMultiPageBatchValues,
  getInitialValues,
  getInitialValuesForBatchFromFilters,
} from './formValues';
import { useBatchUpdateTxns } from './mutations';
import { Values } from './types';

interface FormProps {
  txns: TxnFragment[];
  multiPageBatch?: boolean;
  filtersVar?: ReactiveVar<FiltersState>;
  onSuccess?: () => void;
  onDelete?: () => void;
  showDeleteButton?: boolean;
  isUpdating: boolean;
  setIsUpdating: Dispatch<SetStateAction<boolean>>;
}

const HIDDEN_PROPS = ['updatedAt', 'unitPrice', 'usdSpotPrice'];
const shouldDisplayPropChange = (propName: string, txnType: TxnType) => {
  if (HIDDEN_PROPS.includes(propName)) return false;
  if (
    (BUY_PRICE_ONLY_TYPES.includes(txnType) && propName.startsWith('sell')) ||
    (SELL_PRICE_ONLY_TYPES.includes(txnType) && propName.startsWith('buy'))
  ) {
    return false;
  }
  return true;
};

const pluralizeTransactions = (count: number) => `${count} ${pluralize(count, 'transaction')}`;

interface ToastData {
  type: ToastType;
  message: ReactNode;
}

const getErrorsMessage = (errors: UpdateTxnsMutation['updateTxns']['errors']) => {
  const uniqueErrors = uniq(errors.map(({ error }) => error));
  return `${pluralize(uniqueErrors.length, 'Error')}: ${uniqueErrors.join(', ')}.`;
};

const getCommonTxnType = (txns: TxnFragment[]) => {
  const txnTypes = txns.map(({ txnType }) => txnType);
  if (uniq(txnTypes).length === 1) {
    return txnTypes[0];
  }
};

const NO_UPDATE_DURING_RECONCILIATION_MSG = (
  <>
    Transaction cannot be edited while its import is being reconciled. View its status on the{' '}
    <TextLink
      to="/import"
      onClick={() => {
        closeModals();
      }}
    >
      Import page
    </TextLink>
    .
  </>
);

function EditTxnFormContainer({
  txns: initialTxns,
  onDelete,
  showDeleteButton,
  multiPageBatch,
  filtersVar,
  onSuccess,
  isUpdating,
  setIsUpdating,
}: FormProps) {
  const { refetchNeedsRecalc } = useContext(RecalcContext);
  const client = useApolloClient();
  const [toastData, setToastData] = useState<ToastData>();
  const setToast = useCallback(
    (type: ToastType, message: ReactNode) => setToastData({ type, message }),
    [setToastData],
  );
  const [shouldUpdateCounts, setShouldUpdateCounts] = useState<boolean>(false);
  const [updatedTxnsData, setUpdatedTxnsData] = useState<UpdateTxnsMutation | null>();

  const [txns, setTxns] = useState(initialTxns);
  const { setPollLatestBatchUpdateJobStatus, latestBatchUpdateTxnsJobStatus, isTokenTaxAdmin } =
    useContext(UserContext);

  const [pendingBatchSavedValues, setPendingBatchSavedValues] = useState<Partial<Values>>({});
  const [batchCommonValues, setBatchCommonValues] = useState<Partial<Values>>(
    getInitialValuesForBatchFromFilters({ filtersVar }),
  );

  const { data: chainsData } = useGetEvmGuideChains();

  const methodIds = txns.map((t) => t.blocksvcMethodId);
  const contractAddresses = txns.map((t) => t.blocksvcToAddress);
  const chains = txns.map((t) => t.credential?.credentialType.toLowerCase());
  const identifiers = txns.map((t) => t.reconIdentifier);
  const txnHashes = txns.map((t) => t.blocksvcHash);

  const methodIdCheck = checkEqualAndVal(methodIds);
  const contractAdddressCheck = checkEqualAndVal(contractAddresses);
  const chainsCheck = checkEqualAndVal(chains);
  const identifiersCheck = checkEqualAndVal(identifiers);
  const txnHashesCheck = checkEqualAndVal(txnHashes);

  const credentialType = chainsCheck.val;
  const methodId = methodIdCheck.val;
  const contractAddress = contractAdddressCheck.val;
  const transactionHash = txnHashesCheck.val;
  const identifier = identifiersCheck.val;

  const isSolana =
    chainsCheck.allEqual &&
    chainsCheck.val === 'sol-new' &&
    identifiersCheck.allEqual &&
    identifiersCheck.val !== null &&
    txnHashesCheck.allEqual &&
    transactionHash &&
    identifier;

  const isEvm =
    chainsCheck &&
    chainsData?.evmGuideChains.some((c) => c.credentialType?.toLowerCase() === chains[0]) &&
    identifiersCheck.allEqual &&
    identifiersCheck.val === null &&
    methodId &&
    contractAddress &&
    credentialType;

  const isIbc =
    chainsCheck.allEqual &&
    chainsCheck.val !== 'sol-new' && // if it has an identifier but isn't Solana then it must be IBC
    identifiersCheck.allEqual &&
    identifiersCheck.val !== null &&
    txnHashesCheck.allEqual &&
    transactionHash &&
    credentialType &&
    identifier;

  const { data: evmInstructionData, loading: evmInstructionLoading } = useGetEvmGuideInstruction({
    variables: {
      methodId: methodIdCheck.val || '',
      contractAddress: contractAdddressCheck.val || '',
      chain: chainsCheck.val || '',
    },
    skip: !isTokenTaxAdmin || !isEvm,
    fetchPolicy: 'cache-and-network',
  });

  const { data: solanaInstructionData, loading: solanaInstructionLoading } = useGetSolanaGuideInstruction({
    variables: {
      identifier: identifiersCheck.val || '',
    },
    skip: !isTokenTaxAdmin || !isSolana,
    fetchPolicy: 'cache-and-network',
  });

  const { data: ibcInstructionData, loading: ibcInstructionLoading } = useGetIbcGuideInstruction({
    variables: {
      identifier: identifiersCheck.val || '',
    },
    skip: !isTokenTaxAdmin || !isIbc,
    fetchPolicy: 'cache-and-network',
  });

  const instructionLoading = isEvm
    ? evmInstructionLoading
    : isSolana
    ? solanaInstructionLoading
    : isIbc
    ? ibcInstructionLoading
    : false;

  const instructionData = isEvm
    ? evmInstructionData?.evmGuideInstruction
    : isSolana
    ? solanaInstructionData?.solanaGuideInstruction
    : isIbc
    ? ibcInstructionData?.ibcGuideInstruction
    : undefined;

  const ecosystem = isEvm ? 'EVM' : isSolana ? 'Solana' : isIbc ? 'IBC' : undefined;

  useEffect(() => {
    if (!updatedTxnsData || isUpdating || !shouldUpdateCounts) return;
    const {
      updateTxns: { updatedTxns, errors },
    } = updatedTxnsData;

    const changedPropsCounts = txns.reduce(
      (count, txn) => {
        const updatedTxn = updatedTxns.find(({ id }) => id === txn.id);

        if (!updatedTxn) {
          return count; // this txn was not updated
        }

        const changedValuesArrays = differenceBy(
          Object.entries(txn),
          Object.entries(updatedTxn),
          JSON.stringify,
        );

        changedValuesArrays.forEach(([propName]) => {
          if (!shouldDisplayPropChange(propName, txn.txnType)) return;
          set(count, propName, (count[propName as keyof typeof count] || 0) + 1);
        });

        return count;
      },
      {} as Record<string, number>,
    );

    // this effect would naturally run again once `txns` changes via the cache modification logic
    // if we were to run the effect then, changedPropsCounts would always be an empty object.
    // this ensures we only update these counts after a submit
    setShouldUpdateCounts(false);

    const changedPropsEntries = Object.entries(changedPropsCounts).filter(
      ([property]) =>
        // the API sets `isEdited` on the model when it's modified the first time, but we don't want to show it in the "successful update" message
        property !== 'isEdited' &&
        // the 'credential' property is an object - ignore changes in keys order (also the credential will never change)
        property !== 'credential',
    );

    const type = errors.length === 0 ? 'success' : changedPropsEntries.length === 0 ? 'error' : 'warning';

    let msgParts: ReactNode[] = [];

    if (type === 'error') {
      msgParts = [`Could not update ${pluralize(txns.length, 'transaction')}. ${getErrorsMessage(errors)}`];
    } else {
      msgParts = ['Successfully updated '];

      const txnTypeForLabels = getCommonTxnType(updatedTxns);

      if (uniq(changedPropsEntries.map(([_propName, count]) => count)).length === 1) {
        const changedCount = changedPropsEntries[0][1]; // as all entries have the same count, the 0th will do
        msgParts.push(
          changedPropsEntries.map(([propName]) => getLabelText(propName, txnTypeForLabels)).join(', '),
        );
        if (txns.length > 1) {
          msgParts.push(` for ${pluralizeTransactions(changedCount)}`);
        }
      } else {
        msgParts.push(
          changedPropsEntries
            .map(
              ([propName, count]) =>
                `${getLabelText(propName, txnTypeForLabels)} for ${pluralizeTransactions(count)}`,
            )
            .join(', '),
        );
      }

      if (type === 'warning') {
        msgParts.push('.');
        msgParts.push(<br key="irrelevant" />);
        msgParts.push(
          `Could not update ${pluralizeTransactions(errors.length)}. ${getErrorsMessage(errors)}`,
        );
      }
    }

    setToast(type, <>{msgParts}</>);
    setTxns(txns.map((txn) => updatedTxns.find(({ id }) => id === txn.id) || txn));
  }, [updatedTxnsData, isUpdating, txns, shouldUpdateCounts, setToast]);

  const { type, message } = toastData ?? {};

  const initialValues = useMemo(() => {
    if (multiPageBatch) {
      return {
        ...defaultMultiPageBatchValues,
        ...batchCommonValues,
      } as Values;
    }
    return getInitialValues(txns);
  }, [multiPageBatch, batchCommonValues, txns]);

  const saveTxnValues = useCallback(
    async ({ values }: { values: TxnFragment[] }) => {
      setIsUpdating(true);
      try {
        const data = await updateTxns(client, values, refetchNeedsRecalc);
        setUpdatedTxnsData(data);
        setShouldUpdateCounts(true);
        onSuccess?.();
      } catch (error) {
        const errorMessage =
          getApolloErrorMessages(error) || (error instanceof Error ? error.message : String(error));
        const isReconciliationError = getApolloErrorMessageCodes(error)?.includes(
          'RECONCILIATION_IN_PROGRESS',
        );
        setToast('error', isReconciliationError ? NO_UPDATE_DURING_RECONCILIATION_MSG : errorMessage);
      } finally {
        setIsUpdating(false);
      }
    },
    [client, onSuccess, refetchNeedsRecalc, setIsUpdating, setToast],
  );

  const [batchUpdateTxns] = useBatchUpdateTxns();
  const batchSaveTxnValues = useCallback(
    async ({ formValues }: { formValues: Values }) => {
      setIsUpdating(true);
      try {
        const { filterQuery } = filtersVar!(); // eslint-disable-line @typescript-eslint/no-non-null-assertion
        setPendingBatchSavedValues(formValues);
        await batchUpdateTxns({
          variables: {
            filterQuery: JSON.parse(filterQuery || '{}') as TxnFilterQuery,
            txnInput: {
              buyCurrency: formValues.buyCurrency,
              buyTokenId: formValues.buyTokenId,
              buyQuantity: formValues.buyQuantity,
              buyPrice: formValues.buyPrice,
              description: formValues.description,
              location: formValues.exchangeName,
              sellCurrency: formValues.sellCurrency,
              sellTokenId: formValues.sellTokenId,
              sellQuantity: formValues.sellQuantity,
              feeCurrency: formValues.feeCurrency,
              feeTokenId: formValues.feeTokenId,
              feeQuantity: formValues.feeQuantity == null ? undefined : String(formValues.feeQuantity),
              feePrice: formValues.feePrice,
              sellPrice: formValues.sellPrice,
              txnTimestamp: formValues.txnTimestamp,
              txnType: formValues.txnType,
              reviewed: formValues.reviewed,
              isSpam: formValues.isSpam,
              priceFetchingSide: formValues.priceFetchingSide,
            },
          },
        });
        setPollLatestBatchUpdateJobStatus?.(true);
      } catch (error) {
        const errorMessage =
          getApolloErrorMessages(error) || (error instanceof Error ? error.message : String(error));

        setToast('error', errorMessage);
        // isUpdating for the success scenario is set to `false` by a useEffect() hook when the batch update job has completed
        setIsUpdating(false);
      }
    },
    [batchUpdateTxns, filtersVar, setPollLatestBatchUpdateJobStatus, setIsUpdating, setToast],
  );

  const txnsBatchUpdateIsInProgress = ['created', 'running'].includes(latestBatchUpdateTxnsJobStatus!);
  const txnsBatchUpdateWasInProgress = usePrevious(txnsBatchUpdateIsInProgress);

  useEffect(() => {
    if (txnsBatchUpdateWasInProgress && !txnsBatchUpdateIsInProgress) {
      setIsUpdating(false);
      if (latestBatchUpdateTxnsJobStatus === 'successful') {
        setBatchCommonValues((values) => ({ ...values, ...pendingBatchSavedValues }));
        setPendingBatchSavedValues({});
        setToast('success', 'Transactions successfully updated');
        onSuccess?.();
      }
      if (latestBatchUpdateTxnsJobStatus === 'failed') {
        setToast('error', 'There was an error updating the transactions');
      }
    }
  }, [
    latestBatchUpdateTxnsJobStatus,
    onSuccess,
    pendingBatchSavedValues,
    setIsUpdating,
    setToast,
    txnsBatchUpdateIsInProgress,
    txnsBatchUpdateWasInProgress,
  ]);

  const sameHash = txns.map((t) => t.blocksvcHash).every((v) => v === txns[0].blocksvcHash);

  return (
    <>
      {sameHash && txns[0]?.blocksvcHash && (
        <div className="mx-8 mb-8">
          <span className="font-semibold">Txn Hash:</span>{' '}
          <CopyableExplorerLink
            credentialType={txns[0].credential?.credentialType}
            exchangeName={txns[0].exchangeName || undefined}
            isTxn
            value={txns[0].blocksvcHash}
          >
            {txns[0].blocksvcHash}
          </CopyableExplorerLink>
        </div>
      )}

      <div>
        {toastData && (
          <Toast className="mx-8" {...{ type }} onHide={() => setToastData(undefined)}>
            {message}
          </Toast>
        )}
      </div>

      {isTokenTaxAdmin && !instructionLoading && instructionData && (
        <Toast className="mx-8">
          {ecosystem} instruction found: {instructionData?.treatment?.name}{' '}
          <button
            onClick={() => {
              if (isEvm) {
                showReconGuideContributionModal({
                  type: 'EVM',
                  methodId,
                  contractAddress,
                  chain: credentialType,
                });
              } else if (isSolana) {
                showReconGuideContributionModal({
                  type: 'Solana',
                  identifier,
                  transactionHash,
                });
              } else if (isIbc) {
                showReconGuideContributionModal({
                  type: 'IBC',
                  identifier,
                  transactionHash,
                  credentialType,
                });
              }
            }}
            className="text-underline underline cursor-pointer ml-3"
          >
            Edit
          </button>
        </Toast>
      )}

      <Formik
        initialValues={initialValues}
        onSubmit={noop} // handled below
      >
        <EditTxnFormProvider txns={txns} multiPageBatch={multiPageBatch}>
          <EditTxnForm
            txns={txns}
            updating={isUpdating}
            setToast={setToast}
            initialValues={initialValues}
            saveTxnValues={saveTxnValues}
            onSubmit={useCallback(
              async ({ values, formValues }) => {
                const { length } = txns;

                if (length > 1) {
                  if (isTokenTaxAdmin && !instructionData) {
                    if (isEvm) {
                      showReconGuideContributionModal({
                        type: 'EVM',
                        methodId,
                        contractAddress,
                        chain: credentialType,
                      });
                    }

                    if (isSolana) {
                      showReconGuideContributionModal({
                        type: 'Solana',
                        identifier,
                        transactionHash,
                      });
                    }

                    if (isIbc) {
                      showReconGuideContributionModal({
                        type: 'IBC',
                        identifier,
                        transactionHash,
                        credentialType,
                      });
                    }
                  } else {
                    // TODO; I cant get these modals to show back to back
                    await showConfirmationDialog({
                      title: `Update ${pluralizeTransactions(length)}?`,
                      subtitle: `Click "Confirm" to to update ${pluralizeTransactions(length)}`,
                      buttonText: 'Confirm',
                    });
                  }
                }

                if (multiPageBatch) {
                  await batchSaveTxnValues({
                    formValues: pickBy(
                      formValues,
                      (value) => ![MULTIPLE_VALUES, NEW_VALUES].includes(value),
                    ) as Values,
                  });
                } else {
                  await saveTxnValues({ values });
                }
              },
              [
                batchSaveTxnValues,
                contractAddress,
                credentialType,
                identifier,
                instructionData,
                isEvm,
                isIbc,
                isSolana,
                isTokenTaxAdmin,
                methodId,
                multiPageBatch,
                saveTxnValues,
                transactionHash,
                txns,
              ],
            )}
            onDelete={onDelete}
            showDeleteButton={showDeleteButton}
          />
        </EditTxnFormProvider>
      </Formik>
    </>
  );
}

export default React.memo(EditTxnFormContainer);
