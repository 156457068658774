import React from 'react';
import { useActionItems } from './actionItems';
import NavDropdown from './NavDropdown';
import { navItems } from './navItems';
import type { NavItem } from './types';

type Props = {
  isAdminPage: boolean;
};

const hamburgerMobileNavItems: NavItem[] = [
  navItems.taxDashboard,
  navItems.taxLossHarvesting,
  navItems.incomeDashboard,
  navItems.documents,
  navItems.allTransactions,
  navItems.importData,
  navItems.profile,
  navItems.recon,
  navItems.upgrade,
  navItems.accountSettings,
].filter((item) => item.shown !== false);

export default function MobileDropdown({ isAdminPage }: Props) {
  const actionItems = useActionItems({ isAdminPage });

  return (
    <NavDropdown
      items={[...(isAdminPage ? [] : hamburgerMobileNavItems), ...actionItems]}
      dropdownBody={
        <button className="flex items-center cursor-pointer leading-3">
          <span className="text-white material-icons text-[32px] ml-1">menu</span>
        </button>
      }
    />
  );
}
