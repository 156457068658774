export interface ChoiceProps {
  /** state object */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  current: any;
  /** action function */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  send: any;

  /** close modal */
  onClose?: () => void;
}

export type GenerateProps = Partial<ChoiceProps> & {
  isUSBased: boolean;
  lineItemCount?: number;
  setTitle?: (v: string) => void;
  taxYear?: number;
};

export type FfpByYear = Record<number, string>;
