import classNames from 'classnames';
import React, { useCallback } from 'react';
import Dropdown, { DropdownHandle } from '../Dropdown';
import Text from '../Text';
import FilterableList from './FilterableList';
import PillBody from './PillBody';
import { BasePillProps } from './types';

type Option<Value extends string | boolean> = {
  value: Value;
  label: string;
};

export type DropdownPillProps<Value extends string | boolean> = BasePillProps<Value> & {
  pillCopy: string;
  // value: Value;
  options: Option<Value>[];
  labelFirst?: boolean;
};

function DropdownPill<Value extends string | boolean>({
  filter: value,
  onChange,
  onDelete,
  options,
  pillCopy,
  labelFirst,
}: DropdownPillProps<Value>) {
  const dropdownValuesRef = React.useRef<DropdownHandle>(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    dropdownValuesRef.current?.hide();
  }, [value]);

  const onSelectValue = useCallback(
    (value: Value) => {
      onChange(value);
      dropdownValuesRef.current?.hide();
    },
    [onChange],
  );
  const elements = [
    <div
      key="dropdown"
      className={classNames('hover:bg-light-selected-faint dark:hover:bg-dark-selected-faint rounded-l', {
        'bg-light-selected-faint dark:bg-dark-selected-faint': open,
      })}
    >
      <Dropdown
        isDisabled={false}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        label={options.find((option) => option.value === value)!.label}
        ref={dropdownValuesRef}
        onShow={() => setOpen(true)}
        onHide={() => setOpen(false)}
        noStyles
      >
        {open && (
          <FilterableList<Value> options={options} onSelectOption={onSelectValue} withSearch={false} />
        )}
      </Dropdown>
    </div>,
    <div key="label" className="border-l border-light-control dark:border-dark-control flex items-center">
      <Text variant="muted" className="p-1 px-2">
        {pillCopy}
      </Text>
    </div>,
  ];

  if (labelFirst) {
    elements.reverse();
  }

  return <PillBody onDelete={onDelete}>{elements}</PillBody>;
}

export default React.memo(DropdownPill) as typeof DropdownPill;
