import React, { useCallback, useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { Cell } from '../index';

export interface EditableCellProps<T extends Record<string, unknown>, Prop extends keyof T> {
  cell: Cell<T, Extract<T[Prop], string>>;
  onEditCell?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  className?: string;
  rowIsDisabled?: boolean;
}

function EditableCell<T extends Record<string, unknown>, Prop extends keyof T>(
  props: EditableCellProps<T, Prop>,
): React.ReactElement {
  const {
    cell: { value },
    onEditCell,
    onBlur,
    onFocus,
    className,
    rowIsDisabled,
  } = props;

  const [renderCounter, setRenderCounter] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  let skipSubmit = false;

  // update the innerText whenever it changes (or a re-render is forced)
  useEffect(() => {
    if (!ref.current) return;
    ref.current.innerText = value;
  }, [renderCounter, value]);

  const onKeyDown = useCallback(
    (evt) => {
      const key = evt.key as string;
      if (key === 'Enter') {
        evt.preventDefault();
        evt.currentTarget.blur();
      } else if (key === 'Escape') {
        skipSubmit = true; // eslint-disable-line react-hooks/exhaustive-deps
        evt.currentTarget.blur();
        skipSubmit = false;
        setRenderCounter((count: number) => count + 1); // forces re-render
      }
    },
    [onEditCell],
  );

  const onBlurCallback = useCallback(
    (evt) => {
      onBlur?.();
      const currentValue = evt.currentTarget.innerText as string;
      if (skipSubmit || currentValue === value) return;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onEditCell!(currentValue); // onEditCell must be defined as otherwise onBlur is not set at all
    },
    [onEditCell, skipSubmit, onBlur, value],
  );

  return (
    <div
      ref={ref}
      className={cx(className, 'outline-none pl-4 flex h-full items-center py-0')}
      {...(onEditCell && !rowIsDisabled
        ? {
            contentEditable: true,
            onKeyDown,
            onBlur: onBlurCallback,
            onFocus,
          }
        : {})}
    />
  );
}

export default React.memo(EditableCell) as typeof EditableCell;
