import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PromiseType } from 'utility-types';
import Auth from '../../lib/Auth';
import { signup } from './mutations';
import Signup, { FormProps } from './Signup';

export default function SignupContainer(): React.ReactElement {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    async ({ email, password, captchaResponse }: FormProps) => {
      setIsSubmitting(true);

      let response: PromiseType<ReturnType<typeof signup>>;

      try {
        response = await signup({ email, password, captchaResponse });
      } finally {
        setIsSubmitting(false);
      }

      const { id, userHash } = response;

      Auth.saveTokenData();

      // We're aliasing here first to merge Vero
      // profiles if the user already signed up for our
      // mailing list before signing up for the app.
      // https://segment.com/docs/connections/destinations/catalog/vero/#alias
      window.analytics.alias(String(id), email);

      window.analytics.identify(
        String(id),
        { email },
        { integrations: { Intercom: { user_hash: userHash } } },
      );

      window.analytics.track('sign_up_completed', {
        location: 'sign_up',
        country: undefined, // currently disabled
        onboarding_bucket: 'default',
        checkout_source: 'default',
      });

      history.push('/purchase');
    },
    [history],
  );

  return <Signup {...{ isSubmitting, onSubmit }} />;
}
