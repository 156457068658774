import { Row as RowType } from 'react-table';
import { TableChildrenProps } from '../types';

export interface TableBodyProps<T extends Record<string, unknown>> extends TableChildrenProps<T> {
  onRowClicked?: (id: string) => void;
  isRowClickable?: (data: T) => boolean;
  scrolling?: boolean;
}

export interface ExpandedRowProps<T extends Record<string, unknown>> {
  row: RowType<T>;
}
