import React, { useState } from 'react';
import TextLink from '../../TextLink';

type Props = {
  type: `${'buy' | 'sell' | 'fee'}Price` | 'noPresetSidePrice';
  onFetchPrice: () => void;
};

const MINIMUM_FETCHING_PRICES_SPINNER_DURATION_MS = 300;

function FetchPrice({ type, onFetchPrice }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <TextLink
      loading={isLoading}
      onClick={async (e) => {
        e?.preventDefault(); // do not submit the form
        try {
          setIsLoading(true);
          // show the spinner for a fraction of a second if if all prices are memoized - gives a visual confirmation to the user
          await Promise.all([
            onFetchPrice(),
            new Promise((resolve) => setTimeout(resolve, MINIMUM_FETCHING_PRICES_SPINNER_DURATION_MS)),
          ]);
        } finally {
          setIsLoading(false);
        }
      }}
      testId={`fetch-${type}`}
    >
      {type === 'noPresetSidePrice' ? 'Fetch price for different values' : 'Fetch Price'}
    </TextLink>
  );
}

export default FetchPrice;
