import classNames from 'classnames';
import React, { useCallback } from 'react';
import Spinner from '../Spinner';
import { HandlePageChange } from '../Table/types';

interface PageButtonProps {
  page: number | null;
  isActivePage: boolean;
  isLastButton: boolean;
  handlePageChange: HandlePageChange;
}

export const SHOW_SPINNER_PAGE_NUMBER = -1;
function PageButton({ page, isActivePage, isLastButton, handlePageChange }: PageButtonProps) {
  const showSpinner = page === SHOW_SPINNER_PAGE_NUMBER;

  const defaultClasses = 'px-3 py-1 flex items-center justify-center text-center';
  const activeClasses = 'bg-light-selected dark:bg-dark-selected';
  const inactiveClasses = classNames(
    showSpinner ? 'cursor-default' : 'hover:bg-light-hover dark:hover:bg-dark-hover cursor-pointer',
  );
  const rightBorderClasses = 'border-r border-light-control dark:border-dark-control';

  const isPlaceholder = page === null;
  let classes = defaultClasses;

  // if not ellipsis placeholder add active/inactive classes
  if (!isPlaceholder) {
    classes += ` ${isActivePage ? activeClasses : inactiveClasses}`;
  }

  // if not last button then add right border
  if (!isLastButton) {
    classes += ` ${rightBorderClasses}`;
  }

  return (
    <li
      className={classes}
      onClick={useCallback(() => {
        if (isPlaceholder || showSpinner) return;
        handlePageChange(page as number);
      }, [handlePageChange, isPlaceholder, page, showSpinner])}
    >
      {showSpinner ? (
        <>
          {' '}
          <Spinner size="sm" />
          {' '}
        </>
      ) : !isPlaceholder ? (
        page
      ) : (
        '...'
      )}
    </li>
  );
}

export default React.memo(PageButton);
