import React, { useCallback, useState } from 'react';
import { InvoiceQuery } from '../../graphql/types';
import InvoiceLineItems from '../InvoiceLineItems';
import TextLink from '../TextLink';

type Line = InvoiceQuery['invoice']['lines'][number];

interface Props {
  lines: Line[];
}

function InvoiceDetails({ lines }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleState = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  if (!lines.length) return null;

  return (
    <div>
      <TextLink onClick={toggleState}>{`${isExpanded ? 'Hide' : 'View'} invoice details`}</TextLink>
      {isExpanded && <InvoiceLineItems lines={lines} />}
    </div>
  );
}

export default React.memo(InvoiceDetails);
