import { truncate } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';

import Text from '../../Text';
import { AllTransactionsContext } from '../Provider/context';

const SpecIdToolbarSummary = () => {
  const { specIdTxnResponse } = useContext(AllTransactionsContext);

  const txn = specIdTxnResponse?.data?.txn;
  if (!txn) return null;

  const { sellQuantity, sellCurrency, txnTimestamp, blocksvcHash } = txn;

  return (
    <div>
      <Text variant="muted">
        Select one or more of the transactions below to set a new cost basis for{' '}
        <strong>
          {sellQuantity} {sellCurrency}
        </strong>{' '}
        sold on {moment(txnTimestamp).format('MM/DD/YYYY')} (Txn{' '}
        {truncate(blocksvcHash as string, { length: 8, omission: '...' })})
      </Text>
    </div>
  );
};

export default React.memo(SpecIdToolbarSummary);
