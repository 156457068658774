import { partition } from 'lodash';
import { CATEGORY_DATA } from '../../Wizard/constants';
import { ComplexTreatmentResult, Props } from './types';

export const repayWithExclusion = (props: Props): ComplexTreatmentResult => {
  const { txn, lpTokenTickersByAddress, sentTxns, receivedTxns } = props;
  const { tokenParam1 } = txn;

  if (!tokenParam1 || !tokenParam1.length) return { drafts: null };
  if (receivedTxns.length > 0) return { drafts: null };

  const [matchingWithdrawals, otherWithdrawals] = partition(
    sentTxns,
    ({ txnType, sellCurrency }) =>
      txnType === 'withdrawal' &&
      tokenParam1.map((t) => t.toLowerCase()).includes(sellCurrency.toLowerCase()),
  );
  if (matchingWithdrawals.length !== 1) return { drafts: null };
  const matchingWithdrawal = matchingWithdrawals[0]!;

  const drafts = [
    {
      sent: {
        grouped: false as const,
        txns: [
          {
            id: matchingWithdrawal.id,
            type: CATEGORY_DATA.repay.sentType!,
            currency:
              lpTokenTickersByAddress[matchingWithdrawal.sellAddress!] ?? matchingWithdrawal.sellCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            tokenId: matchingWithdrawal.sellTokenId,
            quantity: matchingWithdrawal.sellQuantity,
            price: matchingWithdrawal.sellPrice,
          },
        ],
      },
    },
    ...otherWithdrawals.map((withdrawal) => ({
      sent: {
        grouped: false as const,
        txns: [
          {
            id: withdrawal.id,
            type: 'sent' as const,
            currency: lpTokenTickersByAddress[withdrawal.sellAddress!] ?? withdrawal.sellCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            tokenId: withdrawal.sellTokenId,
            quantity: withdrawal.sellQuantity,
            price: withdrawal.sellPrice,
          },
        ],
      },
    })),
  ];

  return { drafts };
};
