import cx from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';

import { Cell as CellType } from './types';

interface Props<T extends Record<string, unknown>> {
  cell: CellType<T>;
  originalId?: string;
  setForceHover?: (forced: boolean) => void;
  rowIsDisabled?: boolean;
}

function Cell<T extends Record<string, unknown>>({
  cell,
  originalId,
  setForceHover: setForceHoverRow,
  rowIsDisabled,
}: Props<T>) {
  const { rightAligned, id: columnId, hideTitle, onEditCell, isCellEditable } = cell.column;

  const cellIsEditable = isCellEditable?.(cell.row.original);

  const [isFocused, setIsFocused] = useState(false);
  const [forceHoverCell, setForceHoverCell] = useState(false);
  const [reRenderCounter, setReRenderCounter] = useState(0);

  const classNames = cx(
    'font-normal flex-shrink-0 truncate outline-none',
    rightAligned && 'text-right',
    cellIsEditable
      ? rowIsDisabled
        ? null
        : [
            isFocused
              ? 'bg-light-selected dark:bg-dark-selected'
              : 'hover:bg-light-hover hover:dark:bg-dark-hover',
          ]
      : 'py-3',
    forceHoverCell && 'bg-light-hover dark:bg-dark-hover',
  );

  const onEditCellCallback = useCallback(
    async (newValue: string) => {
      try {
        return await onEditCell!(originalId!, newValue); // eslint-disable-line @typescript-eslint/no-non-null-assertion
      } catch (error) {
        setReRenderCounter((count: number) => count + 1); // forces re-render
      }
    },
    [onEditCell, originalId],
  );

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const setForceHover = useCallback(
    (forced: boolean) => {
      setForceHoverCell(forced);
      setForceHoverRow?.(forced);
    },
    [setForceHoverRow],
  );

  const cellContent = useMemo(
    () =>
      cell.render('Cell', {
        rowIsDisabled,
        ...(cellIsEditable
          ? {
              onEditCell: onEditCellCallback,
              onBlur,
              onFocus,
              setForceHover,
            }
          : {}),
      }),
    [cell, cellIsEditable, onBlur, onFocus, setForceHover, onEditCellCallback, rowIsDisabled],
  );

  const title = hideTitle || typeof cell.value === 'object' ? undefined : (cell.value as string);

  return (
    <div {...cell.getCellProps()} className={classNames} key={`${columnId}-${reRenderCounter}`} title={title}>
      {cellContent}
    </div>
  );
}

export default React.memo(Cell) as unknown as typeof Cell;
