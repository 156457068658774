import React, { useCallback, useState } from 'react';
import { Country, UserTaxDetail } from '../../../graphql/types';
import Button from '../../Button';
import { showSuccessFlash } from '../../Flash';
import Input, { NumberInput } from '../../Input';
import Checkbox from '../../Input/Checkbox';
import Modal, { ModalProps, showModal } from '../../Modal';
import { useGetCountries } from '../../ReconProfile/queries';
import Select from '../../Select';
import Spinner from '../../Spinner';
import Toast from '../../Toast';
import { filingMethodLabels, states } from './constants';
import { useUpdateUserTaxDetail } from './queries';

interface UpdateTaxDetailModalProps extends ModalProps {
  taxDetail: UserTaxDetail;
  onConfirm?: (taxDetail: UserTaxDetail) => Promise<void>;
  buttonText: string;
}

function UpdateTaxDetailModal({ taxDetail, onConfirm, buttonText, ...props }: UpdateTaxDetailModalProps) {
  const { onClose } = props;
  const [marginalTaxRate, setMarginalTaxRate] = useState(taxDetail.marginalTaxRate ?? 0); // it should really always be defined - we're protecting against malformed data
  const [longTermCapitalGainsRate, setLongTermCapitalGainsRate] = useState(
    taxDetail.longTermCapitalGainsRate ?? 0, // it should really always be defined - we're protecting against malformed data
  );
  const [state, setState] = useState<string>(taxDetail.state || '');
  const [country, setCountry] = useState(taxDetail.country);
  const [filingMethod, setFilingMethod] = useState<string>(taxDetail.method || '');
  const [isLocked, setIsLocked] = useState(taxDetail.locked);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const { data: countriesData, loading } = useGetCountries();

  const [updateUserTaxDetail, { loading: updateUserTaxDetailLoading }] = useUpdateUserTaxDetail();

  const handleConfirm = useCallback(async () => {
    setShowErrorToast(false);

    try {
      const res = await updateUserTaxDetail({
        variables: {
          id: taxDetail.id,
          countryId: country.id,
          locked: isLocked,
          marginalTaxRate,
          longTermCapitalGainsRate,
          state,
          method: filingMethod,
        },
      });
      showSuccessFlash(`${taxDetail.taxYear} tax details updated`);
      onConfirm?.(res.data?.updateUserTaxDetail as UserTaxDetail);
      onClose?.();
    } catch (error) {
      setShowErrorToast(true);
    }
  }, [
    updateUserTaxDetail,
    taxDetail.id,
    taxDetail.taxYear,
    country.id,
    isLocked,
    marginalTaxRate,
    longTermCapitalGainsRate,
    state,
    filingMethod,
    onConfirm,
    onClose,
  ]);

  const formIsValid = marginalTaxRate >= 0 && longTermCapitalGainsRate >= 0 && country;

  return (
    <Modal title={`Update ${taxDetail.taxYear} Tax Details`} {...props}>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {showErrorToast && <Toast type="error">Tax details update failed</Toast>}
          {/* {showSuccessToast && <Toast type="success">{taxDetail.taxYear} tax details updated</Toast>} */}
          <div className="mb-4">
            <NumberInput
              label="Short Term"
              suffix="%"
              customInput={Input}
              value={marginalTaxRate * 100}
              onValueChange={({ floatValue }) => {
                if (!floatValue) return;
                setMarginalTaxRate(floatValue / 100);
              }}
            />
          </div>
          <div className="mb-4">
            <NumberInput
              label="Long Term"
              suffix="%"
              customInput={Input}
              value={longTermCapitalGainsRate * 100}
              onValueChange={({ floatValue }) => {
                if (!floatValue) return;
                setLongTermCapitalGainsRate(floatValue / 100);
              }}
            />
          </div>
          <div className="mb-4">
            <Select<number>
              label="Country"
              options={countriesData?.countries.map((country) => ({
                value: country.id,
                label: country.name,
              }))}
              value={{ value: country.id, label: country.name }}
              onChange={(option) => {
                setCountry(countriesData?.countries.find((c) => c.id === option.value) as Country);
              }}
            />
          </div>
          {country.shorthand === 'USA' && (
            <div className="mb-4">
              <Select<string>
                label="State"
                options={states}
                value={{ value: state, label: state }}
                onChange={(option) => {
                  setState(option.value);
                }}
              />
            </div>
          )}
          <div className="mb-4">
            <Select<string>
              label="Filing method"
              options={country.methods.map((m) => ({
                label: filingMethodLabels[m as keyof typeof filingMethodLabels] || m,
                value: m,
              }))}
              value={{
                value: filingMethod,
                label: filingMethodLabels[filingMethod as keyof typeof filingMethodLabels] || filingMethod,
              }}
              onChange={({ value }) => {
                setFilingMethod(value);
              }}
            />
          </div>
          <div className="mb-6 text-left flex">
            <Checkbox checked={isLocked} onChange={() => setIsLocked(!isLocked)} label="Locked" />
          </div>

          <Button disabled={!formIsValid} onClick={handleConfirm} loading={updateUserTaxDetailLoading}>
            {buttonText}
          </Button>
        </div>
      )}
    </Modal>
  );
}

export const showUpdateTaxDetailModal = (props: UpdateTaxDetailModalProps) => {
  showModal(<UpdateTaxDetailModal {...props} />);
};
