import { HIJACKED_USER_ID_QUERY_ARG } from './constants';
import { JwtData } from './types';

export const getTokenData = (token?: string) => {
  if (!token) return undefined;
  return JSON.parse(atob(token.split('.')[1])) as JwtData;
};

export const getHijackedUserIdFromQuery = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const hijackedUserIdString = searchParams.get(HIJACKED_USER_ID_QUERY_ARG);
  return hijackedUserIdString ? parseInt(hijackedUserIdString) : null;
};
