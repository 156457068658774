import { MutationOptions, gql } from '@apollo/client';
import {
  ActivatePlanFromCheckoutMutation,
  ActivatePlanFromCheckoutMutationVariables,
} from '../../graphql/types';
import { client } from '../../lib/apollo';

const ACTIVATE_PLAN_FROM_CHECKOUT = gql`
  mutation ActivatePlanFromCheckout($checkoutSessionId: String!) {
    activatePlanFromCheckout(checkoutSessionId: $checkoutSessionId) {
      plan
      year
      price
    }
  }
`;

export const activatePlanFromCheckout = async (
  variables: MutationOptions<
    ActivatePlanFromCheckoutMutation,
    ActivatePlanFromCheckoutMutationVariables
  >['variables'],
) =>
  client.mutate<ActivatePlanFromCheckoutMutation, ActivatePlanFromCheckoutMutationVariables>({
    mutation: ACTIVATE_PLAN_FROM_CHECKOUT,
    variables,
  });
