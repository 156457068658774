import { useMutation } from '@apollo/client';
import Big from 'big.js';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { camelCase, get } from 'lodash';
import React from 'react';
import { NumberFormatValues } from 'react-number-format';
import { useUserContext } from '../../../contexts';
import { useGetFormLocaleOptions, useLatestTxnReports } from '../../../graphql/queries';
import {
  EnumTaxMethod,
  LatestTxnReportsQuery,
  UpdateReportDetailMutation,
  UpdateReportDetailMutationVariables,
} from '../../../graphql/types';
import Button from '../../Button';
import { showFlash } from '../../Flash';
import { NumberInput } from '../../Input';
import Modal, { ModalProps, showModal } from '../../Modal';
import Select, { OptionType, Optionable } from '../../Select';
import { showPreviousYearConfirmation } from './previousYearConfirmation';
import { UPDATE_SETTINGS } from './queries';

type LatestTxnReportsByYearType = NonNullable<
  LatestTxnReportsQuery['latestTxnReports']
>['latestTxnReportsByYear'][0];
type ReportTaxDetail = LatestTxnReportsByYearType['taxDetail'];
type MethodType = NonNullable<ReportTaxDetail['taxMethod']>;

type Id = number;
type Code = string;
type Method = MethodType;

export type SettingsType = {
  /** id of homeCountry */
  countryId?: Id;
  /** abbrev name of homeCountry */
  homeCountry?: string;
  /** currency code */
  baseCurrency?: Code;
  /** float */
  shortTerm?: number;
  /** float */
  longTerm?: number;
  /** name of method */
  costBasisMethod?: Method;
};

interface Values
  extends Omit<Optionable<SettingsType>, 'homeCountry' | 'shortTerm' | 'longTerm' | 'costBasisMethod'> {
  homeCountry?: OptionType<Id>;
  shortTerm?: number;
  longTerm?: number;
  costBasisMethod?: OptionType<EnumTaxMethod>;
}

export interface SettingsModalProps extends ModalProps {
  /** id of tax detail for this report*/
  id?: number;
  /** methods to populate select options*/
  methods?: Method[];
  /** cb to run once mutation completes */
  onComplete: () => void;
  /** cb once settings are updated */
  onUpdate: () => void;
  /** settings from the tax detail*/
  settings: SettingsType;
  /** the label for the setting, not title of modal */
  title: string;
}

const SettingsModal = ({
  settings,
  id,
  methods,
  title,
  onClose,
  onComplete,
  onUpdate,
}: SettingsModalProps) => {
  const { refetch: refetchReportData } = useLatestTxnReports();
  const { user } = useUserContext();

  const getInitialValues = (): { [name: string]: OptionType | number | undefined } => {
    const { countryId, homeCountry, baseCurrency, shortTerm, longTerm, costBasisMethod } = settings;

    return {
      homeCountry: { value: countryId, label: homeCountry || '' },
      baseCurrency: { value: baseCurrency, label: baseCurrency || '' },
      shortTerm,
      longTerm,
      //TODO: update this to reflect actual method options
      costBasisMethod: { value: costBasisMethod?.id || null, label: costBasisMethod?.label || '' },
    };
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    // eslint-disable-next-line
    onSubmit: (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      setSubmitting(true);
      updateSettings();
      onClose?.();

      // TODO: set submitting state from apollo response
      showFlash({ message: 'Tax detail updated', type: 'success' });
      // setSubmitting(false);
    },
  });

  const { loading, data } = useGetFormLocaleOptions();
  const [updateSettings] = useMutation<UpdateReportDetailMutation, UpdateReportDetailMutationVariables>(
    UPDATE_SETTINGS,
    {
      variables: {
        id: id || 0,
        settings: {
          countryId: formik?.values.homeCountry?.value,
          baseCurrency: formik?.values.baseCurrency?.value,
          method: formik?.values.costBasisMethod?.value,
          marginalTaxRate: formik?.values.shortTerm,
          longTermCapitalGainsRate: formik?.values.longTerm,
        },
      },
      update: () => {
        //TODO: invalidate cache here instead of refetching
        onUpdate();
      },
      onCompleted: (data) => {
        const { updateReportDetail } = data;
        const baseCurrencyChanged = settings.baseCurrency !== updateReportDetail.baseCurrency;
        if (!baseCurrencyChanged) {
          onComplete();
        }

        refetchReportData();

        if (title !== 'Cost basis method') return;

        const prevYearTaxDetail = user?.taxDetails?.find(
          (f) => f.taxYear === data.updateReportDetail.taxYear - 1,
        );

        if (!prevYearTaxDetail) return;

        if (!prevYearTaxDetail.taxMethod) {
          showPreviousYearConfirmation({
            taxDetailsId: prevYearTaxDetail.id,
            year: prevYearTaxDetail.taxYear,
            onComplete: () => refetchReportData(),
          });
        }
      },
    },
  );

  const name = camelCase(title);

  const getOptions = (): OptionType[] | void => {
    if (name === 'homeCountry') {
      return data && data.countries.map((c) => ({ value: c.id, label: c.name }));
    } else if (name === 'baseCurrency') {
      return data && data.currencies.map((c) => ({ value: c.code, label: c.code }));
    } else {
      return methods && methods.map((m) => ({ value: m.id, label: m.label }));
    }
  };

  const renderFormField = ({ values, setFieldValue, setFieldTouched }: FormikProps<Values>) => {
    if (name === 'shortTerm' || name === 'longTerm') {
      const formValue = get(values, name);
      const value = formValue === undefined ? formValue : Big(formValue).times(100).toFixed(2);

      return (
        <NumberInput
          id={name}
          isNumericString
          isAllowed={(values: NumberFormatValues) => {
            const { floatValue } = values;
            if (!floatValue) {
              return true;
            } else {
              return floatValue >= 0 && floatValue < 100;
            }
          }}
          label={title}
          suffix="%"
          value={value}
          onValueChange={({ floatValue }) => {
            setFieldValue(name, floatValue === undefined ? undefined : Big(floatValue).div(100).toNumber());
            setFieldTouched(name, true);
          }}
        />
      );
    }

    return (
      <Select<unknown>
        id={name}
        label={title}
        options={getOptions() || []}
        onChange={(value) => setFieldValue(name, value)}
        onBlur={() => setFieldTouched(name, true)}
        value={get(values, name)}
      />
    );
  };

  const disableSubmit = !formik.isValid || formik.isSubmitting || !formik.dirty ? true : false;

  return (
    <Modal loading={loading} title="Settings" onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        {renderFormField(formik)}
        <div className="pt-6">
          <Button disabled={disableSubmit} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SettingsModal;

export function showSettingsModal(props: SettingsModalProps) {
  showModal(<SettingsModal {...props} />);
}
