import Big from 'big.js';
import { chain, groupBy, partition, uniq } from 'lodash';
import { SetRequired } from 'type-fest';
import { TxnType } from '../../../../graphql/types';
import { CoreTxn, UnreconciledTransaction } from './types';

type Txn = SetRequired<UnreconciledTransaction, 'txns'>['txns'][0];

type NetMergeResult = {
  // TODO simplify this result
  mergedTxn: CoreTxn;
};

export const netMerge = (txns: Txn[]): NetMergeResult[] => {
  if (txns.length < 2) {
    throw new Error('Cannot net merge fewer than two transactions');
  }

  if (!txns.every(({ txnType }) => txnType === 'withdrawal' || txnType === 'deposit')) {
    throw new Error('All transactions should be either withdrawals or deposits');
  }

  const uniqueTokenIds = chain(txns)
    .map(({ txnType, sellTokenId, buyTokenId }) => (txnType === 'withdrawal' ? sellTokenId : buyTokenId))
    .uniq()
    .value();

  const uniqueCurrencies = chain(txns)
    .map(({ txnType, sellCurrency, buyCurrency }) => (txnType === 'withdrawal' ? sellCurrency : buyCurrency))
    .uniq()
    .value();

  if (uniqueTokenIds.length > 1 || uniqueCurrencies.length > 1) {
    throw new Error('Cannot net merge txns with different tokens or currencies');
  }

  const [deposits, withdrawals] = partition(txns, ({ txnType }) => txnType === 'deposit');
  const sellQuantityTotal = withdrawals.reduce((acc, txn) => acc.plus(txn.sellQuantity ?? 0), Big(0));
  const buyQuantityTotal = deposits.reduce((acc, txn) => acc.plus(txn.buyQuantity ?? 0), Big(0));
  const feeQuantityTotal = txns.reduce((acc, txn) => acc.plus(txn.feeQuantity ?? 0), Big(0));
  const firstFeeTxn = txns.find((txn) => txn.feeQuantity !== undefined);

  if (sellQuantityTotal.gt(buyQuantityTotal)) {
    const [firstWithdrawal] = withdrawals;
    return [
      {
        mergedTxn: {
          id: firstWithdrawal.id,
          txnType: TxnType.withdrawal,
          buyQuantity: '',
          buyCurrency: '',
          buyTokenId: undefined,
          buyPrice: '',
          sellQuantity: sellQuantityTotal.minus(buyQuantityTotal).toFixed(),
          sellCurrency: uniqueCurrencies[0],
          sellTokenId: uniqueTokenIds[0],
          sellPrice: withdrawals[0].sellPrice,
          feeQuantity: feeQuantityTotal.toNumber(),
          feeCurrency: firstFeeTxn?.feeCurrency,
          feeTokenId: firstFeeTxn?.feeTokenId,
          feePrice: firstFeeTxn?.feePrice ?? '',
        },
      },
    ];
  }

  if (buyQuantityTotal.gt(sellQuantityTotal)) {
    const [firstDeposit] = deposits;
    return [
      {
        mergedTxn: {
          id: firstDeposit.id,
          txnType: TxnType.deposit,
          buyQuantity: buyQuantityTotal.minus(sellQuantityTotal).toFixed(),
          buyCurrency: uniqueCurrencies[0],
          buyTokenId: uniqueTokenIds[0],
          buyPrice: deposits[0].buyPrice,
          sellQuantity: '',
          sellCurrency: '',
          sellTokenId: undefined,
          sellPrice: '',
          feeQuantity: feeQuantityTotal.toNumber(),
          feeCurrency: firstFeeTxn?.feeCurrency,
          feeTokenId: firstFeeTxn?.feeTokenId,
          feePrice: firstFeeTxn?.feePrice ?? '',
        },
      },
    ];
  }

  return [];
};

export const applyNetMergeWherePossible = (txns: Txn[]): CoreTxn[] => {
  const txnsByCurrency = groupBy(txns, (txn) =>
    txn.txnType === 'withdrawal' ? txn.sellCurrency : txn.buyCurrency,
  );

  const [netMergeableTxns, otherTxnsArray] = partition(
    Object.values(txnsByCurrency),
    // netMergeableTxns are all the arrays of at least 2 txns which have the same currency
    (txnsForCurrency) => {
      if (txnsForCurrency.length <= 1) return false;
      const tokenIds = txnsForCurrency.map((txn) =>
        txn.txnType === 'withdrawal' ? txn.sellTokenId : txn.buyTokenId,
      );
      return uniq(tokenIds).length === 1;
    },
  );

  const otherTxns = otherTxnsArray.flat();
  const netMergeResults = netMergeableTxns.flatMap(netMerge);
  const netMergeTxns = netMergeResults.map(({ mergedTxn }) => mergedTxn);

  return [...netMergeTxns, ...otherTxns];
};
