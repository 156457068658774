import React, { Fragment, memo } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import type { NavItem } from './types';

export const topLevelLinkClasses = 'text-white';

const dropdownLinkClasses =
  'text-left w-full bg-light-base dark:bg-dark-base hover:bg-light-hover hover:dark:bg-dark-hover px-2 py-2';

const NavDropdownItem = ({ item, active }: { item: NavItem; active: boolean }) => {
  const linkItemClasses = classNames(dropdownLinkClasses, {
    'bg-light-hover dark:bg-dark-hover': active,
  });

  return (
    <Fragment>
      {item.path?.includes('http') && (
        <a className={linkItemClasses} href={item.path as string} target="_blank" rel="noreferrer">
          {item.name}
        </a>
      )}
      {item.path && !item.path.includes('http') && (
        <Link className={linkItemClasses} to={item.path as string}>
          {item.name}
        </Link>
      )}

      {item.onClick && (
        <button className={linkItemClasses} onClick={item.onClick}>
          {item.name}
        </button>
      )}
    </Fragment>
  );
};

export default memo(NavDropdownItem);
