import React from 'react';
import { constant } from 'lodash';

import { ContentfulPlanData, ContentfulAddonData } from '../lib/contentfulClient';

export const PlansContext = React.createContext<{
  cryptoOnlyPlans: ContentfulPlanData[];
  addons: ContentfulAddonData[];
  fullFilingPlans: ContentfulAddonData[];
  loading: boolean;
  confirmPlanPricesAreStillValid: () => Promise<boolean>;
}>({
  loading: true,
  cryptoOnlyPlans: [],
  addons: [],
  fullFilingPlans: [],
  confirmPlanPricesAreStillValid: constant(Promise.resolve(false)),
});

PlansContext.displayName = 'PlansContext';
