import moment from 'moment';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { RecalcContext } from '../../contexts';
import { useRecalculate } from '../../graphql/mutations';
import { getTimeDifference } from '../../lib/formatters';
import { useObject } from '../../lib/hooks';
import { useLatestTxnReportJob, useNeedsRecalc } from './queries';

interface Props {
  children: ReactNode;
}

const POLL_INTERVAL_MS = 1500;

function RecalcProvider({ children }: Props) {
  // occasionally we'll want to "force" the isRecalculating state to true
  // at the time of writing this is the case when marking a Tax LossHarvesting asset as lost
  // which triggers a txns processing job, followeb by a recalc
  // for UX purposes we'll want to show the user that the recalc is in progress immediately
  // and until both jobs are completed
  const [forcedIsRecalculatingState, setForcedIsRecalculatingState] = useState(false);

  const [pollInterval, setPollinterval] = useState(0); // no polling
  const {
    data: needsRecalcData,
    refetch: refetchNeedsRecalc,
    loading: needsRecalcLoading,
  } = useNeedsRecalc({
    context: { batched: true },
  });

  const { data: { latestTxnReportJob } = {} } = useLatestTxnReportJob({
    pollInterval,
    onCompleted: ({ latestTxnReportJob }) => {
      if (!latestTxnReportJob) return;

      const jobStatus = latestTxnReportJob.jobStatus;
      if (jobStatus === 'created') {
        setPollinterval(POLL_INTERVAL_MS);
      } else if (pollInterval > 0) {
        refetchNeedsRecalc();
        setPollinterval(0);
      }
    },
  });

  const needsRecalc = !needsRecalcLoading && (needsRecalcData?.needsRecalc ?? false);

  const [callRecalculateMutation, { data: recalcData, loading: recalcIsStarting }] = useRecalculate({
    context: { batched: true },
  });

  const currentJobDurationObject = useMemo(
    () =>
      latestTxnReportJob?.jobStatus === 'created'
        ? getTimeDifference({
            startDate: moment.utc(latestTxnReportJob.createdAt).toDate(),
            endDate: new Date(),
          })
        : null,
    [latestTxnReportJob],
  );

  const threeMinAgo = moment().subtract(3, 'minutes');
  const shouldDisplayLatestJobStatus =
    latestTxnReportJob && moment(latestTxnReportJob.finishedAt).isAfter(threeMinAgo);

  const recalculate = useCallback(
    async (options?: { allYearOverride?: boolean }) => {
      await callRecalculateMutation({
        variables: { options },
      });
      setPollinterval(POLL_INTERVAL_MS);
    },
    [callRecalculateMutation, setPollinterval],
  );

  const isRecalculating =
    forcedIsRecalculatingState ||
    latestTxnReportJob?.jobStatus === 'created' ||
    recalcIsStarting ||
    pollInterval !== 0;

  const contextValue = useObject({
    needsRecalc,
    setForcedIsRecalculatingState,
    isRecalculating,
    refetchNeedsRecalc,
    recalculate,
    noTaxYearsToRecalculate: recalcData?.recalculateTxnReports === null,
    latestJobState: shouldDisplayLatestJobStatus ? latestTxnReportJob.jobStatus : undefined,
    currentJobDurationObject,
  });

  return <RecalcContext.Provider value={contextValue}>{children}</RecalcContext.Provider>;
}

export default RecalcProvider;
