import React from 'react';
import Spinner from '../../Spinner';
import { TABLE_MIN_HEIGHT_FOR_SPINNER } from './constants';

interface BodyLoadingProps {
  initialLoad: boolean;
  currentTableHeight: number;
}

function BodyLoading({ initialLoad, currentTableHeight }: BodyLoadingProps) {
  // we only show the spinner if the numRows being shown would be > TABLE_MIN_HEIGHT_FOR_SPINNER
  // if less than that height is being shown - then the spinner at top of table should be in view anyway
  const showSpinner = initialLoad || currentTableHeight > TABLE_MIN_HEIGHT_FOR_SPINNER;
  return (
    <>
      <div className="bg-light-base dark:bg-dark-base absolute inset-0 z-10" />
      <div className="sticky h-0 z-20" style={{ top: '20%' }}>
        {/* top of spinner is set to 1 half of the min height of the table that it is enabled for */}
        {showSpinner && (
          <div
            className="inline relative transform -translate-x-1/2 -translate-y-1/2"
            style={{ top: TABLE_MIN_HEIGHT_FOR_SPINNER / 2, left: '50%' }}
          >
            <Spinner size="md" />
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(BodyLoading);
