import { useReactiveVar } from '@apollo/client';
import { constant } from 'lodash';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { TxnSortOptions, TxnsQuery } from '../../../graphql/types';
import Card from '../../Card';
import type { Response } from '../../FilteredTable';
import FilteredTable from '../../FilteredTable';
import SelectedItemCell from '../../FilteredTable/SelectedItemCell';
import { Cell } from '../../Table';
import Tooltip from '../../Tooltip';
import { VipUpsellBanner } from '../../VipUpsellBanner';
import { AllTransactionsContext } from '../Provider/context';
import { actions } from '../actions';
import { defaultHiddenColumnsStorageKey } from '../defaults';
import { filtersQueryParamsConf } from '../queryParams';
import { useIsFilterQueryEmpty } from '../selectors';
import type { FiltersState } from '../types';
import { FilteringQueryParams } from '../types';
import { vars } from '../vars';
import SelectionMenu from './SelectionMenu';
import TxnToolbar from './TxnToolbar';
import { useAllTransactionsBlankSlate } from './blankSlate';
import { useColumns } from './useColumns';

type Txn = TxnsQuery['txns']['edges'][0];

export default function AllTransactionsTable() {
  const {
    totalPages,
    loading,
    countsLoading,
    tableData,
    counts,
    txnsBeingProcessed,
    specIdSellId,
    specIdTxnResponse,
    txns,
  } = useContext(AllTransactionsContext);

  const { data: specIdTxn } = specIdTxnResponse ?? {};

  const txnTotalCount = counts?.totalCount;
  const filteredQueryIsEmpty = useIsFilterQueryEmpty();
  const columns = useColumns();

  useEffect(() => {
    if (!specIdTxn) {
      vars.txnIdsLinkedToSpecIdVar([]);
    }
  }, [specIdTxn]);

  const selectionMenu = useMemo(() => <SelectionMenu />, []);
  return (
    <FilteredTable<string, Txn, TxnSortOptions, FiltersState, FilteringQueryParams>
      columns={columns}
      loading={loading}
      countsLoading={countsLoading}
      filterParamsConf={filtersQueryParamsConf}
      vars={vars}
      tableData={tableData as Response<Txn>}
      disabledIds={specIdSellId ? 'all' : txnsBeingProcessed}
      renderToolbar={(columnSelector: ReactElement) => (
        <Card padding="none" className="border-b-0 rounded-b-none">
          <TxnToolbar columnSelector={columnSelector} showSpinner={(txnsBeingProcessed ?? []).length > 0} />
        </Card>
      )}
      selectionMenu={selectionMenu}
      totalPages={totalPages ?? 0}
      actions={actions}
      blankslate={useAllTransactionsBlankSlate({ filteredQueryIsEmpty, txnTotalCount })}
      className="rounded-t-none"
      externalToolbar
      hiddenColumnsStorageKey={defaultHiddenColumnsStorageKey}
      headerOverlay={<VipUpsellBanner />}
      selectionColumnOverrides={useMemo(
        () => ({
          ...(specIdTxn && { Header: constant(<></>) }),
          Cell: function SelectedItemCellWrapper(cell: Cell<Txn, string>) {
            const txnIdsLinkedToSpecId = useReactiveVar(vars.txnIdsLinkedToSpecIdVar);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const txn = txns!.find((txn) => txn.id === cell.row.original.id)!;
            const { id, quantityUsedInOtherSpecs, fullAvailableQuantity } = txn;
            const buyQuantity = parseFloat(txn.buyQuantity);

            if (txnIdsLinkedToSpecId.includes(id)) {
              return (
                <div className="h-full flex items-center">
                  <Tooltip icon="chain" margin="mr-2">
                    This transaction is included in the Spec ID
                  </Tooltip>
                </div>
              );
            }

            if (specIdTxn) {
              if (buyQuantity > 0 && quantityUsedInOtherSpecs === buyQuantity) {
                return (
                  <div className="h-full flex items-center">
                    <Tooltip icon="question" margin="mr-2">
                      The buy amount in this transaction already covers at least one different Spec ID
                    </Tooltip>
                  </div>
                );
              }

              if (fullAvailableQuantity && fullAvailableQuantity < 0) {
                return (
                  <div className="h-full flex items-center">
                    <Tooltip icon="question" margin="mr-2">
                      The net amount in this transaction (quantity - fees) is negative and thus it cannot be
                      used in a Spec ID
                    </Tooltip>
                  </div>
                );
              }
            }

            return (
              <SelectedItemCell<Txn, TxnSortOptions, FilteringQueryParams, string>
                cell={cell}
                items={tableData?.edges}
                multiSelectVar={vars.multiSelectVar}
                actions={actions}
                disabled={false}
              />
            );
          },
        }),
        [specIdTxn, tableData?.edges, txns],
      )}
    />
  );
}
