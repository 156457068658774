const meta: { [key: string]: { title: string; description: string; keywords?: string; image?: string } } = {
  HOME: {
    title: 'Crypto Tax Calculator | Cryptocurrency Taxes | TokenTax',
    description:
      'TokenTax has been rated best software for calculating and filing your crypto taxes. Connect any exchange, track your gains, and automatically create your tax forms.',
    keywords: 'Bitcoin Tax, Cryptocurrency Taxes',
    image: 'https://i.imgur.com/5zRMBwW.png',
  },
  PRODUCT: {
    title: 'Cryptocurrency Tax Software | File Crypto Taxes | TokenTax',
    description:
      'Import your crypto trading from any exchange into our software and export the required bitcoin tax forms. Easy Tax!',
    keywords: 'Bitcoin tax software, crypto tax software',
  },
  EXCHANGES: {
    title: 'Bitcoin Tax Exchange Filing Software | Crypto Exchange Tax Forms',
    description: 'Login to TokenTax and file your cryptocurrency tax quickly from any top exchange.',
    keywords: 'Coinbase tax filling, Kraken tax filling, Binance tax filling, CEX tax filling',
  },
  LOGIN: {
    title: 'TokenTax Login | Access Your Crypto Tax Portfolio | TokenTax',
    description:
      'Login to your TokenTax portfilio and file your cryptocurrency tax quickly from any top exchange.',
  },
  SIGNUP: {
    title: 'TokenTax Sign Up | Easily Pay Your Crypto Taxes | TokenTax',
    description: 'Sign Up now to TokenTax and manage yoru crypto taxes in one place from any major exchange.',
  },
  ABOUT: {
    title: 'About TokenTax | New York Cryptocurrency Tax | US Tax Company',
    description:
      'TokenTax are a cryptocurrency tax platform based in New York, founded by Zac McClure & Alex Miles .',
  },
  PRICING: {
    title: 'Bitcoin Taxes Software | Crypto Tax Report Tool | Pricing Packages',
    description:
      'TokenTax provides an easy to use platform for filing your crypto tax, with affordable prices. Easy Sign Up!',
  },
  FULL_FILING: {
    title: 'Bitcoin Tax Return Software | Prices and Packages | TokenTax',
    description: 'TokenTax not only does crypto taxes but your full tax returns. Easy Sign Up!',
  },
  BLOG: {
    title: 'Cryptocurrency Tax Blog | Latest Tax News & Crypto Tax Tips',
    description:
      'The TokenTax blog provides industry cryptocurrency tax articles from news, tips and tax guides.',
    keywords: 'crypto Tax guides, cryptocurrency tax',
  },
  FAQ: {
    title: 'Bitcoin Tax Return Help & Guidance',
    description: 'Understand the terms and conditions for filling crypto tax returns with TokenTax.',
  },
  COOKIES: {
    title: 'Cookie Policy | TokenTax',
    description:
      'Our cookie policy outlines how we use browsing cookies to improve your experience of our website.',
  },
  TERMS: {
    title: 'Terms & Conditions | TokenTax',
    description:
      'Understand the terms and conditions for using TokenTax regarding the filing of your crypto taxes.',
  },
  PRIVACY: {
    title: 'Privacy Policy | TokenTax',
    description:
      'Privacy is important to us and it&apos;s something we take seriously. Please review our privacy policy for details.',
  },
  WEBINAR: {
    title: 'Ask Us Anything: New IRS Crypto Guidance Livestream | TokenTax',
    description: 'Learn about how TokenTax can make crypto taxes an easy process',
  },
  ENTERPRISE: {
    title: 'Enterprise | TokenTax',
    description: 'Learn about how TokenTax can make crypto taxes an easy process',
  },
  FORGOT: {
    title: 'Forgotten Password | Password Reset | TokenTax',
    description:
      'How do I change my password? If you want to change your password, you can do so from the the forgotten password screen in TokenTax.',
  },
};

export default meta;
