import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { UsePaginationInstanceProps, UseTableOptions, usePagination, useTable } from 'react-table';
import { useObject } from '../../../lib/hooks';
import Card from '../../Card';
import { createTableContext } from '../context';
import { ControlledTableProps } from './types';

export { ControlledState, ControlledTableProps, ControlledTableState } from './types';

function ControlledTable<T extends Record<string, unknown>>(
  props: ControlledTableProps<T>,
): React.ReactElement {
  const {
    data,
    columns,
    handleSort,
    handlePageChange,
    controlledState,
    baseCurrency,
    children,
    expandedRowIds,
    expandedRowComponent,
    gridGapSize = '12px',
    gridTemplateColumns: gridTemplateColumnsProp,
    paddingLeft,
    paddingRight,
    blankslate,
    className,
    scrolling = true,
    disabledIds = [],
    headerOverlay,
  } = props;
  const { initialLoad, loading } = controlledState;

  const [lastLoadedData, setLastLoadedData] = useState<T[] | undefined>(undefined);
  const [windowedScrollBarPadding, setWindowedScrollBarPadding] = useState<number | undefined>(undefined);

  // if after initialLoad and there is data we set the 'lastLoadedData' state to hold on to our previously loaded data
  // this helps keep the page height from jumping dramatically when going into and out of loading state
  useEffect(() => {
    if (!initialLoad && data.length > 0) {
      setLastLoadedData(data);
    }
  }, [loading, initialLoad, data, setLastLoadedData]);

  let displayedData = data;

  // if we are past the 'initialLoad' and loading a new page then we will continue displaying old data until the new data is fetched.
  if (!initialLoad && loading && lastLoadedData !== undefined) {
    displayedData = lastLoadedData;
  }

  const tableOptions = useObject({
    columns,
    data: displayedData,
    manualPagination: true,
    // we handle all the pagination state ourselves via useControlled state
    // react-table doesn't need to know anything about pageCount/currentPage (we won't use their 'hasNextPage' value)
    pageCount: -1,
  }) as UseTableOptions<T>;

  const instance = useTable<T>(tableOptions, usePagination);

  const { getTableProps } = instance;

  const TableContext = createTableContext();
  const handlers = useObject({ handleSort, handlePageChange });

  const gridTemplateColumns =
    gridTemplateColumnsProp ||
    columns
      .map(({ width, min, max }) => {
        if (min || max) return `minmax(${min ?? '0px'}, ${max ?? '1fr'})`;
        return width ?? 'minmax(0, 1fr)';
      })
      .join(' ');

  const gridStyle = useObject({
    gridTemplateColumns,
    display: 'grid',
    columnGap: gridGapSize,
    // `columnGap` applies to space between columns only, so this adds matching padding for the outer columns
    paddingLeft: paddingLeft ?? gridGapSize,
    paddingRight: paddingRight ?? gridGapSize,
  });

  const contextValue = useObject({
    instance,
    columns,
    paginationInstance: instance as unknown as UsePaginationInstanceProps<T>,
    handlers,
    controlledState,
    baseCurrency,
    tableType: 'controlled',
    expandedRowIds,
    windowedScrollBarPadding,
    setWindowedScrollBarPadding,
    ExpandedRowComponent: expandedRowComponent,
    gridStyle,
    disabledIds,
  });

  return (
    <TableContext.Provider value={contextValue}>
      {headerOverlay}
      <Card
        className={cx(
          'tt_table',
          'flex', // this makes the entire table scrollable together with pagination, headers etc
          'overflow-x-auto xl:overflow-x-visible',
          scrolling && 'overflow-y-hidden',
          'flex-grow',
          className,
        )}
        padding="p-0"
      >
        <div
          {...getTableProps()}
          className={cx(
            'relative flex flex-col rounded-b',
            'flex-grow', // ensures table spans at least width of parent
            !blankslate && 'h-full',
          )}
        >
          {children}
          {blankslate && <div className="p-4">{blankslate}</div>}
        </div>
      </Card>
    </TableContext.Provider>
  );
}

export default React.memo(ControlledTable) as unknown as typeof ControlledTable;
