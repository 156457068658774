import { gql } from '@apollo/client';

export const ACCOUNT = gql`
  fragment Account on Account {
    id
    userId
    name
    address
    createdAt
    updatedAt
    integrationId
  }
`;
