import { map, pick, sum } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DeepNonNullable } from 'utility-types';
import { InvoiceQuery } from '../../graphql/types';
import { formatCurrencyAmount } from '../../lib/formatters';
import Input from '../Input';
import Checkbox from '../Input/Checkbox';
import Invoice from '../Invoice';
import { showConfirmationDialog } from '../Modal/ConfirmationDialog';
import Spinner from '../Spinner';
import TextLink from '../TextLink';
import { ErrorToast } from '../Toast';
import CreateInvoiceHeader from './Header';
import InvoiceProducts, { SelectedProducts } from './InvoiceProducts';
import LineItems from './LineItems';
import { useCreateInvoice, useUser } from './queries';
import { useInvoice } from './useInvoice';
import { isInvoiceValid } from './validation';

type Line = InvoiceQuery['invoice']['lines'][0];

const DEFAULT_DESCRIPTION = 'Thank you for your business!';

interface Params {
  userId?: string;
}

function CreateInvoice() {
  const [error, setError] = useState<string>();
  const [invoice, setInvoice] = useInvoice();
  const [selectedProducts, setSelectedProducts] = useState<SelectedProducts>({
    cryptoOnlyPlans: [],
    fullFilingPlans: [],
  });

  const updateInvoice = useCallback(
    (lines: DeepNonNullable<Line>[]) => {
      const amountDue = sum(map(lines, 'amount'));
      setInvoice((previousInvoiceValue) => ({
        ...previousInvoiceValue,
        amountDue,
        amountRemaining: amountDue,
        lines,
      }));
    },
    [setInvoice],
  );

  const [newUserEmail, setNewUserEmail] = useState('');
  const { userId } = useParams<Params>();
  const isCreatingNewUser = !userId;
  const { firstName, lastName, email, loading } = isCreatingNewUser
    ? {
        firstName: null,
        lastName: null,
        email: newUserEmail,
        loading: false,
      }
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useUser({
        variables: { userId: Number(userId) },
        onCompleted: ({ user }) => {
          if (!user) return;
          setInvoice({ ...invoice, customerEmail: user.email });
        },
      });

  const { hideDefaultCrypto, description } = invoice;

  const history = useHistory();
  const [createInvoice] = useCreateInvoice();
  const onCreate = useCallback(async () => {
    const { description, dueDate, amountDue, lines, customerEmail } = invoice;

    if (!isInvoiceValid(invoice, isCreatingNewUser, newUserEmail)) {
      setError('Please check all field values');
      return;
    }

    setError(undefined);

    await showConfirmationDialog({
      title: 'Confirm Invoice Creation',
      subtitle: `Send Invoice for ${formatCurrencyAmount(amountDue / 100)} to ${
        isCreatingNewUser ? newUserEmail : customerEmail
      }?`,
      buttonText: 'Send invoice email to client',
      onConfirm: async () => {
        const res = await createInvoice({
          variables: {
            ...(isCreatingNewUser
              ? {
                  newUserEmail,
                }
              : {
                  userId: Number(userId),
                }),
            description,
            dueDate,
            lines: lines.map((line) => pick(line, ['unitAmount', 'quantity', 'description'])),
            products: selectedProducts,
            hideDefaultCrypto,
          },
        });

        if (!res.data) {
          throw new Error('No invoice created');
        }

        const invoiceId = res.data.createInvoice;
        history.push(`/invoice/admin?id=${invoiceId}`);
      },
    });
  }, [
    invoice,
    createInvoice,
    userId,
    selectedProducts,
    history,
    isCreatingNewUser,
    newUserEmail,
    hideDefaultCrypto,
  ]);

  if (!isCreatingNewUser && loading) {
    return (
      <div className="flex h-screen w-screen justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!isCreatingNewUser && !email) {
    return (
      <div className="w-screen h-screen flex justify-center items-center bg-white">
        Could not find a user with id {userId}. Please go back to the
        <TextLink className="pl-1" to="/home">
          home page
        </TextLink>
        .
      </div>
    );
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-light-base dark:bg-dark-base flex flex-col z-auto">
      <CreateInvoiceHeader {...{ onCreate }} />

      <div className="flex flex-grow flex-col lg:flex-row overflow-y-auto">
        {error && <ErrorToast className="w-full p-4 absolute">{error}</ErrorToast>}

        <div className="lg:w-3/5 p-4 lg:p-12">
          <div className="text-xl py-4">Customer</div>
          {isCreatingNewUser ? (
            <div className="w-full sm:w-96">
              <Input
                label="Email"
                onChange={(event) => {
                  setNewUserEmail(event.target.value);
                }}
              />
            </div>
          ) : (
            `${firstName && lastName ? `${firstName} ${lastName} - ` : ''} ${email}`
          )}

          <div
            className="flex my-4 w-full sm:w-96 cursor-pointer"
            onClick={() =>
              setInvoice((invoice) => ({
                ...invoice,
                hideDefaultCrypto: !invoice.hideDefaultCrypto,
              }))
            }
          >
            <Checkbox checked={hideDefaultCrypto} label="Hide default crypto payment options" />
          </div>

          <Input
            label="Memo"
            value={description || ''}
            placeholder={DEFAULT_DESCRIPTION}
            onChange={(event) => {
              // do not read this property inside the callback or you may try to access `.value` when `target` is set to `null`
              // https://reactjs.org/docs/legacy-event-pooling.html
              const { value } = event.target;
              setInvoice((invoice) => ({ ...invoice, description: value || null }));
            }}
          />

          <div className="mt-8">
            <div className="text-xl pt-4 pb-0 sm:pb-4">Line Items</div>
            <LineItems updateInvoice={updateInvoice} />
          </div>

          <div className="mt-8">
            <div className="text-xl py-4">Products</div>
            <InvoiceProducts updateSelectedProducts={setSelectedProducts} />
          </div>
        </div>

        <div className="lg:w-2/5 p-4 lg:p-12 flex flex-col h-full">
          <div className="text-xl py-4">Preview</div>
          <div className="bg-light-brand dark:bg-dark-site-bg rounded flex-grow">
            <Invoice invoice={{ invoice }} disabled />
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(CreateInvoice);
