import React, { useMemo } from 'react';

import { useAllAccounts } from '../../graphql/queries/accounts';
import { useIntegrationsContext } from '../../providers/IntegrationsProvider';
import Select from '../Select';
import Spinner from '../Spinner';

interface Props {
  accountId: number | null | undefined;
  onChange: (integrationId: number) => void;
  className?: string;
}

function AccountSelector({ accountId, onChange, className }: Props) {
  const { data: allAccounts = [], loading } = useAllAccounts();
  const { allIntegrations } = useIntegrationsContext();

  const options = useMemo(() => {
    return allAccounts.map((account) => {
      const integration = allIntegrations.find((i) => i.id === account.integrationId);
      const suffix = account.address || integration?.name || '';
      return {
        value: account.id as number,
        label: `${account.name}${suffix ? ` (${suffix})` : ''}`,
      };
    });
  }, [allAccounts, allIntegrations]);

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === accountId);
  }, [options, accountId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={`relative${className ? ` ${className}` : ''}`}>
      <Select<number>
        isSearchable
        options={options}
        onChange={(val) => onChange(val.value)}
        value={selectedOption}
      />
    </div>
  );
}

export default React.memo(AccountSelector);
