import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import NumberFormat, { InputAttributes, NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { textVariantClassnames } from '../Text';
import Label from './Label';
import baseClassName from './styles';

type Value = string | number;

interface NumberInputProps extends Omit<NumberFormatProps, 'onChange' | 'tabIndex' | 'onBlur'> {
  /** used with formik*/
  id?: string;
  /** disable input */
  disabled?: boolean;
  /** label for input */
  label?: string;
  /** blur handler */
  onBlur?: (currentValue: string) => void;
  /** change handler - gets called even if underlying props change - see react-number-format docs */
  onValueChange?: (values: NumberFormatValues) => void;
  /** change handler - only called by an actual HTMLInput change event, with the input's value as argument */
  onChange?: (value: string) => void;
  /** prefix eg. $ */
  prefix?: string;
  /** suffix eg. % */
  suffix?: string;
  /** tab order */
  tabIndex?: string;
  /** adds , delimiter when true */
  thousandSeparator?: boolean;
  /** value given to controlled input */
  value?: Value;
  /* if true, the input will be focused after mounting */
  initiallyFocused?: boolean;
  /* called when the enter/return key is pressed */
  onEnterPressed?: () => void;
  /* optional className */
  className?: string;
}

const NumberInput = ({
  disabled,
  label,
  onChange,
  onValueChange,
  tabIndex,
  value,
  onBlur,
  initiallyFocused,
  onEnterPressed,
  className,
  ...props
}: NumberInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [key, setKey] = useState<number>(0);
  const [isFocused, setIsFocused] = useState(false);
  const { prefix } = props;

  useEffect(() => {
    if (isFocused) return;
    // force a re-render of the component when the value changes so that if the value changes to `undefined`,
    // a placeholder is displayed instead of keeping any previously typed input
    setKey((key) => key + 1);
  }, [value, isFocused]);

  useEffect(() => {
    if (initiallyFocused) {
      inputRef.current?.focus();
    }
  }, [initiallyFocused, inputRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {label && <Label htmlFor={label}>{label}</Label>}
      <div tabIndex={tabIndex ? parseInt(tabIndex) : undefined}>
        <NumberFormat<InputAttributes>
          key={key}
          className={cx(baseClassName, disabled && textVariantClassnames.muted, 'p-4', className)}
          disabled={disabled}
          onValueChange={(values) => {
            onValueChange?.(values);
          }}
          onChange={(event) => {
            const { value } = event.target;
            const unprefixedValue = prefix ? value.replace(new RegExp(`^\\${prefix}`), '') : value;
            onChange?.(unprefixedValue);
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={(event) => {
            setIsFocused(false);
            onBlur?.(event.target.value);
          }}
          getInputRef={inputRef}
          value={value}
          {...props}
          onKeyPress={(evt) => {
            props?.onKeyPress?.(evt);
            if (evt.key === 'Enter') {
              onEnterPressed?.();
            }
          }}
        />
      </div>
    </>
  );
};

export default NumberInput;
