import { gql, useQuery } from '@apollo/client';
import { chunk } from 'lodash';
import { useEffect, useState } from 'react';
import {
  MissingWalletTxnCountQuery,
  MissingWalletTxnCountQueryVariables,
  MissingWalletsQuery,
  ValidateMissingWalletsQuery,
} from '../../../../graphql/types';
import { client } from '../../../../lib/apollo';

export const MISSING_WALLETS = gql`
  query MissingWallets {
    missingWallets {
      edges {
        id
        chain
        address
      }
    }
  }
`;

export const MISSING_WALLET_TXN_COUNT = gql`
  query MissingWalletTxnCount($wallets: [WalletTxnCountInput!]!) {
    missingWalletTxnCount(wallets: $wallets) {
      chain
      address
      count
      earliestTxnDate
      latestTxnDate
    }
  }
`;

type MissingWallet = MissingWalletsQuery['missingWallets']['edges'][0];
export type MissingWalletWithTxnCount = MissingWallet & {
  txnCount?: number | null;
  earliestTxnDate?: string | null;
  latestTxnDate?: string | null;
};

const COUNT_QUERIES_BATCH_SIZE = 20;

export function useMissingWallets() {
  const [missingWallets, setMissingWallets] = useState<Array<MissingWalletWithTxnCount> | undefined>();
  const { data, loading, ...rest } = useQuery<MissingWalletsQuery>(MISSING_WALLETS, {
    fetchPolicy: 'cache-and-network',
  });

  const { edges } = data?.missingWallets ?? {};

  useEffect(() => {
    if (!edges) return;
    setMissingWallets(edges);
    (async () => {
      for (const batch of chunk(edges, COUNT_QUERIES_BATCH_SIZE)) {
        const { data } = await client.query<MissingWalletTxnCountQuery, MissingWalletTxnCountQueryVariables>({
          query: MISSING_WALLET_TXN_COUNT,
          variables: { wallets: batch.map(({ chain, address }) => ({ chain, address })) },
          context: { batched: true },
        });
        const { missingWalletTxnCount } = data;
        if (!missingWalletTxnCount) return;
        const newResultsByChainAndAddress = Object.fromEntries(
          missingWalletTxnCount.map((result) => [`${result.chain}.${result.address}`, result]),
        );
        setMissingWallets(
          (prev) =>
            prev?.map((wallet) => {
              const { count, earliestTxnDate, latestTxnDate } =
                newResultsByChainAndAddress[`${wallet.chain}.${wallet.address}`] ?? {};

              const walletWithTxnCount = { ...wallet };
              if (count !== undefined) {
                walletWithTxnCount.txnCount = count;
              }

              if (earliestTxnDate) {
                walletWithTxnCount.earliestTxnDate = earliestTxnDate;
              }

              if (latestTxnDate) {
                walletWithTxnCount.latestTxnDate = latestTxnDate;
              }

              // Some credentials do not have APIs that give us this info,
              // so we want to override the default state values if we get
              // explicit nulls for both (as opposed to undefined)
              if (earliestTxnDate === null && latestTxnDate === null) {
                walletWithTxnCount.earliestTxnDate = null;
                walletWithTxnCount.latestTxnDate = null;
              }

              return walletWithTxnCount;
            }),
        );
      }
    })();
  }, [edges]);

  return { ...rest, data: missingWallets, loading: !data && loading };
}

export const VALIDATE_MISSING_WALLETS = gql`
  query ValidateMissingWallets($missingWallets: [MissingWalletValidationInput!]!) {
    validateMissingWallets(missingWallets: $missingWallets) {
      edges {
        id
        hasTransactions
      }
    }
  }
`;

export function useValidateMissingWallets(missingWallets: Array<{ chain: string; address: string }>) {
  return useQuery<ValidateMissingWalletsQuery>(VALIDATE_MISSING_WALLETS, {
    variables: { missingWallets },
    skip: !missingWallets.length,
  });
}
