import classNames from 'classnames';
import React, { MouseEvent, useCallback } from 'react';
import { LatestTxnReportsQuery } from '../../../graphql/types';
import Card from '../../Card';
import HR from '../../HR';
import { textLinkVariantClassnames } from '../../TextLink';
import { FfpByYear, showGenerateFormModal } from './Modal';

type LatestTxnReportsByYearType = NonNullable<
  LatestTxnReportsQuery['latestTxnReports']
>['latestTxnReportsByYear'][0];
type ReportTaxDetail = LatestTxnReportsByYearType['taxDetail'];
type MethodType = NonNullable<ReportTaxDetail['taxMethod']>;

interface GenerateFormProps {
  /** country setting */
  countryId?: number;
  /** fullFilingPlan by tax year */
  fullFilingPlanByTaxYear?: FfpByYear;
  /** methods to populate select options*/
  methods: MethodType[];
  /** lineItemCount for selected report */
  lineItemCount?: number;
  /** current report year */
  taxYear?: number;
}

const US_ID = 1;

const buttonClasses = classNames(
  `flex justify-between leading-7 p-8 py-10 w-full`,
  textLinkVariantClassnames.primary,
);

function GenerateForm({
  countryId,
  fullFilingPlanByTaxYear,
  lineItemCount,
  methods,
  taxYear,
}: GenerateFormProps) {
  const hasFFP = fullFilingPlanByTaxYear && Object.values(fullFilingPlanByTaxYear).some((ffp) => ffp);

  const isUSBased = countryId === US_ID || false;

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>, selectTokenTaxFlow?: boolean) => {
      e.preventDefault();
      showGenerateFormModal({
        isUSBased,
        completeReturn: selectTokenTaxFlow && hasFFP,
        fullFilingPlanByTaxYear,
        hasFFP,
        lineItemCount,
        methods,
        taxYear,
        upsell: selectTokenTaxFlow && !hasFFP,
      });
    },
    [fullFilingPlanByTaxYear, hasFFP, lineItemCount, isUSBased, methods, taxYear],
  );

  return (
    <>
      <Card className="flex-1 text-xl" sectioned padding="p-0">
        <button className={buttonClasses} onClick={handleClick}>
          Generate Tax Forms
          <i className="material-icons text-xl ml-2 relative top-[3px]">arrow_right</i>
        </button>
        {isUSBased ? (
          <>
            <HR className="my-0" />
            <button className={buttonClasses} onClick={(e) => handleClick(e, true)}>
              {hasFFP ? 'Complete Your Return' : 'File Your Return With TokenTax'}
              <i className="material-icons text-xl ml-2 relative top-[3px]">arrow_right</i>
            </button>
          </>
        ) : null}
      </Card>
    </>
  );
}

export default React.memo(GenerateForm);
