import { QueryHookOptions, useQuery } from '@apollo/client';
import { BKP_ACCOUNTS_VENDORS } from '../../components-v2/EditTxnPanel/Form/queries';
import { BkpAccountsVendorsQuery } from '../types';

export function useBkpAccountsVendors(options: QueryHookOptions<BkpAccountsVendorsQuery>) {
  const res = useQuery<BkpAccountsVendorsQuery>(BKP_ACCOUNTS_VENDORS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  return { ...res, data: res.data };
}
