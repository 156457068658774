import { gql } from '@apollo/client';
import { ORGANIZATION } from './Organization';
import { TAX_DETAIL } from './TaxDetail';

export const USER_INFO = gql`
  fragment UserInfo on User {
    id
    accountYears
    airtableId
    createdAt
    customerId
    deletedAt
    email
    firstName
    includeFees
    secretMode
    isTest
    lastName
    lastSuccessfulCharge
    lockedTilDate
    loginCount
    marginTradeAccount
    minimizationForAllYears
    orgDemoUser
    organizationId
    phone
    plan
    recalcByAccount
    recalcByAccountStartYear
    reconMode
    resetPasswordExpires
    resetPasswordToken
    role
    orgRoles {
      userId
      roleId
      organizationId
      createdAt
      updatedAt
      organization {
        ...Organization
      }
      role {
        name
      }
    }
    subscriptionId
    taxDetails {
      ...TaxDetail
    }
    updatedAt
    useHoursMinsComparison
    verified
  }
  ${TAX_DETAIL}
  ${ORGANIZATION}
`;
