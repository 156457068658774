import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  QueryHookOptions,
  createHttpLink,
  useQuery,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createClient } from 'contentful';
import { CONTENTFUL_ACCESS_TOKEN, CONTENTFUL_SPACE_ID } from '../constants';
import { INTEGRATIONS_DATA, PLANS_DATA } from './queries';
import { AddOn, Integration, Plan, PlanFeature, PlansData } from './shared';
import { IntegrationDataQuery } from './types';

export type ContentfulPlanData = Plan & {
  id: Exclude<Plan['id'], null | undefined>;
};
export type ContentfulAddonData = AddOn & {
  id: Exclude<AddOn['id'], null | undefined>;
};
export type ContentfulPlanFeature = PlanFeature;

export type ContentfulIntegration = Integration;

export const contentfulRestClient = createClient({
  space: CONTENTFUL_SPACE_ID,
  accessToken: CONTENTFUL_ACCESS_TOKEN,
});

let client: ApolloClient<unknown>;

const getClient = () => {
  if (!client) {
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${CONTENTFUL_ACCESS_TOKEN}`,
      },
    }));

    const httpLink = createHttpLink({
      uri: `https://graphql.contentful.com/content/v1/spaces/${CONTENTFUL_SPACE_ID}`,
    });

    client = new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from([authLink, httpLink]),
    });
  }

  return client;
};

export const usePlansData = (options?: QueryHookOptions<PlansData>) =>
  useQuery(PLANS_DATA, { ...options, client: getClient() });

export const useIntegrationsData = (options?: QueryHookOptions<IntegrationDataQuery>) =>
  useQuery(INTEGRATIONS_DATA, { ...options, client: getClient() });
