import React, { useMemo, useCallback, useRef, useLayoutEffect } from 'react';
import cx from 'classnames';

import { useTableContext } from '../context';
import Row from '../Row';
import { ROW_HEIGHT, TABLE_INITIAL_LOAD_HEIGHT } from './constants';
import { TableBodyProps } from './types';
import BodyLoading from './BodyLoading';

function ControlledBody<T extends Record<string, unknown>>({
  onRowClicked,
  isRowClickable,
  scrolling,
}: TableBodyProps<T>): React.ReactElement {
  const { controlledState, instance, paginationInstance, setWindowedScrollBarPadding } = useTableContext<T>();

  if (instance === undefined || paginationInstance === undefined) {
    throw Error('table and pagination instance should not be undefined for ControlledBody');
  }

  const { getTableBodyProps, prepareRow } = instance;

  const { page } = paginationInstance;

  if (controlledState === undefined) {
    throw Error('controlledState needs to be set for ControlledBody');
  }

  const { initialLoad, loading } = controlledState;

  const totalPages = controlledState.totalPages || 1;

  // we only need to run `prepareRow` once so use a memoized copy of the page to avoid unnecessary repeated calls to it
  const rows = useMemo(
    () =>
      page.map((row) => {
        prepareRow(row);
        return row;
      }),
    [page, prepareRow],
  );

  const bodyDiv = useRef<HTMLDivElement>(null);

  // the header is rendered outside the scrollable body - which may or may not have a scrollbar,
  // depending on the number of rows rendered and the available height.
  // this code keeps it in sync giving it some right-padding to align it with the content.
  const updateScrollBarPadding = useCallback(() => {
    if (!setWindowedScrollBarPadding || !bodyDiv.current) return;
    const { offsetWidth, scrollWidth } = bodyDiv.current;
    const scrollBarWidth = offsetWidth - scrollWidth;

    if (scrollBarWidth !== offsetWidth) {
      setWindowedScrollBarPadding(scrollBarWidth);
    }
  }, [setWindowedScrollBarPadding]);

  useLayoutEffect(() => {
    if (initialLoad || loading) return;
    updateScrollBarPadding();
  }, [rows, updateScrollBarPadding, initialLoad, loading]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateScrollBarPadding);
    return () => window.removeEventListener('resize', updateScrollBarPadding);
  }, [updateScrollBarPadding]);

  return (
    // we set minHeight on tableBody if initialLoad
    <div
      ref={bodyDiv}
      {...getTableBodyProps()}
      className={cx(
        'relative',
        'flex-grow',
        page.length > 0 ? 'flex-auto' : 'flex-initial',
        // bottom padding is intentional here to account for intercom chat box
        `pb-32`,
        scrolling && 'overflow-y-auto',
      )}
      style={initialLoad ? { minHeight: TABLE_INITIAL_LOAD_HEIGHT } : {}}
    >
      {loading && <BodyLoading initialLoad={initialLoad} currentTableHeight={page.length * ROW_HEIGHT} />}

      {!loading &&
        rows.map((row, rowIndex) => {
          let noBorder = false;
          const key = row.original.id ?? `row_${rowIndex}`;

          // if only 1 page and this is the last row then hide border
          if (totalPages === 1 && rowIndex === page.length - 1) {
            noBorder = true;
          }

          return (
            <Row
              key={String(key)}
              {...{
                row,
                noBorder,
                onClick: onRowClicked,
                isRowClickable,
              }}
            />
          );
        })}
    </div>
  );
}

export default React.memo(ControlledBody) as unknown as typeof ControlledBody;
