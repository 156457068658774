import React, { useCallback } from 'react';
import { FilterOperatorTypes } from '../../../graphql/types';
import Input, { InputHandle } from '../../Input';
import { BaseFilterProps } from '../types';
import { BaseSearchFilter } from './types';

export type SearchInputProps<SearchFilter extends BaseSearchFilter> = BaseFilterProps<SearchFilter> & {
  filterKey: SearchFilter['key'];
  hideLabel?: boolean;
};

function SearchInput<SearchFilter extends BaseSearchFilter>({
  filterKey,
  label,
  onChange,
  filter,
  hideLabel,
}: SearchInputProps<SearchFilter>) {
  const [value, setValue] = React.useState<string>(filter?.value || '');
  const inputRef = React.useRef<InputHandle>(null);

  const handleSubmit = useCallback(
    (e?: React.FormEvent) => {
      if (e) e.preventDefault();
      onChange({
        ...(filter ? filter : { operator: FilterOperatorTypes.Is }),
        value,
        key: filterKey,
      } as SearchFilter);
    },
    [filter, onChange, filterKey, value],
  );

  return (
    <div style={{ width: 240 }}>
      {!hideLabel && (
        <div className="text-center w-full p-1 border-b border-light-control dark:border-dark-control text-sm font-semibold">
          <span>{label}</span>
        </div>
      )}
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="p-2">
          <Input
            ref={inputRef}
            className="py-1 px-2"
            placeholder={label}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            tabIndex={0}
            initiallyFocused
            required
          />
        </div>
        <button
          type="submit"
          className="text-center w-full p-1 border-t border-light-control dark:border-dark-control text-sm font-semibold"
        >
          Apply
        </button>
      </form>
    </div>
  );
}

export default React.memo(SearchInput) as typeof SearchInput;
