import { InvoiceQuery } from '../../graphql/types';
import { isEmail } from '../../helpers/validators';
import { SelectedProducts } from './InvoiceProducts';

type Invoice = InvoiceQuery['invoice'];

export const isInvoiceValid = (invoice: Invoice, isCreatingNewUser: boolean, newUserEmail: string) => {
  const { dueDate, amountDue, lines, customerEmail } = invoice;

  return (
    dueDate &&
    dueDate > new Date().valueOf() / 1000 &&
    amountDue > 0 &&
    isEmail(isCreatingNewUser ? newUserEmail : customerEmail) &&
    lines.every(
      ({ amount, unitAmount, quantity, description: lineDescription }) =>
        unitAmount &&
        unitAmount !== 0 &&
        quantity &&
        quantity > 0 &&
        amount === unitAmount * quantity &&
        lineDescription &&
        lineDescription.length > 0,
    )
  );
};

export const isProductSelectionValid = ({ cryptoOnlyPlans, fullFilingPlans }: SelectedProducts) => {
  return cryptoOnlyPlans.length > 0 && fullFilingPlans.length > 0;
};
