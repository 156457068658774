import { gql, useMutation } from '@apollo/client';
import { showSuccessFlash } from '../../components-v2/Flash';
import { CreateMovementMutation, CreateMovementMutationVariables } from '../types';

const CREATE_MOVEMENT = gql`
  mutation CreateMovement(
    $txnIds: [String!]!
    $date: DateTime!
    $currency: String!
    $amount: String!
    $toAccountId: Int!
    $fromAccountId: Int!
  ) {
    createMovement(
      txnIds: $txnIds
      date: $date
      currency: $currency
      amount: $amount
      toAccountId: $toAccountId
      fromAccountId: $fromAccountId
    )
  }
`;

type Args = {
  onSuccess?: () => void;
};

export const useCreateMovement = ({ onSuccess }: Args) => {
  return useMutation<CreateMovementMutation, CreateMovementMutationVariables>(CREATE_MOVEMENT, {
    onCompleted: () => {
      showSuccessFlash('Movement created successfully');
      onSuccess && onSuccess();
    },
  });
};
