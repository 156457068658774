import cx from 'classnames';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Spinner from '../Spinner';

export interface ButtonProps {
  /* button size */
  large?: boolean;
  /* children */
  children: React.ReactNode;
  /* custom classes */
  className?: string;
  /* disable */
  disabled?: boolean;
  /* click handler */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /* tab order */
  tabIndex?: string;
  /* button type */
  type?: 'button' | 'submit' | 'reset' | undefined;
  /* button variant - see styleguide */
  variant?: 'primary' | 'secondary' | undefined;
  /* if true, shows a spinner */
  loading?: boolean;
  fullWidth?: boolean;
  extraPadding?: boolean;
  href?: string;
  style?: React.CSSProperties;
  testID?: string; // used for tests
  icon?: React.ReactNode;
  title?: string;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
}

const baseClassName = 'relative rounded inline-flex items-center justify-center border';

const primaryButtonClasses = cx([
  'text-white dark:text-dark-base',
  'bg-light-control-primary dark:bg-dark-control-primary',
  'hover:bg-light-control-primary-hover dark:hover:bg-dark-control-primary-hover',
  'border-transparent',
  'focus:bg-light-control-primary-hover dark:focus:bg-dark-selected',
  'active:bg-light-control-primary-hover dark:active:bg-dark-selected',
  'focus:border-light-control-focus dark:focus:border-dark-control-focus',
]);

const secondaryButtonClasses = cx([
  'text-light-base',
  'dark:text-dark-base',
  'bg-light-control-secondary dark:bg-dark-control-secondary',
  'hover:bg-light-control-secondary-hover dark:hover:bg-dark-hover',
  'focus:bg-light-control-secondary-hover dark:focus:bg-dark-shade',
  'border-light-control dark:border-dark-control',
  'focus:border-light-control-hover dark:focus:border-dark-control-hover',
]);

const Button = ({
  children,
  className: classNameProp,
  disabled,
  onClick,
  type,
  large,
  tabIndex,
  loading,
  fullWidth,
  extraPadding,
  href,
  style,
  testID,
  icon,
  title,
  variant = 'primary',
  onFocus,
}: ButtonProps) => {
  const history = useHistory();

  const themeClassName = variant === 'primary' ? primaryButtonClasses : secondaryButtonClasses;

  const className = cx([
    baseClassName,
    themeClassName,
    large ? (extraPadding ? 'p-6' : 'p-4') : 'px-4 py-2',
    disabled ? 'opacity-25' : loading ? 'opacity-75' : '',
    disabled ? 'pointer-events-none select-none' : 'cursor-pointer',
    fullWidth && 'w-full',
    classNameProp,
  ]);

  const Element = href ? 'a' : 'button';

  return (
    <Element
      className={className}
      disabled={disabled}
      onClick={useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
          if (href) {
            event.preventDefault();
            if (disabled) return;
            history.push(href);
          }
          onClick?.(event);
        },
        [history, href, onClick, disabled],
      )}
      tabIndex={tabIndex ? parseInt(tabIndex) : undefined}
      type={type}
      href={href}
      style={style}
      data-testid={testID}
      title={title}
      onFocus={onFocus}
    >
      <span className={`flex items-center ${loading ? 'opacity-0' : ''}`}>
        {icon && <span className="mr-4">{icon}</span>}
        {children}
      </span>
      {loading && (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            display: 'flex',
          }}
        >
          <Spinner color={variant !== 'primary' ? 'tt-blue' : 'white'} size="sm" />
        </div>
      )}
    </Element>
  );
};

export default Button;
