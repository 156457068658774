import { TokensData } from '../../../contexts/TokensContext';

type Props = {
  tokenId?: string | null;
  address?: string | null;
  currency: string;
  tokensContext: TokensData;
};

export const getTokenData = ({ tokenId, address, currency, tokensContext }: Props) => {
  const { tokensBySymbol, tokensById } = tokensContext;
  const resultById = tokenId ? tokensById[tokenId] : null;
  if (resultById) return resultById;
  if (address) {
    // don't try to look up by symbol if token has an address
    return null;
  }
  const resultBySymbol = tokensBySymbol[currency];
  return resultBySymbol;
};
