import { gql, useMutation } from '@apollo/client';
import { PayInvoiceMutation, PayInvoiceMutationVariables } from '../../graphql/types';

const PAY_INVOICE = gql`
  mutation PayInvoice($invoiceId: String!, $stripeToken: String!, $method: InvoicePaymentMethod!) {
    payInvoice(invoiceId: $invoiceId, stripeToken: $stripeToken, method: $method)
  }
`;

export const usePayInvoice = () => {
  return useMutation<PayInvoiceMutation, PayInvoiceMutationVariables>(PAY_INVOICE);
};
