import React from 'react';

import Tooltip from '../../Tooltip';
import TaxLiabilityTooltipContent from './TaxLiabilityTooltipContent';
import { TaxLiabilityProps } from './types';

function TaxLiabilityTooltip(props: TaxLiabilityProps) {
  return (
    <Tooltip place="bottom" icon="info_outline" interactive>
      <TaxLiabilityTooltipContent {...props} />
    </Tooltip>
  );
}

export default React.memo(TaxLiabilityTooltip);
