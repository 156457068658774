import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  LatestMissingPricesJobQuery,
  MissingPricesDataQuery,
  UnbusyCredentialsQuery,
} from '../../../graphql/types';

export const MISSING_PRICES_DATA = gql`
  query MissingPricesData {
    missingPricesData {
      type
      count
    }
  }
`;

export const useMissingPricesData = () => {
  return useQuery<MissingPricesDataQuery>(MISSING_PRICES_DATA);
};

const LATEST_MISSING_PRICES_JOB = gql`
  query LatestMissingPricesJob {
    latestMissingPricesJob {
      id
      status
      createdAt
      modifiedTxnDetails {
        token
        count
      }
      modifiedTxnsLength
    }
  }
`;

export const useLatestMissingPricesJob = (options: QueryHookOptions<LatestMissingPricesJobQuery>) => {
  return useQuery<LatestMissingPricesJobQuery>(LATEST_MISSING_PRICES_JOB, options);
};

const UNBUSY_CREDENTIALS = gql`
  query UnbusyCredentials {
    unbusyCredentials {
      id
    }
  }
`;

export const useUnbusyCredentials = () => {
  return useQuery<UnbusyCredentialsQuery>(UNBUSY_CREDENTIALS);
};
