import cx from 'classnames';
import React, { MouseEvent } from 'react';
import ChainExplorerLink from '../ChainExplorerLink';
import Icon from '../Icon';
import Filter from './Filter';
import Copyable from './index';
import { CopyableValueFilter } from './types';

export type BaseProps = {
  children: React.ReactNode;
  value: string;
  isTxn?: boolean;
  credentialType?: string | null;
  exchangeName?: string;
  onFilter?: () => void;
  hideActions?: boolean;
  hideChainExplorer?: boolean;
  specIdSellId?: string | null;
  wrappable?: boolean;
  className?: string;
};

type Props = BaseProps & CopyableValueFilter;

export default function CopyableExplorerLink({
  children,
  value,
  isTxn = false,
  credentialType,
  exchangeName,
  onFilter,
  hideActions,
  hideChainExplorer = false,
  wrappable = false,
  className,
  ...props
}: Props) {
  const content = (
    <>
      <Copyable value={value} disabled={hideActions}>
        {children}
      </Copyable>
      <span className={cx('row_visible_if_hovered', hideActions && 'hidden', 'inline')}>
        <Filter {...props} onFilter={onFilter} />

        {!hideChainExplorer && (
          <ChainExplorerLink
            {...{
              credentialType,
              exchangeName,
              isTxn,
              hash: value,
              className: 'px-1',
              title: 'See address on block explorer',
              onClick: (evt: MouseEvent) => {
                evt.stopPropagation();
              },
            }}
          >
            <Icon type="goto" className="cursor-pointer inline-block" />
          </ChainExplorerLink>
        )}
      </span>
    </>
  );

  if (wrappable) {
    return (
      <div className={className} style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
        {content}
      </div>
    );
  }

  return content;
}
