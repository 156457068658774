import React from 'react';
import Button from '../Button';
import Text from '../Text';
import { TaxGuideStepType } from './types';

export function TaxGuideStep({
  title,
  description,
  completed,
  completionMessage,
  ctaText,
  ctaAction,
  index,
  isNextToComplete,
  loading,
}: TaxGuideStepType & { index: number; isNextToComplete?: boolean }) {
  return (
    <div className="flex gap-2 border-b border-light-base dark:border-dark-base last:border-none p-6">
      <div className="relative pt-[6px]">
        {completed ? (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" className="fill-light-alert-success" />
            <path d="M4 8.5L6.5 11L12.5 5" stroke="#FCFCFC" strokeWidth="1.5" />
          </svg>
        ) : (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x="0.75"
              y="0.75"
              width="14.5"
              height="14.5"
              rx="7.25"
              stroke="#171717"
              strokeOpacity="0.15"
              strokeWidth="1.5"
            />
          </svg>
        )}
        {isNextToComplete && (
          <div className="absolute top-[8px] left-[-26px]">
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 1C0 0.447715 0.447715 0 1 0H6.58579C6.851 0 7.10536 0.105357 7.29289 0.292893L12.2929 5.29289C12.6834 5.68342 12.6834 6.31658 12.2929 6.70711L7.29289 11.7071C7.10536 11.8946 6.851 12 6.58579 12H1C0.447715 12 0 11.5523 0 11V1Z"
                className="fill-light-brand dark:fill-dark-brand-alt"
              />
            </svg>
          </div>
        )}
      </div>
      <div className="flex flex-wrap justify-between gap-4 w-full items-center">
        <div className="flex flex-col gap-2 pl-4">
          <h2 className="text-2xl text-light-brand dark:text-dark-brand-alt">
            {index + 1}. {title}
          </h2>
          <Text variant="muted">{description}</Text>
        </div>
        {completed ? (
          <div className="ml-auto">
            <Text variant="muted">{completionMessage}</Text>
          </div>
        ) : (
          <div className="ml-auto">
            <Button variant="primary" onClick={ctaAction} loading={loading} disabled={loading}>
              {ctaText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
