import { TREATMENTS, TransferType } from './UnreconciledTransactions/store/types';

type SelfReconOverviewArgs = {
  missingWalletCount: number;
  missingExchangeCount: number;
  unreconciledTxnsCount: number;
};

export const trackSelfReconOverview = ({
  missingExchangeCount,
  missingWalletCount,
  unreconciledTxnsCount,
}: SelfReconOverviewArgs) => {
  window.analytics.track('Self Recon Overview', {
    missingWalletCount,
    missingExchangeCount,
    unreconciledTxnsCount,
  });
};

type UnreconciledTxArgs = {
  txHash: string;
  chain: string;
  import: number;
  suggestedTreatment: TREATMENTS | null;
};

export const trackUnreconciledTransactionViewed = (args: UnreconciledTxArgs) => {
  window.analytics.track('UnreconciledTx', {
    ...args,
  });
};

export const trackTokensLoadingTime = (milliseconds: number) => {
  // consider it 0 if it's less than 10ms
  const duration = milliseconds < 10 ? 0 : milliseconds;
  window.analytics.track('UnreconciledTx TokensLoaded (ms)', {
    duration,
  });
};

export const trackUnreconciledTxTypeSelected = (type: string) => {
  window.analytics.track('UnreconciledTx TypeSelected', {
    type,
  });
};

export const trackUnreconciledTxReconciled = (type: string) => {
  window.analytics.track('UnreconciledTx Reconciled', {
    typeSaved: type,
  });
};

export const trackUnreconciledTxSkipped = () => {
  window.analytics.track('UnreconciledTx Skipped');
};

type UnreconciledDraftTypeEditedArgs = {
  originalType: TransferType | null;
  updatedType: TransferType;
};

export const trackUnreconciledDraftTypeEdited = (args: UnreconciledDraftTypeEditedArgs) => {
  window.analytics.track('UnreconciledDraft TypeEdited', {
    ...args,
  });
};

type UnreconciledDraftTransferEditedArgs = {
  symbolEdited: boolean;
  quantityEdited: boolean;
};

export const trackUnreconciledDraftTransferEdited = (args: UnreconciledDraftTransferEditedArgs) => {
  window.analytics.track('UnreconciledDraft TransferEdited', {
    ...args,
  });
};
