import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useEvent } from 'react-use';
import Input, { InputHandle } from '../Input';
import Text from '../Text';

interface Props<ValueType> {
  options: {
    label: string;
    value: ValueType;
    hiddenByDefault?: boolean; // If true, option will be hidden unless searched for
  }[];
  onSelectOption: (value: ValueType) => void;
  withSearch?: boolean;
}

export default function FilterableList<ValueType extends string | boolean>({
  options,
  onSelectOption,
  withSearch = true,
}: Props<ValueType>) {
  const [searchTerm, setSearchTerm] = useState('');
  const [focusIndex, setFocusIndex] = useState<number>(0);
  const inputRef = useRef<InputHandle>(null);

  const filteredResults = useMemo(() => {
    if (!searchTerm) return options.filter((option) => !option.hiddenByDefault);
    return options.filter((option) => {
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [options, searchTerm]);

  useEvent('keydown', (event) => {
    if (event.key === 'ArrowDown') {
      if (focusIndex < filteredResults.length - 1) {
        setFocusIndex(focusIndex + 1);
      } else {
        setFocusIndex(0);
      }
    }
    if (event.key === 'ArrowUp') {
      if (focusIndex === 0) {
        setFocusIndex(filteredResults.length - 1);
      } else {
        setFocusIndex(focusIndex - 1);
      }
    }
    if (event.key === 'Enter' && filteredResults.length > 0) {
      onSelectOption(filteredResults[focusIndex].value);
    }
  });

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className="flex flex-col">
      {withSearch && (
        <div className="border-b border-light-control dark:border-dark-control overflow-hidden">
          <Input
            className="py-2 px-3 border-none rounded-t"
            ref={inputRef}
            autoFocus
            type="text"
            placeholder="Filter..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}
      {!filteredResults.length && (
        <div className="text-sm py-2 px-3 text-center">
          <Text variant="muted">No results found</Text>
        </div>
      )}
      {filteredResults.map((option, index) => {
        return (
          <button
            className={classNames(
              'py-2 px-3 text-left hover:bg-light-hover dark:hover:bg-dark-hover last:rounded-b',
              {
                'bg-light-selected hover:bg-light-selected dark:bg-dark-selected hover:dark:bg-dark-selected':
                  index === focusIndex,
                'first:rounded-t': !withSearch,
              },
            )}
            key={index}
            onClick={() => onSelectOption(option.value)}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
}
