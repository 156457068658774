import React from 'react';

import { useTableContext } from '../context';
import { TableBodyProps } from './types';
import ControlledBody from './ControlledBody';
import StaticBody from './StaticBody';

export * from './types';

function Body<T extends Record<string, unknown>>({
  data,
  onRowClicked,
  isRowClickable,
  scrolling = true,
}: TableBodyProps<T>): React.ReactElement {
  const { tableType } = useTableContext<T>();

  return (
    <>
      {tableType === 'static' && (
        <StaticBody<T>
          {...{
            data,
            onRowClicked,
            isRowClickable,
            scrolling,
          }}
        />
      )}
      {tableType === 'controlled' && (
        <ControlledBody<T>
          {...{
            data,
            onRowClicked,
            isRowClickable,
            scrolling,
          }}
        />
      )}
    </>
  );
}

export default React.memo(Body) as unknown as typeof Body;
