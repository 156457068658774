import React from 'react';
import { constant, noop } from 'lodash';
import { PaymentSourcesQuery } from '../graphql/types';

export type CardHolderName = { firstName: string; lastName: string };

interface PaymentContextType {
  sources: PaymentSourcesQuery['paymentSources'];
  refetchSources: () => Promise<unknown>;
  selectedSourceId?: string;
  setSelectedSourceId: (sourceId?: string) => void;
  formIsValid: boolean;
  setFormIsValid: (isValid: boolean) => void;
  nameData: CardHolderName;
  setNameData: (data: CardHolderName) => void;
}

export const PaymentContext = React.createContext<PaymentContextType>({
  sources: [],
  refetchSources: constant(new Promise(noop)),
  selectedSourceId: undefined,
  setSelectedSourceId: noop,
  formIsValid: false,
  setFormIsValid: noop,
  nameData: { firstName: '', lastName: '' },
  setNameData: noop,
});
PaymentContext.displayName = 'PaymentContext';
