import React from 'react';
import { Link } from 'react-router-dom';

import TrustBox from '../../TrustPilot';
import Button from '../../Button';

const PurchaseModal = ({ onClose }: { onClose?: () => void }) => {
  const handleClick = () => {
    onClose && onClose();
  };
  return (
    <div className="text-center px-8 pb-12 py-20">
      <div className="mb-12">
        <h1 className="text-4xl max-w-md mx-auto leading-tight mb-6 mt-0">
          Have TokenTax file your full tax return
        </h1>
        <p className="max-w-sm mx-auto text-lg mb-6">
          Join thousands of people who have successfully filed their crypto taxes with us.
        </p>

        <TrustBox />
      </div>
      <Link to="/purchase/">
        <Button onClick={handleClick}>
          <span className="text-lg">See Plans</span>
        </Button>
      </Link>
    </div>
  );
};

PurchaseModal.displayName = 'PurchaseModal';

export default PurchaseModal;
