/**
 * VipUpsellBanner
 *
 * This component is used to display an upsell banner on specific pages [1]
 * The banner includes a link to our VIP plan marketing page OR a link to a Typeform for a free consultation.
 *
 * Do not show component if any of the following are true:
 * - User is an admin
 * - User has already dismissed the banner (on any page!)
 * - User does not yet have a paid plan
 * - User already has a current-year or prior-year VIP/Enterprise plan
 *
 * [1] Pages where the banner is displayed:
 * - All Transactions
 * - Tax Dashboard
 * - Tax Loss Harvesting Dashboard
 */

import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { vipMarketingUrl } from '../../constants/marketingPage';
import { User, UserContext } from '../../contexts/UserContext';
import Auth from '../../lib/Auth';
import {
  TrackVipUpsellBannerArgs,
  trackVipUpsellBannerClicked,
  trackVipUpsellBannerDismissed,
  trackVipUpsellBannerLoaded,
} from '../../lib/analytics';
import { useExperiment } from '../../lib/hooks';
import TextLink, { textLinkVariantClassnames } from '../TextLink';
import { toastBackgroundClasses } from '../Toast';

/**
 * Increment this key and change groups when we want to run a new experiment
 * Current experiment: 1 (marketingPageLink vs. directSurveyLink)
 *
 * Start of experiment data
 */
const KEY = 'vipBannerGroupTest1';
const experimentGroups = ['marketingPageLink', 'directSurveyLink'];
const consultationTypeformBaseUrl = 'https://form.typeform.com/to/BLQJ7U8L#source=vip-banner&property=app';

interface CTAProps {
  experiment: string;
  url: string;
  preLinkText?: string;
  linkText: string;
  postLinkText?: string;
}

const experimentData: Record<string, CTAProps> = {
  marketingPageLink: {
    experiment: 'marketingPageLink',
    url: vipMarketingUrl,
    preLinkText: 'Let TokenTax do the work for you. Learn more about our ',
    linkText: 'VIP plan.',
  },
  directSurveyLink: {
    experiment: 'directSurveyLink',
    url: consultationTypeformBaseUrl,
    preLinkText: 'Let TokenTax do the work for you. Schedule a free VIP ',
    linkText: 'consultation',
    postLinkText: ' today.',
  },
};
/** End of experiment data */

const handleUrlClick = (args: TrackVipUpsellBannerArgs) => {
  trackVipUpsellBannerClicked(args);
};

const VipUpsellMessage = ({ experiment, url, preLinkText, linkText, postLinkText }: CTAProps) => {
  return (
    <div>
      {preLinkText}
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={classNames(textLinkVariantClassnames.primary)}
        onClick={() => handleUrlClick({ experimentVersion: KEY, experimentGroup: experiment, url })}
        onAuxClick={() => handleUrlClick({ experimentVersion: KEY, experimentGroup: experiment, url })}
      >
        {linkText}
      </a>
      {postLinkText}
    </div>
  );
};

const shouldNotSeeBanner = (user: User | undefined, experimentGroup: string, hidden: boolean) => {
  if (!user) return true;

  const currentYear = new Date().getFullYear();
  const hasRecentVipOrEnterprisePlan = user?.taxDetails?.some(
    ({ taxYear, plan }) =>
      (taxYear === currentYear || taxYear === currentYear - 1 || taxYear === currentYear + 1) &&
      (plan === 'VIP' || plan === 'ENTERPRISE'),
  );

  return (
    !experimentGroup ||
    hidden ||
    localStorage.getItem('vipUpsellBannerDismissed') ||
    !user?.taxDetails?.length ||
    Auth.tokenIsForAdmin() ||
    hasRecentVipOrEnterprisePlan
  );
};

export function VipUpsellBanner() {
  const { user } = useContext(UserContext) ?? {};
  const [hidden, setHidden] = useState(false);
  const experimentGroup = useExperiment(KEY, experimentGroups);

  const consultationTypeformUrl = `${consultationTypeformBaseUrl}&email=${user?.email}`;
  experimentData.directSurveyLink.url = consultationTypeformUrl;

  // We're wrapping this analytics call in a useEffect so that it only fires once
  useEffect(() => {
    if (!shouldNotSeeBanner(user, experimentGroup, hidden)) {
      trackVipUpsellBannerLoaded({ experimentVersion: KEY, experimentGroup: experimentGroup });
    }
  }, [user, experimentGroup, hidden]);

  const handleHide = (args: TrackVipUpsellBannerArgs) => {
    localStorage.setItem('vipUpsellBannerDismissed', 'true');
    setHidden(true);
    trackVipUpsellBannerDismissed(args);
  };

  if (shouldNotSeeBanner(user, experimentGroup, hidden)) {
    return null;
  }

  return (
    <div className={classNames('flex p-4 flex-wrap text-sm', toastBackgroundClasses.warning)}>
      <>
        <span>
          <VipUpsellMessage {...experimentData[experimentGroup]} />
        </span>
        <TextLink
          className="ml-auto"
          onClick={() => handleHide({ experimentVersion: KEY, experimentGroup: experimentGroup })}
        >
          Dismiss
        </TextLink>
      </>
    </div>
  );
}
