/**
 * Module syntax required to be read by tailwind.config.js
 * Refer to the design system Figma file
 * https://www.figma.com/file/IsoYtCgTJgihhqcEiU9keJ/Design-Library?node-id=758%3A29716&t=h4nxLxK1Y42XzJ7X-1
 */

const colors = {
  primary: {
    light: {
      1: '#F8F8FC',
      2: '#F6F6FE',
      3: '#EAEAFE',
      4: '#DFDFFA',
      5: '#D2D2F9',
      6: '#BFBFF9',
      7: undefined,
      8: undefined,
      9: '#5D5CF0',
      10: undefined,
      11: '#5251EF',
      12: '#4948EE',
    },
    dark: {
      1: '#131620',
      2: '#15192D',
      3: '#192140',
      4: '#1C274F',
      5: '#1F2C5C',
      6: '#22346E',
      7: '#273E89',
      8: '#2F4EB2',
      9: '#3E63DD',
      10: '#5373E7',
      11: '#849DFF',
      12: '#EEF1FD',
    },
  },
  grey: {
    light: {
      1: '#FCFCFC',
      2: '#F8F8F8',
      3: '#F3F3F3',
      4: '#EDEDED',
      5: '#E8E8E8',
      6: '#E2E2E2',
      7: '#DBDBDB',
      8: '#C7C7C7',
      9: '#8F8F8F',
      10: '#858585',
      11: '#6F6F6F',
      12: '#171717',
    },
    dark: {
      1: '#161616',
      2: '#1C1C1C',
      3: '#232323',
      4: '#282828',
      5: '#2E2E2E',
      6: '#343434',
      7: '#3E3E3E',
      8: '#505050',
      9: '#707070',
      10: '#7E7E7E',
      11: '#A0A0A0',
      12: '#EDEDED',
    },
  },
  green: {
    light: {
      1: '#FBFEFB',
      2: '#F3FCF3',
      3: '#EBF9EB',
      4: '#DFF3DF',
      5: '#CEEBCF',
      6: '#B7DFBA',
      7: '#97CF9C',
      8: '#65BA75',
      9: '#46A758',
      10: '#3D9A50',
      11: '#297C3B',
      12: '#1B311E',
    },
    dark: {
      1: '#0D1912',
      2: '#0F1E13',
      3: '#132819',
      4: '#16301D',
      5: '#193921',
      6: '#1D4427',
      7: '#245530',
      8: '#2F6E3B',
      9: '#46A758',
      10: '#55B467',
      11: '#63C174',
      12: '#E5FBEB',
    },
  },
  yellow: {
    light: {
      1: '#FDFDF9',
      2: '#FFFCE8',
      3: '#FFFBD1',
      4: '#FFF8BB',
      5: '#FEF2A4',
      6: '#F9E68C',
      7: '#EFD36C',
      8: '#EBBC00',
      9: '#F5D90A',
      10: '#F7CE00',
      11: '#946800',
      12: '#35290F',
    },
    dark: {
      1: '#1C1501',
      2: '#221A02',
      3: '#2C2104',
      4: '#352806',
      5: '#3E3008',
      6: '#493C0A',
      7: '#594A0F',
      8: '#755E1C',
      9: '#F4D833',
      10: '#FAEE68',
      11: '#EFBF2C',
      12: '#FBFAD3',
    },
  },
  red: {
    light: {
      1: '#C7C7C7',
      2: '#FFF8F8',
      3: '#FFEFEF',
      4: '#FFE5E5',
      5: '#FDD8D8',
      6: '#F9C6C6',
      7: '#F3AEAF',
      8: '#EB9091',
      9: '#E5484D',
      10: '#DC3D43',
      11: '#CD2B31',
      12: '#381316',
    },
    dark: {
      1: '#505050',
      2: '#291415',
      3: '#3C181A',
      4: '#481A1D',
      5: '#541B1F',
      6: '#671E22',
      7: '#822025',
      8: '#AA2429',
      9: '#E5484D',
      10: '#F2555A',
      11: '#FF6369',
      12: '#FEECEE',
    },
  },
  overlay: {
    light: {
      1: 'rgba(0, 0, 0, 0.01)',
      2: 'rgba(0, 0, 0, 0.027)',
      3: 'rgba(0, 0, 0, 0.047)',
      4: 'rgba(0, 0, 0, 0.07)',
      5: 'rgba(0, 0, 0, 0.09)',
      6: 'rgba(0, 0, 0, 0.114)',
      7: 'rgba(0, 0, 0, 0.14)',
      8: 'rgba(0, 0, 0, 0.22)',
      9: 'rgba(0, 0, 0, 0.439)',
      10: 'rgba(0, 0, 0, 0.478)',
      11: 'rgba(0, 0, 0, 0.565)',
      12: 'rgba(0, 0, 0, 0.91)',
    },
    dark: {
      1: 'rgba(255, 255, 255, 0.012)',
      2: 'rgba(255, 255, 255, 0.027)',
      3: 'rgba(255, 255, 255, 0.047)',
      4: 'rgba(255, 255, 255, 0.071)',
      5: 'rgba(255, 255, 255, 0.09)',
      6: 'rgba(255, 255, 255, 0.114)',
      7: 'rgba(255, 255, 255, 0.14)',
      8: 'rgba(255, 255, 255, 0.439)',
      9: 'rgba(255, 255, 255, 0.478)',
      10: 'rgba(255, 255, 255, 0.565)',
      11: 'rgba(255, 255, 255, 0.91)',
      12: 'rgba(255, 255, 255, 1)',
    },
  },
};

/**
 * Map of colors to use arbitrarily in the app
 * {
 *  'color-shade-level': '#hex',
 * }
 * <div className="grey-light-12" />
 */
const classMap = Object.keys(colors).reduce((acc, color) => {
  Object.keys(colors[color]).forEach((shade) => {
    Object.keys(colors[color][shade]).forEach((level) => {
      acc[`${color}-${shade}-${level}`] = colors[color][shade][level];
    });
  });
  return acc;
}, {});

module.exports = { colors, classMap };
