import { gql, QueryHookOptions } from '@apollo/client';
import { LatestTxnReportJobQuery, NeedsRecalcQuery } from '../../graphql/types';
import { useQueryAndRefetchOnUserChange } from '../../lib/hooks';

const NEEDS_RECALC = gql`
  query NeedsRecalc {
    needsRecalc
  }
`;

export const useNeedsRecalc = (options?: QueryHookOptions<NeedsRecalcQuery>) =>
  useQueryAndRefetchOnUserChange<NeedsRecalcQuery>(NEEDS_RECALC, {
    ...options,
    notifyOnNetworkStatusChange: true,
  });

const LATEST_TXN_REPORT_JOB = gql`
  query LatestTxnReportJob {
    latestTxnReportJob {
      createdAt
      error
      errorMessage
      finishedAt
      jobStatus
    }
  }
`;

export const useLatestTxnReportJob = (options: QueryHookOptions<LatestTxnReportJobQuery>) =>
  useQueryAndRefetchOnUserChange<LatestTxnReportJobQuery>(LATEST_TXN_REPORT_JOB, {
    ...options,
    fetchPolicy: 'network-only',
    pollInterval: options.pollInterval, // for clarity, see comment below
    notifyOnNetworkStatusChange: true, // https://github.com/apollographql/apollo-client/issues/5531#issuecomment-568235629
    context: { batched: true },
  });
