import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { IbcGuideInstructionQuery, IbcGuideInstructionQueryVariables } from '../types';

const IBC_GUIDE_INSTRUCTION = gql`
  query IbcGuideInstruction($identifier: String!) {
    ibcGuideInstruction(identifier: $identifier) {
      id
      identifier
      treatmentId
      treatment {
        id
        name
        description
        reconOrder
      }
      notes
      publicName
    }
  }
`;

export function useGetIbcGuideInstruction(
  options?: QueryHookOptions<IbcGuideInstructionQuery, IbcGuideInstructionQueryVariables>,
) {
  return useQuery(IBC_GUIDE_INSTRUCTION, options);
}
