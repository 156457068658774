import { gql } from '@apollo/client';
import { client } from '../../lib/apollo';

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const logout = async () => {
  await client.mutate({
    mutation: LOGOUT,
    context: {
      credentials: 'include',
    },
  });
};
