import { gql } from '@apollo/client';

export const INVOICE = gql`
  fragment InvoiceFragment on Invoice {
    id
    userId
    number
    created
    dueDate
    currency
    paid
    voided
    amountRemaining
    description
    createdBy
  }
`;
