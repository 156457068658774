import cx from 'classnames';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { TokensContext } from '../../contexts/TokensContext';
import { usePrevious } from '../../hooks/usePrevious';
import { getDefaultTokenIdForSymbol } from '../EditTxnPanel/Form/helpers';
import Input, { InputHandle } from '../Input';
import { textLinkVariantClassnames } from '../TextLink';
import { useClickOutside } from '../hooks';
import SelectedToken from './SelectedToken';
import TokenSearch, { TokenSearchHandle } from './TokenSearch';

interface Props {
  symbol: string;
  address?: string | null;
  tokenId: string | null | undefined;
  onSymbolChange: (value: string) => void;
  onTokenIdChange: (value: string) => void;
  placeholder?: string;
  linkingEnabled?: boolean;
  id?: string;
}

function TokenSelector({
  symbol,
  tokenId,
  address,
  onSymbolChange,
  onTokenIdChange,
  placeholder,
  linkingEnabled = true,
  id,
}: Props) {
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<InputHandle>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropDownIsVisible, setDropDownIsVisible] = useState(false);
  const tokensContext = useContext(TokensContext);
  const defaultTokenIdForSymbol = getDefaultTokenIdForSymbol(symbol, tokensContext);
  const priceLookupRef = useRef<HTMLDivElement>(null);

  // initially set to null, let effect set its value
  const [selectedTokenId, setSelectedTokenId] = useState<string | null | undefined>(null);
  useEffect(
    () =>
      setSelectedTokenId(() => {
        if (tokenId === undefined) {
          // if tokenId is undefined, set selectedTokenId to undefined as it means the underlying transactions have different tokenId values
          return undefined;
        }

        if (tokenId) return tokenId;
        if (!address) {
          // we'll offer to fetch the default token with that symbol, and make it look like it's linked
          // but ONLY if the token in the underlying txn has no associated address
          // as the API will not fetch a price by symbol when the token has an address
          // this is in order to avoid fetching the wrong price for
          // e.g. a coin with the "BTC" ticker on Solana
          // see https://linear.app/tokentax/issue/TOK-3230/memecoin-price-lookup
          return defaultTokenIdForSymbol;
        }

        return null;
      }),
    [tokenId, defaultTokenIdForSymbol, address, dropDownIsVisible],
  );

  const searchRef = useRef<TokenSearchHandle>(null);

  const {
    styles,
    attributes,
    update: updatePopper,
  } = usePopper(inputWrapperRef.current, dropdownRef.current, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 9],
        },
      },
    ],
  });

  const wasVisible = usePrevious(dropDownIsVisible);

  useLayoutEffect(() => {
    if (dropDownIsVisible && !wasVisible && !selectedTokenId) {
      searchRef.current?.focus();
    }
  }, [dropDownIsVisible, wasVisible, selectedTokenId]);

  useEffect(() => {
    updatePopper?.();
  }, [updatePopper, symbol]);

  useClickOutside(dropdownRef, (event) => {
    if (event.target === priceLookupRef.current) {
      // don't interfere with the "price lookup" div which has its own toggle logic
      return;
    }
    setDropDownIsVisible(false);
  });

  if (symbol === undefined) {
    return null;
  }

  return (
    <div className="relative">
      <div ref={inputWrapperRef}>
        <Input
          id={id}
          value={symbol}
          ref={inputRef}
          padding="pl-4"
          style={{ lineHeight: '49px' }}
          onChange={(event) => onSymbolChange(event.target.value)}
          placeholder={placeholder}
          renderSideElement={() => {
            if (!symbol || !linkingEnabled) return null;
            return (
              <div
                ref={priceLookupRef}
                data-testid="price-lookup"
                className={cx(
                  'cursor-pointer mt-4 mr-4 pl-1 select-none bg-light-base dark:bg-dark-base',
                  textLinkVariantClassnames.primary,
                )}
                onClick={() => {
                  setDropDownIsVisible(!dropDownIsVisible);
                }}
                style={{
                  lineHeight: '49px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0',
                }}
              >
                Price lookup
                <span className="material-icons">arrow_drop_{dropDownIsVisible ? 'up' : 'down'}</span>
              </div>
            );
          }}
        />
      </div>
      <div
        ref={dropdownRef}
        className={cx(
          'bg-light-base dark:bg-dark-base border border-light-control dark:border-dark-control border-r rounded z-9999 p-3 w-full drop-shadow',
          {
            invisible: !dropDownIsVisible,
          },
        )}
        style={styles.popper}
        onClick={(event) => {
          event.stopPropagation();
        }}
        data-testid={`TokenSelector-dropdown-${dropDownIsVisible ? 'visible' : 'invisible'}`}
        {...attributes.popper}
      >
        {selectedTokenId === undefined ? (
          <div>
            <div>{symbol} is linked to different coins for these transactions</div>
            <div
              className={cx('cursor-pointer mt-2', textLinkVariantClassnames.primary)}
              onClick={() => setSelectedTokenId('')}
              data-testid="TokenSelector-selectNewCoin"
            >
              Link a new coin for all transactions
            </div>
          </div>
        ) : [null, ''].includes(selectedTokenId) ? (
          <>
            {'Link a token to CoinGecko for price lookups'}
            <TokenSearch
              initialSymbolValue={symbol}
              showLoupe
              showClearSearch
              focusOnMount
              inputClassNames="pr-28 pl-8 py-2"
              wrapperClassNames="mt-3"
              resultsClassNames="mt-3"
              onTokenSelected={(token) => {
                // onTokenIdChange would be sufficient for 99% of cases
                // but if the user unlinks a token (an operation which sets selectedTokenId to '')
                // and then re-selects the previously-linked token, selectedTokenId would not be updated
                // by the useEffect hook() as the dependency array would have the same values.
                // by setting selectedTokenId manually we're guaranteed that the UI is updated in this case
                setSelectedTokenId(token.id);
                onTokenIdChange(token.id);
              }}
              searchRef={searchRef}
            />
          </>
        ) : (
          <>
            {`Using the coin below for price lookups on CoinGecko`}
            <SelectedToken
              tokenId={selectedTokenId!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
              onUnlink={() => setSelectedTokenId('')}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(TokenSelector);
