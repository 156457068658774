import { gql, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import {
  GetLpTokensQuery,
  SortDirectionOptions,
  UnreconciledTransactionsQuery,
  UnreconciledTransactionsQueryVariables,
  UnreconciledTransactionsSortOptions,
} from '../../../graphql/types';
import { dispatchNetworkError } from '../../../lib/apollo/clientEvents';
import { HIJACKED_USER_ID_QUERY_ARG } from '../../../lib/Auth';
import { HIJACKED_USER_ID_HEADER_NAME } from '../../../lib/constants';
import { PaginationState, getPaginationOptions } from '../../utils/pagination';
import { FiltersState } from './Table/types';

const UNRECONCILED_TXNS_URI = `${process.env.GQL_URI}/user/unreconciledTransactions`;

const UNRECONCILED_TRANSACTIONS = gql`
  query UnreconciledTransactions(
    $pagination: PaginationOptions
    $sortBy: UnreconciledTransactionsSortOptions!
    $sortDirection: SortDirectionOptions!
    $filterQuery: UnreconciledTxnsFiltersQuery
    $hashes: [String!]
  ) {
    unreconciledTransactions(
      unreconciledTransactionsTableInput: {
        pagination: $pagination
        sortBy: $sortBy
        sortDirection: $sortDirection
        filterQuery: $filterQuery
      }
      hashes: $hashes
    ) {
      edges {
        id
        hash
        sentTxnsCount
        receivedTxnsCount
        credentials {
          id
          source
          type
          name
          address
        }
        timestamp
        editedAt
        from
        to
        contractPublicName
        method {
          id # could make this fetched only for admins
          hash
          name
        }
        instruction {
          ... on EvmInstruction {
            id
            contractId # could make this fetched only for admins
            notes
            proba1
            pred1
            publicNotes
            tokenParam1
            tokenParam2
            treatment {
              id
              description
            }
          }
          ... on IdentifierBasedInstruction {
            id
            notes
            publicNotes
            tokenParam1
            tokenParam2
            treatment {
              id
              description
            }
          }
        }
        resolved
        invalidated
        resolutionCategory
        resolutionId
        ecosystem
        reconIdentifier
      }
      pageInfo {
        filteredCount
      }
    }
  }
`;

type UnreconciledTransactionsQueryProps = {
  sortState: {
    sortBy: UnreconciledTransactionsSortOptions;
    sortDir: SortDirectionOptions;
  };
  filtersState?: FiltersState;
  paginationState?: PaginationState;
  hashes?: string[];
};

export const useUnreconciledTransactions = ({
  sortState,
  filtersState,
  paginationState,
  hashes,
}: UnreconciledTransactionsQueryProps) => {
  return useQuery<UnreconciledTransactionsQuery, UnreconciledTransactionsQueryVariables>(
    UNRECONCILED_TRANSACTIONS,
    {
      variables: {
        pagination: paginationState && getPaginationOptions(paginationState),
        sortBy: sortState.sortBy,
        sortDirection: sortState.sortDir,
        filterQuery: JSON.parse(filtersState?.filterQuery || '{}'),
        hashes,
      },
      fetchPolicy: 'cache-and-network',
    },
  );
};

// Looks like GraphQL but is actually not!
// This is done to avoid the overhead of GraphQL for this specific query,
// which can cause memory issues when fetching a large number of transactions.
export const useAllUnreconciledTransactions = () => {
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<UnreconciledTransactionsQuery | undefined>(undefined);

  const searchParams = new URLSearchParams(window.location.search);
  const hijackedUserIdString = searchParams.get(HIJACKED_USER_ID_QUERY_ARG);

  const fetchData = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    try {
      const queryParams = new URLSearchParams({
        filterQuery: JSON.stringify({
          status: { operator: 'Is', values: ['unreconciled'] },
        }),
        sortBy: UnreconciledTransactionsSortOptions.priority,
        sortDirection: SortDirectionOptions.asc,
      });

      const response = await fetch(`${UNRECONCILED_TXNS_URI}?${queryParams.toString()}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...(hijackedUserIdString ? { [HIJACKED_USER_ID_HEADER_NAME]: hijackedUserIdString } : {}),
        },
      });

      const unreconciledTransactions = await response.json();

      setData({ unreconciledTransactions });
    } catch (error) {
      dispatchNetworkError('Unreconciled transactions could not be fetched');
      setData(undefined);
    } finally {
      setLoading(false);
    }
  }, [hijackedUserIdString, loading]);

  useEffect(() => {
    fetchData();
    // don't add fetchData to deps: it sets loading to true and then false
    // thereby changing its own value (as loading is in its deps array)
    // if fetchData is in the deps array, this would cause an infinite loop
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    loading: loading ?? true,
    refetch: fetchData,
  } as const;
};

const GET_LP_TOKENS = gql`
  query GetLpTokens {
    getLpTokens {
      address
      ticker
    }
  }
`;

export const useLpTokens = () => useQuery<GetLpTokensQuery>(GET_LP_TOKENS);
