import React from 'react';
import { DECIMAL_PRECISION } from '../../../constants/numbers';
import { NumberInput } from '../../Input';
import Tooltip from '../../Tooltip';
import FetchPrice from './FetchPrice';

interface Props {
  /* id for formik */
  id: `${'buy' | 'sell' | 'fee'}Price`;
  /* cb for input onBlur */
  onBlur: (currentValue: string) => void;
  /* cb for input onChange */
  onChange: (value: string) => void;
  /* tab order*/
  tabIndex?: string;
  /* value of controlled input */
  value?: string;
  onFetchPrice: () => Promise<void>;
  placeholder?: string;
  fetchEnabled?: boolean;
  isSideAgnostic?: boolean;
}

const PriceInput = ({
  id,
  onBlur,
  onChange,
  tabIndex,
  value = '',
  placeholder,
  onFetchPrice,
  fetchEnabled,
  isSideAgnostic,
}: Props) => {
  return (
    <>
      <NumberInput
        id={id}
        prefix="$"
        decimalScale={DECIMAL_PRECISION} // https://s-yadav.github.io/react-number-format/docs/numeric_format#decimalscale-number
        allowNegative={false}
        onBlur={onBlur}
        onChange={onChange}
        tabIndex={tabIndex}
        {...{ value, placeholder }}
      />
      {fetchEnabled && (
        <div className="pt-2 flex items-center">
          <FetchPrice type={isSideAgnostic ? 'noPresetSidePrice' : id} onFetchPrice={onFetchPrice} />
          {isSideAgnostic && (
            <Tooltip icon="info_outline" iconClassNames="align-bottom">
              We will try to fetch the price for each transaction using the sell or buy side, depending on the
              type and currencies of each transaction.
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(PriceInput);
