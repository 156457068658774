import React, { useState, useRef, useCallback, useLayoutEffect } from 'react';
import { capitalize, get } from 'lodash';
import cx from 'classnames';
import { usePopper } from 'react-popper';

import { useClickOutside } from '../../hooks';
import { Cell } from '../types';
import classNames from 'classnames';

interface DropdownCellProps<T extends Record<string, unknown>, Prop extends keyof T> {
  rowIndex?: number;
  cell: Cell<T>;
  onChange: (id: string, value: T[Prop]) => void;
  options: Array<T[Prop]>;
  className?: string;
  disabled?: boolean;
  setForceHover?: (forced: boolean) => void;
}

function DropdownCell<T extends Record<string, unknown>, Prop extends keyof T>(
  props: DropdownCellProps<T, Prop>,
) {
  const [popperVisible, setPopperVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>();

  const { cell, options, onChange, className, disabled, setForceHover, rowIndex } = props;
  const id = get(cell.row.original, 'id') as string | undefined;

  const { styles, attributes, update } = usePopper(cellRef.current, popperRef.current, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 2],
        },
      },
      {
        // don't remove this modifier or `update` will make scrolling very slow (this may be a bug in popper)
        name: 'eventListeners',
        options: {
          scroll: false,
        },
      },
    ],
  });

  useLayoutEffect(() => {
    update?.(); // update positioning every time the row changes index
  }, [rowIndex, update]);

  useClickOutside(cellRef, () => {
    setPopperVisible(false);
  });

  useLayoutEffect(() => {
    setForceHover?.(popperVisible);
  }, [popperVisible, setForceHover]);

  return (
    <>
      <div
        className={cx('flex items-center justify-between h-full', !disabled && 'cursor-pointer', className)}
        onMouseEnter={() => {
          if (disabled) return;
          setWidth(cellRef.current!.getBoundingClientRect().width); // eslint-disable-line @typescript-eslint/no-non-null-assertion
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        ref={cellRef}
        onClick={() => {
          if (disabled) return;
          setPopperVisible((status) => !status);
        }}
      >
        {cell.value}
        {(isHovered || popperVisible) && !disabled && (
          <div
            className="h-0 w-0 mx-1 border-light-control dark:border-dark-control"
            style={{
              borderLeft: '4px solid transparent',
              borderRight: '4px solid transparent',
              borderTop: '4px solid ',
            }}
          />
        )}
        <div
          ref={popperRef}
          className={cx(
            'bg-light-base dark:bg-dark-base border border-light-control dark:border-dark-control border-r rounded',
            popperVisible ? 'visible z-9999' : 'invisible -z-1',
          )}
          style={{
            ...styles.popper,
            width: `${width}px`,
          }}
          onClick={useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
          }, [])}
          {...attributes.popper}
        >
          <div className="flex flex-col">
            {options.map((option) => (
              <button
                key={option as string}
                className={classNames(
                  'py-2 px-3 text-left last:rounded-b',
                  (option as string)?.toLowerCase() === (cell.value as string)?.toLowerCase()
                    ? 'bg-light-selected dark:bg-dark-selected'
                    : 'hover:bg-light-hover dark:hover:bg-dark-hover',
                )}
                onClick={() => {
                  onChange(id!, option); // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  setPopperVisible(false);
                }}
              >
                {capitalize(option as string)}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(DropdownCell) as unknown as typeof DropdownCell;
