import Big from 'big.js';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { TxnFragment, TxnType } from '../../../graphql/types';
import pluralize from '../../../lib/pluralize';
import { WarningToast } from '../../Toast';

interface Props {
  txns: TxnFragment[];
}

const isInvalidPrice = (price: string) => !['', '.'].includes(price) && Big(price).lte(0);

export default function PriceValidationWarning({ txns }: Props): React.ReactElement | null {
  const [priceValidationErrorsCount, setPriceValidationErrorsCount] = useState<number>(0);

  //TODO: instead of this, create a formik field wrapper that will run validations on fields and set field error on formik context
  useEffect(() => {
    let txnsWithInvalidPricesCount = 0;

    txns.forEach((txn) => {
      const { txnType, sellPrice, buyPrice } = txn;

      if ([txnType, sellPrice, buyPrice].some(isUndefined)) {
        return;
      }

      const invalidBuySidePrice =
        [
          TxnType.income,
          TxnType.mining,
          TxnType.airdrop,
          TxnType.fork,
          TxnType.staking,
          TxnType.borrow,
          TxnType.deposit,
          TxnType.trade,
        ].includes(txnType) && isInvalidPrice(buyPrice);

      const invalidSellSidePrice =
        [
          TxnType.gift,
          TxnType.lost,
          TxnType.stolen,
          TxnType.spend,
          TxnType.repay,
          TxnType.withdrawal,
          TxnType.trade,
        ].includes(txnType) && isInvalidPrice(sellPrice);

      // price is invalid when buy/sell side price is invalid and part of the txn type
      if (invalidBuySidePrice || invalidSellSidePrice) {
        txnsWithInvalidPricesCount++;
      }
    });

    setPriceValidationErrorsCount(txnsWithInvalidPricesCount);
  }, [txns]);

  if (priceValidationErrorsCount === 0) {
    return null;
  }

  let msg = 'Spot price of $0 will cause proceeds to be incorrect';

  if (priceValidationErrorsCount !== txns.length) {
    msg += ` for ${priceValidationErrorsCount} ${pluralize(priceValidationErrorsCount, 'transaction')}`;
  }

  return (
    <WarningToast
      onHide={() => {
        setPriceValidationErrorsCount(0);
      }}
    >
      <i className="relative text-base text-orange-500 material-icons mx-2" style={{ top: '0.1em' }}>
        report_problem
      </i>
      {msg}
    </WarningToast>
  );
}
