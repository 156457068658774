import React, { useCallback, useState } from 'react';
import Turnstile, { useTurnstile } from 'react-turnstile';
import { TURNSTILE_SITE_KEY } from '../../lib/constants';
import Button from '../Button';
import Card from '../Card';
import { FormStatus } from '../FormStatus';
import Input from '../Input';
import PageSeo, { meta } from '../PageSeo';
import PortalLayout from '../PortalLayout';
import TextLink from '../TextLink';
import { passwordForgot } from './mutations';
import { useTheme } from '../../theme/useTheme';

export default function ForgotPassword() {
  const [passwordRequestSuccessful, setPasswordRequestSuccessful] = useState(false);
  const [email, setEmail] = useState('');
  const [submitError, setSubmitError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const turnstile = useTurnstile();

  const { theme } = useTheme();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!captchaResponse) throw new Error('Invalid captcha response');

      setIsLoading(true);

      if (!email) {
        setSubmitError('Please enter your email address');
        setIsLoading(false);
        return;
      }

      try {
        await passwordForgot({
          email,
          captchaResponse: captchaResponse!,
        }); // eslint-disable-line @typescript-eslint/no-non-null-assertion
        setPasswordRequestSuccessful(true);
        window.analytics.track('forgot_password_submit', { location: 'forgot_password' });
      } catch (error) {
        turnstile.reset();
        const errString =
          error instanceof Error
            ? error.message
            : 'Could not send password reset email. Please try again later.';

        setSubmitError(errString);
        window.analytics.track('forgot_password_failed', { location: 'forgot_password' });
      } finally {
        setIsLoading(false);
      }
    },
    [email, captchaResponse, turnstile],
  );

  if (passwordRequestSuccessful) {
    return (
      <PortalLayout>
        <Card className="p-4  center max-w-lg rounded mx-auto mt-10 text-center">
          <div className="px-3 py-4 center max-w-5xl mv6-ns tc flex flex-col gap-4">
            <p>
              A password reset link has been sent to <strong>{email}</strong>
            </p>
            <p>Follow the instructions in the email to log in</p>
            <TextLink to="/login">Back to login</TextLink>
          </div>
        </Card>
      </PortalLayout>
    );
  }

  return (
    <PortalLayout>
      <div className="mx-auto text-center p-3">
        <PageSeo title={meta.FORGOT.title} description={meta.FORGOT.description} />

        <Card className="p-4 bg-white center max-w-lg rounded mx-auto mt-10">
          <h1 className="text-center text-3xl my-5 mb-8">Reset your password</h1>

          {submitError && <FormStatus type="error">{submitError}</FormStatus>}
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <Input
              className="w-full "
              name="email"
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Email"
            />
            <Turnstile
              sitekey={TURNSTILE_SITE_KEY}
              size="flexible"
              theme={theme}
              onVerify={(token) => {
                setCaptchaResponse(token);
              }}
            />
            <Button large fullWidth type="submit" loading={isLoading} className="mb-4">
              Request password reset
            </Button>
          </form>
        </Card>
      </div>
    </PortalLayout>
  );
}
