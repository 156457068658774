import { gql } from '@apollo/client';

// the query below includes limits on collections so as not to incur into complexity limits
// https://www.contentful.com/developers/docs/references/graphql/#/introduction/query-complexity-limits
export const PLANS_DATA = gql`
  query PlansData {
    addOnCollection(limit: 20) {
      items {
        id
        name
        description
        price
        invoiceLine
        analyticsId
        soldOut
        deprecated
        isFullFilingPlan
        priceIsStartingAt
        requiredPlanCollection {
          items {
            id
          }
        }
      }
    }

    planCollection(limit: 20, order: rank_ASC) {
      items {
        id
        name
        description
        price
        rank
        invoiceLine
        soldOut
        deprecated
        requiresExtension
        mostPopular
        includedAddonsCollection(limit: 20) {
          items {
            id
          }
        }
        featuresCollection(limit: 50) {
          items {
            title
            link
            tooltip
            featured
            asterisk
            optional
          }
        }
      }
    }
  }
`;

export const INTEGRATIONS_DATA = gql`
  query IntegrationData($skip: Int) {
    integrationCollection(order: name_ASC, limit: 100, skip: $skip) {
      items {
        id
        name
        type
        adminOnly
        stageOnly
        image {
          url
        }
        icon {
          url
        }
        pointsToCollection {
          items {
            id
            name
          }
        }
        csvInstructions {
          id
          instructions {
            json
          }
        }
        exchangeApiInstructions {
          id
          instructions {
            json
          }
        }
        walletInstructions {
          id
          instructions {
            json
          }
        }
        oAuthInstructions {
          id
          instructions {
            json
          }
        }
        notice {
          json
        }
      }
    }
  }
`;
