import React, { forwardRef } from 'react';
import classNames from 'classnames';

export const textVariantClassnames = {
  base: ['text-light-base', 'dark:text-dark-base'],
  faint: ['text-light-faint', 'dark:text-dark-faint'],
  muted: ['text-light-muted', 'dark:text-dark-muted'],
  critical: ['text-light-alert-critical', 'dark:text-dark-alert-critical'],
  success: ['text-light-alert-success', 'dark:text-dark-alert-success'],
};

type Element = HTMLParagraphElement | HTMLSpanElement | HTMLDivElement;

export interface TextProps extends React.DetailedHTMLProps<React.HTMLAttributes<Element>, Element> {
  children: React.ReactNode;
  variant: keyof typeof textVariantClassnames;
  className?: string;
  // this should be
  // as?: keyof JSX.IntrinsicElements;
  // but it "Produces a union type that is too complex to represent" (typescript)
  // so for now let's just add tags to the union as we need them
  as?: 'span' | 'p' | 'div';
  title?: string;
}

const Text = forwardRef(function TextInner(
  { children, variant, className, as: Tag = 'span', title, ...props }: TextProps,
  ref,
) {
  return (
    <Tag
      className={classNames(textVariantClassnames[variant], className)}
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={ref as any}
      title={title}
    >
      {children}
    </Tag>
  );
});

export default React.memo(Text) as typeof Text;
