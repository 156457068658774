import React from 'react';
import HeaderNavItem from './HeaderNavItem';
import { navItems } from './navItems';
import type { NavItem } from './types';
import { isNavItemActive } from './utils';

const mainDesktopNavItems: NavItem[] = [
  {
    name: 'Tax',
    children: [
      navItems.taxDashboard,
      navItems.taxLossHarvesting,
      navItems.taxGuide,
      navItems.incomeDashboard,
    ].filter((item) => item.shown !== false),
  },
  navItems.documents,
  navItems.allTransactions,
  navItems.importData,
  navItems.profile,
  navItems.recon,
];

type Props = {
  isAdminPage: boolean;
};

export default function DesktopNavItems({ isAdminPage }: Props) {
  if (isAdminPage) {
    return null;
  }
  return (
    <>
      {mainDesktopNavItems.map((item) => {
        return (
          <HeaderNavItem key={item.name} item={item} active={isNavItemActive(item, location.pathname)} />
        );
      })}
    </>
  );
}
