import { CATEGORY_DATA } from './Wizard/constants';
import { SideOrBoth } from './Wizard/types';
import { Category } from './store/types';

export const getUnreconciledTransactionUrl = (id: string) => `/recon/transactions/unreconciled/view?id=${id}`;

const { sent, received, both } = SideOrBoth;

// e.g.
// {
//   sent: ['transferOut', 'repay', ..., 'other' ],
//   received: ['transferIn', 'borrow', ..., 'other' ],
//   both: ['trade', ..., 'other']
// }
export const categoriesForEachSide = Object.fromEntries(
  [sent, received, both].map((side) => [
    side,
    Object.entries(CATEGORY_DATA)
      .filter(([_category, { applicableToTxnsHaving }]) => applicableToTxnsHaving.includes(side))
      .map(([category]) => category as Category),
  ]),
);

export const initiallyHiddenRowsForSide: Record<SideOrBoth, SideOrBoth[]> = {
  [sent]: [both],
  [received]: [both],
  [both]: [sent, received],
};
