import { truncate } from 'lodash';
import React from 'react';
import Spinner from '../../../Spinner';
import Text from '../../../Text';
import Tooltip from '../../../Tooltip';
import { useAllTransactionsContext } from '../../Provider';

const SpecIdPill = () => {
  const { specIdTxnResponse } = useAllTransactionsContext();
  const txn = specIdTxnResponse?.data?.txn;

  if (!txn) {
    return <Spinner size="sm" />;
  }

  return (
    <div className="flex text-sm border border-light-control dark:border-dark-control bg-light-selected dark:bg-dark-selected rounded">
      <div className="p-1 px-2 border-r border-light-control dark:border-dark-control rounded-l flex items-center">
        <Text variant="muted" className="flex items-center">
          Spec ID{' '}
          <Tooltip iconClassNames="leading-none" icon="info_outline">
            <div className="flex flex-col gap-2">
              <p>This filter shows the available options to pair with the selected sell event.</p>
              <p>Close or save the Spec ID panel to clear this filter.</p>
            </div>
          </Tooltip>
        </Text>
      </div>
      <div className="p-1 px-2 flex items-center">
        <Text variant="muted" className="flex items-center">
          Txn {truncate(txn.blocksvcHash as string, { length: 8, omission: '...' })}
        </Text>
      </div>
    </div>
  );
};

export default React.memo(SpecIdPill);
