import { gql, useQuery } from '@apollo/client';
import { chain, sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useUserContext } from '../contexts';
import { Token, TokensContext } from '../contexts/TokensContext';
import { TokenOverridesQuery } from '../graphql/types';
import { dispatchNetworkError } from '../lib/apollo/clientEvents';

export const TOKEN_OVERRIDES = gql`
  query TokenOverrides {
    tokenOverridesList {
      symbol
      id
    }
  }
`;

const TOKENS_JSON_URI = `${process.env.GQL_URI}/assets/tokens.json`;

export default function TokensProvider({ children }: { children: React.ReactNode }) {
  const { isLoggedIn } = useUserContext();
  const { data } = useQuery<TokenOverridesQuery>(TOKEN_OVERRIDES, {
    skip: !isLoggedIn,
    context: { batched: true },
  });

  const [isFetching, setIsFetching] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);

  useEffect(() => {
    if (tokens.length > 0 || !isLoggedIn || isFetching) return;

    setIsFetching(true);
    fetch(TOKENS_JSON_URI, {
      credentials: 'include',
      cache: 'no-cache', // counterintuitive but this is how one uses Etags https://stackoverflow.com/a/68407563
    })
      .then((response) => response.json() as Promise<Token[]>)
      .then((tokens) => sortBy(tokens, (token) => token.marketCapRank ?? Infinity))
      .then(setTokens)
      .finally(() => {
        setIsFetching(false);
      })
      .catch(() => {
        dispatchNetworkError('Tokens could not be fetched');
      });
    // don't add isFetching to the dependencies
    // if the dev server is down in local development, it will cause a request loop
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  const overrides = useMemo(() => data?.tokenOverridesList ?? [], [data?.tokenOverridesList]);

  const top50TokensByMarketCap = useMemo(() => tokens.slice(0, 50), [tokens]);
  const top50IdsByMarketCap = useMemo(
    () => new Set(top50TokensByMarketCap.map(({ id }) => id)),
    [top50TokensByMarketCap],
  );
  const tokenSymbolsWithTop50MarketCap = useMemo(
    () =>
      new Set([
        ...top50TokensByMarketCap.map(({ symbol }) => symbol as string),
        ...overrides.filter(({ id }) => top50IdsByMarketCap.has(id)).map(({ symbol }) => symbol),
      ]),
    [overrides, top50IdsByMarketCap, top50TokensByMarketCap],
  );

  const tokensById = useMemo(
    () => Object.fromEntries(tokens.map(({ id, ...rest }) => [id, { id, ...rest }])),
    [tokens],
  );
  const tokensBySymbol = useMemo(() => {
    const overriddenTokensMap = Object.fromEntries(
      overrides.map(({ id, symbol }) => [symbol, tokensById[id]]),
    );

    const tokensWithSymbolsMap = chain(tokens)
      .filter((tok) => Boolean(tok.symbol))
      .reverse()
      .keyBy((tok) => tok.symbol!.toUpperCase())
      .value();

    return {
      ...tokensWithSymbolsMap,
      ...overriddenTokensMap,
    };
  }, [overrides, tokens, tokensById]);

  return (
    <TokensContext.Provider value={{ tokens, tokensById, tokensBySymbol, tokenSymbolsWithTop50MarketCap }}>
      {children}
    </TokensContext.Provider>
  );
}
