import classNames from 'classnames';
import React, { useCallback } from 'react';

import { CheckboxInput } from '../../Input';
import { Cell } from '../types';

interface SelectCellProps<T extends Record<string, unknown>, IdType extends string | number> {
  cell: Cell<T, IdType>;
  checked?: boolean;
  minus?: boolean;
  onClick?: (value: IdType, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  disabled?: boolean;
}

function SelectCell<T extends Record<string, unknown>, IdType extends string | number>(
  props: SelectCellProps<T, IdType>,
): React.ReactElement {
  const { cell, checked, minus, onClick, className, disabled } = props;

  return (
    <CheckboxInput
      checked={!!checked}
      minus={minus}
      className={classNames('select-none', className)}
      disabled={disabled}
      onClick={useCallback<(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void>(
        (event) => {
          // don't propagate the click event - there may be event listeners on the row containing the cell
          event.stopPropagation();
          onClick?.(cell.value, event);
        },
        [cell.value, onClick],
      )}
    />
  );
}

export default React.memo(SelectCell) as unknown as typeof SelectCell;
