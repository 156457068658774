import React from 'react';
import { FilterOperatorTypes, TxnCurrencyFilter } from '../../../graphql/types';
import Filter from '../Filter';
import { FilterProps } from '../types';
import CurrencyInput from './CurrencyInput';
import CurrencyPill, { CurrencyPillProps } from './CurrencyPill';

type PillProps = Pick<CurrencyPillProps, 'omit'>;
type ExtraProps = PillProps;
type Props = ExtraProps & FilterProps<TxnCurrencyFilter>;

const { Is, IsNot, Ctns, DNCtn } = FilterOperatorTypes;

export { default as CurrencyInput } from './CurrencyInput';

function CurrencyFilter(props: Props) {
  const { omit, ...rest } = props;

  return (
    <Filter<TxnCurrencyFilter, ExtraProps>
      Input={CurrencyInput}
      Pill={CurrencyPill}
      props={{ omit }}
      operators={[Is, IsNot, Ctns, DNCtn]}
      {...rest}
    />
  );
}

export default React.memo(CurrencyFilter);
