import { partition } from 'lodash';
import { CATEGORY_DATA } from '../../Wizard/constants';
import { ComplexTreatmentResult, Props } from './types';

export const incomeWithExclusion = (props: Props): ComplexTreatmentResult => {
  const { txn, lpTokenTickersByAddress, sentTxns, receivedTxns } = props;
  const { tokenParam1 } = txn;

  if (!tokenParam1 || !tokenParam1.length) return { drafts: null };

  const [matchingDeposits, otherDeposits] = partition(
    receivedTxns,
    ({ txnType, buyCurrency }) =>
      txnType === 'deposit' && tokenParam1.map((t) => t.toLowerCase()).includes(buyCurrency.toLowerCase()),
  );
  if (matchingDeposits.length !== 1)
    return {
      drafts: null,
      replacementCategory: 'other', // https://linear.app/tokentax/issue/TOK-2680/token-marked-as-income-when-its-not-in-the-token-params-for-income-w
    };

  const matchingDeposit = matchingDeposits[0]!;

  const drafts = [
    {
      received: {
        grouped: false as const,
        txns: [
          {
            id: matchingDeposit.id,
            type: CATEGORY_DATA.income.receivedType!,
            currency: lpTokenTickersByAddress[matchingDeposit.buyAddress!] ?? matchingDeposit.buyCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            tokenId: matchingDeposit.buyTokenId,
            quantity: matchingDeposit.buyQuantity,
            price: matchingDeposit.buyPrice,
          },
        ],
      },
    },
    ...otherDeposits.map((deposit) => ({
      received: {
        grouped: false as const,
        txns: [
          {
            id: deposit.id,
            type: 'received' as const,
            currency: lpTokenTickersByAddress[deposit.buyAddress!] ?? deposit.buyCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            tokenId: deposit.buyTokenId,
            quantity: deposit.buyQuantity,
            price: deposit.buyPrice,
          },
        ],
      },
    })),
    ...sentTxns.map((withdrawal) => ({
      sent: {
        grouped: false as const,
        txns: [
          {
            id: withdrawal.id,
            type: 'sent' as const,
            currency: lpTokenTickersByAddress[withdrawal.sellAddress!] ?? withdrawal.sellCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            tokenId: withdrawal.sellTokenId,
            quantity: withdrawal.sellQuantity,
            price: withdrawal.sellPrice,
          },
        ],
      },
    })),
  ];

  return { drafts };
};
