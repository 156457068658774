import { ApolloClient, gql } from '@apollo/client';
import { UPLOAD } from '../../graphql/fragments';
import {
  GetCsvUploadStatusQuery,
  GetCsvUploadStatusQueryVariables,
  GetCsvUploadsQuery,
} from '../../graphql/types';
import { HIJACKED_USER_ID_HEADER_NAME } from '../../lib/constants';

export const GET_CSV_UPLOADS = gql`
  query GetCSVUploads {
    uploads(csvOnly: true, uploadTableInput: { sortBy: createdAt, sortDirection: desc }) {
      edges {
        ...Upload
      }
    }
  }
  ${UPLOAD}
`;

interface GetCSVUploadsProps {
  userId?: number;
}

export const getCSVUploads = (client: ApolloClient<unknown>, options?: GetCSVUploadsProps) => {
  const { userId } = options ?? {};
  return client.query<GetCsvUploadsQuery>({
    query: GET_CSV_UPLOADS,
    fetchPolicy: 'no-cache',
    ...(userId
      ? {
          context: {
            headers: { [HIJACKED_USER_ID_HEADER_NAME]: userId },
          },
        }
      : {}),
  });
};

export const CSV_UPLOAD_STATUS = gql`
  query GetCSVUploadStatus($requestId: String!) {
    csvUploadStatus(requestId: $requestId) {
      status
      unsupportedKnownCsv {
        expectedFile
        message
        exchange
      }
    }
  }
`;

type GetCSVUploadStatusProps = {
  requestId: string;
  userId?: number;
};

export const getCSVUploadStatus = (client: ApolloClient<unknown>, options: GetCSVUploadStatusProps) => {
  const { requestId, userId } = options;

  return client.query<GetCsvUploadStatusQuery, GetCsvUploadStatusQueryVariables>({
    query: CSV_UPLOAD_STATUS,
    variables: { requestId },
    fetchPolicy: 'no-cache',
    ...(userId
      ? {
          context: {
            headers: { [HIJACKED_USER_ID_HEADER_NAME]: userId },
          },
        }
      : {}),
  });
};
