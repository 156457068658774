/**
 * Unlike with material-icons, the icons in this file do not use a web font.
 * As such, they don't automatically inherit the text color of their parent element.
 * You cannot style `fill` and other inner properties of SVG's unless the SVG is inlined.
 * This component wraps all icons in a theme-aware <Text>, making sure that the `fill` matches the desired variant.
 */

import React, { SVGAttributes, SVGProps, forwardRef } from 'react';
import Text, { TextProps } from '../Text';

export type IconType = keyof typeof ICONS;

interface Props extends Omit<TextProps, 'children' | 'variant'> {
  type: IconType;
  fill?: SVGAttributes<SVGElement>['fill'];
  variant?: TextProps['variant'] | 'noWrapper';
  svgProps?: SVGProps<SVGSVGElement>;
}

export const isIcon = (icon: string): icon is IconType => Boolean(ICONS[icon as IconType]);

const ICONS: Record<string, (props?: SVGProps<SVGSVGElement>) => React.ReactElement> = {
  bolt: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M11 9H13L5.59072 14.557L7.46667 9.67949L7.72801 9H7H5L12.4093 3.44304L10.5333 8.32051L10.272 9H11Z"
        stroke="currentColor"
      />
    </svg>
  ),
  chain: (props) => (
    <svg width="17" height="12" viewBox="0 0 18 12" fill="none" {...props}>
      <circle cx="6" cy="6" r="4.5" stroke="currentColor" />
      <circle cx="12" cy="6" r="4.5" stroke="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6C10 8.20913 8.20916 9.99998 6.00004 10L6.00004 9C7.65687 8.99998 9 7.65684 9 6L10 6Z"
        fillOpacity="0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11C8.76142 11 11 8.76142 11 6L11 2.68221C11.6318 3.63251 12 4.77326 12 6C12 9.31371 9.31371 12 6 12C4.21217 12 2.60698 11.2181 1.50776 9.97751L2.96981 9.97751C3.81058 10.619 4.8608 11 6 11ZM1.00004 5.98051C1.00001 5.987 0.999999 5.9935 0.999999 6C0.999999 6.0065 1.00001 6.013 1.00004 6.01949L1.00004 9.31785C0.368166 8.36753 -1.37096e-06 7.22676 -1.47821e-06 6C-1.63356e-06 4.223 0.772501 2.62643 2 1.52779L2 2.99951C1.56753 3.57513 1.25641 4.24712 1.10463 4.97751L1.00004 4.97751L1.00004 5.98051ZM6 5.24537e-07C7.22674 4.17292e-07 8.36749 0.368153 9.31779 0.999999L6 1L2.6822 1C3.63251 0.368152 4.77326 6.31781e-07 6 5.24537e-07Z"
        fillOpacity="0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08301 5C6.55909 2.16229 9.02705 0 12 0V1C9.6301 1 7.58293 2.74886 7.10512 5H6.08301ZM8.68225 11C9.63256 11.6318 10.7733 12 12 12V11H8.68225Z"
        fillOpacity="0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 2.0022C11.9526 2.00074 11.9049 2 11.8571 2C9.65544 2 8.01113 3.77873 8 5.97754H9.00003C9.01209 4.40857 10.2866 3 11.8571 3C11.9047 3 11.9524 3.0013 11.9999 3.00385V2.0022ZM15.9997 6.00003V6.0468L15.9999 6.00003H15.9997Z"
        fillOpacity="0"
      />
    </svg>
  ),
  check: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path d="M3.5 9.5L7 13L16.5 3.5" stroke="currentColor" />
    </svg>
  ),
  checked: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 14C6.23858 14 4 11.7614 4 9C4 6.23858 6.23858 4 9 4C10.2535 4 11.3992 4.46124 12.2767 5.22326L12.9853 4.51469C11.9256 3.5724 10.5296 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15C12.3137 15 15 12.3137 15 9C15 7.72625 14.6031 6.54521 13.9262 5.5738L13.2055 6.29449C13.7083 7.07438 14 8.00312 14 9C14 11.7614 11.7614 14 9 14Z"
        fill="currentColor"
      />
      <path d="M6.5 8L9 10.5L17 2.5" stroke="currentColor" />
    </svg>
  ),
  comment: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <mask id="path-1-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 4C3.44772 4 3 4.44772 3 5V9.39776V11V14.8697C3 15.3038 3.51515 15.5317 3.83634 15.2397L7.4 12H14C14.5523 12 15 11.5523 15 11V5C15 4.44772 14.5523 4 14 4H4Z"
        />
      </mask>
      <path
        d="M3.83634 15.2397L3.16366 14.4998L3.16366 14.4998L3.83634 15.2397ZM7.4 12V11H7.01339L6.72733 11.2601L7.4 12ZM4 5H4V3C2.89543 3 2 3.89543 2 5H4ZM4 9.39776V5H2V9.39776H4ZM4 11V9.39776H2V11H4ZM4 14.8697V11H2V14.8697H4ZM3.16366 14.4998C3.48485 14.2078 4 14.4357 4 14.8697H2C2 16.1719 3.54545 16.8556 4.50901 15.9796L3.16366 14.4998ZM6.72733 11.2601L3.16366 14.4998L4.50901 15.9796L8.07267 12.7399L6.72733 11.2601ZM14 11H7.4V13H14V11ZM14 11V13C15.1046 13 16 12.1046 16 11H14ZM14 5V11H16V5H14ZM14 5H14H16C16 3.89543 15.1046 3 14 3V5ZM4 5H14V3H4V5Z"
        fill="currentColor"
        mask="url(#path-1-inside-1)"
      />
    </svg>
  ),
  contract: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <rect x="3.5" y="9.5" width="5" height="1" fill="currentColor" />
      <rect x="9.5" y="7.5" width="5" height="1" fill="currentColor" />
      <rect x="7.5" y="14.5" width="5" height="1" transform="rotate(-90 7.5 14.5)" fill="currentColor" />
      <rect x="9.5" y="8.5" width="5" height="1" transform="rotate(-90 9.5 8.5)" fill="currentColor" />
      <path d="M8 10L2 16" stroke="currentColor" />
      <path d="M16 2L10 8" stroke="currentColor" />
    </svg>
  ),
  copy: (props) => (
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" {...props}>
      <path
        d="M6 0H1C0.5 0 0 0.5 0 1V7.5H1L0.95148 0.954545L6 1V0ZM7 2H3C2.5 2 2 2.5 2 3V10C2 10.5 2.5 11 3 11H7C7.5 11 8 10.5 8 10V3C8 2.475 7.46316 2 7 2ZM7 10H3V3H7V10Z"
        fill="currentColor"
      />
    </svg>
  ),
  delete: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <rect x="7.5" y="2.5" width="3" height="2" rx="0.5" stroke="currentColor" />
      <rect x="4" y="4" width="10" height="1" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6V15C5 15.5523 5.44772 16 6 16H12C12.5523 16 13 15.5523 13 15V6H12V15H6V6H5Z"
        fill="currentColor"
      />
    </svg>
  ),
  dollar: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2.5H8.5V4.20487C7.04201 4.36235 5.92296 5.23771 5.92296 6.83096C5.92296 8.54259 7.22654 9.064 8.5 9.37958V12.6279C7.56375 12.4117 6.79139 11.7436 6.78096 10.523H5.58496C5.64172 12.4643 6.99723 13.4637 8.5 13.6859V15.5H9.5V13.7213C11.0487 13.6053 12.371 12.6923 12.371 11.056C12.371 9.29181 10.831 8.84603 9.5 8.54248V5.2264C10.3741 5.35707 10.9857 5.87247 11.019 6.80495H12.163C12.1046 5.25173 10.9656 4.36994 9.5 4.2067V2.5ZM8.5 5.23502C7.65866 5.37521 7.02796 5.86985 7.02796 6.77895C7.02796 7.70726 7.67448 8.0627 8.5 8.2998V5.23502ZM9.5 9.61944V12.6944C10.4855 12.6068 11.201 12.0644 11.201 11.186C11.201 10.2147 10.3449 9.84498 9.5 9.61944Z"
        fill="currentColor"
      />
    </svg>
  ),
  download: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path d="M9 14L9 3.5" stroke="currentColor" />
      <path d="M5.5 10.5L9 14L12.5 10.5" stroke="currentColor" />
    </svg>
  ),
  edit: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 2.91418L15.0858 4.49997L14.1464 5.43933L12.5606 3.85354L13.5 2.91418ZM11.8535 4.56065L4 12.4142L4 14L5.58579 14L13.4393 6.14643L11.8535 4.56065ZM14.1464 6.85354L6 15L4 15L3 15V14V12L12.7929 2.20708C13.1834 1.81655 13.8166 1.81655 14.2071 2.20708L15.7929 3.79286C16.1834 4.18339 16.1834 4.81655 15.7929 5.20708L14.8535 6.14643L14.8536 6.14645L14.1464 6.85355L14.1464 6.85354Z"
        fill="currentColor"
      />
    </svg>
  ),
  edited: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <circle cx="9" cy="9" r="4.5" stroke="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12V6Z"
        fill="currentColor"
      />
    </svg>
  ),
  expand: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <rect x="10" y="3" width="5" height="1" fill="currentColor" />
      <rect x="3" y="14" width="5" height="1" fill="currentColor" />
      <rect x="14" y="8" width="5" height="1" transform="rotate(-90 14 8)" fill="currentColor" />
      <rect x="3" y="15" width="5" height="1" transform="rotate(-90 3 15)" fill="currentColor" />
      <path d="M14.5 3.5L3.5 14.5" stroke="currentColor" />
    </svg>
  ),
  filter: (props) => (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" {...props}>
      <path d="M9 1H1L3.5 6V11H6.5V6L9 1Z" stroke="currentColor" />
    </svg>
  ),
  goto: (props) => (
    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" {...props}>
      <g>
        <rect x="4" y="1" width="5" height="1" fill="currentColor" />
        <rect x="8" y="6" width="5" height="1" transform="rotate(-90 8 6)" fill="currentColor" />
        <path d="M8.5 1.5L0.5 9.5" stroke="currentColor" />
      </g>
    </svg>
  ),
  question: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9C15 12.3137 12.3137 15 9 15C5.68629 15 3 12.3137 3 9C3 5.68629 5.68629 3 9 3C12.3137 3 15 5.68629 15 9ZM16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM9.41726 10.5506H8.6744V10.0879C8.6744 9.11005 9.16574 8.7567 9.6299 8.4229C9.67045 8.39373 9.7108 8.36471 9.7506 8.33544C10.1506 8.04993 10.5125 7.75457 10.5125 7.20323C10.5125 6.48453 9.94107 6.24824 9.35059 6.24824C8.62679 6.24824 8.1125 6.64205 8.1125 7.42968H7.3125C7.3125 6.12025 8.25536 5.5 9.36964 5.5C10.4554 5.5 11.3125 6.07103 11.3125 7.21308C11.3125 8.07814 10.8298 8.44425 10.324 8.82795C10.2311 8.89841 10.1374 8.96946 10.0458 9.0443C9.5125 9.48734 9.41726 9.73347 9.41726 10.2159V10.5506ZM9.57917 12.5H8.54107V11.3973H9.57917V12.5Z"
        fill="currentColor"
      />
    </svg>
  ),
  refresh: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 10L5 7C5 5.89543 5.89543 5 7 5L13 5L13 4L7 4C5.34315 4 4 5.34314 4 7L4 10L5 10Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 8L13 11C13 12.1046 12.1046 13 11 13L5 13L5 14L11 14C12.6569 14 14 12.6569 14 11L14 8L13 8Z"
          fill="currentColor"
        />
        <path d="M7 16L4.5 13.5L7 11" stroke="currentColor" />
        <path d="M11 2L13.5 4.5L11 7" stroke="currentColor" />
      </g>
    </svg>
  ),
  upload: (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3536 6.64646L9 3.29291L5.64645 6.64646L6.35355 7.35357L8.5 5.20712L8.5 12H9.5L9.5 5.20712L11.6464 7.35357L12.3536 6.64646Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 11H4.5V14.5H13.5V11H14.5V14.5C14.5 15.0523 14.0523 15.5 13.5 15.5H4.5C3.94772 15.5 3.5 15.0523 3.5 14.5V11Z"
        fill="currentColor"
      />
    </svg>
  ),
  warning: (props) => (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2515 17C19.0249 17 19.5056 16.1596 19.1134 15.493L10.8619 1.46527C10.4752 0.807963 9.52464 0.807964 9.13799 1.46527L0.88641 15.493C0.494272 16.1596 0.974928 17 1.74834 17H18.2515ZM10.9999 14C10.9999 14.5523 10.5522 15 9.99993 15C9.44764 15 8.99993 14.5523 8.99993 14C8.99993 13.4477 9.44764 13 9.99993 13C10.5522 13 10.9999 13.4477 10.9999 14ZM9.99993 4.99998C9.44764 4.99998 8.99993 5.4477 8.99993 5.99998V10.5C8.99993 11.0523 9.44764 11.5 9.99993 11.5C10.5522 11.5 10.9999 11.0523 10.9999 10.5V5.99998C10.9999 5.4477 10.5522 4.99998 9.99993 4.99998Z"
        fill="#FF8A01"
      />
    </svg>
  ),
};

const IconWrapper = forwardRef(function Icon({ type, variant = 'muted', svgProps, ...props }: Props, ref) {
  const icon = ICONS[type](svgProps);

  if (variant === 'noWrapper') return icon;

  return (
    <Text variant={variant} {...props} ref={ref}>
      {icon}
    </Text>
  );
});

export default React.memo(IconWrapper);
