import { useReactiveVar } from '@apollo/client';
import cx from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CellProps, Renderer } from 'react-table';
import { ArrayParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { RecalcContext, useUserContext, VersionsContext } from '../../../contexts';
import {
  EnumTaxMethod,
  LineItemsFilterQuery,
  LineItemSortOptions,
  LineItemsQuery,
  SortDirectionOptions,
} from '../../../graphql/types';
import Blankslate from '../../Blankslate/index';
import { showEditTxnPanel } from '../../EditTxnPanel';
import Icon from '../../Icon';
import Spinner from '../../Spinner';
import { Body, ControlledTable, HandlePageChange, HandleSort, Header, TablePagination } from '../../Table';
import { ExpandedRowProps } from '../../Table/Body';
import TextLink from '../../TextLink';
import { VipUpsellBanner } from '../../VipUpsellBanner';
import { getTotalPages } from '../../utils/pagination';
import { ReportType } from '../types';
import CombinedToolbar from './CombinedToolbar';
import { ExpandedRow } from './ExpandedRow';
import { getColumns } from './columns';
import { NUM_ITEMS } from './constants';
import { useLineItems } from './queries';
import selectors from './selectors';
import { actions } from './state';
import { LineItemCounts, LineItemData } from './types';
import {
  expandedIdVar,
  filterQueryVar,
  initialLoadingVar,
  initialOptionsSetVar,
  initialQuerySetVar,
  paginationVar,
  sortVar,
} from './vars';

type Txn = NonNullable<LineItemsQuery['lineItems']['edges'][number]['txnLineItemSellIdTotxn']>;

interface LineItemTableProps {
  /** currency to display values in */
  baseCurrency?: string;
  /** report to display */
  report?: ReportType;
  /** number of lineItems and lineItems with warnings in the report */
  lineItemCounts: LineItemCounts;
  /** method of the report being displayed */
  method: EnumTaxMethod;
  /** year of the report being display */
  year: number;
  /** whether or not the user has a plan for the selected year */
  hasPlan: boolean;
  /** whether or not the user has a report generated for the selected year */
  hasReport: boolean;
  /** whether ot not the user has uploaded any data */
  hasData: boolean;
  /** whether or not the displayed report has any income */
  hasIncome: boolean;
  /** whether or not the user has exceeded the txn limit */
  hasExceededTxnLimit: boolean;
  /** txnLimit query loading */
  reportsLoading: boolean;
}

function LineItemTable({
  report,
  lineItemCounts,
  baseCurrency,
  method,
  hasPlan,
  hasExceededTxnLimit,
  hasData,
  hasReport,
  hasIncome,
  reportsLoading,
  year,
}: LineItemTableProps) {
  const { user } = useUserContext();
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    sortBy: StringParam,
    sortDir: StringParam,
    warningsFilter: ArrayParam,
    fromDate: NumberParam,
    toDate: NumberParam,
    currencyFilterType: StringParam,
    currencyTicker: StringParam,
    filterQuery: StringParam,
  });

  const history = useHistory();

  const { lineItemCount } = lineItemCounts;

  const hasTaxableEvents = lineItemCount > 0;

  const sortState = useReactiveVar(sortVar);
  const paginationState = useReactiveVar(paginationVar);
  const { page } = paginationState;
  const filterQueryState = useReactiveVar(filterQueryVar);
  const initialLoadingState = useReactiveVar(initialLoadingVar);
  const initialOptionsSetState = useReactiveVar(initialOptionsSetVar);
  const initialQuerySetState = useReactiveVar(initialQuerySetVar);
  const expandedId = useReactiveVar(expandedIdVar);
  // const { isTokenTaxAdmin } = useContext(UserContext);

  const { isRecalculating, recalculate } = useContext(RecalcContext);

  const filterQuery = useMemo(() => {
    return JSON.parse(filterQueryState || '{}') as LineItemsFilterQuery;
  }, [filterQueryState]);

  // update query params when reactive vars change
  useEffect(() => {
    if (initialQuerySetState && query.page !== page) {
      setQuery({ page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (initialQuerySetState && (query.sortBy !== sortState.sortBy || query.sortDir !== sortState.sortDir)) {
      setQuery({ sortBy: sortState.sortBy, sortDir: sortState.sortDir });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortState.sortBy, sortState.sortDir]);

  useEffect(() => {
    if (initialQuerySetState && query.filterQuery !== filterQueryState) {
      setQuery({ filterQuery: filterQueryState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialQuerySetState, filterQueryState]);

  // TODO: custom hook
  // set local state if the query changes (history.back/forward)
  useEffect(() => {
    if (initialQuerySetState) {
      actions.setStateFromQuery(query);
    }
  }, [initialQuerySetState, query]);

  const { loading: lineItemsLoading, data } = useLineItems({
    paginationState,
    sortState,
    filterQueryState,
    txnReportId: report?.id,
    filterDust: false,
  });

  // reset expandedId whenever new data loads
  useEffect(() => {
    actions.resetExpandedId();
  }, [data]);

  const loading = lineItemsLoading || reportsLoading;

  const reportId = report?.id;

  // reset pagination options back to page 1 when the report changes (year or method changes)
  // check for initial query on first report fetch after mount and update reactive vars accordingly
  useEffect(() => {
    if (reportId) {
      if (!initialOptionsSetState) {
        // sets the options based on query params
        actions.setInitialOptions(query);
      } else {
        // reset to first page and reset date filters whenever a new report is received
        actions.resetPagination();
        actions.setPage(1);
        // reset query params so there isn't an extra push
        // this will lose the page state if the user hits 'back' after going to another year or method
        // but its better than having the back button get you out of sync
        setQuery(
          {
            page: 1,
          },
          'replaceIn',
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  // TODO: abstract this hook: same logic as txn table
  // reset pagination options back to page 1 when the filters change
  useEffect(() => {
    if (initialOptionsSetState && initialQuerySetState) {
      // reset to first page and reset date filters whenever a new report is received
      actions.resetPagination();
      actions.setPage(1);
      // reset query params so there isn't an extra push
      // this will lose the page state if the user hits 'back' after going to another year or method
      // but its better than having the back button get you out of sync
      setQuery(
        {
          page: 1,
        },
        'replaceIn',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQueryState]);

  // set initial query options after initial options have been set
  // uses 'replaceIn' so that it doesn't add an extra history.back step
  useEffect(() => {
    if (initialOptionsSetState && !initialQuerySetState) {
      const initialQuery = actions.setInitialQuery();
      setQuery(initialQuery, 'replaceIn');
    }
  }, [setQuery, initialOptionsSetState, initialQuerySetState]);

  // resets 'initialQuerySet' on dismount
  // so that we will set the query params from local state again on remount
  useEffect(() => {
    return () => {
      actions.resetInitialQuerySet();
    };
  }, []);

  useEffect(() => {
    if (data !== undefined && initialLoadingState) {
      actions.setInitialLoading(false);
    }
  }, [data, initialLoadingState]);

  // we are in 'initialLoad' state if we do not yet have data and haven't had data before
  const initialLoad = initialLoadingState && data === undefined;

  // filteredCount will be present if we have filtered down the total results
  const filteredCount = data?.lineItems?.pageInfo.filteredCount;

  // TODO: custom hook?
  // cache the last filtered count so that we keep track of how many pages there are
  const [lastFilteredCount, setLastFilteredCount] = useState<number | null | undefined>(filteredCount);
  useEffect(() => {
    if (!loading) {
      setLastFilteredCount(filteredCount);
    }
  }, [loading, filteredCount]);

  // use filtered count to determine page count if it is non-null
  let visibleCount = filteredCount == null ? lineItemCount : filteredCount;

  // if we are loading and we have a lastFilteredCount then use that
  if (loading && lastFilteredCount) {
    visibleCount = lastFilteredCount;
  }
  const totalPages = getTotalPages({ totalCount: visibleCount, pageSize: NUM_ITEMS });

  // if in loading state (no edges yet) we default to empty array of records
  const records = useMemo(() => (data?.lineItems?.edges as LineItemData[]) || [], [data?.lineItems?.edges]);

  const handleSort: HandleSort<LineItemData> = useCallback(({ id }, sorted) => {
    if (id) {
      actions.setSortBy(id as LineItemSortOptions);
      const newSorted = sorted === 'asc' ? 'desc' : 'asc';
      actions.setSortDir(newSorted as SortDirectionOptions);

      actions.resetPagination();
      actions.setPage(1);
    }
  }, []);

  const handlePageChange: HandlePageChange = useCallback((desiredPage) => {
    actions.setPage(desiredPage);
  }, []);

  const handleResetQuery = useCallback(() => {
    const newQuery = actions.resetAll();
    setQuery(newQuery, 'replaceIn');
  }, [setQuery]);

  const { isRestoringOrRefetching } = useContext(VersionsContext);

  const onEditTxnCb = useCallback((txn: Txn) => {
    showEditTxnPanel({
      txns: [txn],
      subtitle: 'You are editing the sell transaction',
    });
  }, []);
  const onEditTxn = isRestoringOrRefetching ? undefined : onEditTxnCb;

  const actionsColumn = useMemo(
    () => ({
      id: 'actions',
      accessor: 'txnLineItemSellIdTotxn',
      Cell: function ActionsColumn(cell) {
        const lineItem = cell.row.original;
        return (
          <div className="flex justify-end items-center h-full relative">
            <span className="ml-3">
              <Icon
                type={lineItem.id === expandedId ? 'contract' : 'expand'}
                className="cursor-pointer"
                onClick={() => {
                  lineItem.id === expandedId ? actions.resetExpandedId() : actions.setExpandedId(lineItem.id);
                }}
              />
            </span>
            <span className="ml-3">
              <Icon
                type="edit"
                className={cx(onEditTxn ? 'cursor-pointer' : 'opacity-50')}
                onClick={() => {
                  if (isRestoringOrRefetching) return;
                  onEditTxn?.(cell.value);
                }}
              />
            </span>
          </div>
        );
      } as Renderer<CellProps<LineItemData>>,
      hideTitle: true,
      sortable: false,
    }),
    [expandedId, onEditTxn, isRestoringOrRefetching],
  );

  // TODO: can turn this back on when we actually use this for every lineItem:
  // const displayFeeColumns = records.some(({ feeUnitsSold }) => feeUnitsSold !== null);
  const displayFeeColumns = false;

  const displayAccountColumns = !!user?.recalcByAccount;

  const columns = useMemo(
    () => [...getColumns({ displayFeeColumns, displayAccountColumns, method }), actionsColumn],
    [displayFeeColumns, displayAccountColumns, method, actionsColumn],
  );

  const blankslate = useMemo(() => {
    if (loading) return null;

    // return proper blankslate for user state
    if (selectors.getIsEmptyFilterQuery(lineItemCounts, page, records, filterQuery)) {
      return (
        <Blankslate
          title="No Results"
          subtitle="Adjust the filters or select a different page"
          cta="Reset"
          onClick={handleResetQuery}
          card={false}
        />
      );
    }

    if (hasTaxableEvents) {
      return null;
    }

    // user has not yet paid for a plan - show upgrade blankslate
    if (!hasPlan) {
      return (
        <Blankslate
          title="Upgrade to calculate your crypto taxes"
          subtitle="With a TokenTax plan, you can view gains and losses, see estimated tax liability, download tax forms, and strategically harvest tax losses"
          cta="Upgrade"
          onClick={() => history.push('/purchase')}
          card={false}
        />
      );
    }

    // use has exceeded the txn limit
    if (hasExceededTxnLimit) {
      return (
        <Blankslate
          title="You have more transactions than our plans allow."
          subtitle={`To increase your transaction limit, upgrade your ${year} plan`}
          cta="Upgrade"
          onClick={() => history.push('/purchase')}
          card={false}
        />
      );
    }

    // no trades but income
    if (hasIncome) {
      return (
        <Blankslate
          title="You have income, but no taxable trades"
          subtitle={`You have earned crypto income, but you have no taxable trades to report for ${year}`}
          cta="Import data"
          onClick={() => history.push('/import')}
          card={false}
        />
      );
    }

    // has report or has uploaded data but no trades or income
    if (hasReport || hasData) {
      return (
        <Blankslate
          title="You have no taxable trades or income"
          subtitle={`Data is uploaded, but you have no taxable trades and no crypto income to report for ${year}.`}
          cta="Import data"
          onClick={() => history.push('/import')}
          card={false}
          extraNode={
            <div className="mt-2">
              <TextLink onClick={() => recalculate()} loading={isRecalculating}>
                Recalculate
              </TextLink>
            </div>
          }
        />
      );
    }

    // final option is hasPlan, but no data and no report
    return (
      <Blankslate
        title="Get started by importing your data"
        subtitle="Your tax and performance dashboard will provide you with all you need to know about your crypto taxes"
        cta="Import data"
        onClick={() => history.push('/import')}
        card={false}
      />
    );
  }, [
    filterQuery,
    handleResetQuery,
    hasData,
    hasExceededTxnLimit,
    hasIncome,
    hasPlan,
    hasReport,
    hasTaxableEvents,
    history,
    isRecalculating,
    lineItemCounts,
    loading,
    page,
    recalculate,
    records,
    year,
  ]);

  const controlledState = useMemo(
    () => ({
      page,
      totalPages,
      initialLoad,
      loading,
      sortBy: sortState.sortBy,
      sortDirection: sortState.sortDir,
    }),
    [initialLoad, loading, page, sortState.sortBy, sortState.sortDir, totalPages],
  );

  const expandedRowIds = useMemo(() => (expandedId ? [expandedId] : undefined), [expandedId]);
  const expandedRowComponent = useCallback(
    (props: ExpandedRowProps<LineItemData>) => ExpandedRow({ ...props, onEditTxn }),
    [onEditTxn],
  );

  if (loading && data === undefined) {
    return (
      <div className="flex flex-grow flex-col justify-center">
        <Spinner className="self-center" />
      </div>
    );
  }

  return (
    <>
      <ControlledTable
        scrolling={false}
        name="LineItemsTable"
        data={records}
        columns={columns}
        handleSort={handleSort}
        handlePageChange={handlePageChange}
        controlledState={controlledState}
        baseCurrency={baseCurrency}
        expandedRowIds={expandedRowIds}
        expandedRowComponent={expandedRowComponent}
        gridGapSize="18px"
        blankslate={
          loading ? (
            <div className="py-20 text-center">
              <Spinner />
            </div>
          ) : (
            blankslate
          )
        }
      >
        {hasTaxableEvents && (
          <CombinedToolbar filterQuery={filterQuery} loading={loading} visibleCount={visibleCount} />
        )}
        <VipUpsellBanner />
        {!blankslate && <Header data={records} />}
        {!blankslate && hasTaxableEvents && <Body scrolling={false} data={records} />}
        <TablePagination />
      </ControlledTable>
    </>
  );
}

export default React.memo(LineItemTable);
