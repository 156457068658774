import { useCallback, useState } from 'react';
import { TxnFragment } from '../../../graphql/types';
import { useObject } from '../../../lib/hooks';
import { showErrorFlash, showSuccessFlash } from '../../Flash';
import { showConfirmationDialog } from '../../Modal/ConfirmationDialog';
import { actions } from '../actions';
import { doNotAskAgainToConfirmMergeTxns, shouldAskToConfirmMergeTxns } from '../helpers';
import { useMergeTxns } from '../queries';
import { AllTransactionsContextType } from './context';

export const useOnMerge = ({ refetch }: Required<Pick<AllTransactionsContextType, 'refetch'>>) => {
  const [mergeTxns] = useMergeTxns();
  // temporarily disable editing operations for these txn ids's (used while certain operations are in progress)
  const [txnsBeingMerged, setTxnsBeingMerged] = useState<string[]>([]);

  const onMerge = useCallback(
    async (txns: TxnFragment[]) => {
      if (shouldAskToConfirmMergeTxns()) {
        await showConfirmationDialog({
          title: 'Merge Transactions',
          subtitle: `Merge the selected transactions?`,
          buttonText: 'Merge',
          setDoNotShowAgain: doNotAskAgainToConfirmMergeTxns,
        });
      }

      const txnIds = txns.map(({ id }) => id);

      try {
        setTxnsBeingMerged(txnIds);
        actions.deselect(txnIds);

        await mergeTxns({
          variables: { txnIds },
        });

        refetch?.();

        showSuccessFlash('Transactions successfully merged');
      } catch (error) {
        showErrorFlash('The selected transactions could not be merged.', { error });
      } finally {
        setTxnsBeingMerged([]);
      }
    },
    [mergeTxns, refetch],
  );

  return useObject({ txnsBeingMerged, onMerge });
};
