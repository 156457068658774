import { LineItemSortOptions, SortDirectionOptions } from '../../../graphql/types';
import { PaginationState } from '../../utils/pagination';
import { NUM_ITEMS } from './constants';
import { sortState } from './types';

export const defaultSortState: sortState = {
  sortBy: LineItemSortOptions.sellDate,
  sortDir: SortDirectionOptions.asc,
};

export const defaultPaginationOptions: PaginationState = {
  pageSize: NUM_ITEMS,
  page: 1,
};
