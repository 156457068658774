import { gql } from '@apollo/client';

export const UNRECONCILED_TRANSACTION_RESOLUTION = gql`
  fragment UnreconciledTransactionResolution on UnreconciledTransactionResolution {
    id
    unreconciledTransactionId
    userId
    credentialType
    blocksvcHash
    category
    edited
    appliedAt
    invalidatedAt
    revertedAt
    drafts {
      sent {
        txns {
          id
          currency
          tokenId
          quantity
          type
        }
      }
      received {
        txns {
          id
          currency
          tokenId
          quantity
          type
        }
      }
    }
  }
`;
