import { gql, useMutation } from '@apollo/client';
import { SyncCovalentWalletsMutation, SyncCovalentWalletsMutationVariables } from '../../graphql/types';

const SYNC_COVALENT_WALLETS = gql`
  mutation SyncCovalentWallets($addresses: [String!]!, $chainIds: [String!]!) {
    syncCovalentWallets(addresses: $addresses, chainIds: $chainIds) {
      credentials
      exchangeServiceRequests
      duplicates
    }
  }
`;

export const useSyncCovalentWallets = () => {
  return useMutation<SyncCovalentWalletsMutation, SyncCovalentWalletsMutationVariables>(
    SYNC_COVALENT_WALLETS,
  );
};
