export const txnFieldTooltips = {
  txnType: 'The type of transaction.',
  txnTimestamp: 'The date and time of the transaction (in UTC). e.g. 04/24/2021 11:00 AM',
  sellCurrency:
    'The ticker of the currency sold or sent. This can also be the currency of the cost basis for income, staking, mining, airdrop, fork, and borrow transactions.',
  sellQuantity:
    'The total amount of currency sold or sent. This can also be the cost basis amount for income, staking, mining, airdrop, fork, and borrow transactions.',
  buyCurrency: 'The ticker of the currency bought or received.',
  buyQuantity: 'The total amount of currency bought or received.',
  feeCurrency: 'The ticker of the fee currency.',
  feeQuantity: 'The total amount of currency fees.',
  sellPrice: 'The USD price of the sell currency, per 1 unit of the currency.',
  buyPrice: 'The USD price of the buy currency, per 1 unit of the currency.',
  exchangeName: 'The exchange name.',
  location: 'Where did the transaction happen.',
  accountId: 'Which account did the transaction happen on.',
  toAccountId: 'Which account did the funds move to.',
  integrationId: 'Which integration did the transaction happen on.',
  description: 'Any comments or notes about this transaction. This is optional.',
  vendor: 'Quickbooks vendor',
  account: 'Quickbooks account',
  reviewed: 'Whether this transaction has been reviewed.',
  isSpam: 'Whether this transaction is spam.',
};
