import React, { useCallback, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Turnstile, { useTurnstile } from 'react-turnstile';
import { TURNSTILE_SITE_KEY } from '../../lib/constants';
import Button from '../Button';
import Card from '../Card';
import { FormStatus } from '../FormStatus';
import Input from '../Input';
import PageSeo, { meta } from '../PageSeo';
import PortalLayout from '../PortalLayout';
import { passwordReset } from './mutations';
import { useTheme } from '../../theme/useTheme';

export default function ResetPassword() {
  const match = useRouteMatch<{ token: string }>();
  const [password, setPassword] = useState('');
  const [passwordCompare, setPasswordCompare] = useState('');
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string>();
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const turnstile = useTurnstile();

  const { theme } = useTheme();

  useEffect(() => {
    window.analytics.track('Reset Password', { location: 'reset_password' });
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!captchaResponse) throw new Error('Invalid captcha response');

      const { token } = match.params;

      if (!password || !passwordCompare) {
        setSubmitError('Please enter your new password.');
        return;
      }

      if (password !== passwordCompare) {
        setSubmitError('The two passwords you entered do not match.');
        return;
      }

      setIsSubmitting(true);

      try {
        await passwordReset({ password, token, captchaResponse });
        setPasswordResetSuccessful(true);
        window.analytics.track('password_reset_completed', { location: 'reset_password' });
      } catch (e) {
        turnstile.reset();
        window.analytics.track('password_reset_failed', { location: 'reset_password' });
        setSubmitError(e instanceof Error ? e.message : 'Unable to create a user with those credentials');
      } finally {
        setIsSubmitting(false);
      }
    },
    [match.params, password, passwordCompare, captchaResponse, turnstile],
  );

  if (passwordResetSuccessful) {
    return (
      <PortalLayout>
        <Card className="center max-w-lg mx-auto mt-10 text-center">
          <div className="p-4 tc">
            <h1 className="text-center font-light text-3xl my-5 mb-8">Password Successfully Reset</h1>
            <p className="messina gray">
              <Link to="/login">
                <Button large>
                  <span className="px-2">Sign in</span>
                </Button>
              </Link>
            </p>
          </div>
        </Card>
      </PortalLayout>
    );
  }

  return (
    <PortalLayout>
      <div className="mx-auto mt-10 px-4 rounded text-center">
        <PageSeo title={meta.FORGOT.title} description={meta.FORGOT.description} />
        <Card className="py-4 px-6 max-w-lg mx-auto">
          <div className="my-5 mb-8">
            <h1 className="text-3xl">Reset your password</h1>
          </div>

          {submitError && <FormStatus type="error">{submitError}</FormStatus>}

          <form onSubmit={handleSubmit}>
            <div className="form-group flex flex-col">
              <Input
                type="password"
                onChange={(event) => setPassword(event.target.value)}
                className="mb-4"
                name="password"
                placeholder="New Password"
              />
              <Input
                type="password"
                onChange={(event) => setPasswordCompare(event.target.value)}
                className="mb-4"
                name="password"
                placeholder="Re-Enter Password"
              />
            </div>

            <Turnstile
              sitekey={TURNSTILE_SITE_KEY}
              size="flexible"
              theme={theme}
              onVerify={(token) => {
                setCaptchaResponse(token);
              }}
            />

            <Button large fullWidth type="submit" loading={isSubmitting} className="mt-3 mb-4">
              Reset password
            </Button>
          </form>
        </Card>
      </div>
    </PortalLayout>
  );
}
