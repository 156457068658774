import React, { memo, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import Dropdown, { DropdownHandle } from '../Dropdown';
import NavDropdownItem from './NavDropdownItem';
import type { NavItem } from './types';
import { isNavItemActive } from './utils';

interface NavDropdownProps {
  items: NavItem[];
  dropdownBody: React.ReactNode;
}

const NavDropdown: React.FC<NavDropdownProps> = ({ items, dropdownBody, children }) => {
  const location = useLocation();
  const dropdownRef = useRef<DropdownHandle>(null);

  useEffect(() => {
    dropdownRef.current?.hide();
  }, [location]);

  return (
    <div className="relative">
      <Dropdown placement="bottom-end" ref={dropdownRef} label={'Menu'} dropdownBody={dropdownBody}>
        <nav className="w-[180px] flex flex-col text-base p-2">
          {items?.map((child) => (
            <NavDropdownItem
              key={child.name}
              item={child}
              active={isNavItemActive(child, location.pathname)}
            />
          ))}
          {children}
        </nav>
      </Dropdown>
    </div>
  );
};

export default memo(NavDropdown);
