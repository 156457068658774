import React from 'react';

import { ChoiceProps } from './types';
import Option from './Option';

export default function ChooseSoftware({ send }: ChoiceProps) {
  const options = [
    { value: 'TURBOTAX_DESKTOP', label: 'TurboTax Desktop' },
    { value: 'TURBOTAX_ONLINE', label: 'TurboTax Online' },
    { value: 'TAXACT', label: 'TaxAct' },
    { value: 'OTHER', label: 'Other' },
  ];

  return (
    <div className="pt-4">
      {options.map((o) => (
        <Option key={o.value} option={o} send={send} next="CHOOSE_METHOD" />
      ))}
    </div>
  );
}
