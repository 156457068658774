import { Side } from '../../Wizard/types';
import { buildDraftFromTransfers } from '../buildDraftFromTransfers';
import { ComplexTreatmentResult, Props } from './types';

export const wrap = (props: Props): ComplexTreatmentResult => {
  const { category, txn, lpTokenTickersByAddress, sentTxns, receivedTxns } = props;
  const { tokenParam1, tokenParam2 } = txn;

  const { sent, received } = Side;
  const txnsMap = { sent: sentTxns, received: receivedTxns };

  const matchingDeposit =
    tokenParam1 &&
    receivedTxns.find(
      ({ txnType, buyCurrency }) =>
        txnType === 'deposit' && buyCurrency && buyCurrency.toLowerCase() === tokenParam1[0].toLowerCase(),
    );

  if (matchingDeposit) {
    const sellCurrency = tokenParam2?.[0];
    if (!sellCurrency) return { drafts: null };
    const quantity = matchingDeposit.buyQuantity;

    // turn matching deposit into a trade
    const trade = {
      sent: {
        grouped: false as const,
        txns: [
          {
            id: null,
            type: 'sold' as const,
            currency: sellCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            quantity,
          },
        ],
      },
      received: {
        grouped: false as const,
        txns: [
          {
            id: matchingDeposit.id,
            type: 'bought' as const,
            currency: lpTokenTickersByAddress[matchingDeposit.buyAddress!] ?? matchingDeposit.buyCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            tokenId: matchingDeposit.buyTokenId,
            quantity,
            price: matchingDeposit.buyPrice,
          },
        ],
      },
    };

    const drafts = [
      trade,
      // break out other transfers into their own drafts
      ...[sent, received].flatMap((side) =>
        txnsMap[side]
          .filter((txn) => txn.id !== matchingDeposit.id)
          .flatMap((txn) =>
            buildDraftFromTransfers({
              category,
              lpTokenTickersByAddress,
              txns: [txn],
            }),
          ),
      ),
    ];

    return { drafts };
  }

  const matchingWithdrawal =
    tokenParam2 &&
    sentTxns.find(
      ({ txnType, sellCurrency }) =>
        txnType === 'withdrawal' &&
        sellCurrency &&
        sellCurrency.toLowerCase() === tokenParam2[0].toLowerCase(),
    );

  if (matchingWithdrawal) {
    const buyCurrency = tokenParam1?.[0];
    if (!buyCurrency) return { drafts: null };
    const quantity = matchingWithdrawal.sellQuantity;

    // turn matching withdrawal into a trade
    const trade = {
      sent: {
        grouped: false as const,
        txns: [
          {
            id: matchingWithdrawal.id,
            type: 'sold' as const,
            currency:
              lpTokenTickersByAddress[matchingWithdrawal.sellAddress!] ?? matchingWithdrawal.sellCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            tokenId: matchingWithdrawal.sellTokenId,
            quantity,
            price: matchingWithdrawal.sellPrice,
          },
        ],
      },
      received: {
        grouped: false as const,
        txns: [
          {
            id: null,
            type: 'sold' as const,
            currency: buyCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            quantity,
          },
        ],
      },
    };

    const drafts = [
      trade,
      // break out other transfers into their own drafts
      ...[sent, received].flatMap((side) =>
        txnsMap[side]
          .filter((txn) => txn.id !== matchingWithdrawal.id)
          .flatMap((txn) =>
            buildDraftFromTransfers({
              category,
              lpTokenTickersByAddress,
              txns: [txn],
            }),
          ),
      ),
    ];

    return { drafts };
  }

  return { drafts: null };
};
