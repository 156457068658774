import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Txn } from '../../graphql/types';
import Page from '../Layout/Page';
import Spinner from '../Spinner';
import Text from '../Text';
import Header from './header';
import { useIncomeDashboard } from './queries';
import { useTheme } from '../../theme/useTheme';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type PartialTxn = Pick<Txn, 'sellQuantity' | 'sellCurrency' | 'txnTimestamp'>;

type SelectedRange = '1W' | '4W' | '1Y' | 'MTD' | 'QTD' | 'YTD' | 'ALL';

const filterDataByRange = (edges: PartialTxn[], selectedRange: SelectedRange) => {
  const now = new Date();
  let filteredEdges = edges;

  const today = new Date();

  switch (selectedRange) {
    case '1W': {
      filteredEdges = edges.filter((txn) => {
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);

        const txnDate = new Date(txn.txnTimestamp);

        return txnDate >= sevenDaysAgo;
      });

      break;
    }
    case '4W': {
      filteredEdges = edges.filter((txn) => {
        const txnDate = new Date(txn.txnTimestamp);

        const twentyEightDaysAgo = new Date(today);
        twentyEightDaysAgo.setDate(today.getDate() - 30);

        return txnDate >= twentyEightDaysAgo;
      });
      break;
    }
    case '1Y': {
      filteredEdges = edges.filter((txn) => {
        const txnDate = new Date(txn.txnTimestamp);

        const oneYearAgo = new Date(today);
        oneYearAgo.setFullYear(today.getFullYear() - 1);

        return txnDate >= oneYearAgo;
      });
      break;
    }
    case 'MTD': {
      filteredEdges = edges.filter((txn) => {
        const txnDate = new Date(txn.txnTimestamp);

        return txnDate.getMonth() === now.getMonth() && txnDate.getFullYear() === now.getFullYear();
      });
      break;
    }
    case 'QTD': {
      const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3;
      filteredEdges = edges.filter((txn) => {
        const txnDate = new Date(txn.txnTimestamp);

        return txnDate.getMonth() >= quarterStartMonth && txnDate.getFullYear() === now.getFullYear();
      });
      break;
    }
    case 'YTD': {
      filteredEdges = edges.filter((txn) => {
        const txnDate = new Date(txn.txnTimestamp);
        return txnDate.getFullYear() === now.getFullYear();
      });
      break;
    }
    default:
      break;
  }

  return filteredEdges;
};

export default function IncomeDashboard() {
  const { theme } = useTheme();

  const isDarkMode = theme === 'dark';

  const { data, loading } = useIncomeDashboard();

  const [selectedRange, setSelectedRange] = useState<SelectedRange>('1Y');

  const filteredEdges = useMemo(
    () => filterDataByRange((data?.incomeDashboard?.edges as PartialTxn[]) || [], selectedRange),
    [data?.incomeDashboard?.edges, selectedRange],
  );

  const aggregatedData = useMemo(
    () =>
      filteredEdges.reduce(
        (acc: Record<string, number>, txn) => {
          const dateKey = new Date(txn.txnTimestamp).toISOString().split('T')[0];

          acc[dateKey] = (acc[dateKey] || 0) + Number(txn.sellQuantity);

          return acc;
        },
        {} as Record<string, number>,
      ),
    [filteredEdges],
  );

  if (loading) {
    return (
      <div className="w-full h-96 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <Page title="Income">
      <Header />
      <div className="flex gap-2 mb-4">
        {(['1W', '4W', '1Y', 'MTD', 'QTD', 'YTD', 'ALL'] as SelectedRange[]).map((range) => (
          <button
            key={range}
            onClick={() => setSelectedRange(range)}
            className={classNames(
              'px-3 py-1 text-sm font-medium transition-colors bg-light-base dark:bg-dark-base border rounded',
              selectedRange === range && 'border-light-brand dark:border-dark-brand-alt',
            )}
          >
            {range}
          </button>
        ))}
      </div>

      <div className={`bg-light-base dark:bg-dark-base border rounded text-xl p-4 mb-4`}>
        <h2 className="text-lg mb-2">Total Income</h2>
        <p className="text-3xl ">
          {filteredEdges
            .reduce((sum, txn) => sum + Number(txn.sellQuantity), 0)
            .toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        </p>
      </div>

      <div className={`bg-light-base dark:bg-dark-base border rounded text-xl p-4 h-96`}>
        {Object.keys(aggregatedData).length > 0 ? (
          <div className="w-full h-full">
            <Line
              data={{
                labels: Object.keys(aggregatedData).map((date) => new Date(date).toLocaleDateString()),
                datasets: [
                  {
                    label: 'Cumulative Income',
                    data: Object.values(aggregatedData).reduce((acc: number[], val, i) => {
                      acc.push((acc[i - 1] || 0) + val);
                      return acc;
                    }, []),

                    borderColor: isDarkMode ? '#FFFFFF' : '#000000',
                    borderWidth: 2,
                    pointRadius: 0,
                    tension: 0,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    backgroundColor: isDarkMode ? '#333' : '#FFF',
                    titleColor: isDarkMode ? '#FFF' : '#000',
                    bodyColor: isDarkMode ? '#FFF' : '#000',
                    intersect: false,
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: isDarkMode ? '#FFF' : '#000',
                    },
                  },
                  y: {
                    ticks: {
                      color: isDarkMode ? '#FFF' : '#000',
                    },
                  },
                },
              }}
            />
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Text variant="muted">No income data available</Text>
          </div>
        )}
      </div>
    </Page>
  );
}
