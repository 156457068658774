import Big from 'big.js';
import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import Input, { InputHandle, NumberInput } from '../Input';
import TextLink from '../TextLink';

export type LineItemData = {
  unitAmount: number;
  quantity: number;
  description: string;
};

interface Props {
  lineItem: LineItemData;
  index: number;
  onChange: (index: number, column: string, value: string | number) => void;
  removeLineItem: (index: number) => void;
  className?: string;
}

function LineItem({ lineItem, onChange, index, removeLineItem, className }: Props) {
  const { description, quantity, unitAmount } = lineItem;
  const descriptionRef = useRef<InputHandle>(null);

  // focus line item when it's added
  useEffect(() => {
    descriptionRef.current?.focus();
  }, []);

  return (
    <div className={cx('block pt-4 sm:contents', className)}>
      <div className="mr-0 sm:mr-4 my-1">
        <div className="sm:hidden">Description</div>
        <Input
          ref={descriptionRef}
          value={description}
          onChange={({ target }) => {
            onChange(index, 'description', target.value);
          }}
        />
      </div>
      <div className="mr-0 sm:mr-4 my-1">
        <div className="sm:hidden">Quantity</div>
        <NumberInput
          value={quantity}
          onChange={(value) => {
            if (value === '') return;
            onChange(index, 'quantity', Number(value));
          }}
          decimalScale={0}
          onBlur={(value) => {
            if (value === '0') onChange(index, 'quantity', 1);
          }}
        />
      </div>
      <div className="mr-0 sm:mr-4 my-1">
        <div className="sm:hidden">Price</div>
        <NumberInput
          value={unitAmount / 100}
          onValueChange={({ floatValue }) => {
            const valueInCents = Math.floor(Big(floatValue!).times(100).toNumber());

            onChange(index, 'unitAmount', valueInCents);
          }}
        />
      </div>
      <TextLink
        className="flex self-center my-4"
        onClick={() => {
          removeLineItem(index);
        }}
      >
        Remove
      </TextLink>
    </div>
  );
}

export default React.memo(LineItem);
