import { gql, ReactiveVar, useQuery, useReactiveVar } from '@apollo/client';
import { sortBy } from 'lodash';
import React from 'react';
import { CountsQuery, TxnFilterQuery } from '../../../graphql/types';
import Modal, { ModalProps, showModal } from '../../Modal';
import Spinner from '../../Spinner';
import { ErrorToast } from '../../Toast';
import { AllTransactionsActions } from '../actions';
import { FiltersState } from '../types';
import Content from './Content';

const EXCHANGE_NAME_COUNTS = gql`
  query Counts($filterQuery: TxnFilterQuery!) {
    txnExchangeNameCount(filterQuery: $filterQuery) {
      year
      total
      counts {
        count
        exchangeName
      }
    }
  }
`;

interface TransactionCountModalProps extends ModalProps {
  actions: AllTransactionsActions;
  filtersVar: ReactiveVar<FiltersState>;
}

function TransactionCountModal({ onClose, actions, filtersVar }: TransactionCountModalProps) {
  const filtersState = useReactiveVar(filtersVar);
  const { filterQuery } = filtersState;

  const { data, loading, error } = useQuery<CountsQuery>(EXCHANGE_NAME_COUNTS, {
    variables: {
      filterQuery: JSON.parse(filterQuery || '{}') as TxnFilterQuery,
    },
  });

  const txnExchangeNameCount = sortBy(data?.txnExchangeNameCount, ['year']);

  return (
    <Modal
      title="Transaction Count"
      onClose={onClose}
      style={{
        content: {
          maxWidth: '42rem',
        },
      }}
      showDivider
      padded={!loading}
    >
      {error && <ErrorToast>Failed to fetch transaction counts by exchange name.</ErrorToast>}
      {loading ? (
        <div className="p-16 text-center">
          <Spinner className="mr-2" size="md" />
        </div>
      ) : (
        <Content years={txnExchangeNameCount || []} {...{ actions, filtersState }} />
      )}
    </Modal>
  );
}

export default React.memo(TransactionCountModal);

export function showTransactionCountModal(props: TransactionCountModalProps) {
  showModal(<TransactionCountModal {...props} />);
}
