import React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import NavDropdown from './NavDropdown';
import type { NavItem } from './types';

export const topLevelLinkClasses = 'text-white';

const HeaderNavItem = ({ item, active }: { item: NavItem; active: boolean }) => {
  const linkItemClasses = classNames(
    'bg-opacity-0 min-h-[30px] flex items-center px-3 rounded transition-none',
    {
      'hover:bg-white hover:!bg-opacity-10': !active,
      'bg-white !bg-opacity-10': active,
    },
  );

  if (item.children) {
    return (
      <NavDropdown
        items={item.children}
        dropdownBody={
          <button
            className={classNames(
              'flex items-center cursor-pointer !text-base leading-3 outline-none',
              topLevelLinkClasses,
              linkItemClasses,
            )}
          >
            {item.name}
            <span className="material-icons text-[18px] ml-1">arrow_drop_down</span>
          </button>
        }
      />
    );
  }

  return (
    <div className={classNames(topLevelLinkClasses)}>
      {item.path?.includes('http') && (
        <a className={linkItemClasses} href={item.path as string} target="_blank" rel="noreferrer">
          {item.name}
        </a>
      )}
      {item.path && !item.path.includes('http') && (
        <Link className={linkItemClasses} to={item.path as string}>
          {item.name}
        </Link>
      )}

      {item.onClick && (
        <button className={linkItemClasses} onClick={item.onClick}>
          {item.name}
        </button>
      )}
    </div>
  );
};

export default HeaderNavItem;
