import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { toLower, uniqBy } from 'lodash';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useUserContext } from '../contexts';
import { IntegrationsContext } from '../contexts/IntegrationsContext';
import { SupportedCredentialsQuery } from '../graphql/types';
import Auth from '../lib/Auth';
import { useIntegrationsData } from '../lib/contentfulClient';
import { Integration } from '../lib/contentfulClient/shared';
import { useObject } from '../lib/hooks';

const SUPPORTED_CREDENTIALS = gql`
  query SupportedCredentials {
    supportedCredentials {
      supportedWallets
      exchangeCredentials {
        id
        schema
      }
      reconCredentialTypes
      evmCredentialTypes
      solanaCredentialTypes
    }
  }
`;

export type RequiredCredentials =
  SupportedCredentialsQuery['supportedCredentials']['exchangeCredentials'][0]['schema'][0];

type SupportedIntegration = Omit<Integration, 'id'> & {
  id: NonNullable<Integration['id']>;
};

export function useSupportedCredentials(options: QueryHookOptions<SupportedCredentialsQuery>) {
  const res = useQuery<SupportedCredentialsQuery>(SUPPORTED_CREDENTIALS, options);
  return { ...res, data: res.data?.supportedCredentials };
}

const isStage = process.env.ENV === 'stage'; // set in netlify.toml

interface Props {
  children: ReactNode;
}

export default function IntegrationsProvider({ children }: Props) {
  const [page, setPage] = useState(0);
  const [integrations, setIntegrations] = useState<SupportedIntegration[]>([]);
  const { isLoggedIn } = useUserContext();

  const isTokenTaxAdmin = Auth.tokenIsForAdmin();

  const { data: supportedCredentialData, loading: supportedCredentialsLoading } = useSupportedCredentials({
    skip: !isLoggedIn,
    context: { batched: true },
  });

  const { data: integrationsData, loading: integrationsDataLoading } = useIntegrationsData({
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: page * 100,
    },
  });

  const supportedIntegrations = useMemo(() => {
    const ids = supportedCredentialData
      ? [
          ...supportedCredentialData.supportedWallets,
          ...supportedCredentialData.exchangeCredentials.map((c) => c.id),
        ]
      : [];

    // Filter out integrations that don't have csvInstructions or are not in the supported credentials list
    return integrations.filter(
      ({ id, csvInstructions }) => (id && ids.includes(id)) || !!csvInstructions?.instructions,
    );
  }, [integrations, supportedCredentialData]);

  useEffect(() => {
    let fetchNextPage = false;

    if (integrationsData?.integrationCollection?.items.length === 100) {
      fetchNextPage = true;
    }

    const items =
      integrationsData?.integrationCollection?.items.filter(
        (integration): integration is SupportedIntegration => {
          if (!integration) return false;
          const { id, adminOnly, stageOnly } = integration;
          if (adminOnly && !isTokenTaxAdmin) return false;
          if (stageOnly && !isStage) return false;
          return Boolean(id);
        },
      ) || [];

    if (!items.length) return;

    setIntegrations((prevIntegrationData) => uniqBy([...prevIntegrationData, ...items], 'id'));

    if (fetchNextPage) {
      setPage((prev) => prev + 1);
    }
  }, [integrationsData, isTokenTaxAdmin]);

  const reconCredentialTypes = useMemo(
    () => supportedCredentialData?.reconCredentialTypes.map(toLower) ?? [],
    [supportedCredentialData?.reconCredentialTypes],
  );

  const evmCredentialTypes = useMemo(
    () => supportedCredentialData?.evmCredentialTypes.map(toLower) ?? [],
    [supportedCredentialData?.evmCredentialTypes],
  );

  const solanaCredentialTypes = useMemo(
    () => supportedCredentialData?.solanaCredentialTypes.map(toLower) ?? [],
    [supportedCredentialData?.solanaCredentialTypes],
  );

  const isReconCredentialType = useCallback(
    (credentialType: string) => {
      return reconCredentialTypes.includes(credentialType.toLowerCase()) ?? false;
    },
    [reconCredentialTypes],
  );

  const context = useObject({
    loading: integrationsDataLoading || supportedCredentialsLoading,
    allIntegrations: integrations,
    supportedIntegrations,
    exchangeCredentials: supportedCredentialData?.exchangeCredentials || [],
    supportedWallets: supportedCredentialData?.supportedWallets || [],
    isReconCredentialType,
    evmCredentialTypes,
    solanaCredentialTypes,
  });

  return <IntegrationsContext.Provider value={context}>{children}</IntegrationsContext.Provider>;
}

export const useIntegrationsContext = () => React.useContext(IntegrationsContext);
