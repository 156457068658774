import React from 'react';
import { TableState } from 'react-table';
import { SortDirectionOptions } from '../../../graphql/types';
import { ExpandedRowProps } from '../Body/types';
import { HandlePageChange, HandleSort, StaticTableProps } from '../types';

/**
 * Additional props required for controlled table
 * You must pass in page info / sort info which will be controlled outside of the
 * table component
 */
export interface ControlledState {
  page: number;
  totalPages: number;
  loading: boolean;
  countsLoading?: boolean;
  initialLoad: boolean;
  sortBy: string;
  sortDirection: SortDirectionOptions;
}

export interface ControlledTableState extends TableState {
  controlledState: ControlledState;
}

export interface ControlledTableProps<T extends Record<string, unknown>> extends StaticTableProps<T> {
  handleSort: HandleSort<T>;
  handlePageChange: HandlePageChange;
  controlledState: ControlledState;
  expandedRowIds?: number[] | string[];
  expandedRowComponent?: React.FC<ExpandedRowProps<T>>;
  gridTemplateColumns?: string;
  gridGapSize?: string;
  paddingLeft?: string;
  paddingRight?: string;
  scrolling?: boolean;
  disabledIds?: string[];
  headerOverlay?: React.ReactNode;
}
