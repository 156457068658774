import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  CreateAccountRowMutation,
  CreateAccountRowMutationVariables,
  DeleteAccountRowMutation,
  DeleteAccountRowMutationVariables,
  DeleteMovementsMutation,
  DeleteMovementsMutationVariables,
  EditAccountRowMutation,
  EditAccountRowMutationVariables,
  LatestCreateMovementsJobQuery,
  LatestGenerateAccountsJobQuery,
  MovementMergeCandidatesQuery,
  MovementMergeCandidatesQueryVariables,
  MovementsQuery,
  MovementsQueryVariables,
  RunCreateAccountsJobMutation,
  RunCreateAccountsJobMutationVariables,
  RunCreateMovementsJobMutation,
  RunCreateMovementsJobMutationVariables,
} from '../../../graphql/types';

const EDIT_ACCOUNT = gql`
  mutation EditAccountRow($editAccountRowId: Int!, $name: String, $address: String, $integrationId: String) {
    editAccountRow(id: $editAccountRowId, name: $name, address: $address, integrationId: $integrationId) {
      id
      address
      name
      integrationId
    }
  }
`;

export function useEditAccount(
  options?: MutationHookOptions<EditAccountRowMutation, EditAccountRowMutationVariables>,
) {
  return useMutation<EditAccountRowMutation, EditAccountRowMutationVariables>(EDIT_ACCOUNT, options);
}

const DELETE_ACCOUNT = gql`
  mutation DeleteAccountRow($deleteAccountRowId: Int!) {
    deleteAccountRow(id: $deleteAccountRowId)
  }
`;

export function useDeleteAccount(
  options?: MutationHookOptions<DeleteAccountRowMutation, DeleteAccountRowMutationVariables>,
) {
  return useMutation<DeleteAccountRowMutation, DeleteAccountRowMutationVariables>(DELETE_ACCOUNT, options);
}

const CREATE_ACCOUNT = gql`
  mutation CreateAccountRow($name: String!, $address: String, $integrationId: String!) {
    createAccountRow(name: $name, address: $address, integrationId: $integrationId) {
      id
      address
      name
      integrationId
    }
  }
`;

export function useCreateAccount() {
  return useMutation<CreateAccountRowMutation, CreateAccountRowMutationVariables>(CREATE_ACCOUNT);
}

const RUN_CREATE_ACCOUNTS_JOB = gql`
  mutation RunCreateAccountsJob {
    runCreateAccountsJob {
      id
    }
  }
`;

export function useRunCreateAccountsJob(
  options?: MutationHookOptions<RunCreateAccountsJobMutation, RunCreateAccountsJobMutationVariables>,
) {
  return useMutation<RunCreateAccountsJobMutation, RunCreateAccountsJobMutationVariables>(
    RUN_CREATE_ACCOUNTS_JOB,
    options,
  );
}

const LATEST_GENERATE_ACCOUNTS_JOB = gql`
  query LatestGenerateAccountsJob {
    latestGenerateAccountsJob {
      id
      status
      createdAt
    }
  }
`;

export const useLatestGenerateAccountsJob = (options: QueryHookOptions<LatestGenerateAccountsJobQuery>) => {
  return useQuery<LatestGenerateAccountsJobQuery>(LATEST_GENERATE_ACCOUNTS_JOB, options);
};

export const MOVEMENTS = gql`
  query Movements($movementTableInput: MovementTableInput!) {
    movements(movementTableInput: $movementTableInput) {
      edges {
        id
        movement {
          id
          component
          txnId
          txnTimestamp
          txnHash
          currency
          soldCurrency
          quantity
          soldQuantity
          credential {
            id
            name
            address
          }
          fromAccount {
            id
            name
            address
            integrationId
          }
          toAccount {
            id
            name
            address
            integrationId
          }
        }
        deposit {
          id
          component
          txnId
          txnTimestamp
          txnHash
          currency
          quantity
          credential {
            id
            name
            address
          }
        }
        withdrawal {
          id
          component
          txnId
          txnTimestamp
          txnHash
          soldCurrency
          soldQuantity
          credential {
            id
            name
            address
          }
        }
      }
      pageInfo {
        filteredCount
      }
    }
  }
`;

export const useMovements = (options: QueryHookOptions<MovementsQuery, MovementsQueryVariables>) => {
  return useQuery<MovementsQuery, MovementsQueryVariables>(MOVEMENTS, options);
};

const LATEST_CREATE_MOVEMENTS_JOB = gql`
  query LatestCreateMovementsJob {
    latestCreateMovementsJob {
      id
      status
      createdAt
      modifiedTxnsLength
    }
  }
`;

export const useLatestCreateMovementsJob = (options: QueryHookOptions<LatestCreateMovementsJobQuery>) => {
  return useQuery<LatestCreateMovementsJobQuery>(LATEST_CREATE_MOVEMENTS_JOB, options);
};

const RUN_CREATE_MOVEMENTS_JOB = gql`
  mutation RunCreateMovementsJob {
    runCreateMovementsJob {
      id
    }
  }
`;

export function useRunCreateMovementsJob(
  options?: MutationHookOptions<RunCreateMovementsJobMutation, RunCreateMovementsJobMutationVariables>,
) {
  return useMutation<RunCreateMovementsJobMutation, RunCreateMovementsJobMutationVariables>(
    RUN_CREATE_MOVEMENTS_JOB,
    options,
  );
}

const MOVEMENT_MERGE_CANDIDATES = gql`
  query MovementMergeCandidates($movementTableInput: MovementTableInput!) {
    movementMergeCandidates(movementTableInput: $movementTableInput) {
      edges {
        id
        txnType
        txnTimestamp
        txnHash
        currency
        soldCurrency
        quantity
        soldQuantity
        account {
          id
          name
        }
        credential {
          id
          name
          address
        }
      }
      pageInfo {
        filteredCount
      }
    }
  }
`;

export const useMovementMergeCandidates = (
  options: QueryHookOptions<MovementMergeCandidatesQuery, MovementMergeCandidatesQueryVariables>,
) => {
  return useQuery<MovementMergeCandidatesQuery, MovementMergeCandidatesQueryVariables>(
    MOVEMENT_MERGE_CANDIDATES,
    options,
  );
};

const DELETE_MOVEMENTS = gql`
  mutation DeleteMovements($txnGroupIds: [Int!]!) {
    deleteMovements(txnGroupIds: $txnGroupIds)
  }
`;

export const useDeleteMovements = (
  options?: MutationHookOptions<DeleteMovementsMutation, DeleteMovementsMutationVariables>,
) => {
  return useMutation<DeleteMovementsMutation, DeleteMovementsMutationVariables>(DELETE_MOVEMENTS, options);
};
