import React, { useState, useEffect, useContext } from 'react';
import useList from 'react-use/lib/useList';

import { PlansContext } from '../../contexts';
import Spinner from '../Spinner';
import Select from '../Select';
import Button from '../Button';
import { TAX_YEARS } from '../../constants/taxYears';
import TextLink from '../TextLink';

const yearOptions = [...TAX_YEARS]
  .sort()
  .reverse()
  .map((year) => ({
    value: year,
    label: `${year}`,
  }));

interface Props {
  updateSelectedProducts: (selectedProducts: SelectedProducts) => void;
}

interface PlanLike {
  id: string;
  category: string;
}

export interface SelectedPlan {
  planId: string;
  year: number;
}

export type SelectedProducts = {
  cryptoOnlyPlans: SelectedPlan[];
  fullFilingPlans: SelectedPlan[];
};

export type PlanConfig = {
  planArray: PlanLike[];
  fullFilingAddonsArray: PlanLike[];
};

function InvoiceProducts({ updateSelectedProducts }: Props) {
  const [cryptoOnlyPlans, cryptoOnlyActions] = useList<SelectedPlan>([]);
  const [fullFilingPlans, fullFilingActions] = useList<SelectedPlan>([]);
  const [selectedCryptoOnlyPlan, setSelectedCryptoOnlyPlan] = useState<string>();
  const [selectedFullFilingPlan, setSelectedFullFilingPlan] = useState<string>();
  const [selectedCryptoOnlyPlanYear, setSelectedCryptoOnlyPlanYear] = useState<number>();
  const [selectedFullFilingPlanYear, setSelectedFullFilingPlanYear] = useState<number>();

  const availablePlans = useContext(PlansContext);

  useEffect(() => {
    updateSelectedProducts({
      cryptoOnlyPlans,
      fullFilingPlans,
    });
  }, [cryptoOnlyPlans, fullFilingPlans, updateSelectedProducts]);

  if (!availablePlans) return <Spinner />;

  const configurations = [
    {
      options: availablePlans.cryptoOnlyPlans
        .filter(({ deprecated }) => !deprecated)
        .map(({ id }) => ({ value: id, label: id })),
      actions: cryptoOnlyActions,
      value: selectedCryptoOnlyPlan,
      year: selectedCryptoOnlyPlanYear,
      setter: setSelectedCryptoOnlyPlan,
      yearSetter: setSelectedCryptoOnlyPlanYear,
      list: cryptoOnlyPlans,
      listTitle: 'Crypto Plans',
    },
    {
      options: availablePlans.fullFilingPlans.map(({ id }) => ({ value: id, label: id })),
      actions: fullFilingActions,
      value: selectedFullFilingPlan,
      year: selectedFullFilingPlanYear,
      setter: setSelectedFullFilingPlan,
      yearSetter: setSelectedFullFilingPlanYear,
      list: fullFilingPlans,
      listTitle: 'Full Filing Plans',
    },
  ];

  return (
    <div>
      {configurations.map(({ options, actions, value, year, list, setter, yearSetter }, index) => {
        return (
          <div className="flex mb-2" key={index}>
            <Select<string>
              className="flex-shrink w-96 mr-4"
              options={options}
              value={options.find((option) => option.value === value)}
              onChange={({ value }) => {
                setter(value);
              }}
            />

            <Select<number>
              className="flex-shrink w-40 sm:w-96 mr-4"
              options={yearOptions}
              value={yearOptions.find((option) => option.value === year)}
              onChange={({ value }) => {
                yearSetter(value);
              }}
            />

            <Button
              className="px-2 flex-grow md:flex-grow-0 sm:px-8"
              disabled={!value || !year || list.map(({ year }) => year).includes(year)}
              onClick={() =>
                actions.push({
                  planId: value!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  year: year!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                })
              }
            >
              Add
            </Button>
          </div>
        );
      })}

      <div className="my-4" />

      {configurations.map(({ actions, list, listTitle }, index) => {
        return (
          list.length > 0 && (
            <div className="mt-4" key={index}>
              <div className="text-lg">{listTitle}</div>
              {list.map((value, innerIndex) => {
                const { planId, year } = value;
                return (
                  <div key={innerIndex}>
                    {planId} {year}
                    <TextLink className="ml-1" onClick={() => actions.removeAt(innerIndex)}>
                      Remove
                    </TextLink>
                  </div>
                );
              })}
            </div>
          )
        );
      })}
    </div>
  );
}

export default React.memo(InvoiceProducts);
