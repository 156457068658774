import { ReactiveVar } from '@apollo/client';
import { capitalize, uniq } from 'lodash';
import { TxnFilterQuery, TxnFragment } from '../../../graphql/types';
import { FiltersState } from '../../AllTransactions/types';
import { Values } from './types';

const properties = [
  'buyCurrency',
  'buyTokenId',
  'buyAddress',
  'buyPrice',
  'buyQuantity',
  'description',
  'exchangeName',
  'sellCurrency',
  'sellTokenId',
  'sellAddress',
  'sellPrice',
  'sellQuantity',
  'txnTimestamp',
  'txnType',
  'feeCurrency',
  'feeTokenId',
  'feeAddress',
  'feeQuantity',
  'feePrice',
  'accountId',
  'credentialId',
  'toAccountId',
  'bkpVendorId',
  'bkpAccountDebitId',
  'bkpAccountCreditId',
  'priceFetchingSide',
  'reviewed',
  'isSpam',
  'hasMovement',
] as const;

export const DIFFERENT_VALUES = '___DIFFERENT_VALUES___';
export const NEW_VALUES = '___NEW_VALUES___';
export const MULTIPLE_VALUES = '___MULTIPLE_VALUES___';

export const DIFFERENT_VALUES_PLACEHOLDER = 'Different Values';
export const NEW_VALUES_PLACEHOLDER = 'New Values';
export const MULTIPLE_VALUES_PLACEHOLDER = 'Multiple Values';

const placeholders = {
  [NEW_VALUES]: NEW_VALUES_PLACEHOLDER,
  [DIFFERENT_VALUES]: DIFFERENT_VALUES_PLACEHOLDER,
  [MULTIPLE_VALUES]: MULTIPLE_VALUES_PLACEHOLDER,
};

export type Value = string | number | undefined | null;

export const getDisplayedValueAndPlaceholder = (originalValue: Value) => {
  let value: Value = originalValue;
  if ([DIFFERENT_VALUES, NEW_VALUES, MULTIPLE_VALUES].includes(originalValue as string)) {
    value = undefined;
  }
  return { value, placeholder: placeholders[originalValue as keyof typeof placeholders] };
};

const getValueForProp = (txns: TxnFragment[], prop: keyof TxnFragment) => {
  const props = txns.map((txn) => {
    // default to credential.integrationId if txn.integrationId is not set
    if (prop === 'integrationId' && !txn.integrationId) {
      return txn.credential?.integrationId;
    }
    return txn[prop];
  });

  if (uniq(props).length > 1) {
    return DIFFERENT_VALUES;
  }

  // if all transactions have the same value for the prop, make that an initial value for the prop
  return props[0];
};

export const getInitialValues = (txns: TxnFragment[]): Values => {
  return properties.reduce((acc, prop) => {
    return {
      ...acc,
      [prop]: getValueForProp(txns, prop),
    };
  }, {} as Values);
};

export const defaultMultiPageBatchValues = Object.fromEntries(
  properties.map((prop) => [prop, MULTIPLE_VALUES]),
) as Record<(typeof properties)[number], typeof MULTIPLE_VALUES>;

const getCurrencyValuesFromFilters = (filters: TxnFilterQuery) => {
  const res = (['buy', 'sell', 'fee'] as const).reduce(
    (acc, type) => {
      if (!filters) return acc;
      const { currency: currencyFilters } = filters;
      if (!currencyFilters) return acc;
      const matchingFilters = currencyFilters.filter(({ side }) => side === `${capitalize(type)}Currency`);
      if (matchingFilters.length !== 1) return acc;
      const currencyFilter = matchingFilters[0]!;
      if (currencyFilter.operator !== 'Is') return acc;
      const { symbols } = currencyFilter;
      if (symbols.length !== 1) return acc;
      return {
        ...acc,
        [`${type}Currency`]: symbols[0],
      };
    },
    {} as Partial<Record<`${'buy' | 'sell' | 'fee'}Currency`, string>>,
  );
  if (Object.keys(res).length > 1) {
    // the API composes these filters with OR, not AND
    // so if more than one is set, they may not have a common value
    return {};
  }
  return res;
};

const getTxnTypeValueFromFilters = (filters: TxnFilterQuery) => {
  if (!filters) return {};
  const { txnType: txnTypeFilter } = filters;
  if (!txnTypeFilter) return {};
  if (txnTypeFilter.operator !== 'Is') return {};
  const { values } = txnTypeFilter;
  if (values.length !== 1) return {};
  return { txnType: values[0] } as Record<'txnType', string>;
};

export const getInitialValuesForBatchFromFilters = ({
  filtersVar,
}: {
  filtersVar?: ReactiveVar<FiltersState>;
}) => {
  if (!filtersVar) return {};
  const filtersState = filtersVar();
  const filters = JSON.parse(filtersState.filterQuery || '{}') as TxnFilterQuery;

  return {
    ...getCurrencyValuesFromFilters(filters),
    ...getTxnTypeValueFromFilters(filters),
  };
};
