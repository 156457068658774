import { makeVar } from '@apollo/client';
import { PaginationState } from '../utils/pagination';

import {
  defaultMultiSelectState,
  defaultFiltersState as emptyState,
  getDefaultPaginationOptions,
} from './defaults';

import { MultiSelectState, SortState } from './types';

interface Props<SortOptions, Filters> {
  defaultSortState: SortState<SortOptions>;
  defaultFiltersState?: Filters;
  defaultHiddenColumns: string[];
  pageSize: number;
}

export const buildVars = <SortOptions, Filters, IdType extends string | number>({
  defaultSortState,
  defaultHiddenColumns,
  pageSize,
  defaultFiltersState,
}: Props<SortOptions, Filters>) => ({
  sortVar: makeVar<SortState<SortOptions>>(defaultSortState),
  paginationVar: makeVar<PaginationState>(getDefaultPaginationOptions(pageSize)),
  filtersVar: makeVar<Filters>(defaultFiltersState ?? (emptyState as Filters)),
  multiSelectVar: makeVar<MultiSelectState<IdType>>(defaultMultiSelectState),
  initialLoadingVar: makeVar<boolean>(true),
  hiddenColumnsVar: makeVar<string[] | undefined>(
    defaultHiddenColumns.length > 0 ? defaultHiddenColumns : undefined,
  ),
});
