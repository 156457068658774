import tailwindConfig from '../../../../tailwind.config';
import { Theme } from '../../../theme/useTheme';
import { Styles } from '../types';
import { getBaseStyles } from './base';

const { textColor } = tailwindConfig.theme.extend;

export function getTitleMediumStyles<Option>(theme: Theme): Styles<Option> {
  return {
    ...getBaseStyles(theme),
    container: (baseStyles) => ({
      ...baseStyles,
      borderWidth: 'none',
      boxShadow: 'none',
      ringWidth: 'none',
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    dropdownIndicator: (baseStyles) => {
      return {
        ...baseStyles,
        color: textColor[`${theme}-base`],
      };
    },
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: textColor[`${theme}-base`],
      fontSize: '1.875rem', // "3xl" tailwind font size
      fontWeight: 300,
      padding: '0',
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      width: '100%',
      padding: '0',
    }),
  };
}
