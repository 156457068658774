import { gql, useQuery } from '@apollo/client';
import { ACCOUNT } from '../fragments/Account';
import { AllAccountsQuery } from '../types';

export const ALL_ACCOUNTS = gql`
  query AllAccounts {
    accounts {
      edges {
        ...Account
      }
    }
  }
  ${ACCOUNT}
`;

export function useAllAccounts() {
  const res = useQuery<AllAccountsQuery>(ALL_ACCOUNTS, {
    fetchPolicy: 'cache-and-network',
  });

  return { ...res, data: res.data?.accounts.edges };
}
