const DELETE_TXN_STORAGE_KEY = 'doNotAskAgainToConfirmTxnDeletion';

export const doNotAskAgainToConfirmTxnDeletion = () =>
  window.localStorage.setItem(DELETE_TXN_STORAGE_KEY, 'true');

export const shouldAskToConfirmTxnDeletion = () => {
  return window.localStorage.getItem(DELETE_TXN_STORAGE_KEY) !== 'true';
};

export const MERGE_TXNS_SESSION_STORAGE_KEY = 'doNotAskAgainToConfirmMergeTxns';

export const doNotAskAgainToConfirmMergeTxns = () =>
  window.localStorage.setItem(MERGE_TXNS_SESSION_STORAGE_KEY, 'true');

export const shouldAskToConfirmMergeTxns = () => {
  return window.localStorage.getItem(MERGE_TXNS_SESSION_STORAGE_KEY) !== 'true';
};
