import { sortBy } from 'lodash';
import { useGetEvmGuideInstruction, useGetSolanaGuideInstruction } from '../../graphql/queries';
import { useGetIbcGuideInstruction } from '../../graphql/queries/ibcGuide';
import { TreatmentsQuery } from '../../graphql/types';
import { ModalProps } from '../Modal';
import {
  useEvmGuideEditInstruction,
  useEvmGuideNewInstruction,
  useIbcGuideEditInstruction,
  useIbcGuideNewInstruction,
  useSolanaGuideEditInstruction,
  useSolanaGuideNewInstruction,
} from './mutations';

export type EvmGuideContributionModalProps = {
  type: 'EVM';
  methodId: string;
  contractAddress: string;
  chain: string;
} & ModalProps;

export type IdentifierBasedContributionModalProps = {
  identifier: string;
  transactionHash: string;
} & ModalProps;

export type SolanaGuideContributionModalProps = {
  type: 'Solana';
} & IdentifierBasedContributionModalProps;

export type IbcGuideContributionModalProps = {
  type: 'IBC';
  credentialType: string;
} & IdentifierBasedContributionModalProps;

export type ReconGuideContributionModalProps =
  | EvmGuideContributionModalProps
  | SolanaGuideContributionModalProps
  | IbcGuideContributionModalProps;

export const treatmentOptions = (treatmentChainData?: TreatmentsQuery) =>
  sortBy(treatmentChainData?.reconGuideTreatments, 'reconOrder').map((t) => ({
    value: t.name,
    label: t.name,
  }));

export const option = (value: string | null) => ({ value, label: value ?? 'None' });

export const isEvm = (p: ReconGuideContributionModalProps): p is EvmGuideContributionModalProps =>
  p.type === 'EVM';
export const isSolana = (p: ReconGuideContributionModalProps): p is SolanaGuideContributionModalProps =>
  p.type === 'Solana';
export const isIbc = (p: ReconGuideContributionModalProps): p is IbcGuideContributionModalProps =>
  p.type === 'IBC';

export const useReconGuideInstructionsQuery = (props: ReconGuideContributionModalProps) => {
  if (!isEvm(props) && !isSolana(props) && !isIbc(props)) {
    throw new Error('Invalid type in useGuideInstructionsQuery!');
  }

  const { data: evmInstructionsData, loading: evmInstructionsLoading } = useGetEvmGuideInstruction({
    variables: isEvm(props)
      ? {
          methodId: props.methodId,
          chain: props.chain,
          contractAddress: props.contractAddress,
        }
      : undefined,
    fetchPolicy: 'cache-and-network',
    skip: !isEvm(props),
  });

  const { data: solanaInstructionsData, loading: solanaInstructionsLoading } = useGetSolanaGuideInstruction({
    variables: isSolana(props)
      ? {
          identifier: props.identifier,
        }
      : undefined,
    fetchPolicy: 'cache-and-network',
    skip: !isSolana(props),
  });

  const { data: ibcInstructionsData, loading: ibcInstructionsLoading } = useGetIbcGuideInstruction({
    variables: isIbc(props)
      ? {
          identifier: props.identifier,
        }
      : undefined,
    fetchPolicy: 'cache-and-network',
    skip: !isIbc(props),
  });

  const instructionsData = isEvm(props)
    ? evmInstructionsData?.evmGuideInstruction
    : isSolana(props)
    ? solanaInstructionsData?.solanaGuideInstruction
    : isIbc(props)
    ? ibcInstructionsData?.ibcGuideInstruction
    : undefined;

  const instructionsLoading = isEvm(props)
    ? evmInstructionsLoading
    : isSolana(props)
    ? solanaInstructionsLoading
    : isIbc(props)
    ? ibcInstructionsLoading
    : false;

  return { instructionsData, instructionsLoading };
};

export const useReconGuideInstructionsMutation = (props: ReconGuideContributionModalProps) => {
  const [editEvmInstruction, { loading: submitEvmEditLoading, error: submitEvmEditError }] =
    useEvmGuideEditInstruction();
  const [editSolanaInstruction, { loading: submitSolanaEditLoading, error: submitSolanaEditError }] =
    useSolanaGuideEditInstruction();
  const [editIbcInstruction, { loading: submitIbcEditLoading, error: submitIbcEditError }] =
    useIbcGuideEditInstruction();

  const [addEvmInstruction, { loading: submitEvmNewLoading, error: submitEvmNewError }] =
    useEvmGuideNewInstruction();
  const [addSolanaInstruction, { loading: submitSolanaNewLoading, error: submitSolanaNewError }] =
    useSolanaGuideNewInstruction();
  const [addIbcInstruction, { loading: submitIbcNewLoading, error: submitIbcNewError }] =
    useIbcGuideNewInstruction();

  const editInstruction = isEvm(props)
    ? editEvmInstruction
    : isSolana(props)
    ? editSolanaInstruction
    : isIbc(props)
    ? editIbcInstruction
    : undefined;

  if (!editInstruction) throw new Error('Invalid type in useGuideMutations edit instruction!');

  const addInstruction = isEvm(props)
    ? addEvmInstruction
    : isSolana(props)
    ? addSolanaInstruction
    : isIbc(props)
    ? addIbcInstruction
    : undefined;

  if (!addInstruction) throw new Error('Invalid type in useGuideMutations add instruction!');

  const submitEditLoading = isEvm(props)
    ? submitEvmEditLoading
    : isSolana(props)
    ? submitSolanaEditLoading
    : isIbc(props)
    ? submitIbcEditLoading
    : false;

  const submitEditError = isEvm(props)
    ? submitEvmEditError
    : isSolana(props)
    ? submitSolanaEditError
    : isIbc(props)
    ? submitIbcEditError
    : undefined;

  const submitNewLoading = isEvm(props)
    ? submitEvmNewLoading
    : isSolana(props)
    ? submitSolanaNewLoading
    : isIbc(props)
    ? submitIbcNewLoading
    : false;

  const submitNewError = isEvm(props)
    ? submitEvmNewError
    : isSolana(props)
    ? submitSolanaNewError
    : isIbc(props)
    ? submitIbcNewError
    : undefined;

  return {
    editInstruction,
    addInstruction,
    submitEditLoading,
    submitEditError,
    submitNewLoading,
    submitNewError,
  };
};
