import cx from 'classnames';
import React from 'react';
import { InvoiceQuery } from '../../graphql/types';
import LineItem from './LineItem';

type Line = InvoiceQuery['invoice']['lines'][number];

interface Props {
  lines: Line[];
  className?: string;
}

function InvoiceLineItems({ lines, className }: Props) {
  return (
    <div
      className={cx('mt-2', className)}
      style={{
        display: 'grid',
        gridTemplateColumns: `6fr 1fr 2fr 2fr`,
        gridColumnGap: '5px',
      }}
    >
      <div>Item</div>
      <div className="text-right">Qty</div>
      <div className="text-right">Unit Price</div>
      <div className="text-right">Price</div>

      {lines.map((line) => (
        <LineItem key={line.id} line={line} />
      ))}
    </div>
  );
}

export default React.memo(InvoiceLineItems);
