import React, { useContext } from 'react';
import { UserContext } from '../../contexts';
import { useLatestTxnReports } from '../../graphql/queries';
import Page from '../Layout/Page';
import TaxDashboard from './index';

const Container = () => {
  const { user, refetch: refetchUser } = useContext(UserContext);
  const { loading, data, refetch } = useLatestTxnReports();

  const { latestTxnReportsByYear: reports, hasData = true } = data?.latestTxnReports ?? {};

  return (
    <Page>
      <TaxDashboard
        hasData={hasData}
        reports={reports}
        reportsLoading={loading}
        refetch={refetch}
        refetchUser={refetchUser}
        user={user}
      />
    </Page>
  );
};

export default Container;
