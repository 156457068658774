import { useCallback } from 'react';

import { actions } from '../actions';
import { showDuplicateTransactionModal } from '../DuplicateModal';
import { AllTransactionsContextType } from './context';

export const useOnDuplicate = ({ refetch }: Required<Pick<AllTransactionsContextType, 'refetch'>>) => {
  const onDuplicate = useCallback(
    (ids: string[]) => {
      showDuplicateTransactionModal({
        txnIds: ids,
        onTxnDuplicated: () => {
          actions.deselect(ids);
          refetch?.();
        },
      });
    },
    [refetch],
  );

  return onDuplicate;
};
