import { QueryHookOptions, gql, useMutation, useQuery } from '@apollo/client';
import {
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  UserQuery,
  UserQueryVariables,
} from '../../graphql/types';

const USER = gql`
  query User($userId: Int!) {
    user(userId: $userId) {
      firstName
      lastName
      email
    }
  }
`;

export const useUser = (options: QueryHookOptions<UserQuery, UserQueryVariables>) => {
  const { data, ...res } = useQuery<UserQuery, UserQueryVariables>(USER, options);

  return {
    ...res,
    ...(data?.user
      ? {
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          email: data.user.email,
        }
      : {}),
  };
};

const CREATE_INVOICE = gql`
  mutation CreateInvoice(
    $userId: Int
    $newUserEmail: String
    $description: String
    $dueDate: Int!
    $lines: [InvoiceLine!]!
    $products: InvoiceProductsInput!
    $hideDefaultCrypto: Boolean
  ) {
    createInvoice(
      userId: $userId
      newUserEmail: $newUserEmail
      description: $description
      dueDate: $dueDate
      lines: $lines
      products: $products
      hideDefaultCrypto: $hideDefaultCrypto
    )
  }
`;

export const useCreateInvoice = () => {
  return useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CREATE_INVOICE);
};
