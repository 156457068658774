import React from 'react';
import cx from 'classnames';

import pluralize from '../../../../lib/pluralize';
import { textVariantClassnames } from '../../../Text';

interface Props {
  isCollapsed: boolean;
  selectedCount: number;
  onClick: () => void;
}

function Line({ double }: { double: boolean }) {
  return (
    <div className="flex flex-col flex-grow justify-evenly">
      <div
        className={cx(
          `border-light-control dark:border-dark-control border-t`,
          double ? 'border-b h-1' : 'h-px',
        )}
      />
    </div>
  );
}

function Triangle({ isCollapsed }: { isCollapsed: boolean }) {
  return (
    <div
      className="flex self-center h-0 w-0 ml-2 border-light-control dark:border-dark-control"
      style={{
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        [isCollapsed ? 'borderTop' : 'borderBottom']: '4px solid',
      }}
    />
  );
}

function Separator({ isCollapsed, selectedCount, onClick }: Props) {
  if (selectedCount === 0) return null;

  return (
    <div className="flex cursor-pointer px-2" {...{ onClick }}>
      <Line double={isCollapsed} />
      <div className={cx('px-2 flex flex-row', textVariantClassnames.muted)}>
        {isCollapsed ? `${selectedCount} Selected ${pluralize(selectedCount, 'Import')}` : 'Hide'}
        <Triangle {...{ isCollapsed }} />
      </div>
      <Line double={isCollapsed} />
    </div>
  );
}

export default React.memo(Separator);
