import React, { useState } from 'react';
import cx from 'classnames';

import Spinner from '../Spinner';

type Color = 'red' | 'green' | 'orange' | 'yellow';
export type ToastType = 'warning' | 'error' | 'success';

interface ToastProps {
  children?: React.ReactNode;
  className?: string;
  color?: Color;
  type?: ToastType;
  onHide?: () => void;
  canHideSelf?: boolean;
  margin?: string; // optional tailwind class giving the toast a margin
}

export const toastBackgroundClasses = {
  critical: 'bg-light-alert-critical dark:bg-dark-alert-critical',
  success: 'bg-light-alert-success dark:bg-dark-alert-success',
  warning: 'bg-light-alert-warning dark:bg-dark-alert-warning',
  info: 'bg-light-shade dark:bg-dark-shade',
};

export function SuccessToast({ children, ...restProps }: ToastProps) {
  return (
    <Toast color="green" {...restProps}>
      {children}
    </Toast>
  );
}

export function LoadingToast({ children }: ToastProps) {
  return (
    <Toast>
      <span className="mr-3" style={{ position: 'relative', top: '-1px' }}>
        <Spinner size="sm" />
      </span>
      <span>{children}</span>
    </Toast>
  );
}

export function ErrorToast({ children, ...restProps }: ToastProps) {
  return (
    <Toast color="red" {...restProps}>
      {children}
    </Toast>
  );
}

export function WarningToast({ children, ...restProps }: ToastProps) {
  return (
    <Toast color="orange" {...restProps}>
      {children}
    </Toast>
  );
}

const getBackgroundClassForColor = (color?: Color) => {
  switch (color) {
    case 'red':
      return toastBackgroundClasses.critical;
    case 'green':
      return toastBackgroundClasses.success;
    case 'orange':
    case 'yellow':
      return toastBackgroundClasses.warning;
  }
};

const getColorForType = (type?: ToastType) => {
  switch (type) {
    case 'error':
      return 'red';
    case 'success':
      return 'green';
    case 'warning':
      return 'orange';
  }
};

function Toast({ className, children, color, onHide, canHideSelf, type, margin = 'mb-6' }: ToastProps) {
  const [hidden, setHidden] = useState(false);
  if (hidden) return null;

  const bgClass = getBackgroundClassForColor(color ?? getColorForType(type)) ?? toastBackgroundClasses.info;

  const classes = cx(
    bgClass,
    'rounded border border-light-alert dark:border-dark-alert py-2 px-4 flex flex-row justify-evenly items-center',
    margin,
    className,
  );

  return (
    <div className={classes} data-testid={`Toast-${type}`}>
      <div className="text-left flex-auto" role="status">
        {children}
      </div>
      {(onHide || canHideSelf) && (
        <div
          className="ml-4 cursor-pointer text-right flex-auto hover:opacity-75"
          onClick={() => {
            if (onHide) onHide();
            if (canHideSelf) setHidden(true);
          }}
        >
          Hide
        </div>
      )}
    </div>
  );
}

export default React.memo(Toast);
