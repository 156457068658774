import React, { useCallback } from 'react';
import Button from '../../Button';
import Modal, { ModalProps, showModal } from '../../Modal';

interface Props extends ModalProps {
  onConfirm?: () => Promise<void>;
}

function CloseSpecIdPanelDialog({ onClose, onConfirm }: Props) {
  return (
    <Modal title="Close and Discard Spec ID selections" onClose={onClose}>
      <div className="mb-8">
        Are you sure you would like to exit the Spec ID panel without saving? Any selections you have made
        will be lost.
      </div>
      <Button className="mr-2" onClick={onClose}>
        Cancel
      </Button>

      <Button
        variant="secondary"
        onClick={useCallback(async () => {
          onClose?.();
          await onConfirm?.();
        }, [onClose, onConfirm])}
      >
        Exit Without Saving
      </Button>
    </Modal>
  );
}

const CloseSpecIdPanelDialogMemo = React.memo(CloseSpecIdPanelDialog);

export async function showCloseSpecIdPanelDialog(props: Props = {}) {
  return new Promise<void>((resolve) => {
    showModal(
      <CloseSpecIdPanelDialogMemo
        {...props}
        onConfirm={async () => {
          await props.onConfirm?.();
          resolve();
        }}
      />,
    );
  });
}
