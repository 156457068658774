import React from 'react';
import PercentFormat from 'react-number-format';
import { launchIntercom } from '../../../utils/launchIntercom';

import { TaxLiabilityProps } from './types';

function TaxLiabilityTooltipContent({ shortTermRate, longTermRate }: TaxLiabilityProps) {
  return (
    <p className="px-2">
      Your Tax Rate is{' '}
      <PercentFormat
        suffix="%"
        decimalScale={1}
        displayType="text"
        value={longTermRate && longTermRate * 100}
      />{' '}
      on long-term capital gains and{' '}
      <PercentFormat
        suffix="%"
        decimalScale={1}
        displayType="text"
        value={shortTermRate && shortTermRate * 100}
      />{' '}
      on short term gains. These numbers consider federal and state/local taxes. <br />
      <br />
      Estimated tax liability has been determined by calculating your short- and long-term capital gain tax
      rates based on the filing status, location, and tax bracket info you provided. For more details,{' '}
      <span className="cursor-pointer underline" onClick={launchIntercom}>
        contact our tax advisors
      </span>
      .
    </p>
  );
}

export default React.memo(TaxLiabilityTooltipContent);
