import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { DateRangeFilter } from '../../../graphql/types';
import Dropdown, { DropdownHandle } from '../../Dropdown';
import PillBody from '../PillBody';
import { BasePillProps } from '../types';
import DateRangeInput from './DateRangeInput';

export type DateRangePillProps = BasePillProps<DateRangeFilter>;

const DateRangePill: React.FC<DateRangePillProps> = ({ label, filter, onChange, onDelete }) => {
  const [open, setOpen] = React.useState(false);
  const dropdownValuesRef = React.useRef<DropdownHandle>(null);

  React.useEffect(() => {
    dropdownValuesRef.current?.hide();
  }, [filter]);

  const startDate = filter?.fromDate as string;
  const endDate = filter?.toDate as string;

  const startDateStr = startDate ? moment.utc(startDate).format('MM/DD/YYYY') : null;
  const endDateStr = endDate ? moment.utc(endDate).format('MM/DD/YYYY') : null;
  let appliedSelection = `${startDateStr} - ${endDateStr}`;
  if (!startDateStr) appliedSelection = `Before ${endDateStr}`;
  if (!endDate) appliedSelection = `After ${startDateStr}`;

  return (
    <PillBody label={label} onDelete={onDelete}>
      <div
        className={classNames('hover:bg-light-selected-faint dark:hover:bg-dark-selected-faint', {
          'bg-light-selected-faint dark:bg-dark-selected-faint': open,
        })}
      >
        <Dropdown
          isDisabled={false}
          label={appliedSelection}
          ref={dropdownValuesRef}
          onShow={() => setOpen(true)}
          onHide={() => setOpen(false)}
          noStyles
        >
          {open && <DateRangeInput {...{ label, filter, onChange }} />}
        </Dropdown>
      </div>
    </PillBody>
  );
};

export default React.memo(DateRangePill);
