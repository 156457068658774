import { useMemo } from 'react';
import Auth from '../../lib/Auth';
import type { NavItem } from './types';

type Props = {
  isAdminPage: boolean;
};

export const useActionItems = ({ isAdminPage }: Props) => {
  const actionItems = useMemo(() => {
    const items: NavItem[] = [
      {
        name: 'Sign out',
        onClick: Auth.logout,
      },
    ];

    if (!isAdminPage) {
      items.unshift({
        name: 'FAQ',
        path: 'https://help.tokentax.co/en/',
      });
    }

    return items;
  }, [isAdminPage]);

  return actionItems;
};
