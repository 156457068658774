import { ApolloQueryResult } from '@apollo/client';
import React from 'react';
import { EnumGenericJobStatus, FetchUserQuery, TaxMethod } from '../graphql/types';

type FetchedUser = NonNullable<FetchUserQuery['user']>;

export type User = Omit<FetchedUser, '__typename'> | null;

export interface UserContextType {
  isHijacked?: boolean;
  isTokenTaxAdmin?: boolean;
  refetch?: () => Promise<ApolloQueryResult<FetchUserQuery>>;
  //user is the user that is actually being controlled if you are hijacked it will be the hijacked user
  user?: User;
  isLoggedIn?: boolean;
  taxMethods?: TaxMethod[];
  roles?: string[];
  currentUserRole?: string;
  txnEditingIsLocked?: boolean;
  latestBatchUpdateTxnsJobStatus?: EnumGenericJobStatus | null;
  setPollLatestBatchUpdateJobStatus?: (poll: boolean) => void;
  latestBatchDeleteTxnsJobStatus?: EnumGenericJobStatus | null;
  setPollLatestBatchDeleteJobStatus?: (poll: boolean) => void;
  highestPlan?: string;
  organizationId?: number | null;
}

export const UserContext = React.createContext<UserContextType>({});
UserContext.displayName = 'UserContext';

export const useUserContext = () => React.useContext(UserContext);
