import React, { useMemo } from 'react';
import { useSortBy, useTable, TableState } from 'react-table';
export { Cell } from 'react-table';

import Card from '../Card/';
import { createTableContext } from './context';
import { StaticTableProps } from './types';
import { useObject } from '../../lib/hooks';

function StaticTable<T extends Record<string, unknown>>(props: StaticTableProps<T>): React.ReactElement {
  const {
    data,
    columns,
    baseCurrency,
    defaultSortedId,
    defaultSortedDirection,
    children,
    gridGapSize = '12px',
    gridTemplateColumns: gridTemplateColumnsProp,
    rowClassName,
    headerClassName,
    minWidth,
    paddingLeft,
    paddingRight,
  } = props;

  const gridTemplateColumns =
    gridTemplateColumnsProp ||
    columns
      .map(({ width, min, max }) => {
        if (min || max) return `minmax(${min ?? '0px'}, ${max ?? '1fr'})`;
        return width ?? 'minmax(0, 1fr)';
      })
      .join(' ');

  const initialSorted = useMemo(() => defaultSortedId, [defaultSortedId]);
  const initialState = useMemo(
    () => ({
      sortBy: initialSorted ? [{ id: initialSorted, desc: defaultSortedDirection === 'desc' }] : [],
    }),
    [initialSorted, defaultSortedDirection],
  );

  const gridStyle = useObject({
    gridTemplateColumns,
    display: 'grid',
    columnGap: gridGapSize,
    // `columnGap` applies to space between columns only, so this adds matching padding for the outer columns
    paddingLeft: paddingLeft ?? gridGapSize,
    paddingRight: paddingRight ?? gridGapSize,
  });

  const instance = useTable<T>(
    {
      columns,
      data,
      initialState: initialState as TableState<T>,
      // there's a mismatch between react-table and @types/react-table about the following prop
      // the usage here is correct https://react-table.tanstack.com/docs/faq#how-do-i-stop-my-table-state-from-automatically-resetting-when-my-data-changes
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      autoResetSortBy: false,
    },
    useSortBy,
  );

  const { getTableProps } = instance;

  const TableContext = createTableContext();

  return (
    <TableContext.Provider
      value={{
        instance,
        tableType: 'static',
        baseCurrency,
        gridStyle,
        rowClassName,
        headerClassName,
      }}
    >
      <Card className="tt_table overflow-x-auto xl:overflow-x-visible" padding="p-0">
        <div {...getTableProps()} className="w-full" style={{ minWidth }}>
          {children}
        </div>
      </Card>
    </TableContext.Provider>
  );
}

export default React.memo(StaticTable) as unknown as typeof StaticTable;
