import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { capitalize, truncate } from 'lodash';
import moment, { MomentInput } from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext, useUserContext } from '../../../contexts/UserContext';
import { TXN } from '../../../graphql/fragments';
import {
  ExpandedRowQuery,
  ExpandedRowQueryVariables,
  LineItemsQuery,
  SortDirectionOptions,
  TxnType,
} from '../../../graphql/types';
import { buildCurrencyTickerFilterUrlParam } from '../../AllTransactions/Table/utils';
import { actions as allTxnsActions } from '../../AllTransactions/actions';
import Modal, { ModalWidths } from '../../Modal';
import NumberFormat from '../../NumberFormat/index';
import Spinner from '../../Spinner/index';
import { Body, Header, StaticTable } from '../../Table';
import { ExpandedRowProps } from '../../Table/Body';
import { useTableContext } from '../../Table/context';
import Text, { textVariantClassnames } from '../../Text';
import TextLink from '../../TextLink';
import Tooltip from '../../Tooltip/index';
// import {
//   expandedRow,
//   expandedRowVariables,
//   expandedRow_lineItemDetails,
//   expandedRow_lineItemDetails_exitDetail,
//   expandedRow_lineItemDetails_migrationDetail,
//   expandedRow_lineItemDetails_missingCostBasisDetail,
//   expandedRow_lineItemDetails_originDetail,
// } from './@types/expandedRow';
import Auth from '../../../lib/Auth';
import { useIntegrationsContext } from '../../../providers/IntegrationsProvider';
import { getMigrationTableColumns } from './columns';
import { LineItemData } from './types';

type Txn = NonNullable<LineItemsQuery['lineItems']['edges'][number]['txnLineItemSellIdTotxn']>;

const AUDIT_DETAIL = gql`
  fragment AuditDetail on LineItemAuditDetail {
    txn {
      ...Txn
    }
    date
    txnType
    location
    soldCurrency
    boughtCurrency
    feeCurrency
    soldAmount
    boughtAmount
    feeAmount
    price
  }
  ${TXN}
`;

const EXPANDED_ROW_DATA = gql`
  query expandedRow($lineItemId: BigInt!, $isAdmin: Boolean!) {
    lineItemDetails(lineItemDetailInput: { lineItemId: $lineItemId }) {
      missingCostBasis
      term
      averageCost
      originDetail {
        ...AuditDetail
      }
      migrationDetail {
        ...AuditDetail
      }
      exitDetail {
        ...AuditDetail
      }
      missingCostBasisDetail {
        missingCurrency
        missingAmount
      }
      soldAmountFlat
      costBasis
      costBasisFlat
      costBasisFromSellFee
      costBasisFeeAddition
      costBasisFeeAdditionUnits
      costBasisFeeAdditionCurrency
      proceeds
      proceedsFlat
      proceedsFromSellFee
      proceedsFeeDeduction
      proceedsFeeDeductionUnits
      proceedsFeeDeductionCurrency
      gainLoss
      bnbMatchType
      specIdMatch
      lineItemTrace {
        id
        buyId
        sellId
        sellCurrency
        buyCurrency
        unitsSold
        buyDate
        sellDate
        proceedsIncludingFees
        costBasisIncludingFees
        gainLossIncludingFees
        term
        missingCostBasis
        splitBuyId
        txnLineItemSellIdTotxn {
          ...Txn
        }
      }
    }
  }
  ${AUDIT_DETAIL}
  ${TXN}
`;

type AuditDetailValueSectionProps = {
  detailType: 'acquisition' | 'sale';
  txnType: string;
  location: string | null;
  price: number;
  feeCurrency: string;
  feeAmount: number;
  renderNumericValue: (value: number, currency: string | undefined, compact?: true) => React.ReactNode;
  isFee: boolean;
  integrationId: string | null | undefined;
};

const AuditDetailValueSection: React.FC<AuditDetailValueSectionProps> = ({
  detailType,
  txnType,
  location,
  price,
  feeCurrency,
  feeAmount,
  renderNumericValue,
  isFee,
  integrationId,
}) => {
  const { baseCurrency } = useTableContext();
  const { allIntegrations } = useIntegrationsContext();

  const locationOrIntegration =
    allIntegrations.find((integration) => integration.id === integrationId)?.name || location;
  return (
    <div className="flex mt-6 flex-col">
      <div className="flex truncate">
        <div className="w-1/2">{detailType === 'acquisition' ? 'Acquired' : 'Sold'} via</div>
        <div className="self-end w-1/2 truncate">{isFee ? 'Fee' : capitalize(txnType)}</div>
      </div>
      <div className="flex ">
        <div className="w-1/2">Location</div>
        <div className="self-end w-1/2 truncate">{locationOrIntegration || '-'}</div>
      </div>
      <div className="flex">
        <div className="w-1/2">Price</div>
        <div className="self-end w-1/2 truncate">{renderNumericValue(price, baseCurrency)}</div>
      </div>
      {/* only display fee section if the lineItem isn't a fee itself */}
      {!isFee && feeCurrency && feeAmount && (
        <div className="flex">
          <div className="w-1/2">Fee</div>
          <div className="self-end w-1/2 whitespace-nowrap">
            {renderNumericValue(feeAmount, feeCurrency)} {feeCurrency}
          </div>
        </div>
      )}
    </div>
  );
};

type HiddenMigrationIndicatorProps = {
  hiddenMigrationCount: number;
  isModal: boolean;
  showModal: () => void;
};

const HiddenMigrationIndicator: React.FC<HiddenMigrationIndicatorProps> = ({
  hiddenMigrationCount,
  showModal,
}) => {
  return (
    <div className="w-4 ml-8 relative">
      <div className="h-full absolute cursor-pointer" style={{ width: 10 }} onClick={showModal}>
        <div className="absolute left-0 top-0 bottom-0 border-r" style={{ width: 1 }} />
        <div className="absolute top-0 bottom-0 border-r" style={{ width: 1, left: 4 }} />
        <div className="absolute top-0 bottom-0 border-r" style={{ width: 1, left: 8 }} />
        <div
          className={`absolute transform -translate-y-1/2 -translate-x-1/2 rotate-90 whitespace-nowrap px-2`}
          style={{ top: '50%', left: '50%' }}
        >
          <Text variant="muted">
            {hiddenMigrationCount} Txn{hiddenMigrationCount > 1 ? 's' : ''}
          </Text>
        </div>
      </div>
    </div>
  );
};

const RightArrow: React.FC = () => {
  return (
    <div className="w-8 ml-8 relative">
      <div
        className="absolute transform -translate-y-1/2 -translate-x-1/2"
        style={{ top: '50%', left: '50%' }}
      >
        <span className={classNames('material-icons', textVariantClassnames.muted)}>arrow_forward_ios</span>
      </div>
    </div>
  );
};

const formatDate = (date: unknown | null, compact?: true): { date: string; datetime: string } | undefined => {
  if (date === null) {
    return undefined;
  }
  const dateToMoment = moment(date as MomentInput);
  if (dateToMoment.isValid()) {
    let dateStr = dateToMoment.format('MM/DD/YYYY');
    if (compact) {
      dateStr = dateToMoment.format('MM/DD');
    }
    const datetimeStr = dateToMoment.format('MM/DD/YYYY h:mm A');
    return { date: dateStr, datetime: datetimeStr };
  }
  return undefined;
};

type OriginDetailProps = {
  originDetail: NonNullable<ExpandedRowQuery['lineItemDetails']['originDetail']>;
  hiddenMigrationCount: number;
  isModal: boolean;
  renderNumericValue: (value: number, currency: string | undefined, compact?: true) => React.ReactNode;
  onEditTxn?: (txn: Txn) => void;
  bnbMatchType?: string | null;
  specIdMatch: boolean;
  sellId: string;
};

const OriginDetail: React.FC<OriginDetailProps> = ({
  originDetail,
  renderNumericValue,
  onEditTxn,
  bnbMatchType,
  specIdMatch,
  sellId,
}) => {
  const history = useHistory();
  const { isTokenTaxAdmin } = useContext(UserContext);

  let originDate = '';
  let originDatetime = '';
  const formatResult = formatDate(originDetail.date);
  if (formatResult) {
    ({ date: originDate, datetime: originDatetime } = formatResult);
  }

  const openSpecId = useCallback(() => {
    if (!isTokenTaxAdmin) return;
    history.push('/all-transactions');
    allTxnsActions.setSpecIdTxn(sellId);
  }, [isTokenTaxAdmin, history, sellId]);

  return (
    <div className="origin-info flex flex-col relative" style={{ minWidth: 200, maxWidth: 260 }}>
      <div>
        <span className="font-bold">{originDetail.boughtCurrency} Origin</span>

        {specIdMatch && (
          <span className={classNames('inline-flex')}>
            <Tooltip appendTo={document.body} interactive icon="chain" place="top">
              The cost basis for this taxable event has been manually set with specific identification
              <br />
              {isTokenTaxAdmin && <TextLink onClick={openSpecId}>View Specific ID link</TextLink>}
            </Tooltip>
          </span>
        )}
      </div>
      <div>
        <Text variant="muted">
          Acquired on <span title={originDatetime}>{originDate}</span>
          {bnbMatchType && <> ({bnbMatchType === 'same_day_rule' ? 'Same day rule' : '30-day rule'})</>}
        </Text>
      </div>
      <div>
        <TextLink disabled={!onEditTxn} onClick={() => onEditTxn?.(originDetail?.txn)}>
          See Transaction
        </TextLink>
      </div>
      <AuditDetailValueSection
        detailType="acquisition"
        txnType={originDetail.txnType}
        location={originDetail.location}
        price={originDetail.price}
        feeCurrency={originDetail.feeCurrency}
        feeAmount={originDetail.feeAmount}
        integrationId={originDetail.txn.integrationId}
        renderNumericValue={renderNumericValue}
        isFee={false}
      />
    </div>
  );
};

type AverageCostOriginDetailProps = {
  originDetail: NonNullable<ExpandedRowQuery['lineItemDetails']['originDetail']>;
  averageCost: number;
  renderNumericValue: (value: number, currency: string | undefined, compact?: true) => React.ReactNode;
};

const AverageCostOriginDetail: React.FC<AverageCostOriginDetailProps> = ({
  originDetail,
  averageCost,
  renderNumericValue,
}) => {
  const { baseCurrency } = useTableContext();
  return (
    <div className="origin-info flex flex-grow flex-col relative" style={{ maxWidth: 220 }}>
      <div className="font-bold">{originDetail.boughtCurrency} Origin</div>
      <div>
        <Text variant="muted">
          Your {originDetail.boughtCurrency} acquisitions for the year have an average cost of{' '}
          {renderNumericValue(averageCost, baseCurrency)}
        </Text>
      </div>
      <div className="mt-4">
        <TextLink
          to={`/all-transactions?currencyFilterType=Currency&currencyTicker=${originDetail.boughtCurrency}&page=1&sortBy=txnTimestamp&sortDir=asc`}
        >
          View {originDetail.boughtCurrency} Transactions
        </TextLink>
      </div>
    </div>
  );
};

type MigrationDetailProps = {
  migrationDetail: NonNullable<ExpandedRowQuery['lineItemDetails']['migrationDetail']>;
  renderNumericValue: (value: number, currency: string | undefined, compact?: boolean) => React.ReactNode;
  onEditTxn?: (txn: Txn) => void;
  fromLocation?: string;
  toLocation?: string;
};

const MigrationDetail: React.FC<MigrationDetailProps> = ({
  migrationDetail,
  renderNumericValue,
  onEditTxn,
  fromLocation,
  toLocation,
}) => {
  let migrationDate = '';
  let migrationDatetime = '';
  const formatResult = formatDate(migrationDetail.date);
  if (formatResult) {
    ({ date: migrationDate, datetime: migrationDatetime } = formatResult);
  }

  const descriptionMap = {
    [TxnType.migration]: (
      <>
        Migrated {renderNumericValue(migrationDetail.soldAmount, migrationDetail.soldCurrency, true)}{' '}
        {migrationDetail.soldCurrency} to{' '}
        {renderNumericValue(migrationDetail.boughtAmount, migrationDetail.boughtCurrency, true)}{' '}
        {migrationDetail.boughtCurrency}
      </>
    ),
    [TxnType.movement]: (
      <>
        Moved {renderNumericValue(migrationDetail.soldAmount, migrationDetail.soldCurrency, true)}{' '}
        {migrationDetail.soldCurrency}
        {fromLocation ? ` from ${fromLocation} ` : ''} {toLocation ? `to ${toLocation}` : ''}
      </>
    ),
  };
  return (
    <div className="migration-info flex flex-grow flex-col ml-8" style={{ minWidth: 180, maxWidth: 360 }}>
      <div className="font-bold truncate" style={{ maxWidth: 360 }}>
        {descriptionMap[migrationDetail.txnType as keyof typeof descriptionMap]}
      </div>
      <div>
        <Text variant="muted">
          Acquired on <span title={migrationDatetime}>{migrationDate}</span>
        </Text>
      </div>
      <div>
        <TextLink disabled={!onEditTxn} onClick={() => onEditTxn?.(migrationDetail?.txn)}>
          See Transaction
        </TextLink>
      </div>
      <AuditDetailValueSection
        detailType="acquisition"
        txnType={migrationDetail.txnType}
        location={migrationDetail.location}
        price={migrationDetail.price}
        feeCurrency={migrationDetail.feeCurrency}
        feeAmount={migrationDetail.feeAmount}
        integrationId={migrationDetail.txn.integrationId}
        renderNumericValue={renderNumericValue}
        isFee={false}
      />
    </div>
  );
};

type ExitDetailProps = {
  exitDetail: NonNullable<ExpandedRowQuery['lineItemDetails']['exitDetail']>;
  term?: string | null;
  renderNumericValue: (value: number, currency: string | undefined, compact?: true) => React.ReactNode;
  onEditTxn?: (txn: Txn) => void;
  isFee: boolean;
};

const ExitDetail: React.FC<ExitDetailProps> = ({
  exitDetail,
  term,
  renderNumericValue,
  onEditTxn,
  isFee,
}) => {
  const { baseCurrency } = useTableContext();

  let exitDate = '';
  let exitDatetime = '';
  const formatResult = formatDate(exitDetail.date);
  if (formatResult) {
    ({ date: exitDate, datetime: exitDatetime } = formatResult);
  }

  return (
    <div className="exit-info flex flex-grow flex-col ml-8" style={{ maxWidth: 380 }}>
      <div className="font-bold truncate" style={{ maxWidth: 380 }}>
        Exit {renderNumericValue(exitDetail.soldAmount, exitDetail.soldCurrency, true)}{' '}
        {exitDetail.soldCurrency}
        {term ? ` ${capitalize(term)} Term` : ''} for{' '}
        {renderNumericValue(exitDetail.boughtAmount, exitDetail.boughtCurrency, true)}
        {exitDetail.boughtCurrency !== baseCurrency && <> {exitDetail.boughtCurrency}</>}
      </div>
      <div>
        <Text variant="muted">
          Sold on <span title={exitDatetime}>{exitDate}</span>
        </Text>
      </div>
      <div>
        <TextLink disabled={!onEditTxn} onClick={() => onEditTxn?.(exitDetail?.txn)}>
          See Transaction
        </TextLink>
      </div>
      <AuditDetailValueSection
        detailType="sale"
        txnType={exitDetail.txnType}
        location={exitDetail.location}
        price={exitDetail.price}
        feeCurrency={exitDetail.feeCurrency}
        feeAmount={exitDetail.feeAmount}
        integrationId={exitDetail.txn.integrationId}
        renderNumericValue={renderNumericValue}
        isFee={isFee}
      />
    </div>
  );
};

type MissingCostBasisDetailProps = {
  missingCostBasisDetail: NonNullable<ExpandedRowQuery['lineItemDetails']['missingCostBasisDetail']>;
  renderNumericValue: (value: number, currency: string | undefined, compact?: true) => React.ReactNode;
};

const MissingCostBasisDetail: React.FC<MissingCostBasisDetailProps> = ({
  missingCostBasisDetail,
  renderNumericValue,
}) => {
  return (
    <div className="missing-cost-basis-info flex flex-col rounded" style={{ maxWidth: 240 }}>
      <div className="font-bold truncate" style={{ maxWidth: 240 }}>
        Missing cost basis for{' '}
        {renderNumericValue(
          missingCostBasisDetail.missingAmount,
          missingCostBasisDetail.missingCurrency,
          true,
        )}{' '}
        {missingCostBasisDetail.missingCurrency}
      </div>
      <div style={{ maxWidth: 200 }}>
        <Text variant="muted">
          We don't know where you acquired this {missingCostBasisDetail.missingCurrency}
        </Text>
      </div>
      <div className="mt-4">
        <TextLink
          to={`/all-transactions${buildCurrencyTickerFilterUrlParam(
            missingCostBasisDetail.missingCurrency,
          )}&page=1&sortBy=txnTimestamp&sortDir=asc`}
        >
          View {missingCostBasisDetail.missingCurrency} Transactions
        </TextLink>
      </div>
    </div>
  );
};

type TxnAuditOverviewProps = {
  lineItemDetails: NonNullable<ExpandedRowQuery['lineItemDetails']>;
  soldCurrency: string;
  sellFeeCurrency: string;
  sellFeeAmount: number;
  originDate: string | null;
  exitDate: string | null;
  missingCostBasisDetail: ExpandedRowQuery['lineItemDetails']['missingCostBasisDetail'];
  renderNumericValue: (
    value: number,
    currency: string | undefined,
    compact?: boolean,
    alwaysShowDecimals?: true,
  ) => React.ReactNode;
};

const TxnAuditOverview: React.FC<TxnAuditOverviewProps> = ({
  lineItemDetails,
  soldCurrency,
  sellFeeCurrency,
  sellFeeAmount,
  originDate,
  exitDate,
  missingCostBasisDetail,
  renderNumericValue,
}) => {
  const { baseCurrency } = useTableContext();

  const {
    soldAmountFlat,
    costBasis,
    costBasisFlat,
    costBasisFromSellFee,
    costBasisFeeAddition,
    costBasisFeeAdditionCurrency,
    costBasisFeeAdditionUnits,
    proceeds,
    proceedsFlat,
    proceedsFromSellFee,
    proceedsFeeDeduction,
    proceedsFeeDeductionCurrency,
    proceedsFeeDeductionUnits,
    gainLoss,
  } = lineItemDetails;

  const soldCurrencyTruncated = truncate(soldCurrency, { length: 7 });
  const sellFeeCurrencyTruncated = truncate(sellFeeCurrency, { length: 7 });
  const costBasisFeeAdditionCurrencyTruncated = truncate(costBasisFeeAdditionCurrency, { length: 7 });
  const proceedsFeeDeductionCurrencyTruncated = truncate(proceedsFeeDeductionCurrency, { length: 7 });

  return (
    <div className="overview-info flex-initial ml-12 px-12 border-solid border-l max-w-xl">
      <div className="flex flex-wrap justify-between items-end gap-4">
        <div className="cost-basis">
          <div>
            Cost Basis
            {costBasis !== 0 && (
              <Tooltip icon="info_outline" place="top" interactive appendTo={document.body} maxWidth="none">
                <>
                  <div className="flex flex-col">
                    <div className="flex justify-between font-bold">
                      <div>Cost basis explanation</div>
                      <div className="ml-10">{renderNumericValue(costBasis, baseCurrency, false, true)}</div>
                    </div>
                    {soldAmountFlat !== 0 && costBasisFlat !== 0 && (
                      <div className="flex justify-between">
                        <div className="whitespace-nowrap">
                          Cost basis of {renderNumericValue(soldAmountFlat, soldCurrency)}{' '}
                          {soldCurrencyTruncated}
                        </div>
                        <div className="ml-10">
                          {renderNumericValue(costBasisFlat, baseCurrency, false, true)}
                        </div>
                      </div>
                    )}
                    {sellFeeCurrency && sellFeeAmount !== 0 && costBasisFromSellFee !== 0 && (
                      <div className="flex justify-between">
                        <div className="whitespace-nowrap">
                          Cost basis of {renderNumericValue(sellFeeAmount, sellFeeCurrency)}{' '}
                          {sellFeeCurrency === 'USD' ? '' : `${sellFeeCurrencyTruncated} `} fee on{' '}
                          {exitDate ? `${exitDate} ` : ''} {soldCurrencyTruncated} exit
                        </div>
                        <div className="ml-10">
                          {renderNumericValue(costBasisFromSellFee, baseCurrency, false, true)}
                        </div>
                      </div>
                    )}
                    {costBasisFeeAdditionCurrency &&
                      costBasisFeeAdditionUnits !== 0 &&
                      costBasisFeeAddition !== 0 && (
                        <div className="flex">
                          <div className="whitespace-nowrap">
                            Cost basis of{' '}
                            {renderNumericValue(costBasisFeeAdditionUnits, costBasisFeeAdditionCurrency)}{' '}
                            {costBasisFeeAdditionCurrency === 'USD'
                              ? ''
                              : `${costBasisFeeAdditionCurrencyTruncated} `}{' '}
                            fee on {originDate ? `${originDate} ` : ''} {soldCurrencyTruncated} origin
                          </div>
                          <div className="ml-10">
                            {renderNumericValue(costBasisFeeAddition, baseCurrency, false, true)}
                          </div>
                        </div>
                      )}
                  </div>
                </>
              </Tooltip>
            )}
          </div>
          <div className="text-3xl">{renderNumericValue(costBasis, baseCurrency, false, true)}</div>
        </div>
        <div className="proceeds">
          <div>
            Proceeds
            {proceeds !== 0 && (
              <Tooltip icon="info_outline" place="top" interactive appendTo={document.body} maxWidth="none">
                <>
                  <div className="flex flex-col">
                    <div className="flex justify-between font-bold">
                      <div>Proceeds explanation</div>
                      <div className="ml-10">{renderNumericValue(proceeds, baseCurrency, false, true)}</div>
                    </div>
                    {soldAmountFlat !== 0 && proceedsFlat !== 0 && (
                      <div className="flex justify-between">
                        <div className="whitespace-nowrap">
                          Proceeds from sale of {renderNumericValue(soldAmountFlat, soldCurrency)}{' '}
                          {soldCurrencyTruncated}
                        </div>
                        <div className="ml-10">
                          {renderNumericValue(proceedsFlat, baseCurrency, false, true)}
                        </div>
                      </div>
                    )}
                    {sellFeeCurrency && sellFeeAmount !== 0 && proceedsFromSellFee !== 0 && (
                      <div className="flex justify-between">
                        <div className="whitespace-nowrap">
                          Proceeds from {renderNumericValue(sellFeeAmount, sellFeeCurrency)}{' '}
                          {sellFeeCurrency === 'USD' ? '' : `${sellFeeCurrencyTruncated} `} fee on{' '}
                          {exitDate ? `${exitDate} ` : ''} {soldCurrencyTruncated} exit
                        </div>
                        <div className="ml-10">
                          {renderNumericValue(proceedsFromSellFee, baseCurrency, false, true)}
                        </div>
                      </div>
                    )}
                    {proceedsFeeDeductionCurrency &&
                      proceedsFeeDeductionUnits !== 0 &&
                      proceedsFeeDeduction !== 0 && (
                        <div className="flex">
                          <div className="whitespace-nowrap">
                            Deduction to proceeds from{' '}
                            {renderNumericValue(proceedsFeeDeductionUnits, proceedsFeeDeductionCurrency)}{' '}
                            {proceedsFeeDeductionCurrency === 'USD'
                              ? ''
                              : `${proceedsFeeDeductionCurrencyTruncated} `}{' '}
                            fee on {exitDate ? `${exitDate} ` : ''} {soldCurrencyTruncated} exit
                          </div>
                          <div className="ml-10">
                            ({renderNumericValue(proceedsFeeDeduction, baseCurrency, false, true)})
                          </div>
                        </div>
                      )}
                  </div>
                </>
              </Tooltip>
            )}
          </div>
          <div className="text-3xl">{renderNumericValue(proceeds, baseCurrency, false, true)}</div>
        </div>
        <div className="gain-loss">
          <div>Realized Gain/Loss</div>
          <div className="text-3xl">{renderNumericValue(gainLoss, baseCurrency, false, true)}</div>
        </div>
      </div>
      <div className="explanation mt-6">
        {!missingCostBasisDetail && (
          <>
            Your {soldCurrency} was acquired with a cost basis of{' '}
            {renderNumericValue(costBasis, baseCurrency, false, true)} and sold for a{' '}
            {gainLoss >= 0 ? 'gain' : 'loss'} of{' '}
            {renderNumericValue(Math.abs(gainLoss), baseCurrency, false, true)}
          </>
        )}
        {missingCostBasisDetail && (
          <>
            We are not sure where you acquired your initial {missingCostBasisDetail.missingCurrency} that was
            sold in this transaction. Please edit or find the transaction history.
          </>
        )}
      </div>
    </div>
  );
};

interface TxnAuditFlowProps {
  lineItemDetails: NonNullable<ExpandedRowQuery['lineItemDetails']>;
  isModal: boolean;
  onEditTxn?: (txn: Txn) => void;
  showModal: () => void;
  isFee: boolean;
}

const TxnAuditFlow: React.FC<TxnAuditFlowProps> = ({
  lineItemDetails,
  isModal,
  onEditTxn,
  showModal,
  isFee,
}) => {
  const { baseCurrency } = useTableContext();
  const { allIntegrations } = useIntegrationsContext();

  const { originDetail, migrationDetail, exitDetail, averageCost, bnbMatchType, missingCostBasisDetail } =
    lineItemDetails;

  const exitDateCompact = formatDate(exitDetail.date, true)?.date || null;
  const originDateCompact = originDetail ? formatDate(originDetail.date, true)?.date || null : null;

  const lineItemTrace: LineItemData[] = (lineItemDetails.lineItemTrace as LineItemData[]) || [];

  const migrationCount = lineItemTrace.length;
  let hiddenMigrationCount = 0;

  if (migrationCount > 1) {
    hiddenMigrationCount = migrationCount - 1;
  }

  const renderNumericValue = (
    value: number,
    currency: string | undefined,
    compact?: boolean,
    alwaysShowDecimals?: boolean,
  ): React.ReactNode => {
    const currencyToRender = currency || 'USD';
    const fiatFormatProps = {
      currency: baseCurrency || 'USD',
      comma: true,
      decimals: alwaysShowDecimals ? true : Math.abs(value) < 1 && Math.abs(value) > 0,
    };

    // if currency to render is fiat/baseCurrency then we render as currency
    if (currencyToRender === fiatFormatProps.currency) {
      return <NumberFormat value={value} {...fiatFormatProps} />;
    }

    // TODO: abstract repeat logic from 'AmountCell'
    // use 4 decimals if greater than 0.0001 - if less than that use 8
    // if smaller than 0.0001 it should show in sci not
    let decimalScale: number | undefined = value && value > 0.0001 ? 4 : 8;

    // don't show decimals at all if >= 100,000
    let showDecimals = true;
    if (value && value >= 100000) {
      showDecimals = false;
      decimalScale = undefined;
    }

    let sciNotation = false;
    // always show in sci notation with 4 decimals if >= 10,000,000 or <= 0.0001
    if (value && (value >= 10000000 || value <= 0.0001)) {
      sciNotation = true;
      showDecimals = true;
      decimalScale = 4;
    }

    // if compact than always show in sci Not if less than 0.01
    // and show decimalScale 2
    if (compact) {
      decimalScale = 2;
      if (value && value >= 100) {
        // hide decimals if above 100
        decimalScale = undefined;
        showDecimals = false;
      } else if (value && value <= 0.01) {
        // show in sci not if less than 0.01
        decimalScale = 1;
        sciNotation = true;
        showDecimals = true;
      }
    }
    const cryptoFormatProps = {
      comma: true,
      decimals: showDecimals,
      decimalScale,
      sciNotation,
    };

    // otherwise render using crypto format props
    return <NumberFormat value={value} {...cryptoFormatProps} />;
  };

  // we show the averageCost detail if averageCost is defined and bnbMatchType is not defined
  const showAverageCostDetail = typeof averageCost === 'number' && typeof bnbMatchType !== 'string';

  return (
    <>
      {migrationCount > 0 && (
        <div className="font-bold mb-6">
          Cost basis passed through {migrationCount} transaction{migrationCount !== 1 ? 's' : ''}
        </div>
      )}
      <div className="w-full flex justify-between">
        <div className="audit-flow-info flex">
          {originDetail && !showAverageCostDetail && (
            <OriginDetail
              originDetail={originDetail}
              hiddenMigrationCount={hiddenMigrationCount}
              isModal={isModal}
              renderNumericValue={renderNumericValue}
              onEditTxn={onEditTxn}
              bnbMatchType={bnbMatchType}
              specIdMatch={lineItemDetails.specIdMatch || false}
              sellId={lineItemDetails.exitDetail.txn.id}
            />
          )}
          {originDetail && showAverageCostDetail && (
            <AverageCostOriginDetail
              originDetail={originDetail}
              averageCost={averageCost}
              renderNumericValue={renderNumericValue}
            />
          )}
          {missingCostBasisDetail && (
            <MissingCostBasisDetail
              missingCostBasisDetail={missingCostBasisDetail}
              renderNumericValue={renderNumericValue}
            />
          )}
          {hiddenMigrationCount > 0 && (
            <HiddenMigrationIndicator
              hiddenMigrationCount={hiddenMigrationCount}
              isModal={isModal}
              showModal={showModal}
            />
          )}
          {!(hiddenMigrationCount > 0) && <RightArrow />}
          {migrationDetail && (
            <>
              <MigrationDetail
                migrationDetail={migrationDetail}
                renderNumericValue={renderNumericValue}
                onEditTxn={onEditTxn}
                fromLocation={
                  allIntegrations.find((integration) => integration.id === migrationDetail.txn.integrationId)
                    ?.name || undefined
                }
                toLocation={
                  allIntegrations.find(
                    (integration) => integration.id === migrationDetail.txn.toIntegrationId,
                  )?.name || undefined
                }
              />
              <RightArrow />
            </>
          )}
          <ExitDetail
            exitDetail={exitDetail}
            term={lineItemDetails.term}
            renderNumericValue={renderNumericValue}
            onEditTxn={onEditTxn}
            isFee={isFee}
          />
        </div>
        <TxnAuditOverview
          lineItemDetails={lineItemDetails}
          soldCurrency={exitDetail.soldCurrency}
          sellFeeCurrency={exitDetail.feeCurrency}
          sellFeeAmount={exitDetail.feeAmount}
          exitDate={exitDateCompact}
          originDate={originDateCompact}
          missingCostBasisDetail={missingCostBasisDetail}
          renderNumericValue={renderNumericValue}
        />
      </div>
    </>
  );
};

interface LineItemExpandedRowProps<T extends Record<string, unknown>> extends ExpandedRowProps<T> {
  onEditTxn?: (txn: Txn) => void;
}

export const ExpandedRow = <T extends LineItemData>({
  row,
  onEditTxn,
}: LineItemExpandedRowProps<T>): React.ReactElement => {
  const { user } = useUserContext();
  const [showFullTxnAuditModal, setShowFullTxnAuditModal] = useState(false);

  const lineItemId = (row.original as LineItemData).id || 0;
  const { loading, data } = useQuery<ExpandedRowQuery, ExpandedRowQueryVariables>(EXPANDED_ROW_DATA, {
    variables: {
      lineItemId,
      isAdmin: Auth.tokenIsForAdmin(),
    },
  });

  let lineItemDetails: ExpandedRowQuery['lineItemDetails'] | undefined;
  let hasHiddenMigrations = true;
  let migrationCount = 0;
  let fullTxnAuditLineItems: LineItemData[] = [row.original];

  if (data) {
    lineItemDetails = data.lineItemDetails;
    hasHiddenMigrations = lineItemDetails?.lineItemTrace.length > 0 || false;
    migrationCount = lineItemDetails?.lineItemTrace.length || 0;
    if (lineItemDetails.lineItemTrace) {
      fullTxnAuditLineItems = [
        ...fullTxnAuditLineItems,
        ...(lineItemDetails.lineItemTrace as LineItemData[]),
      ];
    }
  }

  // TODO: can turn this back on when we actually use this for every lineItem:
  // const displayFeeColumns = fullTxnAuditLineItems.some(({ feeUnitsSold }) => feeUnitsSold !== null);
  const displayFeeColumns = false;

  const displayAccountColumns = !!user?.recalcByAccount;

  const showModal = useCallback(() => setShowFullTxnAuditModal(true), []);

  return (
    <div className="border-t bg-light-shade dark:bg-dark-shade">
      <div className="py-6 px-8">
        {loading && (
          <div className="flex w-full justify-center items-center" style={{ height: 150 }}>
            <Spinner size="sm" />
          </div>
        )}
        {!loading && lineItemDetails && (
          <TxnAuditFlow
            lineItemDetails={lineItemDetails}
            onEditTxn={onEditTxn}
            isModal={false}
            showModal={showModal}
            isFee={row.original.isFee || false}
          />
        )}
      </div>
      {!loading && (
        <>
          {hasHiddenMigrations && (
            <div className="border-t py-3 px-8 cursor-pointer">
              <TextLink onClick={() => setShowFullTxnAuditModal(true)}>
                View Full Transaction Audit Log ({migrationCount})
              </TextLink>
            </div>
          )}
          {showFullTxnAuditModal && lineItemDetails && (
            <Modal
              onClose={() => setShowFullTxnAuditModal(false)}
              width={ModalWidths.FULL}
              showDivider
              padded={false}
            >
              <div className="rounded pt-8 overflow-x-auto xl:overflow-x-visible" style={{ minWidth: 1234 }}>
                <div className="px-6 pb-12">
                  <TxnAuditFlow
                    lineItemDetails={lineItemDetails}
                    onEditTxn={onEditTxn}
                    isModal
                    showModal={showModal}
                    isFee={row.original.isFee || false}
                  />
                </div>
                {migrationCount > 0 && (
                  <div className="border-t pb-4 rounded-b">
                    <StaticTable
                      name="ExpandedRowTable"
                      columns={getMigrationTableColumns({ displayFeeColumns, displayAccountColumns })}
                      defaultSortedId="sellDate"
                      defaultSortedDirection={'desc' as SortDirectionOptions}
                      data={fullTxnAuditLineItems}
                    >
                      <Header data={fullTxnAuditLineItems} />
                      <Body data={fullTxnAuditLineItems} />
                    </StaticTable>
                  </div>
                )}
              </div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

//
