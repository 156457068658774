import cx from 'classnames';
import React from 'react';

export type ExchangeRates = { [currency: string]: ExchangeRate };

type ExchangeRate = {
  locale: string;
  conversionRate: number;
};

export interface NumberFormatProps {
  /** show commas */
  commas?: boolean;
  /** currency code*/
  currency?: string | null;
  /** number of digits after decimal */
  decimalScale?: number | null;
  /** show decimals (or rounds value) */
  decimals?: boolean;
  /** always show in sci not */
  sciNotation?: boolean;
  /** original number value */
  value?: number | null;
}

const numberWithCommas = (number: number) => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export function formatNumber(valueArg: number | string) {
  const value = typeof valueArg === 'string' ? parseFloat(valueArg) : valueArg;
  // use 4 decimals if greater than 0.0001 - if less than that use 8
  // if smaller than 0.0001 it should show in sci not
  let decimalScale: number | undefined = value && value > 0.0001 ? 4 : 8;
  if (value === 0) return '0';

  const absValue = Math.abs(value);
  // don't show decimals at all if >= 100,000
  let showDecimals = true;
  if (absValue >= 100000) {
    showDecimals = false;
    decimalScale = undefined;
  }

  let sciNotation = false;
  // always show in sci notation with 4 decimals if >= 10,000,000 or <= 0.0001
  if (absValue >= 10000000 || absValue <= 0.0001) {
    sciNotation = true;
    showDecimals = true;
    decimalScale = 4;
  }

  return formatNumberWithOptions({ commas: true, value, sciNotation, decimals: showDecimals, decimalScale });
}

function formatNumberWithOptions({
  commas,
  currency,
  decimalScale,
  decimals,
  sciNotation,
  value,
}: NumberFormatProps) {
  if (value === null || value === undefined || isNaN(value)) {
    return '-';
  }
  if (currency) {
    // if no decimals round the number.
    let roundedValue = decimals ? value : Math.round(value);
    if (roundedValue === 0) {
      // gets rid of -0 value.
      roundedValue = 0;
    }

    let formatter;
    try {
      formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol', // displays $ instead of US$
        minimumFractionDigits: decimalScale || 2,
      });
    } catch (e) {
      if (!(e instanceof RangeError)) {
        throw e;
      }
      formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency,
        minimumFractionDigits: decimalScale || 2,
      });
    }

    let currencyDisplay = formatter.format(roundedValue);

    // chop off trailing zeroes if hiding decimals
    if (!decimals) {
      // for CAD, suffix is formatted as ',00 $'
      if (currencyDisplay.slice(-5, -2) === ',00') {
        currencyDisplay = currencyDisplay.slice(0, -5) + currencyDisplay.slice(-2);
      } else if (currencyDisplay.slice(-3) === '.00') {
        currencyDisplay = currencyDisplay.slice(0, -3);
      } else if (currencyDisplay.slice(-5) === ',00 €') {
        currencyDisplay = `${currencyDisplay.slice(0, -5)} €`;
      }
    }

    return String(currencyDisplay);
  }

  let displayValue;
  if (decimalScale) {
    if (sciNotation) {
      displayValue = value.toExponential(decimalScale);
    } else {
      displayValue = parseFloat(value.toFixed(decimalScale));
    }
  } else if (decimals) {
    // default to 2 decimals
    if (sciNotation) {
      displayValue = value.toExponential(2);
    } else {
      displayValue = parseFloat(value.toFixed(2));
    }
  } else {
    if (sciNotation) {
      displayValue = value.toExponential(0);
    } else {
      displayValue = Math.round(value);
    }
  }
  if (!sciNotation && commas) {
    displayValue = numberWithCommas(displayValue as number);
  }
  // chop trailing 0s from sci notation
  if (sciNotation) {
    const splitByExponent = (displayValue as string).split('e');
    if (splitByExponent.length > 1) {
      const beforeExponent = splitByExponent[0];
      const afterExponent = splitByExponent[1];
      displayValue = `${parseFloat(beforeExponent)}e${afterExponent}`;
    }
  }
  return String(displayValue);
}

function NumberFormat(props: NumberFormatProps) {
  return <>{formatNumberWithOptions(props)}</>;
}

export const positiveAmountClassName = 'text-light-alert-success dark:text-dark-alert-success';
export const negativeAmountClassName = 'text-light-alert-critical dark:text-dark-alert-critical';
export function ColoredNumberFormat(props: NumberFormatProps) {
  const { value } = props;
  const isPositive = value && value > 0;
  const isNegative = value && value < 0;

  return (
    <span className={cx(isNegative && negativeAmountClassName, isPositive && positiveAmountClassName)}>
      <NumberFormat {...props} />
    </span>
  );
}

export default React.memo(NumberFormat);
