import { useReactiveVar } from '@apollo/client';
import { useContext, useMemo } from 'react';
import { IntegrationsContext } from '../../../../contexts/IntegrationsContext';
import { useAllCredentials, useBkpAccountsVendors } from '../../../../graphql/queries';
import {
  BooleanFilterTypes,
  SpecIdMatchSide,
  TxnFilterQuery,
  TxnSearchFilterKeys,
} from '../../../../graphql/types';
import { AllTransactionsContext } from '../../Provider/context';
import { actions } from '../../actions';
import * as selectors from '../../selectors';
import { vars } from '../../vars';

const addBooleanFilter = (key: BooleanFilterTypes) => () => {
  actions.addBooleanFilter({ key, value: true });
};

export const useFiltersConfig = () => {
  const { filtersVar } = vars;
  const filtersState = useReactiveVar(filtersVar);

  const { counts } = useContext(AllTransactionsContext);
  const { txnTypeCounts, txnImportTypeCounts } = counts ?? {};

  const filterQuery: TxnFilterQuery = useMemo(() => {
    return JSON.parse(filtersState.filterQuery || '{}');
  }, [filtersState.filterQuery]);

  const integrationsContext = useContext(IntegrationsContext);
  const { data: credentialsData } = useAllCredentials({
    variables: { extended: false },
  });

  const { data: bkpData } = useBkpAccountsVendors({});

  const filtersConfig = useMemo(
    () => ({
      date: {
        label: 'Date',
      },
      fromAddress: {
        label: 'From Address',
        filterKey: TxnSearchFilterKeys.fromAddress,
      },
      methodId: { label: 'Method ID', filterKey: TxnSearchFilterKeys.methodId },
      reconIdentifier: {
        label: 'Identifier',
        filterKey: TxnSearchFilterKeys.reconIdentifier,
        adminOnly: true,
      },
      importType: {
        label: 'Import Type',
        filter: filterQuery.importType,
        multiSelectOptions: selectors.getImportTypeFilterOptions(txnImportTypeCounts),
      },
      imports: {
        label: 'Imports',
        multiSearchOptions: selectors.getCredentialsOptions(credentialsData ?? [], integrationsContext),
        disabled: Boolean(filterQuery.imports),
      },
      vendors: {
        label: 'Vendors',
        multiSearchOptions: selectors.getVendorsOptions(bkpData?.bkpVendors ?? []),
        disabled: Boolean(filterQuery.vendors),
        adminOnly: true,
      },
      accountDebits: {
        label: 'Debit Accounts',
        multiSearchOptions: selectors.getAccountOptions(bkpData?.bkpAccounts ?? []),
        disabled: Boolean(filterQuery.accountDebits),
        adminOnly: true,
      },
      accountCredits: {
        label: 'Credit Accounts',
        multiSearchOptions: selectors.getAccountOptions(bkpData?.bkpAccounts ?? []),
        disabled: Boolean(filterQuery.accountCredits),
        adminOnly: true,
      },
      reviewed: {
        label: 'Reviewed',
        disabled: Boolean(filterQuery.boolean?.find((f) => f.key === 'reviewed')),
        onSelected: addBooleanFilter(BooleanFilterTypes.reviewed),
      },
      hasComments: {
        label: 'Has Comments',
        disabled: Boolean(filterQuery.boolean?.find((f) => f.key === 'hasComments')),
        onSelected: addBooleanFilter(BooleanFilterTypes.hasComments),
      },
      manuallyEdited: {
        label: 'Manually Edited',
        disabled: Boolean(filterQuery.boolean?.find((f) => f.key === 'manuallyEdited')),
        onSelected: addBooleanFilter(BooleanFilterTypes.manuallyEdited),
      },
      automaticallyEdited: {
        label: 'Automatically Reconciled',
        disabled: Boolean(filterQuery.boolean?.find((f) => f.key === 'automaticallyEdited')),
        onSelected: addBooleanFilter(BooleanFilterTypes.automaticallyEdited),
      },
      isSpam: {
        label: 'Spam',
        disabled: Boolean(filterQuery.boolean?.find((f) => f.key === 'isSpam')),
        onSelected: addBooleanFilter(BooleanFilterTypes.isSpam),
      },
      editedAt: { label: 'Last Edited' },
      missingPrice: {
        label: 'Missing Price',
        disabled: Boolean(filterQuery.boolean?.find((f) => f.key === 'missingPrice')),
        onSelected: addBooleanFilter(BooleanFilterTypes.missingPrice),
      },
      specIdSide: {
        label: 'Has Spec ID',
        adminOnly: true,
        onSelected: () => {
          actions.setSpecIdSide(SpecIdMatchSide.buy);
        },
      },
      currency: { label: 'Currency' },
      txnHash: {
        label: 'Txn Hash',
        filterKey: TxnSearchFilterKeys.txnHash,
      },
      txnType: {
        label: 'Txn Type',
        filter: filterQuery.txnType,
        multiSelectOptions: selectors.getTxnTypeFilterOptions(txnTypeCounts),
      },
      toAddress: {
        label: 'Contract (To)',
        filterKey: TxnSearchFilterKeys.toAddress,
      },
      location: {
        label: 'Location',
        filterKey: TxnSearchFilterKeys.location,
      },
      hasMovement: {
        label: 'Part of Movement',
        disabled: Boolean(filterQuery.boolean?.find((f) => f.key === 'hasMovement')),
        onSelected: addBooleanFilter(BooleanFilterTypes.hasMovement),
      },
    }),
    [txnImportTypeCounts, filterQuery, credentialsData, integrationsContext, txnTypeCounts, bkpData],
  );

  return filtersConfig;
};
