import { CATEGORY_DATA } from '../../Wizard/constants';
import { buildDraftFromTransfers } from '../buildDraftFromTransfers';
import { ComplexTreatmentResult, Props } from './types';

export const incomeWithWithdrawal = (props: Props): ComplexTreatmentResult => {
  const { category, lpTokenTickersByAddress, sentTxns, receivedTxns } = props;

  if (receivedTxns.length === 0) return { drafts: null };

  const drafts = [
    ...receivedTxns.map((deposit) => ({
      received: {
        grouped: false as const,
        txns: [
          {
            id: deposit.id,
            type: CATEGORY_DATA.income.receivedType!,
            currency: lpTokenTickersByAddress[deposit.buyAddress!] ?? deposit.buyCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            tokenId: deposit.buyTokenId,
            quantity: deposit.buyQuantity,
            price: deposit.buyPrice,
          },
        ],
      },
    })),
    // break all other sent txns into their own drafts
    ...sentTxns.flatMap((withdrawal) =>
      buildDraftFromTransfers({
        category,
        lpTokenTickersByAddress,
        txns: [withdrawal],
      }),
    ),
  ];

  return { drafts };
};
