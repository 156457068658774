import { gql } from '@apollo/client';

export const UPDATE_SETTINGS = gql`
  mutation UpdateReportDetail($id: Int!, $settings: Settings!) {
    updateReportDetail(id: $id, settings: $settings) {
      id
      baseCurrency
      countryId
      filingStatus
      method
      state
      marginalTaxRate
      longTermCapitalGainsRate
      taxYear
    }
  }
`;
