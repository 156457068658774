export const defaultMultiSelectState = Object.freeze({
  selectionSpan: null,
  selected: [],
});

export const defaultFiltersState = Object.freeze({});

export const getDefaultPaginationOptions = (pageSize: number) =>
  Object.freeze({
    page: 1,
    pageSize: pageSize,
  });
