import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'use-query-params';

import RestoreInProgress from '../../RestoreInProgress';
import { useVersionsContext } from '../../../contexts';
import Blankslate from '../../Blankslate/index';
import { defaultQueryState } from '../defaults';

interface Props {
  filteredQueryIsEmpty: boolean;
  txnTotalCount?: number;
}

export function useAllTransactionsBlankSlate({ filteredQueryIsEmpty, txnTotalCount }: Props) {
  const history = useHistory();
  const [_query, setQuery] = useQueryParams({});
  const { isRestoringOrRefetching } = useVersionsContext();

  const handleResetQuery = useCallback(() => {
    setQuery(defaultQueryState, 'replaceIn');
  }, [setQuery]);

  if (isRestoringOrRefetching) {
    return <RestoreInProgress />;
  }

  if (filteredQueryIsEmpty) {
    return (
      <Blankslate
        title="No Results"
        subtitle="Adjust the filters or select a different page"
        cta="Reset"
        onClick={handleResetQuery}
        card={false}
      />
    );
  }

  if (txnTotalCount === 0) {
    return (
      <Blankslate
        title="No data found"
        subtitle="Please upload some transactions"
        cta="Import"
        onClick={() => history.push('/import')}
        card={false}
      />
    );
  }

  return null;
}
