import cx from 'classnames';
import React from 'react';
import Label from './Label';

type Props = React.HTMLProps<HTMLInputElement> & {
  onChange: () => void;
  className?: string;
  margin?: string;
};

export default function Radio({ id, checked, label, onChange, className, disabled, ...props }: Props) {
  // Handle keyboard events for accessibility.
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (disabled) return;
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onChange();
    }
  };

  const cns = cx(
    `w-4 h-4 inline-block rounded-full border border-light-control dark:border-dark-control`,
    checked && 'shadow-focus bg-dark-base',
    disabled && 'opacity-50 cursor-not-allowed',
  );

  return (
    <div
      className={cx('cursor-pointer flex items-center gap-2', className)}
      onClick={disabled ? undefined : onChange}
      tabIndex={disabled ? -1 : 0}
      onKeyDown={handleKeyDown}
      role="radio"
      aria-checked={checked}
    >
      <input
        className="opacity-0 absolute appearance-none inline-block focus:outline-none shadow-none"
        id={id}
        type="radio"
        name={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        tabIndex={-1}
        {...props}
      />
      <span className={cns}></span>
      <div className="max-w-[100px]">
        {label && (
          <Label className={cx('inline-block', !disabled && 'cursor-pointer')} htmlFor={id || label}>
            {label}
          </Label>
        )}
      </div>
    </div>
  );
}
