import { gql } from '@apollo/client';
import { UNRECONCILED_TRANSACTION_RESOLUTION } from '../../../../graphql/fragments/UnreconciledTransactionResolution';
import {
  CategoryInput,
  DraftInput,
  ResolveUnreconciledTransactionsMutation,
  ResolveUnreconciledTransactionsMutationVariables,
  UnreconciledTransactionInput,
} from '../../../../graphql/types';
import { client } from '../../../../lib/apollo';
import { UnreconciledTransaction } from '../store/types';
import { Side } from './types';

export const RESOLVE_UNRECONCILED_TRANSACTIONS = gql`
  mutation ResolveUnreconciledTransactions($unreconciledTransactionInputs: [UnreconciledTransactionInput!]!) {
    resolveUnreconciledTransactions(unreconciledTransactionInputs: $unreconciledTransactionInputs) {
      errors {
        unreconciledTransactionId
        message
      }
      resolutions {
        ...UnreconciledTransactionResolution
      }
    }
  }
  ${UNRECONCILED_TRANSACTION_RESOLUTION}
`;

const getUnreconciledTransactionInput = ({
  drafts,
  blocksvcHash,
  credentials,
  ...transaction
}: UnreconciledTransaction): UnreconciledTransactionInput => {
  const { category, edited } = transaction;

  return {
    category: category as CategoryInput,
    edited,
    blocksvcHash,
    credentialType: credentials[0].type,
    drafts: drafts!.map((draft) =>
      ['sent', 'received'].reduce(
        (acc, side): DraftInput => ({
          ...acc,
          ...(draft[side as Side]
            ? {
                [side]: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  txns: draft[side as Side]!.txns.map((txn) => {
                    if (!txn) return null;
                    const { price: _unused, ...rest } = txn;
                    return rest;
                  }),
                },
              }
            : {}),
        }),
        {},
      ),
    ),
  };
};

export const resolveUnreconciledTxns = ({ transactions }: { transactions: UnreconciledTransaction[] }) =>
  client.mutate<ResolveUnreconciledTransactionsMutation, ResolveUnreconciledTransactionsMutationVariables>({
    mutation: RESOLVE_UNRECONCILED_TRANSACTIONS,
    variables: {
      unreconciledTransactionInputs: transactions.map((transaction) =>
        getUnreconciledTransactionInput(transaction),
      ),
    },
  });
