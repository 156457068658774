import { gql } from '@apollo/client';
import { SignupMutation, SignupMutationVariables } from '../../graphql/types';
import { client } from '../../lib/apollo';

const SIGNUP = gql`
  mutation Signup($email: String!, $password: String!, $captchaResponse: String!) {
    signup(email: $email, password: $password, captchaResponse: $captchaResponse) {
      user {
        id
      }
      userHash
    }
  }
`;

export const signup = async (variables: SignupMutationVariables) => {
  const result = await client.mutate<SignupMutation, SignupMutationVariables>({
    mutation: SIGNUP,
    variables,
    context: {
      credentials: 'include',
    },
  });

  if (!result.data?.signup) {
    throw new Error('There was an error signing up. Please try again.');
  }

  const signup = result.data.signup;

  return {
    id: signup.user.id,
    userHash: signup.userHash,
  };
};
