import Auth from '../../lib/Auth';
import { ROUTES } from '../../routes';

type NavItem = {
  name: string;
  path: string;
  shown?: boolean;
  matchPaths?: RegExp[];
};

export const navItems: { [key: string]: NavItem } = {
  taxDashboard: { name: 'Tax Dashboard', path: '/dashboard' },
  taxLossHarvesting: { name: 'Tax Loss Harvesting', path: '/tax-loss-harvesting' },
  incomeDashboard: { name: 'Income', path: '/income', shown: Auth.tokenIsForAdmin() },
  taxGuide: { name: 'Tax Guide', path: '/tax-guide', shown: Auth.tokenIsForAdmin() },
  documents: { name: 'Documents', path: ROUTES.documents, matchPaths: [/^\/documents.*/] },
  documentsTable: { name: 'Documents', path: ROUTES.documentsTable },
  allTransactions: { name: 'Transactions', path: '/all-transactions' },
  importData: { name: 'Import', path: '/import', matchPaths: [/^\/import.*/] },
  profile: { name: 'Profile', path: '/profile' },
  accountSettings: { name: 'Settings', path: '/settings/profile' },
  upgrade: { name: 'Upgrade', path: ROUTES.upgrade },
  admin: { name: 'Admin', path: '/tokentax-admin' },
  recon: { name: 'Recon', path: '/recon', matchPaths: [/^\/recon.*/] },
};
