import React from 'react';
import { launchIntercom } from '../../../../utils/launchIntercom';
import TextLink from '../../../TextLink';

export default function Complete() {
  return (
    <div className="px-6 pb-6">
      Please reach out to <TextLink onClick={launchIntercom}>customer support</TextLink> to discuss next steps
    </div>
  );
}
