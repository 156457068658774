import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../contexts';
import Auth from '../../lib/Auth';
import { showSuccessFlash } from '../Flash';

const getUnHijackReturnUrl = () => {
  if (Auth.tokenIsForAdmin()) {
    return '/tokentax-admin';
  }
  if (Auth.tokenIsForOrgOwnerOrAdmin()) {
    return '/clients';
  }
  throw new Error('Hijacker not an admin');
};

export default function HijackedUser() {
  const { user, isHijacked } = useUserContext();
  const history = useHistory();

  const onUnHijack = async () => {
    const url = getUnHijackReturnUrl();
    await Auth.unHijack();
    history.push({
      pathname: url,
      state: { unhijack: true },
    });
  };

  const copyUserId = useCallback(() => {
    navigator.clipboard.writeText(user?.id?.toString() || '');
    showSuccessFlash('User ID copied to clipboard');
  }, [user?.id]);

  // Subtracting 1 from the year is more intuitive and in line
  // with how we typically refer to locekd years internally
  const lockedTilYear = user?.lockedTilDate ? Number(user.lockedTilDate.split('-')[0]) - 1 : null;

  if (!isHijacked) return null;

  return (
    <>
      {lockedTilYear && (
        <span className="text-white opacity-60">
          🔒 <span className="sm:inline hidden">{lockedTilYear}</span>
        </span>
      )}

      <button className="text-white opacity-60" onClick={onUnHijack}>
        <span>Unhijack</span>
      </button>

      <button onClick={copyUserId} className="text-white opacity-60">
        <span>ID: {user?.id}</span>
      </button>
    </>
  );
}
