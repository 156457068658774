import { useApolloClient } from '@apollo/client';
import { useFormikContext } from 'formik';
import { chain, uniq } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { PriceFetchingSide, TxnFragment } from '../../../graphql/types';
import { BUY_PRICE_ONLY_TYPES, SELL_PRICE_ONLY_TYPES } from '../../../lib/constants';
import pluralize from '../../../lib/pluralize';
import {
  TRANSACTION_TYPES_WITH_BOTH_BUY_AND_SELL_PRICES,
  calculateBuyPrice,
  calculateSellPrice,
  isTradeLike,
} from '../../../utils/txnPrices';
import { ToastType } from '../../Toast';
import { getLabelText } from './FormInput';
import { DIFFERENT_VALUES, NEW_VALUES } from './formValues';
import { getCurrencyForType } from './helpers';
import { addPriceToOneSidedTransaction, addPricesToTrade } from './queries';
import { CurrencyType, Values } from './types';

const priceCalculators = {
  sellPrice: calculateSellPrice,
  buyPrice: calculateBuyPrice,
};

interface UseFormProps {
  txns: TxnFragment[];
  setToast: (type: ToastType, message: string) => void;
  // we could in theory get `initialValues` straight from the formik context
  // but for some reason this does not work in tests (this may be a bug in formik)
  // so we pass it as a prop from the parent component
  initialValues: Values;
  saveTxnValues: ({ values, onError }: { values: TxnFragment[]; onError?: () => void }) => Promise<void>;
}

const IMMEDIATELY_SAVEABLE_PROPS = [
  'buyTokenId',
  'sellTokenId',
  'buyPrice',
  'sellPrice',
  'feePrice',
  'priceFetchingSide',
];

export default function useForm({ txns, initialValues, setToast, saveTxnValues }: UseFormProps) {
  const [txnsValues, setTxnsValues] = useState<TxnFragment[]>(txns);
  const client = useApolloClient();
  const formik = useFormikContext<Values>();

  useLayoutEffect(() => {
    setTxnsValues(txns);
  }, [txns]);

  useLayoutEffect(() => {
    formik.setValues(initialValues);
    // be careful not to include `formik` in deps array or this will loop forever as `formik` is built anew with each call to useFormik
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const { isValid, setFieldValue, values, setValues } = formik;
  const { txnType } = values;

  const changedValues = useMemo(() => {
    return Object.entries(values).filter(
      ([property, value]) => initialValues[property as keyof typeof initialValues] !== value,
    );
  }, [initialValues, values]);
  const hasChangedAnyValue = changedValues.length > 0;

  useEffect(() => {
    const changedValues = Object.entries(values)
      .filter(([_prop, value]) => ![NEW_VALUES, DIFFERENT_VALUES].includes(value))
      .reduce(
        (acc, [prop, value]) => ({
          ...acc,
          [prop]: value,
        }),
        {},
      );

    setTxnsValues((txnsValues) =>
      txnsValues.map((txn) => ({
        ...txn,
        ...changedValues,
      })),
    );
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateDisplayedValues = useCallback(
    (newTxnsValues: TxnFragment[]) => {
      const valuesToUpdate: [string, string][] = [];
      for (const propName of Object.keys(values)) {
        const prop = propName as keyof TxnFragment;
        const noValueHasChanged = newTxnsValues.every(
          (newTxnValue, i) => newTxnValue[prop] === txnsValues[i][prop],
        );
        if (noValueHasChanged) {
          continue;
        }
        const allValuesAreTheSame = uniq(newTxnsValues.map((newTxn) => newTxn[prop])).length === 1;
        const newValue = allValuesAreTheSame ? newTxnsValues[0][prop] : NEW_VALUES;

        valuesToUpdate.push([prop, newValue]);
      }

      // DO NOT change this to individual calls to setFieldValue
      // as this will immediately trigger cascading useEffect calls
      // even before all values have been set
      setValues({
        ...values,
        ...Object.fromEntries(valuesToUpdate),
      });
    },
    [setValues, values, txnsValues],
  );

  const updatePrices = useCallback(
    (prop: 'sellPrice' | 'buyPrice', txns?: TxnFragment[]) => {
      let failedUpdates = 0;
      const newTxnsValues = (txns || txnsValues).map((txn) => {
        if (!TRANSACTION_TYPES_WITH_BOTH_BUY_AND_SELL_PRICES.includes(txnType)) return txn;

        try {
          return {
            ...txn,
            [prop]: priceCalculators[prop](txn),
          };
        } catch (e) {
          failedUpdates++;
          return txn;
        }
      });

      setTxnsValues(newTxnsValues);
      updateDisplayedValues(newTxnsValues);

      return { failedUpdates };
    },
    [txnsValues, updateDisplayedValues, txnType],
  );

  const fetchPrices = useCallback(
    async (type: CurrencyType | null, saveImmediately = false) => {
      const newTxns: TxnFragment[] = [];
      let failedCount = 0;
      let successfulCount = 0;

      for (const txn of txnsValues) {
        const dateMoment = moment.utc(txn.txnTimestamp).startOf('day');

        if (type && !getCurrencyForType(txn, type)) {
          // transaction has no currency for the type of price being fetched. simply skip it
          newTxns.push(txn);
          continue;
        }

        const date = {
          day: dateMoment.get('date'),
          month: dateMoment.get('month') + 1,
          year: dateMoment.get('year'),
        };

        const txnWithOptionalNewValues = {
          ...txn,
          ...chain(values)
            .pick(['fee', 'sell', 'buy'].flatMap((type) => [`${type}Currency`, `${type}TokenId`]))
            .pickBy((value) => ![DIFFERENT_VALUES, NEW_VALUES].includes(value))
            .value(),
        };

        try {
          const newTxnValue =
            isTradeLike(txn) && type !== 'fee'
              ? await addPricesToTrade({
                  client,
                  txn: txnWithOptionalNewValues,
                  date,
                  side: type as PriceFetchingSide | null,
                })
              : await addPriceToOneSidedTransaction({
                  client,
                  txn: txnWithOptionalNewValues,
                  date,
                  type,
                });

          newTxns.push(newTxnValue);
          successfulCount++;
        } catch (e) {
          // this log line is mostly useful in tests
          console.error(e); // eslint-disable-line no-console
          newTxns.push(txn);
          failedCount++;
        }
      }

      setTxnsValues(newTxns);

      const canBeSaved =
        changedValues.filter(([prop]) => !IMMEDIATELY_SAVEABLE_PROPS.includes(prop)).length === 0;

      if (saveImmediately && canBeSaved) {
        await saveTxnValues({ values: newTxns });
        return;
      }

      updateDisplayedValues(newTxns);

      const labelName = type ? getLabelText(`${type}Price`, txnType) : 'price';

      if (failedCount === txns.length) {
        setToast(
          'error',
          `Could not fetch ${labelName} for ${failedCount} ${pluralize(failedCount, 'transaction')}`,
        );
        return;
      }

      let msg = `Successfully fetched`;

      if (type === null) {
        msg += ` prices`;
      } else {
        msg += ` ${labelName}`;

        if (type === 'sell' || type === 'buy') {
          const derivedPropName = `${type === 'sell' ? 'buy' : 'sell'}Price` as const;
          const derivedPropLabelName = getLabelText(derivedPropName, txnType);
          const { failedUpdates } = updatePrices(derivedPropName, newTxns);
          successfulCount -= failedUpdates;

          const hasSellPriceOnly = type === 'sell' && SELL_PRICE_ONLY_TYPES.includes(txnType!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
          const hasBuyPriceOnly = type === 'buy' && BUY_PRICE_ONLY_TYPES.includes(txnType!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

          if (!(hasSellPriceOnly || hasBuyPriceOnly)) {
            // if we're here we know type can't be `fee`
            msg += ` and updated ${derivedPropLabelName}`;
          }
        }
      }

      if (txns.length > 1) {
        msg += ` for ${successfulCount} ${pluralize(successfulCount, 'transaction')}`;
      }

      let toastType: ToastType = 'success';

      if (failedCount) {
        msg += `. Could not fetch price for ${failedCount} ${pluralize(failedCount, 'transaction')}.`;
        toastType = 'warning';
      }

      setToast(toastType, msg);
    },
    [
      changedValues,
      updateDisplayedValues,
      txnType,
      txns.length,
      setToast,
      txnsValues,
      client,
      values,
      saveTxnValues,
      updatePrices,
    ],
  );

  return {
    isValid,
    updatePrices,
    fetchPrices,
    values,
    txnsValues,
    setFieldValue,
    hasChangedAnyValue,
  };
}
