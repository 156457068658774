import classNames from 'classnames';

const baseClassName = classNames([
  'bg-light-base dark:bg-dark-base', // background
  'text-light-base dark:text-dark-base', // text color
  'placeholder:text-light-muted dark:placeholder:text-dark-muted', // placeholder color
  'border border-light-control dark:border-dark-control', // border color
  'hover:border-light-control-hover dark:hover:border-dark-control-hover', // border color when hovered
  'focus:border-light-control-focus dark:focus:border-dark-control-focus', // border color when focused
  'w-full',
  'shadow-none',
  'rounded',
  'focus:outline-none',
]);
export default baseClassName;
