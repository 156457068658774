import React, { useCallback } from 'react';
import { useDelinkSpecIds } from '../../../graphql/mutations/delinkSpecIds';
import { ModalWidths } from '../../Modal';
import { showConfirmationDialog } from '../../Modal/ConfirmationDialog';
import { AllTransactionsContextType } from './context';

export const useCheckSpecIdDelinkRequirement = ({
  txns,
}: Pick<AllTransactionsContextType, 'txns' | 'refetch'>) => {
  const [delinkSpecIds] = useDelinkSpecIds();

  const checkSpecIdDelinkRequirement = useCallback(
    async (txnId: string) => {
      const txn = txns?.find((txn) => txn.id === txnId);
      if (!txn) {
        throw new Error('Transaction not found.');
      }

      const { specIdMatchesAsComponent, specIdMatchesAsSell } = txn;
      if ([...specIdMatchesAsComponent, ...specIdMatchesAsSell].length > 0) {
        // show confirmation dialog
        await showConfirmationDialog({
          showCloseIcon: false,
          width: ModalWidths.XL3,
          title: 'You are editing a transaction tied to a Spec ID pairing',
          content() {
            return (
              <p className="mb-6 leading-relaxed">
                By saving this edit you will delink this transaction from its Spec ID grouping.
                <br />
                You will need to manually re-link it to the Spec ID grouping if you wish to continue to use
                this buy as the cost basis for the exit event it was link to before.
              </p>
            );
          },
          async onConfirm() {
            await delinkSpecIds({
              variables: specIdMatchesAsSell.length ? { sellId: txn.id } : { componentId: txn.id },
            });
          },
          // the reason we throw here (and reject the promise) instead of never resolving
          // is because we want to catch the error and revert to a pre-edit state in scenarios like inline-editing cells
          onCancel() {
            throw new Error('User cancelled delink requirement.'); // this allows us to catch the error and revert to a pre-edit state
          },
          buttonText: 'Save and delink',
          cancelButtonText: "Cancel and don't save",
        });
        // slightly hacky but works, we're making sure the dialog is closed before we open a new one
        await new Promise((resolve) => setTimeout(resolve, 1));
      }
    },
    [delinkSpecIds, txns],
  );

  return checkSpecIdDelinkRequirement;
};
