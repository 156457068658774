import React from 'react';
import { InvoiceQuery } from '../../graphql/types';
import { formatDate } from '../../lib/formatters';
import InvoiceRow from './InvoiceRow';
import { formatPaymentMethod } from './formatPaymentMethod';

interface Props {
  invoice: InvoiceQuery['invoice'];
}

function InvoiceSummary({ invoice }: Props) {
  if (!invoice) {
    return null;
  }

  const { customerEmail, description, paid, paidAt, paymentMethod, number } = invoice;

  return (
    <>
      <InvoiceRow label="To" text={customerEmail} />
      <InvoiceRow label="From" text="TokenTax LLC" />
      <InvoiceRow
        label="Memo"
        text={description}
        style={{
          wordBreak: 'break-word',
        }}
      />

      <InvoiceRow label="Invoice Number" text={number} />

      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      {paid && <InvoiceRow label="Payment Date" text={formatDate(paidAt!)} />}
      {paid && <InvoiceRow label="Payment Method" text={formatPaymentMethod(paymentMethod)} />}
    </>
  );
}

export default React.memo(InvoiceSummary);
