import React, { useCallback, useState } from 'react';
import ActivatePlanNotice from '../ActivatePlan/ActivatePlanNotice';
import { getPlanActivationEmail } from '../ActivatePlan/helpers';
import Button from '../Button';
import Card from '../Card';
import { FormStatus } from '../FormStatus';
import GoogleSignIn from '../GoogleSignIn';
import Input from '../Input';
import PageSeo, { meta } from '../PageSeo';
import PortalLayout from '../PortalLayout';
import TextLink from '../TextLink';
import Turnstile, { useTurnstile } from 'react-turnstile';
import { TURNSTILE_SITE_KEY } from '../../lib/constants';
import { useTheme } from '../../theme/useTheme';

export type FormProps = {
  email: string;
  password: string;
  captchaResponse: string;
};

interface LoginProps {
  onSubmit: (props: FormProps) => Promise<void>;
  isSubmitting: boolean;
}

const subdomain = window.location.host.split('.')[0];
const domain = subdomain ?? 'tokentax';

export default function Login({ isSubmitting, onSubmit }: LoginProps) {
  const [email, setEmail] = useState<string>(getPlanActivationEmail() || '');
  const [password, setPassword] = useState<string>('');
  const [loginError, setLoginError] = useState<string>();
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const turnstile = useTurnstile();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      if (!captchaResponse) throw new Error('Invalid captcha response');

      try {
        await onSubmit({ email, password, captchaResponse });
      } catch (e) {
        turnstile.reset();
        setLoginError('Unable to log in with those credentials');
      }
    },
    [email, password, onSubmit, captchaResponse, turnstile],
  );

  const { theme } = useTheme();

  return (
    <PortalLayout>
      <div className="mx-auto mt-10 px-4 rounded text-center">
        <PageSeo title={meta.LOGIN.title} description={meta.LOGIN.description} />
        <Card className="py-4 px-6 max-w-lg mx-auto">
          <div className="my-5 mb-8">
            <h1 className="text-3xl">Sign in to TokenTax</h1>
          </div>

          {loginError && <FormStatus type="error">{loginError}</FormStatus>}
          <ActivatePlanNotice />

          <form data-cy="login-form" onSubmit={handleSubmit}>
            <Input
              className="mb-4"
              name="email"
              defaultValue={email}
              onChange={(event) => setEmail(event.target.value.toLowerCase())}
              placeholder={domain === 'happytax' ? 'firstname.lastname' : 'Email'}
              data-cy="input-email"
              autoFocus
            />
            <Input
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              className="mb-4"
              name="password"
              placeholder="Password"
              data-cy="input-password"
            />
            <Turnstile
              sitekey={TURNSTILE_SITE_KEY}
              size="flexible"
              theme={theme}
              onVerify={(token) => {
                setCaptchaResponse(token);
              }}
            />
            <div className="mt-3 mb-4">
              <Button large fullWidth type="submit" loading={isSubmitting} disabled={!captchaResponse}>
                Sign in
              </Button>
            </div>
          </form>
          <div className="mb-4">
            Forgot password? <TextLink to="/forgot">Reset</TextLink>
          </div>
          <div className="mb-4">
            New to TokenTax? <TextLink to="/signup">Create an account</TextLink>
          </div>
        </Card>
        <GoogleSignIn type="login" showError={(error) => setLoginError(error ?? undefined)} />
      </div>
    </PortalLayout>
  );
}
