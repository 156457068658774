import { useMachine } from '@xstate/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { CURRENT_TAX_YEAR } from '../../../../constants/taxYears';
import { LatestTxnReportsQuery } from '../../../../graphql/types';
import Modal, { ModalProps, showModal } from '../../../Modal';
import { textLinkVariantClassnames } from '../../../TextLink';
import UpsellModal from '../../Upsell/modal';
import generateFormMachine from '../machine';
import ChooseMethod from './ChooseMethod';
import ChooseSoftware from './ChooseSoftware';
import ChooseYear from './ChooseYear';
import Complete from './Complete';
import FileWith from './FileWith';
import Generate from './Generate';
import UploadDocuments from './UploadDocuments';
import { FfpByYear } from './types';

type LatestTxnReportsByYearType = NonNullable<
  LatestTxnReportsQuery['latestTxnReports']
>['latestTxnReportsByYear'][0];
type ReportTaxDetail = LatestTxnReportsByYearType['taxDetail'];
type MethodType = NonNullable<ReportTaxDetail['taxMethod']>;

export * from './types';

export interface GenerateFormModalProps extends ModalProps {
  /** triggers the form to start the complete return flow */
  completeReturn?: boolean;
  /** fullFilingPlan by tax year */
  fullFilingPlanByTaxYear?: FfpByYear;
  /** has any ffp*/
  hasFFP?: boolean;
  /** current detail country is set to USA */
  isUSBased: boolean;
  /** methods to populate select options*/
  methods: MethodType[];
  /** lineItemCount for selected report */
  lineItemCount?: number;
  /** report year */
  taxYear?: number;
  /** triggers the form to start the upsell flow */
  upsell?: boolean;
}

export default function GenerateFormModal({
  completeReturn,
  fullFilingPlanByTaxYear,
  hasFFP,
  isUSBased,
  lineItemCount,
  methods,
  onClose,
  taxYear = CURRENT_TAX_YEAR,
  upsell,
}: GenerateFormModalProps) {
  const [title, setTitle] = useState<string>('');
  const [year, setYear] = useState<number>(taxYear || CURRENT_TAX_YEAR);
  const [current, send] = useMachine(generateFormMachine);
  const choiceProps = { current, send, onClose };

  useEffect(() => {
    if (completeReturn) {
      send('FILE_WITH_TOKENTAX');
      send('CHOOSE_YEAR');
    }
  });

  const modalTitle = (): string | React.ReactNode => {
    if (upsell || current.matches('upsell')) {
      return '';
    }

    if (title) {
      return title;
    }

    if (current.matches('year')) {
      return 'What year are you filing for?';
    } else if (current.matches('method')) {
      return 'Select a method';
    } else if (current.matches('documents')) {
      return (
        <>
          Have you uploaded all of your filing year's tax documents to our{' '}
          <a
            href="https://tokentax.clientportal.com"
            target="_blank"
            rel="noreferrer noopener"
            className={classNames(textLinkVariantClassnames.primary)}
          >
            Tax Doc Uploader
          </a>
          ?
        </>
      );
    } else if (current.matches('complete')) {
      const noDocumentsUploaded = current?.historyValue?.states?.documents?.current === 'no';
      return noDocumentsUploaded
        ? 'Please upload all your tax documents.'
        : `I’m finished with my crypto calculations.`;
    } else if (current.matches('generate')) {
      return 'Your form is being generated';
    }

    return `How are you filing your return?`;
  };

  const modalSubtitle = (): string | undefined => {
    if (current.matches('file') && !upsell) {
      return `How are your filing your ${String(year)} tax return`;
    }

    return undefined;
  };

  const filingWithTokenTax = current.historyValue?.states?.file?.current === 'tokentax';

  const renderContent = () => {
    if (upsell || current.matches('upsell')) {
      return <UpsellModal onClose={onClose} />;
    }

    if (current.matches('file')) {
      return <FileWith {...choiceProps} hasFFP={hasFFP} isUSBased={isUSBased} />;
    } else if (current.matches('software')) {
      return <ChooseSoftware {...choiceProps} />;
    } else if (current.matches('year')) {
      return <ChooseYear {...choiceProps} setYear={setYear} ffpByYear={fullFilingPlanByTaxYear} />;
    } else if (current.matches('method')) {
      return (
        <ChooseMethod
          {...choiceProps}
          methods={methods}
          next={filingWithTokenTax ? 'UPLOAD_DOCUMENTS' : undefined}
        />
      );
    } else if (current.matches('documents')) {
      return <UploadDocuments {...choiceProps} />;
    } else if (current.matches('complete')) {
      return <Complete />;
    } else if (current.matches('generate')) {
      return (
        <Generate
          {...choiceProps}
          lineItemCount={lineItemCount}
          isUSBased={isUSBased}
          taxYear={taxYear}
          setTitle={setTitle}
        />
      );
    }
  };

  return (
    <Modal padded={false} title={modalTitle()} subtitle={modalSubtitle()} onClose={onClose}>
      {renderContent()}
    </Modal>
  );
}

export function showGenerateFormModal(props: GenerateFormModalProps) {
  showModal(<GenerateFormModal {...props} />);
}
