import moment from 'moment';
import { useState } from 'react';
import { DeepNonNullable } from 'utility-types';
import { InvoicePaymentMethod, InvoiceQuery } from '../../graphql/types';

type Invoice = InvoiceQuery['invoice'];

const INVOICE_DUE_DATE_DISTANCE = [1, 'week'];

export const useInvoice = () => {
  return useState<Omit<DeepNonNullable<Invoice>, 'description'> & Pick<Invoice, 'description'>>({
    __typename: 'Invoice' as const,
    userId: 0, // not relevant
    id: 'unused',
    number: '12312',
    created: 0,
    createdBy: 'notshown@irrelevant.us',
    hideDefaultCrypto: false,
    customerEmail: '', // set after user is fetched
    description: null,
    dueDate: Math.floor(
      moment()
        .add(...INVOICE_DUE_DATE_DISTANCE)
        .valueOf() / 1000,
    ),
    newUser: false,
    currency: 'USD',
    amountDue: 0,
    amountRemaining: 0,
    paid: false,
    paidAt: 123, // not shown
    voided: false,
    lines: [],
    paymentMethod: InvoicePaymentMethod.card,
    cryptocurrency: '',
    txnHash: '',
    products: {
      __typename: 'Products' as const,
      cryptoOnlyPlans: [],
      fullFilingPlans: [],
      addons: [],
    },
  });
};
