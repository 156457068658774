import { Machine } from 'xstate';

/* eslint-disable @typescript-eslint/ban-types  */
/* eslint-disable @typescript-eslint/no-empty-interface */
interface GenerateFormStateSchema {
  states: {
    file: {
      states: {
        unknown: {};
        software: {};
        tokentax: {};
        cpa: {};
      };
    };
    software: {
      states: {
        unknown: {};
        turbotaxDesktop: {};
        turbotaxOnline: {};
        taxact: {};
        other: {};
      };
    };
    year: {};
    method: {
      states: {
        unknown: {};
        fifo: {};
        lifo: {};
        hpfo: {};
        minimization: {};
        averageCost: {};
      };
    };
    documents: {
      states: {
        unknown: {};
        yes: {};
        no: {};
      };
    };
    generate: {};
    complete: {};
    upsell: {};
  };
}

// The events that the machine handles
type GenerateFormEvent =
  | { type: 'GENERATE' }
  | { type: 'CHOOSE_SOFTWARE' }
  | { type: 'FILE_WITH_SOFTWARE' }
  | { type: 'FILE_WITH_TOKENTAX' }
  | { type: 'FILE_WITH_CPA' }
  | { type: 'CHOOSE_YEAR' }
  | { type: 'CHOOSE_METHOD' }
  | { type: 'TURBOTAX_DESKTOP' }
  | { type: 'TURBOTAX_ONLINE' }
  | { type: 'TAXACT' }
  | { type: 'OTHER' }
  | { type: 'FIFO' }
  | { type: 'LIFO' }
  | { type: 'HPFO' }
  | { type: 'MINIMIZATION' }
  | { type: 'AVERAGE_COST' }
  | { type: 'UPLOAD_DOCUMENTS' }
  | { type: 'YES' }
  | { type: 'NO' }
  | { type: 'COMPLETE' }
  | { type: 'UPSELL' };

// TODO: move form state to Context
interface GenerateFormContext {}

const filingChoices = {
  FILE_WITH_SOFTWARE: 'software',
  FILE_WITH_TOKENTAX: 'tokentax',
  FILE_WITH_CPA: 'cpa',
};

const softwareChoices = {
  TURBOTAX_DESKTOP: '#turbotaxDesktop',
  TURBOTAX_ONLINE: '#turbotaxOnline',
  TAXACT: '#taxact',
  OTHER: '#other',
};

const methodChoices = {
  FIFO: 'fifo',
  LIFO: 'lifo',
  HPFO: 'hpfo',
  MINIMIZATION: 'minimization',
  AVERAGE_COST: 'averageCost',
};

const documentChoices = {
  YES: 'yes',
  NO: 'no',
};

const generateFormMachine = Machine<GenerateFormContext, GenerateFormStateSchema, GenerateFormEvent>({
  id: 'genForm',
  initial: 'file',
  states: {
    file: {
      initial: 'unknown',
      states: {
        unknown: {
          on: {
            ...filingChoices,
          },
        },
        software: {
          on: {
            ...filingChoices,
            CHOOSE_SOFTWARE: '#software',
          },
        },
        tokentax: {
          on: {
            ...filingChoices,
            CHOOSE_METHOD: '#method',
            CHOOSE_YEAR: '#year',
            UPSELL: '#upsell',
          },
        },
        cpa: {
          on: {
            ...filingChoices,
            CHOOSE_METHOD: '#method',
          },
        },
      },
    },
    software: {
      id: 'software',
      initial: 'unknown',
      states: {
        unknown: {
          on: {
            ...softwareChoices,
          },
        },
        turbotaxDesktop: {
          id: 'turbotaxDesktop',
          on: {
            CHOOSE_METHOD: '#method',
            ...softwareChoices,
          },
        },
        turbotaxOnline: {
          id: 'turbotaxOnline',
          on: {
            CHOOSE_METHOD: '#method',
            ...softwareChoices,
          },
        },
        taxact: {
          id: 'taxact',
          on: {
            CHOOSE_METHOD: '#method',
            ...softwareChoices,
          },
        },
        other: {
          id: 'other',
          on: {
            CHOOSE_METHOD: '#method',
            ...softwareChoices,
          },
        },
      },
    },

    method: {
      id: 'method',
      initial: 'unknown',
      states: {
        unknown: {
          on: {
            ...methodChoices,
          },
        },
        fifo: {
          on: {
            GENERATE: '#generate',
            UPLOAD_DOCUMENTS: '#documents',
            ...methodChoices,
          },
        },
        lifo: {
          on: {
            GENERATE: '#generate',
            UPLOAD_DOCUMENTS: '#documents',
            ...methodChoices,
          },
        },
        hpfo: {
          on: {
            GENERATE: '#generate',
            UPLOAD_DOCUMENTS: '#documents',
            ...methodChoices,
          },
        },
        minimization: {
          on: {
            GENERATE: '#generate',
            UPLOAD_DOCUMENTS: '#documents',
            ...methodChoices,
          },
        },
        averageCost: {
          on: {
            GENERATE: '#generate',
            UPLOAD_DOCUMENTS: '#documents',
            ...methodChoices,
          },
        },
      },
    },

    year: {
      id: 'year',
      on: {
        CHOOSE_METHOD: '#method',
      },
    },

    documents: {
      id: 'documents',
      initial: 'unknown',
      states: {
        unknown: {
          on: { ...documentChoices },
        },
        yes: {
          on: {
            COMPLETE: '#complete',
            ...documentChoices,
          },
        },
        no: {
          on: {
            COMPLETE: '#complete',
            ...documentChoices,
          },
        },
      },
    },

    generate: {
      id: 'generate',
    },

    complete: {
      id: 'complete',
      on: {
        UPSELL: '#upsell',
      },
    },

    upsell: {
      id: 'upsell',
    },
  },
});

export default generateFormMachine;
