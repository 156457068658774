import classNames from 'classnames';
import React, { useMemo } from 'react';
import { MultiSelectStringFilter } from '../../../graphql/types';
import Dropdown, { DropdownHandle } from '../../Dropdown';
import Operator from '../Operator';
import PillBody from '../PillBody';
import { BasePillProps, SelectOption } from '../types';
import MultiSelectInput from './MultiSelectInput';

export type MultiSelectPillProps = BasePillProps<MultiSelectStringFilter> & {
  multiSelectOptions: SelectOption[];
};

function MultiSelectPill({
  label,
  multiSelectOptions,
  operators,
  filter,
  onChange,
  onDelete,
}: MultiSelectPillProps) {
  const [open, setOpen] = React.useState(false);
  const dropdownValuesRef = React.useRef<DropdownHandle>(null);

  React.useEffect(() => {
    dropdownValuesRef.current?.hide();
  }, [filter]);

  const valueString = useMemo(() => {
    const options = multiSelectOptions || [];
    const selectedOptions = options.filter(({ value }) => filter?.values.includes(value));
    return selectedOptions.map(({ label }) => label).join(', ');
  }, [filter?.values, multiSelectOptions]);

  if (!filter) return null;

  return (
    <PillBody label={label} onDelete={onDelete}>
      <div className="border-r border-light-control dark:border-dark-control">
        <Operator {...{ operators, filter, onChange }} />
      </div>
      <div
        className={classNames('hover:bg-light-selected-faint dark:hover:bg-dark-selected-faint', {
          'bg-light-selected-faint dark:bg-dark-selected-faint': open,
        })}
      >
        <Dropdown
          isDisabled={false}
          label={valueString}
          ref={dropdownValuesRef}
          onShow={() => setOpen(true)}
          onHide={() => setOpen(false)}
          noStyles
        >
          {open && <MultiSelectInput {...{ filter, onChange, label, multiSelectOptions }} />}
        </Dropdown>
      </div>
    </PillBody>
  );
}

export default React.memo(MultiSelectPill) as typeof MultiSelectPill;
