import { isEmpty, isEqual } from 'lodash';
import {
  BooleanFilter,
  DateRangeFilter,
  LineItemSortOptions,
  LineItemsFilterQuery,
  MultiSelectIntFilter,
  SortDirectionOptions,
  TxnCurrencyFilter,
} from '../../../graphql/types';
import { client } from '../../../lib/apollo';

import { PaginationState } from '../../utils/pagination';
import { NUM_ITEMS } from './constants';
import { defaultPaginationOptions, defaultSortState } from './defaults';
import { LineItemsTableQueryParams, sortState } from './types';
import {
  expandedIdVar,
  filterQueryVar,
  initialLoadingVar,
  initialOptionsSetVar,
  initialQuerySetVar,
  paginationVar,
  sortVar,
} from './vars';

const getFilterQuery = () => {
  const filterQueryState = filterQueryVar();
  return JSON.parse(filterQueryState || '{}') as LineItemsFilterQuery;
};

const setStateFromQuery = (query: LineItemsTableQueryParams): void => {
  // TODO: abstract the pagination and sort state setting logic to utils
  const paginationState = paginationVar();
  if (query.page && paginationState.page !== query.page) {
    // need to add skip to the pagination if trying to go directly to later page
    const paginationOptions: PaginationState = {
      pageSize: NUM_ITEMS,
      page: query.page,
    };

    paginationVar(paginationOptions);
  }

  if (query.sortBy || query.sortDir) {
    const currentSortState = sortVar();
    const newSortState = { ...currentSortState };
    if (query.sortBy && Object.values(LineItemSortOptions).includes(query.sortBy as LineItemSortOptions)) {
      newSortState.sortBy = query.sortBy as LineItemSortOptions;
    }
    if (
      query.sortDir &&
      Object.values(SortDirectionOptions).includes(query.sortDir as SortDirectionOptions)
    ) {
      newSortState.sortDir = query.sortDir as SortDirectionOptions;
    }
    if (
      currentSortState.sortBy !== newSortState.sortBy ||
      currentSortState.sortDir !== newSortState.sortDir
    ) {
      sortVar(newSortState);
    }
  }

  if (query.filterQuery) {
    const filterQuery = getFilterQuery();
    if (!isEqual(filterQuery, query.filterQuery)) {
      filterQueryVar(query.filterQuery);
    }
  }
};

export const actions = {
  setLineItemFilterQuery: (query?: LineItemsFilterQuery) => {
    filterQueryVar(JSON.stringify(query) || undefined);
  },
  addFilter: (filters: Partial<LineItemsFilterQuery>) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      ...filters,
    };
    actions.setLineItemFilterQuery(newFilters);
  },
  addBooleanFilter: (filter: BooleanFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      boolean: filterQuery.boolean ? [...filterQuery.boolean, filter] : [filter],
    };
    actions.setLineItemFilterQuery(newFilters);
  },
  editBooleanFilter: (index: number, filter: BooleanFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      boolean: filterQuery.boolean
        ? [...filterQuery.boolean.slice(0, index), filter, ...filterQuery.boolean.slice(index + 1)]
        : [filter],
    };
    actions.setLineItemFilterQuery(newFilters);
  },
  setDateFilter: (dateFilter: DateRangeFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      date: dateFilter,
    };
    actions.setLineItemFilterQuery(newFilters);
  },
  setAccountFilter: (filter: MultiSelectIntFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      account: filter,
    };
    actions.setLineItemFilterQuery(newFilters);
  },
  clearAccoutFilter: () => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
    };
    delete newFilters.account;
    actions.setLineItemFilterQuery(newFilters);
  },
  addCurrencyFilter: (filter: TxnCurrencyFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      currency: filterQuery.currency ? [...filterQuery.currency, filter] : [filter],
    };
    actions.setLineItemFilterQuery(newFilters);
  },
  editCurrencyFilter: (index: number, filter: TxnCurrencyFilter) => {
    const filterQuery = getFilterQuery();
    const newFilters = {
      ...filterQuery,
      currency: filterQuery.currency
        ? [...filterQuery.currency.slice(0, index), filter, ...filterQuery.currency.slice(index + 1)]
        : [filter],
    };
    actions.setLineItemFilterQuery(newFilters);
  },

  removeFilter: (key: keyof LineItemsFilterQuery, index?: number) => {
    const filterQuery = getFilterQuery();
    const value = filterQuery[key];

    if (index !== undefined && Array.isArray(value)) {
      const newValue = [...value].filter((_, i) => i !== index);
      const newFilters = {
        ...filterQuery,
        [key]: newValue,
      };
      if (newValue.length === 0) {
        delete newFilters[key];
      }
      actions.setLineItemFilterQuery(isEmpty(newFilters) ? undefined : newFilters);
      return;
    }

    const newFilters = { ...filterQuery };
    delete newFilters[key];
    actions.setLineItemFilterQuery(isEmpty(newFilters) ? undefined : newFilters);
  },
  setSortBy: (sort: LineItemSortOptions): sortState => {
    const prevSortState = sortVar();

    return sortVar({ ...prevSortState, sortBy: sort });
  },
  setSortDir: (dir: SortDirectionOptions): sortState => {
    const prevSortState = sortVar();

    return sortVar({ ...prevSortState, sortDir: dir });
  },
  setPage: (page: number): void => {
    if (page === paginationVar().page) return;
    paginationVar({ ...paginationVar(), page });
  },
  resetPagination: (): void => {
    paginationVar(defaultPaginationOptions);
  },
  resetAll: (): LineItemsTableQueryParams => {
    paginationVar(defaultPaginationOptions);
    filterQueryVar(undefined);
    sortVar(defaultSortState);
    return {
      page: 1,
      sortBy: defaultSortState.sortBy,
      sortDir: defaultSortState.sortDir,
      filterQuery: undefined,
    };
  },
  setInitialOptions: (initialQuery: LineItemsTableQueryParams): boolean => {
    setStateFromQuery(initialQuery);
    return initialOptionsSetVar(true);
  },
  setStateFromQuery,
  setInitialLoading: (initialLoading: boolean): boolean => {
    return initialLoadingVar(initialLoading);
  },
  setInitialQuery: (): LineItemsTableQueryParams => {
    const { sortBy, sortDir } = sortVar();
    const filterQueryState = filterQueryVar();
    initialQuerySetVar(true);

    return {
      page: paginationVar().page,
      sortBy,
      sortDir,
      filterQuery: filterQueryState,
    };
  },
  resetInitialQuerySet: (): boolean => {
    return initialQuerySetVar(false);
  },
  setExpandedId: (id: number): number | undefined => {
    return expandedIdVar(id);
  },
  resetExpandedId: (): number | undefined => {
    return expandedIdVar(undefined);
  },
  resetState: (): void => {
    sortVar(defaultSortState);
    paginationVar(defaultPaginationOptions);
    filterQueryVar(undefined);
    initialLoadingVar(true);
    initialOptionsSetVar(false);
    initialQuerySetVar(false);
    expandedIdVar(undefined);
  },
};

client.onResetStore(async () => {
  actions.resetState();
});
