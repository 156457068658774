import React from 'react';
import Modal from '../Modal';

export interface PanelProps {
  /** content inside modal */
  children?: React.ReactNode;
  /** modal is visible when set to true */
  isOpen?: boolean;
  /** loading state */
  loading?: boolean;
  /** callback when react-modal is closed*/
  onClose?: () => void;
  /** header text */
  title?: string | React.ReactNode;
  /** subheader text */
  subtitle?: string | React.ReactNode;
  /* if true, content in the modal will be padded */
  padded?: boolean;
  /* if defined, apply this padding to the title */
  titlePadding?: string;
}

function Panel({
  children,
  loading,
  isOpen = true,
  onClose,
  subtitle,
  title,
  padded,
  titlePadding,
}: PanelProps) {
  return (
    <Modal
      {...{
        isOpen,
        loading,
        onClose,
        title,
        subtitle,
        padded,
        titlePadding,
      }}
      className="min-h-screen float-right w-screen lg:w-240 pt-4"
      style={{
        content: { marginTop: '0', marginBottom: '0', maxWidth: '100%' },
      }}
    >
      {children}
    </Modal>
  );
}

export default React.memo(Panel);
