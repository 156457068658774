import { capitalize } from 'lodash';
import React from 'react';
import {
  MultiSelectIntFilter,
  SpecIdMatchSide,
  TxnFilterQuery,
  TxnSearchFilterKeys,
} from '../../../../graphql/types';
import FilterBuilder, { DropdownPill, MultiSearchPill } from '../../../FilterBuilder';
import CurrencyFilter, { CurrencyInput } from '../../../FilterBuilder/CurrencyFilter';
import DateRangeFilter, { DateRangeInput } from '../../../FilterBuilder/DateRangeFilter';
import { MultiSearchInput } from '../../../FilterBuilder/MultiSearchFilter';
import MultiSelectFilter, { MultiSelectInput } from '../../../FilterBuilder/MultiSelectFilter';
import SearchFilter, { SearchInput } from '../../../FilterBuilder/SearchFilter';
import { actions } from '../../actions';
import SpecIdPill from './SpecIdPill';
import { useFiltersConfig } from './useFiltersConfig';

// {key: [affirmativeTerm, negativeTerm]}
const booleanOperatorMap: { [key: string]: string[] } = {
  hasComments: ['Has', 'Does not have'],
  missingPrice: ['Missing', 'Has'],
  reviewed: ['Has been', 'Has not been'],
  manuallyEdited: ['Has been', 'Has not been'],
  automaticallyEdited: ['Has been', 'Has not been'],
  isSpam: ['Is', 'Is not'],
  hasMovement: ['Is', 'Is not'],
};

const booleanLabelMap: { [key: string]: string } = {
  hasComments: 'Comments',
  missingPrice: 'Price',
  reviewed: 'Reviewed',
  manuallyEdited: 'Manually Edited',
  automaticallyEdited: 'Reconciled Automatically',
  isSpam: 'Spam',
  hasMovement: 'Part of Movement transaction',
};

function Filters({ filterQuery }: { filterQuery: TxnFilterQuery }) {
  const { currency, boolean, methodId, reconIdentifier, specId } = filterQuery;
  const filtersMap = useFiltersConfig();

  // firstCurrencyFilter will always be at least `undefined`, causing the currency filter to always be rendered
  const [firstCurrencyFilter, ...restCurrencyFilters] = currency || [];

  function renderStaticSearchFilters(key: TxnSearchFilterKeys) {
    // firstFilter will always be at least `undefined`, causing the filter to always be rendered
    const [firstFilter, ...restFilters] = filterQuery[key] || [];

    return [firstFilter, ...restFilters]?.map((filter, idx) => (
      <SearchFilter
        {...filtersMap[key]}
        key={idx}
        filter={filter}
        onChange={(filter) => actions.editSearchFilter(idx, filter)}
        onDelete={() => actions.removeFilter(TxnSearchFilterKeys[key], idx)}
      />
    ));
  }

  return (
    <FilterBuilder
      filterQuery={filterQuery}
      filtersMap={filtersMap}
      inputMenuWidth="146px"
      renderSelectedInput={({ selectedInput: key }) => {
        switch (key) {
          case 'date':
          case 'editedAt':
            return (
              <DateRangeInput
                {...filtersMap[key]}
                onChange={(filter) => actions.setFilters({ [key]: filter })}
              />
            );
          case 'currency':
            return (
              <CurrencyInput
                {...filtersMap.currency}
                onChange={(filter) => actions.addCurrencyFilter(filter)}
              />
            );
          case 'fromAddress':
          case 'toAddress':
          case 'location':
          case 'txnHash':
          case 'methodId':
            return <SearchInput {...filtersMap[key]} onChange={actions.addSearchFilter} />;
          case 'reconIdentifier':
            return <SearchInput {...filtersMap[key]} onChange={actions.addSearchFilter} />;
          case 'txnType':
            return (
              <MultiSelectInput
                {...filtersMap.txnType}
                onChange={(filter) => actions.setFilters({ txnType: filter })}
              />
            );
          case 'importType':
            return (
              <MultiSelectInput
                {...filtersMap.importType}
                onChange={(filter) => actions.setFilters({ importType: filter })}
              />
            );
          case 'imports':
            return (
              <MultiSearchInput<MultiSelectIntFilter, number>
                {...filtersMap.imports}
                onChange={(filter) => actions.setFilters({ imports: filter })}
              />
            );
          case 'vendors':
            return (
              <MultiSearchInput<MultiSelectIntFilter, number>
                {...filtersMap.vendors}
                onChange={(filter) => actions.setVendorsFilter(filter)}
              />
            );
          case 'accountCredits':
            return (
              <MultiSearchInput<MultiSelectIntFilter, number>
                {...filtersMap.accountCredits}
                onChange={(filter) => actions.setAccountCreditsFilter(filter)}
              />
            );
          case 'accountDebits':
            return (
              <MultiSearchInput<MultiSelectIntFilter, number>
                {...filtersMap.accountDebits}
                onChange={(filter) => actions.setAccountDebitsFilter(filter)}
              />
            );
          default:
            return null;
        }
      }}
    >
      {specId?.sellId && <SpecIdPill />}
      {[firstCurrencyFilter, ...restCurrencyFilters].map((filter, idx) => (
        <CurrencyFilter
          {...filtersMap.currency}
          key={idx}
          filter={filter}
          onChange={(filter) => actions.editCurrencyFilter(idx, filter)}
          onDelete={() => actions.removeFilter('currency', idx)}
        />
      ))}

      {renderStaticSearchFilters(TxnSearchFilterKeys.txnHash)}

      <MultiSelectFilter
        {...filtersMap.txnType}
        onChange={(filter) => actions.setFilters({ txnType: filter })}
        onDelete={() => actions.removeFilter('txnType')}
      />

      {renderStaticSearchFilters(TxnSearchFilterKeys.toAddress)}
      {renderStaticSearchFilters(TxnSearchFilterKeys.fromAddress)}
      {renderStaticSearchFilters(TxnSearchFilterKeys.location)}

      <DateRangeFilter
        {...filtersMap.date}
        filter={filterQuery.date}
        onChange={(filter) => actions.setFilters({ date: filter })}
        onDelete={() => actions.removeFilter('date')}
      />

      {boolean?.map((filter, idx) => {
        // TODO this narrowing-down is necessary as some keys in `boolean` (e.g. `zeroProceeds`) are used by unrelated queries
        // we may want to revisit this
        const filterKey = filter.key as keyof typeof filtersMap & `${typeof filter.key}`;
        return (
          <DropdownPill<boolean>
            {...filtersMap[filterKey]}
            filter={filter.value}
            key={filterKey}
            options={[
              { label: booleanOperatorMap[filterKey][0], value: true },
              { label: booleanOperatorMap[filterKey][1], value: false },
            ]}
            pillCopy={booleanLabelMap[filterKey]}
            onChange={(value) => actions.editBooleanFilter(idx, { ...filter, value })}
            onDelete={() => actions.removeFilter('boolean', idx)}
          />
        );
      })}

      {specId?.side && (
        <DropdownPill<SpecIdMatchSide>
          {...filtersMap.specIdSide}
          key="specIdSide"
          filter={specId?.side}
          labelFirst
          options={Object.keys(SpecIdMatchSide).map((side) => ({
            label: capitalize(side),
            value: SpecIdMatchSide[side as keyof typeof SpecIdMatchSide],
          }))}
          pillCopy="Spec ID Side is"
          onChange={(side) => actions.setSpecIdSide(side)}
          onDelete={() => actions.unsetSpecIdSide()}
        />
      )}

      {filterQuery.imports && (
        <MultiSearchPill<MultiSelectIntFilter, number>
          filter={filterQuery.imports}
          {...filtersMap.imports}
          onChange={(filter) => actions.setFilters({ imports: filter })}
          onDelete={() => actions.removeFilter('imports')}
        />
      )}

      {filterQuery.vendors && (
        <MultiSearchPill<MultiSelectIntFilter, number>
          filter={filterQuery.vendors}
          {...filtersMap.vendors}
          onChange={(filter) => actions.setVendorsFilter(filter)}
          onDelete={() => actions.removeFilter('vendors')}
        />
      )}

      {filterQuery.accountCredits && (
        <MultiSearchPill<MultiSelectIntFilter, number>
          filter={filterQuery.accountCredits}
          {...filtersMap.accountCredits}
          onChange={(filter) => actions.setAccountCreditsFilter(filter)}
          onDelete={() => actions.removeFilter('accountCredits')}
        />
      )}

      {filterQuery.accountDebits && (
        <MultiSearchPill<MultiSelectIntFilter, number>
          filter={filterQuery.accountDebits}
          {...filtersMap.accountDebits}
          onChange={(filter) => actions.setAccountDebitsFilter(filter)}
          onDelete={() => actions.removeFilter('accountDebits')}
        />
      )}

      {methodId?.map((filter, idx) => (
        <SearchFilter
          {...filtersMap.methodId}
          key={idx}
          filter={filter}
          onChange={(filter) => actions.editSearchFilter(idx, filter)}
          onDelete={() => actions.removeFilter('methodId', idx)}
        />
      ))}

      {reconIdentifier?.map((filter, idx) => (
        <SearchFilter
          {...filtersMap.reconIdentifier}
          key={idx}
          filter={filter}
          onChange={(filter) => actions.editSearchFilter(idx, filter)}
          onDelete={() => actions.removeFilter('reconIdentifier', idx)}
        />
      ))}

      {filterQuery.importType && (
        <MultiSelectFilter
          {...filtersMap.importType}
          onChange={(filter) => actions.setFilters({ importType: filter })}
          onDelete={() => actions.removeFilter('importType')}
        />
      )}

      {filterQuery.editedAt && (
        <DateRangeFilter
          {...filtersMap.editedAt}
          filter={filterQuery.editedAt}
          onChange={(filter) => actions.setFilters({ editedAt: filter })}
          onDelete={() => actions.removeFilter('editedAt')}
        />
      )}
    </FilterBuilder>
  );
}

export default React.memo(Filters) as typeof Filters;
