import { gql } from '@apollo/client';
import { UnreconciledTxnsDataQuery, UnreconciledTxnsDataQueryVariables } from '../../../../graphql/types';
import { client } from '../../../../lib/apollo';

export const UNRECONCILED_TRANSACTION_DATA_FRAGMENT = gql`
  fragment UnreconciledTransactionDataFragment on Txn {
    id
    txnType
    buyAddress
    buyCurrency
    buyPrice
    buyQuantity
    buyTokenId
    sellAddress
    sellCurrency
    sellPrice
    sellQuantity
    sellTokenId
    feeCurrency
    feeTokenId
    feeQuantity
    feePrice
    blocksvcToAddress
    blocksvcFromAddress
  }
`;

export const UNRECONCILED_TRANSACTIONS_DATA = gql`
  query UnreconciledTxnsData($txnHashes: [String!]!, $credentialType: String!) {
    unreconciledTxnsData(txnHashes: $txnHashes, credentialType: $credentialType) {
      txnHash
      ecosystem
      txns {
        ...UnreconciledTransactionDataFragment
      }
    }
  }
  ${UNRECONCILED_TRANSACTION_DATA_FRAGMENT}
`;

export const getUnreconciledTransactionData = async ({
  txnHash,
  credentialType,
}: {
  txnHash: string;
  credentialType: string;
}) => {
  const dataArray = await getUnreconciledTransactionsData({
    txnHashes: [txnHash],
    credentialType,
  });

  const dataForHash = dataArray.find((data) => data.txnHash === txnHash);
  if (!dataForHash) {
    throw new Error('No data found for txnHash');
  }
  return dataForHash;
};

export const getUnreconciledTransactionsData = async ({
  txnHashes,
  credentialType,
}: {
  txnHashes: string[];
  credentialType: string;
}) => {
  const { data } = await client.query<UnreconciledTxnsDataQuery, UnreconciledTxnsDataQueryVariables>({
    query: UNRECONCILED_TRANSACTIONS_DATA,
    variables: {
      txnHashes,
      credentialType,
    },
  });

  return data.unreconciledTxnsData;
};
