import { useCallback, useContext, useState } from 'react';
import { RecalcContext } from '../../../contexts';
import { useCreateMovement } from '../../../graphql/mutations';
import { CreateMovementMutationVariables, TxnFragment } from '../../../graphql/types';
import { useObject } from '../../../lib/hooks';
import { showErrorFlash } from '../../Flash';
import { showCreateMovementModal } from '../CreateMovementModal';
import { actions } from '../actions';
import { AllTransactionsContextType } from './context';

export const useOnCreateMovement = ({ refetch }: Required<Pick<AllTransactionsContextType, 'refetch'>>) => {
  const [txnsUsedInMovement, setTxnsUsedInMovement] = useState<string[]>([]);
  const { refetchNeedsRecalc } = useContext(RecalcContext);

  const [createMovement, { loading }] = useCreateMovement({
    onSuccess: () => {
      refetchNeedsRecalc();
    },
  });

  const onCreateMovement = useCallback(
    async (txns: TxnFragment[]) => {
      if (loading) return;

      const txnIds = txns.map(({ id }) => id);
      if (txnIds.length !== 2) {
        showErrorFlash('Must use exactly two transactions to create a movement.');
        return;
      }

      const deposit = txns.find(({ txnType }) => txnType === 'deposit');
      const withdrawal = txns.find(({ txnType }) => txnType === 'withdrawal');

      if (!deposit || !withdrawal) {
        showErrorFlash('Deposit and Withdrawal are required to create a movement.');
        return;
      }

      if (!deposit.credential?.accountId || !withdrawal.credential?.accountId) {
        showErrorFlash('Deposit and Withdrawal must both have an account to create a movement.');
        return;
      }

      const initialData: CreateMovementMutationVariables & { txns: TxnFragment[] } = {
        date: withdrawal.txnTimestamp,
        currency: deposit.buyCurrency,
        amount: deposit.buyQuantity,
        toAccountId: deposit.credential.accountId,
        fromAccountId: withdrawal.credential.accountId,
        txnIds,
        txns: txns,
      };

      const handleSubmit = async (formData: CreateMovementMutationVariables) => {
        setTxnsUsedInMovement(txnIds);
        try {
          await createMovement({
            variables: formData,
          });

          actions.deselect(txnIds);

          refetch();
          // We already show a success flash in the mutation hook
        } catch (e) {
          showErrorFlash('The movement could not be created.');
        } finally {
          setTxnsUsedInMovement([]);
        }
      };

      const handleClose = () => {
        setTxnsUsedInMovement([]);
      };

      showCreateMovementModal({
        onSubmit: handleSubmit,
        onClose: handleClose,
        initialData,
      });
    },
    [refetch, createMovement, loading],
  );

  return useObject({
    txnsUsedInMovement,
    onCreateMovement,
  });
};
