import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useCallback, useContext } from 'react';
import { UserContext } from '../../../contexts';
import { TxnsQuery } from '../../../graphql/types';
import Icon from '../../Icon';
import { Cell } from '../../Table';
import TextLink from '../../TextLink';
import Tooltip from '../../Tooltip/index';
import { AllTransactionsContext } from '../Provider/context';
import { actions } from '../actions';

type Txn = TxnsQuery['txns']['edges'][0];

interface Props {
  cell: Cell<Txn>;
  rowIsDisabled?: boolean;
}

function ActionsColumn({ cell, rowIsDisabled }: Props) {
  const { onEdit, onReview, onDelete } = useContext(AllTransactionsContext);
  const { isTokenTaxAdmin } = useContext(UserContext);
  const txn = cell.row.original;

  const {
    id: txnId,
    updatedAt,
    description,
    usdSpotPrice,
    txnType,
    isEdited,
    reviewed,
    editedByReconGuideJob,
    specIdMatchesAsSell,
    specIdMatchesAsComponent,
    hasMovement,
  } = txn;

  const missingPrice = !usdSpotPrice && !['withdrawal', 'deposit'].includes(txnType);

  const setSpecId = useCallback(() => {
    if (!isTokenTaxAdmin) return;
    if (specIdMatchesAsSell.length > 0) {
      actions.setSpecIdTxn(txnId);
      return;
    }
    for (let i = 0; i < specIdMatchesAsComponent.length; i++) {
      actions.setSpecIdTxn(specIdMatchesAsComponent[i].sellId, i > 0);
    }
  }, [isTokenTaxAdmin, specIdMatchesAsComponent, specIdMatchesAsSell.length, txnId]);

  return (
    <div className="flex justify-end items-center h-full relative">
      <Tooltip icon="dollar" place="top" invisible={!missingPrice}>
        Missing fiat price.
      </Tooltip>

      <Tooltip icon="checked" place="top" invisible={!reviewed || undefined}>
        Has been reviewed
      </Tooltip>

      <Tooltip icon="edited" place="top" invisible={!isEdited}>
        Transaction edited at {moment(updatedAt).format('MM/DD/YYYY h:mm A')}
      </Tooltip>

      {isTokenTaxAdmin && (
        // todo @ellebaum - remove admin only on GA release
        <Tooltip icon="sync_alt" color="gray" place="top" invisible={!hasMovement}>
          Part of a Movement transaction
        </Tooltip>
      )}

      {isTokenTaxAdmin && (
        <Tooltip icon="bolt" place="top" invisible={!editedByReconGuideJob}>
          Automatically reconciled
        </Tooltip>
      )}

      <span
        className={classNames(
          rowIsDisabled && '!invisible',
          'inline-flex',
          isTokenTaxAdmin && 'cursor-pointer',
        )}
        onClick={setSpecId}
      >
        <Tooltip
          appendTo={document.body}
          interactive
          icon="chain"
          place="top"
          invisible={!(specIdMatchesAsSell.length || specIdMatchesAsComponent.length)}
        >
          This transaction has been used in a specific identification grouping
          <br />
          {isTokenTaxAdmin && <TextLink onClick={setSpecId}>View Specific ID link</TextLink>}
        </Tooltip>
      </span>

      <Tooltip icon="comment" place="top" invisible={!description}>
        {description}
      </Tooltip>

      <span className={classNames(rowIsDisabled && '!invisible', 'ml-2 row_visible_if_hovered')}>
        <Icon type="check" className="cursor-pointer" onClick={() => onReview?.([txn], !reviewed)} />
      </span>

      <span className={classNames(rowIsDisabled && '!invisible', 'ml-2 row_visible_if_hovered')}>
        <Icon
          type="edit"
          className="cursor-pointer"
          onClick={useCallback(() => {
            onEdit?.([txn]);
          }, [onEdit, txn])}
        />
      </span>

      <span className={classNames(rowIsDisabled && '!invisible', 'ml-2 row_visible_if_hovered')}>
        <Icon
          type="delete"
          className="cursor-pointer"
          onClick={useCallback(() => onDelete?.([txnId], true), [txnId, onDelete])}
        />
      </span>
    </div>
  );
}

export default React.memo(ActionsColumn);
