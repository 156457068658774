import cx from 'classnames';
import { difference, escapeRegExp } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Token, TokensContext } from '../../contexts/TokensContext';
import loupeIcon from '../../images/loupe.svg';
import baseClassName from '../Input/styles';
import TextLink from '../TextLink';

const MAX_RESULTS_SHOWN = 5;

const highlightMatch = (str: string, match: string) => {
  const matchRegex = new RegExp(match, 'i');
  const inputLength = match.length;
  const matchIndex = str.search(matchRegex);

  if (matchIndex === -1) return str;

  return (
    <>
      {str.substring(0, matchIndex)}
      <u>{str.substring(matchIndex, matchIndex + inputLength)}</u>
      {str.substring(matchIndex + inputLength, str.length)}
    </>
  );
};

export type TokenSearchHandle = {
  focus: () => void;
};

interface Props {
  initialSymbolValue: string; // this is an uncontrolled component
  onTokenSelected: (token: Token) => void;
  onSymbolChange?: (symbol: string) => void;
  searchRef?: React.RefObject<TokenSearchHandle>;
  inputClassNames?: string;
  resultsClassNames?: string;
  wrapperClassNames?: string;
  showLoupe?: boolean;
  showClearSearch?: boolean;
  focusOnMount?: boolean;
}

function TokenSearch({
  initialSymbolValue,
  onTokenSelected,
  onSymbolChange,
  searchRef,
  inputClassNames,
  resultsClassNames,
  wrapperClassNames,
  showLoupe,
  showClearSearch,
  focusOnMount,
}: Props) {
  const { tokens } = useContext(TokensContext);
  const [inputValue, setInputValue] = useState(initialSymbolValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [showResults, setShowResults] = useState<boolean>(false);

  const matchingTokens = useMemo(() => {
    if (inputValue.length === 0) return [];
    const perfectSymbolMatches: Token[] = [];
    const suggestions = tokens
      .filter((token) => {
        const { symbol, name } = token;
        const matchRegex = new RegExp(escapeRegExp(inputValue), 'i');
        if (symbol && symbol?.toLowerCase() === inputValue.toLowerCase()) {
          perfectSymbolMatches.push(token);
        }
        return (symbol && matchRegex.test(symbol)) || matchRegex.test(name);
      })
      .slice(0, MAX_RESULTS_SHOWN);
    const perfectMatchesNotIncluded = difference(perfectSymbolMatches, suggestions);
    return [...suggestions, ...perfectMatchesNotIncluded];
  }, [inputValue, tokens]);

  useLayoutEffect(() => {
    if (focusOnMount) inputRef.current?.focus();
  }, [inputValue, focusOnMount]);

  useImperativeHandle(searchRef, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
  }));

  const selectAndHideResults = useCallback(
    (token: Token) => {
      setInputValue(token.symbol!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
      onTokenSelected(token);
      setShowResults(false);
    },
    [onTokenSelected],
  );

  const onKeydown = useCallback(
    (event) => {
      if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
        event.preventDefault();
      }
      switch (event.key) {
        case 'ArrowDown':
          setSelectedIndex((selectedIndex + 1) % matchingTokens.length);
          break;
        case 'ArrowUp':
          setSelectedIndex((selectedIndex - 1 + matchingTokens.length) % matchingTokens.length);
          break;
        case 'Enter':
          if (selectedIndex !== -1) {
            selectAndHideResults(matchingTokens[selectedIndex]);
          }
          break;
      }
    },
    [matchingTokens, selectAndHideResults, selectedIndex],
  );

  useEffect(() => {
    onSymbolChange?.(inputValue);
  }, [inputValue, onSymbolChange]);

  return (
    <>
      <div className={cx('relative', wrapperClassNames)}>
        <img src={loupeIcon} alt="check" className={cx('w-4 absolute mt-3 ml-3', !showLoupe && 'hidden')} />
        <input
          placeholder="Search..."
          className={cx(baseClassName, inputClassNames, 'w-full rounded')}
          value={inputValue}
          ref={inputRef}
          onChange={(event) => {
            setInputValue(event.target.value);
            setShowResults(true);
          }}
          onKeyDown={onKeydown}
          data-testid="TokenSearch-input"
          onFocus={() => setShowResults(true)}
        />
        <div className={cx('absolute top-0 mt-2 mr-3 right-0', !showClearSearch && 'hidden')}>
          <TextLink disabled={!inputValue} onClick={useCallback(() => setInputValue(''), [])}>
            Clear search
          </TextLink>
        </div>
      </div>
      <div className={cx(resultsClassNames, { hidden: !showResults || !matchingTokens.length })}>
        {matchingTokens.map((token, index) => (
          <div
            className={cx(
              'cursor-pointer rounded leading-9 px-3 hover:bg-light-hover dark:hover:bg-dark-hover',
              index === selectedIndex && 'bg-light-selected dark:bg-dark-selected',
            )}
            key={token.id}
            onClick={() => selectAndHideResults(token)}
            data-testid={`TokenSearch-option-${token.id}`}
          >
            {highlightMatch(token.name, inputValue)}{' '}
            {token.symbol && <>({highlightMatch(token.symbol, inputValue)})</>}
          </div>
        ))}
      </div>
    </>
  );
}

export default React.memo(TokenSearch);
