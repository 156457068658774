import React, { useMemo, useCallback } from 'react';
import { useReactiveVar, ReactiveVar } from '@apollo/client';
import type { Actions, ColumnWithHeader } from './types';
import MultiSelectNew from '../Table/Filters/MultiSelect';
import { Placement } from '@popperjs/core';

interface Props<SortOptions, FilteringQueryParams, IdType extends string | number> {
  hiddenColumnsVar: ReactiveVar<string[] | undefined>;
  actions: Actions<SortOptions, FilteringQueryParams, IdType>;
  columns: Array<unknown>;
  dropDownPlacement?: Placement;
}

function ColumnSelector<SortOptions, FilteringQueryParams, IdType extends string | number>({
  actions,
  hiddenColumnsVar,
  columns,
  dropDownPlacement,
}: Props<SortOptions, FilteringQueryParams, IdType>) {
  const allColumnConfs = columns as Array<ColumnWithHeader>;
  const allColumns = allColumnConfs.map((column) => column.id);
  const columnsWithHeaders = useMemo(
    () =>
      allColumnConfs.filter(({ Header }) => {
        return typeof Header === 'string' && (Header as string).length > 0;
      }),
    [allColumnConfs],
  );

  const hiddenColumns = useReactiveVar(hiddenColumnsVar);

  return (
    <MultiSelectNew<string>
      label="Columns"
      isClearable={false}
      dropDownPlacement={dropDownPlacement}
      options={useMemo(
        () => columnsWithHeaders.map(({ id, Header }) => ({ value: id, label: Header })),
        [columnsWithHeaders],
      )}
      onChange={useCallback(
        (selectedColumns) => actions.setColumns({ selectedColumns, allColumns }),
        [actions, allColumns],
      )}
      selectedValues={useMemo(
        () => columnsWithHeaders.flatMap(({ id }) => (hiddenColumns?.includes(id) ? [] : [id])),
        [columnsWithHeaders, hiddenColumns],
      )}
    />
  );
}

export default React.memo(ColumnSelector) as typeof ColumnSelector;
