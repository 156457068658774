import { noop } from 'lodash';
import React from 'react';
import {
  AllCredentialsQuery,
  LatestCreateMovementsJobQuery,
  LatestGenerateAccountsJobQuery,
  LatestMissingPricesJobQuery,
  LatestReconGuideTreatmentsJobQuery,
  MissingExchangesQuery,
  MissingPricesDataQuery,
} from '../../graphql/types';
import { MissingWalletWithTxnCount } from './Imports/MissingWallets/queries';

export interface SelfReconContextData {
  missingWallets?: Array<MissingWalletWithTxnCount>;
  refetchMissingWallets: () => void;
  missingExchanges?: MissingExchangesQuery['missingExchanges']['edges'];
  refetchMissingExchanges: () => void;
  lpTokenTickersByAddress: Record<string, string>;
  unreconciledTxnsCount?: number;
  startReconGuideAutomationPolling: () => void;
  reconGuideAutomationStatusIsLoading: boolean;
  latestReconGuideTreatmentsJobData?: LatestReconGuideTreatmentsJobQuery;
  unreconciledTransactionsLoading: boolean;
  allCredentials?: AllCredentialsQuery['credentials']['edges'];
  missingWalletsLoading: boolean;
  missingPricesData?: MissingPricesDataQuery['missingPricesData'];
  missingPricesLoading: boolean;
  startMissingPricesPolling: () => void;
  missingPricesStatusIsLoading: boolean;
  latestMissingPricesJobData?: LatestMissingPricesJobQuery;
  startAccountGenerationPolling: () => void;
  accountGenerationStatusIsLoading: boolean;
  latestGenerateAccountsJobData?: LatestGenerateAccountsJobQuery;
  startMovementGenerationPolling: () => void;
  movementGenerationStatusIsLoading: boolean;
  latestGenerateMovementsJobData?: LatestCreateMovementsJobQuery;
  reconOrAccountsOrMovementsStatusIsLoading: boolean;
}

export const SelfReconContext = React.createContext<SelfReconContextData>({
  missingWallets: [],
  missingExchanges: [],
  refetchMissingWallets: noop,
  refetchMissingExchanges: noop,
  lpTokenTickersByAddress: {},
  unreconciledTxnsCount: 0,
  startReconGuideAutomationPolling: noop,
  reconGuideAutomationStatusIsLoading: false,
  unreconciledTransactionsLoading: false,
  missingWalletsLoading: false,
  missingPricesLoading: false,
  startMissingPricesPolling: noop,
  missingPricesStatusIsLoading: false,
  startAccountGenerationPolling: noop,
  accountGenerationStatusIsLoading: false,
  startMovementGenerationPolling: noop,
  movementGenerationStatusIsLoading: false,
  reconOrAccountsOrMovementsStatusIsLoading: false,
});

SelfReconContext.displayName = 'SelfReconContext';
