import tailwindConfig from '../../../../tailwind.config';
import { Theme } from '../../../theme/useTheme';
import { Styles } from '../types';
import { getBaseStyles } from './base';

const { textColor, borderColor } = tailwindConfig.theme.extend;

export function getDefaultStyles<Option>(theme: Theme, hasError?: boolean): Styles<Option> {
  return {
    ...getBaseStyles(theme),
    container: (baseStyles) => ({
      ...baseStyles,
      width: '100%',
      border: 'none',
      boxShadow: 'none',
    }),
    control: (baseStyles, { isFocused }) => ({
      ...baseStyles,
      backgroundColor: 'transparent',
      padding: '6.5px 0.25rem',
      cursor: 'pointer',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: hasError
        ? borderColor[`${theme}-control-critical`]
        : borderColor[`${theme}-control${isFocused ? '-focus' : ''}`],
      boxShadow: 'none',
      '&:hover': {
        borderColor: borderColor[`${theme}-control${isFocused ? '-focus' : '-hover'}`],
      },
    }),
    dropdownIndicator: (baseStyles) => {
      const color = textColor[`${theme}-muted`];
      return {
        ...baseStyles,
        color,
        '&:hover': { color },
      };
    },
  };
}
