import React from 'react';
import { LatestTxnReportsQuery } from '../../../../graphql/types';
import Option from './Option';
import { ChoiceProps } from './types';

type LatestTxnReportsByYearType = NonNullable<
  LatestTxnReportsQuery['latestTxnReports']
>['latestTxnReportsByYear'][0];
type ReportTaxDetail = LatestTxnReportsByYearType['taxDetail'];
type MethodType = NonNullable<ReportTaxDetail['taxMethod']>;

export default function ChooseMethod({
  send,
  methods,
  next,
}: ChoiceProps & {
  methods: MethodType[];
  next?: string;
}) {
  const options =
    methods.map((m: MethodType) => ({
      value: m.id,
      label: m.label,
    })) || [];

  return (
    <div className="pt-4">
      {options.map((o) => (
        <Option key={o.value} option={o} send={send} next={next || 'GENERATE'} />
      ))}
    </div>
  );
}
