import React, { useEffect } from 'react';

import PageSeo from '../PageSeo';
import Page from '../Layout/Page';
import { actions } from './actions';
import AllTransactionsHeader from './Header';
import AllTransactionsContextProvider from './Provider';
import AllTransactionsTable from './Table';
import SpecIdPanel from './SpecIdPanel';
import { createPortal } from 'react-dom';

export function AllTransactions() {
  return (
    <Page scrollable={false}>
      <PageSeo title="Transactions" />
      <div className="flex flex-col flex-grow overflow-hidden">
        <AllTransactionsHeader />
        <AllTransactionsTable />
      </div>
      {createPortal(<SpecIdPanel />, document.body)}
    </Page>
  );
}

function AllTransactionsContainer() {
  useEffect(() => {
    return () => {
      actions.resetState();
    };
  });

  return (
    <AllTransactionsContextProvider>
      <AllTransactions />
    </AllTransactionsContextProvider>
  );
}

export default React.memo(AllTransactionsContainer);
