import React, { useState, useCallback } from 'react';
import { get } from 'lodash';
import cx from 'classnames';

import type { Row as RowType } from './types';
import Cell from './Cell';
import { useTableContext } from './context';

interface RowProps<T extends Record<string, unknown>> {
  row: RowType<T>;
  role?: string;
  noBorder?: boolean;
  id?: string;
  onClick?: (id: string) => void; // FIXME id should be generic
  isRowClickable?: (data: T) => boolean;
}

function Row<T extends Record<string, unknown>>({
  row,
  noBorder,
  onClick,
  isRowClickable,
}: RowProps<T>): React.ReactElement {
  const { expandedRowIds, ExpandedRowComponent, gridStyle, rowClassName, disabledIds } = useTableContext();
  const originalId = get(row.original, 'id') as string | undefined;
  const isExpanded = expandedRowIds && (expandedRowIds as string[]).includes(originalId || '');
  const [forceHover, setForceHover] = useState(false);
  const borderClasses = 'border-base dark:border-dark-base border-b';
  const rowIsClickable = Boolean(isRowClickable?.(row.original));
  const disabled = disabledIds?.includes(originalId as string);

  return (
    <div
      {...row.getRowProps()}
      className={cx(
        !noBorder && borderClasses,
        'bg-light-base dark:bg-dark-base',
        'hover:bg-light-hover-faint dark:hover:bg-dark-hover-faint',
        forceHover && 'tt_table-force_hover',
        rowIsClickable && 'cursor-pointer',
        rowClassName,
        disabled && 'text-opacity-50',
      )}
      onClick={useCallback(() => {
        if (!rowIsClickable) return;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onClick?.(originalId!);
      }, [onClick, originalId, rowIsClickable])}
    >
      <div style={gridStyle}>
        {row.cells.map((cell) => (
          <Cell<T>
            cell={cell}
            originalId={originalId}
            setForceHover={setForceHover}
            rowIsDisabled={disabled}
            key={`${originalId}-${cell.column.id}`}
          />
        ))}
      </div>

      {isExpanded && ExpandedRowComponent && (
        <ExpandedRowComponent row={row as RowType<Record<string, unknown>>} />
      )}
    </div>
  );
}

export default React.memo(
  Row,
  // https://reactjs.org/docs/react-api.html#reactmemo
  function propsAreEqual({ row: row1, ...props1 }, { row: row2, ...props2 }) {
    const dataIsEqual = JSON.stringify(row1.original) === JSON.stringify(row2.original); // underlying data model has not changed
    const valuesAreEqual = JSON.stringify(row1.values) === JSON.stringify(row2.values); // column accessor data has not changed
    const otherPropsAreEqual = Object.entries(props1).every(
      ([name, value]) => props2[name as keyof typeof props2] === value,
    );
    return valuesAreEqual && dataIsEqual && otherPropsAreEqual;
  },
) as unknown as typeof Row;
