import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Auth from '../../lib/Auth';
import Button from '../Button';
import Card from '../Card';
import Input from '../Input';
import { WithPortal } from '../PortalLayout';
import Spinner from '../Spinner';
import TextLink from '../TextLink';
import { useActivateAccount, useIsPasswordResetTokenValid } from './queries';

function Content() {
  const token = new URLSearchParams(useLocation<{ token: string }>().search).get('token') as string;

  const history = useHistory();

  const { data, loading } = useIsPasswordResetTokenValid(token);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [activateAccount, { loading: isSubmitting }] = useActivateAccount();

  const onSubmit = useCallback(
    async (evt) => {
      evt.preventDefault();

      try {
        if (confirmedPassword !== password) {
          throw new Error('Passwords must match');
        }

        await activateAccount({ variables: { token, password } });

        Auth.saveTokenData();
        history.push('/home');
      } catch (e) {
        setErrorMessage((e as Error).message);
      }
    },
    [token, password, activateAccount, history, confirmedPassword],
  );

  if (loading) {
    return (
      <div className="flex flex-col items-center pb-8">
        <Spinner className="mt-12" />
      </div>
    );
  }

  const valid = Boolean(data?.isPasswordResetTokenValid);
  if (!valid) {
    return (
      <div className="py-8">
        This activation link has expired. Please <TextLink to="/forgot">reset your password</TextLink>.
      </div>
    );
  }

  return (
    <div>
      <h1 className="font-light text-3xl my-5 mb-8 text-center">Choose a password</h1>

      <form onSubmit={onSubmit}>
        <div className="form-group">
          <Input
            className="w-full mb-3 border border-light-control dark:border-dark-control rounded p-3 block"
            type="password"
            name="password"
            onChange={(event) => setPassword(event.target.value.toLowerCase())}
            placeholder={'Password'}
          />
        </div>

        <div className="form-group">
          <Input
            className="w-full mb-3 border border-light-control dark:border-dark-control rounded p-3 block"
            type="password"
            name="confirm-password"
            onChange={(event) => setConfirmedPassword(event.target.value.toLowerCase())}
            placeholder={'Re-Enter Password'}
          />
        </div>
        {errorMessage && (
          <div className="text-light-alert-critical dark:text-dark-alert-critical pt-2 pb-4">
            {errorMessage}
          </div>
        )}
        <Button large fullWidth type="submit" loading={isSubmitting} disabled={isSubmitting}>
          Save password
        </Button>
      </form>
    </div>
  );
}

function ActivateAccount() {
  return (
    <div className="mt-12 w-full max-w-lg">
      <Card className="pb-6">
        <Content />
      </Card>
    </div>
  );
}

export default React.memo(WithPortal(ActivateAccount, { className: 'pt-16 flex flex-col items-center' }));
