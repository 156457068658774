import { Placement } from '@popperjs/core';
import React, { useCallback, useEffect, useState } from 'react';
import Dropdown, { DropdownHandle } from '../../../Dropdown';
import Checkbox from '../../../Input/Checkbox';
import Text from '../../../Text';

export type MultiSelectOption<Value = string> = {
  value: Value;
  label?: string | null;
  count?: number;
};

interface Props<Value = string> {
  /* Whether or not the select should be disabled */
  isDisabled?: boolean;
  /* Text to display as the unselected state of the multiselect */
  label: string;
  /* Function to handle what happens on change */
  onChange: (values: Value[]) => void;
  /* Options to select */
  options: MultiSelectOption<Value>[];
  /* Option to align the menu to a specific side  */
  dropDownPlacement?: Placement;
  /* The values to be displayed as selected */
  selectedValues: Value[];
  /* If provided, limit the height of the dropdown. After that height, scrolling is enabled */
  maxMenuHeight?: number;
  /* If true, a clear button is displayed */
  isClearable?: boolean;
}

function MultiSelect<Value>({
  options,
  selectedValues,
  onChange,
  dropDownPlacement = 'bottom-start',
  maxMenuHeight,
  isClearable = true,
  ...props
}: Props<Value>) {
  const [selectedValuesState, setSelectedValuesState] = useState<Value[]>(selectedValues);
  const dropDownRef = React.useRef<DropdownHandle>(null);

  useEffect(() => setSelectedValuesState(selectedValues), [selectedValues]);

  const toggleOption = useCallback(
    (value: Value) => {
      const newValues = selectedValuesState.includes(value)
        ? selectedValuesState.filter((v) => v !== value)
        : [...selectedValuesState, value];
      setSelectedValuesState(newValues);
    },
    [selectedValuesState],
  );

  const canBeCleared = isClearable && selectedValues.length > 0;
  const label = canBeCleared
    ? selectedValues.map((val) => options.find((option) => option.value === val)?.label).join(', ')
    : props.label;

  return (
    <Dropdown
      label={label}
      placement={dropDownPlacement}
      ref={dropDownRef}
      isClearable={canBeCleared}
      onClear={useCallback(() => onChange([]), [onChange])}
    >
      <div className="flex flex-col" style={{ maxHeight: maxMenuHeight }}>
        <div className="overflow-y-auto flex-grow">
          <div className="py-1">
            {options.map(({ label, value, count }) => (
              <div
                key={String(value)}
                className="flex cursor-pointer px-3 py-1 hover:bg-light-hover dark:hover:bg-dark-hover pr-10"
                onClick={() => {
                  toggleOption(value);
                }}
              >
                <Checkbox size="small" checked={selectedValuesState.includes(value)} className="mr-3" />
                {label}
                {count !== undefined && (
                  <span className="ml-2">
                    <Text variant="muted">({count})</Text>
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
        <button
          className="sticky bg-light-base dark:bg-dark-base border-t border-light-control dark:border-dark-control text-sm font-semibold text-center w-full py-1 hover:bg-light-hover hover:dark:bg-dark-hover rounded-b"
          onClick={useCallback(() => {
            onChange(selectedValuesState);
            dropDownRef?.current?.hide();
          }, [onChange, selectedValuesState])}
        >
          Apply
        </button>
      </div>
    </Dropdown>
  );
}

export default React.memo(MultiSelect) as typeof MultiSelect;
