import { capitalize } from 'lodash';
import React from 'react';

import { TxnType } from '../../../graphql/types';
import Select, { SelectProps } from '../../Select';

const options = Object.values(TxnType)
  .map((t) => ({
    value: t as TxnType,
    label: capitalize(t),
  }))
  .sort((a) => (a.value === 'trade' || a.value === 'income' ? -1 : 1));

interface Props {
  onChange: (value: TxnType) => void;
  onBlur: () => void;
  value?: string;
  placeholder?: string;
  omitOptions?: TxnType[];
  variant?: SelectProps['variant'];
}

export default function TxnTypeSelect({
  onChange,
  onBlur,
  value,
  placeholder,
  omitOptions = [],
  variant,
}: Props) {
  const filteredOptions = options.filter((o) => !omitOptions.includes(o.value));
  return (
    <Select<TxnType>
      id="txnType"
      options={filteredOptions}
      onChange={({ value }) => {
        onChange(value);
      }}
      classNames={{
        placeholder: () => 'truncate',
      }}
      onBlur={onBlur}
      value={options.find((option) => option.value === value)}
      placeholder={placeholder}
      variant={variant || 'default'}
    />
  );
}
