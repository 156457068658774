import Big from 'big.js';
import { uniq } from 'lodash';
import {
  CurrencyFilterTypes,
  DateRangeFilter,
  FilterOperatorTypes,
  MovementMatchConstraintsQuery,
  MultiSelectIntFilter,
  TxnCurrencyFilter,
  TxnFilterQuery,
  TxnFragment,
  TxnSearchFilter,
  TxnSearchFilterKeys,
} from '../../../graphql/types';
import { areWithinPercentage, areWithinTimestampLeeway } from '../../../helpers/validators';
import { TxnWithOptionalSpecIdQuantities } from '../Provider/types';

export const buildDateFilterObject = (dateString: string): DateRangeFilter => ({
  fromDate: dateString,
  toDate: dateString,
});

export const buildMethodIdFilterObject = (methodId: string): TxnSearchFilter => ({
  key: TxnSearchFilterKeys.methodId,
  value: methodId,
  operator: FilterOperatorTypes.Is,
});
export const buildTxnHashFilterObject = (hash: string): TxnSearchFilter => ({
  key: TxnSearchFilterKeys.txnHash,
  value: hash,
  operator: FilterOperatorTypes.Is,
});
export const buildFromAddressFilterObject = (fromAddress: string): TxnSearchFilter => ({
  key: TxnSearchFilterKeys.fromAddress,
  value: fromAddress,
  operator: FilterOperatorTypes.Is,
});
export const buildToAddressFilterObject = (toAddress: string): TxnSearchFilter => ({
  key: TxnSearchFilterKeys.toAddress,
  value: toAddress,
  operator: FilterOperatorTypes.Is,
});
export const buildCredentialIdFilterObject = (credentialId: number): MultiSelectIntFilter => ({
  values: [credentialId],
  operator: FilterOperatorTypes.Is,
});
export const buildReconIdentifierFilterObject = (reconIdentifier: string): TxnSearchFilter => ({
  key: TxnSearchFilterKeys.reconIdentifier,
  value: reconIdentifier,
  operator: FilterOperatorTypes.Is,
});

export const buildCurrencyFilterObject = (
  currency: string,
  side?: CurrencyFilterTypes,
): TxnCurrencyFilter => ({
  side: side || CurrencyFilterTypes.Currency,
  symbols: [currency],
  operator: FilterOperatorTypes.Is,
});

interface UrlOptions {
  specIdSellId?: string | null;
}

export const buildDateFilterUrlParam = (dateString: string, { specIdSellId }: UrlOptions) => {
  const query: TxnFilterQuery = {
    date: buildDateFilterObject(dateString),
    ...(specIdSellId ? { specId: { sellId: specIdSellId } } : {}),
  };
  return `?filterQuery=${encodeURIComponent(JSON.stringify(query))}`;
};

export const buildMethodIdFilterUrlParam = (methodId: string, { specIdSellId }: UrlOptions) => {
  const query: TxnFilterQuery = {
    methodId: [buildMethodIdFilterObject(methodId)],
    ...(specIdSellId ? { specId: { sellId: specIdSellId } } : {}),
  };
  return `?filterQuery=${encodeURIComponent(JSON.stringify(query))}`;
};

export const buildReconIdentifierFilterUrlParam = (reconIdentifier: string, { specIdSellId }: UrlOptions) => {
  const query: TxnFilterQuery = {
    reconIdentifier: [buildReconIdentifierFilterObject(reconIdentifier)],
    ...(specIdSellId ? { specId: { sellId: specIdSellId } } : {}),
  };
  return `?filterQuery=${encodeURIComponent(JSON.stringify(query))}`;
};

export const buildTxnHashFilterUrlParam = (hash: string, { specIdSellId }: UrlOptions) => {
  const query: TxnFilterQuery = {
    txnHash: [buildTxnHashFilterObject(hash)],
    ...(specIdSellId ? { specId: { sellId: specIdSellId } } : {}),
  };
  return `?filterQuery=${encodeURIComponent(JSON.stringify(query))}`;
};

export const buildFromAddressFilterUrlParam = (fromAddress: string, { specIdSellId }: UrlOptions) => {
  const query: TxnFilterQuery = {
    fromAddress: [buildFromAddressFilterObject(fromAddress)],
    ...(specIdSellId ? { specId: { sellId: specIdSellId } } : {}),
  };
  return `?filterQuery=${encodeURIComponent(JSON.stringify(query))}`;
};

export const buildToAddressFilterUrlParam = (toAddress: string, { specIdSellId }: UrlOptions) => {
  const query: TxnFilterQuery = {
    toAddress: [buildToAddressFilterObject(toAddress)],
    ...(specIdSellId ? { specId: { sellId: specIdSellId } } : {}),
  };
  return `?filterQuery=${encodeURIComponent(JSON.stringify(query))}`;
};

export const buildCredentialIdFilterUrlParam = (credentialId: number, { specIdSellId }: UrlOptions) => {
  const query: TxnFilterQuery = {
    imports: buildCredentialIdFilterObject(credentialId),
    ...(specIdSellId ? { specId: { sellId: specIdSellId } } : {}),
  };
  return `?filterQuery=${encodeURIComponent(JSON.stringify(query))}`;
};

export const buildCurrencyTickerFilterUrlParam = (
  ticker: string,
  { side, specIdSellId }: UrlOptions & { side?: CurrencyFilterTypes } = {},
) => {
  const query: TxnFilterQuery = {
    currency: [buildCurrencyFilterObject(ticker, side || CurrencyFilterTypes.Currency)],
    ...(specIdSellId ? { specId: { sellId: specIdSellId } } : {}),
  };
  return `?filterQuery=${encodeURIComponent(JSON.stringify(query))}`;
};

export const txnsHaveSameTypeAndCurrency = (txns: TxnWithOptionalSpecIdQuantities[]) => {
  if (txns.every(({ txnType }) => txnType === 'deposit')) {
    const tokenIds = txns.map(({ buyTokenId }) => buyTokenId);
    const hasTokenId = tokenIds.some(Boolean);
    if (hasTokenId) {
      return uniq(tokenIds).length === 1;
    }

    const currencies = txns.map(({ buyCurrency }) => buyCurrency);
    return uniq(currencies).length === 1;
  }

  if (txns.every(({ txnType }) => txnType === 'withdrawal')) {
    const tokenIds = txns.map(({ sellTokenId }) => sellTokenId);
    const hasTokenId = tokenIds.some(Boolean);
    if (hasTokenId) {
      return uniq(tokenIds).length === 1;
    }

    const currencies = txns.map(({ sellCurrency }) => sellCurrency);
    return uniq(currencies).length === 1;
  }

  return false;
};

type MovementsCanBeMatchedProps = {
  selectedTxns: TxnFragment[];
  movementMatchConstraints: MovementMatchConstraintsQuery['movementMatchConstraints'] | undefined;
};

export const movementsCanBeMatched = ({
  selectedTxns,
  movementMatchConstraints,
}: MovementsCanBeMatchedProps) => {
  if (!movementMatchConstraints) return false;
  if (selectedTxns.length !== 2) return false;

  const deposit = selectedTxns.find((txn) => txn.txnType === 'deposit');
  const withdrawal = selectedTxns.find((txn) => txn.txnType === 'withdrawal');

  if (!deposit || !withdrawal) return false;

  if (deposit.hasMovement || withdrawal.hasMovement) return false;

  const timestamps = [deposit.txnTimestamp, withdrawal.txnTimestamp];
  if (!areWithinTimestampLeeway(timestamps, movementMatchConstraints.timestampLeewayDays)) return false;

  const quantities = [Big(deposit.buyQuantity || 0).toNumber(), Big(withdrawal.sellQuantity || 0).toNumber()];
  return areWithinPercentage(quantities, movementMatchConstraints.quantityLeewayPercentage);
};
