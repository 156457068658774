import { ApolloClient, ApolloLink, HttpOptions, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { chain, set } from 'lodash';
import { v4 as uuid } from 'uuid';
import { lineItemsTableCache } from '../../components-v2/TaxDashboard/LineItemTable/cache';
import { taxDashboardCache } from '../../components-v2/TaxDashboard/cache';
import { HIJACKED_USER_ID_QUERY_ARG } from '../Auth/constants';
import { HIJACKED_USER_ID_HEADER_NAME } from '../constants';
import { dispatchNetworkError } from './clientEvents';
import { errorLink } from './errorLink';

const getOperationName = (options?: RequestInit) => {
  try {
    const data = JSON.parse(options?.body as string);
    if (Array.isArray(data)) {
      return chain(data).map('operationName').join('-').value();
    }
    return data.operationName;
  } catch {
    return 'unknown';
  }
};

const DISABLE_OPERATION_BATCHING = true;

const ENDPOINT_URI = process.env.GQL_URI;
const fetch: HttpOptions['fetch'] = (uri, options) => {
  // we add an `operationName` HTTP argument for developer convenience only (so it shows up in the devtools). The API does nothing with it.
  set(options!, ['headers', 'X-Correlation-ID'], uuid()); // eslint-disable-line @typescript-eslint/no-non-null-assertion
  return window.fetch(`${ENDPOINT_URI}/graph/graphql?opname=${getOperationName(options)}`, {
    ...options,
    credentials: 'include',
  });
};
const httpLink = createHttpLink({ fetch });
// https://www.apollographql.com/docs/react/api/link/apollo-link-batch-http/
const batchHttpLink = new BatchHttpLink({
  fetch,
  batchMax: 10,
  batchDebounce: true,
  batchInterval: 2000,
});
const httpPrioritySwitchingLink = split(
  (operation) => !DISABLE_OPERATION_BATCHING && operation.getContext().batched === true,
  batchHttpLink,
  httpLink,
);

const MAX_RETRIES_COUNT = 5;
const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    // eslint-disable-next-line no-console
    console.error(`Caught error in retryLink for operation ${operation.operationName}`);
    if (count > MAX_RETRIES_COUNT) {
      // eslint-disable-next-line no-console
      console.error(`Maximum number of retries (${MAX_RETRIES_COUNT}) exceeded`);
      dispatchNetworkError(error || `Retries exceeded for operation ${operation.operationName}`);
      return false;
    }
    return operation.getContext().retry === true;
  },
  delay: (count, operation, _error) => {
    const wait = Math.pow(count, 2) * 500 * Math.random(); // exponential backoff
    // eslint-disable-next-line no-console
    console.log(`Will wait ${wait}ms before retrying operation ${operation.operationName}`);
    return wait;
  },
});

const authLink = setContext((_, prevContext) => {
  const { headers, noHijack } = prevContext;

  const searchParams = new URLSearchParams(window.location.search);
  const hijackedUserIdString = searchParams.get(HIJACKED_USER_ID_QUERY_ARG);

  // return the headers to the context so httpLink can read them
  return {
    ...prevContext,
    headers: {
      ...headers, // allows Authorization header to be overridden in individual calls
      ...(hijackedUserIdString && !noHijack
        ? {
            [HIJACKED_USER_ID_HEADER_NAME]: parseInt(hijackedUserIdString),
          }
        : {}),
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          ...taxDashboardCache,
          ...lineItemsTableCache,
        },
      },
      TaxLossHarvestingAsset: {
        keyFields: ['id', 'costBasis'],
      },
      TokenOverrideConf: {
        keyFields: ['symbol'],
      },
    },
  }),
  link: ApolloLink.from([retryLink, authLink, errorLink, httpPrioritySwitchingLink]),
});
