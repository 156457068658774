import classNames from 'classnames';
import React, { useMemo } from 'react';
import { FilterOperatorTypes } from '../../graphql/types';
import Dropdown, { DropdownHandle } from '../Dropdown';

interface Props<FilterType> {
  operators?: FilterOperatorTypes[];
  filter: FilterType;
  onChange: (value: FilterType) => void;
}
const { Is, IsNot, Ctns, DNCtn } = FilterOperatorTypes;

const OPTIONS = [
  { label: 'is', value: Is },
  { label: 'is not', value: IsNot },
  { label: 'contains', value: Ctns },
  { label: 'does not contain', value: DNCtn },
];

const DEFAULT_OPERATORS = [Is, IsNot];

function Operator<FilterType extends { operator: FilterOperatorTypes }>({
  operators = DEFAULT_OPERATORS,
  filter,
  onChange,
}: Props<FilterType>) {
  const dropdownRef = React.useRef<DropdownHandle>(null);
  const [open, setOpen] = React.useState(false);

  const operatorOptions = useMemo(
    () => OPTIONS.filter(({ value }) => operators.includes(value)),
    [operators],
  );

  const valueString = useMemo(() => {
    return operatorOptions.find((o) => o.value === filter.operator)?.label || '';
  }, [filter.operator, operatorOptions]);

  const updateOperator = (operator: FilterOperatorTypes) => {
    onChange({ ...filter, operator });
  };

  if (!operatorOptions.length) return <div className="p-1 px-2">is</div>;

  return (
    <div
      className={classNames(
        'h-full flex items-center bg-light-selected dark:bg-dark-selected hover:bg-light-selected-faint dark:hover:bg-dark-selected-faint',
        {
          'bg-light-selected-faint dark:bg-dark-selected-faint': open,
        },
      )}
    >
      <Dropdown
        isDisabled={false}
        label={valueString}
        ref={dropdownRef}
        onShow={() => setOpen(true)}
        onHide={() => setOpen(false)}
        noStyles
      >
        <div className="flex flex-col rounded overflow-hidden">
          {operatorOptions.map((operator) => (
            <button
              key={operator.value}
              className={classNames('py-2 px-3 text-left hover:bg-light-hover dark:hover:bg-dark-hover', {
                'bg-light-selected hover:bg-light-selected dark:bg-dark-selected hover:dark:bg-dark-selected':
                  operator.value === filter.operator,
              })}
              onClick={() => {
                updateOperator(operator.value);
                dropdownRef.current?.hide();
              }}
            >
              {operator.label}
            </button>
          ))}
        </div>
      </Dropdown>
    </div>
  );
}

export default React.memo(Operator) as typeof Operator;
