import { partition, sortBy, uniq } from 'lodash';
import React from 'react';
import { CountsQuery } from '../../../graphql/types';
import Text from '../../Text';
import TextLink from '../../TextLink';
import type { AllTransactionsActions } from '../actions';
import type { FiltersState } from '../types';

type CountByYear = CountsQuery['txnExchangeNameCount'][0];

type ContentProps = {
  years: CountByYear[];
  filtersState: FiltersState;
  actions: AllTransactionsActions;
};

const FILTER_DISPLAY_NAMES = {
  warnings: 'warnings',
  txnTypes: 'transaction type',
  fromDate: 'date',
  toDate: 'date',
  location: 'location',
  currencies: 'currency',
  txnHash: 'transaction hash',
  fromAddress: 'from',
  toAddress: 'to',
  importTypes: 'import type',
};

const getDisplayedFilterNames = ({
  filtersState,
  actions,
}: Pick<ContentProps, 'filtersState' | 'actions'>) => {
  const activeFilterNames = Object.entries(filtersState)
    .filter(([, filterValue]) => (Array.isArray(filterValue) ? filterValue.length : !!filterValue))
    .map(([filterName]) => FILTER_DISPLAY_NAMES[filterName as keyof typeof FILTER_DISPLAY_NAMES]);

  const uniqueFilterNames = uniq(activeFilterNames);

  if (!uniqueFilterNames.length) {
    return <div className="py-4" />;
  }

  const [firstFilter, secondFilter, ...restFilters] = uniqueFilterNames;

  return (
    <div className="py-4 flex justify-between">
      <span>
        Filtered by{' '}
        {[firstFilter, secondFilter, ...(restFilters.length ? [`and ${restFilters.length} more`] : [])]
          .filter((value) => !!value)
          .join(', ')}
        {'  '}
      </span>
      <TextLink
        onClick={() => {
          actions.resetFilters();
        }}
      >
        Clear filters
      </TextLink>
    </div>
  );
};

function Content({ years, filtersState, actions }: ContentProps) {
  if (!years) {
    return null;
  }

  return (
    <div>
      {getDisplayedFilterNames({ filtersState, actions })}
      <ul className="px-4 rounded border">
        {years
          .filter(({ total }) => total > 0)
          .map(({ year, counts, total }, i) => {
            const [otherExchangeCounts, namedExchangesCounts] = partition(
              counts,
              ({ exchangeName }) => exchangeName === 'Other',
            );
            const sortedCountsForYear = sortBy(namedExchangesCounts, 'count').reverse();
            return (
              <li key={year} className={`mb-8 ${i === years.length ? '' : 'mb-8'}`}>
                <span className="grid grid-cols-2 mb-2 sticky top-0 bg-light-base dark:bg-dark-base border-b py-2">
                  <span className="font-semibold">{year}</span>
                  <Text variant="muted" className="text-right">
                    {total}
                  </Text>
                </span>

                <ul>
                  {[...sortedCountsForYear, ...otherExchangeCounts].map(({ exchangeName, count }) => (
                    <li key={exchangeName} className="grid grid-cols-2 mb-2">
                      <span>{exchangeName} </span>
                      <Text variant="muted" className="text-right">
                        {count}
                      </Text>
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default React.memo(Content);
