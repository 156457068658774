import { MutationFunction } from '@apollo/client';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { reconGuideBaseUrl } from '../../constants/reconGuide';
import { useGetTreatments } from '../../graphql/queries/reconGuide';
import {
  EvmGuideSubmitEditInstructionMutation,
  EvmGuideSubmitEditInstructionMutationVariables,
  EvmGuideSubmitNewInstructionMutation,
  EvmGuideSubmitNewInstructionMutationVariables,
  IbcGuideSubmitEditInstructionMutation,
  IbcGuideSubmitEditInstructionMutationVariables,
  IbcGuideSubmitNewInstructionMutation,
  IbcGuideSubmitNewInstructionMutationVariables,
  SolanaGuideSubmitEditInstructionMutation,
  SolanaGuideSubmitEditInstructionMutationVariables,
  SolanaGuideSubmitNewInstructionMutation,
  SolanaGuideSubmitNewInstructionMutationVariables,
} from '../../graphql/types';
import Button from '../Button';
import { showFlash } from '../Flash';
import TextArea from '../Input/TextArea';
import Modal, { closeModals, showModal } from '../Modal';
import Select from '../Select';
import Spinner from '../Spinner';
import TextLink from '../TextLink';
import { ErrorToast } from '../Toast';
import {
  ReconGuideContributionModalProps,
  isEvm,
  isIbc,
  isSolana,
  option,
  treatmentOptions,
  useReconGuideInstructionsMutation,
  useReconGuideInstructionsQuery,
} from './helpers';

// Currently we have three options for the modal that take different props:
//  -- EVM: methodId, contractAddress, chain
//  -- Solana: identifier
// -- IBC: identifier, credentialType (chain)
// We may need to handle additional chains in the future

function InstructionModal(props: ReconGuideContributionModalProps) {
  const ecosystem = isEvm(props) ? 'EVM' : isSolana(props) ? 'Solana' : isIbc(props) ? 'IBC' : undefined;
  if (!ecosystem) throw new Error('Invalid type in ReconGuideContributionModal!');

  const { onClose } = props;
  const { data: treatmentData, loading: treatmentsLoading } = useGetTreatments();

  const { instructionsData, instructionsLoading } = useReconGuideInstructionsQuery(props);
  const {
    editInstruction,
    addInstruction,
    submitEditLoading,
    submitEditError,
    submitNewLoading,
    submitNewError,
  } = useReconGuideInstructionsMutation(props);

  const [treatment, setTreatment] = useState<string | null | undefined>(undefined);
  const [treatmentId, setTreatmentId] = useState<number | null>(null);
  const [notes, setNotes] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    const treatmentObj = treatmentData?.reconGuideTreatments.find((f) => f.name === treatment);
    if (treatmentObj) {
      setTreatmentId?.(treatmentObj.id);
    }
  }, [treatment, treatmentData]);

  useEffect(() => {
    setTreatment(instructionsData?.treatment?.name);
    setNotes(instructionsData?.notes || '');
  }, [instructionsData]);

  useEffect(() => {
    setDisabled(instructionsData?.treatmentId === treatmentId && instructionsData?.notes === notes);
  }, [instructionsData, treatmentId, notes]);

  const treatmentValue = useMemo(() => {
    return treatment === undefined ? undefined : option(treatment);
  }, [treatment]);

  const onSubmitEditInstruction = async (e: FormEvent) => {
    e.preventDefault();

    if (!treatment || !treatmentId || !instructionsData?.id) {
      return;
    }

    let variables:
      | EvmGuideSubmitEditInstructionMutationVariables
      | SolanaGuideSubmitEditInstructionMutationVariables
      | IbcGuideSubmitEditInstructionMutationVariables;

    if (isEvm(props)) {
      variables = {
        instructionId: instructionsData.id,
        newTreatmentId: treatmentId,
        newNotes: notes,
      };
      await (
        editInstruction as MutationFunction<
          EvmGuideSubmitEditInstructionMutation,
          EvmGuideSubmitEditInstructionMutationVariables
        >
      )({ variables });
    } else if (isSolana(props)) {
      variables = {
        instructionId: instructionsData.id,
        newTreatmentId: treatmentId,
        newNotes: notes,
        transactionHash: props.transactionHash,
      };
      await (
        editInstruction as MutationFunction<
          SolanaGuideSubmitEditInstructionMutation,
          SolanaGuideSubmitEditInstructionMutationVariables
        >
      )({ variables });
    } else if (isIbc(props)) {
      variables = {
        instructionId: instructionsData.id,
        newTreatmentId: treatmentId,
        newNotes: notes,
        transactionHash: props.transactionHash,
        credentialType: props.credentialType,
      };
      await (
        editInstruction as MutationFunction<
          IbcGuideSubmitEditInstructionMutation,
          IbcGuideSubmitEditInstructionMutationVariables
        >
      )({ variables });
    }

    closeModals();
    showFlash({ message: `${ecosystem} instruction edited`, type: 'success' });
  };

  const onSubmitNewInstruction = async (e: FormEvent) => {
    e.preventDefault();

    if (!treatment || !treatmentId) {
      setDisabled(true);
      return;
    }

    let variables:
      | EvmGuideSubmitNewInstructionMutationVariables
      | SolanaGuideSubmitNewInstructionMutationVariables
      | IbcGuideSubmitNewInstructionMutationVariables;

    if (isEvm(props)) {
      variables = {
        methodId: props.methodId,
        contractAddress: props.contractAddress,
        chain: props.chain,
        treatmentId: treatmentId,
        notes,
      };
      await (
        addInstruction as MutationFunction<
          EvmGuideSubmitNewInstructionMutation,
          EvmGuideSubmitNewInstructionMutationVariables
        >
      )({ variables });
    } else if (isSolana(props)) {
      variables = {
        identifier: props.identifier,
        treatmentId: treatmentId,
        notes,
        transactionHash: props.transactionHash,
      };
      await (
        addInstruction as MutationFunction<
          SolanaGuideSubmitNewInstructionMutation,
          SolanaGuideSubmitNewInstructionMutationVariables
        >
      )({ variables });
    } else if (isIbc(props)) {
      variables = {
        identifier: props.identifier,
        treatmentId: treatmentId,
        notes,
        transactionHash: props.transactionHash,
        credentialType: props.credentialType,
      };
      await (
        addInstruction as MutationFunction<
          IbcGuideSubmitNewInstructionMutation,
          IbcGuideSubmitNewInstructionMutationVariables
        >
      )({ variables });
    }

    closeModals();
    showFlash({ message: `New ${ecosystem} instruction submitted`, type: 'success' });
  };

  const onSubmit = instructionsData ? onSubmitEditInstruction : onSubmitNewInstruction;

  const copy = instructionsData
    ? {
        title: `Edit ${ecosystem} Guide Treatment`,
        subtitle: `Would you like to change the suggested ${ecosystem} treatment for this transaction?`,
      }
    : {
        title: `Unknown ${ecosystem} Guide Treatment`,
        subtitle: `There is no entry in the ${ecosystem} guide for this identifier. How should this transaction be edited?`,
      };

  const submitError = instructionsData ? submitEditError : submitNewError;
  const submitLoading = instructionsData ? submitEditLoading : submitNewLoading;

  if (treatmentsLoading || instructionsLoading) {
    return (
      <Modal onClose={onClose}>
        <Spinner />
      </Modal>
    );
  }

  let contractId, blockSvcMethodId;
  if (instructionsData?.__typename === 'EvmInstruction') {
    contractId = instructionsData?.contractId;
    blockSvcMethodId = instructionsData?.blockSvcMethodId;
  }

  const guideUrl = isEvm(props)
    ? `${reconGuideBaseUrl}/contracts/${contractId}?method=${blockSvcMethodId}`
    : isSolana(props)
    ? `${reconGuideBaseUrl}/solana/instruction/${props.identifier}`
    : isIbc(props)
    ? `${reconGuideBaseUrl}/mintscan/instruction/${props.identifier}`
    : undefined;

  return (
    <Modal title={copy.title} subtitle={copy.subtitle} onClose={onClose}>
      {submitError && <ErrorToast>{submitError.message}</ErrorToast>}

      <form onSubmit={onSubmit}>
        <p className="mb-2">Treatment</p>
        <Select<string | null>
          className="w-full mb-6"
          isSearchable
          placeholder="Please select an option"
          options={treatmentOptions(treatmentData)}
          onChange={({ value }) => setTreatment && setTreatment(value)}
          value={treatmentValue}
        />

        <p className="mb-2">Instructions</p>
        <TextArea
          placeholder={'Please explain'}
          className={'p-3 mb-2'}
          value={notes}
          onChange={(evt) => setNotes && setNotes(evt.target.value)}
          rows={3}
        />
        <Button disabled={disabled}>
          {submitLoading && <Spinner className="mr-2" size="sm" color="white" />}Submit
        </Button>
      </form>

      <TextLink className="mt-2" onClick={() => window.open(guideUrl, '_blank')}>
        View entry in {ecosystem} Guide
      </TextLink>
    </Modal>
  );
}

export default React.memo(InstructionModal);

export function showReconGuideContributionModal(props: ReconGuideContributionModalProps) {
  showModal(<InstructionModal {...props} />);
}
