import { gql } from '@apollo/client';
import { useQueryAndRefetchOnUserChange } from '../../lib/hooks';
import { VersionsQuery } from '../types';

const VERSIONS_QUERY = gql`
  query Versions {
    versions {
      items {
        description
        adminNotes
        id
        createdAt
        number
        txnReportDataFifo
        txnReportDataLifo
        txnReportDataAverageCost
        txnReportDataMinimization
      }

      status {
        isSavingCurrentVersion
        isRestoringVersion
        restoredVersionNumber
        lastRestoredAt
        latestStatus
      }
    }
  }
`;

export const useVersionsQuery = () =>
  useQueryAndRefetchOnUserChange<VersionsQuery>(VERSIONS_QUERY, {
    context: { batched: true },
  });
