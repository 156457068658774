import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import Spinner from '../Spinner';

interface TextProps {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  to?: LinkProps['to'];
  external?: boolean;
  variant?: 'primary' | 'secondary' | 'critical' | 'white';
  testId?: string;
  type?: 'button' | 'submit' | 'reset';
}

const baseClassnames = ['relative', 'cursor-pointer'];

// Primary is a theme blue text link
const primaryClassnames = [
  'text-light-link dark:text-dark-link',
  'hover:text-light-link-hover dark:hover:text-dark-link-hover',
  'focus:text-light-link-hover dark:focus:text-dark-link-hover',
];

// Secondary is a black text link for light theme, white text link for dark theme
const secondaryClassnames = ['text-light-base dark:text-dark-base', 'hover:opacity-75 focus:opacity-75'];

// Critical is a red text link
const criticalClassnames = ['text-light-alert-critical dark:text-dark-alert-critical'];

// Force white
const whiteClassnames = ['text-white dark:text-white hover:text-light-faint focus:text-dark-muted'];

export const textLinkVariantClassnames = {
  primary: primaryClassnames,
  secondary: secondaryClassnames,
  critical: criticalClassnames,
  white: whiteClassnames,
};

const TextLink = ({
  children,
  className,
  to,
  external,
  loading,
  onClick,
  variant = 'primary',
  disabled,
  testId,
  type,
}: TextProps) => {
  const themedClassnames = classNames(baseClassnames, textLinkVariantClassnames[variant], {
    'pointer-events-none select-none opacity-25 disabled': disabled,
  });
  const textContent = (
    <span className="relative">
      {children}
      {loading && (
        <span className="absolute top-[50%] translate-y-[-50%] right-0 translate-x-[175%]">
          <Spinner size="sm" />
        </span>
      )}
    </span>
  );
  if (onClick && !to)
    return (
      <button
        data-testid={testId}
        className={classNames(themedClassnames, className)}
        disabled={disabled}
        onClick={onClick}
        type={type}
      >
        {textContent}
      </button>
    );
  else if (to && external)
    return (
      <a
        className={classNames(themedClassnames, className)}
        href={typeof to === 'string' ? to : '#'}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={testId}
        onClick={onClick}
      >
        {textContent}
      </a>
    );
  else if (to)
    return (
      <Link
        className={classNames(themedClassnames, className)}
        to={to || '#'}
        data-testid={testId}
        onClick={onClick}
      >
        {textContent}
      </Link>
    );
  else return textContent;
};

export default React.memo(TextLink) as typeof TextLink;
