import React from 'react';
import cx from 'classnames';

type SizeType = 'sm' | 'md' | 'lg';

export interface SpinnerProps {
  /** spinner color if required, otherwise will default to blue spinner for light mode, white spinner for dark */
  color?: 'tt-blue' | 'white';
  /** custom className */
  className?: string;
  /** tailwind height/width size (rem) */
  size?: SizeType;
}

const classMap = {
  'tt-blue': 'bg-light-brand dark:bg-dark-brand',
  white: 'bg-white',
};

export default function Spinner({ color, className = '', size = 'md' }: SpinnerProps) {
  let sizeClass = '';
  switch (size) {
    case 'sm':
      sizeClass = 'h-2 w-2';
      break;
    case 'md':
      sizeClass = 'h-4 w-4';
      break;
    case 'lg':
      sizeClass = 'h-8 w-8';
      break;
  }

  let bgColorClass = 'bg-light-brand dark:bg-white';

  if (color) {
    bgColorClass = classMap[color];
  }

  return (
    <div className={cx(bgColorClass, 'animate-spin inline-block', sizeClass, className)} role="progressbar" />
  );
}
