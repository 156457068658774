import moment from 'moment';

export const areWithinPercentage = (values: number[], percentage: number): boolean => {
  const max = Math.max(...values);
  const min = Math.min(...values);

  return (max - min) / max <= percentage / 100;
};

export const areWithinTimestampLeeway = (dates: Date[], leewayInDays: number): boolean => {
  const momentDates = dates.map((d) => moment(d));
  const maxDate = moment.max(momentDates);
  const minDate = moment.min(momentDates);

  return maxDate.diff(minDate, 'days') <= leewayInDays;
};

export const isFloat = (value: string) => {
  const whiteSpaceRegex = /^\s*$/;

  if (whiteSpaceRegex.test(value)) return false;

  const parsedFloat = parseFloat(value);

  return !isNaN(parsedFloat);
};

// copied from https://emailregex.com/
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

export const isEmail = (value?: string | null) => !!value && EMAIL_REGEX.test(value);
