import { capitalize } from 'lodash';
import { Column } from 'react-table';
import { EnumTaxMethod } from '../../../graphql/types';
import { AmountCell, CurrencyCell, DateCell } from '../../Table/index';
import { LineItemData } from './types';
import { MissingCostBasisCell, ZeroProceedsCell } from './WarningCells';

const columns = {
  amount: {
    id: 'unitsSold',
    Header: 'Sell Amount',
    // TODO: when we incorporate the additional fee column we will need to switch this back to just 'unitsSold'
    accessor: (t: LineItemData) => (t.unitsSold ? t.unitsSold : t.feeUnitsSold),
    Cell: AmountCell,
    rightAligned: true,
    min: '110px',
  } as const,
  sellCurrency: {
    Header: 'Sell Currency',
    accessor: 'sellCurrency',
    min: '110px',
  } as const,
  buyCurrency: {
    Header: 'Buy Currency',
    accessor: 'buyCurrency',
    min: '110px',
  } as const,
  feeAmount: {
    Header: 'Fee Amount',
    accessor: 'feeUnitsSold',
    Cell: AmountCell,
    rightAligned: true,
    min: '110px',
  } as const,
  feeCurrency: {
    Header: 'Fee Currency',
    accessor: 'feeCurrency',
    min: '110px',
  } as const,
  dateAcquired: {
    Header: 'Date Acq. (UTC)',
    accessor: 'buyDate',
    Cell: DateCell,
    min: '120px',
  } as const,
  dateSold: {
    Header: 'Date Sold (UTC)',
    accessor: 'sellDate',
    Cell: DateCell,
    min: '120px',
  } as const,
  totalProceeds: {
    Header: 'Total Proceeds',
    accessor: 'proceedsIncludingFees',
    Cell: ZeroProceedsCell,
    rightAligned: true,
    min: '110px',
  } as const,
  costBasis: {
    Header: 'Cost Basis',
    accessor: 'costBasisIncludingFees',
    Cell: MissingCostBasisCell,
    rightAligned: true,
    min: '110px',
  } as const,
  gainLoss: {
    Header: 'Gain/Loss',
    accessor: 'gainLossIncludingFees',
    Cell: CurrencyCell,
    rightAligned: true,
    min: '110px',
  } as const,
  deferredGainLoss: {
    Header: 'Deferred Gain/Loss',
    id: 'deferredGainLoss',
    accessor: (t: LineItemData) =>
      t.txnLineItemSellIdTotxn?.txnType === 'migration' ? t.gainLossIncludingFees : 0,
    Cell: CurrencyCell,
    rightAligned: true,
    min: '130px',
  } as const,
  realizedGainLoss: {
    Header: 'Realized Gain/Loss',
    id: 'realizedGainLoss',
    accessor: (t: LineItemData) =>
      t.txnLineItemSellIdTotxn?.txnType !== 'migration' ? t.gainLossIncludingFees : 0,
    Cell: CurrencyCell,
    rightAligned: true,
    min: '130px',
  } as const,
  shortLong: {
    Header: 'Short/Long',
    id: 'term',
    accessor: (d: LineItemData) => capitalize(d.term || undefined),
    min: '110px',
  } as const,
  account: {
    Header: 'Account',
    id: 'accountId',
    accessor: (d: LineItemData) => {
      return d.account?.name || '-';
    },
    min: '110px',
  },
};

const getDefaultColumns = ({ displayFeeColumns, displayAccountColumns }: GetColumnProps) =>
  (
    [
      columns.amount,
      columns.sellCurrency,
      columns.buyCurrency,
      displayFeeColumns && columns.feeAmount,
      displayFeeColumns && columns.feeCurrency,
      columns.dateAcquired,
      columns.dateSold,
      columns.totalProceeds,
      columns.costBasis,
      columns.gainLoss,
      columns.shortLong,
      displayAccountColumns && columns.account,
    ] as Column<LineItemData>[]
  ).filter(Boolean);

export const getMigrationTableColumns = ({ displayFeeColumns, displayAccountColumns }: GetColumnProps) =>
  (
    [
      columns.amount,
      columns.sellCurrency,
      columns.buyCurrency,
      displayFeeColumns && columns.feeAmount,
      displayFeeColumns && columns.feeCurrency,
      columns.dateAcquired,
      columns.dateSold,
      columns.totalProceeds,
      columns.costBasis,
      columns.deferredGainLoss,
      columns.realizedGainLoss,
      displayAccountColumns && columns.account,
    ] as Column<LineItemData>[]
  ).filter(Boolean);

type GetColumnProps = {
  displayFeeColumns: boolean;
  displayAccountColumns: boolean;
};

const getAverageCostColumns = ({ displayFeeColumns, displayAccountColumns }: GetColumnProps) =>
  (
    [
      columns.amount,
      columns.sellCurrency,
      columns.buyCurrency,
      displayFeeColumns && columns.feeAmount,
      displayFeeColumns && columns.feeCurrency,
      columns.dateSold,
      columns.totalProceeds,
      columns.costBasis,
      columns.gainLoss,
      displayAccountColumns && columns.account,
    ] as Column<LineItemData>[]
  ).filter(Boolean);

export const getColumns = ({
  displayFeeColumns,
  displayAccountColumns,
  method,
}: {
  method: EnumTaxMethod;
  displayAccountColumns: boolean;
  displayFeeColumns: boolean;
}) => {
  if (method === EnumTaxMethod.AVERAGE_COST) {
    return getAverageCostColumns({ displayFeeColumns, displayAccountColumns });
  }

  return getDefaultColumns({ displayFeeColumns, displayAccountColumns });
};
