import React from 'react';

interface ApplyButtonProps {
  onApply: () => void;
}

const ApplyButton = ({ onApply }: ApplyButtonProps) => {
  return (
    <div
      className="py-1 mt-1 border-t border-light-control dark:border-dark-control text-sm text-center font-semibold rounded-b cursor-pointer hover:bg-light-hover dark:hover:bg-dark-hover"
      onClick={onApply}
    >
      Apply
    </div>
  );
};

export default ApplyButton;
