import classNames from 'classnames';
import { truncate } from 'lodash';
import React, { useMemo } from 'react';
import Dropdown, { DropdownHandle } from '../../Dropdown';
import Operator from '../Operator';
import PillBody from '../PillBody';
import { BasePillProps } from '../types';
import SearchInput from './SearchInput';
import { BaseSearchFilter } from './types';

export type SearchPillProps<SearchFilter extends BaseSearchFilter> = BasePillProps<SearchFilter> & {
  filterKey: SearchFilter['key'];
};

function SearchPill<SearchFilter extends BaseSearchFilter>({
  filter,
  filterKey,
  label,
  operators,
  onChange,
  onDelete,
}: SearchPillProps<SearchFilter>) {
  const dropdownValuesRef = React.useRef<DropdownHandle>(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    dropdownValuesRef.current?.hide();
  }, [filter]);

  const truncatedValue = useMemo(() => {
    const value = filter?.value as string;
    if (value.split('').length < 12) return value;
    if (['location'].includes(filterKey)) {
      return truncate(value, { length: 12, omission: '...' }); // Truncate end of string
    }
    return `${value.slice(0, 4)}...${value.slice(-3)}`; // Truncate middle of string
  }, [filter, filterKey]);

  if (!filter) return null;

  return (
    <PillBody label={label} onDelete={onDelete}>
      <div className="border-r border-light-control dark:border-dark-control">
        <Operator<SearchFilter> {...{ operators, filter, onChange }} />
      </div>
      <div
        className={classNames('hover:bg-light-selected-faint dark:hover:bg-dark-selected-faint', {
          'bg-light-selected-faint dark:bg-dark-selected-faint': open,
        })}
      >
        <Dropdown
          isDisabled={false}
          label={truncatedValue}
          ref={dropdownValuesRef}
          onShow={() => setOpen(true)}
          onHide={() => setOpen(false)}
          noStyles
        >
          {open && <SearchInput {...{ filter, onChange, label, filterKey }} />}
        </Dropdown>
      </div>
    </PillBody>
  );
}

export default React.memo(SearchPill) as typeof SearchPill;
