import React from 'react';
import { TableChildrenProps } from '../types';
import { useTableContext } from '../context';
import StaticHeader from './StaticHeader';
import ControlledHeader from './ControlledHeader';

function Header<T extends Record<string, unknown>>({ data }: TableChildrenProps<T>): React.ReactElement {
  const { tableType } = useTableContext<T>();

  return (
    <>
      {tableType === 'static' && <StaticHeader data={data} />}
      {tableType === 'controlled' && <ControlledHeader data={data} />}
    </>
  );
}

export default React.memo(Header) as unknown as typeof Header;
