import { makeVar } from '@apollo/client';
import { ReactElement } from 'react';

export const modalsVar = makeVar<ReactElement[]>([]);
export const flashVar = makeVar<ReactElement | null>(null);

export const clearFlash = () => {
  flashVar(null);
};

export interface recalculationState {
  needsRecalculation: boolean;
  recalculating: boolean;
  txnReportJobId?: number;
  showToastSet: boolean;
}

export const defaultRecalculationState = Object.freeze({
  needsRecalculation: false,
  recalculating: false,
  txnReportJobId: undefined,
  showToastSet: false,
});

export const recalculationVar = makeVar<recalculationState>(defaultRecalculationState);

export const disableLogoVar = makeVar<boolean>(false);
