import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from './modal';
import TextLink from '../../TextLink';

interface Props {
  handleClick: (name: Content, title: string) => void;
}

function GettingStartedTab({ handleClick }: Props) {
  const history = useHistory();

  return (
    <>
      <ol className="list-inside">
        <li className="list-decimal leading-7 py-2">
          <TextLink className={`ml-3`} onClick={useCallback(() => history.push('/import'), [history])}>
            Import all of your data
          </TextLink>
        </li>
        <li className="list-decimal leading-7 py-2">
          <TextLink
            className={`ml-3`}
            onClick={useCallback(
              () => handleClick('calculate', 'Calculate & review gains/losses'),
              [handleClick],
            )}
          >
            Calculate & review gains/losses
          </TextLink>
        </li>
        <li className="list-decimal leading-7 py-2">
          <span className={`ml-3 text-light-link dark:text-dark-link`}>Generate tax forms</span>
        </li>
      </ol>
    </>
  );
}

export default React.memo(GettingStartedTab);
