import { gql } from '@apollo/client';
import {
  LoginOrSignupWithGoogleMutation,
  LoginOrSignupWithGoogleMutationVariables,
} from '../../graphql/types';
import { client } from '../../lib/apollo';

export const LOGIN_OR_SIGNUP_WITH_GOOGLE = gql`
  mutation LoginOrSignupWithGoogle($token: String!) {
    loginOrSignupWithGoogle(token: $token) {
      user {
        email
        firstName
        lastName
        id
      }
      userHash
      isSignup
    }
  }
`;

export const loginOrSignUpWithGoogle = async (variables: LoginOrSignupWithGoogleMutationVariables) => {
  const res = await client.mutate<LoginOrSignupWithGoogleMutation, LoginOrSignupWithGoogleMutationVariables>({
    mutation: LOGIN_OR_SIGNUP_WITH_GOOGLE,
    variables,
    context: {
      credentials: 'include',
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user, userHash, isSignup } = res.data!.loginOrSignupWithGoogle;

  return { user, userHash, isSignup };
};
