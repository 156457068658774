import { gql } from '@apollo/client';
import request from 'superagent'; // TODO consider removing this dependency, only used in this file as of 2024-06-27
import { getCSVUploadStatus } from '../../components-v2/CSVUploader/queries';
import { client } from '../../lib/apollo';
import { HIJACKED_USER_ID_HEADER_NAME } from '../../lib/constants';
import { ProcessUploadMutation, ProcessUploadMutationVariables } from '../types';
import { getUploadURL } from './getUploadUrl';

const PROCESS_UPLOAD = gql`
  mutation ProcessUpload(
    $Bucket: String!
    $Key: String!
    $fileName: String!
    $timezone: String!
    $replacedFileId: Int
  ) {
    processUpload(
      Bucket: $Bucket
      Key: $Key
      fileName: $fileName
      timezone: $timezone
      replacedFileId: $replacedFileId
    ) {
      requestId
    }
  }
`;

interface UnsupportedKnownCSVErrorProps {
  expectedFile: string;
  message: string;
  exchange: string;
}
// see link below for why Object.setPrototypeOf is necessary
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
export class UnsupportedKnownCSVError extends Error {
  expectedFile: string;
  message: string;
  exchange: string;

  constructor({ expectedFile, message, exchange }: UnsupportedKnownCSVErrorProps) {
    super();
    Object.setPrototypeOf(this, new.target.prototype);
    this.expectedFile = expectedFile;
    this.message = message;
    this.exchange = exchange;
  }
}

export class UploadNeedsReviewError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

interface UploadFileProps {
  file: File;
  timezone: string; // ex. 'Asia/Kolkata'
  onProgress: (props: { percent: number }) => void;
  onProcessStart: () => void;
  userId?: number;
  replacedFileId?: number;
}

const POLL_INTERVAL_MS = 1000;

export const uploadFile = async ({
  file,
  timezone,
  onProgress,
  onProcessStart,
  userId,
  replacedFileId,
}: UploadFileProps) => {
  const fileName = file.name;
  const { Bucket, Key, url } = await getUploadURL({ fileName }, userId);

  if (!url) throw new Error(`Could not upload file "${fileName}"`);

  await request
    .put(url)
    .on('progress', onProgress)
    .type('text/csv')
    .send(await file.arrayBuffer());

  onProcessStart();

  const { data: processUploadData } = await client.mutate<
    ProcessUploadMutation,
    ProcessUploadMutationVariables
  >({
    mutation: PROCESS_UPLOAD,
    variables: { Bucket, Key, fileName, timezone, replacedFileId },
    ...(userId && { context: { headers: { [HIJACKED_USER_ID_HEADER_NAME]: userId } } }),
  });

  if (!processUploadData) {
    throw new Error(`Could not process upload for "${fileName}"`);
  }

  const { requestId } = processUploadData.processUpload;

  let statusResponse;
  do {
    await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL_MS));
    statusResponse = await getCSVUploadStatus(client, { requestId, userId });
    if (!statusResponse) {
      throw new Error('Could not get status for CSV');
    }
  } while (['created', 'running'].includes(statusResponse.data.csvUploadStatus.status));

  const { status, unsupportedKnownCsv } = statusResponse.data.csvUploadStatus;

  switch (status) {
    case 'failed': {
      if (unsupportedKnownCsv) {
        const { expectedFile, message, exchange } = unsupportedKnownCsv;
        throw new UnsupportedKnownCSVError({
          expectedFile,
          message,
          exchange,
        } as UnsupportedKnownCSVErrorProps);
      }
      throw new Error(`Failed to process upload for "${fileName}"`);
    }
    case 'inReview':
      throw new UploadNeedsReviewError();
    default:
      return;
  }
};
