import { gql } from '@apollo/client';

export const CREDENTIAL = gql`
  fragment Credential on Credential {
    id
    source
    name
    address
    startDate
    endDate
    filterSpam
    txnCount
    credentialType
    latestTransaction
    earliestTransaction
    createdAt
    updatedAt
    status
    lastRefreshError
    errorRemediation
    integrationId
    isOauth
    reconcileOnSync
    accountId
    ensAlias
  }
`;
