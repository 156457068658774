import { gql, useMutation } from '@apollo/client';
import {
  EvmGuideSubmitEditInstructionMutation,
  EvmGuideSubmitEditInstructionMutationVariables,
  EvmGuideSubmitNewInstructionMutation,
  EvmGuideSubmitNewInstructionMutationVariables,
  MutationSolanaGuideEditInstructionArgs,
  MutationSolanaGuideNewInstructionArgs,
  SolanaGuideSubmitEditInstructionMutation,
  MutationIbcGuideEditInstructionArgs,
  MutationIbcGuideNewInstructionArgs,
} from '../../graphql/types';

const EVM_GUIDE_EDIT_INSTRUCTION = gql`
  mutation EvmGuideSubmitEditInstruction($instructionId: Int!, $newTreatmentId: Int!, $newNotes: String) {
    evmGuideEditInstruction(
      instructionId: $instructionId
      newTreatmentId: $newTreatmentId
      newNotes: $newNotes
    )
  }
`;

export const useEvmGuideEditInstruction = () => {
  return useMutation<EvmGuideSubmitEditInstructionMutation, EvmGuideSubmitEditInstructionMutationVariables>(
    EVM_GUIDE_EDIT_INSTRUCTION,
  );
};

const EVM_GUIDE_NEW_INSTRUCTION = gql`
  mutation EvmGuideSubmitNewInstruction(
    $methodId: String!
    $chain: String!
    $contractAddress: String!
    $treatmentId: Int!
    $notes: String
  ) {
    evmGuideNewInstruction(
      methodId: $methodId
      chain: $chain
      contractAddress: $contractAddress
      treatmentId: $treatmentId
      notes: $notes
    )
  }
`;

export const useEvmGuideNewInstruction = () => {
  return useMutation<EvmGuideSubmitNewInstructionMutation, EvmGuideSubmitNewInstructionMutationVariables>(
    EVM_GUIDE_NEW_INSTRUCTION,
  );
};

const SOLANA_GUIDE_EDIT_INSTRUCTION = gql`
  mutation SolanaGuideSubmitEditInstruction(
    $instructionId: Int!
    $newTreatmentId: Int!
    $newNotes: String
    $transactionHash: String!
  ) {
    solanaGuideEditInstruction(
      instructionId: $instructionId
      newTreatmentId: $newTreatmentId
      newNotes: $newNotes
      transactionHash: $transactionHash
    )
  }
`;

export const useSolanaGuideEditInstruction = () => {
  return useMutation<SolanaGuideSubmitEditInstructionMutation, MutationSolanaGuideEditInstructionArgs>(
    SOLANA_GUIDE_EDIT_INSTRUCTION,
  );
};

const SOLANA_GUIDE_NEW_INSTRUCTION = gql`
  mutation SolanaGuideSubmitNewInstruction(
    $identifier: String!
    $treatmentId: Int!
    $notes: String
    $transactionHash: String!
  ) {
    solanaGuideNewInstruction(
      identifier: $identifier
      treatmentId: $treatmentId
      notes: $notes
      transactionHash: $transactionHash
    )
  }
`;

export const useSolanaGuideNewInstruction = () => {
  return useMutation<SolanaGuideSubmitEditInstructionMutation, MutationSolanaGuideNewInstructionArgs>(
    SOLANA_GUIDE_NEW_INSTRUCTION,
  );
};

const IBC_GUIDE_EDIT_INSTRUCTION = gql`
  mutation IbcGuideSubmitEditInstruction(
    $instructionId: Int!
    $newTreatmentId: Int!
    $newNotes: String
    $credentialType: String!
    $transactionHash: String!
  ) {
    ibcGuideEditInstruction(
      instructionId: $instructionId
      newTreatmentId: $newTreatmentId
      newNotes: $newNotes
      credentialType: $credentialType
      transactionHash: $transactionHash
    )
  }
`;

export const useIbcGuideEditInstruction = () => {
  return useMutation<EvmGuideSubmitEditInstructionMutation, MutationIbcGuideEditInstructionArgs>(
    IBC_GUIDE_EDIT_INSTRUCTION,
  );
};

const IBC_GUIDE_NEW_INSTRUCTION = gql`
  mutation IbcGuideSubmitNewInstruction(
    $identifier: String!
    $treatmentId: Int!
    $notes: String
    $credentialType: String!
    $transactionHash: String!
  ) {
    ibcGuideNewInstruction(
      identifier: $identifier
      treatmentId: $treatmentId
      notes: $notes
      credentialType: $credentialType
      transactionHash: $transactionHash
    )
  }
`;

export const useIbcGuideNewInstruction = () => {
  return useMutation<EvmGuideSubmitNewInstructionMutation, MutationIbcGuideNewInstructionArgs>(
    IBC_GUIDE_NEW_INSTRUCTION,
  );
};
