import { TokensData } from '../../../contexts/TokensContext';
import { isTradeLike } from '../../../utils/txnPrices';
import { DIFFERENT_VALUES } from './formValues';
import { getTokenData } from './getTokenData';
import { getFetchableSymbol } from './helpers';
import { Values } from './types';

export const NO_CHOOSABLE_SIDE = 'NO_CHOOSABLE_SIDE';

export type DisplayedPriceFetchingSide = ReturnType<typeof getPriceFetchingSide>;

export const getPriceFetchingSide = (
  values: Values,
  tokensContext: TokensData,
): 'buyCurrency' | 'sellCurrency' | null | typeof NO_CHOOSABLE_SIDE => {
  const { txnType, priceFetchingSide, buyTokenId, sellTokenId, buyAddress, sellAddress } = values;
  const buyCurrency = values.buyCurrency && getFetchableSymbol(values.buyCurrency);
  const sellCurrency = values.sellCurrency && getFetchableSymbol(values.sellCurrency);

  if (!isTradeLike({ txnType })) return null;

  if (buyCurrency === 'USD') return 'buyCurrency';
  if (sellCurrency === 'USD') return 'sellCurrency';

  if ([buyCurrency, sellCurrency].includes(DIFFERENT_VALUES)) {
    // we're editing >1 trades and they don't all have the same currency on both sides
    // the code below simply causes "Fetch price" to be displayed on the left side, which is more visually pleasing
    // clicking that button will choose the most appropriate side for price fetching for each underlying transaction
    // via the `priceForTxn` query
    return null;
  }

  if (priceFetchingSide === 'buy') {
    return 'buyCurrency';
  }

  if (priceFetchingSide === 'sell') {
    return 'sellCurrency';
  }

  // no priceFetchingSide is set: in this case we'd normally lookup both tokens by their symbol
  // and use the token with the highest market cap

  if (!buyCurrency) return 'sellCurrency';
  if (!sellCurrency) return 'buyCurrency';

  const buyToken = getTokenData({
    tokenId: buyTokenId,
    currency: buyCurrency,
    address: buyAddress,
    tokensContext,
  });
  const sellToken = getTokenData({
    tokenId: sellTokenId,
    currency: sellCurrency,
    address: sellAddress,
    tokensContext,
  });

  const buyTokenMarketCap = buyToken?.marketCapRank;
  const sellTokenMarketCap = sellToken?.marketCapRank;

  if (buyTokenMarketCap) {
    if (sellTokenMarketCap) {
      return buyTokenMarketCap < sellTokenMarketCap ? 'buyCurrency' : 'sellCurrency';
    }
    return 'buyCurrency';
  } else if (sellTokenMarketCap) {
    return 'sellCurrency';
  }

  // it's a trade, but we have no market cap data for either token, so we don't know which token to prioritize
  // show "fetch price" for whichever token we can fetch a price for
  if (buyToken) {
    return 'buyCurrency';
  }

  if (sellToken) {
    return 'sellCurrency';
  }

  // we don't know how to fetch price for either token
  return NO_CHOOSABLE_SIDE;
};
