import { gql } from '@apollo/client';

export const ORGANIZATION = gql`
  fragment Organization on Organization {
    id
    name
    slug
    status
    includeDemoUser
    discount
    logo
    colorPrimary
    colorSecondary
    colorLink
    createdAt
    updatedAt
    deletedAt
  }
`;
