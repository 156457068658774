import cx from 'classnames';
import React, {
  ChangeEventHandler,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import Label from './Label';
import { InputHandle, inputVarantClassMap, InputVariant } from './index';
import baseClassName from './styles';

interface TextAreaProps extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'tabIndex'> {
  /** label for input */
  label?: string;
  /** tab order */
  tabIndex?: number;
  /* if true, the input will be focused after mounting */
  initiallyFocused?: boolean;
  /* called when the enter/return key is pressed */
  onEnterPressed?: (value?: string) => void;
  padding?: string;
  renderSideElement?: (input: React.RefObject<HTMLTextAreaElement>) => ReactNode;
  /* number of rows to display */
  rows?: number;
  /* like onChange, but this one is passed a string instead of the event */
  onValueChange?: (value: string) => void;
  variant?: InputVariant;
}

export default forwardRef<InputHandle, TextAreaProps>(function TextArea(
  {
    label,
    className,
    padding,
    value,
    tabIndex,
    initiallyFocused = false,
    onEnterPressed,
    rows = 5,
    onChange,
    onValueChange,
    ...props
  }: TextAreaProps,
  forwardedRef,
) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const appliedClassName = cx(baseClassName, className, padding);
  const variantClass = inputVarantClassMap[props.variant as InputVariant] ?? '';

  useEffect(() => {
    if (initiallyFocused) {
      inputRef.current?.focus();
    }
  }, [initiallyFocused]);

  useImperativeHandle(forwardedRef, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
    blur: () => {
      inputRef.current?.blur();
    },
    setValue: (value: string) => {
      inputRef.current!.value = value; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    },
    getValue: () => inputRef.current?.value,
  }));

  return (
    <>
      {label && <Label htmlFor={props.id || label}>{label}</Label>}
      <textarea
        ref={inputRef}
        tabIndex={tabIndex}
        value={value}
        {...props}
        onKeyPress={(evt) => {
          props?.onKeyPress?.(evt);
          if (evt.key === 'Enter') {
            onEnterPressed?.(inputRef.current?.value);
          }
        }}
        onChange={useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
          (evt) => {
            onChange?.(evt);
            onValueChange?.(evt.target.value);
          },
          [onChange, onValueChange],
        )}
        rows={rows}
        className={cx(appliedClassName, variantClass)}
      />
    </>
  );
});
