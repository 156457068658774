export const taxGuideBlogLinks = [
  {
    title: 'About Cryptocurrency Taxes',
    link: 'https://help.tokentax.co/en/collections/823908-about-cryptocurrency-taxes',
  },
  {
    title: 'Getting started with TokenTax',
    link: 'https://help.tokentax.co/en/collections/3115212-getting-started-with-tokentax',
  },
  {
    title: 'Crypto Taxes: The Complete Guide',
    link: 'https://tokentax.co/blog/tax-rates-for-cryptocurrency',
  },
  {
    title: 'TokenTax Help Center',
    link: 'https://help.tokentax.co',
  },
];
