import React, { useEffect } from 'react';
import { snakeCase, toUpper } from 'lodash';
import { Link } from 'react-router-dom';

import Button from '../../../Button';
import { CURRENT_TAX_YEAR } from '../../../../constants/taxYears';
import { GenerateProps } from './types';
import { EnumTaxMethod } from '../../../../graphql/types';
import { useGenerateReport } from '../../../../graphql/mutations/generateReport';

const getReportType = (
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  current: any,
  _lineItemCount: number,
  _taxYear: number,
  _isUSBased: boolean,
) => {
  const file = current.historyValue.states.file.current;
  const software = current.historyValue.states.software.current;

  if (file === 'software') {
    switch (software) {
      case 'turbotaxDesktop':
        return 'TXF_8949';
        break;
      case 'turbotaxOnline':
        return 'CSV_8949_TURBOTAX';
        break;
      case 'taxact':
        return 'CSV_8949_TAXACT';
        break;
      case 'other':
        return 'CSV_8949';
        break;
      default:
        throw new Error('invalid choice');
    }
  }

  if (file === 'cpa') {
    return 'CSV_8949';
  }

  throw new Error('invalid choice');
};

export default function Generate({
  current,
  lineItemCount,
  isUSBased,
  onClose,
  setTitle,
  taxYear,
}: GenerateProps) {
  const [generateReport, { error }] = useGenerateReport({
    onError: (e) => {
      if (e.message === 'limit exceeded') {
        setTitle && setTitle("You have exceeded your plan's transaction limit");
      } else {
        setTitle && setTitle('Failed to generate report.');
      }
    },
    variables: {
      report: {
        reportType: getReportType(current, lineItemCount || 0, taxYear || CURRENT_TAX_YEAR, isUSBased),
        taxYear: taxYear || CURRENT_TAX_YEAR,
        method: toUpper(snakeCase(current.historyValue.states.method.current)) as EnumTaxMethod,
      },
    },
  });

  useEffect(() => {
    //TODO: generate on click or something else
    generateReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <div className="pb-3 px-6">
        <p className="mt-3 mb-6">
          Please upgrade your plan for this tax year or purchase a transaction limit increase add-on.
        </p>
        <Link to="/purchase">
          <Button onClick={onClose}>Upgrade plan</Button>
        </Link>
      </div>
    );
  }

  return (
    <div className="pb-3 px-6">
      <p className="mt-3 mb-6">
        Your tax form is generating. On the following page, you can download your form and then send it to
        your CPA or upload it to your tax software.
      </p>
      <Link to="/documents">
        <Button onClick={onClose}>Download forms</Button>
      </Link>
    </div>
  );
}
