export const NUM_ITEMS = 20;

export const missingCostBasisFilterOption = {
  value: 'missingCostBasis',
  label: 'Missing Cost Basis',
};

export const zeroProceedsFilterOption = {
  value: 'zeroProceeds',
  label: 'Zero Proceeds',
};
