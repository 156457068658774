import { SortDirectionOptions } from '../../graphql/types';
import { defaultFiltersState, getDefaultPaginationOptions } from './defaults';
import type { Item, SelectionSpan, SortState, Vars } from './types';

export interface Props<SortOptions, Filters, IdType extends string | number> {
  defaultSortState: SortState<SortOptions>;
  pageSize: number;
  vars: Vars<SortOptions, Filters, IdType>;
  defaultHiddenColumns: string[];
}

export function getFilteredTableActions<SortOptions, Filters, IdType extends string | number>({
  defaultSortState,
  defaultHiddenColumns,
  pageSize,
  vars,
}: Props<SortOptions, Filters, IdType>) {
  const { multiSelectVar, sortVar, paginationVar, filtersVar, initialLoadingVar, hiddenColumnsVar } = vars;
  const defaultPaginationOptions = getDefaultPaginationOptions(pageSize);

  const actions = {
    selectMany: (items?: Item<IdType>[], selectionSpan?: SelectionSpan) => {
      const prevSelectState = multiSelectVar();
      multiSelectVar({
        ...prevSelectState,
        selected: (items ?? []).map((t) => t.id),
        selectionSpan: selectionSpan ?? null,
      });
    },
    select: (id: IdType) => {
      const prevSelectState = multiSelectVar();
      const { selected } = prevSelectState;
      const wasPreviouslySelected = selected.includes(id);
      multiSelectVar({
        ...prevSelectState,
        selected: wasPreviouslySelected ? selected.filter((v) => v !== id) : [...selected, id],
        selectionSpan: null,
      });
    },
    deselect: (ids: IdType[]) => {
      const prevSelectState = multiSelectVar();
      multiSelectVar({
        ...prevSelectState,
        selectionSpan: null,
        selected: prevSelectState.selected.filter((id) => !ids.includes(id)),
      });
    },
    setSortBy: (sort: SortOptions) => {
      sortVar({ ...sortVar(), sortBy: sort });
    },
    setSortDir: (dir: SortDirectionOptions) => {
      sortVar({ ...sortVar(), sortDir: dir });
    },
    setPage: (page: number) => {
      const { selectionSpan } = multiSelectVar();
      if (selectionSpan !== 'FilterSet') {
        multiSelectVar({ selected: [], selectionSpan: null });
      }
      if (page === paginationVar().page) return;
      paginationVar({ page, pageSize });
    },
    resetFilters() {
      filtersVar(defaultFiltersState as Filters);
    },
    resetPagination: () => {
      paginationVar(defaultPaginationOptions);
    },
    setInitialLoading: (initialLoading: boolean) => {
      initialLoadingVar(initialLoading);
    },
    resetSortState: () => {
      sortVar(defaultSortState);
    },
    resetState: () => {
      actions.resetSortState();
      actions.resetPagination();
      actions.resetFilters();
      actions.selectMany([]);
      initialLoadingVar(true);
      actions.resetColumns();
    },
    setColumns: ({ selectedColumns, allColumns }: { selectedColumns: string[]; allColumns: string[] }) => {
      const hiddenColumns = allColumns.filter(
        (column) => column !== 'actions' && !selectedColumns.includes(column),
      );

      hiddenColumnsVar(hiddenColumns.length === 0 ? undefined : hiddenColumns); // use `undefined` instead of `[]` as default value
    },
    resetColumns: () => {
      hiddenColumnsVar(defaultHiddenColumns.length > 0 ? defaultHiddenColumns : undefined);
    },
  };

  return actions;
}
