import { TokensData } from '../../../contexts/TokensContext';
import { TxnFragment } from '../../../graphql/types';
import { CurrencyType } from './types';

export const getCurrencyForType = (txn: TxnFragment, type: CurrencyType) => {
  switch (type) {
    case 'buy':
      return txn.buyCurrency;
    case 'sell':
      return txn.sellCurrency;
    case 'fee':
      return txn.feeCurrency;
  }
};

export const getFetchableSymbol = (currency: string) => {
  const uppercaseCurrency = currency.toUpperCase(); // it should normally already be uppercase, but just for good measure

  // https://linear.app/tokentax/issue/TOK-1481/fetch-price-if-currency-ends-in-e
  if (uppercaseCurrency.endsWith('.E')) return uppercaseCurrency.substring(0, uppercaseCurrency.length - 2);

  return uppercaseCurrency;
};

export const getDefaultTokenIdForSymbol = (symbol: string | undefined, tokensContext: TokensData) => {
  if (!symbol) return null;
  const { tokensBySymbol } = tokensContext;

  return tokensBySymbol[getFetchableSymbol(symbol)]?.id ?? null;
};
