import { gql } from '@apollo/client';

export const PLAN = gql`
  fragment Plan on Plan {
    planId
    year
  }
`;

export const PRODUCTS = gql`
  fragment Products on Products {
    cryptoOnlyPlans {
      ...Plan
    }
    fullFilingPlans {
      ...Plan
    }
    addons {
      ...Plan
    }
  }
  ${PLAN}
`;
