import { ApolloClient, gql, makeVar, useApolloClient, useReactiveVar } from '@apollo/client';
import { noop } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useUserContext } from '../../contexts';
import { USER_INFO } from '../../graphql/fragments';
import { useAllCredentials } from '../../graphql/queries';
import {
  AllCredentialsQuery,
  GetUserInfoQuery,
  GetUserInfoQueryVariables,
  UserInfoFragment,
} from '../../graphql/types';
import { CREDENTIALS_ADMIN_ROLE } from '../../lib/Auth';
import { useObject } from '../../lib/hooks';
import { adminPageViewedUserIdVar } from './vars';

type Credential = AllCredentialsQuery['credentials']['edges'][0];

type AdminContextValue = {
  viewedUser: GetUserInfoQuery['user'] | null;
  setViewedUser: (user: UserInfoFragment | null) => void;
  resetUser: () => void;
  refetchUserData: () => Promise<void>;
  credentials?: Credential[];
  refetchCredentials: () => Promise<unknown>;
};

export const AdminContext = React.createContext<AdminContextValue>({
  viewedUser: null,
  setViewedUser: noop,
  resetUser: noop,
  refetchUserData: () => Promise.resolve(),
  credentials: [],
  refetchCredentials: () => Promise.resolve(),
});

const viewedUserVar = makeVar<UserInfoFragment | null>(null);

export const USER = gql`
  query GetUserInfo($userId: Int!) {
    user(userId: $userId) {
      ...UserInfo
      clonedUser {
        id
        email
      }
      clones {
        id
        email
        createdAt
      }
    }
  }
  ${USER_INFO}
`;

const fetchUser = async (client: ApolloClient<unknown>, userId: number) => {
  const { data } = await client.query<GetUserInfoQuery, GetUserInfoQueryVariables>({
    query: USER,
    fetchPolicy: 'network-only',
    variables: { userId },
  });
  return data;
};

function AdminProvider({ children }: { children: React.ReactNode }) {
  const client = useApolloClient();
  const viewedUser = useReactiveVar(viewedUserVar);
  const viewedUserIdState = useReactiveVar(adminPageViewedUserIdVar);
  const { roles } = useUserContext();
  const setViewedUser = (user?: UserInfoFragment | null) => {
    viewedUserVar(user ?? null);
  };

  const resetUser = useCallback(async () => {
    adminPageViewedUserIdVar(null);
    viewedUserVar(null);
    await client.resetStore();
  }, [client]);

  useEffect(() => {
    if (viewedUserIdState && (!viewedUser?.id || viewedUser.id !== viewedUserIdState)) {
      fetchUser(client, viewedUserIdState).then(async ({ user }) => {
        setViewedUser(user);
        await client.resetStore();
      });
    }
  }, [client, viewedUserIdState, viewedUser?.id]);

  const refetchUserData = useCallback(async () => {
    const { user } = await fetchUser(client, viewedUser!.id); // eslint-disable-line @typescript-eslint/no-non-null-assertion
    setViewedUser(user);
  }, [client, viewedUser]);

  const isCredentialsAdmin = roles?.includes(CREDENTIALS_ADMIN_ROLE);

  const { data: credentials, refetch: refetchCredentials } = useAllCredentials({
    variables: { userId: viewedUser?.id, extended: true, credentialsAdmin: isCredentialsAdmin },
    skip: !viewedUser,
  });

  const contextValue = useObject({
    viewedUser,
    setViewedUser,
    resetUser,
    refetchUserData,
    credentials,
    refetchCredentials,
  });

  return <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>;
}

export default React.memo(AdminProvider);
