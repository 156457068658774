import { UnreconciledTransactionsQuery } from '../../../../graphql/types';
import { getCategoryForTreatment } from '../Wizard/constants';
import { SideOrBoth } from '../Wizard/types';
import { getTreatmentBucket } from './treatmentBuckets';
import { Category, TREATMENTS, UnreconciledTransaction } from './types';

type UnreconciledTxnEntry = UnreconciledTransactionsQuery['unreconciledTransactions']['edges'][0];

type Props = {
  unreconciledTransaction: UnreconciledTxnEntry;
  chosenCategory?: Category;
};

export const buildUnreconciledTransaction = ({
  unreconciledTransaction,
  chosenCategory,
}: Props): UnreconciledTransaction => {
  const {
    contractPublicName,
    resolutionId,
    hash,
    credentials,
    timestamp,
    from,
    to,
    instruction,
    method,
    resolved,
    invalidated,
    reconIdentifier,
  } = unreconciledTransaction;
  const resolutionCategory = unreconciledTransaction.resolutionCategory as Category | null | undefined;
  const { sentTxnsCount, receivedTxnsCount } = unreconciledTransaction;
  const treatment = unreconciledTransaction.instruction?.treatment?.id;
  const { received, sent, both } = SideOrBoth;
  const side = sentTxnsCount === 0 ? received : receivedTxnsCount === 0 ? sent : both;

  const isEvmInstruction = instruction?.__typename === 'EvmInstruction';

  const treatmentIsUnknown = treatment === 14; // matches treatment row with this id in recon db

  // Suggestions are only supported for EVM at the moment
  const suggestedTreatment =
    treatment && !treatmentIsUnknown
      ? (treatment as TREATMENTS)
      : treatmentIsUnknown && isEvmInstruction && instruction?.proba1 && instruction.proba1 > 0.75
      ? (instruction?.pred1 as TREATMENTS)
      : null;

  const suggestedCategory = suggestedTreatment ? getCategoryForTreatment(suggestedTreatment, side) : null;
  const category = chosenCategory ?? resolutionCategory ?? null;

  const { tokenParam1, tokenParam2, publicNotes } = instruction ?? {};
  const proba1 = isEvmInstruction ? instruction?.proba1 : null;

  return {
    blocksvcHash: hash,
    from,
    to,
    methodId: method.id,
    methodHash: method.hash,
    methodName: method.name,
    id: unreconciledTransaction.id,
    sentTxnsCount,
    receivedTxnsCount,
    suggestedCategory,
    category,
    suggestedCategoryConfidence: proba1 ?? null,
    suggestedTreatment,
    suggestionFromTokenTaxAdmin: Boolean(treatment && !treatmentIsUnknown),
    suggestionFromModel: Boolean(treatmentIsUnknown && proba1 && proba1 >= 0.75),
    treatment,
    tokenParam1,
    tokenParam2,
    contract: {
      id: isEvmInstruction ? instruction.contractId : null,
      name: contractPublicName,
      address: to,
    },
    resolved,
    invalidated,
    resolutionId,
    resolutionCategory,
    credentials,
    timestamp,
    edited: false,
    isReconciling: false,
    publicNotes,
    bucket: getTreatmentBucket(suggestedTreatment),
    ecosystem: unreconciledTransaction.ecosystem,
    reconIdentifier: reconIdentifier ?? null,
  };
};
