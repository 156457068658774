// Styled components theme
import { css } from 'styled-components';

export const sizes = {
  giant: '64em',
  large: '48em',
  medium: '30em',
  small: '16em',
};

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label];
  // eslint-disable-next-line no-param-reassign
  accumulator[label] = (...args) => css`
    @media screen and (min-width: ${emSize}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

const theme = {
  fonts: {
    messina: "'Messina', helvetica, sans-serif",
    maax: "'Maax Mono Trial', monospace",
  },
  borderRadius: '4px',
  shadow: '0 2px 4px 0 rgba(0, 0, 0, 0.09)',
  shadowDarker: '0 2px 5px 1px rgba(0, 0, 0, 0.2)',
  breakpoints: [sizes.medium, sizes.large, sizes.giant],
  colors: {
    primaryColor: '#4948ee',
    secondaryColor: '#4948ee',
    linkColor: '#4948ee',
    washedBlue: '#ededfc',
    textBlack: '#071229',
    textBlack50: '#838894',
    white: '#fff',
    lightGray: '#F5F5F5',
    washedRed: '#ffdfdf',
    red: '#DA1D1D',
    washedGreen: '#E6F5E1',
    green: '#57DF58',
    markGreen: '#5FAF3A',
    gainGreen: '#19A974',
    greenOnBlue: '#3AB21C',
    yellow: '#ebd534',
    gold: '#FFD600',
    washedYellow: '#faf5d7',
    gray2: '#fafafa',
    gray3: '#f7f7f7',
    gray5: '#f3f3f3',
    gray7: '#e6e6e6',
    gray10: '#E5E5E5',
    gray15: '#d9d9d9',
    gray20: '#ccc',
    gray30: '#b2b2b2',
    gray40: '#999',
    gray50: '#808080',
    gray60: '#666',
    gray70: '#4d4d4d',
    gray80: '#333',
    gray90: '#1a1a1a',
    black: '#000',
    gray: '#777',
    transparent: 'transparent',
  },
  borderColors: {
    lightGray: 'rgba(0, 0, 0, 0.1)',
    mediumGray: 'rgba(0, 0, 0, 0.2)',
  },
  borders: ['1px solid #e6e6e6', '1px solid #ccc', '1px solid #d9d9d9'],
  space: [0, '0.25rem', '0.5rem', '1rem', '1.5rem', '2rem', '3rem', '4rem', '8rem', '16rem', '20rem'],
  maxWidths: [0, '1rem', '2rem', '4rem', '8rem', '16rem', '32rem', '48rem', '64rem', '90rem'],
  fontSizes: [
    '0.75rem',
    '0.875rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2.25rem',
    '3rem',
    '3.5rem',
    '4rem',
    '5rem',
  ],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
};

export default theme;
