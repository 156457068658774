import { gql } from '@apollo/client';

import { USER_INFO } from '../../graphql/fragments';

export const USER_QUERY = gql`
  query fetchUser {
    user {
      ...UserInfo
    }
  }
  ${USER_INFO}
`;

export const LATEST_BATCH_UPDATE_TXNS_JOB_STATUS = gql`
  query LatestBatchUpdateTxnsJobStatus {
    latestBatchUpdateTxnsJobStatus
  }
`;

export const LATEST_BATCH_DELETE_TXNS_JOB_STATUS = gql`
  query LatestBatchDeleteTxnsJobStatus {
    latestBatchDeleteTxnsJobStatus
  }
`;

export const USER_TAX_METHODS = gql`
  query UserTaxMethods {
    userTaxMethods {
      methods {
        id
        label
      }
    }
  }
`;
