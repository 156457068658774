import { NavItem } from './types';

export const isNavItemActive = (item: NavItem, location: string) => {
  if (item.children) {
    return item.children.some((child) => child.path === location);
  }
  if (item.matchPaths) {
    return item.matchPaths.some((path) => path.test(location));
  }
  return item.path === location;
};
