import moment from 'moment';

import {
  LatestCreateMovementsJobQuery,
  LatestGenerateAccountsJobQuery,
  LatestMissingPricesJobQuery,
  LatestReconGuideTreatmentsJobQuery,
} from '../../graphql/types';
import { FAILED_JOB_RELEVANCY_TIMEFAME_MS } from './consts';

type MissingPricesJob = LatestMissingPricesJobQuery['latestMissingPricesJob'];
type ReconGuideTreatmentsJob = LatestReconGuideTreatmentsJobQuery['latestReconGuideTreatmentsJob'];
type GenerateAccountsJob = LatestGenerateAccountsJobQuery['latestGenerateAccountsJob'];
type CreateMovementsJob = LatestCreateMovementsJobQuery['latestCreateMovementsJob'];

type SelfReconJob = MissingPricesJob | ReconGuideTreatmentsJob | GenerateAccountsJob | CreateMovementsJob;

interface ShouldThrowJobErrorProps {
  job: SelfReconJob;
  expiryTime?: number;
}

export const shouldThrowErrorForJob = ({
  job,
  expiryTime = FAILED_JOB_RELEVANCY_TIMEFAME_MS,
}: ShouldThrowJobErrorProps): boolean => {
  if (!job) return true; // Something weird happened!
  return job.status === 'failed' && moment().diff(moment(job.createdAt)) <= expiryTime;
};
