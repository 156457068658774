import { ReactiveVar } from '@apollo/client';
import {
  ArrayParam,
  BooleanParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
} from 'use-query-params';
import { PaginationOptions, SortDirectionOptions } from '../../graphql/types';
import { PaginationState } from '../utils/pagination';

export interface TableState<SortOptions, Filters, IdType extends string | number> {
  sort: SortState<SortOptions>;
  page: number;
  pagination: PaginationOptions;
  filters: Filters;
  multiSelect: MultiSelectState<IdType>;
  initialLoading: boolean;
}

export type SelectionSpan = 'Page' | 'FilterSet' | null;

export interface MultiSelectState<IdType extends string | number> {
  selectionSpan: SelectionSpan;
  selected: IdType[];
}

export interface SortState<SortOptions> {
  sortBy: SortOptions;
  sortDir: SortDirectionOptions;
}

export interface SortQueryParams<SortOptions> {
  sortBy: SortOptions | null | undefined;
  sortDir: string | null | undefined;
}

export interface PaginationQueryParams {
  page: number | null | undefined;
}

export interface ColumnsParams {
  hiddenColumns: string[];
}

export type Item<IdType extends string | number> = {
  id: IdType;
};

export interface Vars<SortOptions, Filters, IdType extends string | number> {
  paginationVar: ReactiveVar<PaginationState>;
  sortVar: ReactiveVar<SortState<SortOptions>>;
  filtersVar: ReactiveVar<Filters>;
  multiSelectVar: ReactiveVar<MultiSelectState<IdType>>;
  initialLoadingVar: ReactiveVar<boolean>;
  hiddenColumnsVar: ReactiveVar<string[] | undefined>;
}

// ideally we would use a type built from a GraphQL interface for this but I can't seem to make it work
export interface PageInfo {
  filteredCount?: number | null;
}

export interface Response<Data> {
  pageInfo: PageInfo;
  edges: Data[];
}

export type QueryParamsConf = Record<
  string,
  | typeof NumberParam
  | typeof StringParam
  | typeof ArrayParam
  | typeof DelimitedNumericArrayParam
  | typeof BooleanParam
>;

export type Query<SortOptions, FilteringQueryParams> = PaginationQueryParams &
  SortQueryParams<SortOptions> &
  FilteringQueryParams;

export interface Actions<SortOptions, FilteringQueryParams, IdType extends string | number> {
  selectMany: (items?: Item<IdType>[], selectionSpan?: SelectionSpan) => void;
  select: (id: IdType) => void;
  deselect: (ids: IdType[]) => void;
  setSortBy: (sort: SortOptions) => void;
  setSortDir: (dir: SortDirectionOptions) => void;
  setPage: (page: number) => void;
  resetFilters: () => void;
  resetPagination: () => void;
  setStateFromQuery: (query: Query<SortOptions, FilteringQueryParams>) => void;
  setInitialLoading: (initialLoading: boolean) => void;
  resetState: () => void;
  setColumns: ({ selectedColumns, allColumns }: { selectedColumns: string[]; allColumns: string[] }) => void;
  resetColumns: () => void;
}

export interface CombinedState<SortOptions, Filters> {
  filtersState: Filters;
  sortState: SortState<SortOptions>;
  paginationState: PaginationOptions;
}

export type ColumnWithHeader = { Header: string; id: string };
