import { isUndefined, omitBy } from 'lodash';
import React from 'react';
import {
  buildCredentialIdFilterUrlParam,
  buildFromAddressFilterUrlParam,
  buildMethodIdFilterUrlParam,
  buildReconIdentifierFilterUrlParam,
  buildToAddressFilterUrlParam,
  buildTxnHashFilterUrlParam,
} from '../AllTransactions/Table/utils';
import Icon from '../Icon';
import { CopyableValueFilter } from './types';

type Props = CopyableValueFilter & {
  specIdSellId?: string | null;
  onFilter?: () => void;
};

export const getFilterProps = ({ specIdSellId, ...props }: Props) => {
  if ('blocksvcHash' in props) {
    const { blocksvcHash } = props;
    if (blocksvcHash === null || blocksvcHash === undefined) return null;
    return {
      url: `/all-transactions${buildTxnHashFilterUrlParam(blocksvcHash, { specIdSellId })}`,
      title: 'Filter by Hash',
    };
  }
  if ('blocksvcMethodId' in props) {
    const { blocksvcMethodId } = props;
    if (blocksvcMethodId === null || blocksvcMethodId === undefined) return null;
    return {
      url: `/all-transactions${buildMethodIdFilterUrlParam(blocksvcMethodId, { specIdSellId })}`,
      title: 'Filter by Method ID',
    };
  }
  if ('reconIdentifier' in props) {
    const { reconIdentifier } = props;
    if (reconIdentifier === null || reconIdentifier === undefined) return null;
    return {
      url: `/all-transactions${buildReconIdentifierFilterUrlParam(reconIdentifier, { specIdSellId })}`,
      title: 'Filter by Recon Identifier',
    };
  }
  if ('fromAddress' in props) {
    const { fromAddress } = props;
    if (fromAddress === null || fromAddress === undefined) return null;
    return {
      url: `/all-transactions${buildFromAddressFilterUrlParam(fromAddress, { specIdSellId })}`,
      title: 'Filter by From Address',
    };
  }
  if ('toAddress' in props) {
    const { toAddress } = props;
    if (toAddress === null || toAddress === undefined) return null;
    return {
      url: `/all-transactions${buildToAddressFilterUrlParam(toAddress, { specIdSellId })}`,
      title: 'Filter by To Address',
    };
  }
  if ('credentialId' in props) {
    const { credentialId } = props;
    if (credentialId === null || credentialId === undefined) return null;
    return {
      url: `/all-transactions${buildCredentialIdFilterUrlParam(credentialId, { specIdSellId })}`,
      title: 'Filter by Import',
    };
  }
};

function Filter({ onFilter, ...props }: Props) {
  const filterProps = getFilterProps(omitBy(props, isUndefined));
  if (!filterProps) return null;
  return (
    <a
      href={filterProps.url}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => {
        if (e.ctrlKey || e.metaKey || !onFilter) return;
        e.preventDefault();
        onFilter();
      }}
    >
      <Icon type="filter" className="cursor-pointer inline-block px-1" title={filterProps.title} />
    </a>
  );
}

export default React.memo(Filter);
