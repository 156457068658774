import { DocumentNode, OperationVariables, QueryHookOptions, useQuery, useReactiveVar } from '@apollo/client';
import Cookies from 'js-cookie';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from '../hooks/usePrevious';
import { hijackedUserIdVar, tokenDataVar } from './Auth';

export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function useClickOutside(ref: MutableRefObject<HTMLElement | null>, handler: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent, clickOutsideHandler: () => void) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        clickOutsideHandler();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', (event) => handleClickOutside(event, handler));
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', (event) => handleClickOutside(event, handler));
    };
  }, [ref, handler]);
}

// this hook memoizes an object so you don't have to write e.g.
// useMemo(() => ({ a, b }), [a, b])
export function useObject<T extends Record<string, unknown>>(object: T) {
  const deps = Object.values(object);

  return useMemo(() => object, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}

export function useQueryAndRefetchOnUserChange<
  TData,
  TVariables extends OperationVariables = OperationVariables,
>(query: DocumentNode, options?: QueryHookOptions<TData, TVariables>) {
  const hijackedUserId = useReactiveVar(hijackedUserIdVar);
  const tokenUserId = useReactiveVar(tokenDataVar)?.id;
  const actingUserId = hijackedUserId ?? tokenUserId ?? null;
  const previousActingUserId = usePrevious(actingUserId);
  const isLoggedIn = Boolean(useReactiveVar(tokenDataVar));

  const queryRes = useQuery<TData, TVariables>(query, {
    ...options,
    skip: !isLoggedIn || Boolean(options?.skip),
  });

  useEffect(() => {
    if (actingUserId && previousActingUserId && actingUserId !== previousActingUserId && !queryRes.loading) {
      queryRes.refetch();
    }
  }, [actingUserId, previousActingUserId, queryRes]);

  return queryRes;
}

/**
 * Used to track and implement A/B tests using cookies.
 *
 * Accepts an experimentName and array of experimentGroup options.
 * Returns the value of the experiment group the user is in.
 * If the user is not yet in an experiment group, it will randomly assign them to one,
 * store the value in a cookie, and return the value.
 */
export function useExperiment(experimentName: string, experimentGroups: string[]) {
  const [experimentGroup, setExperimentGroup] = useState('');

  useEffect(() => {
    const valFromCookie = Cookies.get(experimentName);

    if (!valFromCookie) {
      const randomGroupAssignment = experimentGroups[Math.floor(Math.random() * experimentGroups.length)];
      Cookies.set(experimentName, randomGroupAssignment);
      setExperimentGroup(randomGroupAssignment);
    } else {
      setExperimentGroup(valFromCookie);
    }
  }, [experimentName, experimentGroups]);

  return experimentGroup;
}
