import { useReactiveVar } from '@apollo/client';
import React, { ReactElement, useContext, useMemo } from 'react';
import { TxnFilterQuery } from '../../../../graphql/types';
import pluralize from '../../../../lib/pluralize';
import Spinner from '../../../Spinner';
import { Toolbar } from '../../../Table';
import { AllTransactionsContext } from '../../Provider/context';
import SpecIdToolbarSummary from '../../SpecIdPanel/SpecIdToolbarSummary';
import { actions } from '../../actions';
import { vars } from '../../vars';
import ExportAction from '../ExportAction';
import SelectedActions from '../SelectedActions';
import TransactionCount from '../TransactionCount';
import Filters from './Filters';

interface ToolbarProps {
  columnSelector?: ReactElement;
  showSpinner?: boolean;
}

function TxnToolbar({ columnSelector, showSpinner }: ToolbarProps) {
  const { filtersVar, sortVar, initialLoadingVar } = vars;
  const initialLoad = useReactiveVar(initialLoadingVar);
  const filtersState = useReactiveVar(filtersVar);
  const sortState = useReactiveVar(sortVar);
  const {
    loading,
    countsLoading,
    counts,
    visibleCount,
    selectedTxns,
    specIdTxnResponse,
    credentialsBeingReconciled,
  } = useContext(AllTransactionsContext);
  const { reviewedCount } = counts ?? {};
  const credentialsBeingReconciledCount = credentialsBeingReconciled?.length ?? 0;

  const filterQuery: TxnFilterQuery = useMemo(() => {
    return JSON.parse(filtersState.filterQuery || '{}');
  }, [filtersState.filterQuery]);

  return (
    <>
      <Toolbar roundedTop paddingBottom={false}>
        <div className="flex w-full pb-3">
          <div className="w-full">
            <Filters filterQuery={filterQuery} />
          </div>
          <div className="shrink-0 ml-[1rem]">{columnSelector}</div>
        </div>
      </Toolbar>
      <Toolbar
        background="shade"
        className="justify-between items-start text-sm flex-nowrap border-b-0 bg-light-shade dark:bg-dark-shade"
      >
        <div className="flex min-h-[32px] items-center gap-5">
          {specIdTxnResponse?.data?.txn ? (
            <SpecIdToolbarSummary />
          ) : (
            !countsLoading && (
              <>
                <TransactionCount
                  hidden={selectedTxns && selectedTxns.length > 0}
                  loading={initialLoad || (loading && !visibleCount)}
                  totalCount={visibleCount}
                  reviewedCount={reviewedCount}
                  actions={actions}
                  filtersVar={filtersVar}
                />

                <SelectedActions />
              </>
            )
          )}

          {credentialsBeingReconciledCount > 0 && (
            <span className="flex items-center">
              {(showSpinner || countsLoading || credentialsBeingReconciledCount > 0) && (
                <Spinner size="sm" className="self-center mr-2" />
              )}
              {credentialsBeingReconciledCount} {pluralize(credentialsBeingReconciledCount, 'import')} being
              reconciled
            </span>
          )}
        </div>
        <div className="flex items-start shrink-0">
          <ExportAction filtersState={filtersState} sortState={sortState} />
        </div>
      </Toolbar>
    </>
  );
}

export default React.memo(TxnToolbar);
