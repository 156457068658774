import { onError } from '@apollo/client/link/error';
import { once } from 'lodash';
import React from 'react';
import { showConfirmationDialog } from '../../components-v2/Modal/ConfirmationDialog';
import { dispatchUnauthorized } from './clientEvents';

export const BAD_GATEWAY = 502;
export const UNAUTHORIZED = 401;
export const GRAPHQL_SCHEMA_ERROR = 'GRAPHQL_VALIDATION_FAILED';

const showUpdatedApiDialog = once(() => {
  showConfirmationDialog({
    title: 'New version available',
    content: function UpdatedApiDialog() {
      return (
        <div className="mb-4">
          A new version of the app is available! Reload the page now to get the latest features.
        </div>
      );
    },
    buttonText: 'Reload the page',
    cancelButtonText: 'I will reload later',
    onConfirm: () => {
      window.location.reload();
    },
    showCloseIcon: false,
  });
});

export const errorLink = onError(({ networkError, operation, graphQLErrors }) => {
  const operationContainsMutation = operation.query.definitions.some(
    (definition) => definition.kind === 'OperationDefinition' && definition.operation === 'mutation',
  );

  const operationWasNotSent = networkError && !('statusCode' in networkError); // e.g. there was no connectivity

  if (operationWasNotSent || (networkError?.statusCode === BAD_GATEWAY && !operationContainsMutation)) {
    // we occasionally get a "Bad Gateway" error from k8s when under high load
    // set the context to be used upstream by the retryLink
    operation.setContext(Object.assign(operation.getContext(), { retry: true }));
    return;
  }

  if (networkError?.statusCode === UNAUTHORIZED && operation.operationName !== 'refreshToken') {
    // if the user is logged in in more than 1 browser, we're currently unable to keep track of multiple refresh tokens
    // for now, ignore Auth errors thrown by `refreshToken` mutations
    // [1] https://tokentax.slack.com/archives/CHJKQKR4P/p1717700011414669
    dispatchUnauthorized();
    return;
  }

  if (graphQLErrors?.some((err) => err.extensions?.code === GRAPHQL_SCHEMA_ERROR)) {
    // We want to catch this and alert the user that they should refresh the page if they are caught in the middle
    // of a GraphQL schema change (i.e. we push some change in api-v2 while the user is using the app)
    // eslint-disable-next-line no-console
    console.error('API validation failed. Please refresh your page or contact support.');
    showUpdatedApiDialog();
    return;
  }
});
