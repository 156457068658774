import React, { useState } from 'react';
import { useEvent } from 'react-use';
import { DateRangeFilter } from '../../../graphql/types';
import DateTimePicker from '../../DateTimePicker';
import { showErrorFlash } from '../../Flash';
import Text from '../../Text';
import { BaseFilterProps } from '../types';

export type DateRangeInputProps = BaseFilterProps<DateRangeFilter>;

function DateRangeInput({ onChange, filter }: DateRangeInputProps) {
  const [startDate, setStartDate] = useState<string | undefined>(filter?.fromDate ?? undefined);
  const [endDate, setEndDate] = useState<string | undefined>(filter?.toDate ?? undefined);

  useEvent('keydown', (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  });

  const handleSubmit = () => {
    if (!startDate && !endDate) {
      return showErrorFlash('Please select either a start or end date');
    }

    onChange({
      ...(filter || {}),
      fromDate: startDate,
      toDate: endDate,
    });
  };

  return (
    <div style={{ width: 280 }}>
      <div className="flex px-2 pt-2 pb-3">
        <div>
          <DateTimePicker
            label="Start date"
            value={startDate}
            dateOnly
            onChange={(value) => {
              setStartDate(value);
            }}
            utc
            allowClear
            closeOnSelect
            inputProps={{
              padding: 'p-1',
            }}
          />
        </div>
        <div className="px-5 relative pointer-events-none select-none" style={{ top: 30 }}>
          <Text variant="muted">to</Text>
        </div>
        <div>
          <DateTimePicker
            label="End date"
            value={endDate}
            dateOnly
            onChange={(value) => {
              setEndDate(value);
            }}
            utc
            allowClear
            closeOnSelect
            inputProps={{
              padding: 'p-1',
            }}
          />
        </div>
      </div>
      <button
        onClick={handleSubmit}
        className="text-center w-full p-1 border-t border-light-control dark:border-dark-control text-sm font-semibold relative z-10"
      >
        Apply
      </button>
    </div>
  );
}

export default React.memo(DateRangeInput) as typeof DateRangeInput;
