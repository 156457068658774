import { ApolloProvider, useReactiveVar } from '@apollo/client';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import Big from 'big.js';
import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { Route, Router } from 'react-router-dom';
import { QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params';
import App from './App';
import PlanActivator from './components-v2/ActivatePlan/PlanActivator';
import { showNetworkErrorFlash } from './components-v2/Flash';
import { textVariantClassnames } from './components-v2/Text';
import { DECIMAL_PRECISION } from './constants/numbers';
import Auth from './lib/Auth';
import { client } from './lib/apollo';
import { onNetworkError } from './lib/apollo/clientEvents';
import history from './lib/history';
import { initSentry } from './lib/sentry';
import IntegrationsProvider from './providers/IntegrationsProvider';
import PaymentProvider from './providers/PaymentProvider';
import PlansProvider from './providers/PlansProvider';
import RecalcProvider from './providers/RecalcProvider';
import TokensProvider from './providers/TokensProvider';
import UserProvider from './providers/UserProvider';
import VersionsProvider from './providers/VersionsProvider';
import ThemeMetaTags from './theme/ThemeMetaTags';
import { useTheme } from './theme/useTheme';
import { flashVar, modalsVar } from './variables';

initSentry();

const queryStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

setUseWhatChange(process.env.NODE_ENV === 'development');

// uncomment to use WDYR
// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     // trackAllPureComponents: true,
//   });
// }

const Client = () => {
  const flash = useReactiveVar(flashVar);
  const modals = useReactiveVar(modalsVar);
  const { theme } = useTheme();

  useEffect(() => {
    Auth.refreshToken(); // refresh token when page is (re)-loaded

    // attempt to refresh the token on every click (the actual API call is throttled)
    document.addEventListener('mousedown', Auth.refreshToken, true);
    document.addEventListener('touchstart', Auth.refreshToken, true);

    onNetworkError((event) => {
      console.error(event); // eslint-disable-line no-console
      showNetworkErrorFlash();
    });

    client.onClearStore(async () => {
      // clear flashes and modals on logout
      modalsVar([]);
      flashVar(null);
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
          <PlansProvider>
            <UserProvider>
              <VersionsProvider client={client}>
                <PaymentProvider>
                  <RecalcProvider>
                    <IntegrationsProvider>
                      <TokensProvider>
                        <ThemeMetaTags theme={theme} />
                        <PlanActivator>
                          <App />
                          {flash}
                          {modals}
                        </PlanActivator>
                      </TokensProvider>
                    </IntegrationsProvider>
                  </RecalcProvider>
                </PaymentProvider>
              </VersionsProvider>
            </UserProvider>
          </PlansProvider>
        </QueryParamProvider>
      </Router>
    </ApolloProvider>
  );
};

Big.NE = -DECIMAL_PRECISION; // The negative exponent value at and below which toString returns exponential notation https://mikemcl.github.io/big.js/#eneg
Big.PE = DECIMAL_PRECISION; //  The positive exponent value at and above which toString returns exponential notation https://mikemcl.github.io/big.js/#epos
Big.DP = DECIMAL_PRECISION; //  The maximum number of decimal places of the results of operations involving division https://mikemcl.github.io/big.js/#dp

const root = document.getElementById('root');

// apply "base" variant styles to text by default
root?.classList.add(...textVariantClassnames.base);
render(<Client />, root);

if (module.hot) {
  module.hot.accept();
}
