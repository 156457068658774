import { flatten } from 'lodash';
import React from 'react';
import { ColumnInstance } from 'react-table';
import HeaderCell from '../Cells/HeaderCell';
import { useTableContext } from '../context';
import { HandleSort, TableChildrenProps } from '../types';

function ControlledHeader<T extends Record<string, unknown>>({
  data,
}: TableChildrenProps<T>): React.ReactElement {
  const { instance, paginationInstance, controlledState, handlers, windowedScrollBarPadding, gridStyle } =
    useTableContext<T>();

  if (instance === undefined || paginationInstance === undefined) {
    throw Error('table and pagination instance should not be undefined for ControlledHeader');
  }

  if (controlledState === undefined) {
    throw Error('controlledState needs to be set for ControlledHeader');
  }

  const sortBy = controlledState?.sortBy;
  const sortDirection = controlledState?.sortDirection;

  const { headerGroups } = instance;

  // we don't use/support headerGroups as shown in https://react-table.tanstack.com/docs/examples/grouping-column
  // no need to complicate the markup for now
  const allHeaders = flatten(headerGroups.map(({ headers }) => headers));
  const paddingRight = `calc(${gridStyle?.paddingRight ?? 0} + ${windowedScrollBarPadding ?? 0}px)`;

  return (
    <div
      className="sticky rounded-t z-50 border-b bg-light-base dark:bg-dark-base"
      style={{ top: -1, ...gridStyle, paddingRight }}
    >
      {allHeaders.map((column: ColumnInstance<T>) => {
        const headerProps = column.getHeaderProps();
        const sorted = column.id === sortBy ? sortDirection || null : null;

        let onSort;
        if (handlers?.handleSort) {
          const handleSort = handlers?.handleSort as HandleSort<T>;
          onSort = () => handleSort(column, sorted);
        }

        return (
          <HeaderCell
            data={data}
            key={headerProps.key}
            column={column}
            sorted={sorted}
            onSort={onSort}
            tableType="controlled"
          />
        );
      })}
    </div>
  );
}

export default React.memo(ControlledHeader);
