import React from 'react';

import Arrow from '../../Arrow';
import { OptionType } from '../../../Select';
import HR from '../../../HR';

const sectionClasses =
  'flex justify-between leading-7 text-xl p-6 w-full hover:bg-light-hover dark:hover:bg-dark-hover';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Option({ option, send, next }: { option: OptionType; send: any; next?: string }) {
  return (
    <>
      <HR className="my-0" />
      <button
        className={sectionClasses}
        onClick={() => {
          send(option.value);
          next && send(next);
        }}
      >
        {option.label}
        <Arrow dir="right" />
      </button>
    </>
  );
}
