import cx from 'classnames';
import { memoize } from 'lodash';
import React from 'react';
import { GroupBase, OptionProps, components } from 'react-select';
import { BkpAccountsVendorsQuery } from '../../../graphql/types';
import { OptionType } from '../../Select';

type BkpAccount = BkpAccountsVendorsQuery['bkpAccounts'][0];

export const sortAccounts = memoize((accounts: BkpAccount[]) => {
  type MapStructure = {
    [key: string]: BkpAccount[];
  };

  const map = accounts.reduce((acc, val) => {
    if (!val.bkpIntegrationParentRefId) {
      if (acc['0']) {
        acc['0'] = [...acc['0'], val];
      } else {
        acc['0'] = [val];
      }
      return acc;
    }

    if (acc[val.bkpIntegrationParentRefId]) {
      acc[val.bkpIntegrationParentRefId] = [...acc[val.bkpIntegrationParentRefId], val];
    } else {
      acc[val.bkpIntegrationParentRefId] = [val];
    }

    return acc;
  }, {} as MapStructure);

  // Array of parents
  const parents = map['0'];

  // Remove parents from map
  delete map['0'];

  const finalArray: BkpAccount[] = [];
  for (const parent of parents ?? []) {
    finalArray.push(parent);

    const children = map[parent.bkpIntegrationId!]; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    if (children) {
      finalArray.push(...children);
    }
  }

  return finalArray;
});

export const BkpAccountOption = (
  props: OptionProps<OptionType<BkpAccount>, false, GroupBase<OptionType<BkpAccount>>>,
) => {
  const { innerRef, getStyles, isDisabled, isFocused, isSelected, ...restProps } = props;

  const styles = cx(getStyles('option', props), props.data.value.bkpIntegrationParentRefId && 'pl-6');

  return (
    <div>
      <components.Option
        getStyles={getStyles}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        innerRef={innerRef}
        {...restProps}
      >
        <div className={styles}>
          <div>{props.data.value.name}</div>
          <div className="opacity-60">{props.data.value.bkpIntegrationAccountType}</div>
        </div>
      </components.Option>
    </div>
  );
};
