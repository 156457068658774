import { head } from 'lodash';
import { CURRENT_TAX_YEAR } from '../../constants/taxYears';
import { EnumTaxMethod, LatestTxnReportsQuery } from '../../graphql/types';
import { client } from '../../lib/apollo';
import { defaultRecalculationState, recalculationVar } from '../../variables';
import { defaultMethod, defaultYear } from './constants';
import selectors from './selectors';
import { TaxDashboardQueryParams } from './types';
import { initialOptionsSetVar, initialQuerySetVar, methodVar, yearVar } from './vars';

type LatestTxnReportsByYearType = NonNullable<
  LatestTxnReportsQuery['latestTxnReports']
>['latestTxnReportsByYear'][0];

export const actions = {
  setInitialOptions: (reports: LatestTxnReportsByYearType[], initialQuery: TaxDashboardQueryParams) => {
    const yearOptions = selectors.getYearOptions(reports);
    const yearValues: number[] = yearOptions.map(({ value }) => value);

    // default year to first report
    let reportYear: number = head(yearValues) || CURRENT_TAX_YEAR;

    // use value in initial query params if it is available
    if (initialQuery.year && yearValues.includes(initialQuery.year)) {
      reportYear = initialQuery.year;
    }

    yearVar(reportYear);

    const methodOptions = selectors.getMethodOptions(reports, reportYear);
    const methodValues: EnumTaxMethod[] = methodOptions.map(({ key }) => key as EnumTaxMethod);

    // default method to first method
    let method = head(methodValues) || EnumTaxMethod.FIFO;

    // use value in initial query params if it is available
    if (initialQuery.method && methodValues.includes(initialQuery.method as EnumTaxMethod)) {
      method = initialQuery.method as EnumTaxMethod;
    }
    methodVar(method);

    initialOptionsSetVar(true);
  },
  setStateFromQuery: (query: TaxDashboardQueryParams): void => {
    const currentYearState = yearVar();
    if (query.year && query.year !== currentYearState) {
      yearVar(query.year);
    }

    const currentMethodState = methodVar();
    if (query.method && (query.method as EnumTaxMethod) !== currentMethodState) {
      methodVar(query.method as EnumTaxMethod);
    }
  },
  setInitialQuery: () => {
    initialQuerySetVar(true);
    return {
      year: yearVar(),
      method: methodVar(),
    };
  },
  setMethod: (method: EnumTaxMethod) => {
    methodVar(method);
  },
  setYear: (year: number) => {
    yearVar(year);
  },
  resetInitialQuerySet: () => {
    initialQuerySetVar(false);
  },
  resetState: (): void => {
    initialQuerySetVar(false);
    initialOptionsSetVar(false);
    recalculationVar(defaultRecalculationState);
    methodVar(defaultMethod);
    yearVar(defaultYear);
  },
};

client.onResetStore(async () => {
  actions.resetState();
});
