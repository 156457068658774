import React, { useState, useCallback } from 'react';

import Button from '../../Button';
import { RadioInput } from '../../Input';
import Modal, { ModalProps, showModal } from '../../Modal';
import Spinner from '../../Spinner';
import { showSuccessFlash } from '../../Flash';
import { ErrorToast } from '../../Toast';
import { FiltersState, SortState } from '../types';
import { useTxnsCSVExport } from '../queries';
import { TxnFilterQuery } from '../../../graphql/types';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

interface ExportCSVModalProps extends ModalProps {
  filtersState: FiltersState;
  sortState: SortState;
}

function ExportCSVModal({ onClose, filtersState, sortState }: ExportCSVModalProps) {
  const [touched, setTouched] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | undefined>(undefined);

  const { filterQuery } = filtersState;

  const [exportToCSV, { loading, error }] = useTxnsCSVExport({
    onCompleted: (data) => {
      // modal doesn't close if export fails
      if (data?.exportTxns) {
        window.location.href = data?.exportTxns;
      } else {
        showSuccessFlash(
          'Your export is being processed and will be available shortly on the Documents page.',
        );
      }
      window.analytics.track('transaction_csv_exported');
      onClose?.();
    },
    variables: {
      sortBy: sortState.sortBy,
      sortDirection: sortState.sortDir,
      filterQuery: selected !== 'all' && filterQuery ? JSON.parse(filterQuery) : ({} as TxnFilterQuery),
    },
  });

  return (
    <Modal title="Export To CSV" onClose={onClose}>
      {error && <ErrorToast>Export to CSV failed.</ErrorToast>}
      <RadioInput
        id="all"
        checked={selected === 'all'}
        className="auto-cols-max"
        onChange={useCallback(() => {
          setSelected('all');
          setTouched(true);
        }, [])}
        label="All Transactions"
        value="All"
      />
      <RadioInput
        id="filtered"
        checked={selected === 'filtered'}
        className="auto-cols-max"
        onChange={useCallback(() => {
          setSelected('filtered');
          setTouched(true);
        }, [])}
        label="Filtered Transactions"
        value="Filtered"
      />
      <div className="pt-4">
        <Button
          disabled={loading || !touched}
          onClick={() => {
            exportToCSV();
          }}
          type="submit"
        >
          {loading && <Spinner className="mr-2" size="sm" />}Export
        </Button>
      </div>
    </Modal>
  );
}

export default React.memo(ExportCSVModal);

export function showExportCSVModal(props: ExportCSVModalProps) {
  showModal(<ExportCSVModal {...props} />);
}
