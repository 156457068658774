import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Auth from '../../lib/Auth';
import Button from '../Button';
import { loginOrSignUpWithGoogle } from './mutations';

import iconSvg from './icon.svg';

interface Props {
  showError: (error: string | null) => void;
  type: string;
}

type GoogleResponse = Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>;

export default function GoogleSignIn({ type, showError }: Props) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const isSignupPage = type === 'signup';

  const handleResponse = useCallback(
    async (googleResponse?: GoogleResponse) => {
      showError(null);
      setIsLoading(true);

      try {
        if (!googleResponse) {
          return showError('Unable to verify with google.');
        }

        const { user, isSignup, userHash } = await loginOrSignUpWithGoogle({
          token: googleResponse.access_token,
        });
        const { email, firstName, lastName, id } = user;

        Auth.saveTokenData();

        // We're aliasing here first to merge Vero
        // profiles if the user already signed up for our
        // mailing list before signing up for the app.
        // https://segment.com/docs/connections/destinations/catalog/vero/#alias
        window.analytics.alias(String(id), email);

        window.analytics.identify(
          String(id),
          {
            firstName,
            lastName,
            email,
          },
          {
            integrations: { Intercom: { user_hash: userHash } },
          },
        );

        if (isSignup) {
          window.analytics.track('sign_up_completed', {
            location: 'sign_up',
            type: 'google_verification',
            onboarding_bucket: 'default',
            checkout_source: 'default',
          });

          history.push('/purchase');
        }
      } catch (e) {
        showError('Unable to verify with google.');
      } finally {
        setIsLoading(false);
      }
    },
    [history, showError],
  );

  const login = useGoogleLogin({
    onSuccess: (response) => handleResponse(response),
    onError: () => handleResponse(),
    onNonOAuthError: () => handleResponse(),
  });

  return (
    <div className="max-w-lg mx-auto mt-4">
      <Button
        large
        fullWidth
        extraPadding
        onClick={() => login()}
        type="button"
        loading={isLoading}
        variant="secondary"
        icon={<img src={iconSvg} width={24} />}
      >
        {isSignupPage ? 'Sign up' : 'Sign in'} with Google
      </Button>
    </div>
  );
}
