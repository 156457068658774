import { gql } from '@apollo/client';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { CardHolderName, PaymentContext } from '../contexts';
import { PaymentSourcesQuery } from '../graphql/types';
import { useObject, useQueryAndRefetchOnUserChange } from '../lib/hooks';

const PAYMENT_SOURCES = gql`
  query PaymentSources {
    paymentSources {
      id
      addressZip
      brand
      expMonth
      expYear
      last4
    }
  }
`;

interface Props {
  children: ReactNode;
}

export default function PaymentProvider({ children }: Props) {
  const [formIsValid, setFormIsValid] = useState(false);
  const [nameData, setNameData] = useState<CardHolderName>({ firstName: '', lastName: '' });
  const { data, refetch: refetchSources } = useQueryAndRefetchOnUserChange<PaymentSourcesQuery>(
    PAYMENT_SOURCES,
    {
      errorPolicy: 'ignore',
      context: {
        batched: true,
      },
    },
  );
  const [selectedSourceId, setSelectedSourceId] = useState<string>();
  const sources = useMemo(() => data?.paymentSources ?? [], [data]);

  useEffect(() => {
    if (sources.length > 0) setSelectedSourceId(sources[0].id);
  }, [sources]);

  const contextValue = useObject({
    formIsValid,
    setFormIsValid,
    sources,
    refetchSources,
    selectedSourceId,
    setSelectedSourceId,
    nameData,
    setNameData,
  });

  return <PaymentContext.Provider value={contextValue}>{children}</PaymentContext.Provider>;
}
