import { useMutation } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { useUserContext } from '../../../contexts';
import {
  EnumTaxMethod,
  UpdateReportDetailMutation,
  UpdateReportDetailMutationVariables,
} from '../../../graphql/types';
import Button from '../../Button';
import Modal, { ModalProps, showModal } from '../../Modal';
import Select from '../../Select';
import Text from '../../Text';
import TextLink from '../../TextLink';
import { UPDATE_SETTINGS } from './queries';

interface Props extends ModalProps {
  /** previous year report object */
  year: number;
  /** previous year tax detail id */
  taxDetailsId: number;
  /** previous year tax method */
  taxMethod?: EnumTaxMethod;
  /** cb to run once mutation completes */
  onComplete?: () => void;
}

const PreviousYearModal = ({ year, taxDetailsId, taxMethod, onComplete, onClose }: Props) => {
  const [method, setMethod] = useState<EnumTaxMethod | undefined>(taxMethod);
  const { user } = useUserContext();
  const { taxDetails } = user ?? {};
  const taxMethods = taxDetails?.find(({ taxYear }) => taxYear === Number(year))?.country?.taxMethods;
  const methodOptions = useMemo(() => {
    return taxMethods?.map((method) => ({ value: method.id, label: method.label })) || [];
  }, [taxMethods]);

  const [updateSettings, { loading }] = useMutation<
    UpdateReportDetailMutation,
    UpdateReportDetailMutationVariables
  >(UPDATE_SETTINGS, {
    variables: {
      id: taxDetailsId,
      settings: {
        method: method,
      },
    },
    onCompleted: () => {
      onComplete?.();
      onClose?.();
    },
  });

  return (
    <Modal loading={loading} title={`Which method did you file with in ${year}?`} onClose={onClose}>
      <Text variant="muted" className="mb-4">
        To make your gains and losses as accurate as possible, you need to select with method you filed with
        in previous years.
      </Text>
      <Select<EnumTaxMethod>
        className="my-6"
        required
        label={'Tax method'}
        value={
          method
            ? { label: methodOptions.find((f) => f.value === method)?.label as string, value: method }
            : null
        }
        onChange={({ value }) => setMethod(value)}
        options={methodOptions}
      />
      <div className="flex justify-between">
        <div className="flex gap-4">
          <Button onClick={() => updateSettings()}>Save</Button>
          <TextLink onClick={onClose}>Cancel</TextLink>
        </div>
        <TextLink onClick={() => window.Intercom('showNewMessage')}>Need help? Message us</TextLink>
      </div>
    </Modal>
  );
};

export default PreviousYearModal;

export function showPreviousYearConfirmation(props: Props) {
  showModal(<PreviousYearModal {...props} />);
}
