import moment from 'moment';
import { ValuesType } from 'utility-types';

const userAgent = navigator.userAgent.toLowerCase();
const isSafari = userAgent.includes('safari') && !userAgent.includes('chrome');

export const formatCurrencyAmount = (
  amount: number,
  options?: Intl.NumberFormatOptions & { currency?: string },
) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: options?.currency ?? 'USD',
    // 'narrowSymbol' displays $ instead of US$ but safari doesn't support it as of 2021-08-16
    // https://caniuse.com/?search=currencyDisplay
    currencyDisplay: isSafari ? 'symbol' : 'narrowSymbol',
    minimumFractionDigits: 2,
    ...options,
  });

  return formatter.format(amount);
};

export const formatCents = (amount: number, options?: Intl.NumberFormatOptions) =>
  formatCurrencyAmount(amount / 100, {
    ...(amount % 100 === 0
      ? {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      : {
          minimumFractionDigits: 2,
        }),
    ...options,
  });

export const formatDate = (epoch: number) => {
  const date = new Date(epoch * 1000);

  return new Intl.DateTimeFormat().format(date);
};

const timeUnits = ['years', 'months', 'weeks', 'days', 'hours', 'minutes'] as const;
export type TimeUnitsObject = Record<ValuesType<typeof timeUnits>, number>;

/*
 * Returns e.g. { years: 1, months: 0, weeks: 12, days: 3, hours: 10}
 */
export const getTimeDifference = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  return timeUnits.reduce((acc, unit) => {
    const remainingTime = startMoment.clone().add(acc);
    const remainingUnitAmount = endMoment.diff(remainingTime, unit);
    return {
      ...acc,
      [unit]: remainingUnitAmount,
    };
  }, {} as TimeUnitsObject);
};

/*
 * Formats a time units object into a human-readable string
 */
export const formatDuration = (timeUnitsObject: Partial<TimeUnitsObject>) => {
  return timeUnits
    .reduce((phrases, unit) => {
      const remaining = timeUnitsObject[unit];
      if (!remaining) return phrases;

      const formattedUnit = remaining > 1 ? unit : unit.substr(0, unit.length - 1); // if === 1, turn e.g. 'hours' into 'hour'
      return [...phrases, `${remaining} ${formattedUnit}`];
    }, [] as string[])
    .join(', ');
};
