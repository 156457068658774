import React from 'react';
import classNames from 'classnames';

import { getTokenData } from '../../lib/constants';
import { textLinkVariantClassnames } from '../TextLink';

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  isTxn: boolean;
  credentialType?: string | null;
  exchangeName?: string;
  hash: string;
}

export default function ChainExplorerLink({ isTxn, credentialType, exchangeName, hash, ...rest }: Props) {
  const { buildAddressUrl, buildTxnUrl } = getTokenData(credentialType);
  const url = isTxn ? buildTxnUrl(hash, exchangeName) : buildAddressUrl(hash);

  const className = rest.className ?? classNames(textLinkVariantClassnames.primary);
  return <a href={url} target="_blank" rel="noreferrer" {...{ className, ...rest }} />;
}
