import React from 'react';
import { CostBasisProps } from './types';

function CostBasisTooltipContent({ displayValue, onClick }: CostBasisProps) {
  return (
    <p>
      When your cost basis is {displayValue}, it could mean there is an error in your data. Fix the problem by
      using our{' '}
      <span className="cursor-pointer underline" onClick={onClick}>
        Cost Basis Wizard
      </span>
      .
    </p>
  );
}

export default React.memo(CostBasisTooltipContent);
