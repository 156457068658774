import classNames from 'classnames';
import React from 'react';
import { useUserContext } from '../../contexts';
import Card from '../Card';
import PortalLayout from '../PortalLayout';
import Text from '../Text';
import TextLink from '../TextLink';

function CheckoutSuccessful() {
  const { isLoggedIn } = useUserContext();

  return (
    <PortalLayout className="flex flex-col items-center">
      <Card className="mt-12 w-152">
        <div className="flex flex-col items-center p-16">
          <Text variant="success">
            <span className="material-icons text-5xl">check_circle</span>
          </Text>
          <p className={classNames(`text-3xl text-center my-8`, isLoggedIn ? 'w-112' : 'w-72')}>
            {isLoggedIn ? 'Thank you for your purchase' : 'Please check your email to get started'}
          </p>
          <Text variant="muted">
            <p className="text-lg">
              {isLoggedIn
                ? 'We have sent you an email with an activation link'
                : 'Thank you for your purchase'}
            </p>
          </Text>
          {isLoggedIn && (
            <TextLink to="/home" className="mt-16">
              Open Tax Dashboard
            </TextLink>
          )}
        </div>
      </Card>
    </PortalLayout>
  );
}

export default React.memo(CheckoutSuccessful);
