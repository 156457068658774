import React from 'react';
import { VersionsQuery } from '../graphql/types';

export interface VersionsData {
  versions?: VersionsQuery['versions'] | undefined;
  loading?: boolean;
  poll?: () => void;
  isRestoringOrRefetching: boolean;
  refetch?: () => void;
}

export const VersionsContext = React.createContext<VersionsData>({ isRestoringOrRefetching: false });
VersionsContext.displayName = 'VersionsContext';

export const useVersionsContext = () => React.useContext(VersionsContext);
