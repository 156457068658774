import type { Identifier, XYCoord } from 'dnd-core';
import moment from 'moment';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { formatCurrencyAmount } from '../../../lib/formatters';
import { formatNumber } from '../../NumberFormat';
import Text from '../../Text';
import Tooltip from '../../Tooltip';
import { actions } from '../actions';
import { Component } from './types';

type Props = {
  component: Component;
  index: number;
  currency: string;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  removeComponent: (id: string) => void;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const SPEC_ID_COMPONENT = 'spec-id-component';

function SpecIdComponent({ component, index, moveCard, removeComponent, currency }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const {
    componentId: id,
    componentQuantity,
    fullAvailableQuantity,
    buyPrice,
    quantityUsedInOtherSpecs,
    componentAcquisitionDate,
  } = component;
  const enabled = componentQuantity > 0;

  // this code (including comments) is taken more or less verbatim from react-dnd's example at
  // https://react-dnd.github.io/react-dnd/examples/sortable/simple
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: SPEC_ID_COMPONENT,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here! Generally it's better to avoid mutations,
      // but it's good here for the sake of performance to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: SPEC_ID_COMPONENT },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  const opacity = isDragging ? 0 : enabled ? 1 : 0.5;

  drag(ref);
  drop(ref);

  const componentHasBeenUsedInPart = componentQuantity < fullAvailableQuantity;

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className="active:cursor-move py-2 px-2 border-b text-sm flex justify-between items-center"
    >
      <div className="flex items-center">
        <Text variant="muted" className="flex items-center">
          <span className="material-icons mr-2">drag_handle</span>
        </Text>
        <div className="flex">
          <span className="flex">
            {formatNumber(componentQuantity)}
            {componentQuantity < fullAvailableQuantity && ` of ${formatNumber(fullAvailableQuantity)}`}
            {' '}
            <span className="inline-block max-w-[4rem] truncate cursor-default" title={currency}>
              {currency}
            </span>
          </span>
          {componentHasBeenUsedInPart && (
            <Tooltip icon="question" margin="mx-1">
              This transaction contributes only a portion of its quantity to this spec.
              {quantityUsedInOtherSpecs > 0 && (
                <>
                  <br />
                  {formatNumber(quantityUsedInOtherSpecs)} of its {formatNumber(fullAvailableQuantity)}{' '}
                  {currency} are used in other specs.
                </>
              )}
            </Tooltip>
          )}
        </div>
        <Text variant="muted">
          {' '}acquired {moment(componentAcquisitionDate).format('MM/DD/YY')}
        </Text>
      </div>
      <div className="flex items-center">
        <span>
          Cost Basis:{' '}
          {buyPrice && componentQuantity
            ? formatCurrencyAmount(parseFloat(buyPrice) * componentQuantity)
            : 'N/A'}
        </span>
        <button
          onClick={() => {
            removeComponent(id);
            actions.unmarkAsLinkedSpecId([id]);
          }}
          className="p-none leading-none ml-2"
        >
          <Text variant="muted" className="material-icons text-[16px] leading-none">
            close
          </Text>
        </button>
      </div>
    </div>
  );
}

export default React.memo(SpecIdComponent);
