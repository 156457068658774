import { ReactiveVar } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { TxnFragment } from '../../graphql/types';
import { FiltersState } from '../AllTransactions/types';
import { showModal } from '../Modal';
import Panel, { PanelProps } from '../Panel';
import EditTxnForm from './Form';

interface EditTxnPanelProps extends PanelProps {
  txns: TxnFragment[];
  onSuccess?: () => void;
  onDelete?: (ids: string[]) => void;
  isEditingMultiPageBatch?: boolean;
  multiPageBatchCount?: number;
  filtersVar?: ReactiveVar<FiltersState>; // passed with multi-page batch edits
}

function EditTxnPanel({
  onClose: onCloseOriginal,
  txns,
  subtitle,
  onSuccess,
  onDelete,
  isEditingMultiPageBatch,
  multiPageBatchCount,
  filtersVar,
}: EditTxnPanelProps) {
  const { length } = txns;
  const title = isEditingMultiPageBatch
    ? `Batch editing ${multiPageBatchCount} transactions`
    : length > 1
    ? `Edit ${length} Transactions`
    : 'Edit Transaction';
  const showDeleteButton = !!onDelete;
  const [isUpdating, setIsUpdating] = useState(false);
  const onClose = useCallback(() => {
    if (isUpdating) return;
    onCloseOriginal?.();
  }, [isUpdating, onCloseOriginal]);

  return (
    <Panel onClose={onClose} title={title} subtitle={subtitle} padded={false} titlePadding="px-8 py-4">
      <EditTxnForm
        txns={txns}
        multiPageBatch={isEditingMultiPageBatch}
        filtersVar={filtersVar}
        onSuccess={onSuccess}
        onDelete={useCallback(async () => {
          await onDelete?.(txns.map(({ id }) => id));
          onClose?.();
        }, [onDelete, onClose, txns])}
        showDeleteButton={showDeleteButton}
        isUpdating={isUpdating}
        setIsUpdating={setIsUpdating}
      />
    </Panel>
  );
}

export default React.memo(EditTxnPanel);

export function showEditTxnPanel(props: EditTxnPanelProps) {
  showModal(<EditTxnPanel {...props} />);
}
