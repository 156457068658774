import { InvoiceFragmentFragment } from '../graphql/types';

interface TrackInvoicePaymentArgs {
  invoice: InvoiceFragmentFragment;
}

export const trackInvoicePaymentSuccessful = ({ invoice }: TrackInvoicePaymentArgs) => {
  const { amountRemaining, userId } = invoice;
  const priceInUSD = amountRemaining / 100;

  window.analytics?.track?.('Order Completed', {
    order_id: invoice.id,
    total: priceInUSD,
    revenue: priceInUSD,
    products: [
      {
        is_upgrade: false,
        name: 'Custom Invoice',
        price: priceInUSD,
        product_id: 'CUSTOM_INVOICE',
        quantity: 1,
        sku: null,
        url: null,
        years: null,
      },
    ],
    token_tax_id: userId,
    currency: 'USD',
    status: 'completed',
  });
};

interface AccountDetailsArgs {
  hijacked_account_email: string;
  hijacked_account_id: number;
  is_hijacked: boolean;
}

interface TrackTxnDeleteArgs extends AccountDetailsArgs {
  txn_delete_quantity: number;
  txn_ids: string;
}
export const trackTxnDelete = (args: TrackTxnDeleteArgs) => {
  window.analytics?.track?.('Transaction Deleted', {
    ...args,
  });
};

interface TrackBatchTxnDeleteArgs extends AccountDetailsArgs {
  filter_query: string;
}
export const trackBatchTxnDelete = (args: TrackBatchTxnDeleteArgs) => {
  window.analytics?.track?.('Transaction Batch Deleted', {
    ...args,
  });
};

interface TrackIntegrationAddedArgs extends AccountDetailsArgs {
  type: 'CSV' | 'API' | 'OAUTH';
  name?: string;
}
export const trackIntegrationAdded = (args: TrackIntegrationAddedArgs) => {
  window.analytics?.track?.('Integration Added', {
    ...args,
  });
};

interface TrackIntegrationEditedArgs extends AccountDetailsArgs {
  credential_type: string;
}
export const trackIntegrationEdited = (args: TrackIntegrationEditedArgs) => {
  window.analytics.track('Integration Edited', {
    ...args,
  });
};

export const trackIntegrationDeleted = (args: TrackIntegrationEditedArgs) => {
  window.analytics.track('Integration Deleted', {
    ...args,
  });
};

export const trackTaxDashboardRecalculated = (args: AccountDetailsArgs) => {
  window.analytics.track('TaxDashboard Recalculated', {
    ...args,
  });
};

interface TrackIntegrationResyncedArgs extends AccountDetailsArgs {
  hard_resync: boolean;
  credential_ids: string;
}

export const trackIntegrationResynced = (args: TrackIntegrationResyncedArgs) => {
  window.analytics.track('Integration Resynced', {
    ...args,
  });
};

interface TrackTaxYearLocking extends AccountDetailsArgs {
  year: number;
}

export const trackTaxYearLocked = (args: TrackTaxYearLocking) => {
  window.analytics.track('TaxYear Locked', {
    ...args,
  });
};

export const trackTaxYearUnlocked = (args: TrackTaxYearLocking) => {
  window.analytics.track('TaxYear Unlocked', {
    ...args,
  });
};

export interface TrackVipUpsellBannerArgs {
  experimentVersion: string;
  experimentGroup: string;
  url?: string;
}

export const trackVipUpsellBannerLoaded = (args: TrackVipUpsellBannerArgs) => {
  window.analytics.track('VipUpsellBanner Loaded', {
    ...args,
  });
};

export const trackVipUpsellBannerDismissed = (args: TrackVipUpsellBannerArgs) => {
  window.analytics.track('VipUpsellBanner Dismissed', {
    ...args,
  });
};

export const trackVipUpsellBannerClicked = (args: TrackVipUpsellBannerArgs) => {
  window.analytics.track('VipUpsellBanner Clicked', {
    ...args,
  });
};
