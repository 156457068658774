import React, { ReactNode, useCallback, useContext, useState } from 'react';

import { RecalcContext } from '../../../contexts';
import { getApolloErrorMessages } from '../../../lib/errors';
import pluralize from '../../../lib/pluralize';
import Button from '../../Button';
import Checkbox from '../../Input/Checkbox';
import Modal, { ModalProps, showModal } from '../../Modal';
import Spinner from '../../Spinner';
import { ErrorToast } from '../../Toast';
import { doNotAskAgainToConfirmTxnDeletion } from '../helpers';
import { useDeleteTxns } from '../mutations';

interface DeleteModalProps extends ModalProps {
  txnIds: string[];
  onTxnDeleted: (txnIds: string[]) => void;
}

function DeleteModal({ onTxnDeleted, onClose, txnIds }: DeleteModalProps) {
  const { refetchNeedsRecalc } = useContext(RecalcContext);
  const [error, setError] = useState<ReactNode>(null);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const [deleteTxns, { loading }] = useDeleteTxns({
    onSuccess: () => {
      refetchNeedsRecalc();
      onTxnDeleted(txnIds);
    },
  });

  const handleSubmit = useCallback(async () => {
    try {
      await deleteTxns({
        variables: { ids: txnIds },
      });
      if (doNotShowAgain) {
        doNotAskAgainToConfirmTxnDeletion();
      }
      setTimeout(onClose as () => void);
    } catch (error) {
      setError(
        <>
          The transaction could not be deleted.{' '}
          {getApolloErrorMessages(error) || (error as Error)?.message || String(error)}
        </>,
      );
    }
  }, [deleteTxns, doNotShowAgain, onClose, txnIds]);

  const { length } = txnIds;
  const isMany = length > 1;
  const message = `Are you sure you want to delete ${
    isMany ? 'these transactions' : 'this transaction'
  }? If ${length > 1 ? 'they are' : 'it is'} from an API or wallet connection, ${
    isMany ? 'they' : 'it'
  } will come back when you resync.`;

  return (
    <Modal title={`Delete ${pluralize(length, 'transaction')}?`} subtitle={message} onClose={onClose}>
      {error && <ErrorToast>{error}</ErrorToast>}
      <div className="mb-6 flex items-center">
        <Checkbox
          className="text-muted"
          checked={doNotShowAgain}
          onChange={setDoNotShowAgain}
          label="Do not ask me again"
          labelClassName="text-sm text-light-muted dark:text-dark-muted"
        />
      </div>
      <Button disabled={loading} onClick={handleSubmit} type="submit">
        {loading && <Spinner className="mr-2" size="sm" />}Delete
      </Button>
    </Modal>
  );
}

export default React.memo(DeleteModal);

export function showDeleteTransactionModal(props: DeleteModalProps) {
  showModal(<DeleteModal {...props} />);
}
