import { chain, partition } from 'lodash';
import { applyNetMergeWherePossible } from '../store/netMerge';
import {
  Category,
  TREATMENTS,
  TransferReceived,
  TransferSent,
  TransferType,
  UnreconciledTransaction,
} from '../store/types';
import { Side, SideOrBoth } from './types';

export const borderColor = 'border-light-control dark:border-dark-control';

export const getCategoryForTreatment = (treatment: TREATMENTS, side: SideOrBoth): Category | null => {
  switch (treatment) {
    case TREATMENTS.complexTrade:
    case TREATMENTS.simpleSwap:
      return 'trade';
    case TREATMENTS.weightedLpEntrance:
    case TREATMENTS.lpEntrance:
      return 'lpEntrance';
    case TREATMENTS.weightedLpExit:
    case TREATMENTS.lpExit:
      return 'lpExit';
    case TREATMENTS.zeroDollarTrade:
      return 'freeMint';
    case TREATMENTS.ignore: {
      if (side === 'sent') return 'transferOut';
      if (side === 'received') return 'transferIn';
      return DEFAULT_CATEGORY;
    }
    case TREATMENTS.repayWithExclusion:
    case TREATMENTS.repayWithIncome:
    case TREATMENTS.simpleRepay:
      return 'repay';
    case TREATMENTS.simpleSpend:
      return 'spend';
    case TREATMENTS.bridge: {
      if (side === 'sent') return 'bridgeOut';
      if (side === 'received') return 'bridgeIn';
      return DEFAULT_CATEGORY;
    }
    case TREATMENTS.borrowWithExclusion:
    case TREATMENTS.simpleBorrow:
      return 'borrow';
    case TREATMENTS.spam:
      return 'spam';
    case TREATMENTS.other:
      return 'other';
    case TREATMENTS.simpleIncome:
    case TREATMENTS.incomeWithWithdrawal:
    case TREATMENTS.incomeWithExclusion:
    case TREATMENTS.swapWithIncome:
      return 'income';
    case TREATMENTS.wrap:
    case TREATMENTS.unknown:
      return null;
  }
};

const { received, sent, both } = SideOrBoth;
// each of these entries matches a button in the "unreconciled txns" UI
export const CATEGORY_DATA: Record<
  Category,
  {
    label: string;
    sentType?: TransferSent;
    receivedType?: TransferReceived;
    applicableToTxnsHaving: SideOrBoth[];
    shortcut?: string;
    isDisabled?: ({ txn }: { txn: UnreconciledTransaction }) => boolean;
    disabledMessage?: string;
  }
> = {
  trade: {
    label: 'Trade',
    sentType: 'sold',
    receivedType: 'bought',
    applicableToTxnsHaving: [both],
    shortcut: 't',
    isDisabled: ({ txn }) => {
      const txns = txn.txns;
      if (!txns) return false; // should not be possible; just a type safety check

      // If we still have multiple currencies on both sides after net merging, it
      // does not make sense to offer "Trade" as a category recommendation to users.
      const netMergeResult = applyNetMergeWherePossible(txns);
      const [withdrawals, deposits] = partition(netMergeResult, ({ txnType }) => txnType === 'withdrawal');

      return withdrawals.length > 1 && deposits.length > 1;
    },
    disabledMessage:
      'This transaction cannot be automatically merged because there are multiple currencies on both sides. Select "Other" to manually create trades.',
  },
  lpEntrance: {
    label: 'LP Entrance',
    sentType: 'lpContribution',
    receivedType: 'lpReceived',
    applicableToTxnsHaving: [both],
    shortcut: 'n',
  },
  lpExit: {
    label: 'LP Exit',
    sentType: 'lpWithdrawn',
    receivedType: 'lpDistribution',
    applicableToTxnsHaving: [both],
    shortcut: 'x',
  },
  mint: {
    label: 'Mint',
    sentType: 'paid',
    receivedType: 'minted',
    applicableToTxnsHaving: [both],
  },
  transferOut: {
    label: 'Transfer Out',
    sentType: 'sent',
    applicableToTxnsHaving: [sent],
    shortcut: 'a',
  },
  repay: {
    label: 'Repay',
    sentType: 'repaid',
    applicableToTxnsHaving: [sent],
    shortcut: 'r',
  },
  spend: {
    label: 'Spend',
    sentType: 'spent',
    applicableToTxnsHaving: [sent],
    shortcut: 's',
  },
  lost: {
    label: 'Lost',
    sentType: 'lost',
    applicableToTxnsHaving: [sent],
  },
  bridgeOut: {
    label: 'Bridge Out',
    sentType: 'bridgedOut',
    applicableToTxnsHaving: [sent],
    shortcut: 'o',
  },
  transferIn: {
    label: 'Transfer In',
    receivedType: 'received',
    applicableToTxnsHaving: [received],
    shortcut: 'd',
  },
  borrow: {
    label: 'Borrow',
    receivedType: 'borrowed',
    applicableToTxnsHaving: [received],
    shortcut: 'b',
  },
  income: {
    label: 'Income',
    receivedType: 'earned',
    applicableToTxnsHaving: [received],
    shortcut: 'i',
  },
  bridgeIn: {
    label: 'Bridge In',
    receivedType: 'bridgedIn',
    applicableToTxnsHaving: [received],
    shortcut: 'g',
  },
  freeMint: {
    label: 'Free Mint',
    receivedType: 'minted',
    applicableToTxnsHaving: [received],
    shortcut: 'f',
  },
  spam: {
    label: 'Spam',
    receivedType: 'spam',
    applicableToTxnsHaving: [received, both],
    shortcut: 'p',
  },
  other: {
    label: 'Other',
    applicableToTxnsHaving: [received, sent, both],
    shortcut: 'h',
  },
};

Object.values(CATEGORY_DATA).forEach((categoryData) => {
  if (categoryData.shortcut && categoryData.shortcut.length > 1) {
    throw new Error(`Category shortcut must be a single character, got: ${categoryData.shortcut}`);
  }
});

export const getFriendlyCategoryLabel = (category: Category): string => CATEGORY_DATA[category]?.label;

export const TREATMENT_DATA: Record<TREATMENTS, { sentenceLabel: string } | null> = {
  [TREATMENTS.complexTrade]: { sentenceLabel: 'a complex trade' },
  [TREATMENTS.simpleSwap]: { sentenceLabel: 'a trade' },
  [TREATMENTS.lpEntrance]: { sentenceLabel: 'an LP entrance' },
  [TREATMENTS.lpExit]: { sentenceLabel: 'an LP exit' },
  [TREATMENTS.zeroDollarTrade]: { sentenceLabel: 'a free mint' },
  [TREATMENTS.ignore]: null,
  [TREATMENTS.simpleRepay]: { sentenceLabel: 'a repay' },
  [TREATMENTS.simpleSpend]: { sentenceLabel: 'a spend' },
  [TREATMENTS.bridge]: { sentenceLabel: 'a bridge' },
  [TREATMENTS.simpleBorrow]: { sentenceLabel: 'a borrow' },
  [TREATMENTS.spam]: { sentenceLabel: 'spam' },
  [TREATMENTS.other]: null,
  [TREATMENTS.simpleIncome]: { sentenceLabel: 'income' },
  [TREATMENTS.incomeWithWithdrawal]: { sentenceLabel: 'income' },
  [TREATMENTS.incomeWithExclusion]: { sentenceLabel: 'sometimes containing income' },
  [TREATMENTS.swapWithIncome]: { sentenceLabel: 'a swap with income' },
  [TREATMENTS.unknown]: null,
  [TREATMENTS.borrowWithExclusion]: { sentenceLabel: 'a borrow with exclusion' },
  [TREATMENTS.repayWithExclusion]: { sentenceLabel: 'a repay with exclusion' },
  [TREATMENTS.repayWithIncome]: { sentenceLabel: 'a repay with income' },
  [TREATMENTS.wrap]: { sentenceLabel: 'a wrap' },
  [TREATMENTS.weightedLpEntrance]: { sentenceLabel: 'a weighted LP Entrance' },
  [TREATMENTS.weightedLpExit]: { sentenceLabel: 'a weighted LP Exit' },
};

export const ADMIN_STRING = 'TokenTax Pros have identified this transaction as';
export const AI_STRING = 'TokenTax AI thinks this transaction may be';

type TransferTypeData = {
  side: Side;
  isOneSided: boolean;
};

export const transferTypeToSideMap = Object.fromEntries(
  Object.values(CATEGORY_DATA).flatMap((value): Array<[TransferType, TransferTypeData]> => {
    const { sentType, receivedType } = value;
    const isOneSided = !(sentType && receivedType);
    return [
      [sentType, { side: sent, isOneSided }],
      [receivedType, { side: received, isOneSided }],
    ].filter(([type]) => Boolean(type)) as Array<[TransferType, TransferTypeData]>;
  }),
) as Record<TransferType, TransferTypeData>;

const singleSidedTransferTypesToSidePairs = Object.values(CATEGORY_DATA).flatMap(
  ({ sentType, receivedType }) => {
    if (sentType && receivedType) return [];
    if (!(sentType || receivedType)) return [];
    return [[(sentType || receivedType)!, sentType ? sent : received]] as const;
  },
);

export const singleSidedTransferTypesToSideMap = Object.fromEntries(singleSidedTransferTypesToSidePairs);

export const singleSidedTransferTypesMap = chain(CATEGORY_DATA)
  .values()
  .flatMap((data) => {
    const { sentType, receivedType } = data;
    if (sentType && receivedType) return [];
    if (!(sentType || receivedType)) return [];
    return [[sentType || receivedType, data]];
  })
  .fromPairs()
  .value() as Record<TransferType, TransferTypeData>;

export const DEFAULT_CATEGORY = 'other';

export const TWO_SIDED_CATEGORIES = Object.entries(CATEGORY_DATA)
  .filter(([_key, value]) => value.sentType && value.receivedType)
  .map(([key]) => key as Category);
