import Big from 'big.js';
import { produce } from 'immer';
import { orderBy, uniq } from 'lodash';
import { SetRequired } from 'type-fest';
import { create } from 'zustand';
import { UnreconciledTransactionsQuery } from '../../../../graphql/types';
import history from '../../../../lib/history';
import {
  trackTokensLoadingTime,
  trackUnreconciledDraftTransferEdited,
  trackUnreconciledTxReconciled,
  trackUnreconciledTxTypeSelected,
} from '../../analytics';
import { transferTypeToSideMap } from '../Wizard/constants';
import { otherSide } from '../Wizard/helpers';
import { resolveUnreconciledTxns } from '../Wizard/queries';
import { Side, SideOrBoth } from '../Wizard/types';
import { getUnreconciledTransactionUrl } from '../constants';
import { getDraftSide } from '../helpers';
import { buildUnreconciledTransaction } from './buildUnreconciledTransaction';
import { getInitialDraftsForTxn } from './getInitialDraftsForTxn';
import { getUnreconciledTransactionData, getUnreconciledTransactionsData } from './queries';
import { TREATMENT_BUCKET_ORDER } from './treatmentBuckets';
import {
  TreatmentBucket,
  type AddTransferProps,
  type BatchSetCategoryProps,
  type Category,
  type EditTransferProps,
  type LoadTxnDataProps,
  type MergeDraftsProps,
  type RemoveDraftProps,
  type RemoveTransferProps,
  type ResetCurrentTransactionProps,
  type ResolveUnreconciledTxnsProps,
  type SetCategoryProps,
  type SetTransferTypeProps,
  type SetTxnDataProps,
  type SplitTradeProps,
  type Store,
  type Transfer,
  type UnreconciledTransaction,
} from './types';
export * from './types';

const getCurrentTransactionId = () => {
  const params = new URLSearchParams(history.location.search);
  return params.get('id');
};

const initialState = {
  loaded: false,
  transactions: [],
};

const getCredentialType = (txn: UnreconciledTransaction) => {
  const credentialTypes = txn.credentials.map((c) => c.type);
  const uniqueCredentialTypes = uniq(credentialTypes);
  if (uniqueCredentialTypes.length > 1) {
    throw new Error(`Transaction ${txn.id} has multiple credential types`);
  }
  return uniqueCredentialTypes[0];
};

export const useStore = create<Store>((set, getState) => {
  history.listen(() => {
    set({ currentTransactionId: getCurrentTransactionId() });
  });

  return {
    ...initialState,
    currentTransactionId: getCurrentTransactionId(),
    reset: () => set(initialState),
    getCurrentTransaction() {
      const { currentTransactionId } = getState();
      // it would be tempting to have this be an object instead of a function but the source of this state is the `transactions` property
      return getState().transactions.find((t) => t.id === currentTransactionId) ?? null;
    },

    skipToBucket: (bucket: TreatmentBucket) => {
      const firstTransactionForBucket = getState().transactions.find((t) => t.bucket === bucket);
      if (!firstTransactionForBucket) return;
      history.push(getUnreconciledTransactionUrl(firstTransactionForBucket.id));
    },

    getNextUnreconciledTransactionIds: (howMany: number) => {
      const { getCurrentTransaction, transactions } = getState();
      const ids: string[] = [];
      const currentTransaction = getCurrentTransaction(); // compute it once, not for every iteration
      const currentTransactionIndex = transactions.findIndex((t) => t.id === currentTransaction?.id);
      for (let i = currentTransactionIndex + 1; i < transactions.length; i++) {
        const { resolved, id, isReconciling } = transactions[i];
        if (!resolved && !isReconciling) {
          ids.push(id);
          if (ids.length === howMany) {
            break;
          }
        }
      }
      return ids;
    },
    getPrevUnreconciledTransactionIds: (howMany: number) => {
      const { getCurrentTransaction, transactions } = getState();
      const ids: string[] = [];
      const currentTransaction = getCurrentTransaction(); // compute it once, not for every iteration
      const currentTransactionIndex = transactions.findIndex((t) => t.id === currentTransaction?.id);
      for (let i = currentTransactionIndex - 1; i >= 0; i--) {
        const { resolved, id, isReconciling } = transactions[i];
        if (!resolved && !isReconciling) {
          ids.push(id);
          if (ids.length === howMany) {
            break;
          }
        }
      }
      return ids;
    },
    /**
     * Shows the prev unreconciled transaction in the list after the current one
     */
    showPrevUnreconciledTransaction: () => {
      const [prevId] = getState().getPrevUnreconciledTransactionIds(1);
      if (!prevId) return history.push('/recon/transactions/unreconciled');
      history.push(getUnreconciledTransactionUrl(prevId));
    },
    /**
     * Shows the next unreconciled transaction in the list after the current one
     */
    showNextUnreconciledTransaction: () => {
      const [nextId] = getState().getNextUnreconciledTransactionIds(1);
      if (!nextId) return history.push('/recon/transactions/unreconciled');
      history.push(getUnreconciledTransactionUrl(nextId));
    },
    loadUnreconciledTransactions: (
      unreconciledTransactions: UnreconciledTransactionsQuery['unreconciledTransactions']['edges'],
    ) => {
      const builtTransactions = unreconciledTransactions.map((unreconciledTransaction) =>
        buildUnreconciledTransaction({ unreconciledTransaction }),
      );

      const sortedTransactions = orderBy(builtTransactions, (txn) =>
        TREATMENT_BUCKET_ORDER.indexOf(txn.bucket),
      );

      set({ transactions: sortedTransactions, loaded: true });
    },

    loadTxnData: async ({ unreconciledTransactionId, lpTokenTickersByAddress }: LoadTxnDataProps) => {
      const { setTxnData } = getState();
      const txn = getState().transactions.find((t) => t.id === unreconciledTransactionId);
      if (!txn) throw new Error('Invalid transaction id');
      const { blocksvcHash: txnHash } = txn;
      if (txn.drafts && txn.txns) {
        // no need to load data and in fact
        // --> we must not do it <-- as we can risk getting stale data from the apollo cache
        // for instance, if a txn is loaded as reconciled but then is reverted
        // (an action which overwrites the txns array)
        // alternatively, separate getTxnData into two functions
        // one which loads txns and resolution (not cached)
        // and one which loads transfers (cached)
        return;
      }

      const credentialType = getCredentialType(txn);

      const timeStarted = Date.now();
      const unreconciledTxnData = await getUnreconciledTransactionData({ txnHash, credentialType });
      trackTokensLoadingTime(Date.now() - timeStarted);

      await setTxnData({ unreconciledTransactionId, unreconciledTxnData, lpTokenTickersByAddress });
    },

    setTxnData: async ({
      unreconciledTransactionId,
      unreconciledTxnData,
      lpTokenTickersByAddress,
    }: SetTxnDataProps) => {
      const txn = getState().transactions.find((t) => t.id === unreconciledTransactionId);
      if (!txn) throw new Error('Invalid transaction id');
      const { category } = txn;
      const { txns } = unreconciledTxnData;
      const feeQuantityTotal = txns.reduce((acc, { feeQuantity }) => acc.plus(feeQuantity ?? 0), Big(0));
      const firstFeeTxn = txns.find((txn) => txn.feeCurrency && txn.feeQuantity !== undefined);

      const drafts = category
        ? getInitialDraftsForTxn({
            category,
            txn: { ...txn, txns },
            lpTokenTickersByAddress,
          })
        : undefined;

      set(
        produce((state: Store) => {
          const txn = state.transactions.find((t) => t.id === unreconciledTransactionId);
          if (!txn) throw new Error('Invalid transaction id');
          if (drafts) txn.drafts = drafts as (typeof txn)['drafts'];
          txn.txns = txns;
          txn.fee = firstFeeTxn
            ? {
                quantity: feeQuantityTotal.toFixed(),
                currency: firstFeeTxn.feeCurrency!,
                tokenId: firstFeeTxn.feeTokenId ?? null,
                price: firstFeeTxn.feePrice,
              }
            : undefined;
        }),
      );
    },

    batchSetCategory: async ({ category, ids, lpTokenTickersByAddress }: BatchSetCategoryProps) => {
      const { transactions, setTxnData, setCategory } = getState();
      const matchingTxns = transactions.filter((t) => ids.includes(t.id));
      const idsByHash = Object.fromEntries(matchingTxns.map((t) => [t.blocksvcHash, t.id]));
      const credentialTypes = matchingTxns.map(getCredentialType);
      if (uniq(credentialTypes).length > 1) {
        throw new Error('Transactions have different credential types');
      }
      const credentialType = credentialTypes[0];
      const dataForTxns = await getUnreconciledTransactionsData({
        txnHashes: Object.keys(idsByHash),
        credentialType,
      });

      for (const { txnHash, txns, ecosystem } of dataForTxns) {
        const id = idsByHash[txnHash];
        setTxnData({
          unreconciledTransactionId: id,
          unreconciledTxnData: { txns, ecosystem },
          lpTokenTickersByAddress,
        });
        setCategory({ category, id, lpTokenTickersByAddress });
      }
    },

    setCategory: async ({ category, id, lpTokenTickersByAddress }: SetCategoryProps) => {
      const index = getState().transactions.findIndex((t) => t.id === id);
      if (index === -1) throw new Error('Invalid transaction id');

      const { transactions, loadTxnData } = getState();
      const transaction = transactions[index];
      let { txns } = transaction;
      if (!txns) {
        // this transaction hasn't been loaded yet
        await loadTxnData({
          unreconciledTransactionId: transactions[index].id,
          lpTokenTickersByAddress,
        });
        txns = getState().transactions[index].txns!;
      }

      const txn = getState().transactions[index] as SetRequired<UnreconciledTransaction, 'txns'>;
      set(
        produce((state: Store) => {
          if (!txns) throw new Error('No txns data available');
          const { transactions } = state;
          transactions[index].category = category;
          transactions[index].drafts = getInitialDraftsForTxn({ category, txn, lpTokenTickersByAddress });
          trackUnreconciledTxTypeSelected(category);
        }),
      );
    },

    resolveUnreconciledTxns: async ({ transactions }: ResolveUnreconciledTxnsProps) => {
      const allIds = new Set(transactions.map(({ id }) => id));
      set(
        produce((state: Store) => {
          for (const transaction of state.transactions) {
            if (allIds.has(transaction.id)) {
              transaction.isReconciling = true;
            }
          }
        }),
      );

      try {
        const { data } = await resolveUnreconciledTxns({ transactions });
        if (!data) throw new Error('Invalid resolveUnreconciledTransactions response');
        const { errors, resolutions } = data?.resolveUnreconciledTransactions ?? {};

        // 1 - update the transactions with the resolutions
        for (const resolution of resolutions) {
          const category = resolution.category as Category;
          trackUnreconciledTxReconciled(category);
          set(
            produce(({ transactions }: Store) => {
              const index = transactions.findIndex((t) => t.id === resolution.unreconciledTransactionId);
              const transaction = transactions[index];
              if (!transaction.category) {
                // we're applying a resolution to a transaction with no category
                // this is a "similar" transaction to the current one that the user chose to apply the same resolution to
                transaction.category = category;
              }
              transaction.drafts = resolution.drafts;
              transaction.resolutionCategory = category;
              transaction.resolved = true;
              transaction.resolutionId = resolution.id;
              transaction.edited = false;
              transaction.invalidated = false;
            }),
          );
        }

        // 2 - remove txns newly reconciled as spam
        set((store) => ({
          transactions: store.transactions.filter((txn) => txn.resolutionCategory !== 'spam'),
        }));

        const firstTransactionCouldNotBeReconciled = errors.some(
          ({ unreconciledTransactionId }) => unreconciledTransactionId === transactions[0].id,
        );

        if (firstTransactionCouldNotBeReconciled) {
          // the transaction the user was working on could not be reconciled: we need to notify them immediately.
          // if, on the other hand, the errors are for transactions we identified as "similar"
          // (which would have index > 0), we can just ignore the errors.
          // the user will eventually land on these txns in the reconciliation flow.
          throw new Error(`Transaction ${transactions[0].id} could not be reconciled`);
        }
      } finally {
        // whether the update was successful or not, set transactions back to not reconciling
        set(
          produce((state: Store) => {
            for (const transaction of state.transactions) {
              if (allIds.has(transaction.id)) {
                transaction.isReconciling = false;
              }
            }
          }),
        );
      }
    },

    resetCurrentTransaction: async (props: ResetCurrentTransactionProps) => {
      const { category = null, lpTokenTickersByAddress } = props;
      const { getCurrentTransaction, loadTxnData } = getState();
      let txns = props?.txns ?? getCurrentTransaction()!.txns;
      if (!txns) {
        const unreconciledTransactionId = getCurrentTransaction()!.id;
        await loadTxnData({ unreconciledTransactionId, lpTokenTickersByAddress });
        txns = getState().getCurrentTransaction()!.txns!;
      }
      const txn = getState().getCurrentTransaction() as SetRequired<UnreconciledTransaction, 'txns'>;
      set(
        produce(({ transactions, ...nextState }: Store) => {
          if (!txns) throw new Error('No txns data available');
          const transaction = nextState.getCurrentTransaction()!;
          const currentTransactionIndex = transactions.findIndex((t) => t.id === transaction?.id);
          transactions[currentTransactionIndex] = {
            ...transaction,
            category,
            ...(txns ? { txns } : {}),
            edited: false,
            resolved: false,
            invalidated: false,
            resolutionCategory: null,
            resolutionId: null,
            sentTxnsCount: txns!.filter(({ txnType }) => txnType === 'withdrawal').length,
            receivedTxnsCount: txns!.filter(({ txnType }) => txnType === 'deposit').length,
            drafts: category
              ? getInitialDraftsForTxn({
                  category,
                  txn: { ...txn, txns },
                  lpTokenTickersByAddress,
                })
              : undefined,
          };
        }),
      );
    },

    removeDraft: ({ draftIndex }: RemoveDraftProps) => {
      set(
        produce(({ transactions, getCurrentTransaction }: Store) => {
          const currentTransaction = getCurrentTransaction();
          const currentTransactionIndex = transactions.findIndex((t) => t.id === currentTransaction?.id);
          const transaction = transactions[currentTransactionIndex];
          transaction.drafts = transaction.drafts!.filter((_d, index) => index !== draftIndex);
          transaction.edited = true;
        }),
      );
    },

    setTransferType: (props: SetTransferTypeProps) => {
      const { draftIndex, side, transferType, transferIndex } = props;
      set(
        produce(({ transactions, getCurrentTransaction }: Store) => {
          const currentTransaction = getCurrentTransaction();
          const currentTransactionIndex = transactions.findIndex((t) => t.id === currentTransaction?.id);
          const transaction = transactions[currentTransactionIndex];
          const draft = transaction.drafts![draftIndex];
          const transfersGroup = draft[side];
          const { isOneSided } = transferTypeToSideMap[transferType];
          if (!transfersGroup) throw new Error('Invalid side');

          const { sent, received } = draft;
          const sentCount = sent?.txns.length ?? 0;
          const receivedCount = received?.txns.length ?? 0;

          if (sentCount > 0 && receivedCount > 0) {
            // can't set a type on a two-sided draft
            throw new Error('Cannot set a type on a two-sided draft');
          }
          if ((sentCount == 0 || receivedCount == 0) && !isOneSided) {
            // A trade-like type is being chosen but the draft currently has only one side
            // this shouldn't have been possible
            throw new Error('Cannot change to a trade-like type if the draft has only one side');
          }
          // if (transferIndex !== undefined) {
          // a single transfer is changing type
          const transfer = transfersGroup.txns[transferIndex];
          if (!transfer) throw new Error('Invalid transfer index');
          transfer.type = transferType;
          // } else {
          //   // transfers are grouped: change all of them
          //   for (const transfer of transfersGroup.txns) {
          //     if (!transfer) continue;
          //     transfer.type = transferType;
          //   }
          // }

          transaction.edited = true;
          transaction.category = 'other';
        }),
      );
    },

    removeTransfer: (props: RemoveTransferProps) => {
      const { draftIndex, side, transferIndex } = props;

      set(
        produce(({ transactions, getCurrentTransaction }: Store) => {
          const currentTransaction = getCurrentTransaction(); // compute it once, not for every iteration
          const currentTransactionIndex = transactions.findIndex((t) => t.id === currentTransaction?.id);
          const transaction = transactions[currentTransactionIndex];
          const draft = transaction.drafts![draftIndex];
          const transfersGroup = draft[side];
          if (!transfersGroup) throw new Error('Invalid side');
          const { txns } = transfersGroup;
          let newTxns: Array<Transfer | null> = txns.filter((_t, index) => index !== transferIndex);
          const otherSideTxns = draft[otherSide(side)]?.txns ?? [];
          if (newTxns.length === 0) {
            if (otherSideTxns.length > 0 && otherSideTxns[0] !== null) {
              // there are still transactions on the other side: insert a placeholder on this side
              newTxns = [null];
            } else {
              // there are no transactions on the other side: the whole draft can be deleted
              transaction.drafts = transaction.drafts!.filter((_d, index) => index !== draftIndex);
            }
          }
          transfersGroup.txns = newTxns;
          transaction.edited = true;
        }),
      );
    },

    addTransfer: (props: AddTransferProps) => {
      const { draftIndex, sent, received } = props;

      set(
        produce(({ transactions, getCurrentTransaction }: Store) => {
          const currentTransaction = getCurrentTransaction(); // compute it once, not for every iteration
          const currentTransactionIndex = transactions.findIndex((t) => t.id === currentTransaction?.id);
          const transaction = transactions[currentTransactionIndex];
          if (draftIndex === undefined) {
            // we're creating a new draft
            transaction.drafts!.push({
              ...(sent ? { sent: { txns: [sent] } } : {}),
              ...(received ? { received: { txns: [received] } } : {}),
            });
            transaction.category = 'other';
          } else {
            if ((sent && received) || !(sent || received)) {
              throw new Error('Only one transfer can be added to one side at a time');
            }
            const side = sent ? Side.sent : Side.received;
            const draft = transaction.drafts![draftIndex];
            const transfer = (sent || received)!;
            const transfersGroup = draft[side];
            if (!transfersGroup) throw new Error('Invalid side');
            const { txns } = transfersGroup;
            if (txns[0] === null) {
              // remove placeholder
              transfersGroup.txns = [transfer];
            } else {
              txns.push(transfer);
            }
          }
          transaction.edited = true;
        }),
      );
    },

    editTransfer: (props: EditTransferProps) => {
      const { draftIndex, side, transferIndex, quantity, currency, tokenId } = props;

      set(
        produce(({ transactions, getCurrentTransaction }: Store) => {
          const currentTransaction = getCurrentTransaction(); // compute it once, not for every iteration
          const currentTransactionIndex = transactions.findIndex((t) => t.id === currentTransaction?.id);
          const transaction = transactions[currentTransactionIndex];
          const draft = transaction.drafts![draftIndex];
          const transfersGroup = draft[side];
          if (!transfersGroup) throw new Error('Invalid side');
          const { txns } = transfersGroup;
          const transfer = txns[transferIndex];
          if (!transfer) throw new Error('Invalid transfer index');
          trackUnreconciledDraftTransferEdited({
            quantityEdited: transfer.quantity !== quantity,
            symbolEdited: transfer.currency !== currency,
          });
          transfer.quantity = quantity;
          transfer.currency = currency;
          transfer.tokenId = tokenId;
          transaction.edited = true;
        }),
      );
    },

    getSimilarTransactions: (transaction: UnreconciledTransaction) => {
      const { category, edited, contract, methodId } = transaction;
      if (edited) {
        // the transaction has been modified after a category was chosen - we can't confidently find similar transactions
        return [];
      }
      if (!category) {
        throw new Error('Cannot find similar transactions for a transaction without a category');
      }

      if (!['income', 'borrow', 'spend', 'repay', 'trade'].includes(category)) return [];

      return getState().transactions.filter((txn) => {
        const { receivedTxnsCount: receivedLength, sentTxnsCount: sentLength } = txn;

        if (['income', 'borrow'].includes(category) && sentLength > 0) return false;
        if (['spend', 'repay'].includes(category) && receivedLength > 0) return false;
        if (category === 'trade' && !(sentLength && receivedLength)) {
          return false; // txn must have both sides
        }

        return (
          txn.id !== transaction.id &&
          !txn.resolved &&
          txn.methodId === methodId &&
          txn.contract &&
          contract &&
          getCredentialType(txn) === getCredentialType(transaction) &&
          txn.contract.address === contract.address
        );
      });
    },

    mergeDrafts: ({ sourceDraftIndex, targetDraftIndices }: MergeDraftsProps) => {
      set(
        produce(({ transactions, getCurrentTransaction }: Store) => {
          const currentTransaction = getCurrentTransaction(); // compute it once, not for every iteration
          const currentTransactionIndex = transactions.findIndex((t) => t.id === currentTransaction?.id);
          const transaction = transactions[currentTransactionIndex];
          const { drafts } = transaction;
          const sourceDraft = drafts![sourceDraftIndex];
          if (!sourceDraft) throw new Error('Invalid source draft index');

          const sourceSide = getDraftSide(sourceDraft);
          if (sourceSide === SideOrBoth.both) throw new Error('Cannot merge a two-sided draft');

          const targetTransfers: Transfer[] = [];

          for (const targetDraftIndex of targetDraftIndices) {
            const targetDraft = drafts![targetDraftIndex];
            if (!targetDraft) throw new Error('Invalid target draft index');
            const targetSide = getDraftSide(targetDraft);
            if (targetSide === SideOrBoth.both) throw new Error('Cannot merge a two-sided draft');
            if (sourceSide === targetSide) throw new Error('Cannot merge two drafts of the same side');
            for (const txn of targetDraft[targetSide]!.txns) {
              if (!txn) throw new Error('Cannot merge a placeholder txn');
              targetTransfers.push(txn);
            }
          }

          const otherSide = sourceSide === Side.sent ? Side.received : Side.sent;
          const mergedDraft = {
            // merge target drafts into source draft
            ...sourceDraft,
            [otherSide]: { txns: targetTransfers },
          };
          transaction.drafts = transaction.drafts!.flatMap((draft, index) => {
            if (targetDraftIndices.includes(index)) return []; // filter out target drafts
            if (index === sourceDraftIndex) return mergedDraft; // replace source draft with merged draft
            return draft; // leave other drafts unmodified
          });
          transaction.edited = true;
          transaction.category = 'other';
        }),
      );
    },

    splitTrade: ({ draftIndex }: SplitTradeProps) => {
      set(
        produce(({ transactions, getCurrentTransaction }: Store) => {
          const currentTransaction = getCurrentTransaction(); // compute it once, not for every iteration
          const currentTransactionIndex = transactions.findIndex((t) => t.id === currentTransaction?.id);
          const transaction = transactions[currentTransactionIndex];
          const { drafts } = transaction;
          if (!drafts) throw new Error('No drafts available');
          const draft = drafts[draftIndex];
          if (!draft) throw new Error('Invalid draft index');
          const { sent, received } = draft;
          if (!sent || !received) throw new Error('Cannot split a draft that is not a trade');
          const newDrafts = (['sent', 'received'] as const).flatMap((side) => {
            return (
              draft[side]?.txns
                .filter(Boolean) // don't try to split out placeholders
                .map((transfer) => ({
                  [side]: { txns: [{ ...transfer, type: side }] },
                })) ?? []
            );
          });
          transaction.drafts = drafts.flatMap((draft, index) => (index === draftIndex ? newDrafts : [draft]));
          transaction.edited = true;
          transaction.category = 'other';
        }),
      );
    },
  };
});

if (process.env.NODE_ENV === 'development') {
  const { mountStoreDevtool } = require('simple-zustand-devtools'); // eslint-disable-line @typescript-eslint/no-var-requires
  mountStoreDevtool('Store', useStore);
}
