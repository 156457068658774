import { gql, useMutation, useQuery } from '@apollo/client';
import {
  AddUserProfileMutation,
  AddUserProfileMutationVariables,
  GetAccountYearsQuery,
  GetCountriesQuery,
  GetLatestUserProfileQuery,
} from '../../graphql/types';

// Fetch latest questionnaire/add new response
export const ADD_USER_PROFILE = gql`
  mutation AddUserProfile($userProfileInputData: UserProfileInputData!) {
    addUserProfile(userProfileInputData: $userProfileInputData)
  }
`;

export function useAddUserProfile() {
  return useMutation<AddUserProfileMutation, AddUserProfileMutationVariables>(ADD_USER_PROFILE);
}

export const GET_LATEST_USER_PROFILE = gql`
  query GetLatestUserProfile {
    getLatestUserProfile {
      data
      touchedSections
    }
  }
`;

export function useLatestUserProfile() {
  return useQuery<GetLatestUserProfileQuery>(GET_LATEST_USER_PROFILE, {
    fetchPolicy: 'no-cache',
  });
}

// We need country data and tax year data to provide selection options
export const GET_COUNTRIES = gql`
  query GetCountries {
    countries {
      id
      name
      shorthand
      methods
    }
  }
`;

export function useGetCountries() {
  return useQuery<GetCountriesQuery>(GET_COUNTRIES);
}

export const GET_ACCOUNT_YEARS = gql`
  query GetAccountYears {
    user {
      taxDetails {
        taxYear
      }
    }
  }
`;

export function useGetAccountYears() {
  return useQuery<GetAccountYearsQuery>(GET_ACCOUNT_YEARS);
}

// Get user data so we can send it to Trello as a hidden field
export const GET_USER = gql`
  query GetUser {
    user {
      id
      email
    }
  }
`;

export function useGetUser() {
  return useQuery(GET_USER);
}
