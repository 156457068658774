import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../Button';

interface Props {
  onCreate: () => void;
}

function CreateInvoiceHeader({ onCreate }: Props) {
  return (
    <div className="flex w-full border-b">
      <div className="border-r">
        <Link to="/home">
          <div className="p-6 flex self-center cursor-pointer">✕</div>
        </Link>
      </div>
      <div className="flex p-4 justify-between w-full">
        <div className="flex self-center">Create Invoice</div>
        <Button onClick={onCreate}>Review Invoice</Button>
      </div>
    </div>
  );
}

export default React.memo(CreateInvoiceHeader);
