import React, { useCallback, useContext } from 'react';
import { RecalcContext } from '../../../contexts';
import Icon from '../../Icon';

interface Props {
  recalculate: (options?: { allYearOverride?: boolean }) => void;
}

export function AccountRecalcWarningContent({ recalculate }: Props) {
  return (
    <div
      className="rounded bg-black text-white text-lg flex flex-row justify-evenly items-center fixed z-[1000] bottom-[24px] left-[50%] -translate-x-[50%]"
      style={{
        ['--bg-opacity']: 0.8,
        fontSize: '14px',
        padding: '8px',
      }}
    >
      <span className="pl-1 mr-3">
        <Icon type="warning" />
      </span>
      Your account needs to be recalculated{' '}
      <button
        className="ml-4 px-3 py-1 rounded border relative hover:bg-black"
        onClick={useCallback(() => recalculate(), [recalculate])}
        style={{ ['--bg-opacity']: 0.6 }}
      >
        Recalculate
      </button>
    </div>
  );
}

export default function AccountRecalcWarning() {
  const { isRecalculating, needsRecalc, recalculate } = useContext(RecalcContext);

  const shouldShowWarning = Boolean(needsRecalc && !isRecalculating);

  return shouldShowWarning ? <AccountRecalcWarningContent {...{ recalculate }} /> : null;
}
