import cx from 'classnames';
import React from 'react';

export interface CardProps {
  /** content inside cardd */
  children: React.ReactNode;
  /** additional css classes */
  className?: string;
  /** removes x-axis padding when set to true to allow for <hr/> spanning entire card */
  sectioned?: boolean;
  /** optional padding className(s) */
  padding?: string;
  /* optional styles */
  style?: React.CSSProperties;
}

function Card({ children, className, sectioned, padding, style }: CardProps) {
  return (
    <div
      className={cx(
        'bg-light-base dark:bg-dark-base border',
        'rounded',
        padding || (sectioned ? 'py-4' : 'py-4 px-6'),
        className,
      )}
      {...{ style }}
    >
      {children}
    </div>
  );
}

Card.defaultProps = { className: '', sectioned: false };

export default React.memo(Card);
