import classNames from 'classnames';
import React from 'react';
import { CurrencyFilterTypes, TxnCurrencyFilter } from '../../../graphql/types';
import Dropdown, { DropdownHandle } from '../../Dropdown';
import Operator from '../Operator';
import PillBody from '../PillBody';
import { BasePillProps } from '../types';
import CurrencyInput from './CurrencyInput';

const labelMap: { [key: string]: string } = {
  [CurrencyFilterTypes.Currency]: 'Currency',
  [CurrencyFilterTypes.BuyCurrency]: 'Buy Currency',
  [CurrencyFilterTypes.SellCurrency]: 'Sell Currency',
  [CurrencyFilterTypes.FeeCurrency]: 'Fee Currency',
};

export type CurrencyPillProps = BasePillProps<TxnCurrencyFilter> & {
  omit?: CurrencyFilterTypes[];
};

const CurrencyPill: React.FC<CurrencyPillProps> = ({
  filter,
  operators,
  label,
  onChange,
  onDelete,
  omit = [],
}) => {
  const [open, setOpen] = React.useState(false);
  const dropdownValuesRef = React.useRef<DropdownHandle>(null);

  React.useEffect(() => {
    dropdownValuesRef.current?.hide();
  }, [filter]);

  if (!filter) return null;

  return (
    <PillBody label={labelMap[filter.side]} onDelete={onDelete}>
      <div className="border-r border-light-control dark:border-dark-control">
        <Operator {...{ operators, filter, onChange }} />
      </div>
      <div
        className={classNames('hover:bg-light-selected-faint dark:hover:bg-dark-selected-faint rounded-l', {
          'bg-light-selected-faint dark:bg-dark-selected-faint': open,
        })}
      >
        <Dropdown
          isDisabled={false}
          label={filter.symbols[0] as string}
          ref={dropdownValuesRef}
          noStyles
          onShow={() => setOpen(true)}
          onHide={() => setOpen(false)}
        >
          {open && <CurrencyInput {...{ filter, onChange, label, omit }} />}
        </Dropdown>
      </div>
    </PillBody>
  );
};

export default React.memo(CurrencyPill) as typeof CurrencyPill;
