import React from 'react';

interface Props {
  type: 'success' | 'error' | 'info';
  onClick?: () => void;
}

const getIcon = (type: Props['type']) => {
  switch (type) {
    case 'success':
      return { color: 'green', name: 'check' };
    case 'error':
      return { color: 'red', name: 'close' };
    case 'info':
      return { color: 'white', name: 'info' };
  }
};

export default function FlashIcon({ type, onClick }: Props) {
  const { color, name } = getIcon(type);
  return (
    <i
      style={{
        fontSize: '1.5rem',
        paddingBottom: '0.8rem',
        marginRight: '0.8rem',
        position: 'relative',
        top: '0.3rem',
        color,
      }}
      className="material-icons cursor-pointer"
      onClick={onClick}
    >
      {name}
    </i>
  );
}
