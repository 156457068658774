import { gql } from '@apollo/client';
import { LoginMutation, LoginMutationVariables } from '../../graphql/types';
import { client } from '../../lib/apollo';

const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $captchaResponse: String!) {
    login(email: $email, password: $password, captchaResponse: $captchaResponse) {
      user {
        id
      }
      userHash
    }
  }
`;

export const login = async (variables: LoginMutationVariables) => {
  const result = await client.mutate<LoginMutation, LoginMutationVariables>({
    mutation: LOGIN,
    variables,
    context: {
      credentials: 'include',
    },
  });

  if (!result.data?.login) {
    throw new Error('There was an error logging in. Please try again.');
  }

  return result.data.login;
};
