import { useReactiveVar } from '@apollo/client';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../contexts';
import { showErrorFlash, showSuccessFlash } from '../Flash';
import { trackOrderCompleted } from '../Purchase/Checkout/analytics';
import Spinner from '../Spinner';
import { PLAN_ACTIVATION_DATA_LOCALSTORAGE_KEY } from './constants';
import { activatePlanFromCheckout } from './mutations';
import { PlanActivationStoredData } from './types';
import { activatePlanData } from './vars';

function PlanActivator({ children }: { children: React.ReactNode }) {
  const { refetch: refetchUser, user, isLoggedIn } = useUserContext();
  const userId = user?.id;
  const planDataString =
    useReactiveVar(activatePlanData) ?? localStorage.getItem(PLAN_ACTIVATION_DATA_LOCALSTORAGE_KEY);
  const { checkoutSessionId } = JSON.parse(planDataString || '{}') as Partial<PlanActivationStoredData>;
  const [isActivatingPlan, setIsActivatingPlan] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function activate() {
      if (isLoggedIn && checkoutSessionId && userId) {
        try {
          setIsActivatingPlan(true);
          const { data } = await activatePlanFromCheckout({
            checkoutSessionId,
          });
          if (!data) throw new Error('The plan could not be activated. Please try again.');

          await refetchUser?.();
          const { year, plan, price } = data.activatePlanFromCheckout;
          history.push(plan === 'VIP' ? '/getting-started-with-vip' : '/home');
          const planName = plan === 'VIP' ? 'VIP' : capitalize(plan);
          showSuccessFlash(`Your ${planName} plan has been activated for ${year}`, {
            sticky: true,
          });
          trackOrderCompleted({
            userId,
            invoiceId: checkoutSessionId,
            amountPaid: price,
            checkoutSource: 'stripe_checkout',
            analyticsProducts: [
              {
                name: plan,
                product_id: plan,
                price,
                quantity: 1,
              },
            ],
          });
        } catch {
          showErrorFlash(
            'There was an error activating your plan. Please follow the link in your confirmation email.',
          );
        } finally {
          setIsActivatingPlan(false);
          activatePlanData(null);
          localStorage.removeItem(PLAN_ACTIVATION_DATA_LOCALSTORAGE_KEY);
        }
      }
    }

    activate();
  }, [isLoggedIn, checkoutSessionId, refetchUser, history, userId]);

  if (isActivatingPlan) {
    return (
      <div className="flex flex-grow justify-center flex-col items-center pb-8">
        <Spinner className="mt-12" />
      </div>
    );
  }

  return <>{children}</>;
}

export default React.memo(PlanActivator);
