import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { TxnFilterQuery } from '../../../graphql/types';

import { UserContext } from '../../../contexts';
import { usePrevious } from '../../../hooks/usePrevious';
import { getApolloErrorMessages } from '../../../lib/errors';
import Button from '../../Button';
import { showSuccessFlash } from '../../Flash';
import Modal, { ModalProps, showModal } from '../../Modal';
import { ErrorToast } from '../../Toast';
import { useBatchDeleteTxns } from '../mutations';
import { vars } from '../vars';
import { trackBatchTxnDelete } from '../../../lib/analytics';

function BatchDeleteModal({ onClose }: ModalProps) {
  const [error, setError] = useState<ReactNode>(null);
  const { setPollLatestBatchDeleteJobStatus, latestBatchDeleteTxnsJobStatus, user, isHijacked } =
    useContext(UserContext);
  const { filtersVar } = vars;

  const [submitBatchDeleteJob] = useBatchDeleteTxns();

  const [isDeleting, setIsDeleting] = useState(false);
  const handleSubmit = useCallback(async () => {
    try {
      const { filterQuery } = filtersVar();
      if (!filterQuery) throw new Error(`No filters defined`);

      setIsDeleting(true);

      await submitBatchDeleteJob({
        variables: { filterQuery: JSON.parse(filterQuery) as TxnFilterQuery },
      });

      trackBatchTxnDelete({
        hijacked_account_email: user?.email as string,
        hijacked_account_id: user?.id as number,
        filter_query: filterQuery,
        is_hijacked: !!isHijacked,
      });

      setPollLatestBatchDeleteJobStatus?.(true);
    } catch (error) {
      setError(
        <>
          An error occurred while submitting the batch delete job.{' '}
          {getApolloErrorMessages(error) || (error as Error)?.message || String(error)}
        </>,
      );
    }
  }, [
    filtersVar,
    submitBatchDeleteJob,
    user?.email,
    user?.id,
    isHijacked,
    setPollLatestBatchDeleteJobStatus,
  ]);

  const message = `Are you sure you want to delete these transactions?
                  If they are from an API or wallet connection,
                  they will come back when you resync.`;

  const latestJobIsRunning = latestBatchDeleteTxnsJobStatus === 'created';
  const latestJobWasRunning = usePrevious(latestJobIsRunning);

  useEffect(() => {
    if (latestJobWasRunning && !latestJobIsRunning) {
      setIsDeleting(false);
      onClose?.();
      showSuccessFlash('Batch delete job completed');
    }
  }, [latestJobIsRunning, latestJobWasRunning, onClose]);

  return (
    <Modal
      title="Batch delete transactions?"
      subtitle={message}
      onClose={useCallback(() => {
        if (isDeleting) return;
        onClose?.();
      }, [isDeleting, onClose])}
    >
      {error && <ErrorToast>{error}</ErrorToast>}
      <Button loading={isDeleting} disabled={isDeleting} onClick={handleSubmit} type="submit">
        Delete
      </Button>
    </Modal>
  );
}

export default React.memo(BatchDeleteModal);

export function showBatchDeleteTransactionsModal(props?: ModalProps) {
  showModal(<BatchDeleteModal {...props} />);
}
