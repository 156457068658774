import { get, throttle } from 'lodash';
import React, { ReactNode } from 'react';
import posed, { PoseGroup } from 'react-pose';
import theme from '../../css/theme';
import { client } from '../../lib/apollo';
import { getApolloErrorMessages } from '../../lib/errors';
import { clearFlash, flashVar } from '../../variables';
import Icon from './Icon';

export { clearFlash } from '../../variables';

const AnimatedFlash = posed.div({
  enter: { x: '-50%', y: 0, opacity: 1, transition: { ease: 'easeInOut', duration: 150 } },
  exit: { x: '-50%', y: -10, opacity: 0, transition: { ease: 'easeInOut', duration: 150 } },
});

interface Props {
  message: ReactNode;
  type: 'success' | 'error' | 'info';
}

export default function Flash({ message, type }: Props) {
  return (
    <div
      className="z-max"
      style={{
        display: 'flex',
        position: 'fixed',
        top: '24px',
        left: '50%',
      }}
    >
      <PoseGroup>
        <AnimatedFlash
          key="flash"
          style={{
            background: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            minWidth: '20rem',
            top: '0',
            textAlign: 'center',
            borderRadius: theme.borderRadius,
            boxShadow: theme.shadow,
            whiteSpace: 'nowrap',
          }}
        >
          <div className="flex">
            <div className="flex-grow px-12 py-1">
              <Icon type={type} onClick={clearFlash} />
              {message}
            </div>
            <div className="w-8 flex items-center justify-center cursor-pointer" onClick={clearFlash}>
              <span className="material-icons text-base">close</span>
            </div>
          </div>
        </AnimatedFlash>
      </PoseGroup>
    </div>
  );
}

let timeoutId: number;

client?.onClearStore(async () => {
  clearFlash();
});

interface ShowFlashProps extends Props {
  duration?: number;
  error?: unknown;
  sticky?: boolean;
}

export function showFlash({ message, type, sticky, duration = 3000 }: ShowFlashProps) {
  clearTimeout(timeoutId);
  if (!sticky) timeoutId = window.setTimeout(clearFlash, duration);

  flashVar(<Flash {...{ message, type }} />);
}

type ShowFlashShortcutProps = Omit<ShowFlashProps, 'message' | 'type'>;

export const showSuccessFlash = (message: ReactNode, props?: ShowFlashShortcutProps) =>
  showFlash({ message, type: 'success', ...props });

export const showErrorFlash = (message: ReactNode, props?: ShowFlashShortcutProps) => {
  const errorSpecificMessage = getApolloErrorMessages(props?.error);
  const description = errorSpecificMessage || get(props?.error, 'message') || props?.error || '';

  showFlash({
    message: (
      <>
        {message}
        {description}
      </>
    ),
    type: 'error',
    ...props,
    duration: errorSpecificMessage ? 30000 : props?.duration,
  });
};

export const showInfoFlash = (message: ReactNode, props?: ShowFlashShortcutProps) =>
  showFlash({ message, type: 'info', ...props });

const NETWORK_ERROR_THROTTLE_INTERVAL_MS = 60_000; // don't show the network error flash more than this often
export const showNetworkErrorFlash = throttle(() => {
  showErrorFlash(
    'An error occurred communicating with the API. Some functionality may not work as expected. Check your connectivity and reload the page.',
    { duration: 10000 },
  );
}, NETWORK_ERROR_THROTTLE_INTERVAL_MS);
