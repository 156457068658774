import { noop } from 'lodash';
import React, { PropsWithChildren, useState } from 'react';
import { TxnFragment } from '../../../graphql/types';
import { useObject } from '../../../lib/hooks';

type EditTxnFormContextType = {
  txns: TxnFragment[];
  hasSpecIdMatches?: boolean;
  multiPageBatch?: boolean;
  setHasSpecIdMatches: (hasSpecIdMatches: boolean) => void;
};

const EditTxnFormContext = React.createContext<EditTxnFormContextType>({
  txns: [],
  hasSpecIdMatches: false,
  setHasSpecIdMatches: noop,
});

export const useEditTxnFormContext = () => React.useContext(EditTxnFormContext);

function EditTxnFormProvider({
  children,
  txns,
  multiPageBatch,
}: PropsWithChildren<Omit<EditTxnFormContextType, 'hasSpecIdMatches' | 'setHasSpecIdMatches'>>) {
  const [hasSpecIdMatches, setHasSpecIdMatches] = useState(
    txns.some((txn) => txn.specIdMatchesAsComponent.length > 0 || txn.specIdMatchesAsSell.length > 0),
  );
  return (
    <EditTxnFormContext.Provider
      value={useObject({
        txns,
        hasSpecIdMatches,
        setHasSpecIdMatches,
        multiPageBatch,
      })}
    >
      {children}
    </EditTxnFormContext.Provider>
  );
}

export default React.memo(EditTxnFormProvider);
