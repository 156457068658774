import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  BatchDeleteTxnsMutation,
  BatchDeleteTxnsMutationVariables,
  DeleteTxnsMutation,
  DeleteTxnsMutationVariables,
} from '../../graphql/types';
import pluralize from '../../lib/pluralize';
import { showSuccessFlash } from '../Flash';

const DELETE_TXNS = gql`
  mutation DeleteTxns($ids: [String!]!) {
    deleteTxns(ids: $ids)
  }
`;

type Args = {
  onSuccess?: () => void;
};

export const useDeleteTxns = ({ onSuccess }: Args) => {
  return useMutation<DeleteTxnsMutation, DeleteTxnsMutationVariables>(DELETE_TXNS, {
    onCompleted: (data) => {
      showSuccessFlash(`${pluralize(data.deleteTxns, 'Transaction')} deleted`);
      onSuccess && onSuccess();
    },
  });
};

const BATCH_DELETE_TXNS = gql`
  mutation BatchDeleteTxns($filterQuery: TxnFilterQuery!) {
    batchDeleteTxns(filterQuery: $filterQuery)
  }
`;

export const useBatchDeleteTxns = (
  options?: MutationHookOptions<BatchDeleteTxnsMutation, BatchDeleteTxnsMutationVariables>,
) => {
  return useMutation<BatchDeleteTxnsMutation, BatchDeleteTxnsMutationVariables>(BATCH_DELETE_TXNS, options);
};
