import React, { useCallback, useMemo, useState } from 'react';
import { deleteCookie } from '../../lib/Auth/deleteCookie';
import Button from '../Button';
import { showErrorFlash, showSuccessFlash } from '../Flash';
import Modal, { ModalProps, showModal } from '../Modal';
import Text from '../Text';
import TextLink from '../TextLink';
import { METAMASK_PROMO_COOKIE } from './consts';
import { useSyncCovalentWallets } from './mutations';

interface MetamaskPromoModalProps extends ModalProps {
  cookieValue: string;
}

function MetamaskPromoModal({ cookieValue, ...modalProps }: MetamaskPromoModalProps) {
  const [syncWallets] = useSyncCovalentWallets();

  const [loading, setLoading] = useState(false);

  const { addresses, chainIds } = useMemo(() => {
    const queryString = new URLSearchParams(decodeURIComponent(cookieValue));
    const addresses = (queryString.get('address') || '').split(',');
    const chainIds = (queryString.get('chainid') || '').split(',');
    return { addresses, chainIds };
  }, [cookieValue]);

  const confirm = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await syncWallets({
        variables: { addresses, chainIds },
      });
      let message = `Imported ${data?.syncCovalentWallets.credentials} wallets`;
      if (data?.syncCovalentWallets.duplicates) {
        message += ` and skipped ${data?.syncCovalentWallets.duplicates} duplicates`;
      }
      showSuccessFlash(message);
      deleteCookie(METAMASK_PROMO_COOKIE);
      modalProps.onClose?.();
    } catch (e) {
      showErrorFlash('Failed to import wallets');
      setLoading(false);
    }
  }, [addresses, chainIds, modalProps, syncWallets]);

  const ignore = useCallback(() => {
    deleteCookie(METAMASK_PROMO_COOKIE);
    modalProps.onClose?.();
  }, [modalProps]);

  return (
    <Modal title="Import wallets from MetaMask" {...modalProps}>
      <div className="flex flex-col gap-4">
        <Text variant="muted">
          Would you like to proceed with importing your MetaMask wallets into TokenTax?
        </Text>
        <ul className="pl-4 flex flex-col gap-1 mb-2">
          {addresses.map((address) => (
            <li key={address}>
              <Text variant="muted">
                <span className="mr-2">•</span>
                {address}
              </Text>
            </li>
          ))}
        </ul>
        <div className="flex gap-4">
          <Button onClick={confirm} loading={loading}>
            Import wallets
          </Button>
          <TextLink onClick={ignore}>Ignore</TextLink>
        </div>
      </div>
    </Modal>
  );
}

export const showMetamaskPromoModal = (props: MetamaskPromoModalProps) => {
  showModal(<MetamaskPromoModal {...props} />);
};
