import { keyBy } from 'lodash';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useAllCredentials } from '../../graphql/queries';
import { useLatestReconGuideTreatmentsJob } from '../../graphql/queries/reconGuide';
import { EnumGenericJobStatus } from '../../graphql/types';
import { useObject } from '../../lib/hooks';
import { showErrorFlash } from '../Flash';
import { useLatestCreateMovementsJob, useLatestGenerateAccountsJob } from './CostBasis/queries';
import { useMissingExchanges } from './Imports/MissingExchanges/queries';
import { useHiddenWallets } from './Imports/MissingWallets/Table/useHiddenWallets';
import { useMissingWallets, useValidateMissingWallets } from './Imports/MissingWallets/queries';
import { useLatestMissingPricesJob, useMissingPricesData } from './Pricing/queries';
import { SelfReconContext } from './SelfReconContext';
import { useAllUnreconciledTransactions, useLpTokens } from './UnreconciledTransactions/queries';
import { useStore } from './UnreconciledTransactions/store';
import { shouldThrowErrorForJob } from './helpers';

const { created, running } = EnumGenericJobStatus;

interface Props {
  children: ReactNode;
}

const SELF_RECON_JOB_POLL_INTERVAL_MS = 2000;

export default function SelfReconProvider({ children }: Props) {
  const { data: allCredentials } = useAllCredentials({
    variables: { extended: false },
  });
  const [hiddenWalletIds] = useHiddenWallets();
  const [lpTokenTickersByAddress, setLpTokenTickersByAddress] = useState({});
  const {
    data: unreconciledTransactionsData,
    refetch: refetchUnreconciledTransactions,
    loading: unreconciledTransactionsLoading,
  } = useAllUnreconciledTransactions();
  const { data: lpTokensData } = useLpTokens();
  const nextUnreconciledTransactionIds = useStore(
    (state) => state.getNextUnreconciledTransactionIds(2),
    // this array is calculated anew every time. do not re-render unless its elements change
    (oldIds, newIds) => JSON.stringify(oldIds) === JSON.stringify(newIds),
  );

  const allTransactions = useStore((state) => state.transactions);
  const isLoaded = useStore((state) => state.loaded);
  const unreconciledTxnsCount = isLoaded ? allTransactions.filter((t) => !t.resolved).length : undefined;

  useEffect(() => {
    if (!lpTokensData) return;
    const lpTokens = lpTokensData.getLpTokens;
    const lpTokenTickersByAddress = Object.fromEntries(
      lpTokens.map(({ address, ticker }) => [address.toLowerCase(), ticker]),
    );
    setLpTokenTickersByAddress(lpTokenTickersByAddress);
  }, [lpTokensData]);

  useEffect(() => {
    const { loadUnreconciledTransactions, loaded } = useStore.getState();
    if (!unreconciledTransactionsData || loaded) {
      return;
    }
    const unreconciledTransactions = unreconciledTransactionsData.unreconciledTransactions.edges;

    // load the entire unfiltered set of unreconciled transactions into the store
    loadUnreconciledTransactions(unreconciledTransactions);
  }, [lpTokenTickersByAddress, unreconciledTransactionsData]);

  useEffect(() => {
    const { loadTxnData } = useStore.getState();
    if (!lpTokenTickersByAddress) {
      // this is _slightly_ inefficient as for the first call to loadTxnData
      // we'll have to wait for these depedencies to load
      // it's not that bad as the problem only occurs for the first call
      return;
    }

    for (const unreconciledTransactionId of nextUnreconciledTransactionIds) {
      loadTxnData({ unreconciledTransactionId, lpTokenTickersByAddress });
    }
  }, [lpTokenTickersByAddress, nextUnreconciledTransactionIds]);

  useEffect(() => {
    return () => {
      useStore.getState().reset();
    };
  }, []);

  const { data: missingExchanges, refetch: refetchMissingExchanges } = useMissingExchanges();

  const {
    data: missingWallets,
    refetch: refetchMissingWallets,
    loading: missingWalletsLoading,
  } = useMissingWallets();

  const missingWalletsWithoutHidden = useMemo(
    () => missingWallets?.filter((missingWallet) => !hiddenWalletIds.includes(missingWallet.id)),
    [missingWallets, hiddenWalletIds],
  );

  const { data: missingWalletsValidation, loading: missingWalletsValidationLoading } =
    useValidateMissingWallets(
      missingWalletsWithoutHidden?.map((f) => ({ address: f.address, chain: f.chain })) || [],
    );

  const missingWalletsValidationMap = keyBy(missingWalletsValidation?.validateMissingWallets?.edges, 'id');

  // Filter out any exchanges which have no validated txns
  const validatedMissingWallets = useMemo(() => {
    if (!missingWalletsValidation) return missingWalletsWithoutHidden;
    return missingWalletsWithoutHidden?.filter((missingWallet) => {
      return missingWalletsValidationMap[missingWallet.id]?.hasTransactions;
    });
  }, [missingWalletsValidation, missingWalletsValidationMap, missingWalletsWithoutHidden]);

  const { data: missingPricesData, loading: missingPricesLoading } = useMissingPricesData();

  const {
    startPolling: startLatestMissingPricesPolling,
    stopPolling: stopLatestMissingPricesPolling,
    loading: missingPricesStatusIsLoading,
    data: latestMissingPricesJobData,
  } = useLatestMissingPricesJob({
    onCompleted: ({ latestMissingPricesJob }) => {
      // setTimeout ensures destructured variables from returned value are available
      setTimeout(() => {
        if (!latestMissingPricesJob || ['successful', 'failed'].includes(latestMissingPricesJob.status)) {
          if (shouldThrowErrorForJob({ job: latestMissingPricesJob })) {
            showErrorFlash(
              'We encountered an error while fetching missing prices. Try again, or contact support if the error persists.',
            );
          }
          stopLatestMissingPricesPolling();
        } else {
          startLatestMissingPricesPolling(SELF_RECON_JOB_POLL_INTERVAL_MS);
        }
      });
    },
  });

  const startMissingPricesPolling = useCallback(
    () => startLatestMissingPricesPolling(SELF_RECON_JOB_POLL_INTERVAL_MS),
    [startLatestMissingPricesPolling],
  );

  const {
    startPolling: startLatestReconGuideTreatmentsJobPolling,
    stopPolling: stopLatestReconGuideTreatmentsJobPolling,
    data: latestReconGuideTreatmentsJobData,
    loading: reconGuideAutomationStatusIsLoading,
  } = useLatestReconGuideTreatmentsJob({
    onCompleted({ latestReconGuideTreatmentsJob }) {
      setTimeout(() => {
        if (
          !latestReconGuideTreatmentsJob ||
          ['successful', 'failed'].includes(latestReconGuideTreatmentsJob.status)
        ) {
          if (shouldThrowErrorForJob({ job: latestReconGuideTreatmentsJob })) {
            showErrorFlash(
              'We encountered an error during automatic reconciliation. Try again, or contact support if the error persists.',
            );
          }
          stopLatestReconGuideTreatmentsJobPolling();
          refetchUnreconciledTransactions();
        } else {
          useStore.getState().reset();
          startLatestReconGuideTreatmentsJobPolling(SELF_RECON_JOB_POLL_INTERVAL_MS);
        }
      });
    },
  });

  const startReconGuideAutomationPolling = useCallback(
    () => startLatestReconGuideTreatmentsJobPolling(SELF_RECON_JOB_POLL_INTERVAL_MS),
    [startLatestReconGuideTreatmentsJobPolling],
  );

  const {
    startPolling: startLatestGenerateAccountsJobPolling,
    stopPolling: stopLatestGenerateAccountsJobPolling,
    loading: accountGenerationStatusIsLoading,
    data: latestGenerateAccountsJobData,
  } = useLatestGenerateAccountsJob({
    onCompleted({ latestGenerateAccountsJob }) {
      setTimeout(() => {
        if (
          !latestGenerateAccountsJob ||
          (latestGenerateAccountsJob?.status &&
            ['successful', 'failed'].includes(latestGenerateAccountsJob.status))
        ) {
          if (shouldThrowErrorForJob({ job: latestGenerateAccountsJob })) {
            showErrorFlash(
              'We encountered an error while generating accounts. Try again, or contact support if the error persists.',
            );
          }
          stopLatestGenerateAccountsJobPolling();
        } else {
          startLatestGenerateAccountsJobPolling(SELF_RECON_JOB_POLL_INTERVAL_MS);
        }
      });
    },
  });

  const startAccountGenerationPolling = useCallback(
    () => startLatestGenerateAccountsJobPolling(SELF_RECON_JOB_POLL_INTERVAL_MS),
    [startLatestGenerateAccountsJobPolling],
  );

  const {
    startPolling: startLatestCreateMovementsJobPolling,
    stopPolling: stopLatestCreateMovementsJobPolling,
    loading: movementGenerationStatusIsLoading,
    data: latestGenerateMovementsJobData,
  } = useLatestCreateMovementsJob({
    onCompleted({ latestCreateMovementsJob }) {
      setTimeout(() => {
        if (
          !latestCreateMovementsJob ||
          (latestCreateMovementsJob?.status &&
            ['successful', 'failed'].includes(latestCreateMovementsJob.status))
        ) {
          if (shouldThrowErrorForJob({ job: latestCreateMovementsJob })) {
            showErrorFlash(
              'We encountered an error while creating movements. Try again, or contact support if the error persists.',
            );
          }
          stopLatestCreateMovementsJobPolling();
        } else {
          startLatestCreateMovementsJobPolling(SELF_RECON_JOB_POLL_INTERVAL_MS);
        }
      });
    },
  });

  const startMovementGenerationPolling = useCallback(
    () => startLatestCreateMovementsJobPolling(SELF_RECON_JOB_POLL_INTERVAL_MS),
    [startLatestCreateMovementsJobPolling],
  );

  // These jobs are tightly coupled; we want to see the individual load states, but also have the ability
  // to "lock down" components if ANY of the jobs are running
  const reconOrAccountsOrMovementsStatusIsLoading = useMemo(() => {
    const { status: reconStatus } = latestReconGuideTreatmentsJobData?.latestReconGuideTreatmentsJob ?? {};
    const { status: accountsStatus } = latestGenerateAccountsJobData?.latestGenerateAccountsJob ?? {};
    const { status: movementsStatus } = latestGenerateMovementsJobData?.latestCreateMovementsJob ?? {};

    return (
      [created, running].includes(reconStatus as EnumGenericJobStatus) ||
      [created, running].includes(accountsStatus as EnumGenericJobStatus) ||
      [created, running].includes(movementsStatus as EnumGenericJobStatus)
    );
  }, [latestReconGuideTreatmentsJobData, latestGenerateAccountsJobData, latestGenerateMovementsJobData]);

  const data = useObject({
    allCredentials,
    missingExchanges,
    missingWallets: validatedMissingWallets,
    refetchMissingExchanges,
    refetchMissingWallets,
    lpTokenTickersByAddress,
    unreconciledTxnsCount,
    startReconGuideAutomationPolling,
    reconGuideAutomationStatusIsLoading,
    latestReconGuideTreatmentsJobData,
    unreconciledTransactionsLoading,
    missingWalletsLoading: missingWalletsLoading || missingWalletsValidationLoading,
    missingPricesLoading,
    missingPricesData: missingPricesData?.missingPricesData,
    startMissingPricesPolling,
    missingPricesStatusIsLoading,
    latestMissingPricesJobData,
    startAccountGenerationPolling,
    accountGenerationStatusIsLoading,
    latestGenerateAccountsJobData,
    startMovementGenerationPolling,
    movementGenerationStatusIsLoading,
    latestGenerateMovementsJobData,
    reconOrAccountsOrMovementsStatusIsLoading,
  });

  return <SelfReconContext.Provider value={data}>{children}</SelfReconContext.Provider>;
}

export const useSelfReconContext = () => React.useContext(SelfReconContext);
