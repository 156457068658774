import React from 'react';
import { InvoiceQuery } from '../../graphql/types';
import { formatCurrencyAmount } from '../../lib/formatters';
import Text from '../Text';

type Line = InvoiceQuery['invoice']['lines'][number];

interface Props {
  line: Line;
}

function LineItem({ line }: Props) {
  const { amount, currency, description, quantity, unitAmount } = line;

  return (
    <>
      <div>
        <Text variant="muted">{description}</Text>
      </div>
      <div className="text-right">
        <Text variant="muted">{quantity}</Text>
      </div>
      <div className="text-right">
        <Text variant="muted">
          {unitAmount ? formatCurrencyAmount(unitAmount / 100, { currency, minimumFractionDigits: 0 }) : '-'}
        </Text>
      </div>
      <div className="text-right">
        <Text variant="muted">
          {formatCurrencyAmount(amount / 100, { currency, minimumFractionDigits: 0 })}
        </Text>
      </div>
    </>
  );
}

export default React.memo(LineItem);
