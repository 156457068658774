import { gql } from '@apollo/client';

export const TAX_DETAIL = gql`
  fragment TaxDetail on UserTaxDetail {
    id
    addons {
      id
      addon {
        createdAt
        displayName
        price
        product
        sku
        updatedAt
      }
    }
    baseCurrency
    calculateAsMargin
    country {
      id
      endDateDay
      endDateMonth
      name
      shorthand
      methods
      taxMethods {
        id
        label
      }
      startDateDay
      startDateMonth
    }
    createdAt
    dateRange {
      startDate
      endDate
    }
    filingStatus
    fullFilingPlan
    grossIncome
    likeKind
    longTermCapitalGainsRate
    marginalTaxRate
    method
    taxMethod {
      id
      label
    }
    needsFbar
    plan
    setFilingMethodSkipped
    skipped
    state
    taxYear
    ukBnbRule
    updatedAt
    useHoursMinsComparison
    userId
    locked
    isEngaged
  }
`;
