export enum Side {
  sent = 'sent',
  received = 'received',
}

export enum Both {
  both = 'both',
}

export const SideOrBoth = {
  ...Side,
  ...Both,
};

export type SideOrBoth = (typeof SideOrBoth)[keyof typeof SideOrBoth];
