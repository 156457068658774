import { gql } from '@apollo/client';
import { PasswordForgotMutationVariables } from '../../graphql/types';
import { client } from '../../lib/apollo';

const PASSWORD_FORGOT = gql`
  mutation PasswordForgot($email: String!, $captchaResponse: String!) {
    passwordForgot(email: $email, captchaResponse: $captchaResponse)
  }
`;

export const passwordForgot = async (variables: PasswordForgotMutationVariables) => {
  await client.mutate<PasswordForgotMutationVariables, PasswordForgotMutationVariables>({
    mutation: PASSWORD_FORGOT,
    variables,
  });
};
