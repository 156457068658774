import tailwindConfig from '../../../../tailwind.config';
import { Theme } from '../../../theme/useTheme';
import { Styles } from '../types';
import { getBaseStyles } from './base';

const { textColor } = tailwindConfig.theme.extend;

export function getInlineStyles<Option>(theme: Theme): Styles<Option> {
  return {
    ...getBaseStyles(theme),
    control: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: 'transparent',
      padding: '0.25rem',
      cursor: 'pointer',
      borderRadius: '0',
      borderColor: 'transparent',
      '&:hover': {
        borderColor: 'transparent',
      },
      border: 'none',
      boxShadow: 'none',
      minHeight: '0',
    }),
    dropdownIndicator: (baseStyles) => {
      const color = textColor[`${theme}-muted`];
      return {
        ...baseStyles,
        color,
        '&:hover': { color },
        padding: '0',
      };
    },
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      width: '100%',
      padding: '0',
    }),
  };
}

// inline-brand is the same as inline, but with the brand link color for the selected value
export function getInlineBrandStyles<Option>(theme: Theme): Styles<Option> {
  return {
    ...getInlineStyles(theme),
    singleValue: (baseStyles) => {
      const color = textColor[`${theme}-link`];
      return {
        ...baseStyles,
        color,
      };
    },
  };
}
