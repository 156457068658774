import React from 'react';

import { ChoiceProps, FfpByYear } from './types';
import Option from './Option';

export default function ChooseYear({
  ffpByYear,
  send,
  setYear,
}: ChoiceProps & {
  setYear: (year: number) => void;
  ffpByYear?: FfpByYear;
}) {
  const options =
    (ffpByYear &&
      Object.keys(ffpByYear)
        .filter((k: string) => ffpByYear[parseInt(k, 10)])
        .map((y) => ({ value: parseInt(y, 10), label: String(y) }))) ||
    [];

  return (
    <div className="pt-4">
      {options.map((o) => (
        <Option
          key={o.value}
          option={o}
          send={() => {
            setYear(o.value);
            send('CHOOSE_METHOD');
          }}
        />
      ))}
    </div>
  );
}
