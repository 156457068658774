import { useState } from 'react';
import {
  EnumGenericJobStatus,
  LatestBatchDeleteTxnsJobStatusQuery,
  LatestBatchUpdateTxnsJobStatusQuery,
} from '../../graphql/types';
import { useQueryAndRefetchOnUserChange } from '../../lib/hooks';
import { LATEST_BATCH_DELETE_TXNS_JOB_STATUS, LATEST_BATCH_UPDATE_TXNS_JOB_STATUS } from './queries';

const { successful, failed } = EnumGenericJobStatus;

function useBatchUpdateJobStatus() {
  const [pollLatestBatchUpdateJobStatus, setPollLatestBatchUpdateJobStatus] = useState(false);

  const latestBatchUpdateTxnsJobStatusRes =
    useQueryAndRefetchOnUserChange<LatestBatchUpdateTxnsJobStatusQuery>(LATEST_BATCH_UPDATE_TXNS_JOB_STATUS, {
      fetchPolicy: 'network-only',
      pollInterval: pollLatestBatchUpdateJobStatus ? 1000 : 0,
      onCompleted: (data) => {
        const { latestBatchUpdateTxnsJobStatus } = data;
        if (!latestBatchUpdateTxnsJobStatus) return;
        if ([successful, failed].includes(latestBatchUpdateTxnsJobStatus)) {
          setPollLatestBatchUpdateJobStatus(false);
        }
      },
    });

  const { latestBatchUpdateTxnsJobStatus = null } = latestBatchUpdateTxnsJobStatusRes.data ?? {};
  return { latestBatchUpdateTxnsJobStatus, setPollLatestBatchUpdateJobStatus };
}

function useBatchDeleteJobStatus() {
  const [pollLatestBatchDeleteJobStatus, setPollLatestBatchDeleteJobStatus] = useState(false);

  const latestBatchDeleteTxnsJobStatusRes =
    useQueryAndRefetchOnUserChange<LatestBatchDeleteTxnsJobStatusQuery>(LATEST_BATCH_DELETE_TXNS_JOB_STATUS, {
      fetchPolicy: 'network-only',
      pollInterval: pollLatestBatchDeleteJobStatus ? 1000 : 0,
      onCompleted: (data) => {
        if (data.latestBatchDeleteTxnsJobStatus !== 'created') {
          setPollLatestBatchDeleteJobStatus(false);
        }
      },
    });

  const { latestBatchDeleteTxnsJobStatus = null } = latestBatchDeleteTxnsJobStatusRes.data ?? {};
  return { latestBatchDeleteTxnsJobStatus, setPollLatestBatchDeleteJobStatus };
}

export const useBatchJobsStatus = () => {
  const { latestBatchUpdateTxnsJobStatus, setPollLatestBatchUpdateJobStatus } = useBatchUpdateJobStatus();
  const { latestBatchDeleteTxnsJobStatus, setPollLatestBatchDeleteJobStatus } = useBatchDeleteJobStatus();

  const txnEditingIsLocked =
    latestBatchUpdateTxnsJobStatus === 'created' || latestBatchDeleteTxnsJobStatus === 'created';

  return {
    latestBatchUpdateTxnsJobStatus,
    setPollLatestBatchUpdateJobStatus,
    latestBatchDeleteTxnsJobStatus,
    setPollLatestBatchDeleteJobStatus,
    txnEditingIsLocked,
  };
};
