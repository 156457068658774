import { CURRENT_TAX_YEAR } from '../../constants/taxYears';
import { EnumTaxMethod, LatestTxnReportsQuery } from '../../graphql/types';

type MethodType = NonNullable<
  NonNullable<
    LatestTxnReportsQuery['latestTxnReports']
  >['latestTxnReportsByYear'][0]['taxDetail']['taxMethod']
>;

const methodPriority: EnumTaxMethod[] = [
  EnumTaxMethod.FIFO,
  EnumTaxMethod.LIFO,
  EnumTaxMethod.MINIMIZATION,
  EnumTaxMethod.AVERAGE_COST,
];

// Defaults of FIFO/LIFO/MIN
const defaultMethods: MethodType[] = [
  {
    __typename: 'TaxMethod',
    id: EnumTaxMethod.FIFO,
    label: 'FIFO',
  },
  {
    __typename: 'TaxMethod',
    id: EnumTaxMethod.LIFO,
    label: 'LIFO',
  },
  {
    __typename: 'TaxMethod',
    id: EnumTaxMethod.MINIMIZATION,
    label: 'LIFO',
  },
];

const downloadOptionsUSA = [
  { value: 'PDF_8949', label: 'Download 8949 PDF' },
  { value: 'CSV_8949_DRAKE', label: 'Download 8949 Drake' },
  { value: 'CSV_8949', label: 'Download 8949 CSV' },
  { value: 'TXF_8949', label: 'Download 8949 TXF' },
  { value: 'CSV_TURBOTAX_WEB', label: 'Generate TurboTax CSV' },
  { value: 'CSV_FBAR', label: 'Download FBAR Data' },
  { value: 'income-tax-summary', label: 'Income & Tax Summary' },
];

const downloadOptionsInternational = [
  { value: 'CSV_8949', label: 'Gain Loss Report CSV' },
  { value: 'TXF_8949', label: 'Gain Loss Report TXF' },
  { value: 'CSV_FBAR', label: 'FBAR Data' },
  { value: 'income-tax-summary', label: 'Income & Tax Summary' },
];

const defaultQueries = {
  method: 'FIFO',
  page: '1',
  numItems: '50',
  sortColumn: 'dateSold',
  sortOrder: 'asc',
};

const defaultLongTermCapitalGainsRate = 0.15;

const defaultMarginalTaxRate = 0.3;

type D = Partial<{
  sell: { currency: string; sold_currency: string };
  taxObject: {
    costBasis: number;
    costBasisFeeAddition: number;
    gainLoss: number;
    proceedsFeeDeduction: number;
    totalProceeds: number;
  };
}>;

const costBasisColumns = (
  includeFees: boolean,
): {
  id: string;
  label: string;
  accessor?: string | ((d: D) => string | number | undefined);
  type?: string;
  headerClassName?: string;
  hide?: string;
  uppercase?: boolean;
  decimals?: boolean;
  isCents?: boolean;
}[] => [
  {
    id: 'soldCurrency',
    label: 'Sell Currency',
    accessor: (d: D) => `${d.sell && d.sell.sold_currency ? `${d.sell.sold_currency}` : ''}`,
  },
  {
    id: 'buyCurrency',
    label: 'Buy Currency',
    accessor: (d: D) => `${d.sell && d.sell.currency ? `${d.sell.currency}` : ''}`,
  },
  {
    id: 'amount',
    accessor: 'taxObject.unitsSold',
    label: 'Amount',
    type: 'quantity',
  },
  {
    id: 'dateAcquired',
    headerClassName: 'tax-dashboard-date',
    accessor: 'taxObject.buyDate',
    label: 'Date Acq. (UTC)',
    type: 'date',
    hide: 'averageCost',
  },
  {
    id: 'dateSold',
    headerClassName: 'tax-dashboard-date',
    accessor: 'taxObject.sellDate',
    label: 'Date Sold (UTC)',
    type: 'date',
  },
  {
    id: 'proceeds',
    headerClassName: 'tax-dashboard-proceeds',
    accessor: includeFees
      ? (d: D) => d.taxObject && d.taxObject.totalProceeds - (d.taxObject.proceedsFeeDeduction || 0)
      : 'taxObject.totalProceeds',
    label: 'Total Proceeds',
    type: 'currency',
    decimals: false,
    isCents: true,
  },
  {
    id: 'costBasis',
    accessor: includeFees
      ? (d: D) => d.taxObject && d.taxObject.costBasis + (d.taxObject.costBasisFeeAddition || -2)
      : 'taxObject.costBasis',
    type: 'costBasis',
    label: 'Cost Basis',
  },
  {
    id: 'gainLoss',
    accessor: includeFees
      ? (d: D) =>
          d.taxObject &&
          d.taxObject.gainLoss -
            ((d.taxObject.costBasisFeeAddition || 0) + (d.taxObject.proceedsFeeDeduction || 0))
      : 'taxObject.gainLoss',
    label: 'Gain/Loss',
    type: 'gain-loss',
    decimals: false,
    isCents: true,
  },
  {
    id: 'shortLong',
    accessor: 'taxObject.term',
    label: 'Short/Long',
    uppercase: true,
    hide: 'averageCost',
  },
];

export default {
  methodPriority,
  defaultMethods,
  downloadOptionsUSA,
  downloadOptionsInternational,
  defaultQueries,
  defaultLongTermCapitalGainsRate,
  defaultMarginalTaxRate,
  costBasisColumns,
};

// defaults to FIFO but should always be set from result of latestTxnReports
export const defaultMethod = EnumTaxMethod.FIFO;
export const defaultYear = CURRENT_TAX_YEAR;
