import { makeVar } from '@apollo/client';
import Cookies from 'js-cookie';
import { getHijackedUserIdFromQuery } from './shared';
import { JwtData } from './types';

export const ACCESSIBLE_TOKEN_DATA_COOKIE_NAME = 'accessibleTokenData';

type AccessibleTokenData = Pick<JwtData, 'roles' | 'id' | 'exp'>;

export const getTokenDataFromCookie = () => {
  const tokenDataStr = Cookies.get(ACCESSIBLE_TOKEN_DATA_COOKIE_NAME);
  if (!tokenDataStr) throw new Error();
  return JSON.parse(tokenDataStr) as AccessibleTokenData;
};

const initialTokenData = (() => {
  try {
    return getTokenDataFromCookie();
  } catch {
    return undefined;
  }
})();

export const tokenDataVar = makeVar<AccessibleTokenData | undefined>(initialTokenData);
export const hijackedUserIdVar = makeVar<number | null>(getHijackedUserIdFromQuery());

export const unsetTokenData = () => {
  Cookies.remove(ACCESSIBLE_TOKEN_DATA_COOKIE_NAME, {
    domain: window.location.hostname,
    path: '/',
    secure: true,
    sameSite: 'strict',
  });
  tokenDataVar(undefined);
};
