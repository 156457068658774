import React from 'react';
import { MultiSelectStringFilter } from '../../../graphql/types';
import Filter from '../Filter';
import { FilterProps } from '../types';
import MultiSelectInput, { MultiSelectInputProps } from './MultiSelectInput';
import MultiSelectPill, { MultiSelectPillProps } from './MultiSelectPill';

type InputProps = Pick<MultiSelectInputProps, 'multiSelectOptions'>;
type PillProps = Pick<MultiSelectPillProps, 'multiSelectOptions' | 'operators'>;
type ExtraProps = InputProps & PillProps;
type Props = ExtraProps & FilterProps<MultiSelectStringFilter>;

export { default as MultiSelectInput } from './MultiSelectInput';

function MultiSelectFilter(props: Props) {
  const { multiSelectOptions, operators } = props;

  return (
    <Filter<MultiSelectStringFilter, ExtraProps>
      Input={MultiSelectInput}
      Pill={MultiSelectPill}
      props={{ multiSelectOptions, operators }}
      {...props}
    />
  );
}

export default React.memo(MultiSelectFilter);
