import { ReactiveVar } from '@apollo/client';
import React, { useCallback } from 'react';
import Text from '../../Text';
import TextLink from '../../TextLink';
import { showTransactionCountModal } from '../TransactionCountModal';
import { AllTransactionsActions } from '../actions';
import { FiltersState } from '../types';

interface Props {
  /* loading state */
  loading: boolean;
  /* total transactions */
  totalCount?: number;
  reviewedCount?: number;
  hidden?: boolean;
  actions: AllTransactionsActions;
  filtersVar: ReactiveVar<FiltersState>;
}

function TransactionCount({ totalCount, reviewedCount, loading, hidden, actions, filtersVar }: Props) {
  const handleClick = useCallback(() => {
    if (totalCount === 0) return;
    showTransactionCountModal({ actions, filtersVar });
  }, [totalCount, actions, filtersVar]);

  const total = totalCount || 0;
  const reviewed = reviewedCount || 0;

  return (
    <span className={`py-1 flex items-center gap-5 ${hidden ? 'hidden order-1' : ''}`}>
      {!loading && (
        <>
          <TextLink onClick={handleClick}>{total} Transactions</TextLink>
          {reviewed > 0 && <Text variant="muted">{reviewed} Reviewed Transactions</Text>}
        </>
      )}
      {loading && <Text variant="muted">Loading</Text>}
    </span>
  );
}

export default React.memo(TransactionCount);
