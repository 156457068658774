import { MutationHookOptions, gql, useMutation } from '@apollo/client';
import { DelinkSpecIdsMutation, DelinkSpecIdsMutationVariables } from '../types';

export const DELINK_SPEC_IDS = gql`
  mutation DelinkSpecIds($sellId: String, $componentId: String) {
    delinkSpecIds(sellId: $sellId, componentId: $componentId)
  }
`;

export const useDelinkSpecIds = (
  options?: MutationHookOptions<DelinkSpecIdsMutation, DelinkSpecIdsMutationVariables>,
) => useMutation<DelinkSpecIdsMutation, DelinkSpecIdsMutationVariables>(DELINK_SPEC_IDS, options);
