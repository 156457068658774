import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useMovementMatchConstraints } from '../../../graphql/queries';
import { CreateMovementMutationVariables, TxnFragment } from '../../../graphql/types';
import AccountSelector from '../../AccountSelector';
import Button from '../../Button';
import DateTimePicker from '../../DateTimePicker';
import { showErrorFlash } from '../../Flash';
import Input, { NumberInput } from '../../Input';
import Modal, { showModal } from '../../Modal';
import Spinner from '../../Spinner';
import TextLink from '../../TextLink';
import { ErrorToast } from '../../Toast';
import { validate } from './validate';

type CreateMovementModalProps = {
  initialData: CreateMovementMutationVariables & { txns: TxnFragment[] };
  onClose: () => void;
  onSubmit: (data: CreateMovementMutationVariables) => Promise<void>;
};

export const CreateMovementModal = ({ initialData, onClose, onSubmit }: CreateMovementModalProps) => {
  const [formData, setFormData] = useState<CreateMovementMutationVariables>(initialData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, loading } = useMovementMatchConstraints({
    onError: () => {
      showErrorFlash('Error loading movement data. Please contact support if the issue persists');
      onClose();
    },
  });

  const constraints = useMemo(
    () => ({
      quantityLeewayPercentage: data?.movementMatchConstraints?.quantityLeewayPercentage,
      timestampLeewayDays: data?.movementMatchConstraints?.timestampLeewayDays,
    }),
    [data],
  );

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await onSubmit(formData);
      onClose();
    } catch (error) {
      // Intentionally empty (this is already inside of a try/catch in parent component)
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateField = <K extends keyof CreateMovementMutationVariables>(
    field: K,
    value: CreateMovementMutationVariables[K],
  ) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const txns = initialData.txns;

  const { isValid, errorMessage } = useMemo(
    () => validate(formData, txns, constraints),
    [formData, txns, constraints],
  );

  if (loading) {
    return (
      <Modal
        title="Create Movement"
        subtitle="Represent the transfer of currency from one account to another"
        onClose={onClose}
      >
        <div className="flex justify-center items-center p-8">
          <Spinner />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      title="Create Movement"
      subtitle="Represent the transfer of currency from one account to another"
      onClose={onClose}
    >
      <div className="space-y-4 mb-6">
        {!isValid && errorMessage && <ErrorToast>{errorMessage}</ErrorToast>}
        <div className="space-y-2">
          <label className="block text-sm font-medium">Date</label>
          <DateTimePicker
            value={moment(formData.date).format()}
            onChange={(value) => value && updateField('date', new Date(value))}
            utc
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium">From Account</label>
          <AccountSelector
            accountId={formData.fromAccountId}
            onChange={(value) => updateField('fromAccountId', value)}
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium">To Account</label>
          <AccountSelector
            accountId={formData.toAccountId}
            onChange={(value) => updateField('toAccountId', value)}
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium">Currency</label>
          <Input
            value={formData.currency}
            onChange={(e) => updateField('currency', e.target.value)}
            placeholder="Enter currency symbol"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium">Amount</label>
          <NumberInput
            value={formData.amount}
            onChange={(value) => updateField('amount', value)}
            placeholder="Enter amount"
            isNumericString
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <Button onClick={handleSubmit} loading={isSubmitting} disabled={!isValid || isSubmitting}>
          Submit
        </Button>
        <TextLink onClick={onClose}>Cancel</TextLink>
      </div>
    </Modal>
  );
};

export default React.memo(CreateMovementModal);

export function showCreateMovementModal(props: CreateMovementModalProps) {
  showModal(<CreateMovementModal {...props} />);
}
