import { get } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { UserContext } from '../../../contexts';
import { FilterConfig } from '../types';
import FilterableList from '../FilterableList';

interface Props {
  hideDropdown: () => void;
  setSelectedOption: (option: string) => void;
  width?: string;
  filtersMap: Record<string, FilterConfig>;
  filtersArray: Array<FilterConfig & { key: string }>;
}

function InputMenu({ hideDropdown, setSelectedOption, width, filtersMap, filtersArray }: Props) {
  const { isTokenTaxAdmin } = useContext(UserContext);

  const options = useMemo(
    () =>
      filtersArray
        .filter((option) => isTokenTaxAdmin || !option.adminOnly)
        .map((option) => ({
          label: option.label,
          value: option.key,
          hiddenByDefault: option.disabled,
        })),
    [filtersArray, isTokenTaxAdmin],
  );

  return (
    <div style={{ width }}>
      <FilterableList<string>
        options={options}
        onSelectOption={(value) => {
          const onSelected = get(filtersMap[value as keyof typeof filtersMap], 'onSelected');

          if (onSelected) {
            onSelected();
            hideDropdown();
            return;
          }

          setSelectedOption(value);
        }}
      />
    </div>
  );
}

export default React.memo(InputMenu);
