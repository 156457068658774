import React from 'react';
import Helmet from 'react-helmet';
import metaTags from '../constants/metaTags';
import { useUserContext } from '../contexts';

// Export here for easy access
export const meta = metaTags;

interface Props {
  title?: string;
  description?: string;
  image?: string;
  keywords?: string;
  article?: string;
  isHijacked?: boolean;
  currentUser?: {
    email: string;
    id: string;
  };
}

const PageSeo: React.FC<Props> = ({
  title = meta.HOME.title,
  description = meta.HOME.description,
  image = meta.HOME.image,
  keywords = '',
  article = 'false',
}) => {
  const { user, isHijacked } = useUserContext();
  return (
    <Helmet>
      <title>{isHijacked && user ? `${title} - ${user.email} (${user.id})` : title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:url" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={article} />
      {/* <meta property="og:url" content={`https://tokentax.co${path}`} /> */}
      <meta property="og:site_name" content="TokenTax" />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:width" content="1500" />
      <meta property="og:image:height" content="1125" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@tokentax" />
      <meta name="twitter:creator" content="@tokentax" />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default React.memo(PageSeo);
