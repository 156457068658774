import { gql, useMutation, useQuery } from '@apollo/client';
import {
  ActivateAccountMutation,
  ActivateAccountMutationVariables,
  IsPasswordResetTokenValidQuery,
  IsPasswordResetTokenValidQueryVariables,
} from '../../graphql/types';

const IS_PASSWORD_RESET_TOKEN_VALID = gql`
  query IsPasswordResetTokenValid($token: String!) {
    isPasswordResetTokenValid(token: $token)
  }
`;

export const useIsPasswordResetTokenValid = (token: string) => {
  return useQuery<IsPasswordResetTokenValidQuery, IsPasswordResetTokenValidQueryVariables>(
    IS_PASSWORD_RESET_TOKEN_VALID,
    { variables: { token } },
  );
};

const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccount($password: String!, $token: String!) {
    activateAccount(password: $password, token: $token) {
      user {
        id
      }
      userHash
    }
  }
`;

export const useActivateAccount = () => {
  return useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(ACTIVATE_ACCOUNT);
};
