import { gql } from '@apollo/client';
import { SPEC_ID_MATCH } from './SpecIdMatch';

export const TXN = gql`
  fragment Txn on Txn {
    id
    associatedExchangeAddress
    blocksvcHash
    credentialId
    buyCurrency
    buyTokenId
    buyAddress
    buyNftId
    description
    exchangeId
    exchangeName
    feeCurrency
    feeTokenId
    feeQuantity
    feePrice
    feeAddress
    buyPrice
    sellPrice
    buyQuantity
    sellCurrency
    sellTokenId
    sellAddress
    sellNftId
    sellQuantity
    txnTimestamp
    txnType
    unitPrice
    priceFetchingSide
    usdSpotPrice
    blocksvcHash
    blocksvcMethodId
    blocksvcToAddress
    blocksvcFromAddress
    createdAt
    updatedAt
    isEdited
    isSpam
    reviewed
    editedByReconGuideJob
    reconIdentifier @include(if: $isAdmin)
    bkpVendorId
    integrationId
    toIntegrationId
    bkpVendor {
      id
      bkpIntegrationId
      bkpIntegrationDisplayName
    }
    bkpAccountDebitId
    bkpAccountDebit {
      id
      bkpIntegrationId
      name
    }
    bkpAccountCreditId
    bkpAccountCredit {
      id
      bkpIntegrationId
      name
    }
    credential {
      accountId
      credentialType
      source
      name
      address
      integrationId
    }
    specIdMatchesAsComponent {
      ...SpecIdMatch
    }
    specIdMatchesAsSell {
      ...SpecIdMatch
    }
    credentialId
    accountId
    toAccountId
    hasMovement
  }
  ${SPEC_ID_MATCH}
`;
