import { capitalize, get } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { CredentialSource, CurrencyFilterTypes, TxnType, TxnsQuery } from '../../../graphql/types';
import { BUY_PRICE_ONLY_TYPES, SELL_PRICE_ONLY_TYPES } from '../../../lib/constants';
import { useIntegrationsContext } from '../../../providers/IntegrationsProvider';
import { getCredentialName } from '../../Import/formatters';
import { AddressCell, CellProps, DateCell } from '../../Table';
import AmountCell from '../../Table/Cells/AmountCell';
import CredentialCell from '../../Table/Cells/CredentialCell';
import DropdownCell from '../../Table/Cells/DropdownCell';
import EditableCell from '../../Table/Cells/EditableCell';
import FilterableCellWrapper from '../../Table/Cells/FilterableCellWrapper';
import { AllTransactionsContext } from '../Provider/context';
import { actions } from '../actions';
import ActionsColumn from './ActionsColumn';
import {
  buildCurrencyFilterObject,
  buildCurrencyTickerFilterUrlParam,
  buildDateFilterObject,
  buildDateFilterUrlParam,
  buildMethodIdFilterObject,
  buildReconIdentifierFilterObject,
  buildTxnHashFilterObject,
} from './utils';

type Txn = TxnsQuery['txns']['edges'][0];

const isPropEditable =
  (editProp: string) =>
  ({ txnType }: Txn) => {
    return (
      (txnType === 'income' && editProp === 'sellQuantity') || // https://linear.app/tokentax/issue/TOK-1002/inline-transaction-editing-allow-admins-to-edit-sell-side-for-income
      (txnType === 'spend' && editProp === 'buyQuantity') || // https://linear.app/tokentax/issue/TOK-2041/allow-inline-editing-for-fiat-side-of-spend-txns
      ['feeQuantity', 'feeCurrency'].includes(editProp) ||
      !(
        (editProp?.startsWith('buy') && SELL_PRICE_ONLY_TYPES.includes(txnType)) ||
        (editProp?.startsWith('sell') && BUY_PRICE_ONLY_TYPES.includes(txnType))
      )
    );
  };

export function useColumns() {
  const { onEditCell, specIdSellId } = useContext(AllTransactionsContext);
  const { isTokenTaxAdmin = false } = useContext(UserContext);
  const integrationsContext = useIntegrationsContext();

  const getEditableCellProps = useCallback(
    (editProp: string, uppercase?: boolean) => ({
      isCellEditable: isTokenTaxAdmin ? isPropEditable(editProp) : () => false,
      onEditCell: (id: string, value: string) => {
        return onEditCell?.(id, editProp, uppercase ? value.toUpperCase() : value);
      },
    }),
    [isTokenTaxAdmin, onEditCell],
  );

  const columns = useMemo(
    () => [
      {
        id: 'txnType',
        Header: 'Type',
        className: 'pl-2',
        accessor: (t: Txn) => capitalize(t.txnType || undefined),
        isCellEditable: () => true,
        Cell: React.memo(function DropdownCellWrapper({
          cell,
          row,
          setForceHover,
          rowIsDisabled,
        }: CellProps<Txn>) {
          const { onEditCell, txns } = useContext(AllTransactionsContext);
          return (
            <DropdownCell<Txn, 'txnType'>
              {...{
                rowIndex: txns?.findIndex((t) => t.id === row.original.id),
                cell,
                className: 'px-2',
                options: useMemo(
                  () => ['borrow', 'deposit', 'income', 'migration', 'repay', 'spend', 'trade', 'withdrawal'],
                  [],
                ) as TxnType[],
                onChange: useCallback(
                  async (id, value) => {
                    try {
                      await onEditCell?.(id, 'txnType', value);
                    } catch {
                      // do nothing - the dropdown still displays the previous value
                    }
                  },
                  [onEditCell],
                ),
                disabled: rowIsDisabled,
                setForceHover,
              }}
            />
          );
        }),
        min: '100px',
      } as const,
      {
        id: 'quantity',
        Header: 'Buy Amount',
        accessor: (t: Txn) => t.buyQuantity ?? parseFloat(t.buyQuantity),
        rightAligned: true,
        Cell: AmountCell,
        min: '90px',
        ...getEditableCellProps('buyQuantity'),
      } as const,
      {
        id: 'usdSpotPrice',
        Header: 'Buy Price',
        accessor: (t: Txn) => t.buyPrice ?? parseFloat(t.buyPrice),
        rightAligned: true,
        Cell: AmountCell,
        min: '90px',
        adminOnly: true,
        ...getEditableCellProps('buyPrice'),
      } as const,
      {
        Header: 'Buy Currency',
        id: 'currency',
        accessor: (t: Txn) => t.buyCurrency || '-',
        min: '110px',
        className: 'pl-4',
        Cell: React.memo(function EditableCellWrapper({
          cell,
          onBlur,
          onFocus,
          rowIsDisabled,
          onEditCell,
          row,
        }: CellProps<Txn, string>) {
          return (
            <FilterableCellWrapper
              onFilter={() =>
                actions.setTxnFilterQuery({
                  currency: [
                    buildCurrencyFilterObject(row.original.buyCurrency, CurrencyFilterTypes.Currency),
                  ],
                })
              }
              filterUrl={buildCurrencyTickerFilterUrlParam(row.original.buyCurrency, {
                side: CurrencyFilterTypes.Currency,
                specIdSellId,
              })}
              value={row.original.buyCurrency || undefined}
              rowIsDisabled={rowIsDisabled}
            >
              <EditableCell<Txn, 'buyCurrency'>
                {...{
                  cell,
                  onBlur,
                  onFocus,
                  rowIsDisabled,
                  onEditCell,
                }}
              />
            </FilterableCellWrapper>
          );
        }),
        ...getEditableCellProps('buyCurrency', true),
      } as const,
      {
        id: 'soldQuantity',
        Header: 'Sell Amount',
        accessor: (t: Txn) => t.sellQuantity ?? parseFloat(t.sellQuantity),
        rightAligned: true,
        Cell: AmountCell,
        min: '100px',
        ...getEditableCellProps('sellQuantity'),
      } as const,

      {
        id: 'unitPrice',
        Header: 'Sell Price',
        accessor: (t: Txn) => t.sellPrice ?? parseFloat(t.sellPrice),
        rightAligned: true,
        Cell: AmountCell,
        min: '90px',
        adminOnly: true,
        ...getEditableCellProps('sellPrice'),
      } as const,
      {
        id: 'soldCurrency',
        Header: 'Sell Currency',
        accessor: (t: Txn) => t.sellCurrency || '-',
        className: 'pl-4',
        width: 'minmax(95px, 0.5fr)',
        Cell: React.memo(function EditableCellWrapper({
          cell,
          onBlur,
          onFocus,
          rowIsDisabled,
          onEditCell,
          row,
        }: CellProps<Txn, string>) {
          return (
            <FilterableCellWrapper
              onFilter={useCallback(
                () =>
                  actions.setTxnFilterQuery({
                    currency: [
                      buildCurrencyFilterObject(row.original.sellCurrency, CurrencyFilterTypes.Currency),
                    ],
                  }),
                [row.original.sellCurrency],
              )}
              filterUrl={buildCurrencyTickerFilterUrlParam(row.original.sellCurrency, {
                side: CurrencyFilterTypes.Currency,
                specIdSellId,
              })}
              value={row.original.sellCurrency || undefined}
              rowIsDisabled={rowIsDisabled}
            >
              <EditableCell<Txn, 'sellCurrency'>
                {...{
                  cell,
                  onBlur,
                  onFocus,
                  rowIsDisabled,
                  onEditCell,
                }}
              />
            </FilterableCellWrapper>
          );
        }),
        ...getEditableCellProps('sellCurrency', true),
      } as const,
      {
        id: 'feeQuantity',
        Header: 'Fee',
        accessor: 'feeQuantity',
        rightAligned: true,
        Cell: AmountCell,
        min: '70px',
        ...getEditableCellProps('feeQuantity'),
      } as const,
      {
        id: 'feeCurrency',
        Header: 'Fee Currency',
        accessor: (t: Txn) => t.feeCurrency || '-',
        width: 'minmax(95px, 0.5fr)',
        Cell: React.memo(function EditableCellWrapper({
          cell,
          onBlur,
          onFocus,
          rowIsDisabled,
          onEditCell,
          row,
        }: CellProps<Txn, string>) {
          return (
            <FilterableCellWrapper
              onFilter={() =>
                actions.setTxnFilterQuery({
                  currency: [
                    buildCurrencyFilterObject(
                      row.original.feeCurrency as string,
                      CurrencyFilterTypes.Currency,
                    ),
                  ],
                })
              }
              filterUrl={buildCurrencyTickerFilterUrlParam(row.original.feeCurrency as string, {
                side: CurrencyFilterTypes.Currency,
                specIdSellId,
              })}
              value={row.original.feeCurrency || undefined}
            >
              <EditableCell<Txn, 'feeCurrency'>
                {...{
                  cell,
                  onBlur,
                  onFocus,
                  rowIsDisabled,
                  onEditCell,
                }}
              />
            </FilterableCellWrapper>
          );
        }),
        ...getEditableCellProps('feeCurrency', true),
      } as const,
      {
        id: 'exchangeName',
        Header: 'Location',
        min: '90px',
        accessor: (t: Txn) => t.exchangeName || '-',
      } as const,
      {
        id: 'credentialName',
        Header: 'Credential',
        min: '120px',
        accessor: (t: Txn) =>
          `${
            getCredentialName(
              {
                source: t.credential?.source as CredentialSource,
                credentialType: t.credential?.credentialType as string,
              },
              integrationsContext,
            ) || '-'
          } ${t.credential?.name ? `- ${t.credential.name}` : ''} ${
            t.credential?.address ? `- ${t.credential.address}` : ''
          }`,
        adminOnly: false,
        Cell: React.memo(function CredentialWrapper(cellProps: CellProps<Txn>) {
          const { credential } = cellProps.row.original;
          return <CredentialCell {...credential} />;
        }),
      } as const,
      {
        id: 'txnTimestamp',
        Header: 'Date (UTC)',
        accessor: 'txnTimestamp',
        Cell: React.memo(function DateCellWrapper(cellProps: CellProps<Txn>) {
          const { pathname } = useLocation();
          const isoTimestamp = cellProps.row.original.txnTimestamp;
          const dateString = isoTimestamp.split('T')[0];

          return (
            <DateCell
              {...cellProps}
              filter={{
                url: `${pathname}${buildDateFilterUrlParam(dateString, { specIdSellId })}`,
                name: 'Date',
                onFilter: () => {
                  actions.setTxnFilterQuery({ date: buildDateFilterObject(dateString) });
                },
              }}
            />
          );
        }),
        fullTimestamp: true,
        min: '100px',
        max: '1.5fr',
      } as const,
      {
        id: 'blocksvcHash',
        Header: 'Txn Hash',
        accessor: 'blocksvcHash',
        Cell: React.memo(function AddressWrapper(cellProps: CellProps<Txn>) {
          const { credential, blocksvcHash, exchangeName } = cellProps.row.original;
          const { credentialType } = credential || {};
          const onFilter = useCallback(() => {
            if (!blocksvcHash) return;
            actions.setTxnFilterQuery({
              txnHash: [buildTxnHashFilterObject(blocksvcHash)],
            });
          }, [blocksvcHash]);
          return (
            <AddressCell
              {...cellProps}
              isTxn
              {...{
                credentialType,
                exchangeName: exchangeName ?? undefined,
                onFilter,
                blocksvcHash,
                isTokenTaxAdmin,
                specIdSellId,
              }}
            />
          );
        }),
        min: '120px',
      } as const,
      {
        id: 'blocksvcToAddress',
        Header: 'Contract (To)',
        accessor: 'blocksvcToAddress',
        Cell: React.memo(function AddressWrapper(cellProps: CellProps<Txn>) {
          const { credential, blocksvcToAddress } = cellProps.row.original;
          const { credentialType } = credential || {};
          const onFilter = useCallback(() => {
            if (!blocksvcToAddress) return;
            actions.setTxnFilterQuery({
              toAddress: [buildReconIdentifierFilterObject(blocksvcToAddress)],
            });
          }, [blocksvcToAddress]);
          return (
            <AddressCell
              {...cellProps}
              {...{ credentialType, onFilter, blocksvcToAddress, isTokenTaxAdmin, specIdSellId }}
            />
          );
        }),
        min: '120px',
        sortable: true,
      } as const,
      {
        id: 'blocksvcMethodId',
        Header: 'Method ID',
        accessor: 'blocksvcMethodId',
        Cell: React.memo(function AddressWrapper(cellProps: CellProps<Txn>) {
          const { credential, blocksvcMethodId } = cellProps.row.original;
          const { credentialType } = credential || {};
          const onFilter = useCallback(() => {
            if (!blocksvcMethodId) return;
            actions.setTxnFilterQuery({
              methodId: [buildMethodIdFilterObject(blocksvcMethodId)],
            });
          }, [blocksvcMethodId]);
          return (
            <AddressCell
              {...cellProps}
              isTxn
              {...{ credentialType, onFilter, blocksvcMethodId, isTokenTaxAdmin, specIdSellId }}
            />
          );
        }),
        min: '120px',
      } as const,
      {
        id: 'reconIdentifier',
        Header: 'Identifier',
        accessor: 'reconIdentifier',
        adminOnly: true,
        Cell: React.memo(function AddressWrapper(cellProps: CellProps<Txn>) {
          const { credential, reconIdentifier } = cellProps.row.original;
          const { credentialType } = credential || {};
          const onFilter = useCallback(() => {
            if (!reconIdentifier) return;

            actions.setTxnFilterQuery({
              reconIdentifier: [buildReconIdentifierFilterObject(reconIdentifier)],
            });
          }, [reconIdentifier]);
          return (
            <AddressCell
              {...cellProps}
              isTxn
              {...{ credentialType, onFilter, reconIdentifier, isTokenTaxAdmin, specIdSellId }}
            />
          );
        }),
        min: '120px',
      } as const,
      {
        id: 'updatedAt',
        Header: 'Last Updated',
        accessor: 'updatedAt',
        Cell: DateCell,
        fullTimestamp: true,
        min: '90px',
        max: '1.5fr',
      } as const,

      {
        id: 'bkpVendorName',
        Header: 'Vendor',
        min: '90px',
        sortable: false,
        accessor: (t: Txn) => t.bkpVendor?.bkpIntegrationDisplayName || '-',
        adminOnly: true,
      } as const,
      {
        id: 'bkpAccountDebitName',
        Header: 'Debit Account',
        min: '90px',
        sortable: false,
        accessor: (t: Txn) => t.bkpAccountDebit?.name || '-',
        adminOnly: true,
      } as const,
      {
        id: 'bkpAccountCreditName',
        Header: 'Credit Account',
        min: '90px',
        sortable: false,
        accessor: (t: Txn) => t.bkpAccountCredit?.name || '-',
        adminOnly: true,
      } as const,
      {
        id: 'actions',
        Header: '',
        accessor: 'id',
        Cell: ActionsColumn,
        hideTitle: true,
        sortable: false,
        width: '230px',
      } as const,
    ],
    [getEditableCellProps, integrationsContext, isTokenTaxAdmin, specIdSellId],
  );

  const filteredColumns = useMemo(
    () => columns.filter((column) => !get(column, 'adminOnly') || isTokenTaxAdmin),
    [columns, isTokenTaxAdmin],
  );

  return filteredColumns;
}
