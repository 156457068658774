import React, { useCallback } from 'react';
import Arrow from '../Arrow';
import { Content } from './modal';
import TextLink from '../../TextLink';

interface Props {
  handleClick: (name: Content, title: string) => void;
}

const questions: { name: Content; title: string }[] = [
  {
    name: 'gains',
    title: 'My gains are too high/wrong!',
  },
  {
    name: 'income',
    title: 'Some of my income is missing',
  },
  {
    name: 'report',
    title: 'My tax report is not accurate',
  },
];

function CommonHelpQuestionsTab({ handleClick }: Props) {
  const _handleClick = useCallback(
    (name: Content, title: string) => {
      handleClick(name, title);
    },
    [handleClick],
  );

  return (
    <div className="flex flex-col">
      {questions.map((question) => (
        <TextLink
          key={question.name}
          className={'flex leading-7 py-2 items-center'}
          onClick={() => _handleClick(question.name, question.title)}
        >
          <Arrow dir="right" />
          <span className="ml-3">{question.title}</span>
        </TextLink>
      ))}
    </div>
  );
}

export default React.memo(CommonHelpQuestionsTab);
