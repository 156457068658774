import { gql, useMutation } from '@apollo/client';
import { BatchUpdateTxnsMutation, BatchUpdateTxnsMutationVariables } from '../../../graphql/types';

export const BATCH_UPDATE_TXNS = gql`
  mutation BatchUpdateTxns($txnInput: PartialTxnInput!, $filterQuery: TxnFilterQuery) {
    batchUpdateTxns(txnInput: $txnInput, filterQuery: $filterQuery)
  }
`;

export const useBatchUpdateTxns = () =>
  useMutation<BatchUpdateTxnsMutation, BatchUpdateTxnsMutationVariables>(BATCH_UPDATE_TXNS);
