import Big from 'big.js';
import classNames from 'classnames';
import { chain, isEqual, map, sumBy, takeWhile, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDelinkSpecIds } from '../../../graphql/mutations/delinkSpecIds';
import { formatCurrencyAmount } from '../../../lib/formatters';
import Button from '../../Button';
import Card from '../../Card';
import { showErrorFlash, showSuccessFlash } from '../../Flash';
import { getZindex } from '../../Modal';
import { showConfirmationDialog } from '../../Modal/ConfirmationDialog';
import { formatNumber } from '../../NumberFormat';
import Spinner from '../../Spinner';
import Text, { textVariantClassnames } from '../../Text';
import TextLink from '../../TextLink';
import Tooltip from '../../Tooltip';
import { useAllTransactionsContext } from '../Provider';
import { actions } from '../actions';
import { getSpecIdQuantities } from '../specId';
import { showCloseSpecIdPanelDialog } from './CloseSpecIdPanelDialog';
import SpecIdComponent from './SpecIdComponent';
import { useSaveSpecId } from './mutations';
import { Component } from './types';

const withUsedQuantities = (components: Omit<Component, 'componentQuantity'>[], amountToCover: number) => {
  let remaining = amountToCover;
  return components.map((component) => {
    const { fullAvailableQuantity, quantityUsedInOtherSpecs } = component;
    const availableQuantity = fullAvailableQuantity - quantityUsedInOtherSpecs;
    const usedQuantity = Math.min(remaining, availableQuantity);
    remaining = Math.max(remaining - usedQuantity, 0);
    return {
      ...component,
      componentQuantity: usedQuantity,
    };
  });
};

function SpecIdPanel() {
  const [minified, setMinified] = useState(false);
  const { specIdSellId, specIdTxnResponse, selectedTxns, refetch } = useAllTransactionsContext();
  const { data: specIdTxn, refetch: refetchSpecIdTxn } = specIdTxnResponse ?? {};
  const [componentsInOrder, setComponentsInOrder] = useState<Component[]>([]);
  const [originalComponentIdsInOrder, setOriginalComponentIdsInOrder] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSpecId] = useSaveSpecId();
  const [delinkSpecIds] = useDelinkSpecIds();

  const {
    id: sellId,
    sellQuantity,
    sellCurrency: currency,
    sellPrice,
    feeCurrency,
    feeQuantity,
    txnTimestamp,
    specIdMatchesAsSell,
  } = specIdTxn?.txn ?? {};

  useEffect(() => {
    if (!specIdTxn?.txn || !specIdSellId) return;
    const { specIdMatchesAsSell } = specIdTxn?.txn ?? {};
    const componentsInOrder = chain(specIdMatchesAsSell)
      .orderBy('componentOrder')
      .map(({ componentId, componentQuantity, componentOrder, component }) => {
        return {
          componentId,
          componentQuantity,
          componentOrder,
          buyPrice: component.buyPrice,
          componentAcquisitionDate: component.txnTimestamp,
          ...getSpecIdQuantities({ txn: component, specIdSellId }),
        };
      })
      .value();

    setComponentsInOrder(componentsInOrder);

    const componentIdsInOrder = componentsInOrder.map(({ componentId }) => componentId);
    setOriginalComponentIdsInOrder(componentIdsInOrder);
    actions.markAsLinkedSpecId(componentIdsInOrder);
  }, [specIdSellId, specIdTxn]);

  const contributingComponentIdsInOrder = map(
    componentsInOrder.filter(({ componentQuantity }) => componentQuantity > 0),
    'componentId',
  );

  const hasChanges = specIdMatchesAsSell?.length
    ? !isEqual(originalComponentIdsInOrder, contributingComponentIdsInOrder)
    : contributingComponentIdsInOrder.length > 0;

  const quantitiesSubTotal = sumBy(componentsInOrder, 'componentQuantity');
  let amountToCover = sellQuantity ? parseFloat(sellQuantity) : null;
  const shouldIncludeSellFees = amountToCover && feeQuantity && feeCurrency === currency;

  if (shouldIncludeSellFees) {
    amountToCover! += feeQuantity; // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }

  const moveCard = useCallback(
    (draggedRowIndex: number, hoveredRowIndex: number) => {
      if (!amountToCover) throw new Error("Can't spec transaction with no amount to cover");
      setComponentsInOrder((componentsInOrder) => {
        const arrayWithoutDraggedRow = componentsInOrder.map((val, index) =>
          index === draggedRowIndex ? undefined : val,
        );
        if (hoveredRowIndex > draggedRowIndex) {
          hoveredRowIndex++;
        }
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const componentsWithOutdatedQuantities = [
          ...arrayWithoutDraggedRow.slice(0, hoveredRowIndex!),
          componentsInOrder[draggedRowIndex!],
          ...arrayWithoutDraggedRow.slice(hoveredRowIndex!),
        ].filter(Boolean) as typeof componentsInOrder;

        return withUsedQuantities(componentsWithOutdatedQuantities, amountToCover!);
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      });
    },
    [amountToCover],
  );

  const remainingRaw = amountToCover ? amountToCover - quantitiesSubTotal : null;
  // there can be rounding errors due to limited (IEEE 754) precision
  const remaining = remainingRaw && Math.abs(remainingRaw) < 0.000001 ? 0 : remainingRaw;

  const newCostBasis =
    remaining === 0
      ? sumBy(componentsInOrder, ({ buyPrice, componentQuantity }) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return parseFloat(buyPrice!) * componentQuantity;
        })
      : null;

  const exitAmountCopy = amountToCover ? `Exit ${formatNumber(amountToCover)} ${currency}` : ' ';
  const newGainLoss =
    sellQuantity && sellPrice && newCostBasis
      ? Big(sellQuantity).times(sellPrice).minus(newCostBasis).toNumber()
      : null;

  const saveCostBasis = useCallback(async () => {
    if (!sellId) throw new Error('No sellId');
    try {
      setIsSaving(true);
      const components = takeWhile(componentsInOrder, ({ componentQuantity }) => componentQuantity > 0);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await saveSpecId({
        variables: {
          sellId,
          componentIds: components
            .filter(({ componentQuantity }) => componentQuantity > 0)
            .map(({ componentId }) => componentId),
        },
      });
      showSuccessFlash('New Cost Basis successfully saved');
      await refetchSpecIdTxn?.();
    } catch (e) {
      showErrorFlash('An error occurred while saving the Spec ID');
    } finally {
      setIsSaving(false);
    }
  }, [componentsInOrder, saveSpecId, sellId, refetchSpecIdTxn]);

  const removeComponent = useCallback(
    (id: string) => {
      setComponentsInOrder((componentsInOrder) => {
        if (!amountToCover) throw new Error("Can't spec transaction with no sell quantity");
        const newComponentsInOrder = componentsInOrder.filter(({ componentId }) => componentId !== id);
        return withUsedQuantities(newComponentsInOrder, amountToCover);
      });
    },
    [amountToCover],
  );

  const onClose = useCallback(async () => {
    if (hasChanges) {
      await showCloseSpecIdPanelDialog();
    }
    actions.clearSpecIdTxn();
  }, [hasChanges]);

  const zIndex = useMemo(getZindex, []);

  const [isClearing, setIsClearing] = useState(false);

  if (!specIdSellId) return null;

  return (
    <div
      className={classNames(
        'fixed w-screen px-4 sm:w-fit sm:right-[80px] bottom-0',
        textVariantClassnames.base,
      )}
      style={{ zIndex }}
    >
      <Card
        className="flex flex-col w-full sm:w-[500px] border-b-0 rounded-b-none cursor-default"
        padding="none"
      >
        <div className="flex justify-between items-center w-full p-2 px-3 bg-light-selected-faint dark:bg-dark-selected-faint rounded-t">
          <button onClick={() => setMinified((d) => !d)} className="p-none leading-none">
            <Text variant="muted" className="material-icons text-[20px]">
              {`keyboard_arrow_${minified ? 'up' : 'down'}`}
            </Text>
          </button>
          {!minified ? (
            <button onClick={onClose} className="p-none leading-none">
              <Text variant="muted" className="material-icons text-[20px]">
                close
              </Text>
            </button>
          ) : (
            <Text variant="muted">{exitAmountCopy}</Text>
          )}
        </div>
        {!minified && (
          <>
            <div className="flex justify-between px-4 pt-2 pb-4 bg-light-selected-faint dark:bg-dark-selected-faint">
              <div className="flex flex-col gap-1">
                <div className="flex text-sm font-semibold">
                  {exitAmountCopy}
                  {shouldIncludeSellFees && (
                    <Tooltip icon="question">
                      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
                      This covers the sale of {formatNumber(sellQuantity!)} {currency} plus{' '}
                      {formatNumber(feeQuantity)} {currency} worth of fees
                    </Tooltip>
                  )}
                </div>
                <span>
                  {sellQuantity && sellPrice
                    ? formatCurrencyAmount(Number(sellQuantity) * Number(sellPrice))
                    : '-'}
                </span>
                <Text variant="muted" className="text-sm">
                  Sold on {moment(txnTimestamp).format('MM/DD/YY')}
                </Text>
              </div>
              <div className="flex flex-col gap-1 text-right">
                <span className="text-sm font-semibold">New Gain/Loss</span>
                <span>
                  <Text variant={newGainLoss ? (newGainLoss < 0 ? 'critical' : 'success') : 'muted'}>
                    {newGainLoss ? formatCurrencyAmount(newGainLoss) : 'N/A'}
                  </Text>
                </span>
                <Text variant="muted" className="text-sm">
                  New Cost Basis: {newCostBasis ? formatCurrencyAmount(newCostBasis) : 'N/A'}
                </Text>
              </div>
            </div>
            <div>
              <div
                className={classNames(
                  'border-t border-b',
                  'bg-light-shade dark:bg-dark-shade',
                  'px-4 py-2 flex justify-between items-center text-sm',
                )}
              >
                <span>
                  Amount to use: {remaining === null ? '-' : remaining === 0 ? '0' : formatNumber(remaining)}{' '}
                  {currency}
                </span>
                {componentsInOrder.length > 0 && (
                  <TextLink
                    className="text-sm"
                    loading={isClearing}
                    onClick={async () => {
                      await showConfirmationDialog({
                        title: 'Clear all components?',
                        content: function Content() {
                          return (
                            <div className="mb-6">
                              This will remove all linked components from the Spec ID
                            </div>
                          );
                        },
                        buttonText: 'Clear',
                        cancelButtonText: 'Cancel',
                      });
                      setIsClearing(true);
                      try {
                        await delinkSpecIds({
                          variables: { sellId },
                        });
                        await refetch?.();
                        await refetchSpecIdTxn?.();
                        actions.unmarkAsLinkedSpecId(componentsInOrder.map(({ componentId }) => componentId));
                        setComponentsInOrder([]);
                      } finally {
                        setIsClearing(false);
                      }
                    }}
                  >
                    Clear
                  </TextLink>
                )}
              </div>
              <div className="flex h-[300px] overflow-auto">
                {specIdTxnResponse?.loading ? (
                  <div className="flex flex-grow items-center justify-center">
                    <Spinner className="self-center" />
                  </div>
                ) : componentsInOrder.length === 0 ? (
                  <div className="flex flex-col gap-4 p-[25px]">
                    <Text variant="muted">
                      Select transaction(s) and populate this panel to specify a new cost basis for this sell.
                      Select them in the order you would like them to be calculated.
                    </Text>
                    <Text variant="muted">
                      The all transactions table has been filtered to show only the available options for this
                      sell event. Save or close this panel to clear this filter and return the all
                      transactions view.
                    </Text>
                  </div>
                ) : (
                  <div className="cursor-move flex-grow">
                    {componentsInOrder.map((component, i) => (
                      <SpecIdComponent
                        key={component.componentId}
                        component={component}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        currency={currency!}
                        index={i}
                        moveCard={moveCard}
                        removeComponent={removeComponent}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 border-t border-b bg-light-shade dark:bg-dark-shade p-4">
              <Button
                variant="secondary"
                disabled={selectedTxns.length === 0}
                onClick={() => {
                  if (!amountToCover) throw new Error('amount to cover is null');
                  const newComponents = selectedTxns.map((txn) => {
                    return {
                      componentId: txn.id,
                      buyPrice: txn.buyPrice,
                      componentAcquisitionDate: txn.txnTimestamp,
                      quantityUsedInOtherSpecs: txn.quantityUsedInOtherSpecs!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                      fullAvailableQuantity: txn.fullAvailableQuantity!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                    };
                  });

                  setComponentsInOrder((componentsInOrder) =>
                    withUsedQuantities(
                      uniqBy([...componentsInOrder, ...newComponents], 'componentId'),
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      amountToCover!,
                    ),
                  );
                  actions.selectMany([]);
                  actions.markAsLinkedSpecId(selectedTxns.map(({ id }) => id));
                }}
              >
                Add Transactions
              </Button>
              <Button
                onClick={saveCostBasis}
                fullWidth
                disabled={!hasChanges || (remaining !== null && remaining > 0) || isSaving}
                loading={isSaving}
              >
                Save New Cost Basis
              </Button>
            </div>
          </>
        )}
      </Card>
    </div>
  );
}

export default React.memo(SpecIdPanel);
