import React, { useCallback, useMemo } from 'react';
import Card from '../../Card/';
import Text from '../../Text';
import TextLink from '../../TextLink';
import { SettingsModalProps, showSettingsModal } from './modal';
export { SettingsType } from './modal';

const DEFAULT_EMPTY = 'Set';

const Settings = ({
  id,
  methods,
  onComplete,
  onUpdate,
  settings,
}: Omit<SettingsModalProps, 'onClose' | 'title'>) => {
  const showModalForSection = useCallback(
    (title: string) => {
      showSettingsModal({ title, id, methods, onComplete, onUpdate, settings });
    },
    [id, methods, onComplete, onUpdate, settings],
  );
  const links = useMemo(() => {
    const { homeCountry, baseCurrency, shortTerm, longTerm, costBasisMethod } = settings;

    return [
      {
        title: 'Home country',
        value: homeCountry || DEFAULT_EMPTY,
        onClick: () => showModalForSection('Home country'),
      },
      {
        title: 'Base currency',
        value: baseCurrency || DEFAULT_EMPTY,
        onClick: () => showModalForSection('Base currency'),
      },
      {
        title: 'Short term tax rate',
        value: shortTerm ? `${(shortTerm * 100).toFixed(2)}%` : DEFAULT_EMPTY,
        onClick: () => showModalForSection('Short term'),
      },
      {
        title: 'Long term tax rate',
        value: longTerm ? `${(longTerm * 100).toFixed(2)}%` : DEFAULT_EMPTY,
        onClick: () => showModalForSection('Long term'),
      },
      {
        title: 'Cost basis method',
        value: costBasisMethod?.label || DEFAULT_EMPTY,
        onClick: () => showModalForSection('Cost basis method'),
      },
    ];
  }, [settings, showModalForSection]);

  return (
    <>
      <Card className="flex-1 " sectioned>
        <div className="px-8 pt-4">
          <Text variant="muted">Settings</Text>
        </div>
        <div className="flex flex-col px-8 py-4 gap-3">
          {links.map((link) => (
            <div className="flex justify-between" key={link.title}>
              <p className="text-xl">{link.title}</p>
              <TextLink className="text-xl" onClick={link.onClick}>
                {link.value}
              </TextLink>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};

export default Settings;
