import { getTotalPages } from '../utils/pagination';

interface GetPageCountsProps {
  totalCount: number | undefined;
  filteredCount: number | undefined;
  lastFilteredCount: number | undefined;
  loading: boolean;
  pageSize: number;
}
export const getPageCounts = ({
  totalCount,
  filteredCount,
  lastFilteredCount,
  loading,
  pageSize,
}: GetPageCountsProps) => {
  let visibleCount = filteredCount == null ? totalCount : filteredCount;

  // if we are loading and we have a lastFilteredCount then use that
  if (loading && lastFilteredCount) {
    visibleCount = lastFilteredCount;
  }

  const totalPages = getTotalPages({ totalCount: visibleCount || 0, pageSize });

  return { totalPages, visibleCount };
};
