import classNames from 'classnames';
import { noop } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserContext } from '../../contexts';
import { useInvoice } from '../../graphql/queries';
import { InvoicePaymentMethod, InvoiceQuery } from '../../graphql/types';
import { trackInvoicePaymentSuccessful } from '../../lib/analytics';
import { formatCurrencyAmount, formatDate } from '../../lib/formatters';
import { showErrorFlash } from '../Flash';
import HR from '../HR';
import PortalLayout from '../PortalLayout';
import Spinner from '../Spinner';
import Text from '../Text';
import TextLink from '../TextLink';
import CardPayment from './CardPayment';
import CryptoPayment from './CryptoPayment';
import InvoiceDetails from './InvoiceDetails';
import InvoiceSummary from './InvoiceSummary';
import { usePayInvoice } from './queries';

const NEW_USER_SUCCESS_NOTICE_TIME_WINDOW_SECONDS = 3 * 60; // three minutes
const SHOW_CRYPTO_PAYMENT = false;

interface Props {
  invoice?: InvoiceQuery;
  disabled?: boolean;
}

function InvoicePage(props: Props) {
  const { disabled } = props;
  const { isLoggedIn } = useUserContext();
  const invoiceId = new URLSearchParams(useLocation<{ id: string }>().search).get('id') as string;
  const { loading, data, refetch } = props.invoice
    ? {
        loading: false,
        data: props.invoice,
        refetch: noop,
      }
    : useInvoice({ invoiceId, withProducts: false }); // eslint-disable-line react-hooks/rules-of-hooks
  const [payInvoice] = usePayInvoice();
  const [hideNotice, setHideNotice] = useState(false);

  const onPay = useCallback(
    async (stripeToken: string) => {
      try {
        await payInvoice({
          variables: {
            invoiceId,
            stripeToken,
            method: InvoicePaymentMethod.card,
          },
        });

        data && trackInvoicePaymentSuccessful({ invoice: data.invoice });
        await refetch();
      } catch (e) {
        showErrorFlash('There was an error processing the payment for this invoice. Please try again later.');
        throw e;
      }
    },
    [invoiceId, data, payInvoice, refetch],
  );

  if (loading) {
    return (
      <div className="w-full flex items-center h-96 justify-center">
        <Spinner />
      </div>
    );
  }

  if (!data) {
    return <div>ERROR: invoice not found</div>;
  }

  const { invoice } = data;
  const {
    dueDate,
    currency,
    amountDue,
    amountRemaining,
    paid,
    paidAt,
    voided,
    lines,
    newUser,
    hideDefaultCrypto,
  } = invoice;

  const paymentWasCompletedRecently =
    paid && Date.now() / 1000 - paidAt! < NEW_USER_SUCCESS_NOTICE_TIME_WINDOW_SECONDS; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return (
    <PortalLayout logoClassname="my-12">
      <div
        className="flex flex-col items-center m-auto p-4"
        style={{
          maxWidth: '34rem',
          width: '100%',
        }}
      >
        <div className="w-full p-6 bg-white dark:bg-transparent dark:border dark:border-dark-base rounded">
          {paymentWasCompletedRecently && newUser && !hideNotice && (
            <div className="mb-4 px-4 py-2 rounded bg-light-alert-success dark:bg-dark-alert-success border border-light-alert-success dark:border-dark-alert-success text-light-alert-success dark:text-dark-alert-success flex justify-between">
              Check your email to claim your account
              <div className="cursor-pointer ml-2" onClick={() => setHideNotice(true)}>
                ✕
              </div>
            </div>
          )}

          <div style={{ fontSize: '36px' }}>
            {formatCurrencyAmount((paid ? amountDue : amountRemaining) / 100, { currency })}
            {paid && (
              <span className="rounded border border-light-alert-success dark:border-dark-alert-success text-light-alert-success dark:text-dark-alert-success bg-light-alert-success dark:bg-dark-alert-success text-sm px-2 py-1 align-middle ml-3">
                Paid
              </span>
            )}
            {voided && (
              <span className="rounded border border-red-200 text-red-900 bg-red-100 text-sm px-2 py-1 align-middle ml-3">
                Void
              </span>
            )}
          </div>

          {!(paid || voided) && dueDate && (
            <Text variant="muted" className="mb-1">
              Due {formatDate(dueDate)}
            </Text>
          )}

          <HR className="my-6" />

          <InvoiceSummary invoice={invoice} />

          <HR className="my-6" />
          <InvoiceDetails lines={lines} />

          {paymentWasCompletedRecently && !newUser && (
            <div className="w-full text-center">
              <TextLink to="/home">{isLoggedIn ? 'Go to Dashboard' : 'Go to Login'}</TextLink>
            </div>
          )}
        </div>

        {!(paid || voided) && (
          <div
            className={classNames(
              'w-full mt-4 rounded bg-white dark:bg-transparent dark:border dark:border-dark-base p-6',
              disabled && 'pointer-events-none',
            )}
          >
            <CardPayment onPay={onPay} />
          </div>
        )}

        {!(paid || voided) && !hideDefaultCrypto && SHOW_CRYPTO_PAYMENT && (
          <div className="w-full mt-4 rounded bg-white dark:bg-transparent dark:border dark:border-dark-base p-6">
            <CryptoPayment />
          </div>
        )}

        <div className="mt-8 flex text-white opacity-60">
          <a target="_blank" rel="noreferrer" href="https://tokentax.co/terms">
            Terms
          </a>
          <div className="mx-4">|</div>
          <a target="_blank" rel="noreferrer" href="https://tokentax.co/privacy">
            Privacy
          </a>
        </div>
      </div>
    </PortalLayout>
  );
}

export default React.memo(InvoicePage);
