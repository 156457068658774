import { InvoicePaymentMethod } from '../../graphql/types';

export const formatPaymentMethod = (method?: InvoicePaymentMethod | null) => {
  switch (method) {
    case 'card':
      return 'Credit Card';

    case 'bank':
      return 'Plaid';

    case 'crypto':
      return 'Cryptocurrency';

    default:
      return 'Other';
  }
};
