import { Theme } from '../theme/useTheme';
import tailwindConfig from '../../tailwind.config';

const { textColor } = tailwindConfig.theme.extend;

export default function getStripeElementsStyle(theme: Theme) {
  const text = textColor[`${theme}-base`];
  const placeholder = textColor[`${theme}-muted`];
  const invalid = textColor[`${theme}-alert-critical`];
  return {
    base: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      fontFamily: 'Messina, sans-serif',
      fontSmoothing: 'antialiased',
      borderRadius: '4px',
      color: text,
      '::placeholder': {
        color: placeholder,
      },
    },
    invalid: {
      color: invalid,
    },
  };
}
