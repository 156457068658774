import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { AllCredentialsQuery } from '../types';

export const ALL_CREDENTIALS = gql`
  query AllCredentials($userId: Int, $extended: Boolean!, $credentialsAdmin: Boolean = false) {
    credentials(
      credentialTableInput: { filters: {}, sortBy: updatedAt, sortDirection: desc }
      userId: $userId
    ) {
      edges {
        id
        name
        source
        address
        credentialType
        accountId
        status @include(if: $extended)
        createdAt @include(if: $extended)
        uploads @include(if: $extended) {
          id
        }
        lastRefreshError @include(if: $extended)
        credentials @include(if: $credentialsAdmin)
      }
    }
  }
`;

// consider moving this query's data to a provider
export function useAllCredentials(options: QueryHookOptions<AllCredentialsQuery>) {
  const res = useQuery<AllCredentialsQuery>(ALL_CREDENTIALS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  return { ...res, data: res.data?.credentials.edges };
}
