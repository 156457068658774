import cx from 'classnames';
import React, { useMemo } from 'react';
import ReactSelect, { Props } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from '../../theme/useTheme';
import Label from '../Input/Label';
import { DropdownIndicator } from './components';
import { getVariantStyles } from './styles';
import { OptionType, SelectProps } from './types';

export type { OptionType, Optionable, SelectProps } from './types';

function Select<Value = unknown, creatable extends boolean = false>({
  className,
  label,
  tabIndex,
  creatable,
  title,
  variant = 'default',
  isSearchable = false,
  hasError = false,
  ...props
}: SelectProps<OptionType<Value>>) {
  const labelColor = 'text-light-base dark:text-dark-base';
  const Component = creatable ? CreatableSelect : ReactSelect;

  const { theme } = useTheme();

  return (
    <div className={className} title={title ?? undefined}>
      {label && <Label className={cx(`block mb-2`, labelColor)}>{label}</Label>}

      <Component<OptionType<Value>, false>
        {...(creatable ? { formatCreateLabel: (inputValue) => `"${inputValue}"` } : {})}
        {...props}
        isSearchable={isSearchable}
        isMulti={false}
        classNamePrefix="custom-select"
        tabIndex={tabIndex}
        className="w-full"
        onChange={props.onChange as Props['onChange']}
        styles={useMemo(
          () => ({ ...getVariantStyles<OptionType<Value>>(variant, theme, hasError), ...props.styles }),
          [variant, theme, hasError, props.styles],
        )}
        components={{
          DropdownIndicator,
        }}
      />
    </div>
  );
}

export default React.memo(Select) as unknown as typeof Select;
