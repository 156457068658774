import React, { useState } from 'react';
import { CardCVCElement, CardExpiryElement, CardNumberElement, Elements } from 'react-stripe-elements';

import getStripeElementsStyle from '../../../constants/stripeElementsStyle';
import { useTheme } from '../../../theme/useTheme';
import Text from '../../Text';
import { ErrorToast } from '../../Toast';
import PayButton from './PayButton';

interface Props {
  onPay: (stripeToken: string) => Promise<void>;
}

function CardPayment({ onPay }: Props) {
  const [error, setError] = useState<string>();

  const { theme } = useTheme();
  const stripeElementsStyle = getStripeElementsStyle(theme);

  return (
    <div className="mt-2">
      <div className="text-2xl">Pay with card</div>
      <div className="mt-4 mb-2">
        <Text variant="muted">Card Information</Text>
      </div>
      <Elements>
        <div>
          <div className="border rounded-t border-light-base dark:border-dark-base p-4">
            <CardNumberElement style={stripeElementsStyle} className="align-middle w-full" />
          </div>
          <div>
            <div className="inline-block w-2/4 border border-t-0 border-r-0 rounded-bl border-light-base dark:border-dark-base p-4">
              <CardExpiryElement style={stripeElementsStyle} className="align-middle" />
            </div>
            <div className="inline-block w-2/4 border border-t-0 rounded-br border-light-base dark:border-dark-base p-4">
              <CardCVCElement style={stripeElementsStyle} className="align-middle" />
            </div>
          </div>
          {error && <ErrorToast className="p-2 mt-2">{error}</ErrorToast>}
          <PayButton onPay={onPay} onError={setError} />
        </div>
      </Elements>
    </div>
  );
}

export default React.memo(CardPayment);
