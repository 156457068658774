import { gql, useQuery } from '@apollo/client';
import { FormOptionsQuery } from '../types';

const FORM_OPTIONS_QUERY = gql`
  query FormOptions {
    countries {
      id
      name
    }
    currencies {
      countryId
      code
    }
  }
`;

export const useGetFormLocaleOptions = () => useQuery<FormOptionsQuery>(FORM_OPTIONS_QUERY);
