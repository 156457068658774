import { chain, head } from 'lodash';
import { CURRENT_TAX_YEAR } from '../../constants/taxYears';
import { User } from '../../contexts';
import { EnumTaxMethod, FetchUserQuery, LatestTxnReportsQuery } from '../../graphql/types';
import { OptionType } from '../Select/index';
import { TabType } from '../TabbedCard/index';
import { SettingsType } from './Settings/modal';
import taxDashboardConstants from './constants';

type LatestTxnReportsByYearType = NonNullable<
  LatestTxnReportsQuery['latestTxnReports']
>['latestTxnReportsByYear'][0];
type LatestTxnReportsType = NonNullable<LatestTxnReportsByYearType['reports']>[0];
type ReportTaxDetail = LatestTxnReportsByYearType['taxDetail'];
type MethodType = NonNullable<ReportTaxDetail['taxMethod']>;
type UserTaxDetailType = NonNullable<FetchUserQuery['user']>['taxDetails'][0];

const getYearOptions = (
  reports: LatestTxnReportsByYearType[] | null | undefined,
  suffix: string | undefined = 'Tax Report',
): OptionType<number>[] => {
  return (
    (reports &&
      reports
        .map((rs: LatestTxnReportsByYearType) => ({
          value: rs.year,
          label: `${rs.year} ${suffix}`,
        }))
        .sort((a, b) => (a.value < b.value ? 1 : -1))) ||
    []
  );
};

const getMethodOptions = (
  reports: LatestTxnReportsByYearType[] | null | undefined,
  reportYear: number | undefined,
): TabType[] => {
  const tabs: TabType[] = [];
  const methods = getMethods(reports, reportYear);

  for (const method of methods) {
    const { id, label } = method;
    tabs.push({ key: id, label: label, active: false });
  }

  return tabs;
};

const getMethods = (
  reports: LatestTxnReportsByYearType[] | null | undefined,
  reportYear: number | undefined,
): MethodType[] => {
  const latestTxnReportsForYear = getLatestTxnReportsForYear(reports, reportYear);
  let taxMethods: MethodType[] = [];

  // we try to use method available on reports of possible
  if (latestTxnReportsForYear) {
    latestTxnReportsForYear.forEach((r: LatestTxnReportsType) => {
      taxMethods.push(r.report.taxMethod);
    });
  } else {
    // if no reports found then try to use default methods from country
    const defaultCountryMethods = getDefaultMethodsFromUserCountry(reports, reportYear);

    if (defaultCountryMethods) {
      taxMethods = defaultCountryMethods;
    } else {
      // if no country found for current year then use defaults from constants
      taxMethods = taxDashboardConstants.defaultMethods;
    }
  }

  const orderedTaxMethods: MethodType[] = [];
  // use methodPriority constant to consistently order methods
  taxDashboardConstants.methodPriority.forEach((methodKey: EnumTaxMethod) => {
    taxMethods.forEach((taxMethod: MethodType) => {
      if (taxMethod.id === methodKey) {
        orderedTaxMethods.push(taxMethod);
      }
    });
  });

  return orderedTaxMethods;
};

const getLatestTxnReportsForYear = (
  reports: LatestTxnReportsByYearType[] | null | undefined,
  reportYear: number | undefined,
): LatestTxnReportsType[] | null => {
  //TODO: handle unavailable year
  if (reports) {
    return chain(reports)
      .find((rs: LatestTxnReportsByYearType) => rs.year === (reportYear || CURRENT_TAX_YEAR))
      .get('reports')
      .value();
  }
  return null;
};

const getDefaultMethodsFromUserCountry = (
  reports: LatestTxnReportsByYearType[] | null | undefined,
  reportYear: number | undefined,
): MethodType[] | null => {
  const utdForYear = getUserTaxDetailForYear(reports, reportYear);
  if (utdForYear) {
    return utdForYear.country?.taxMethods || null;
  }

  return null;
};

const getUserTaxDetailForYear = (
  reports: LatestTxnReportsByYearType[] | null | undefined,
  reportYear: number | undefined,
): ReportTaxDetail | null => {
  if (reports) {
    return chain(reports)
      .find((rs: LatestTxnReportsByYearType) => rs.year === (reportYear || CURRENT_TAX_YEAR))
      .get('taxDetail')
      .value();
  }
  return null;
};

const getReport = (
  reports: LatestTxnReportsByYearType[] | null | undefined,
  year: number,
  method: EnumTaxMethod,
): LatestTxnReportsType | null => {
  //TODO: handle unavailable year
  if (reports) {
    return chain(reports)
      .find((rs: LatestTxnReportsByYearType) => rs.year === year)
      .get('reports')
      .find((r) => r.report.taxMethod.id === method)
      .value();
  }
  return null;
};

const getDetail = (
  reports: LatestTxnReportsByYearType[] | null | undefined,
  year: number,
  user?: User,
): ReportTaxDetail | UserTaxDetailType | null | undefined => {
  if (reports) {
    return chain(reports)
      .find((rs: LatestTxnReportsByYearType) => rs.year === year)
      .get('taxDetail')
      .value();
  }

  return head(user?.taxDetails);
};

const getSettings = (
  reports: LatestTxnReportsByYearType[] | null | undefined,
  year: number,
  user?: User,
): SettingsType => {
  const settings: SettingsType = {};
  const detail = getDetail(reports, year, user);

  settings.countryId = detail?.country?.id || 0;
  settings.homeCountry = detail?.country?.name || '';
  settings.baseCurrency = detail?.baseCurrency || '';
  settings.shortTerm = detail?.marginalTaxRate || undefined;
  settings.longTerm = detail?.longTermCapitalGainsRate || undefined;
  settings.costBasisMethod = detail?.taxMethod || undefined;

  return settings;
};

export default {
  getYearOptions,
  getMethodOptions,
  getMethods,
  getLatestTxnReportsForYear,
  getDefaultMethodsFromUserCountry,
  getUserTaxDetailForYear,
  getReport,
  getDetail,
  getSettings,
};
