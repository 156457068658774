import cx from 'classnames';
import { merge } from 'lodash';
import React, { useMemo } from 'react';
import { default as ReactModal } from 'react-modal';
import './modal.scss';

import Spinner from '../Spinner';
import Text, { textVariantClassnames } from '../Text';

export enum ModalWidths {
  FULL = 'w-full',
  XL9 = 'max-w-9xl',
  XL8 = 'max-w-8xl',
  XL7 = 'max-w-7xl',
  XL6 = 'max-w-6xl',
  XL5 = 'max-w-5xl',
  XL4 = 'max-w-4xl',
  XL3 = 'max-w-3xl',
  XL2 = 'max-w-2xl',
  MD = 'max-w-md',
}

export interface ModalProps {
  /** content inside modal */
  children?: React.ReactNode;
  /** additional css classes */
  className?: string;
  /** modal is visible when set to true */
  isOpen?: boolean;
  /** loading state */
  loading?: boolean;
  /**  If true (default), show a close icon in the top-right corner */
  showCloseIcon?: boolean;
  /** callback when react-modal is closed*/
  onClose?: () => void;
  /** header text */
  title?: string | React.ReactNode;
  /** subheader text */
  subtitle?: string | React.ReactNode;
  /* if true, content in the modal will be padded */
  padded?: boolean;
  /* if defined, apply this padding to the title */
  titlePadding?: string;
  /* if true, gives the modal a gray background */
  grayBackground?: boolean;
  /* max width style to use for the modal */
  width?: ModalWidths;
  /* If true, a horizontal line will be drawn below the header */
  showDivider?: boolean;
  /* optional styles to pass to ReactModal */
  style?: ReactModal.Styles;
  /* optional classes to apply to the header */
  headerClassName?: string;
}

// rudimentary mechanism to ensure modals stack on top of each other progressively if needed
let zIndexOffset = 0;
export const getZindex = () => {
  zIndexOffset++;
  return 99999 + zIndexOffset;
};

// needed for screen readers to ignore app when react-modals are open
ReactModal.setAppElement('body');

function Modal({
  children,
  loading,
  isOpen = true,
  showCloseIcon = true,
  onClose,
  subtitle,
  title,
  padded = true,
  titlePadding,
  grayBackground,
  width,
  style = {},
  showDivider = false,
  className,
  headerClassName,
}: ModalProps) {
  const zIndex = useMemo(getZindex, [isOpen]);

  let maxWidthClass = 'max-w-xl';

  if (width) {
    maxWidthClass = width;
  }

  if (!titlePadding) {
    titlePadding = cx('py-4', padded ? 'px-8' : 'px-6');
  }

  return (
    <ReactModal
      bodyOpenClassName={null}
      className={cx(
        maxWidthClass === 'w-full' ? 'w-auto mx-4' : maxWidthClass,
        `relative inset-auto m-auto rounded min-w-2/6 focus:outline-none bg-light-base dark:bg-dark-base`,
        textVariantClassnames.base,
        className,
      )}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={useMemo(
        () =>
          merge(
            {
              overlay: { backgroundColor: 'rgba(0,0,0,0.50)', zIndex },
              content: { marginTop: '10rem', marginBottom: '8rem' },
            },
            style,
          ),
        [style, zIndex],
      )}
    >
      {title && (
        <div className={cx(titlePadding, `flex justify-between flex-col`, showDivider && 'border-b')}>
          <h1 className={cx(headerClassName ?? 'text-2xl', 'mr-10 leading-loose')}>{title}</h1>
          {showCloseIcon && (
            <button onClick={onClose} className="absolute right-4 top-4">
              <span className="material-icons">close</span>
            </button>
          )}
          {subtitle && (
            <Text variant="muted" className="my-2">
              {subtitle}
            </Text>
          )}
        </div>
      )}

      {!title && showCloseIcon && (
        <button onClick={onClose} className="absolute right-4 top-10">
          <span className="material-icons">close</span>
        </button>
      )}

      {loading ? (
        <div className="flex items-center justify-center py-12">
          <Spinner size="md" />
        </div>
      ) : (
        <div
          className={cx(
            'rounded-b',
            padded && 'px-8 pb-8',
            grayBackground && 'bg-light-shade dark:bg-dark-shade',
          )}
        >
          {children}
        </div>
      )}
    </ReactModal>
  );
}

export default React.memo(Modal);
