import { gql, QueryOptions } from '@apollo/client';
import { client } from '../../lib/apollo';
import { UploadDownloadUrlQuery, UploadDownloadUrlQueryVariables } from '../types';

const UPLOAD_DOWNLOAD_URL = gql`
  query UploadDownloadUrl($id: Int!) {
    uploadDownloadUrl(id: $id)
  }
`;

export const getCredentialDownloadUrl = async (
  id: number,
  options?: Pick<QueryOptions<UploadDownloadUrlQuery, UploadDownloadUrlQueryVariables>, 'context'>,
) => {
  const {
    data: { uploadDownloadUrl },
  } = await client.query<UploadDownloadUrlQuery, UploadDownloadUrlQueryVariables>({
    query: UPLOAD_DOWNLOAD_URL,
    variables: { id },
    ...options,
  });
  return uploadDownloadUrl;
};
