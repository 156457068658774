import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { chain } from 'lodash';
import { useMemo } from 'react';
import {
  CredentialsBeingReconciledQuery,
  SpecIdTxnQuery,
  SpecIdTxnQueryVariables,
} from '../../../graphql/types';

export const SPEC_ID_TXN = gql`
  query SpecIdTxn($sellId: String!) {
    txn(txnId: $sellId) {
      id
      sellQuantity
      sellCurrency
      sellPrice
      feeQuantity
      feeCurrency
      feePrice
      txnTimestamp
      blocksvcHash
      specIdMatchesAsSell {
        componentId
        sellId
        componentQuantity
        componentOrder
        component {
          buyCurrency
          buyQuantity
          buyPrice
          txnTimestamp
          feeCurrency
          feeQuantity
          feePrice
          specIdMatchesAsComponent {
            componentId
            sellId
            componentQuantity
            componentOrder
          }
        }
      }
    }
  }
`;

export const useSpecIdTxn = (options: QueryHookOptions<SpecIdTxnQuery, SpecIdTxnQueryVariables>) =>
  useQuery<SpecIdTxnQuery, SpecIdTxnQueryVariables>(SPEC_ID_TXN, options);

export const CREDENTIALS_BEING_RECONCILED = gql`
  query CredentialsBeingReconciled {
    credentialsBeingReconciled {
      id
    }
  }
`;

const CREDENTIALS_BEING_RECONCILED_POLL_INTERVAL_MS = 5000;
export const useCredentialsBeingReconciled = () => {
  const res = useQuery<CredentialsBeingReconciledQuery>(CREDENTIALS_BEING_RECONCILED, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data.credentialsBeingReconciled.length > 0) {
        res.startPolling(CREDENTIALS_BEING_RECONCILED_POLL_INTERVAL_MS);
      } else {
        res.stopPolling();
      }
    },
  });

  return useMemo(() => {
    const credentials = res.data?.credentialsBeingReconciled.map((cred) => cred.id) ?? [];
    return chain(credentials).uniq().sort().value();
  }, [res.data?.credentialsBeingReconciled]);
};
