import { recalculationVar } from '../../variables';
import { taxDashboardState } from './types';
import { initialOptionsSetVar, initialQuerySetVar, methodVar, yearVar } from './vars';

export const taxDashboardCache = {
  taxDashboard: {
    read: (): taxDashboardState => ({
      recalculationState: recalculationVar(),
      method: methodVar(),
      year: yearVar(),
      initialOptionsSet: initialOptionsSetVar(),
      initialQuerySet: initialQuerySetVar(),
    }),
  },
};
