import { TxnType } from '../graphql/types';

const {
  income,
  mining,
  airdrop,
  fork,
  staking,
  borrow,
  deposit,
  gift,
  lost,
  stolen,
  spend,
  repay,
  withdrawal,
  movement,
} = TxnType;

export const stripeKey = process.env.RAZZLE_APP_STRIPE_KEY;
export const STRIPE_DASHBOARD_URI = process.env.STRIPE_DASHBOARD_URI;
export const MANAGED_DIALOGS_ID = 'managed_dialogs_wrapper';
export const BUY_PRICE_ONLY_TYPES = [airdrop, borrow, deposit, fork, income, mining, staking, movement];
export const SELL_PRICE_ONLY_TYPES = [gift, lost, repay, spend, stolen, withdrawal];
export const TURNSTILE_SITE_KEY = process.env.TURNSTILE_SITE_KEY || '0x4AAAAAAAwe2IYpesKiBqOn';
export const CONTENTFUL_SPACE_ID = 't58o7x216tsn';
export const CONTENTFUL_ACCESS_TOKEN = 'JpJ2o4-wejcv9QGQasj4KGbGKUPPoSAh_3ITLW2_b8U';
export const HIJACKED_USER_ID_HEADER_NAME = 'x-hijacked-user-id';

type ChainConfig = {
  chain: string;
  symbol: string;
  explorer: string;
  buildAddressUrl: (hash: string) => string;
  buildTxnUrl: (hash: string, exchangeName?: string) => string;
};

export const BLOCK_SVC_CHAINS = [
  'eth',
  'arbitrum',
  'avalanche',
  'fantom',
  'bsc',
  'matic',
  'optimism',
  'base',
];

const GENERIC_ORDINAL_EXCHANGE_NAME = 'Ordiscan';
const ordinalExchanges = ['Unisat', 'MagicEden'];
const ordinalLocations = [...ordinalExchanges, GENERIC_ORDINAL_EXCHANGE_NAME];

// This object maps credential types to the data necessary to display transactions of that type
const DATA: Record<string, ChainConfig> = {
  arbitrum: {
    chain: 'Arbitrum',
    symbol: 'ETH',
    explorer: 'Arbiscan',
    buildAddressUrl: (hash) => `https://arbiscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://arbiscan.io/tx/${hash}`,
  },
  avalanche: {
    chain: 'Avalanche',
    symbol: 'AVAX',
    explorer: 'SnowScan',
    buildAddressUrl: (hash) => `https://snowscan.xyz/address/${hash}`,
    buildTxnUrl: (hash) => `https://snowscan.xyz/tx/${hash}`,
  },
  bsc: {
    chain: 'BSC',
    symbol: 'BNB',
    explorer: 'BscScan',
    buildAddressUrl: (hash) => `https://bscscan.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://bscscan.com/tx/${hash}`,
  },
  eth: {
    chain: 'Ethereum',
    symbol: 'ETH',
    explorer: 'Etherscan',
    buildAddressUrl: (hash) => `https://etherscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://etherscan.io/tx/${hash}`,
  },
  fantom: {
    chain: 'Fantom',
    symbol: 'FTM',
    explorer: 'FTMscan',
    buildAddressUrl: (hash) => `https://ftmscan.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://ftmscan.com/tx/${hash}`,
  },
  matic: {
    chain: 'Matic',
    symbol: 'MATIC',
    explorer: 'PolygonScan',
    buildAddressUrl: (hash) => `https://polygonscan.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://polygonscan.com/tx/${hash}`,
  },
  ronin: {
    chain: 'Ronin',
    symbol: 'RON',
    explorer: 'RoninChain',
    buildAddressUrl: (hash) => `https://explorer.roninchain.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.roninchain.com/tx/${hash}`,
  },
  xtz: {
    chain: 'Tezos',
    symbol: 'XTZ',
    explorer: 'TzStats',
    buildAddressUrl: (hash) => `https://tzkt.io/${hash}`,
    buildTxnUrl: (hash) => `https://tzkt.io/${hash}`,
  },
  ont: {
    chain: 'Ontology',
    symbol: 'ONT',
    explorer: 'TokenView',
    buildAddressUrl: (hash) => `https://ont.tokenview.com/en/address/${hash}`,
    buildTxnUrl: (hash) => `https://ont.tokenview.com/en/tx/${hash}`,
  },
  'binance-chain': {
    chain: 'Binance Chain',
    symbol: 'BNB',
    explorer: 'Binance Explorer',
    buildAddressUrl: (hash) => `https://explorer.bnbchain.org/txs?address=${hash}`,
    buildTxnUrl: (hash) => `https://explorer.bnbchain.org/tx/${hash}`,
  },
  neo: {
    chain: 'Neo',
    symbol: 'NEO',
    explorer: 'NeoScan',
    buildAddressUrl: (hash) => `https://neoscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://neoscan.io/transaction/${hash}`,
  },
  optimism: {
    chain: 'Optimism',
    symbol: 'ETH',
    explorer: 'Etherscan',
    buildAddressUrl: (hash) => `https://optimistic.etherscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://optimistic.etherscan.io/tx/${hash}`,
  },
  terra: {
    chain: 'Terra',
    symbol: 'LUNA',
    explorer: 'Terra Finder',
    buildAddressUrl: (hash) => `https://finder.terraclassic.community/mainnet/address/${hash}`,
    buildTxnUrl: (hash) => `https://finder.terraclassic.community/mainnet/tx/${hash}`,
  },
  luna2: {
    chain: 'Terra 2.0',
    symbol: 'LUNA',
    explorer: 'Terra Finder',
    buildAddressUrl: (hash) => `https://finder.terra.money/mainnet/address/${hash}`,
    buildTxnUrl: (hash) => `https://finder.terra.money/mainnet/tx/${hash}`,
  },
  algorand: {
    chain: 'Algorand',
    symbol: 'ALGO',
    explorer: 'Pera Algorand Explorer',
    buildAddressUrl: (hash) => `https://explorer.perawallet.app/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.perawallet.app/tx-group/${hash}`,
  },
  osmosis: {
    chain: 'Osmosis',
    symbol: 'OSMO',
    explorer: 'Mintscan',
    buildAddressUrl: (hash) => `https://www.mintscan.io/osmosis/account/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/osmosis/txs/${hash}`,
  },
  cosmos: {
    chain: 'Cosmos',
    symbol: 'ATOM',
    explorer: 'Mintscan',
    buildAddressUrl: (hash) => `https://www.mintscan.io/cosmos/account/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/cosmos/txs/${hash}`,
  },
  harmony: {
    chain: 'Harmony',
    symbol: 'ONE',
    explorer: 'Harmony Explorer',
    buildAddressUrl: (hash) => `https://explorer.harmony.one/address/${hash}`,
    buildTxnUrl: (hash) => {
      const [, txHash] = hash.split('staking-');
      if (txHash) {
        return `https://explorer.harmony.one/staking-tx/${txHash}`;
      }
      return `https://explorer.harmony.one/tx/${hash}`;
    },
  },
  moonriver: {
    chain: 'Moonriver',
    symbol: 'MOVR',
    explorer: 'Moonriver Explorer',
    buildAddressUrl: (hash) => `https://moonriver.moonscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://moonriver.moonscan.io/tx/${hash}`,
  },
  fet: {
    chain: 'Fetch.ai',
    symbol: 'FET',
    explorer: 'Fetch.ai Block Explorer',
    buildAddressUrl: (hash) => `https://explore-fetchhub.fetch.ai/account/${hash}`,
    buildTxnUrl: (hash) => `https://explore-fetchhub.fetch.ai/transactions/${hash}`,
  },
  juno: {
    chain: 'Juno',
    symbol: 'JUNO',
    explorer: 'Juno Explorer',
    buildAddressUrl: (hash) => `https://www.mintscan.io/juno/account/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/juno/txs/${hash}`,
  },
  'gnosis-chain': {
    chain: 'Gnosis Chain',
    symbol: 'XDAI',
    explorer: 'Gnosis Chain',
    buildAddressUrl: (hash) => `https://gnosisscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://gnosisscan.io/tx/${hash}`,
  },
  kusama: {
    chain: 'Kusama',
    symbol: 'KSM',
    explorer: 'Kusama Explorer',
    buildAddressUrl: (hash) => `https://kusama.subscan.io/account/${hash}`,
    buildTxnUrl: (hash) => `https://kusama.subscan.io/extrinsic/${hash}`,
  },
  acala: {
    chain: 'Acala',
    symbol: 'ACA',
    explorer: 'Blockscout/Subscan',
    buildAddressUrl: (hash) => {
      if (hash.startsWith('0x')) return `https://blockscout.acala.network/address/${hash}`;
      return `https://acala.subscan.io/account/${hash}`;
    },
    buildTxnUrl: (hash) => {
      const [, evmHash] = hash.split('evm-');
      if (evmHash) return `https://blockscout.acala.network/tx/${evmHash}`;
      return `https://acala.subscan.io/extrinsic/${hash}`;
    },
  },
  polkadot: {
    chain: 'Polkadot',
    symbol: 'DOT',
    explorer: 'Polkadot Explorer',
    buildAddressUrl: (hash) => `https://polkadot.subscan.io/account/${hash}`,
    buildTxnUrl: (hash) => `https://polkadot.subscan.io/extrinsic/${hash}`,
  },
  evmos: {
    chain: 'EVMOS',
    symbol: 'EVMOS',
    explorer: 'EVMOS Explorer',
    buildAddressUrl: (hash) => `https://www.mintscan.io/evmos/account/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/evmos/txs/${hash}`,
  },
  huahua: {
    chain: 'HUAHUA',
    symbol: 'HUAHUA',
    explorer: 'HUAHUA Explorer',
    buildAddressUrl: (hash) => `https://www.mintscan.io/chihuahua/account/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/chihuahua/txs/${hash}`,
  },
  iotex: {
    chain: 'Iotex',
    symbol: 'IOTX',
    explorer: 'IoTeXScan',
    buildAddressUrl: (hash) => `https://iotexscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://iotexscan.io/tx/${hash}`,
  },
  metis: {
    chain: 'metis',
    symbol: 'MTS',
    explorer: 'Metis Explorer',
    buildAddressUrl: (hash) => `https://explorer.metis.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.metis.io/tx/${hash}`,
  },
  celo: {
    chain: 'celo',
    symbol: 'CELO',
    explorer: 'CELO Explorer',
    buildAddressUrl: (hash) => `https://celoscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://celoscan.io/tx/${hash}`,
  },
  moonbeam: {
    chain: 'moonbeam',
    symbol: 'GLMR',
    explorer: 'Moonbeam Chain Explorer',
    buildAddressUrl: (hash) => `https://moonscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://moonscan.io/tx/${hash}`,
  },
  klaytn: {
    chain: 'klaytn',
    symbol: 'KLAY',
    explorer: 'Klaytnscope',
    buildAddressUrl: (hash) => `https://scope.klaytn.com/account/${hash}`,
    buildTxnUrl: (hash) => `https://scope.klaytn.com/tx/${hash}`,
  },
  aurora: {
    chain: 'aurora',
    symbol: 'AURORA',
    explorer: 'Aurora Explorer',
    buildAddressUrl: (hash) => `https://explorer.mainnet.aurora.dev/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.mainnet.aurora.dev/tx/${hash}`,
  },
  palm: {
    chain: 'palm',
    symbol: 'PALM',
    explorer: 'On Dora',
    buildAddressUrl: (hash) => `https://www.ondora.xyz/network/palm/accounts/${hash}`,
    buildTxnUrl: (hash) => `https://www.ondora.xyz/network/palm/interactions/${hash}`,
  },
  canto: {
    chain: 'canto',
    symbol: 'CANTO',
    explorer: 'OKLINK',
    buildAddressUrl: (hash) => `https://www.oklink.com/canto/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.oklink.com/canto/tx/${hash}`,
  },
  astar: {
    chain: 'astar',
    symbol: 'ASTR',
    explorer: 'Blockscout/Subscan',
    buildAddressUrl: (hash) => {
      if (hash.startsWith('0x')) return `https://astar.blockscout.com/address/${hash}`;
      return `https://astar.subscan.io/account/${hash}`;
    },
    buildTxnUrl: (hash) => {
      const [, evmHash] = hash.split('evm-');
      if (evmHash) return `https://astar.blockscout.com/tx/${evmHash}`;
      return `https://astar.subscan.io/extrinsic/${hash}`;
    },
  },
  boba: {
    chain: 'boba',
    symbol: 'BOBA',
    explorer: 'Bobascan Explorer',
    buildAddressUrl: (hash) => `https://bobascan.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://bobascan.com/tx/${hash}`,
  },
  shiden: {
    chain: 'shiden',
    symbol: 'SDN',
    explorer: 'Shiden Explorer',
    buildAddressUrl: (hash) => `https://blockscout.com/shiden/address/${hash}`,
    buildTxnUrl: (hash) => `https://blockscout.com/shiden/tx/${hash}`,
  },
  rsk: {
    chain: 'rsk',
    symbol: 'RBTC',
    explorer: 'Blockscout',
    buildAddressUrl: (hash) => `https://rootstock.blockscout.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://rootstock.blockscout.com/tx/${hash}`,
  },
  godwoken: {
    chain: 'godwoken',
    symbol: 'pCKB',
    explorer: 'GwScan',
    buildAddressUrl: (hash) => `https://v1.gwscan.com/account/${hash}`,
    buildTxnUrl: (hash) => `https://v1.gwscan.com/tx/${hash}`,
  },
  stacks: {
    chain: 'stacks',
    symbol: 'STX',
    explorer: 'Stacks Explorer',
    buildAddressUrl: (hash) => `https://explorer.stacks.co/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.stacks.co/txid/${hash}`,
  },
  heco: {
    chain: 'heco',
    symbol: 'HT',
    explorer: 'HecoInfo',
    buildAddressUrl: (hash) => `https://www.hecoinfo.com/en-us/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.hecoinfo.com/en-us/tx/${hash}`,
  },
  'arbitrum-nova': {
    chain: 'arbitrum-nova',
    symbol: 'ETH',
    explorer: 'Arbitrum Nova Explorer',
    buildAddressUrl: (hash) => `https://nova.arbiscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://nova.arbiscan.io/tx/${hash}`,
  },
  near: {
    chain: 'near',
    symbol: 'NEAR',
    explorer: 'Nearblocks',
    buildAddressUrl: (hash) => `https://nearblocks.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://nearblocks.io/txns/${hash}`,
  },
  swimmer: {
    chain: 'swimmer',
    symbol: 'TUS',
    explorer: 'Avalanche Subnet Explorer',
    buildAddressUrl: (hash) => `https://subnets.avax.network/swimmer/address/${hash}`,
    buildTxnUrl: (hash) => `https://subnets.avax.network/swimmer/tx/${hash}`,
  },
  chia: {
    chain: 'chia',
    symbol: 'XCH',
    explorer: 'Chia Blockchain Explorer',
    buildAddressUrl: (hash) => `https://xchscan.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://xchscan.com/txns/${hash}`,
  },
  'boba-bnb': {
    chain: 'boba-bnb',
    symbol: 'BOBA',
    explorer: 'Boba BNB Explorer',
    buildAddressUrl: (hash) => `https://blockexplorer.bnb.boba.network/address/${hash}`,
    buildTxnUrl: (hash) => `https://blockexplorer.bnb.boba.network/tx/${hash}`,
  },
  'avalanche-x-chain': {
    chain: 'avalanche-x-chain',
    symbol: 'AVAX',
    explorer: 'Avalanche Explorer',
    buildAddressUrl: (hash) => `https://explorer-xp.avax.network/address/X-${hash}`,
    buildTxnUrl: (hash) => `https://explorer-xp.avax.network/tx/${hash}`,
  },
  cronos: {
    chain: 'cronos',
    symbol: 'CRO',
    explorer: 'Cronos Chain Explorer',
    buildAddressUrl: (hash) => `https://cronoscan.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://cronoscan.com/tx/${hash}`,
  },
  aptos: {
    chain: 'aptos',
    symbol: 'APT',
    explorer: 'AptoScan Explorer',
    buildAddressUrl: (hash) => `https://explorer.aptoslabs.com/account/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.aptoslabs.com/txn/${hash}`,
  },
  bobabeam: {
    chain: 'bobabeam',
    symbol: 'BOBA',
    explorer: 'Bobabeam Boba Explorer',
    buildAddressUrl: (hash) => `https://blockexplorer.bobabeam.boba.network/address/${hash}`,
    buildTxnUrl: (hash) => `https://blockexplorer.bobabeam.boba.network/tx/${hash}`,
  },
  'boba-avalanche': {
    chain: 'boba-avalanhe',
    symbol: 'BOBA',
    explorer: 'Boba Avalanche Explorer',
    buildAddressUrl: (hash) => `https://blockexplorer.avax.boba.network/address/${hash}`,
    buildTxnUrl: (hash) => `https://blockexplorer.avax.boba.network/tx/${hash}`,
  },
  dogechain: {
    chain: 'dogechain',
    symbol: 'DOGE',
    explorer: 'Dogechain Explorer',
    buildAddressUrl: (hash) => `https://explorer.dogechain.dog/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.dogechain.dog/tx/${hash}`,
  },
  'energy-web': {
    chain: 'energy-web',
    symbol: 'EWT',
    explorer: 'Energy Web Chain Explorer',
    buildAddressUrl: (hash) => `https://explorer.energyweb.org/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.energyweb.org/tx/${hash}`,
  },
  zksync: {
    chain: 'zksync',
    symbol: 'ETH',
    explorer: 'zkScan',
    buildAddressUrl: (hash) => `https://zkscan.io/explorer/accounts/${hash}`,
    buildTxnUrl: (hash) => `https://zkscan.io/explorer/transactions/${hash}`,
  },
  'defi-kingdoms': {
    chain: 'defi-kingdoms',
    symbol: 'JEWEL',
    explorer: 'DFK Subnet Explorer',
    buildAddressUrl: (hash) => `https://subnets.avax.network/defi-kingdoms/address/${hash}`,
    buildTxnUrl: (hash) => `https://subnets.avax.network/defi-kingdoms/tx/${hash}`,
  },
  karura: {
    chain: 'karura',
    symbol: 'KAR',
    explorer: 'Karura Chain Explorer',
    buildAddressUrl: (hash) => `https://blockscout.karura.network/address/${hash}`,
    buildTxnUrl: (hash) => `https://blockscout.karura.network/tx/${hash}`,
  },
  chiliz: {
    chain: 'chiliz',
    symbol: 'ETH',
    explorer: 'CHZ Explorer',
    buildAddressUrl: (hash) => `https://explorer.chiliz.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.chiliz.com/tx/${hash}`,
  },
  kava: {
    chain: 'kava',
    symbol: 'KAVA',
    explorer: 'Mintscan',
    buildAddressUrl: (hash) => `https://www.mintscan.io/kava/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/kava/tx/${hash}`,
  },
  milkomeda: {
    chain: 'milkomeda',
    symbol: 'milkADA',
    explorer: 'Milkomeda Explorer',
    buildAddressUrl: (hash) => `https://explorer-mainnet-cardano-evm.c1.milkomeda.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer-mainnet-cardano-evm.c1.milkomeda.com/tx/${hash}`,
  },
  elastos: {
    chain: 'elastos',
    symbol: 'ELA',
    explorer: 'ELA Explorer',
    buildAddressUrl: (hash) => `https://eth.elastos.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://eth.elastos.io/tx/${hash}`,
  },
  callisto: {
    chain: 'callisto',
    symbol: 'CLO',
    explorer: 'CLO Explorer',
    buildAddressUrl: (hash) => `https://explorer.callisto.network/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.callisto.network/tx/${hash}`,
  },
  exosama: {
    chain: 'exosama',
    symbol: 'SAMA',
    explorer: 'Exosama POA Explorer',
    buildAddressUrl: (hash) => `https://explorer.exosama.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.exosama.com/tx/${hash}`,
  },
  techpay: {
    chain: 'techpay',
    symbol: 'TPC',
    explorer: 'TechPay Explorer',
    buildAddressUrl: (hash) => `https://tpcscan.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://tpcscan.com/tx/${hash}`,
  },
  cloudwalk: {
    chain: 'cloudwalk',
    symbol: 'CWN',
    explorer: 'CloudWalk Explorer',
    buildAddressUrl: (hash) => `https://explorer.mainnet.cloudwalk.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.mainnet.cloudwalk.io/tx/${hash}`,
  },
  skale: {
    chain: 'skale',
    symbol: 'SKL',
    explorer: 'SKALE Explorer',
    buildAddressUrl: (hash) => `https://elated-tan-skat.explorer.mainnet.skalenodes.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://elated-tan-skat.explorer.mainnet.skalenodes.com/tx/${hash}`,
  },
  fuse: {
    chain: 'fuse',
    symbol: 'FUSE',
    explorer: 'Fuse Explorer',
    buildAddressUrl: (hash) => `https://explorer.fuse.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.fuse.io/tx/${hash}`,
  },
  flare: {
    chain: 'flare',
    symbol: 'FLR',
    explorer: 'Flare Explorer',
    buildAddressUrl: (hash) => `https://flare-explorer.flare.network/address/${hash}`,
    buildTxnUrl: (hash) => `https://flare-explorer.flare.network/tx/${hash}`,
  },
  syscoin: {
    chain: 'syscoin',
    symbol: 'SYS',
    explorer: 'Syscoin Explorer',
    buildAddressUrl: (hash) => `https://explorer.syscoin.org/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.syscoin.org/tx/${hash}`,
  },
  oasis: {
    chain: 'oasis',
    symbol: 'Rose',
    explorer: 'Emerald Explorer',
    buildAddressUrl: (hash) => `https://explorer.emerald.oasis.dev/address/${hash}`,
    buildTxnUrl: (hash) => {
      const [, consensusTx] = hash.split('consensus-');
      if (consensusTx) {
        return `https://www.oasisscan.com/transactions/${consensusTx}`;
      }
      return `https://explorer.emerald.oasis.dev/tx/${hash}`;
    },
  },
  ada: {
    chain: 'cardano',
    symbol: 'ADA',
    explorer: 'Cardanoscan',
    buildAddressUrl: (hash) => `https://cardanoscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://cardanoscan.io/transaction/${hash}`,
  },
  'milkomeda-a1': {
    chain: 'milkomeda-a1',
    symbol: 'milkALGO',
    explorer: 'A1 Milkomeda Explorer',
    buildAddressUrl: (hash) => `https://explorer-mainnet-algorand-rollup.a1.milkomeda.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer-mainnet-algorand-rollup.a1.milkomeda.com/tx/${hash}`,
  },
  meter: {
    chain: 'meter',
    symbol: 'MTR',
    explorer: 'Meter Explorer',
    buildAddressUrl: (hash) => `https://scan.meter.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://scan.meter.io/tx/${hash}`,
  },
  btc: {
    chain: 'bitcoin',
    symbol: 'BTC',
    explorer: 'Mempool/Ordiscan',
    buildAddressUrl: (hash) => `https://mempool.space/address/${hash}`,
    buildTxnUrl: (hash, exchangeName) => {
      // Legacy support for stale wallets
      const [, inscriptionTx] = hash.split('inscription');
      if (inscriptionTx) return `https://ordiscan.com/tx/${inscriptionTx}`;

      // Divert to Ordiscan if we think this is a BTC Ordinal transaction
      if (exchangeName && ordinalLocations.includes(exchangeName)) return `https://ordiscan.com/tx/${hash}`;

      // Mempool is the preferred default for regular ol' BTC transactions
      return `https://mempool.space/tx/${hash}`;
    },
  },
  sol: {
    chain: 'Solana',
    symbol: 'SOL',
    explorer: 'Solscan',
    buildAddressUrl: (hash) => `https://solscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://solscan.io/tx/${hash}`,
  },
  'sol-new': {
    chain: 'Solana',
    symbol: 'SOL',
    explorer: 'Solscan',
    buildAddressUrl: (hash) => `https://solscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://solscan.io/tx/${hash}`,
  },
  oasys: {
    chain: 'Oasys',
    symbol: 'OAS',
    explorer: 'Oasys Explorer',
    buildAddressUrl: (hash) => `https://scan.oasys.games/address/${hash}`,
    buildTxnUrl: (hash) => `https://scan.oasys.games/tx/${hash}`,
  },
  gather: {
    chain: 'Gather',
    symbol: 'GTH',
    explorer: 'Gather Explorer',
    buildAddressUrl: (hash) => `https://explorer.velas.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.velas.com/tx/${hash}`,
  },
  counterparty: {
    chain: 'Counterparty',
    symbol: 'XCP',
    explorer: 'Counterparty Block Explorer',
    buildAddressUrl: (hash) => `https://xchain.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://xchain.io/tx/${hash}`,
  },
  'zksync-era': {
    chain: 'ZkSync Era',
    symbol: 'ETH',
    explorer: 'zkSync Era Block Explorer',
    buildAddressUrl: (hash) => `https://explorer.zksync.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.zksync.io/tx/${hash}`,
  },
  'polygon-zkevm': {
    chain: 'Polygon zkEVM',
    symbol: 'ETH',
    explorer: 'Polygon zkEVM Blockchain Explorer',
    buildAddressUrl: (hash) => `https://zkevm.polygonscan.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://zkevm.polygonscan.com/tx/${hash}`,
  },
  tron: {
    chain: 'Tron',
    symbol: 'TRX',
    explorer: 'Tron BlockChain Explorer',
    buildAddressUrl: (hash) => `https://tronscan.org/#/address/${hash}`,
    buildTxnUrl: (hash) => `https://tronscan.org/#/transaction/${hash}`,
  },
  bittorrent: {
    chain: 'BitTorrent',
    symbol: 'BTT',
    explorer: 'BitTorrent Chain Explorer',
    buildAddressUrl: (hash) => `https://bttcscan.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://bttcscan.com/tx/${hash}`,
  },
  findora: {
    chain: 'Findora',
    symbol: 'FRA',
    explorer: 'Findora EVM Scan Explorer',
    buildAddressUrl: (hash) => `https://evm.findorascan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://evm.findorascan.io/tx/${hash}`,
  },
  pulsechain: {
    chain: 'Pulsechain',
    symbol: 'PLS',
    explorer: 'PulseChain Explorer',
    buildAddressUrl: (hash) => `https://scan.9mm.pro/address/${hash}`,
    buildTxnUrl: (hash) => `https://scan.9mm.pro/tx/${hash}`,
  },
  telos: {
    chain: 'Telos',
    symbol: 'TLOS',
    explorer: 'Teloscan',
    buildAddressUrl: (hash) => `https://www.teloscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.teloscan.io/tx/${hash}`,
  },
  zora: {
    chain: 'Zora',
    symbol: 'ETH',
    explorer: 'Zora Explorer',
    buildAddressUrl: (hash) => `https://explorer.zora.energy/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.zora.energy/tx/${hash}`,
  },
  'immutable-x': {
    chain: 'Immutable-X',
    symbol: 'IMX',
    explorer: 'Immutascan Explorer',
    buildAddressUrl: (hash) => `https://immutascan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://immutascan.io/tx/${hash}`,
  },
  base: {
    chain: 'Base',
    symbol: 'ETH',
    explorer: 'BaseScan',
    buildAddressUrl: (hash) => `https://basescan.org/address/${hash}`,
    buildTxnUrl: (hash) => `https://basescan.org/tx/${hash}`,
  },
  songbird: {
    chain: 'Songbird',
    symbol: 'SGB',
    explorer: 'Songbird Flare Explorer',
    buildAddressUrl: (hash) => `https://songbird-explorer.flare.network/address/${hash}`,
    buildTxnUrl: (hash) => `https://songbird-explorer.flare.network/tx/${hash}`,
  },
  kcc: {
    chain: 'KCC',
    symbol: 'KCS',
    explorer: 'KCC Explorer',
    buildAddressUrl: (hash) => `https://scan.kcc.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://scan.kcc.io/tx/${hash}`,
  },
  shibarium: {
    chain: 'Shibarium',
    symbol: 'BONE',
    explorer: 'Puppynet Explorer',
    buildAddressUrl: (hash) => `https://www.shibariumscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.shibariumscan.io/tx/${hash}`,
  },
  mantle: {
    chain: 'Mantle',
    symbol: 'MNT',
    explorer: 'Mantle Network Explorer',
    buildAddressUrl: (hash) => `https://explorer.mantle.xyz/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.mantle.xyz/tx/${hash}`,
  },
  linea: {
    chain: 'Linea',
    symbol: 'ETH',
    explorer: 'Linea Ethereum Explorer',
    buildAddressUrl: (hash) => `https://explorer.linea.build/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.linea.build/tx/${hash}`,
  },
  conflux: {
    chain: 'Conflux',
    symbol: 'CFX',
    explorer: 'ConfluxScan',
    buildAddressUrl: (hash) => `https://evm.confluxscan.net/address/${hash}`,
    buildTxnUrl: (hash) => `https://evm.confluxscan.net/tx/${hash}`,
  },
  injective: {
    chain: 'Injective',
    symbol: 'INJ',
    explorer: 'Mintscan',
    buildAddressUrl: (hash) => `https://www.mintscan.io/injective/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/injective/tx/${hash}`,
  },
  scroll: {
    chain: 'Scroll',
    symbol: 'ETH',
    explorer: 'Scroll Explorer',
    buildAddressUrl: (hash) => `https://blockscout.scroll.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://blockscout.scroll.io/tx/${hash}`,
  },
  akash: {
    chain: 'Akash',
    symbol: 'AKT',
    explorer: 'Mintscan',
    buildAddressUrl: (hash) => `https://www.mintscan.io/akash/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/akash/tx/${hash}`,
  },
  celestia: {
    chain: 'Celestia',
    symbol: 'TIA',
    explorer: 'Celestia Explorer',
    buildAddressUrl: (hash) => `https://www.mintscan.io/celestia/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/celestia/tx/${hash}`,
  },
  manta: {
    chain: 'Manta',
    symbol: 'ETH',
    explorer: 'Blockscout',
    buildAddressUrl: (hash) => `https://pacific-explorer.manta.network/address/${hash}`,
    buildTxnUrl: (hash) => `https://pacific-explorer.manta.network/tx/${hash}`,
  },
  picasso: {
    chain: 'Picasso',
    symbol: 'PICA',
    explorer: 'Subscan',
    buildAddressUrl: (hash) => `https://picasso.subscan.io/account/${hash}`,
    buildTxnUrl: (hash) => `https://picasso.subscan.io/extrinsic/${hash}`,
  },
  xlm: {
    chain: 'Stellar',
    symbol: 'XLM',
    explorer: 'Stellar',
    buildAddressUrl: (hash) => `https://stellar.expert/explorer/public/account/${hash}`,
    buildTxnUrl: (hash) => `https://stellar.expert/explorer/public/tx/${hash}`,
  },
  neutron: {
    chain: 'Neutron',
    symbol: 'NTRN',
    explorer: 'Mintscan',
    buildAddressUrl: (hash) => `https://www.mintscan.io/neutron/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/neutron/tx/${hash}`,
  },
  tomb: {
    chain: 'Tomb',
    symbol: 'TOMB',
    explorer: 'Blockscout',
    buildAddressUrl: (hash) => `https://tombscout.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://tombscout.com/tx/${hash}`,
  },
  starknet: {
    chain: 'Starknet',
    symbol: 'STRK',
    explorer: 'Voyager',
    buildAddressUrl: (hash) => `https://voyager.online/contract/${hash}`,
    buildTxnUrl: (hash) => `https://voyager.online/tx/${hash}`,
  },
  blast: {
    chain: 'Blast',
    symbol: 'ETH',
    explorer: 'Blast Explorer',
    buildAddressUrl: (hash) => `https://blastscan.io/address/${hash}`,
    buildTxnUrl: (hash) => `https://blastscan.io/tx/${hash}`,
  },
  frax: {
    chain: 'Frax',
    symbol: 'frxETH',
    explorer: 'Fraxscan',
    buildAddressUrl: (hash) => `https://fraxscan.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://fraxscan.com/tx/${hash}`,
  },
  stride: {
    chain: 'Stride',
    symbol: 'STRD',
    explorer: 'Mintscan',
    buildAddressUrl: (hash) => `https://www.mintscan.io/stride/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/stride/tx/${hash}`,
  },
  sei: {
    chain: 'Sei',
    symbol: 'SEI',
    explorer: 'SeiScan',
    buildAddressUrl: (hash) => `https://www.seiscan.app/pacific-1/accounts/${hash}`,
    buildTxnUrl: (hash) => `https://www.seiscan.app/pacific-1/txs/${hash}`,
  },
  sui: {
    chain: 'Sui',
    symbol: 'SUI',
    explorer: 'SuiVision',
    buildAddressUrl: (hash) => `https://suivision.xyz/account/${hash}`,
    buildTxnUrl: (hash) => `https://suivision.xyz/txblock/${hash}`,
  },
  velas: {
    chain: 'Velas',
    symbol: 'VLX',
    explorer: 'Velas',
    buildAddressUrl: (hash) => `https://explorer.velas.com/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.velas.com/tx/${hash}`,
  },
  archway: {
    chain: 'Archway',
    symbol: 'ARCH',
    explorer: 'Mintscan',
    buildAddressUrl: (hash) => `https://www.mintscan.io/archway/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/archway/tx/${hash}`,
  },
  dymension: {
    chain: 'Dymension',
    symbol: 'DYM',
    explorer: 'Mintscan',
    buildAddressUrl: (hash) => `https://www.mintscan.io/dymension/address/${hash}`,
    buildTxnUrl: (hash) => `https://www.mintscan.io/dymension/tx/${hash}`,
  },
  kujira: {
    chain: 'Kujira',
    symbol: 'KUJI',
    explorer: 'Kujira Finder',
    buildAddressUrl: (hash) => `https://finder.kujira.network/kaiyo-1/address/${hash}`,
    buildTxnUrl: (hash) => `https://finder.kujira.network/kaiyo-1/tx/${hash}`,
  },
  degen: {
    chain: 'Degen',
    symbol: 'DEGEN',
    explorer: 'Degen Explorer',
    buildAddressUrl: (hash) => `https://explorer.degen.tips/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.degen.tips/tx/${hash}`,
  },
  mode: {
    chain: 'Mode',
    symbol: 'ETH',
    explorer: 'Blockscout',
    buildAddressUrl: (hash) => `https://explorer.mode.network/address/${hash}`,
    buildTxnUrl: (hash) => `https://explorer.mode.network/tx/${hash}`,
  },
  dogecoin: {
    chain: 'Dogecoin',
    symbol: 'DOGE',
    explorer: 'Dogecoin Explorer',
    buildAddressUrl: (hash) => `https://dogechain.info/address/${hash}`,
    buildTxnUrl: (hash) => `https://dogechain.info/tx/${hash}`,
  },
  hyperliquid: {
    chain: 'Hyperliquid',
    symbol: 'HYPE',
    explorer: 'Hyperliquid',
    buildAddressUrl: (hash) => `https://app.hyperliquid.xyz/explorer/address/${hash}`,
    buildTxnUrl: (hash) => `https://app.hyperliquid.xyz/explorer/tx/${hash}`,
  },
  sonic: {
    chain: 'Sonic',
    symbol: 'S',
    explorer: 'Sonic Explorer',
    buildAddressUrl: (hash) => `https://sonicscan.org/address/${hash}`,
    buildTxnUrl: (hash) => `https://sonicscan.org/tx/${hash}`,
  },
  xrp: {
    chain: 'XRP',
    symbol: 'XRP',
    explorer: 'XRPSCAN',
    buildAddressUrl: (hash) => `https://livenet.xrpl.org/accounts/${hash}`,
    buildTxnUrl: (hash) => `https://livenet.xrpl.org/transactions/${hash}`,
  },
  abstract: {
    chain: 'Abstract',
    symbol: 'ETH',
    explorer: 'Abstract Explorer',
    buildAddressUrl: (hash) => `https://abscan.org/address/${hash}`,
    buildTxnUrl: (hash) => `https://abscan.org/tx/${hash}`,
  },
};

export function getTokenData(credentialType?: string | null) {
  return DATA[credentialType?.toLowerCase() as keyof typeof DATA] ?? DATA['eth'];
}
