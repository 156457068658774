import classNames from 'classnames';
import React, { useCallback } from 'react';

interface TabProps {
  /** set active to true when the tab when tab is selected */
  active: boolean;
  /** visible label */
  label: string;
  /** callback when tab is clicked */
  onTabChange: (tabKey: string) => void;
  /** unique tab identifier */
  tabKey: string;
  themedBackground?: boolean;
}

function Tab({ active, label, onTabChange, tabKey, themedBackground }: TabProps) {
  return (
    <button
      aria-label={label.concat(' tab')}
      key={tabKey}
      className={classNames(
        active &&
          `bg-light-base dark:bg-dark-base text-light-link dark:text-dark-link border-t border-l border-r`,
        !active && !themedBackground && `hover:text-opacity-75`,
        `rounded-t px-8 pt-2 pb-[8px] relative top-[1px] z-10`,
      )}
      onClick={useCallback(() => {
        onTabChange(tabKey);
      }, [onTabChange, tabKey])}
    >
      {label}
    </button>
  );
}

export default React.memo(Tab);
