import { ReactNode, ReactElement } from 'react';
import {
  TableOptions,
  Column as ReactTableColumn,
  ColumnInstance as ReactTableColumnInstance,
  IdType,
  Cell as ReactTableCell,
  Row as ReactTableRow,
  CellProps as ReactTableCellProps,
  UsePaginationInstanceProps,
  TableInstance as ReactTableInstance,
} from 'react-table';

import { SortDirectionOptions } from '../../graphql/types';

/**
 * Data is passed as props to all table children so they have access to the row type
 */
export interface TableChildrenProps<T extends Record<string, unknown>> {
  data: T[];
  onRowClicked?: (id: string) => void;
  isRowClickable?: (data: T) => boolean;
}

/**
 * Table is either 'controlled' or 'static'
 * different props are required for the different types
 */
export type TableType = 'controlled' | 'static';

/**
 * Base table props
 */
interface TableProps<T extends Record<string, unknown>> extends TableOptions<T> {
  /** table identifier */
  name: string;
  /** currency to display values in */
  baseCurrency?: string;
}

type ExtraColumnProps<T> = {
  min?: string;
  max?: string;
  padding?: string;

  rightAligned?: boolean;
  hideTitle?: boolean;
  sortable?: boolean;
  fullTimestamp?: boolean;
  isIndexColumn?: boolean;
  onEditCell?: (id: string, value: string) => void; // TODO this should be async, but TS is complaining
  isCellEditable?: (t: T) => boolean;
  className?: string;
  dateFormat?: string;
};

/**
 * Single column definition
 * */
export type Column<T extends Record<string, unknown>> = ReactTableColumn<T> &
  ExtraColumnProps<T> & {
    Footer?: string | ((cellProps: CellProps<T>) => ReactNode);
  };

/**
 * Column instance available to components at runtime
 */
export type ColumnInstance<T extends Record<string, unknown>> = ReactTableColumnInstance<T> &
  ExtraColumnProps<T>;

export interface StaticTableProps<T extends Record<string, unknown>>
  extends React.PropsWithChildren<TableProps<T>> {
  columns: Column<T>[];
  defaultSortedId?: IdType<T>;
  defaultSortedDirection?: SortDirectionOptions;
  gridTemplateColumns?: string;
  gridGapSize?: string;
  rowClassName?: string;
  headerClassName?: string;
  minWidth?: string;
  paddingLeft?: string;
  paddingRight?: string;
  blankslate?: ReactElement | null;
  className?: string;
}

/** HANDLER TYPES **/

/**
 * Handles clicks on header cells to update the sort query for a table
 */
export type HandleSort<T extends Record<string, unknown>> = (
  column: ColumnInstance<T>,
  sorted: SortDirectionOptions | null,
) => void;

/**
 * Handles pagination changes to update pagination query
 */
export type HandlePageChange = (desiredPage: number) => void;

/*
 * Cell instance passed to components at runtime
 */
export interface Cell<T extends Record<string, unknown> = Record<string, unknown>, ValueType = unknown>
  extends ReactTableCell<T, ValueType> {
  column: ColumnInstance<T>;
}

/*
 * Row instance pased to components at runtime
 */
export type Row<T extends Record<string, unknown> = Record<string, unknown>> = Omit<
  ReactTableRow<T>,
  'cells' | 'allCells'
> & {
  cells: Array<Cell<T>>;
  allCells: Array<Cell<T>>;
};

export type PaginationInstance<T extends Record<string, unknown> = Record<string, unknown>> = Omit<
  UsePaginationInstanceProps<T>,
  'page'
> & {
  page: Array<Row<T>>;
};

export type CellProps<T extends Record<string, unknown>, V = unknown> = Omit<
  ReactTableCellProps<T>,
  'cell'
> & {
  cell: Cell<T, V>;
  column: Column<T>;
  setForceHover?: (forced: boolean) => void;
  rowIsDisabled?: boolean;
  onEditCell?: (value: string) => Promise<void>;
  onBlur?: () => void;
  onFocus?: () => void;
  children?: ReactNode;
};

export type TableInstance<T extends Record<string, unknown>> = Omit<
  ReactTableInstance<T>,
  'columns' | 'rows'
> & {
  columns: ColumnInstance<T>[];
  rows: Row<T>[];
};
