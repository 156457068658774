import * as Sentry from '@sentry/react';

export function initSentry() {
  const isProductionBuild = process.env.NODE_ENV === 'production'; // https://parceljs.org/features/node-emulation
  if (isProductionBuild) {
    Sentry.init({
      environment: process.env.ENV, // set in netlify.toml
      dsn: 'https://4342e3a4e1f1403eb73ebf6049a38f41@o171839.ingest.sentry.io/4505392385753088',
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', /^https:\/\/api-v2\.tokentax\.co\/graph\/graphql/],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}
