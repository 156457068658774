import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FilterOperatorTypes, MultiSelectIntFilter, MultiSelectStringFilter } from '../../../graphql/types';
import { showErrorFlash } from '../../Flash';
import Input, { InputHandle } from '../../Input';
import Option from '../../Table/Filters/MultiOptionSearch/Option';
import Separator from '../../Table/Filters/MultiOptionSearch/Separator';
import { BaseFilterProps, MultiSearchInputOption } from '../types';

const numberOfOptionsShownByDefault = 4;
const numberOfOptionsShownWhenSearching = Infinity;

export type MultiSearchInputProps<FilterType> = BaseFilterProps<FilterType> & {
  multiSearchOptions: MultiSearchInputOption[];
  defaultNumShown?: number;
};

function MultiSearchInput<
  FilterType extends MultiSelectIntFilter | MultiSelectStringFilter,
  ValueType extends string | number,
>({ multiSearchOptions, onChange, filter, defaultNumShown }: MultiSearchInputProps<FilterType>) {
  const [selectedVals, setSelectedVals] = useState<ValueType[]>(
    (filter?.values as unknown as ValueType[]) || [],
  );
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const inputRef = useRef<InputHandle>(null);

  const options = useMemo(() => multiSearchOptions || [], [multiSearchOptions]);

  const handleSubmit = () => {
    if (!selectedVals.length) return showErrorFlash('Please select at least one value');
    onChange({
      ...(filter || { operator: FilterOperatorTypes.Is }),
      values: selectedVals,
    } as unknown as FilterType);
  };

  const selections = useMemo(
    () => options.filter(({ id }) => selectedVals?.includes(id as ValueType)),
    [options, selectedVals],
  );
  const unselectedOptions = useMemo(
    () => options.filter(({ id }) => !selectedVals?.includes(id as ValueType)),
    [options, selectedVals],
  );
  const searchResults = useMemo(
    () =>
      inputValue
        ? unselectedOptions.filter(({ label, subLabel, metadata }) =>
            [label, subLabel, metadata].some(
              (text) => text?.toLowerCase()?.includes(inputValue?.toLowerCase()),
            ),
          )
        : unselectedOptions,
    [inputValue, unselectedOptions],
  );

  const shownResults = useMemo(
    () =>
      searchResults.slice(
        0,
        !inputValue ? defaultNumShown || numberOfOptionsShownByDefault : numberOfOptionsShownWhenSearching,
      ),
    [defaultNumShown, inputValue, searchResults],
  );

  const toggleSelection = useCallback(
    (id: ValueType) => {
      setSelectedVals(
        selectedVals.includes(id)
          ? selectedVals.filter((selection) => selection !== id)
          : [...selectedVals, id],
      );
    },
    [selectedVals],
  );

  const renderOption = useCallback(
    (option) => (
      <Option<ValueType>
        key={option.id}
        {...option}
        isSelected={selectedVals.includes(option.id)}
        onChange={toggleSelection}
      />
    ),
    [selectedVals, toggleSelection],
  );

  const toggleCollapsed = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  return (
    <div style={{ width: 336 }} className="max-h-[50vh] overflow-auto relative">
      <div className="flex px-3 pt-3 pb-2">
        <Input
          ref={inputRef}
          placeholder="Search..."
          onChange={useCallback((changeEvent: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(changeEvent.currentTarget.value || undefined);
          }, [])}
          padding="p-1"
          style={{ minWidth: '20rem' }}
          value={inputValue || ''}
        />
      </div>
      <div>
        {!isCollapsed && selections?.map(renderOption)}
        <Separator {...{ isCollapsed, selectedCount: selections.length, onClick: toggleCollapsed }} />
        {shownResults.length ? (
          shownResults.map(renderOption)
        ) : (
          <div className="flex h-32 justify-center items-center">No results found</div>
        )}
      </div>
      <button
        onClick={handleSubmit}
        className="sticky bottom-[-1px] text-center w-full p-1 pb-2 border-t border-light-control dark:border-dark-control bg-light-base dark:bg-dark-base text-sm font-semibold rounded-b"
      >
        Apply
      </button>
    </div>
  );
}

export default React.memo(MultiSearchInput) as typeof MultiSearchInput;
