import classNames from 'classnames';
import React, { useMemo } from 'react';
import { MultiSelectIntFilter, MultiSelectStringFilter } from '../../../graphql/types';
import Dropdown, { DropdownHandle } from '../../Dropdown';
import Operator from '../Operator';
import PillBody from '../PillBody';
import { BasePillProps, MultiSearchInputOption } from '../types';
import MultiSearchInput from './MultiSearchInput';

export type MultiSearchPillProps<FilterType> = BasePillProps<FilterType> & {
  multiSearchOptions: MultiSearchInputOption[];
};

function MultiSelectPill<
  FilterType extends MultiSelectIntFilter | MultiSelectStringFilter,
  ValueType extends string | number,
>({ filter, multiSearchOptions, label, operators, onChange, onDelete }: MultiSearchPillProps<FilterType>) {
  const dropdownValuesRef = React.useRef<DropdownHandle>(null);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    dropdownValuesRef.current?.hide();
  }, [filter]);

  const valueString = useMemo(() => {
    const values = filter?.values as ValueType[];
    const options = multiSearchOptions || [];
    const selectedOptions = options.filter(({ id }) => values.includes(id as ValueType));
    return selectedOptions.map(({ label }) => label).join(', ');
  }, [filter, multiSearchOptions]);

  if (!filter) return null;

  return (
    <PillBody label={label} onDelete={onDelete}>
      <div className="border-r border-light-control dark:border-dark-control">
        <Operator {...{ label, operators, filter, onChange }} />
      </div>
      <div
        className={classNames('hover:bg-light-selected-faint dark:hover:bg-dark-selected-faint', {
          'bg-light-selected-faint dark:bg-dark-selected-faint': open,
        })}
      >
        <Dropdown
          isDisabled={false}
          label={valueString}
          ref={dropdownValuesRef}
          onShow={() => setOpen(true)}
          onHide={() => setOpen(false)}
          noStyles
        >
          {open && (
            <MultiSearchInput<FilterType, ValueType> {...{ filter, onChange, label, multiSearchOptions }} />
          )}
        </Dropdown>
      </div>
    </PillBody>
  );
}

export default React.memo(MultiSelectPill) as typeof MultiSelectPill;
