import React from 'react';
import { TxnType } from '../../../graphql/types';
import { isTradeLike } from '../../../utils/txnPrices';
import { txnFieldTooltips } from '../../utils/consts';
import PriceInput from '../PriceInput';
import FormInput from './FormInput';
import { DIFFERENT_VALUES } from './formValues';
import { DisplayedPriceFetchingSide, NO_CHOOSABLE_SIDE } from './getPriceFetchingSide';
import useForm from './useForm';

type Props = {
  txnType: TxnType;
  tradeTxnPriceFetchingSide: DisplayedPriceFetchingSide;
  isDisabled: boolean;
  updatePrices: ReturnType<typeof useForm>['updatePrices'];
  fetchPrices: ReturnType<typeof useForm>['fetchPrices'];
};

function PriceFields({
  txnType,
  tradeTxnPriceFetchingSide,
  isDisabled = false,
  updatePrices,
  fetchPrices,
}: Props) {
  // the API will try to fetch a price for this trade using the addresses on each side
  // even if there is no tokenId on either side, and thus no price fetching side could be determined
  const showSideIndependentPriceFetchCTA =
    [TxnType.trade, TxnType.migration, DIFFERENT_VALUES].includes(txnType) &&
    [NO_CHOOSABLE_SIDE, null].includes(tradeTxnPriceFetchingSide);

  const visibilityMap = Object.fromEntries(
    (['buy', 'sell'] as const).map((side) => {
      const enabled = (() => {
        if (tradeTxnPriceFetchingSide === NO_CHOOSABLE_SIDE) {
          return false;
        }

        if (isTradeLike({ txnType }) && tradeTxnPriceFetchingSide !== `${side}Currency`) {
          return false;
        }

        if (showSideIndependentPriceFetchCTA) {
          return false;
        }

        // if there's a tokenId to be used, we know "fetch price" will normally work.
        // in other cases, we'll show the CTA anyway.
        // the API can try to use the address to fetch the price for a pool, if there is an address to be used.
        // otherwise, the API will simply return a failure response.
        return true;
      })();
      return [side, enabled];
    }),
  ) as Record<'buy' | 'sell', boolean>;

  return (
    <div className="relative grid grid-cols-2 gap-x-4 col-span-2">
      <FormInput
        name="sellPrice"
        txnType={txnType}
        tooltipText={txnFieldTooltips.sellPrice}
        isDisabled={isDisabled}
        render={({ sellPrice: { value, placeholder, setValue, setTouched } }) => (
          <PriceInput
            id="sellPrice"
            onBlur={(currentValue) => {
              setTouched();
              if (!currentValue) return;
              updatePrices('buyPrice');
            }}
            isSideAgnostic={showSideIndependentPriceFetchCTA}
            fetchEnabled={visibilityMap.sell || showSideIndependentPriceFetchCTA}
            onChange={setValue}
            onFetchPrice={() => fetchPrices(showSideIndependentPriceFetchCTA ? null : 'sell')}
            {...{ value, placeholder }}
          />
        )}
      />

      <FormInput
        name="buyPrice"
        txnType={txnType}
        tooltipText={txnFieldTooltips.buyPrice}
        isDisabled={isDisabled}
        render={({ buyPrice: { value, placeholder, setValue, setTouched } }) => (
          <PriceInput
            id="buyPrice"
            onBlur={(currentValue) => {
              setTouched();
              if (!currentValue) return;
              updatePrices('sellPrice');
            }}
            fetchEnabled={visibilityMap.buy}
            onChange={setValue}
            onFetchPrice={() => fetchPrices('buy')}
            {...{ value, placeholder }}
          />
        )}
      />
    </div>
  );
}

export default React.memo(PriceFields);
