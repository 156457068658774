import React from 'react';

import { ChoiceProps } from './types';
import Option from './Option';

export default function UploadDocuments({ send }: ChoiceProps) {
  return (
    <>
      <Option option={{ value: 'YES', label: 'Yes' }} send={send} next={'COMPLETE'} />
      <Option option={{ value: 'NO', label: 'No' }} send={send} next={'COMPLETE'} />
    </>
  );
}
