import { gql, useQuery } from '@apollo/client';
import { INVOICE } from '../fragments';
import { PRODUCTS } from '../fragments/Products';
import { InvoiceQuery, InvoiceQueryVariables } from '../types';

const INVOICE_QUERY = gql`
  query Invoice($invoiceId: String!, $withProducts: Boolean!) {
    invoice(invoiceId: $invoiceId) {
      ...InvoiceFragment
      newUser
      customerEmail
      amountDue
      paidAt
      hideDefaultCrypto
      lines {
        id
        amount
        currency
        description
        unitAmount
        quantity
      }
      paymentMethod
      cryptocurrency
      txnHash

      products @include(if: $withProducts) {
        ...Products
      }
    }
  }
  ${INVOICE}
  ${PRODUCTS}
`;

export const useInvoice = ({ invoiceId, withProducts }: InvoiceQueryVariables) => {
  return useQuery<InvoiceQuery, InvoiceQueryVariables>(INVOICE_QUERY, {
    variables: { invoiceId, withProducts },
  });
};
