import cx from 'classnames';
import React, { ComponentType, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../images/logo-dark.svg';
import LogoDark from '../../images/logo.svg';
import { useTheme } from '../../theme/useTheme';

interface Props {
  children: React.ReactElement;
  logoClassname?: string;
  className?: string;
}

export default function PortalLayout({ children, logoClassname, className }: Props): React.ReactElement {
  const { theme } = useTheme();

  const logo = theme === 'dark' ? LogoDark : Logo;

  return (
    <div className={className}>
      <Link to="/">
        <img src={logo} alt="TokenTax" className={cx(logoClassname, 'mt-14 mx-auto w-[168px]')} />
      </Link>
      {children}
    </div>
  );
}

export function WithPortal<P extends PropsWithChildren<unknown>>(
  Component: ComponentType<P>,
  portalProps?: Omit<Props, 'children'>,
) {
  return function PortalWrapper(props: P) {
    return (
      <PortalLayout {...portalProps}>
        <Component {...props} />
      </PortalLayout>
    );
  };
}
