import { gql, useQuery } from '@apollo/client';
import { IncomeDashboardQuery, IncomeDashboardQueryVariables } from '../../graphql/types';

const INCOME_DASHBOARD_QUERY = gql`
  query IncomeDashboard {
    incomeDashboard {
      edges {
        quantity
        currency
        usdSpotPrice
        unitPrice
        sellQuantity
        sellCurrency
        txnTimestamp
      }
    }
  }
`;

export function useIncomeDashboard() {
  return useQuery<IncomeDashboardQuery, IncomeDashboardQueryVariables>(INCOME_DASHBOARD_QUERY);
}
