import { Props as ReactSelectProps } from 'react-select';

import tailwindConfig from '../../../../tailwind.config';
import { Theme } from '../../../theme/useTheme';

const { textColor, backgroundColor, borderColor } = tailwindConfig.theme.extend;

export function getBaseStyles<Option>(theme: Theme): ReactSelectProps<Option>['styles'] {
  return {
    input: (baseStyles) => ({
      ...baseStyles,
      color: textColor[`${theme}-base`],
      cursor: 'pointer',
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: textColor[`${theme}-base`],
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: textColor[`${theme}-muted`],
    }),
    menuPortal: (baseStyles) => ({
      ...baseStyles,
      zIndex: 50,
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      display: 'none',
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: backgroundColor[`${theme}-base`],
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: borderColor[`${theme}-control`],
      boxShadow: 'none',
      scrollbarWidth: 'none',
      webkitScrollbarWidth: 'none',
    }),
    option: (baseStyles, { isFocused, isSelected, isDisabled }) => {
      const bg = isSelected
        ? backgroundColor[`${theme}-selected`]
        : isFocused
        ? backgroundColor[`${theme}-hover`]
        : 'initial';

      return {
        ...baseStyles,
        color: textColor[`${theme}-base`],
        opacity: isDisabled ? 0.5 : 1,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: bg,
        '&:active': {
          backgroundColor: bg,
        },
      };
    },
  };
}
