import React from 'react';
import { DateRangeFilter } from '../../../graphql/types';
import Filter from '../Filter';
import { FilterProps } from '../types';
import DateRangeInput from './DateRangeInput';
import DateRangePill from './DateRangePill';

export { default as DateRangeInput } from './DateRangeInput';

type Props = FilterProps<DateRangeFilter>;

const EMPTY_FILTER = {}; // avoids unnecessary re-renders by always using the same empty object as props

function DateRangeFilter(props: Props) {
  return (
    <Filter<DateRangeFilter> Input={DateRangeInput} Pill={DateRangePill} props={EMPTY_FILTER} {...props} />
  );
}

export default React.memo(DateRangeFilter);
