import React from 'react';

import { actions } from '../../../AllTransactions/actions';
import TextLink from '../../../TextLink';
import { WarningToast } from '../../../Toast';
import { useEditTxnFormContext } from '../EditTxnFormProvider';
import { useDelinkSpecIds } from '../../../../graphql/mutations/delinkSpecIds';
import { showSuccessFlash } from '../../../Flash';

function SpecIdWarningToast() {
  const { txns, hasSpecIdMatches, setHasSpecIdMatches } = useEditTxnFormContext();
  const [delinkSpecIds, { loading }] = useDelinkSpecIds();

  if (txns.length !== 1 || !hasSpecIdMatches) return null;

  const [txn] = txns;
  const { specIdMatchesAsComponent, specIdMatchesAsSell } = txn;

  return (
    <WarningToast>
      This Exit event has Spec ID settings applied. In order to edit all properties, delink the Spec ID.
      <br />
      <TextLink
        loading={loading}
        onClick={async (evt) => {
          evt.preventDefault(); // do not submit the form

          const variables = specIdMatchesAsSell.length ? { sellId: txn.id } : { componentId: txn.id };
          await delinkSpecIds({ variables });
          await setHasSpecIdMatches(false);
          showSuccessFlash('Spec ID delinked');
        }}
        className="mr-6"
      >
        Delink Spec ID
      </TextLink>
      <TextLink
        onClick={(evt) => {
          evt.preventDefault(); // do not submit the form
          if (specIdMatchesAsSell.length > 0) {
            actions.setSpecIdTxn(txn.id, true);
            return;
          }
          for (const match of specIdMatchesAsComponent) {
            actions.setSpecIdTxn(match.sellId, true);
          }
        }}
      >
        View Spec ID Grouping
      </TextLink>
    </WarningToast>
  );
}

export default React.memo(SpecIdWarningToast);
