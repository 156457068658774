import * as PopperJS from '@popperjs/core';
import { Placement } from '@popperjs/core';
import classNames from 'classnames';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useKeyPressEvent } from 'react-use';

import { useClickOutside } from '../hooks';
import ApplyButton from './ApplyButton';

export interface DropdownProps {
  /** Whether or not the dropdown should be disabled */
  isDisabled?: boolean;
  /** Whether or not the dropdown is in a state that can be cleared */
  isClearable?: boolean;
  /** Show without styling */
  noStyles?: boolean;
  /** Whether or not the dropdown should be open on mouse over */
  openOnMouseEnter?: boolean;
  /** Text to display as the unselected state of the multiselect */
  label: React.ReactNode;
  /** content in the dropdown */
  children: React.ReactNode;
  /** optional 'apply' handler for running a func and closing the dropdown */
  onApply?: () => void;
  /** optional 'clear' handler for clearing the current selection (works only with 'isClearable') */
  onClear?: () => void;
  /* called when the "popper" is shown/hidden */
  onShow?: () => void;
  onHide?: () => void;
  dropdownBody?: React.ReactNode;
  placement?: Placement;
  popperClassname?: string;
  popperOptions?: Omit<Partial<PopperJS.Options>, 'modifiers'>;
}

export interface DropdownHandle {
  hide: () => void;
  show: () => void;
  updatePopper: (() => void) | null;
}

// ideally, create a reusable component that can be used by whichever component currently uses these classes
export const CLASSNAMES = {
  textSize: 'text-sm',
  border: 'border border-light-control dark:border-dark-control rounded',
  background: 'bg-light-base dark:bg-dark-base',
  padding: 'py-1 px-2',
};

export const dropDownClassNames = Object.values(CLASSNAMES).join(' ');

const Dropdown = React.forwardRef<DropdownHandle, DropdownProps>(function Dropdown(
  {
    label,
    isDisabled,
    children,
    isClearable,
    onApply,
    onClear,
    onShow,
    onHide,
    noStyles,
    dropdownBody,
    placement,
    openOnMouseEnter,
    popperClassname,
    popperOptions,
  }: DropdownProps,
  ref,
) {
  const [popperVisible, setPopperVisible] = useState(false);

  const buttonRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const dropdownWrapperRef = useRef<HTMLDivElement | null>(null);

  const { styles, attributes, forceUpdate } = usePopper(buttonRef.current, popperRef.current, {
    placement: placement ?? 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 3.5],
        },
      },
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top', 'bottom'], // by default, all the placements are allowed
        },
      },
    ],
    ...popperOptions,
  });

  const hideDropdown = useCallback(() => {
    setPopperVisible(false);
  }, []);

  const showDropdown = useCallback(() => {
    setPopperVisible(true);
  }, []);

  const onClick = useCallback(() => {
    setPopperVisible(!popperVisible);
    forceUpdate?.();
  }, [setPopperVisible, popperVisible, forceUpdate]);

  // handles closing the menu if the user clicks outside of the component
  useClickOutside(dropdownWrapperRef, hideDropdown);
  useKeyPressEvent('Escape', hideDropdown);

  const handleApply = useCallback(() => {
    hideDropdown();
    onApply?.();
  }, [onApply, hideDropdown]);

  const handleClear = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      onClear?.();
    },
    [onClear],
  );

  useImperativeHandle(ref, () => ({
    hide: hideDropdown,
    show: showDropdown,
    updatePopper: forceUpdate,
  }));

  useEffect(() => {
    if (popperVisible) onShow?.();
    else onHide?.();
  }, [popperVisible, onShow, onHide]);

  function renderDropdownBody() {
    if (dropdownBody)
      return (
        <div className="flex items-center h-full" ref={buttonRef}>
          {dropdownBody}
        </div>
      );

    return (
      <div
        className={classNames(
          isDisabled ? 'cursor-default' : 'cursor-pointer hover:bg-light-hover dark:hover:bg-dark-hover',
          noStyles
            ? 'bg-transparent'
            : [
                CLASSNAMES.border,
                CLASSNAMES.background,
                'inline-flex justify-between items-center  overflow-hidden',
              ],
        )}
        ref={buttonRef}
      >
        <div className={CLASSNAMES.padding}>{label}</div>
        {!isClearable && !noStyles && (
          <div
            className="h-0 w-0 mx-2 border-light-control dark:border-dark-control"
            style={{
              borderLeft: '4px solid transparent',
              borderRight: '4px solid transparent',
              borderTop: '4px solid',
            }}
          />
        )}
        {isClearable && onClear && (
          <div
            className="self-stretch flex items-center border-l border-light-control dark:border-dark-control cursor-pointer"
            onClick={handleClear}
          >
            <i className="material-icons px-2 text-[12px]">close</i>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames(CLASSNAMES.textSize, 'h-full')}
      onClick={!isDisabled ? onClick : undefined}
      ref={dropdownWrapperRef}
      onMouseOver={() => {
        if (openOnMouseEnter && !popperVisible) showDropdown();
      }}
    >
      {renderDropdownBody()}
      <div
        ref={popperRef}
        className={classNames(
          popperClassname,
          'bg-light-base dark:bg-dark-base border',
          `border-light-control dark:border-dark-control`,
          `border-r rounded`,
          popperVisible ? 'visible z-9999' : 'invisible -z-1',
        )}
        style={styles.popper}
        onClick={useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          event.stopPropagation();
        }, [])}
        {...attributes.popper}
      >
        {children}
        {onApply && <ApplyButton onApply={handleApply} />}
      </div>
    </div>
  );
});

export default React.memo(Dropdown);
