import { gql, useMutation, MutationHookOptions } from '@apollo/client';
import { GenerateReportMutation, GenerateReportMutationVariables } from '../types';

export const GENERATE_REPORT = gql`
  mutation GenerateReport($report: ReportCreateInput!) {
    generateReport(report: $report) {
      jobId
      report {
        id
        userId
        txnReportId
        reportType
        extraCols
        margin
        hidden
        url
        taxYear
        status
        failedReason
        method
        createdAt
        updatedAt
        txnReport {
          id
        }
        user {
          id
        }
      }
    }
  }
`;

export const useGenerateReport = (
  options: MutationHookOptions<GenerateReportMutation, GenerateReportMutationVariables>,
) => useMutation<GenerateReportMutation, GenerateReportMutationVariables>(GENERATE_REPORT, options);
