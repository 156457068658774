import { TREATMENTS, TreatmentBucket } from './types';

const { Income, Trade, Borrow, Spend, Ignore, Other, Bridge, Repay, Unknown, Spam } = TreatmentBucket;

export const TREATMENT_BUCKET_ORDER = [
  Income,
  Trade,
  Borrow,
  Spend,
  Repay,
  Other,
  Unknown,
  Bridge,
  Spam,
  Ignore,
];

export const getTreatmentBucket = (treatment: TREATMENTS | null): TreatmentBucket => {
  if (!treatment) return Unknown;

  switch (treatment) {
    case TREATMENTS.ignore:
      return Ignore;
    case TREATMENTS.simpleIncome:
      return Income;
    case TREATMENTS.simpleSwap:
      return Trade;
    case TREATMENTS.simpleSpend:
      return Spend;
    case TREATMENTS.lpEntrance:
      return Trade;
    case TREATMENTS.lpExit:
      return Trade;
    case TREATMENTS.incomeWithExclusion:
      return Income;
    case TREATMENTS.swapWithIncome:
      return Income;
    case TREATMENTS.complexTrade:
      return Trade;
    case TREATMENTS.other:
      return Other;
    case TREATMENTS.bridge:
      return Bridge;
    case TREATMENTS.simpleBorrow:
      return Borrow;
    case TREATMENTS.simpleRepay:
      return Repay;
    case TREATMENTS.unknown:
      return Unknown;
    case TREATMENTS.spam:
      return Spam;
    case TREATMENTS.zeroDollarTrade:
      return Trade;
    case TREATMENTS.incomeWithWithdrawal:
      return Income;
    case TREATMENTS.borrowWithExclusion:
      return Borrow;
    case TREATMENTS.repayWithExclusion:
      return Repay;
    case TREATMENTS.repayWithIncome:
      return Income;
    case TREATMENTS.wrap:
      return Trade;
    case TREATMENTS.weightedLpEntrance:
      return Trade;
    case TREATMENTS.weightedLpExit:
      return Trade;
  }
};
