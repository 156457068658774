import { useReactiveVar } from '@apollo/client';
import { isEqual, isUndefined, mapValues, omitBy, pick } from 'lodash';
import { useEffect, useState } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { useObject } from '../../lib/hooks';
import type { Actions, Query, QueryParamsConf, Vars } from './types';

export type TxnTableQueryParams = ReturnType<typeof useUrlQuery>['query'];

interface Props<SortOptions, Filters, FilteringQueryParams, IdType extends string | number> {
  vars: Vars<SortOptions, Filters, IdType>;
  filterParamsConf: QueryParamsConf;
  actions: Actions<SortOptions, FilteringQueryParams, IdType>;
}

const baseQueryParamsConf = {
  page: NumberParam,
  sortBy: StringParam,
  sortDir: StringParam,
};

/*
 * This helper hook takes care of synchronizing the URL and sorting/pagination/filtering state
 */
export function useUrlQuery<
  SortOptions extends string,
  Filters,
  FilteringQueryParams,
  IdType extends string | number,
>({
  vars: { sortVar, filtersVar, paginationVar },
  filterParamsConf,
  actions,
}: Props<SortOptions, Filters, FilteringQueryParams, IdType>) {
  const filtersState = useReactiveVar(filtersVar);
  const sortState = useReactiveVar(sortVar);
  const { page } = useReactiveVar(paginationVar);
  const queryParamsConf = useObject({ ...baseQueryParamsConf, ...filterParamsConf });
  const [query, setQuery] = useQueryParams(queryParamsConf);
  const [initialStateWasSet, setInitialStateWasSet] = useState(false);
  const [initialQueryWasSet, setInitialQueryWasSet] = useState(false);

  // set local state if the query changes (history.back/forward)
  useEffect(() => {
    // only load the query if it's not empty. on first page load it may be an empty object.
    // this makes sure we don't override the default state with it
    if (!isEqual(omitBy(query, isUndefined), {})) {
      actions.setStateFromQuery(query as Query<SortOptions, FilteringQueryParams>);
    }
    setInitialStateWasSet(true);
  }, [query, actions]);

  useEffect(() => {
    if (!initialStateWasSet) return;

    const allProps = { ...sortState, page, ...filtersState };
    const baseProps = pick(allProps, Object.keys(queryParamsConf));
    const emptyMap = mapValues(queryParamsConf, () => undefined);
    const newQueryState = { ...emptyMap, ...baseProps };

    if (initialQueryWasSet && isEqual(query, { ...query, ...newQueryState })) {
      return;
    }

    setQuery(newQueryState, !initialQueryWasSet ? 'replaceIn' : undefined);
    setInitialQueryWasSet(true);

    // DO NOT add `query` to the deps array or this will cause an infinite loop such as:
    // hit back/forward button -> query changes
    // this effect is run as `query` has changed. the filters state has been updated by the state-updating effect, but it's not reflected in the closure of this effect
    // so this effect finds the new query state and an old filters state. it interprets this as a change in the filters state and sets the query right back to its previous value, resulting in a loop.
  }, [sortState, page, filtersState, initialStateWasSet, initialQueryWasSet]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      setInitialStateWasSet(false);
      setInitialQueryWasSet(false);
    };
  }, []);

  return { query, setQuery };
}
