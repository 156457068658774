import React from 'react';
import { MultiSelectIntFilter, MultiSelectStringFilter } from '../../../graphql/types';
import Filter from '../Filter';
import { FilterProps } from '../types';
import MultiSearchInput from './MultiSearchInput';
import MultiSearchPill, { MultiSearchPillProps } from './MultiSearchPill';

type ExtraProps<FilterType extends MultiSelectIntFilter | MultiSelectStringFilter> = Pick<
  MultiSearchPillProps<FilterType>,
  'multiSearchOptions'
> & { defaultNumShown?: number };
type Props<FilterType extends MultiSelectIntFilter | MultiSelectStringFilter> = ExtraProps<FilterType> &
  FilterProps<FilterType>;

export { default as MultiSearchInput } from './MultiSearchInput';

function MultiSearchFilter<FilterType extends MultiSelectIntFilter | MultiSelectStringFilter>(
  props: Props<FilterType>,
) {
  const { multiSearchOptions, defaultNumShown, ...rest } = props;

  return (
    <Filter<FilterType, ExtraProps<FilterType>>
      Input={MultiSearchInput}
      Pill={MultiSearchPill}
      props={{ multiSearchOptions, defaultNumShown }}
      {...rest}
    />
  );
}

export default React.memo(MultiSearchFilter) as typeof MultiSearchFilter;
