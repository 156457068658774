import { useApolloClient } from '@apollo/client';
import cx from 'classnames';
import { Form, useFormikContext } from 'formik';
import { isUndefined, uniq } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { usePrevious } from 'react-use';
import { UserContext, useUserContext } from '../../../contexts';
import { TokensContext } from '../../../contexts/TokensContext';
import { useAllCredentials } from '../../../graphql/queries';
import { BkpAccountsVendorsQuery, PriceFetchingSide, TxnFragment, TxnType } from '../../../graphql/types';
import { BUY_PRICE_ONLY_TYPES, SELL_PRICE_ONLY_TYPES } from '../../../lib/constants';
import { ROUTES } from '../../../routes';
import { isTradeLike } from '../../../utils/txnPrices';
import AccountSelector from '../../AccountSelector';
import Button from '../../Button';
import DateTimePicker from '../../DateTimePicker';
import Input, { CheckboxInput, NumberInput } from '../../Input';
import Select, { OptionType } from '../../Select';
import Spinner from '../../Spinner';
import TextLink, { textLinkVariantClassnames } from '../../TextLink';
import { ToastType, WarningToast } from '../../Toast';
import TokenSelector from '../../TokenSelector';
import { txnFieldTooltips } from '../../utils/consts';
import PriceInput from '../PriceInput';
import { useEditTxnFormContext } from './EditTxnFormProvider';
import FormInput from './FormInput';
import { DIFFERENT_VALUES } from './formValues';
import { getPriceFetchingSide, NO_CHOOSABLE_SIDE } from './getPriceFetchingSide';
import { getDefaultTokenIdForSymbol } from './helpers';
import PriceFields from './PriceFields';
import PriceValidationWarning from './PriceValidationWarning';
import { fetchBkpAccountsVendors } from './queries';
import { BkpAccountOption, sortAccounts } from './Quickbooks';
import SpecIdWarningToast from './SpecIdWarningToast';
import TxnTypeSelect from './TxnTypeSelect';
import { Values } from './types';
import useForm from './useForm';
import UseForPriceFetching from './UseForPriceFetching';

interface SubmitProps {
  values: TxnFragment[];
  formValues: Values;
}

export interface FormProps {
  txns: TxnFragment[];
  updating: boolean;
  setToast: (type: ToastType, message: string) => void;
  onSubmit: (props: SubmitProps) => void;
  onDelete?: () => void;
  initialValues: Values;
  showDeleteButton?: boolean;
  saveTxnValues: (props: { values: TxnFragment[]; onError?: () => void }) => Promise<void>;
}

function EditTxnForm({
  txns,
  initialValues,
  updating,
  setToast,
  onSubmit,
  onDelete,
  showDeleteButton = true,
  saveTxnValues,
}: FormProps) {
  const client = useApolloClient();
  const { hasSpecIdMatches, multiPageBatch } = useEditTxnFormContext();
  const { user } = useUserContext();

  const { values, setFieldValue, isValid } = useFormikContext<Values>();

  // TODO: instead of fetching all credentials, we should consider
  // modifying the `credential` in the `Txn` fragment to include the `accountId`
  // https://github.com/TokenTax/client/pull/2544#pullrequestreview-2556666735
  const { data: allCreds } = useAllCredentials({
    variables: {
      extended: false,
    },
  });

  const tokensContext = useContext(TokensContext);
  const tradeTxnPriceFetchingSide = getPriceFetchingSide(values, tokensContext);

  const { txnType, buyPrice, sellPrice } = values;

  const { updatePrices, fetchPrices, txnsValues, hasChangedAnyValue } = useForm({
    initialValues,
    txns,
    setToast,
    saveTxnValues,
  });

  const { isTokenTaxAdmin } = useContext(UserContext);
  const { ref: bottomBarRef, inView } = useInView({ threshold: 1 });

  const [sideBeingSwitchedTo, setSideBeingSwitchedTo] = useState<'buy' | 'sell' | null>(null);

  const previousSideBeingSwitchedTo = usePrevious(sideBeingSwitchedTo);

  useEffect(() => {
    if (!sideBeingSwitchedTo || sideBeingSwitchedTo === previousSideBeingSwitchedTo) {
      return;
    }

    fetchPrices(sideBeingSwitchedTo, true)
      .then(() => setFieldValue('priceFetchingSide', sideBeingSwitchedTo))
      .finally(() => {
        setSideBeingSwitchedTo(null);
      });
  }, [fetchPrices, previousSideBeingSwitchedTo, setFieldValue, sideBeingSwitchedTo]);

  (['buy', 'sell', 'fee'] as const).forEach((side) => {
    const propName = `${side}TokenId` as `${'buy' | 'sell' | 'fee'}TokenId`;
    const allValues = txnsValues.map((txn) => txn[propName]);
    const distinctValues = uniq(allValues);
    const value = distinctValues.length === 1 ? distinctValues[0] : null;
    const previousValue = usePrevious(value); // eslint-disable-line react-hooks/rules-of-hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (previousValue === undefined || !value || value === previousValue) {
        return;
      }

      // fetch price whenever a tokenId changes
      fetchPrices(side, true);
    }, [value, previousValue, side]);
  });

  const [bkpAccounts, setBkpAccounts] = useState<OptionType<BkpAccountsVendorsQuery['bkpAccounts'][0]>[]>([]);
  const [bkpVendors, setBkpVendors] = useState<OptionType<BkpAccountsVendorsQuery['bkpVendors'][0]>[]>([]);

  useEffect(() => {
    (async () => {
      const { data, loading } = await fetchBkpAccountsVendors(client);
      if (!loading && data) {
        const { bkpAccounts, bkpVendors } = data;

        const accounts = sortAccounts(bkpAccounts).map((account) => ({
          value: account,
          label: account.name || 'unknown',
        }));
        setBkpAccounts(accounts);
        setBkpVendors(
          bkpVendors.map((vendor) => ({
            value: vendor,
            label: vendor.bkpIntegrationDisplayName || vendor.name || 'unknown',
          })),
        );
      }
    })();
  }, [client]);

  // Movement edit logic
  useEffect(() => {
    if (values.txnType === initialValues.txnType) return;

    if (values.txnType === TxnType.movement) {
      const parentCredential = allCreds?.find((cred) => cred.id === values.credentialId);
      const parentCredentialAccountId = parentCredential?.accountId;

      // For cases where a user edits from a "sell side" txn to a movement,
      // we want to prepopulate the form fields with the "sell" values
      if (SELL_PRICE_ONLY_TYPES.includes(initialValues.txnType)) {
        if (initialValues.sellCurrency) setFieldValue('buyCurrency', initialValues.sellCurrency);
        if (initialValues.sellQuantity) setFieldValue('buyQuantity', initialValues.sellQuantity);
        if (initialValues.sellPrice) setFieldValue('buyPrice', initialValues.sellPrice);

        setFieldValue('accountId', initialValues.accountId || parentCredentialAccountId);
        setFieldValue('toAccountId', null);
      } else {
        // Assume "inbound" directionality when starting point is a buy side txn or something
        // like a trade, which doesn't really make sense, but we should support regardless
        setFieldValue('toAccountId', initialValues.accountId || parentCredentialAccountId);
        setFieldValue('accountId', null);
      }

      // Movements should never use "sell side" fields since
      // directionality is represented by the "account" fields
      setFieldValue('sellCurrency', '');
      setFieldValue('sellQuantity', '');
      setFieldValue('sellPrice', '');
    }
  }, [
    values.txnType,
    values.credentialId,
    initialValues.txnType,
    initialValues.sellCurrency,
    initialValues.sellQuantity,
    initialValues.sellPrice,
    initialValues.accountId,
    allCreds,
    setFieldValue,
  ]);

  const hasMovementTxn = txns.some((txn) => txn.hasMovement);

  return (
    <Form>
      <div className="mx-8">
        <PriceValidationWarning txns={txnsValues} />
      </div>

      {hasMovementTxn && (
        <WarningToast className="mx-8">
          Transaction may be edited on the <TextLink to={ROUTES.movements}>movements overview page</TextLink>.
        </WarningToast>
      )}

      <div className="grid grid-cols-2 gap-4 px-8 mb-8">
        <FormInput
          className="pb-4"
          name="txnTimestamp"
          txnType={txnType}
          tooltipText={txnFieldTooltips.txnTimestamp}
          disableFocusOnClick
          isDisabled={hasMovementTxn}
          render={({ txnTimestamp: { value, placeholder, setValue } }) => (
            <DateTimePicker
              id="txnTimestamp"
              onChange={setValue}
              utc
              value={value}
              placeholder={txns.length > 1 ? placeholder : 'Choose a date and time'}
            />
          )}
        />

        <FormInput
          className="pb-4"
          name="txnType"
          txnType={txnType}
          tooltipText={txnFieldTooltips.txnType}
          useAriaLabel
          isDisabled={hasMovementTxn}
          render={({ txnType: { value, placeholder, setValue, setTouched } }) => (
            <TxnTypeSelect
              onChange={setValue}
              onBlur={setTouched}
              omitOptions={[TxnType.staking, TxnType.movement]} // Movement is not deprecated like Staking, but it should not be accessible from this form.
              {...{ value, placeholder }}
            />
          )}
        />

        <FormInput
          name="sellCurrency"
          txnType={txnType}
          tooltipText={txnFieldTooltips.sellCurrency}
          isDisabled={hasMovementTxn}
          renderSideLabel={({ sellCurrency, buyCurrency, sellTokenId }) => {
            if (
              multiPageBatch ||
              !tradeTxnPriceFetchingSide ||
              tradeTxnPriceFetchingSide === 'sellCurrency' ||
              tradeTxnPriceFetchingSide === NO_CHOOSABLE_SIDE ||
              [buyCurrency.originalValue, sellCurrency.originalValue].some((curr) =>
                ['USD', DIFFERENT_VALUES].includes(curr as string),
              ) ||
              !(sellTokenId.value || getDefaultTokenIdForSymbol(sellCurrency.value, tokensContext))
            ) {
              return null;
            }

            return (
              <div
                className={cx('cursor-pointer inline-block text-sm ml-2', textLinkVariantClassnames.primary)}
                data-testid="UseForPriceFetching-sell"
                onClick={() => {
                  // this causes an effect to run that updates the priceFetchingSide and re-fetches prices
                  setSideBeingSwitchedTo('sell');
                }}
              >
                Use for price lookups
                {sideBeingSwitchedTo === 'sell' && <Spinner className="ml-1.5" size="sm" />}
              </div>
            );
          }}
          render={({ sellTokenId, sellCurrency, sellAddress }) => (
            <>
              <TokenSelector
                id="sellCurrency"
                symbol={sellCurrency.value ?? ''}
                address={sellAddress.value}
                tokenId={sellTokenId.value}
                linkingEnabled={
                  (tradeTxnPriceFetchingSide === NO_CHOOSABLE_SIDE ||
                    Boolean(
                      !isTradeLike({ txnType }) ||
                        tradeTxnPriceFetchingSide === 'sellCurrency' ||
                        tradeTxnPriceFetchingSide === null,
                    )) &&
                  Boolean(sellCurrency.value) &&
                  sellCurrency.value !== 'USD'
                }
                onSymbolChange={(symbol) => {
                  sellCurrency.setValue(symbol);
                  sellTokenId.setValue(null);
                }}
                onTokenIdChange={(tokenId) => {
                  setFieldValue('sellTokenId', tokenId);
                }}
                // the tokenId placeholder can be null if all the values are null, but the currency could still differ
                placeholder={sellTokenId.placeholder || sellCurrency.placeholder}
              />
              {multiPageBatch && <UseForPriceFetching side={PriceFetchingSide.sell} />}
            </>
          )}
        />

        <FormInput
          name="buyCurrency"
          txnType={txnType}
          tooltipText={txnFieldTooltips.buyCurrency}
          isDisabled={hasMovementTxn}
          renderSideLabel={({ buyCurrency, sellCurrency, buyTokenId }) => {
            if (
              multiPageBatch ||
              !tradeTxnPriceFetchingSide ||
              tradeTxnPriceFetchingSide === 'buyCurrency' ||
              tradeTxnPriceFetchingSide === NO_CHOOSABLE_SIDE ||
              [buyCurrency.originalValue, sellCurrency.originalValue].some((curr) =>
                ['USD', DIFFERENT_VALUES].includes(curr as string),
              ) ||
              !(buyTokenId.value || getDefaultTokenIdForSymbol(buyCurrency.value, tokensContext))
            ) {
              return null;
            }

            return (
              <div
                className={cx('cursor-pointer inline-block text-sm ml-2', textLinkVariantClassnames.primary)}
                data-testid="UseForPriceFetching-buy"
                onClick={() => {
                  // this causes an effect to run that updates the priceFetchingSide and re-fetches prices
                  setSideBeingSwitchedTo('buy');
                }}
              >
                Use for price lookups
                {sideBeingSwitchedTo === 'buy' && <Spinner className="ml-1.5" size="sm" />}
              </div>
            );
          }}
          render={({ buyTokenId, buyCurrency, buyAddress }) => (
            <>
              <TokenSelector
                id="buyCurrency"
                symbol={buyCurrency.value ?? ''}
                tokenId={buyTokenId.value}
                address={buyAddress.value}
                linkingEnabled={
                  (tradeTxnPriceFetchingSide === NO_CHOOSABLE_SIDE ||
                    Boolean(
                      !isTradeLike({ txnType }) ||
                        tradeTxnPriceFetchingSide === 'buyCurrency' ||
                        tradeTxnPriceFetchingSide === null,
                    )) &&
                  Boolean(buyCurrency.value) &&
                  buyCurrency.value !== 'USD'
                }
                onSymbolChange={(symbol) => {
                  buyCurrency.setValue(symbol);
                  buyTokenId.setValue(null);
                }}
                onTokenIdChange={(tokenId) => {
                  setFieldValue('buyTokenId', tokenId);
                }}
                // the tokenId placeholder can be null if all the values are null, but the currency could still differ
                placeholder={buyTokenId.placeholder || buyCurrency.placeholder}
              />
              {multiPageBatch && <UseForPriceFetching side={PriceFetchingSide.buy} />}
            </>
          )}
        />

        <FormInput
          name="sellQuantity"
          txnType={txnType}
          tooltipText={txnFieldTooltips.sellQuantity}
          isDisabled={hasMovementTxn}
          render={({ sellQuantity: { value, placeholder, setValue, setTouched }, ...props }) => (
            <NumberInput
              id="sellQuantity"
              isNumericString
              onBlur={(currentValue) => {
                setTouched();
                if (currentValue === '0') {
                  props.sellPrice.setValue('0');
                  props.buyPrice.setValue('0');
                  return;
                }
                if (currentValue && sellPrice !== '') {
                  updatePrices('buyPrice');
                }
              }}
              onChange={setValue}
              {...{ value, placeholder }}
            />
          )}
        />

        <FormInput
          name="buyQuantity"
          txnType={txnType}
          tooltipText={txnFieldTooltips.buyQuantity}
          isDisabled={hasMovementTxn}
          render={({ buyQuantity: { value, placeholder, setValue, setTouched }, ...props }) => (
            <NumberInput
              id="buyQuantity"
              isNumericString
              onBlur={(currentValue) => {
                setTouched();
                if (currentValue === '0') {
                  props.sellPrice.setValue('0');
                  props.buyPrice.setValue('0');
                  return;
                }
                if (currentValue && buyPrice !== '') {
                  updatePrices('sellPrice');
                }
              }}
              onChange={setValue}
              {...{ value, placeholder }}
            />
          )}
        />

        {/* txns is an empty array when batch editing multiple pages of txns */}
        {txns.length > 0 && (
          <PriceFields
            txnType={txnType}
            tradeTxnPriceFetchingSide={tradeTxnPriceFetchingSide}
            updatePrices={updatePrices}
            fetchPrices={fetchPrices}
            isDisabled={hasMovementTxn}
          />
        )}

        <FormInput
          className="pb-4 col-span-2"
          name="feeCurrency"
          txnType={txnType}
          tooltipText={txnFieldTooltips.feeCurrency}
          isDisabled={hasMovementTxn}
          render={({ feeTokenId, feeCurrency, feeAddress }) => (
            <TokenSelector
              id="feeCurrency"
              symbol={feeCurrency.value ?? ''}
              tokenId={feeTokenId.value}
              address={feeAddress.value}
              onSymbolChange={(symbol) => {
                feeCurrency.setValue(symbol);
                feeTokenId.setValue(null);
              }}
              onTokenIdChange={(tokenId) => {
                setFieldValue('feeTokenId', tokenId);
              }}
              // the tokenId placeholder can be null if all the values are null, but the currency could still differ
              placeholder={feeTokenId.placeholder || feeCurrency.placeholder}
            />
          )}
        />

        <FormInput
          className="pb-4 col-span-2"
          name="feeQuantity"
          txnType={txnType}
          tooltipText={txnFieldTooltips.feeQuantity}
          isDisabled={hasMovementTxn}
          render={({ feeQuantity: { value, placeholder, setValue } }) => (
            <Input
              id="feeQuantity"
              onChange={(e) => {
                setValue(Number(e.target.value));
              }}
              value={value ?? ''}
              placeholder={placeholder}
            />
          )}
        />

        <FormInput
          className="pb-4 col-span-2"
          name="feePrice"
          txnType={txnType}
          tooltipText="The USD price of the fee currency, per 1 unit of the currency."
          isDisabled={hasMovementTxn}
          render={({ feePrice, feeTokenId, feeCurrency }) => (
            <PriceInput
              id="feePrice"
              onBlur={() => {
                feePrice.setTouched();
              }}
              onChange={feePrice.setValue}
              onFetchPrice={() => fetchPrices('fee')}
              fetchEnabled={Boolean(
                feeTokenId.value ||
                  feeCurrency.value === 'USD' ||
                  (feeTokenId.value !== undefined &&
                    feeCurrency.value &&
                    getDefaultTokenIdForSymbol(feeCurrency.value, tokensContext)),
              )}
              value={feePrice.value}
              placeholder={feePrice.placeholder}
            />
          )}
        />

        <FormInput
          className="pb-4 col-span-2"
          name="exchangeName"
          txnType={txnType}
          tooltipText={txnFieldTooltips.exchangeName}
          isDisabled={hasMovementTxn}
          render={({ exchangeName: { value, placeholder, setValue } }) => (
            <Input
              id="exchangeName"
              onChange={(e) => {
                setValue(e.target.value);
              }}
              value={value ?? ''}
              placeholder={placeholder}
            />
          )}
        />

        <FormInput
          name="description"
          className="pb-4 col-span-2"
          txnType={txnType}
          tooltipText={txnFieldTooltips.description}
          isDisabled={hasMovementTxn}
          render={({ description: { value, placeholder, setValue } }) => (
            <Input
              id="description"
              onChange={(e) => {
                setValue(e.target.value);
              }}
              value={value ?? ''}
              placeholder={placeholder}
            />
          )}
        />

        {txnType === TxnType.movement && user?.recalcByAccount && (
          <>
            <FormInput
              className="pb-4 col-span-2"
              name="accountId"
              txnType={txnType}
              tooltipText={txnFieldTooltips.accountId}
              isDisabled={hasMovementTxn}
              render={({ accountId, credentialId }) => {
                const parentCredential = allCreds?.find((cred) => cred.id === credentialId.value);
                const parentCredentialAccountId = parentCredential?.accountId;
                const isExistingMovement = initialValues.txnType === TxnType.movement;
                const isFromBuyTxn =
                  BUY_PRICE_ONLY_TYPES.includes(initialValues.txnType) && !isExistingMovement;

                return (
                  <AccountSelector
                    accountId={
                      isExistingMovement
                        ? accountId.value
                        : isFromBuyTxn
                        ? accountId.value
                        : accountId.value || parentCredentialAccountId
                    }
                    onChange={(value) => {
                      accountId.setValue(value);
                    }}
                  />
                );
              }}
            />
            <FormInput
              className="pb-4 col-span-2"
              name="toAccountId"
              txnType={txnType}
              tooltipText={txnFieldTooltips.toAccountId}
              isDisabled={hasMovementTxn}
              render={({ toAccountId, credentialId }) => {
                const parentCredential = allCreds?.find((cred) => cred.id === credentialId.value);
                const parentCredentialAccountId = parentCredential?.accountId;
                const isExistingMovement = initialValues.txnType === TxnType.movement;
                const isFromBuyTxn =
                  BUY_PRICE_ONLY_TYPES.includes(initialValues.txnType) && !isExistingMovement;

                return (
                  <AccountSelector
                    accountId={
                      isExistingMovement
                        ? toAccountId.value
                        : isFromBuyTxn
                        ? toAccountId.value || parentCredentialAccountId
                        : toAccountId.value
                    }
                    onChange={(value) => {
                      toAccountId.setValue(value);
                    }}
                  />
                );
              }}
            />
          </>
        )}

        {isTokenTaxAdmin && (
          <>
            {txnType !== TxnType.movement && user?.recalcByAccount && (
              <>
                <FormInput
                  className="pb-4 col-span-2"
                  name="accountId"
                  txnType={txnType}
                  tooltipText={txnFieldTooltips.accountId}
                  isDisabled={hasMovementTxn}
                  render={({ accountId: { value, setValue }, credentialId }) => {
                    const parentCredential = allCreds?.find((cred) => cred.id === credentialId.value);
                    const parentCredentialAccountId = parentCredential?.accountId;
                    return (
                      <AccountSelector accountId={value || parentCredentialAccountId} onChange={setValue} />
                    );
                  }}
                />
              </>
            )}
            <FormInput
              name="bkpVendorId"
              className="pb-4 col-span-2"
              txnType={txnType}
              tooltipText={txnFieldTooltips.vendor}
              isDisabled={hasMovementTxn}
              render={({ bkpVendorId: { value, placeholder, setValue } }) => {
                return (
                  <Select<BkpAccountsVendorsQuery['bkpVendors'][0]>
                    value={bkpVendors.find((option) => option.value.id === value)!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                    options={bkpVendors}
                    isSearchable={true}
                    onChange={(option) => {
                      setValue(option?.value.id);
                    }}
                    placeholder={placeholder}
                  />
                );
              }}
            />

            <FormInput
              name="bkpAccountDebitId"
              className="pb-4 col-span-2 md:col-span-1"
              txnType={txnType}
              tooltipText={txnFieldTooltips.account}
              isDisabled={hasMovementTxn}
              render={({ bkpAccountDebitId: { value, placeholder, setValue } }) => {
                return (
                  <Select<BkpAccountsVendorsQuery['bkpAccounts'][0]>
                    value={bkpAccounts.find((option) => option.value.id === value)!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                    options={bkpAccounts}
                    isSearchable={true}
                    onChange={(option) => {
                      setValue(option?.value.id);
                    }}
                    placeholder={placeholder}
                    components={{ Option: BkpAccountOption }}
                  />
                );
              }}
            />

            <FormInput
              name="bkpAccountCreditId"
              className="pb-4 col-span-2 md:col-span-1"
              txnType={txnType}
              tooltipText={txnFieldTooltips.account}
              isDisabled={hasMovementTxn}
              render={({ bkpAccountCreditId: { value, placeholder, setValue } }) => {
                return (
                  <Select<BkpAccountsVendorsQuery['bkpAccounts'][0]>
                    value={bkpAccounts.find((option) => option.value.id === value)!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                    options={bkpAccounts}
                    isSearchable={true}
                    onChange={(option) => {
                      setValue(option?.value.id);
                    }}
                    placeholder={placeholder}
                    components={{ Option: BkpAccountOption }}
                  />
                );
              }}
            />

            <FormInput
              name="reviewed"
              className="col-span-1 pb-2"
              tooltipText={txnFieldTooltips.reviewed}
              isDisabled={hasMovementTxn}
              render={({ reviewed: { value, setValue } }) => {
                return (
                  <CheckboxInput
                    checked={!!value}
                    minus={isUndefined(value)}
                    onChange={(value) => {
                      setValue(value);
                    }}
                  />
                );
              }}
            />
            <FormInput
              name="isSpam"
              className="col-span-1 pb-2"
              tooltipText={txnFieldTooltips.isSpam}
              isDisabled={hasMovementTxn}
              render={({ isSpam: { value, setValue } }) => {
                return (
                  <CheckboxInput
                    checked={!!value}
                    minus={isUndefined(value)}
                    onChange={(value) => {
                      setValue(value);
                    }}
                  />
                );
              }}
            />
          </>
        )}
      </div>

      <div
        className={cx('py-4 px-8 sticky bg-light-base dark:bg-dark-base border-solid', !inView && 'border-t')}
        style={{ bottom: '-1px' }}
        ref={bottomBarRef}
      >
        <SpecIdWarningToast />
        <Button
          disabled={!isValid || !hasChangedAnyValue || updating}
          loading={updating}
          type="submit"
          testID="EditTxnForm-SubmitButton"
          onClick={(event) => {
            event.preventDefault();
            onSubmit({ values: txnsValues, formValues: values });
          }}
        >
          Submit
        </Button>

        <button
          className={cx('text-light-alert-critical dark:text-dark-alert-critical ml-4', {
            hidden: !showDeleteButton || hasSpecIdMatches,
          })}
          onClick={useCallback(
            (event) => {
              event.preventDefault();
              onDelete?.();
            },
            [onDelete],
          )}
          disabled={hasMovementTxn}
        >
          Delete
        </button>
      </div>
    </Form>
  );
}

export default React.memo(EditTxnForm);
