import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useKeyPressEvent } from 'react-use';
import InputMenu from './InputMenu';
import { FilterConfig } from '../types';
import Dropdown, { DropdownHandle } from '../../Dropdown';

interface Props<QueryType> {
  filterQuery: QueryType;
  filtersMap: Record<string, FilterConfig>;
  filtersArray: Array<FilterConfig & { key: string }>;
  renderSelectedInput: ({ selectedInput }: { selectedInput: string }) => JSX.Element | null;
  inputMenuWidth?: string;
}

function FiltersDropdown<QueryType>({
  filterQuery,
  filtersMap,
  filtersArray,
  renderSelectedInput,
  inputMenuWidth,
}: Props<QueryType>) {
  const [selectedInput, setSelectedInput] = useState<string>();
  const [open, setOpen] = useState(false);
  const dropDownRef = useRef<DropdownHandle>(null);
  const hideDropdown = useCallback(() => {
    dropDownRef.current?.hide();
  }, [dropDownRef]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(hideDropdown, [filterQuery]);

  const toggleFilterMenu = useCallback(() => {
    if (open) {
      dropDownRef.current?.hide();
    } else {
      setTimeout(() => {
        // setTimeout fixes the issue reported in https://tokentax.slack.com/archives/CHJKQKR4P/p1695994723989799
        dropDownRef.current?.updatePopper?.(); // recalc popper position as it may have changed
        dropDownRef.current?.show();
      }, 10);
    }
  }, [open]);

  useKeyPressEvent('f', (e) => {
    // If cmd-f return
    if (e.metaKey) return;
    const target = e.target as HTMLElement;
    // Only toggle when user is not focused in another element
    if (target.tagName === 'BODY') {
      toggleFilterMenu();
    }
  });

  useKeyPressEvent('Escape', hideDropdown);

  const showInputMenu = open && !selectedInput;
  const showFilterInput = open && selectedInput;

  return (
    <Dropdown
      label="Add Filter"
      isDisabled={false}
      ref={dropDownRef}
      onShow={() => setOpen(true)}
      onHide={() => {
        setOpen(false);
        setSelectedInput(undefined);
      }}
      dropdownBody={
        <button
          className={classNames(
            'w-[25px] h-[25px] flex items-center justify-center border border-light-control dark:border-dark-control border-dashed hover:bg-light-hover hover:dark:bg-dark-hover rounded',
            {
              'bg-light-hover dark:bg-dark-hover': open,
            },
          )}
        >
          <span className="material-icons" style={{ fontSize: 14 }}>
            add
          </span>
        </button>
      }
    >
      {showInputMenu && (
        <InputMenu
          {...{
            hideDropdown,
            setSelectedOption: setSelectedInput,
            filtersMap,
            filtersArray,
            width: inputMenuWidth,
          }}
        />
      )}
      {showFilterInput && renderSelectedInput({ selectedInput })}
    </Dropdown>
  );
}

export default React.memo(FiltersDropdown);
