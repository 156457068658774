import { isBoolean } from 'lodash';
import { SetOptional } from 'type-fest';
import { BuyProductsMutation } from '../../../graphql/types';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rdt: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
  }
}

interface TrackOrderCompletedProps {
  userId: number;
  invoiceId: string;
  amountPaid: number;
  couponCode?: string | null;
  couponPercentOff?: number | null;
  analyticsProducts: Array<
    SetOptional<BuyProductsMutation['buyProducts']['analyticsProducts'][0], '__typename' | 'is_upgrade'>
  >;
  checkoutSource?: string;
}

export const trackOrderCompleted = ({
  userId,
  invoiceId,
  amountPaid,
  couponCode = null,
  couponPercentOff = null,
  analyticsProducts,
  checkoutSource = 'new_purchase_page',
}: TrackOrderCompletedProps) => {
  const isUpgrade = analyticsProducts.some(({ is_upgrade }) => !!is_upgrade);

  const total = amountPaid / 100;
  const coupon = couponCode && couponPercentOff && `${couponCode} - ${couponPercentOff}%`;

  window.analytics.track('Order Completed', {
    order_id: invoiceId,
    total: total,
    revenue: total,
    coupon: coupon,
    products: analyticsProducts.map(({ name, product_id, is_upgrade, price, quantity }) => ({
      name,
      product_id,
      price: price / 100,
      quantity,
      ...(isBoolean(is_upgrade) ? { is_upgrade } : {}),
    })),
    token_tax_id: userId,
    currency: 'USD',
    is_upgrade: isUpgrade,
    status: 'completed',
    onboarding_bucket: 'default',
    checkout_source: checkoutSource,
  });

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: invoiceId,
        value: total,
        currency: 'USD',
        coupon: coupon,
        items: analyticsProducts.map(({ name, product_id, price, quantity }) => ({
          item_id: product_id,
          item_name: name,
          price: price / 100,
          quantity,
        })),
      },
    });
  }

  // Reddit conversion -> reddit integration not in segment
  if (window.rdt) {
    window?.rdt?.('track', 'Purchase', {
      currency: 'USD',
      itemCount: analyticsProducts.length,
      transactionId: invoiceId,
      value: total,
      products: analyticsProducts.map(({ name, product_id }) => ({
        id: product_id,
        name: name,
      })),
    });
  }
};
