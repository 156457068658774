import React from 'react';

import { CurrencyCell, ColumnInstance, CellProps } from '../../Table';
import Tooltip from '../../Tooltip/index';
import { LineItemData } from './types';
import { useTableContext } from '../../Table/context';
import NumberFormat from '../../NumberFormat/index';

type WarningCellBaseProps = CellProps<LineItemData, number>;

interface WarningCellProps extends WarningCellBaseProps {
  column: ColumnInstance<LineItemData>;
  warningType: string;
  hasWarning: boolean;
  tooltip: React.ReactNode;
}

const getFormatProps = (baseCurrency?: string) => {
  return {
    currency: baseCurrency || 'USD',
    comma: true,
  };
};

export function MissingCostBasisCell(props: WarningCellBaseProps) {
  const {
    cell: {
      value,
      row: {
        original: { missingCostBasis },
      },
    },
  } = props;

  const { baseCurrency } = useTableContext();

  const tooltip = (
    <>
      When your cost basis is <NumberFormat value={value} {...getFormatProps(baseCurrency)} />, it could mean
      there is an error in your data.
    </>
  );

  return (
    <WarningCell
      hasWarning={missingCostBasis || false}
      tooltip={tooltip}
      warningType="missingCostBasis"
      {...props}
    />
  );
}

export function ZeroProceedsCell(props: WarningCellBaseProps) {
  const {
    cell: {
      value,
      row: {
        original: { proceedsIncludingFees },
      },
    },
  } = props;

  const { baseCurrency } = useTableContext();

  const tooltip = (
    <>
      When your proceeds are <NumberFormat value={value} {...getFormatProps(baseCurrency)} />, it could mean
      that there are missing prices in your data.
    </>
  );

  return (
    <WarningCell
      hasWarning={proceedsIncludingFees === 0}
      tooltip={tooltip}
      warningType="zeroProceeds"
      {...props}
    />
  );
}

export function WarningCell(props: WarningCellProps) {
  const { hasWarning, tooltip } = props;

  return (
    <div className={hasWarning ? 'text-light-alert-critical dark:text-dark-alert-critical' : ''}>
      {hasWarning && (
        <CurrencyCell<LineItemData> {...props}>
          <Tooltip
            icon="report_problem"
            iconClassNames="text-light-alert-critical dark:text-dark-alert-critical mr-1"
          >
            {tooltip}
          </Tooltip>
        </CurrencyCell>
      )}
      {!hasWarning && <CurrencyCell {...props} />}
    </div>
  );
}
