import {
  SupportedEcosystem,
  UnreconciledTransactionsQuery,
  UnreconciledTxnsDataQuery,
} from '../../../../graphql/types';
import { Side } from '../Wizard/types';

export type Category =
  | 'trade'
  | 'lpEntrance'
  | 'lpExit'
  | 'freeMint'
  | 'mint'
  | 'transferOut'
  | 'repay'
  | 'spend'
  | 'lost'
  | 'bridgeOut'
  | 'transferIn'
  | 'borrow'
  | 'income'
  | 'bridgeIn'
  | 'spam'
  | 'other';

// the following numbers match ids from the `treatments` table in the recon DB
export enum TREATMENTS {
  ignore = 1,
  simpleIncome = 2,
  simpleSwap = 3,
  simpleSpend = 4,
  lpEntrance = 5,
  lpExit = 6,
  incomeWithExclusion = 7,
  swapWithIncome = 8,
  complexTrade = 9,
  other = 10,
  bridge = 11,
  simpleBorrow = 12,
  simpleRepay = 13,
  unknown = 14,
  spam = 15,
  zeroDollarTrade = 16,
  incomeWithWithdrawal = 17,
  borrowWithExclusion = 18,
  repayWithExclusion = 19,
  repayWithIncome = 20,
  wrap = 21,
  weightedLpEntrance = 22,
  weightedLpExit = 23,
}

export enum TreatmentBucket {
  'Income' = 'Income',
  'Trade' = 'Trade',
  'Borrow' = 'Borrow',
  'Spend' = 'Spend',
  'Repay' = 'Repay',
  'Other' = 'Other',
  'Unknown' = 'Unknown',
  'Bridge' = 'Bridge',
  'Spam' = 'Spam',
  'Ignore' = 'Ignore',
}

export type TransferSent =
  | 'sold'
  | 'lpContribution'
  | 'lpWithdrawn'
  | 'paid'
  | 'sent'
  | 'repaid'
  | 'spent'
  | 'lost'
  | 'bridgedOut';

export type TransferReceived =
  | 'bought'
  | 'lpReceived'
  | 'lpDistribution'
  | 'minted'
  | 'received'
  | 'borrowed'
  | 'earned'
  | 'bridgedIn'
  | 'spam';

export type TransferType = TransferSent | TransferReceived;

export type Transfer = {
  id?: string | null;
  type: TransferType;
  currency: string;
  tokenId?: string | null;
  quantity: string;
  price?: string;
};

export type TransfersGroup = {
  // grouped?: boolean;
  // removing this for now https://tokentax.slack.com/archives/C05AZ4LVBDK/p1690383541421299?thread_ts=1690377301.367009&cid=C05AZ4LVBDK
  grouped?: false;
  txns: Array<Transfer | null>;
};

export type Draft = {
  sent?: TransfersGroup | null;
  received?: TransfersGroup | null;
};

export type Contract = {
  id?: number | null;
  address?: string | null;
  name?: string | null;
};

export type CoreTxnResponse = NonNullable<UnreconciledTxnsDataQuery['unreconciledTxnsData'][0]['txns']>[0];

export type CoreTxn = Omit<CoreTxnResponse, 'id'> & {
  id: string | null;
};

export type UnreconciledTransactionFee = {
  currency: string;
  tokenId: string | null;
  quantity: string;
  price: string;
};

type UnreconciledTransactionEntry = UnreconciledTransactionsQuery['unreconciledTransactions']['edges'][0];

export type UnreconciledTransaction = {
  id: string;
  sentTxnsCount: number;
  receivedTxnsCount: number;
  resolved: boolean;
  invalidated: boolean;
  resolutionId?: number | null;
  resolutionCategory?: Category | null;
  credentials: UnreconciledTransactionEntry['credentials'];
  blocksvcHash: string;
  from?: string | null;
  to?: string | null;
  contractPublicName?: string | null;
  methodId?: number | null;
  methodHash: string;
  methodName?: string | null;
  timestamp: Date;
  suggestedCategory: Category | null;
  suggestedTreatment: TREATMENTS | null;
  suggestedCategoryConfidence: number | null;
  suggestionFromTokenTaxAdmin: boolean;
  suggestionFromModel: boolean;
  treatment: number | undefined;
  tokenParam1?: string[] | null;
  tokenParam2?: string[] | null;
  category: Category | null;
  contract: Contract;
  drafts?: Draft[];
  edited: boolean;
  txns?: UnreconciledTxnsDataQuery['unreconciledTxnsData'][number]['txns'];
  isReconciling: boolean;
  fee?: UnreconciledTransactionFee;
  publicNotes?: string | null;
  bucket: TreatmentBucket;
  ecosystem: SupportedEcosystem;
  reconIdentifier: string | null;
};

export type RemoveDraftProps = { draftIndex: number };

export type SetTransferTypeProps = {
  draftIndex: number;
  side: Side;
  transferType: TransferType;
  transferIndex: number; // make this optional if we bring back transfer grouping
};

export type RemoveTransferProps = {
  draftIndex: number;
  side: Side;
  transferIndex: number;
};

export type AddTransferProps = {
  draftIndex?: number;
  sent?: Transfer;
  received?: Transfer;
};

export type EditTransferProps = {
  draftIndex: number;
  side: Side;
  transferIndex: number;
  quantity: string;
  currency: string;
  tokenId: string | null;
};

export type UpdateTransactionProps = {
  id: string;
  data: UnreconciledTransactionEntry;
};

export type ResolveUnreconciledTxnsProps = {
  transactions: UnreconciledTransaction[];
};

export type ResetCurrentTransactionProps = {
  txns?: UnreconciledTxnsDataQuery['unreconciledTxnsData'][0]['txns'];
  category?: Category | null;
  lpTokenTickersByAddress: Record<string, string>;
};

export type SetCategoryProps = {
  category: Category;
  id: string;
  lpTokenTickersByAddress: Record<string, string>;
};

export type SetTxnDataProps = {
  unreconciledTransactionId: string;
  unreconciledTxnData: Omit<UnreconciledTxnsDataQuery['unreconciledTxnsData'][number], 'txnHash'>;
  lpTokenTickersByAddress: Record<string, string>;
};

export type BatchSetCategoryProps = {
  category: Category;
  ids: string[];
  lpTokenTickersByAddress: Record<string, string>;
};

export type LoadTxnDataProps = {
  unreconciledTransactionId: string;
  lpTokenTickersByAddress: Record<string, string>;
};

export type MergeDraftsProps = {
  sourceDraftIndex: number;
  targetDraftIndices: number[];
};

export type SplitTradeProps = {
  draftIndex: number;
};

export type Store = {
  loaded: boolean;
  transactions: UnreconciledTransaction[];
  currentTransactionId: string | null;
  getCurrentTransaction: () => UnreconciledTransaction | null;
  getNextUnreconciledTransactionIds: (howMany: number) => string[];
  getPrevUnreconciledTransactionIds: (howMany: number) => string[];
  showNextUnreconciledTransaction: () => void;
  showPrevUnreconciledTransaction: () => void;
  loadUnreconciledTransactions: (unreconciledTransactions: UnreconciledTransactionEntry[]) => void;
  loadTxnData: (props: LoadTxnDataProps) => Promise<void>;
  setTxnData: (props: SetTxnDataProps) => void;
  batchSetCategory: (props: BatchSetCategoryProps) => void;
  setCategory: (props: SetCategoryProps) => void;
  resolveUnreconciledTxns: (props: ResolveUnreconciledTxnsProps) => Promise<void>;
  resetCurrentTransaction: (props: ResetCurrentTransactionProps) => Promise<void>;
  removeDraft: (props: RemoveDraftProps) => void;
  setTransferType: (props: SetTransferTypeProps) => void;
  removeTransfer: (props: RemoveTransferProps) => void;
  addTransfer: (props: AddTransferProps) => void;
  editTransfer: (props: EditTransferProps) => void;
  mergeDrafts: (props: MergeDraftsProps) => void;
  splitTrade: (props: SplitTradeProps) => void;
  getSimilarTransactions: (transaction: UnreconciledTransaction) => UnreconciledTransaction[];
  reset: () => void;
  skipToBucket: (bucket: TreatmentBucket) => void;
};
