import { ReactiveVar, useReactiveVar } from '@apollo/client';
import React, { useCallback } from 'react';

import { Cell, SelectCell } from '../Table';
import { Actions, Item, MultiSelectState } from './types';
import { useTableContext } from '../Table/context';

interface Props<
  Data extends Item<IdType>,
  SortOptions,
  FilteringQueryParams,
  IdType extends string | number,
> {
  cell: Cell<Data, IdType>;
  items?: Item<IdType>[];
  multiSelectVar: ReactiveVar<MultiSelectState<IdType>>;
  actions: Actions<SortOptions, FilteringQueryParams, IdType>;
  className?: string;
  disabled?: boolean;
}

function SelectedItemCell<
  Data extends Item<IdType>,
  SortOptions,
  FilteringQueryParams,
  IdType extends string | number,
>({
  cell,
  items,
  multiSelectVar,
  actions,
  className,
  disabled,
}: Props<Data, SortOptions, FilteringQueryParams, IdType>) {
  const { disabledIds } = useTableContext();
  const multiSelectState = useReactiveVar(multiSelectVar);
  const { selected, selectionSpan } = multiSelectState;

  return (
    <SelectCell<Data, IdType>
      cell={cell}
      className={className}
      checked={selectionSpan === 'FilterSet' || selected.includes(cell.value)}
      disabled={disabled ?? disabledIds?.includes(String(cell.value))}
      onClick={useCallback<(id: IdType, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void>(
        (newlySelectedId, event) => {
          if (!items) return;
          if (event.shiftKey && selected.length === 1 && selected[0] !== newlySelectedId) {
            const newlySelectedIdIndex = items.findIndex(({ id }) => id === newlySelectedId);
            const currentlySelectedIdIndex = items.findIndex(({ id }) => id === selected[0]);
            const selectedIds =
              newlySelectedIdIndex < currentlySelectedIdIndex
                ? items.slice(newlySelectedIdIndex, currentlySelectedIdIndex + 1)
                : items.slice(currentlySelectedIdIndex, newlySelectedIdIndex + 1);
            actions.selectMany(selectedIds);
            return;
          }
          actions.select(newlySelectedId);
        },
        [actions, selected, items],
      )}
    />
  );
}

export default React.memo(SelectedItemCell) as typeof SelectedItemCell;
