import { ReactiveVar } from '@apollo/client';
import React, { ReactNode } from 'react';
import { Cell } from '../Table';

import SelectedItemCell from './SelectedItemCell';
import SelectedItemHeader from './SelectedItemHeader';

import type { Actions, Item, MultiSelectState } from './types';

interface Props<SortOptions, FilteringQueryParams, IdType extends string | number> {
  multiSelectVar: ReactiveVar<MultiSelectState<IdType>>;
  items: Item<IdType>[];
  actions: Actions<SortOptions, FilteringQueryParams, IdType>;
  selectionMenu?: ReactNode;
}

export type SelectionColumn<
  IdType extends string | number,
  // eslint is tripping on generics here
  Data extends Item<IdType>, // eslint-disable-line @typescript-eslint/no-unused-vars
  SortOptions, // eslint-disable-line @typescript-eslint/no-unused-vars
  FilteringQueryParams, // eslint-disable-line @typescript-eslint/no-unused-vars
> = ReturnType<typeof getSelectionColumn<IdType, Data, SortOptions, FilteringQueryParams>>;

export function getSelectionColumn<
  IdType extends string | number,
  Data extends Item<IdType>,
  SortOptions,
  FilteringQueryParams,
>({ multiSelectVar, items, actions, selectionMenu }: Props<SortOptions, FilteringQueryParams, IdType>) {
  return {
    id: 'id',
    Header: function SelectedItemHeaderWrapper(cell: Cell<Data, IdType>) {
      return (
        <SelectedItemHeader<Data, SortOptions, FilteringQueryParams, IdType>
          {...{ cell, items, multiSelectVar, actions, selectionMenu }}
        />
      );
    },
    accessor: 'id',
    Cell: function SelectedItemCellWrapper(cell: Cell<Data, IdType>) {
      return (
        <SelectedItemCell<Data, SortOptions, FilteringQueryParams, IdType>
          {...{ cell, items, multiSelectVar, actions }}
        />
      );
    },
    isIndexColumn: true,
    hideTitle: true,
    sortable: false,
    width: '24px',
    padding: '',
  };
}
