import React from 'react';
import { TxnsQuery } from '../../../graphql/types';
import { useIntegrationsContext } from '../../../providers/IntegrationsProvider';
import Copyable from '../../Copyable';
import { formatCredentialSource, getCredentialName } from '../../Import/formatters';

type CredentialCellProps = Partial<TxnsQuery['txns']['edges'][0]['credential']>;

const WALLET_ADDRESS_LEAD_CHARS = 4;
const WALLET_NAME_LEAD_CHARS = 5;

const NONWALLET_NAME_LEAD_CHARS = 12;

// `source` is the type of credential (e.g. API, CSV, Manual Entry, Unreconciled) - not useful for presentation
// `name` is the arbitrary label for the credential set by the user
// `address` is the address of the credential. Only works for wallets, which are from 'api' sources

// Wallets should get a copy button to add the address to the clipboard
// Other credentials should try to show a human-readable name if they can,
// and fall back to the source name if they can't
function CredentialCell({ ...credential }: CredentialCellProps) {
  const integrationsContext = useIntegrationsContext();

  // This should never happen but let's do it just in case
  if (!credential || !credential.credentialType || !credential.source) {
    return <>{'-'}</>;
  }

  const { credentialType, source, name, address } = credential;

  let displayName: string | null | undefined = name;
  if (!displayName) displayName = getCredentialName({ credentialType, source }, integrationsContext);
  if (!displayName) displayName = formatCredentialSource(source);

  // Wallet condition
  if (address) {
    const displayAddress = address.slice(0, WALLET_ADDRESS_LEAD_CHARS);

    if (displayName && displayName.length > WALLET_NAME_LEAD_CHARS) {
      displayName = `${displayName.slice(0, WALLET_NAME_LEAD_CHARS)}...`;
    }

    return (
      <Copyable value={address} className="w-full flex justify-start">
        {`${displayName} - ${displayAddress}..`}
      </Copyable>
    );
  }

  // Nonwallet condition
  if (displayName.length > NONWALLET_NAME_LEAD_CHARS)
    displayName = `${displayName.slice(0, NONWALLET_NAME_LEAD_CHARS)}...`;

  return <div className="w-full flex justify-start">{displayName}</div>;
}

export default React.memo(CredentialCell) as unknown as typeof CredentialCell;
