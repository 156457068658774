import React from 'react';

function Header() {
  return (
    <div className="mb-6">
      <h1 className="text-4xl font-light ">Income</h1>
    </div>
  );
}

export default React.memo(Header);
