import React, { useCallback, useEffect, useState } from 'react';
import { useKeyPressEvent } from 'react-use';
import { getApolloErrorMessages } from '../../lib/errors';
import Button from '../Button';
import Checkbox from '../Input/Checkbox';
import { ErrorToast } from '../Toast';
import Modal, { ModalProps } from './Modal';
import { showModal } from './index';

export type ConfirmationDialogProps = ModalProps & {
  content?: (props: ConfirmationDialogProps) => React.ReactNode;
  buttonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  /** Optionally provide a function for saving a session storage variable to not show modal again */
  setDoNotShowAgain?: () => void;
};

function ConfirmationDialog({
  ...allProps
}: ConfirmationDialogProps & {
  timestamp: number;
}) {
  const {
    title,
    subtitle,
    content,
    buttonText,
    cancelButtonText,
    onConfirm,
    onCancel,
    timestamp,
    setDoNotShowAgain,
    ...props
  } = allProps;
  const [shown, setShown] = useState(true);
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [doNotShowAgainBool, setDoNotShowAgainBool] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setShown(false);
    props.onClose?.();
  }, [props]);

  const onConfirmClicked = useCallback(async () => {
    try {
      setIsLoading(true);
      await onConfirm?.();
      doNotShowAgainBool && setDoNotShowAgain?.();
      setShown(false);
      onClose?.();
    } catch (e) {
      setError(e instanceof Error ? e : new Error(String(e)));
      setIsLoading(false);
    }
  }, [doNotShowAgainBool, onClose, onConfirm, setDoNotShowAgain]);

  useKeyPressEvent('Enter', onConfirmClicked);

  useEffect(() => {
    setShown(true);
  }, [timestamp]);

  return (
    <Modal
      {...{
        isOpen: shown,
        title,
        subtitle,
        ...props,
        onClose,
      }}
    >
      {content?.(allProps)}
      {error && <ErrorToast>{getApolloErrorMessages(error) || error.message}</ErrorToast>}
      {Boolean(setDoNotShowAgain) && (
        <div className="mb-6 flex items-center">
          <Checkbox
            labelClassName="text-sm text-light-muted dark:text-dark-muted"
            checked={doNotShowAgainBool}
            onChange={setDoNotShowAgainBool}
            label=" Do not ask me again"
          />
        </div>
      )}

      {buttonText && (
        <Button disabled={!!error} onClick={onConfirmClicked} loading={isLoading}>
          {buttonText}
        </Button>
      )}

      {cancelButtonText && (
        <Button
          variant="secondary"
          onClick={() => {
            onClose?.();
            onCancel?.();
          }}
          className="ml-2"
        >
          {cancelButtonText}
        </Button>
      )}
    </Modal>
  );
}

export default React.memo(ConfirmationDialog);

export function showConfirmationDialog(props: ConfirmationDialogProps) {
  return new Promise<void>((resolve, reject) => {
    showModal(
      <ConfirmationDialog
        {...props}
        timestamp={+new Date()}
        onConfirm={async () => {
          await props.onConfirm?.();
          resolve();
        }}
        onCancel={async () => {
          try {
            await props.onCancel?.();
          } catch (e) {
            reject(e);
          }
        }}
      />,
    );
  });
}
