import { ApolloClient, QueryHookOptions, gql, useQuery } from '@apollo/client';
import { useUserContext } from '../../contexts';
import { DOCUMENT } from '../../graphql/fragments';
import {
  Available8949PdfYearsQuery,
  DocSortOptions,
  DocumentDownloadUrlsQuery,
  DocumentsCountQuery,
  DocumentsQuery,
  DocumentsQueryVariables,
  SortDirectionOptions,
  TxnAllowanceForYearQuery,
  TxnAllowanceForYearQueryVariables,
} from '../../graphql/types';
import { PaginationState, getPaginationOptions } from '../utils/pagination';
import type { FiltersState } from './types';

export type DocumentType = DocumentsQuery['documents']['edges'][number];

const POLL_INTERVAL_MS = 3000;

export const TXN_ALLOWANCE_FOR_YEAR = gql`
  query TxnAllowanceForYear($year: Int!) {
    txnAllowanceForYear(year: $year) {
      allowed
      count
    }
  }
`;

export const useTxnAllowanceForYear = (
  options: QueryHookOptions<TxnAllowanceForYearQuery, TxnAllowanceForYearQueryVariables>,
) => useQuery<TxnAllowanceForYearQuery, TxnAllowanceForYearQueryVariables>(TXN_ALLOWANCE_FOR_YEAR, options);

export const DOCUMENTS = gql`
  query documents(
    $pagination: PaginationOptions!
    $sortBy: DocSortOptions!
    $sortDirection: SortDirectionOptions!
    $filterQuery: DocFilterQuery!
  ) {
    documents(
      docTableInput: {
        pagination: $pagination
        sortBy: $sortBy
        sortDirection: $sortDirection
        filterQuery: $filterQuery
      }
    ) {
      pageInfo {
        filteredCount
      }
      edges {
        ...Document
      }
    }
  }
  ${DOCUMENT}
`;

interface UseDocumentsProps {
  sortState: {
    sortBy: DocSortOptions;
    sortDir: SortDirectionOptions;
  };
  filtersState: FiltersState;
  paginationState: PaginationState;
  poll: boolean;
  onCompleted?: (data: DocumentsQuery) => void;
}

export function useDocuments({
  paginationState,
  sortState,
  filtersState,
  poll,
  onCompleted,
}: UseDocumentsProps) {
  const { filterQuery } = filtersState;
  const { isLoggedIn } = useUserContext();
  const { data, loading, ...rest } = useQuery<DocumentsQuery, DocumentsQueryVariables>(DOCUMENTS, {
    variables: {
      pagination: getPaginationOptions(paginationState),
      sortBy: sortState.sortBy,
      sortDirection: sortState.sortDir,
      filterQuery: JSON.parse(filterQuery || '{}'),
    },
    pollInterval: poll ? POLL_INTERVAL_MS : 0, // 0 means no polling
    onCompleted,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !isLoggedIn,
  });

  return { ...rest, data: data?.documents, loading: !data?.documents.edges.length && loading };
}

const DOCUMENTS_COUNT_DATA = gql`
  query documentsCount {
    documentsCount {
      totalCount
      yearCounts {
        taxYear
        count
      }
      recommendedDocumentCounts {
        taxYear
        count
      }
    }
  }
`;

export function useDocumentsCount() {
  const { isLoggedIn } = useUserContext();
  const { data, loading, ...rest } = useQuery<DocumentsCountQuery>(DOCUMENTS_COUNT_DATA, {
    fetchPolicy: 'cache-and-network',
    skip: !isLoggedIn,
  });

  return { ...rest, data: data?.documentsCount, loading: !data && loading };
}

export const DOCUMENT_DOWNLOAD_URLS = gql`
  query documentDownloadUrls($documentIds: [Int!]!) {
    documentDownloadUrls(documentIds: $documentIds) {
      edges {
        id
        url
      }
    }
  }
`;

export const getDocumentDownloadUrls = (client: ApolloClient<unknown>, documentIds: number[]) => {
  return client.query<DocumentDownloadUrlsQuery>({
    query: DOCUMENT_DOWNLOAD_URLS,
    variables: { documentIds },
    fetchPolicy: 'no-cache',
  });
};

export const AVAILABLE8949_PDF_YEARS = gql`
  query available8949PdfYears {
    available8949PdfYears
  }
`;

export function useAvailable8949PdfYears() {
  const { isLoggedIn } = useUserContext();
  return useQuery<Available8949PdfYearsQuery>(AVAILABLE8949_PDF_YEARS, {
    fetchPolicy: 'cache-and-network',
    skip: !isLoggedIn,
  });
}
