import { useFormikContext } from 'formik';
import React from 'react';
import { PriceFetchingSide } from '../../../graphql/types';
import { TRANSACTION_TYPES_WITH_BOTH_BUY_AND_SELL_PRICES } from '../../../utils/txnPrices';
import Checkbox from '../../Input/Checkbox';
import { useEditTxnFormContext } from './EditTxnFormProvider';
import { MULTIPLE_VALUES } from './formValues';
import { Values } from './types';

type Props = {
  side: PriceFetchingSide;
};

function UseForPriceFetching({ side }: Props) {
  const { values, setFieldValue } = useFormikContext<Values>();
  const { multiPageBatch } = useEditTxnFormContext();
  const checked = values.priceFetchingSide === side;
  const { txnType } = values;

  const value = values[`${side}Currency`];

  const isTradeLike = TRANSACTION_TYPES_WITH_BOTH_BUY_AND_SELL_PRICES.includes(txnType);
  if (!multiPageBatch || value === MULTIPLE_VALUES || !isTradeLike) return null;

  return (
    <div className="mt-1">
      <Checkbox
        size="small"
        checked={checked}
        onChange={(checked) => {
          setFieldValue('priceFetchingSide', checked ? side : null);
        }}
        label="Use for price fetching"
      />
    </div>
  );
}

export default React.memo(UseForPriceFetching);
