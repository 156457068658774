import React from 'react';
import { FilterOperatorTypes, MultiSelectStringFilter } from '../../../graphql/types';
import { showErrorFlash } from '../../Flash';
import Checkbox from '../../Input/Checkbox';
import Text from '../../Text';
import { BaseFilterProps, SelectOption } from '../types';

export type MultiSelectInputProps = BaseFilterProps<MultiSelectStringFilter> & {
  multiSelectOptions: SelectOption[];
  hideLabel?: boolean;
};

function MultiSelectInput({ label, multiSelectOptions, onChange, filter, hideLabel }: MultiSelectInputProps) {
  const [selectedVals, setSelectedVals] = React.useState<string[]>(filter?.values || []);

  const handleSubmit = () => {
    if (!selectedVals.length) return showErrorFlash('Please select at least one value');
    onChange({
      ...(filter || { operator: FilterOperatorTypes.Is }),
      values: selectedVals,
    });
  };

  return (
    <div style={{ width: 180 }}>
      {!hideLabel && (
        <div className="text-center w-full p-1 border-b border-light-control dark:border-dark-control text-sm font-semibold">
          <span>{label}</span>
        </div>
      )}
      <div className="flex flex-col py-1">
        {multiSelectOptions?.map((val, index) => (
          <button
            className="py-1 px-3 text-left flex items-center hover:bg-light-hover dark:hover:bg-dark-hover"
            key={index}
            onClick={() => {
              if (selectedVals.includes(val.value)) {
                setSelectedVals(selectedVals.filter((v) => v !== val.value));
              } else {
                setSelectedVals([...selectedVals, val.value]);
              }
            }}
          >
            <Checkbox checked={selectedVals.includes(val.value)} size="small" label={val.label} />
            <Text variant="muted" className="ml-2 relative top-[2px]">
              ({val.count})
            </Text>
          </button>
        ))}
      </div>
      <button
        onClick={handleSubmit}
        className="text-center w-full p-1 border-t border-light-control dark:border-dark-control text-sm font-semibold"
      >
        Apply
      </button>
    </div>
  );
}

export default React.memo(MultiSelectInput) as typeof MultiSelectInput;
