import { useReactiveVar } from '@apollo/client';
import { useCallback, useContext } from 'react';

import { RecalcContext, useUserContext } from '../../../contexts';
import { showErrorFlash } from '../../Flash';
import { showBatchDeleteTransactionsModal } from '../BatchDeleteModal';
import { showDeleteTransactionModal } from '../DeleteModal';
import { actions } from '../actions';
import { shouldAskToConfirmTxnDeletion } from '../helpers';
import { useDeleteTxns } from '../mutations';
import { IdType } from '../types';
import { vars } from '../vars';
import { AllTransactionsContextType } from './context';
import { trackTxnDelete } from '../../../lib/analytics';

export const useOnDelete = ({
  refetch,
  checkSpecIdDelinkRequirement,
}: Required<Pick<AllTransactionsContextType, 'refetch' | 'checkSpecIdDelinkRequirement'>>) => {
  const { multiSelectVar } = vars;
  const { refetchNeedsRecalc } = useContext(RecalcContext);
  const { selectionSpan } = useReactiveVar(multiSelectVar);
  const { user, isHijacked } = useUserContext();

  const [deleteTxns, { loading: deleteTxnsLoading }] = useDeleteTxns({
    onSuccess: () => {
      refetchNeedsRecalc();
    },
  });

  const onTxnDeleted = useCallback(
    (txnIds: IdType[]) => {
      actions.deselect(txnIds);
      trackTxnDelete({
        hijacked_account_email: user?.email as string,
        hijacked_account_id: user?.id as number,
        txn_ids: txnIds.join(', '),
        txn_delete_quantity: txnIds.length,
        is_hijacked: !!isHijacked,
      });
      refetch?.();
    },
    [isHijacked, refetch, user?.email, user?.id],
  );

  const onDelete = useCallback(
    async (txnIds: IdType[], triggeredByActionIcon = false) => {
      if (deleteTxnsLoading) return;

      if (triggeredByActionIcon) {
        if (txnIds.length !== 1) {
          throw new Error('Only one transaction can be deleted inline at a time.');
        }
        try {
          await checkSpecIdDelinkRequirement(txnIds[0]);
        } catch {
          return;
        }
      }

      if (selectionSpan === 'FilterSet') {
        showBatchDeleteTransactionsModal();
        return;
      }

      if (shouldAskToConfirmTxnDeletion()) {
        showDeleteTransactionModal({ onTxnDeleted, txnIds });
      } else {
        try {
          await deleteTxns({
            variables: { ids: txnIds },
          });
          onTxnDeleted(txnIds);
        } catch (error) {
          showErrorFlash('The transaction could not be deleted.');
        }
      }
    },
    [deleteTxnsLoading, selectionSpan, checkSpecIdDelinkRequirement, onTxnDeleted, deleteTxns],
  );

  return onDelete;
};
