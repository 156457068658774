import React, { DetailedHTMLProps, LabelHTMLAttributes } from 'react';

export interface LabelProps
  extends DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  className?: string;
  children?: React.ReactNode;
  htmlFor?: string;
}

const Label = ({ className, children, htmlFor, ...restProps }: LabelProps) => {
  const cn = className ? className : 'block mb-2';

  return (
    <label htmlFor={htmlFor} className={cn} {...restProps}>
      {children}
    </label>
  );
};

export default Label;
