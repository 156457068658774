import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import Dropdown, { DropdownHandle } from '../Dropdown';
import { BaseFilterProps, BasePillProps, FilterProps } from './types';

type FilterComponentProps<FilterType, ExtraProps> = FilterProps<FilterType> & {
  Input: FunctionComponent<BaseFilterProps<FilterType> & ExtraProps>;
  Pill?: FunctionComponent<BasePillProps<FilterType> & ExtraProps>;
  props: ExtraProps;
};

function Filter<FilterType, ExtraProps = Record<string, unknown>>({
  Input,
  Pill,
  props,
  onDelete,
  ...baseProps
}: FilterComponentProps<FilterType, ExtraProps>) {
  const { filter, label } = baseProps;
  const [open, setOpen] = useState(() => {
    return false;
  });
  const dropDownRef = useRef<DropdownHandle>(null);

  useEffect(() => {
    if (filter) {
      setOpen(false);
    }
  }, [filter]);

  const onShow = useCallback(() => {
    setOpen(true);
  }, []);

  const onHide = useCallback(() => {
    setOpen(false);
  }, []);

  const onChange = useCallback<FilterComponentProps<FilterType, ExtraProps>['onChange']>(
    (filter) => {
      baseProps.onChange(filter);
      dropDownRef.current?.hide();
    },
    [baseProps],
  );

  const allProps = { ...baseProps, ...props, onChange, onDelete };
  const input = <Input {...allProps} />;
  const pill = Pill && <Pill {...allProps} />;

  if (pill && filter) return pill;

  return (
    <Dropdown
      label="Add Filter"
      isDisabled={false}
      ref={dropDownRef}
      onShow={onShow}
      onHide={onHide}
      dropdownBody={
        <button
          className={classNames(
            'flex',
            'border border-light-control dark:border-dark-control',
            'hover:bg-light-hover hover:dark:bg-dark-hover rounded pl-2 py-1',
            {
              'bg-light-hover dark:bg-dark-hover': open,
              'bg-light-selected dark:bg-dark-selected': !!filter,
            },
          )}
        >
          <span className="text-sm mr-2">{label}</span>

          {onDelete && baseProps.filter && (
            <div
              className="self-stretch flex items-center border-l border-light-control dark:border-dark-control"
              onClick={(evt) => {
                evt.stopPropagation(); // don't open the dropdown
                onDelete();
              }}
            >
              <i className="material-icons mx-2 text-[12px]">close</i>
            </div>
          )}
        </button>
      }
    >
      {open ? input : null}
    </Dropdown>
  );
}
export default React.memo(Filter) as typeof Filter;
