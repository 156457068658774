import { useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { useUserContext } from '../../../contexts';
import { BooleanFilterTypes, CurrencyFilterTypes, LineItemsFilterQuery } from '../../../graphql/types';
import { actions } from './state';
import { filterQueryVar } from './vars';

export const useFiltersConfig = () => {
  const { user } = useUserContext();
  const filterQueryState = useReactiveVar(filterQueryVar);

  const filterQuery = useMemo(() => {
    return JSON.parse(filterQueryState || '{}') as LineItemsFilterQuery;
  }, [filterQueryState]);

  const filtersMap = useMemo(() => {
    const baseFilters = {
      missingCostBasis: {
        label: 'Missing Cost Basis',
        disabled: Boolean(filterQuery?.boolean?.find(({ key }) => key === 'missingCostBasis')),
        onSelected: () => actions.addBooleanFilter({ key: BooleanFilterTypes.missingCostBasis, value: true }),
      },
      zeroProceeds: {
        label: 'Zero Proceeds',
        disabled: Boolean(filterQuery?.boolean?.find(({ key }) => key === 'zeroProceeds')),
        onSelected: () => actions.addBooleanFilter({ key: BooleanFilterTypes.zeroProceeds, value: true }),
      },
      currency: {
        label: 'Currency',
        omit: [CurrencyFilterTypes.FeeCurrency],
      },
      date: {
        label: 'Date Sold',
      },
      ...(user?.recalcByAccount
        ? {
            account: {
              label: 'Account',
            },
          }
        : {}),
    };

    return baseFilters;
  }, [filterQuery?.boolean, user?.recalcByAccount]);

  return filtersMap;
};
