import { MutableRefObject, useCallback, useEffect } from 'react';

export function useClickOutside(
  ref: MutableRefObject<HTMLDivElement | null>,
  handlerUnwrapped: (event: MouseEvent) => void,
): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(handlerUnwrapped, []); // wrap handler in useCallback to avoid unnecessary re-renders
  useEffect(() => {
    function handleClickOutside(event: MouseEvent, clickOutsideHandler: (event: MouseEvent) => void) {
      if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
        clickOutsideHandler(event);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', (event) => handleClickOutside(event, handler));
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', (event) => handleClickOutside(event, handler));
    };
  }, [ref, handler]);
}

export function useUndo(handler: () => void) {
  useEffect(() => {
    const onKeyDown = ({ ctrlKey, metaKey, key }: KeyboardEvent) => {
      if ((ctrlKey || metaKey) && key === 'z') handler();
    };

    document.body.addEventListener('keydown', onKeyDown);

    return () => {
      document.body.removeEventListener('keydown', onKeyDown);
    };
  }, [handler]);
}
