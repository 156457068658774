import { gql } from '@apollo/client';
import { client } from '../../lib/apollo';
import { HIJACKED_USER_ID_HEADER_NAME } from '../../lib/constants';
import { GetUploadUrlMutation, GetUploadUrlMutationVariables } from '../types';

const GET_UPLOAD_URL = gql`
  mutation getUploadURL($fileName: String!) {
    getUploadURL(fileName: $fileName) {
      Bucket
      Key
      url
    }
  }
`;

interface GetUploadURLVariables {
  fileName: string;
}

export const getUploadURL = async (variables: GetUploadURLVariables, userId?: number) => {
  const { data } = await client.mutate<GetUploadUrlMutation, GetUploadUrlMutationVariables>({
    mutation: GET_UPLOAD_URL,
    variables,
    ...(userId && { context: { headers: { [HIJACKED_USER_ID_HEADER_NAME]: userId } } }),
  });

  if (!data) {
    throw new Error(`Could not get an upload URL`);
  }

  return data?.getUploadURL;
};
