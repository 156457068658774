import React, { useState, useCallback } from 'react';

import { Content, showHelpModal } from './modal';
import CommonHelpQuestionsTab from './CommonHelpQuestionsTab';
import GettingStartedTab from './GettingStartedTab';
import TextLink from '../../TextLink';
import Text from '../../Text';

function Help() {
  const [helpTab, setHelpTab] = useState<boolean>(true);

  const handleClick = useCallback((content: Content, title: string) => {
    showHelpModal({ content, title });
  }, []);

  return (
    <div className="p-8">
      <div className="flex justify-between mb-4">
        <Text variant="muted">{helpTab ? 'Help' : 'Getting Started'}</Text>
        <TextLink onClick={() => setHelpTab(!helpTab)}>
          {helpTab ? 'See getting started guide' : 'See common help questions'}
        </TextLink>
      </div>

      <div className="text-xl">
        {helpTab ? (
          <CommonHelpQuestionsTab handleClick={handleClick} />
        ) : (
          <GettingStartedTab handleClick={handleClick} />
        )}
      </div>
    </div>
  );
}

export default React.memo(Help);
