import { useReactiveVar } from '@apollo/client';
import { chain, groupBy, partition, uniq } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { UserContext } from '../../../contexts';
import { useMovementMatchConstraints } from '../../../graphql/queries';
import { useGetEvmGuideChains } from '../../../graphql/queries/evmGuide';
import { TxnsQuery } from '../../../graphql/types';
import { checkEqualAndVal } from '../../../helpers/equality';
import pluralize from '../../../lib/pluralize';
import Breadcrumb from '../../Breadcrumb';
import Dropdown from '../../Dropdown';
import { showReconGuideContributionModal } from '../../ReconGuideContributionModal';
import { applyNetMergeWherePossible } from '../../SelfRecon/UnreconciledTransactions/store/netMerge';
import TextLink from '../../TextLink';
import Tooltip from '../../Tooltip';
import { AllTransactionsContext } from '../Provider/context';
import { actions as allTxnActions } from '../actions';
import { vars } from '../vars';
import { movementsCanBeMatched, txnsHaveSameTypeAndCurrency } from './utils';

export type SelectedAction = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
  adminOnly?: boolean;
};

const TXN_TYPES_THAT_CAN_BE_SPECCED = ['trade', 'repay', 'spend', 'gift', 'lost'];

function SelectedActions() {
  const { multiSelectVar } = vars;
  const {
    onMarkSpam,
    onDelete,
    onDuplicate,
    onCreateMovement,
    onEdit,
    onMerge,
    onReview,
    visibleCount: totalCount,
    selectedTxns,
    specIdTxnResponse,
  } = useContext(AllTransactionsContext);
  const { isTokenTaxAdmin, user, taxMethods = [] } = useContext(UserContext);
  const dropdownRef = React.useRef(null);

  const { data: movementMatchConstraintData, loading: movementMatchConstraintsLoading } =
    useMovementMatchConstraints();
  const movementMatchConstraints = movementMatchConstraintData?.movementMatchConstraints;

  const numSelected = selectedTxns?.length;
  const selectionHasMovement = selectedTxns.some((txn) => txn.hasMovement);
  const hidden = numSelected === 0 || Boolean(specIdTxnResponse?.data?.txn);
  const { selectionSpan } = useReactiveVar(multiSelectVar);
  const isMultiPageSelection = selectionSpan === 'FilterSet';

  const firstSelectedTxn = selectedTxns[0] as TxnsQuery['txns']['edges'][0] | undefined; // this would be caught by TS if we had stricter rules

  const firstSelectedTxnIsInLockedYear =
    user?.lockedTilDate &&
    firstSelectedTxn?.txnTimestamp &&
    new Date(firstSelectedTxn.txnTimestamp) < new Date(user.lockedTilDate);

  const specIdRequirements = useMemo(() => {
    const { sellCurrency, sellQuantity, txnType } = firstSelectedTxn ?? {};
    return [
      [selectedTxns.length === 1, 'Only one transaction at a time can be specified'],
      [Number(sellQuantity) > 0, 'Transaction must have a positive sell quantity'],
      [
        TXN_TYPES_THAT_CAN_BE_SPECCED.includes(txnType!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        `Transaction type must be one of ${TXN_TYPES_THAT_CAN_BE_SPECCED.join(', ')}`,
      ],
      [
        !taxMethods.find(({ id }) => id === 'AVERAGE_COST'),
        'Spec ID is not available when using Average Cost as tax method',
      ],
      [sellCurrency?.toLowerCase() !== 'usd', 'Transaction must not be in USD'],
      [!firstSelectedTxnIsInLockedYear, 'Transaction must not be in a locked year'],
    ] as const;
  }, [firstSelectedTxn, firstSelectedTxnIsInLockedYear, selectedTxns.length, taxMethods]);

  const txnsByHash = useMemo(() => {
    return groupBy(selectedTxns, (txn) => txn.blocksvcHash);
  }, [selectedTxns]);

  const mergeableTxnsByHash = useMemo(() => {
    return chain(txnsByHash)
      .toPairs()
      .map(([hash, txnsForHash]) => {
        if (txnsForHash.length < 2) return null;

        const txnTypes = uniq(txnsForHash.map(({ txnType }) => txnType));
        if (txnTypes.length === 1 && !txnsHaveSameTypeAndCurrency(txnsForHash)) {
          // are we attempting to merge deposit- or withdrawals-only?
          // this is allowed as long as they all have the same currency
          return null;
        }

        try {
          const txnsPossiblyNetMerged = applyNetMergeWherePossible(txnsForHash);
          const [withdrawals, deposits] = partition(
            txnsPossiblyNetMerged,
            ({ txnType }) => txnType === 'withdrawal',
          );

          if ([withdrawals, deposits].some((txns) => txns.length === 1)) {
            return [hash, txnsPossiblyNetMerged];
          }
          return null;
        } catch {
          return null;
        }
      })
      .filter(Boolean)
      .fromPairs()
      .value();
  }, [txnsByHash]);

  const txnsCountAfterMerge = chain(mergeableTxnsByHash)
    .values()
    .map((txns) => {
      const [withdrawals, deposits] = partition(txns, ({ txnType }) => txnType === 'withdrawal');
      return Math.max(withdrawals.length, deposits.length);
    })
    .sum()
    .value();

  const shouldEnableMergeTxnsButton =
    Object.keys(mergeableTxnsByHash).length === Object.keys(txnsByHash).length;

  const shouldEnableMovementButton = movementsCanBeMatched({ selectedTxns, movementMatchConstraints });

  const txnsHaveBeenReviewed = selectedTxns.some((d) => d.reviewed);
  const txnsHaveSpam = selectedTxns.some((d) => d.isSpam);

  const numTxnsReviewed = selectedTxns.filter((d) => d.reviewed).length;
  const numTxnsWithSpam = selectedTxns.filter((d) => d.isSpam).length;

  const onMarkTxnsSpam = useCallback(() => {
    onMarkSpam?.(selectedTxns, !txnsHaveSpam);
  }, [onMarkSpam, selectedTxns, txnsHaveSpam]);

  const onReviewTxns = useCallback(() => {
    onReview?.(selectedTxns, !txnsHaveBeenReviewed);
  }, [onReview, selectedTxns, txnsHaveBeenReviewed]);

  const onMergeTxns = useCallback(() => {
    if (!shouldEnableMergeTxnsButton) return;
    onMerge?.(selectedTxns);
  }, [onMerge, selectedTxns, shouldEnableMergeTxnsButton]);

  const onCreateMovementTxn = useCallback(() => {
    if (!shouldEnableMovementButton) return;
    onCreateMovement?.(selectedTxns);
  }, [onCreateMovement, selectedTxns, shouldEnableMovementButton]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const onDuplicateTxns = useCallback(
    () => onDuplicate?.(selectedTxns.map((txn) => txn.id)),
    [onDuplicate, selectedTxns],
  );

  const onEditTxns = useCallback(() => onEdit?.(selectedTxns), [onEdit, selectedTxns]);

  const onDeleteTxns = useCallback(
    () => onDelete?.(selectedTxns.map(({ id }) => id)),
    [selectedTxns, onDelete],
  );

  const methodIds = selectedTxns.map((t) => t.blocksvcMethodId);
  const contractAddresses = selectedTxns.map((t) => t.blocksvcToAddress);
  const chains = selectedTxns.map((t) => t.credential?.credentialType.toUpperCase());
  const identifiers = selectedTxns.map((t) => t.reconIdentifier);
  const credentials = selectedTxns.map((t) => t.credential?.credentialType);

  // Currently the ReconGuideContributionModal supports Solana and a subset of EVM and IBC chains
  // A valid selection must have matching methodIds, contractAddresses, chains, and reconIdentifiers
  const { data: chainsData, loading: chainsLoading } = useGetEvmGuideChains();

  const methodIdCheck = checkEqualAndVal(methodIds);
  const contractAdddressCheck = checkEqualAndVal(contractAddresses);
  const chainsCheck = checkEqualAndVal(chains);
  const identifiersCheck = checkEqualAndVal(identifiers);
  const credentialsCheck = checkEqualAndVal(credentials);

  const isSolanaSelection =
    chainsCheck.allEqual &&
    chainsCheck.val === 'SOL-NEW' &&
    identifiersCheck.allEqual &&
    identifiersCheck.val !== null;

  const isSupportedEvmSelection =
    chainsCheck &&
    chainsData?.evmGuideChains.some((c) => c.credentialType?.toUpperCase() === chains[0]) &&
    identifiersCheck.allEqual &&
    identifiersCheck.val === null;

  const isIbcSelection =
    chainsCheck.allEqual &&
    chainsCheck.val !== 'SOL-NEW' && // if it has an identifier but isn't Solana then it must be IBC
    identifiersCheck.allEqual &&
    identifiersCheck.val !== null;

  const reconLabel = isSolanaSelection
    ? 'Solana'
    : isSupportedEvmSelection
    ? 'EVM'
    : isIbcSelection
    ? 'IBC'
    : 'Recon';

  const onEditEvmTreatment = useCallback(() => {
    showReconGuideContributionModal({
      type: 'EVM',
      methodId: methodIdCheck.val || '',
      contractAddress: contractAdddressCheck.val || '',
      chain: chainsCheck.val || '',
    });
  }, [chainsCheck, methodIdCheck, contractAdddressCheck]);

  const onEditSolanaTreatment = useCallback(() => {
    showReconGuideContributionModal({
      type: 'Solana',
      identifier: identifiersCheck.val || '',
      transactionHash: selectedTxns[0].blocksvcHash || '',
    });
  }, [identifiersCheck, selectedTxns]);

  const onEditIbcTreatment = useCallback(() => {
    showReconGuideContributionModal({
      type: 'IBC',
      identifier: identifiersCheck.val || '',
      credentialType: credentialsCheck.val || '',
      transactionHash: selectedTxns[0].blocksvcHash || '',
    });
  }, [identifiersCheck, credentialsCheck, selectedTxns]);

  const onSetSpecIdTxn = useCallback(() => {
    allTxnActions.setSpecIdTxn(selectedTxns[0].id);
  }, [selectedTxns]);

  let actions: SelectedAction[] = useMemo(() => {
    const markReviewedDisabled = isMultiPageSelection || selectionHasMovement;
    const mergeDisabled = !shouldEnableMergeTxnsButton || isMultiPageSelection || selectionHasMovement;
    const createMovementDisabled =
      !shouldEnableMovementButton || isMultiPageSelection || selectionHasMovement;
    const duplicateDisabled = isMultiPageSelection || selectionHasMovement;
    const markAsSpamDisabled = isMultiPageSelection || selectionHasMovement;

    return [
      {
        label: txnsHaveBeenReviewed ? `Mark ${numTxnsReviewed} as Unreviewed` : 'Mark as Reviewed',
        onClick: onReviewTxns,
        disabled: markReviewedDisabled,
      },
      {
        label: mergeDisabled
          ? 'Merge'
          : `Merge Into ${txnsCountAfterMerge} ${pluralize(txnsCountAfterMerge, 'Transaction')}`,
        onClick: onMergeTxns,
        disabled: mergeDisabled,
        tooltip: shouldEnableMergeTxnsButton
          ? undefined
          : 'You can merge deposit and withdrawal transactions, provided they have the same hash',
      },
      {
        label: 'Create Movement',
        onClick: onCreateMovementTxn,
        disabled: createMovementDisabled || movementMatchConstraintsLoading,
        tooltip: movementMatchConstraintsLoading
          ? 'Loading...'
          : createMovementDisabled
          ? 'You can create a movement transaction from a deposit and withdrawal pair that have similar quantities and dates'
          : undefined,
        adminOnly: true, // todo @ellebaum we should remove this once we are ready for GA rollout and the validation is more robust
      },
      {
        label: 'Duplicate',
        onClick: onDuplicateTxns,
        disabled: duplicateDisabled,
      },
      {
        label: 'Edit',
        onClick: onEditTxns,
      },
      {
        label: 'Delete',
        onClick: onDeleteTxns,
      },
      {
        label: txnsHaveSpam ? `Unmark ${numTxnsWithSpam} as Spam` : 'Mark as Spam',
        onClick: onMarkTxnsSpam,
        disabled: markAsSpamDisabled,
      },
      {
        label: `Edit ${reconLabel} Treatment`,
        onClick: isSupportedEvmSelection
          ? onEditEvmTreatment
          : isSolanaSelection
          ? onEditSolanaTreatment
          : isIbcSelection
          ? onEditIbcTreatment
          : () => {},
        disabled:
          chainsLoading ||
          !methodIdCheck.allEqual ||
          !contractAdddressCheck.allEqual ||
          !chainsCheck.allEqual ||
          !identifiersCheck.allEqual ||
          !credentialsCheck.allEqual ||
          (!isSolanaSelection && !isSupportedEvmSelection && !isIbcSelection) ||
          (isSolanaSelection && !identifiersCheck.val),
        adminOnly: true,
      },
      {
        label: 'Spec ID',
        onClick: onSetSpecIdTxn,
        adminOnly: false,
        disabled: !chain(specIdRequirements)
          .every(([condition]) => condition)
          .value(),
        tooltip: chain(specIdRequirements)
          .find(([condition]) => !condition)
          ?.last()
          ?.value() as string,
      },
    ];
  }, [
    txnsHaveBeenReviewed,
    numTxnsReviewed,
    onReviewTxns,
    isMultiPageSelection,
    onMergeTxns,
    shouldEnableMergeTxnsButton,
    onDuplicateTxns,
    onEditTxns,
    onDeleteTxns,
    txnsHaveSpam,
    numTxnsWithSpam,
    onMarkTxnsSpam,
    onEditEvmTreatment,
    onEditSolanaTreatment,
    onEditIbcTreatment,
    methodIdCheck.allEqual,
    contractAdddressCheck.allEqual,
    chainsCheck.allEqual,
    identifiersCheck.allEqual,
    identifiersCheck.val,
    credentialsCheck.allEqual,
    onSetSpecIdTxn,
    specIdRequirements,
    chainsLoading,
    reconLabel,
    isSolanaSelection,
    isSupportedEvmSelection,
    isIbcSelection,
    txnsCountAfterMerge,
    onCreateMovementTxn,
    movementMatchConstraintsLoading,
    shouldEnableMovementButton,
    selectionHasMovement,
  ]);

  if (!isTokenTaxAdmin) actions = actions.filter((r) => !r.adminOnly);

  const renderDesktopButtons = () => {
    return actions.map(({ label, onClick, disabled, tooltip }) => {
      const button = (
        <TextLink variant="secondary" key={label} onClick={onClick} disabled={disabled}>
          {label}
        </TextLink>
      );
      return tooltip ? (
        <Tooltip key={label} icon={<div className="flex">{button}</div>}>
          {tooltip}
        </Tooltip>
      ) : (
        button
      );
    });
  };

  const renderMobileButtons = () => {
    return actions.map(({ label, onClick, disabled, tooltip }) => {
      const button = (
        <button
          className="py-2 px-3 text-left hover:bg-light-hover dark:hover:bg-dark-hover last:rounded-b first:rounded-t disabled:opacity-50 disabled:pointer-events-none"
          key={label}
          onClick={onClick}
          disabled={disabled}
        >
          {label}
        </button>
      );
      return tooltip ? (
        <Tooltip key={label} icon={<div className="flex">{button}</div>}>
          {tooltip}
        </Tooltip>
      ) : (
        button
      );
    });
  };

  return (
    <div className={`flex flex-wrap items-center ${hidden ? 'hidden' : ''}`}>
      <Breadcrumb>{isMultiPageSelection ? totalCount : numSelected} Selected</Breadcrumb>
      <div className="flex-wrap hidden md:flex gap-6 ml-6">{renderDesktopButtons()}</div>
      <div className="flex md:hidden ml-3">
        <Dropdown isDisabled={false} label={'Actions'} ref={dropdownRef}>
          <div className="flex flex-col">{renderMobileButtons()}</div>
        </Dropdown>
      </div>
    </div>
  );
}

export default React.memo(SelectedActions);
