import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useUserContext } from '../../contexts';
import Card from '../Card';
import { WithPortal } from '../PortalLayout';
import Spinner from '../Spinner';
import TextLink from '../TextLink';
import { PLAN_ACTIVATION_DATA_LOCALSTORAGE_KEY } from './constants';
import { useRetrieveCheckout } from './queries';
import { PlanActivationStoredData } from './types';
import { activatePlanData } from './vars';

function Content() {
  const { isLoggedIn } = useUserContext();
  const checkoutSessionId = new URLSearchParams(useLocation<{ csId: string }>().search).get('csId') as string;

  const history = useHistory();

  const { data, loading } = useRetrieveCheckout({
    variables: { checkoutSessionId },
  });

  const { email, activated, plan, emailHasMatchingAccount } = data?.retrieveCheckout || {};

  useEffect(() => {
    if (loading || activated || !email) return;

    const data: PlanActivationStoredData = { email, checkoutSessionId };
    const dataString = JSON.stringify(data);
    localStorage.setItem(PLAN_ACTIVATION_DATA_LOCALSTORAGE_KEY, dataString);
    activatePlanData(dataString);

    if (isLoggedIn) {
      history.push('/home'); // PlanActivator will take care of the activation process
      return;
    }

    history.push(emailHasMatchingAccount ? '/login' : '/signup');
  }, [activated, checkoutSessionId, email, emailHasMatchingAccount, history, isLoggedIn, loading, plan]);

  if (loading) {
    return (
      <div className="flex flex-col items-center pb-8">
        <Spinner className="mt-12" />
      </div>
    );
  }

  if (activated) {
    return (
      <div className="py-8 text-center">
        <p className="text-lg">The purchased plan has already been activated.</p>
        <br />
        <TextLink to="/home">Return to home</TextLink>
      </div>
    );
  }

  return null;
}

function ActivateAccount() {
  return (
    <div className="mt-12 w-full max-w-lg">
      <Card className="pb-6">
        <Content />
      </Card>
    </div>
  );
}

export default React.memo(WithPortal(ActivateAccount, { className: 'pt-16 flex flex-col items-center' }));
