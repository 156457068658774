import React from 'react';
import { SupportedCredentialsQuery } from '../graphql/types';
import { ContentfulIntegration } from '../lib/contentfulClient';

export type IntegrationsContextType = {
  allIntegrations: ContentfulIntegration[];
  supportedIntegrations: ContentfulIntegration[];
  exchangeCredentials: SupportedCredentialsQuery['supportedCredentials']['exchangeCredentials'];
  supportedWallets: string[];
  evmCredentialTypes: string[];
  solanaCredentialTypes: string[];
  loading: boolean;
  isReconCredentialType: (id: string) => boolean;
};

export const IntegrationsContext = React.createContext<IntegrationsContextType>({
  allIntegrations: [],
  supportedIntegrations: [],
  exchangeCredentials: [],
  supportedWallets: [],
  evmCredentialTypes: [],
  solanaCredentialTypes: [],
  loading: true,
  isReconCredentialType: () => false,
});

IntegrationsContext.displayName = 'IntegrationsContext';
