import { ApolloClient } from '@apollo/client';
import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { showFlash } from '../components-v2/Flash';
import { VersionsContext } from '../contexts';
import { useVersionsQuery } from '../graphql/queries';
import { usePrevious } from '../hooks/usePrevious';
import { useObject } from '../lib/hooks';

const POLL_INTERVAL = 1000;

interface Props {
  client: ApolloClient<unknown>;
  children: ReactNode;
}

export default function VersionsProvider({ client, children }: Props) {
  const { data, loading, startPolling, stopPolling, refetch } = useVersionsQuery();

  const { isSavingCurrentVersion, isRestoringVersion, restoredVersionNumber, latestStatus } =
    data?.versions.status ?? {};

  const isSavingOrRestoring = Boolean(isSavingCurrentVersion || isRestoringVersion);
  const wasRestoringVersion = usePrevious(isRestoringVersion);
  const wasSavingCurrentVersion = usePrevious(isSavingCurrentVersion);
  const [isRefetching, setIsRefetching] = useState(false);

  const poll = useCallback(() => {
    refetch();
    startPolling(POLL_INTERVAL);
  }, [startPolling, refetch]);

  useLayoutEffect(() => {
    if (!isRestoringVersion && wasRestoringVersion) {
      const wasSuccessful = latestStatus === 'successful';
      const message = wasSuccessful
        ? `Version ${restoredVersionNumber} successfully restored`
        : `Could not restore version ${restoredVersionNumber}`;

      showFlash({ type: wasSuccessful ? 'success' : 'error', message });

      setIsRefetching(true);
      client.resetStore().then(() => {
        setIsRefetching(false);
      });
    }
  }, [isRestoringVersion, wasRestoringVersion, client, latestStatus, restoredVersionNumber]);

  useEffect(() => {
    if (!isSavingCurrentVersion && wasSavingCurrentVersion) {
      const wasSuccessful = latestStatus === 'successful';

      const message = wasSuccessful
        ? `Current version successfully saved`
        : `Could not restore the current version`;

      showFlash({ type: wasSuccessful ? 'success' : 'error', message });
    }
  });

  useEffect(() => {
    if (isSavingOrRestoring) {
      poll();
    } else {
      stopPolling();
    }
  }, [poll, stopPolling, isSavingOrRestoring]);

  const versions = data?.versions;
  const isRestoringOrRefetching = Boolean(isRestoringVersion || isRefetching);
  const contextValue = useObject({
    versions,
    loading,
    poll,
    isRestoringOrRefetching,
    refetch,
  });

  return <VersionsContext.Provider value={contextValue}>{children}</VersionsContext.Provider>;
}
