import { ApolloClient, gql } from '@apollo/client';
import Big from 'big.js';
import { memoize } from 'lodash';
import moment from 'moment';
import {
  BkpAccountsVendorsQuery,
  PriceFetchingSide,
  PriceForTxnQuery,
  PriceForTxnQueryVariables,
  PriceQuery,
  PriceQueryVariables,
  TxnFragment,
} from '../../../graphql/types';
import { calculateBuyPrice, calculateSellPrice } from '../../../utils/txnPrices';
import { getCurrencyForType } from './helpers';
import { CurrencyType } from './types';

export const BKP_ACCOUNTS_VENDORS = gql`
  query BkpAccountsVendors {
    bkpVendors {
      id
      name
      active
      bkpIntegrationId
      bkpIntegrationDisplayName
      bkpIntegrationSyncToken
      createdAt
      updatedAt
    }
    bkpAccounts {
      id
      name
      active
      bkpIntegrationId
      bkpIntegrationClassification
      bkpIntegrationAccountType
      bkpIntegrationSyncToken
      bkpIntegrationAcctNum
      bkpIntegrationParentRefId
      createdAt
      updatedAt
    }
  }
`;

export const fetchBkpAccountsVendors = async (client: ApolloClient<unknown>) => {
  const res = await client.query<BkpAccountsVendorsQuery>({
    query: BKP_ACCOUNTS_VENDORS,
  });

  const { data, loading, ...rest } = res;

  return { data, loading, ...rest };
};

export const PRICE = gql`
  query Price(
    $symbol: String!
    $tokenId: String
    $date: DateObject!
    $address: String
    $credentialType: String
  ) {
    price(symbol: $symbol, tokenId: $tokenId, date: $date, address: $address, credentialType: $credentialType)
  }
`;

export const fetchCurrencyPrice = memoize(
  async (client: ApolloClient<unknown>, variables: PriceQueryVariables) => {
    const res = await client.query<PriceQuery, PriceQueryVariables>({
      fetchPolicy: 'no-cache',
      query: PRICE,
      variables,
    });

    const { data } = res;
    const { tokenId, symbol } = variables;
    if (!data?.price) {
      throw new Error(`Price for ${tokenId || symbol} could not be fetched`);
    }

    return data.price;
  },
  // cache function calls by <timestamp, symbol, tokenId>
  (_client, { date, symbol, tokenId }: PriceQueryVariables) =>
    `${moment(date).valueOf()}-${symbol}-${tokenId}`,
);

export const PRICE_FOR_TXN = gql`
  query PriceForTxn(
    $currency: String!
    $soldCurrency: String!
    $buyTokenId: String
    $sellTokenId: String
    $buyAddress: String
    $sellAddress: String
    $credentialType: String!
    $priceFetchingSide: PriceFetchingSide
    $date: DateObject!
  ) {
    priceForTxn(
      currency: $currency
      soldCurrency: $soldCurrency
      buyTokenId: $buyTokenId
      sellTokenId: $sellTokenId
      priceFetchingSide: $priceFetchingSide
      buyAddress: $buyAddress
      sellAddress: $sellAddress
      credentialType: $credentialType
      date: $date
    ) {
      buyPrice
      sellPrice
    }
  }
`;

export const priceForTxn = async (client: ApolloClient<unknown>, variables: PriceForTxnQueryVariables) => {
  const res = await client.query<PriceForTxnQuery, PriceForTxnQueryVariables>({
    fetchPolicy: 'no-cache',
    query: PRICE_FOR_TXN,
    variables,
  });

  const { data } = res;

  if (!data?.priceForTxn) {
    throw new Error(`The prices for the transaction could not be fetched`);
  }

  return data.priceForTxn;
};

type AddPricesProps = {
  client: ApolloClient<unknown>;
  txn: TxnFragment;
  date: PriceForTxnQueryVariables['date'];
};

export const addPricesToTrade = async (
  props: AddPricesProps & {
    side: PriceFetchingSide | null;
  },
): Promise<TxnFragment> => {
  const { client, txn, date, side } = props;
  const { buyQuantity, sellQuantity, buyCurrency, sellCurrency, buyTokenId, sellTokenId, priceFetchingSide } =
    txn;
  const { buyAddress, sellAddress } = txn;
  const credentialType = txn.credential?.credentialType;

  if (buyQuantity === '0' || sellQuantity === '0') {
    return {
      ...txn,
      buyPrice: '0',
      sellPrice: '0',
    };
  }

  const pricesForTxn = await priceForTxn(client, {
    currency: buyCurrency,
    soldCurrency: sellCurrency,
    buyTokenId,
    sellTokenId,
    buyAddress,
    sellAddress,
    credentialType,
    priceFetchingSide: side || priceFetchingSide,
    date,
  });

  if (pricesForTxn.buyPrice != null) {
    const buyPrice = Big(pricesForTxn.buyPrice).toString();
    return {
      ...txn,
      buyPrice,
      sellPrice: calculateSellPrice({ buyPrice, buyQuantity, sellQuantity }),
      // don't delete the following line or a newly-chosen price fetching side won't be immediately saved
      ...(side ? { priceFetchingSide: side } : {}),
    };
  }

  if (pricesForTxn.sellPrice != null) {
    const sellPrice = Big(pricesForTxn.sellPrice).toString();
    return {
      ...txn,
      sellPrice,
      buyPrice: calculateBuyPrice({ sellPrice, buyQuantity, sellQuantity }),
      // don't delete the following line or a newly-chosen price fetching side won't be immediately saved
      ...(side ? { priceFetchingSide: side } : {}),
    };
  }

  throw new Error('Could not fetch prices for transaction');
};

export const addPriceToOneSidedTransaction = async (
  props: AddPricesProps & {
    type: CurrencyType | null;
  },
): Promise<TxnFragment> => {
  const { client, txn, date } = props;
  const { credentialType } = txn.credential ?? {};
  let { type } = props;

  if (!type) {
    // we want to fetch a price for a one-sided transaction but the user did not click on either side
    // this means they were editing multiple transactions and we showed a generic "side-agnostic" "Fetch price" button
    // in this case, infer which currency we're trying to fetch
    // it will be "buy" or "sell" as "fetch prices" on the Fee Price field always sends the `type` arg to this function
    const inferredType = (['buy', 'sell'] as const).find((t) => {
      const currency = txn[`${t}Currency`];
      return currency && currency.toUpperCase() !== 'USD';
    });
    if (!inferredType) {
      throw new Error(`No type to fetch prices for transaction`);
    }
    type = inferredType;
  }

  const symbol = getCurrencyForType(txn, type);
  if (!symbol) {
    throw new Error(`No symbol to fetch for transaction`);
  }

  const price = await fetchCurrencyPrice(client, {
    date,
    symbol: txn[`${type}Currency`]!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
    address: txn[`${type}Address`],
    credentialType,
    tokenId: txn[`${type}TokenId`],
  });

  return {
    ...txn,
    [`${type}Price`]: `${price}`,
  };
};
