import cx from 'classnames';
import React, { useContext } from 'react';

import { RecalcContext, UserContext } from '../../../contexts';
import { trackTaxDashboardRecalculated } from '../../../lib/analytics';
import { showConfirmationDialog } from '../../Modal/ConfirmationDialog';
import TextLink from '../../TextLink';

interface RecalculateButtonProps {
  className?: string;
}

function RecalculateButton({ className }: RecalculateButtonProps) {
  const { isTokenTaxAdmin, user, isHijacked } = useContext(UserContext);
  const { isRecalculating, recalculate } = useContext(RecalcContext);
  const disabled = !isTokenTaxAdmin && isRecalculating === true;

  return (
    <TextLink
      onClick={async () => {
        if (disabled) return;
        if (isRecalculating && isTokenTaxAdmin) {
          await showConfirmationDialog({
            title: 'Recalc in progress',
            content: function RecalcConfirmationDialog() {
              return <div className="mb-4">A recalc is in progress. Do you want to start a new one?</div>;
            },
            buttonText: 'Start new recalc',
          });
        }
        recalculate();
        trackTaxDashboardRecalculated({
          hijacked_account_email: user?.email as string,
          hijacked_account_id: user?.id as number,
          is_hijacked: !!isHijacked,
        });
      }}
      className={cx(
        className,
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer ',
        'flex-initial pt-2 pb-2',
      )}
    >
      Recalculate Data
    </TextLink>
  );
}

export default React.memo(RecalculateButton);
