import cx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

type CheckboxSize = 'large' | 'small' | 'xs';

const DEFAULT_SIZE: CheckboxSize = 'large';

type BaseCheckboxProps = {
  disabled?: boolean;
  minus?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onChange?: (checked: boolean) => void;
  size?: CheckboxSize;
  className?: string;
  label?: string;
  labelClassName?: string;
};

interface ControlledCheckboxProps extends BaseCheckboxProps {
  checked: boolean; // builds a controlled component
}

interface UncontrolledCheckboxProps extends BaseCheckboxProps {
  initialValue: boolean; // builds an uncontrolled component
}

type CheckboxProps = ControlledCheckboxProps | UncontrolledCheckboxProps;

function isControlledProps(props: CheckboxProps): props is ControlledCheckboxProps {
  return [].hasOwnProperty.call(props, 'checked');
}

const sizeToClassNameMap: Record<CheckboxSize, string> = {
  large: 'text-base',
  small: 'text-sm',
  xs: 'text-xs',
};

function Checkbox(props: CheckboxProps) {
  const {
    disabled,
    minus,
    onClick: onClickProp,
    onChange,
    size = DEFAULT_SIZE,
    className,
    labelClassName,
    label,
  } = props;
  const isControlled = isControlledProps(props);
  const [checked, setChecked] = useState(isControlled ? props.checked : props.initialValue);

  let onClick: BaseCheckboxProps['onClick'];
  if (isControlled) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setChecked(props.checked);
    }, [props.checked]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    onClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onClickProp?.(event);
        onChange?.(!checked);
      },
      [onClickProp, onChange, checked],
    );
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    onClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setChecked(!checked);
        onClickProp?.(event);
        onChange?.(!checked);
      },
      [onClickProp, onChange, checked],
    );
  }

  const classes = cx(
    'whitespace-nowrap',
    'outline-none inline-block leading-4 align-middle border rounded',
    'border-light-control hover:border-light-control-hover dark:border-dark-control dark:hover:border-dark-control-hover',
    checked || minus ? 'bg-light-control-active dark:bg-dark-control-primary' : 'bg-white dark:bg-dark-shade',
    'box-border',
  );

  const borderSize = size === 'small' ? '1px' : '2px';

  const base = {
    display: checked ? 'table' : 'none',
    position: 'absolute' as const,
    content: ' ',
    pointerEvents: 'none' as const,
  };

  const minusStyle = {
    ...base,
    display: 'table',
    left: size === 'small' ? '0.3em' : '0.35em',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '0.6em',
    borderWidth: '1px',
  };

  const checkStyle = {
    ...base,
    height: '1.2em',
    left: size === 'small' ? '0.7em' : '0.65em',
    top: '-0.2em',
    width: '0.5em',
    borderWidth: borderSize,
    transform: 'rotate(45deg)',
    borderTop: '0',
    borderLeft: '0',
  };

  return (
    <div
      onClick={onClick}
      className={cx(
        'flex items-center gap-1',
        disabled ? 'opacity-50 pointer-events-none' : 'cursor-pointer',
      )}
    >
      <div
        className={cx('relative', sizeToClassNameMap[size], className)}
        style={{ height: '1.25em', width: '1.25em' }}
      >
        <span className={classes} style={{ height: '1.25em', width: '1.25em' }} />
        <div style={minus ? minusStyle : checkStyle} className="border border-black dark:border-white" />
      </div>
      {props.label && (
        <span
          className={cx(size === 'large' ? 'ml-2' : 'ml-1', 'top-[2px] relative select-none', labelClassName)}
        >
          {label}
        </span>
      )}
    </div>
  );
}

export default React.memo(Checkbox);
