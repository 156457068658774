import { SortDirectionOptions } from '../../graphql/types';
import { ColumnsParams, PaginationQueryParams, SortQueryParams, Vars } from './types';

interface QueryStateSetter<SortOptions, Filters, IdType extends string | number> {
  query: Partial<PaginationQueryParams & SortQueryParams<SortOptions> & ColumnsParams>;
  vars: Vars<SortOptions, Filters, IdType>;
  storageKey?: string;
}

export function setPaginationState<SortOptions, Filters, IdType extends string | number>({
  query,
  vars,
  pageSize,
}: QueryStateSetter<SortOptions, Filters, IdType> & {
  pageSize: number;
}) {
  const { paginationVar } = vars;
  const { page } = paginationVar();

  if (!query.page || page === query.page) {
    return;
  }

  paginationVar({
    page: query.page,
    pageSize,
  });
}

export function setSortingState<SortOptions, Filters, IdType extends string | number>({
  query,
  vars,
  sortOptionsArray,
}: QueryStateSetter<SortOptions, Filters, IdType> & {
  sortOptionsArray: SortOptions[];
}) {
  const { sortVar } = vars;
  const sortBy = query.sortBy as SortOptions;
  const sortDir = query.sortDir as SortDirectionOptions;

  if (!(query.sortBy || query.sortDir)) {
    return;
  }

  const currentSortState = sortVar();
  const newSortState = { ...currentSortState };

  if (sortOptionsArray.includes(sortBy)) {
    newSortState.sortBy = sortBy;
  }

  if (Object.values(SortDirectionOptions).includes(sortDir)) {
    newSortState.sortDir = sortDir;
  }

  if (currentSortState.sortBy === sortBy && currentSortState.sortDir === sortDir) return;

  sortVar(newSortState);
}
