import React, { ReactNode } from 'react';
import Spinner from '../Spinner';
import classNames from 'classnames';

interface ToolbarProps {
  className?: string;
  background?: 'base' | 'shade';
  roundedTop?: boolean;
  paddingBottom?: boolean;
  sticky?: boolean;
  loading?: boolean;
  children?: ReactNode;
}

function Toolbar({
  className,
  background = 'base',
  roundedTop,
  paddingBottom = true,
  sticky,
  loading,
  children,
}: ToolbarProps): React.ReactElement {
  const classes = classNames(
    'px-4',
    paddingBottom ? 'py-3' : 'pt-3',
    `border-b bg-light-${background} dark:bg-dark-${background} flex flex-wrap items-center`,
    roundedTop && 'rounded-t',
    sticky && 'sticky top-0',
  );
  return (
    // if roundedTop and sticky then we need to set top to -1 to get rid of the rounded corners
    <div className={classNames(classes, className)} style={sticky && roundedTop ? { top: -1 } : {}}>
      {children}
      {loading && <Spinner className="ml-2" size="sm" />}
    </div>
  );
}

export default React.memo(Toolbar) as unknown as typeof Toolbar;
