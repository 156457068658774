import React, { useCallback } from 'react';

import classNames from 'classnames';
import Checkbox from '../../../Input/Checkbox';
import { textVariantClassnames } from '../../../Text';
import EllipsedText from './EllipsedText';

export interface OptionType<IdType extends string | number> {
  id: IdType;
  label: string | null;
  subLabel?: string | null;
  metadata?: string | null;
}

interface ExtraProps<IdType extends string | number> {
  isSelected?: boolean;
  onChange: (id: IdType) => void;
}

function Option<IdType extends string | number>(props: OptionType<IdType> & ExtraProps<IdType>) {
  const { label, subLabel, isSelected, onChange, id } = props;
  const toggleOption = useCallback(() => onChange(id), [id, onChange]);

  return (
    <div className="flex flex-row px-3 py-2 cursor-pointer hover:bg-light-hover hover:dark:bg-dark-hover">
      <Checkbox checked={Boolean(isSelected)} size="small" onClick={toggleOption} />
      <div className="flex-shrink flex-grow overflow-hidden ml-3" onClick={toggleOption}>
        <EllipsedText>{label}</EllipsedText>
        <EllipsedText className={classNames(textVariantClassnames.muted)}>{subLabel}</EllipsedText>
      </div>
    </div>
  );
}

export default React.memo(Option) as typeof Option;
