import { ReactiveVar, useReactiveVar } from '@apollo/client';
import classNames from 'classnames';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Cell, SelectCell } from '../Table';
import { Actions, Item, MultiSelectState } from './types';

interface Props<T extends Item<IdType>, SortOptions, FilteringQueryParams, IdType extends string | number> {
  cell: Cell<T, IdType>;
  items?: Item<IdType>[];
  multiSelectVar: ReactiveVar<MultiSelectState<IdType>>;
  actions: Actions<SortOptions, FilteringQueryParams, IdType>;
  selectionMenu?: ReactNode;
}

function SelectedItemHeader<
  T extends Item<IdType>,
  SortOptions,
  FilteringQueryParams,
  IdType extends string | number,
>({
  cell,
  items,
  multiSelectVar,
  actions,
  selectionMenu,
}: Props<T, SortOptions, FilteringQueryParams, IdType>) {
  const multiSelectState = useReactiveVar(multiSelectVar);
  const { selected, selectionSpan } = multiSelectState;
  const [popperVisible, setPopperVisible] = useState(false);
  const checkboxRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(checkboxRef.current, popperRef.current, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -1],
        },
      },
    ],
  });

  const onDropdownClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);

  return (
    <div className="relative">
      <div
        className={classNames(
          'flex relative items-center select-none group content-center justify-center',
          selectionMenu && [
            'cursor-pointer p-2 pr-0 -ml-2 border z-20',
            popperVisible
              ? 'border-light-control dark:border-dark-control border-b-[#FCFCFC] dark:border-b-[#1C1C1C]'
              : 'border-transparent hover:bg-light-hover dark:hover:bg-dark-hover',
          ],
        )}
        style={{
          borderRadius: popperVisible ? '3px 3px 0 0' : '3px',
        }}
        ref={checkboxRef}
        onClick={useCallback(() => {
          setPopperVisible((visible) => !visible);
        }, [])}
      >
        <SelectCell<T, IdType>
          cell={cell}
          checked={!!selected.length}
          className={classNames(!selectionMenu && 'flex')}
          minus={selected.length > 0 && selected.length < ((items && items.length) || 0)}
          onClick={useCallback(() => {
            if (selectionSpan === null) {
              actions.selectMany(items, 'Page');
            } else {
              actions.selectMany([], null);
            }
          }, [actions, items, selectionSpan])}
        />

        {selectionMenu && (
          <i
            className={classNames(
              'material-icons text-lg leading-3 opacity-0 group-hover:opacity-100',
              popperVisible && 'opacity-100',
            )}
          >
            arrow_drop_down
          </i>
        )}
      </div>

      {selectionMenu && (
        <div
          ref={popperRef}
          className={`bg-light-base dark:bg-dark-base border border-light-control dark:border-dark-control border-r rounded relative ${
            popperVisible ? 'visible z-10' : 'invisible -z-1'
          }`}
          style={{
            ...styles.popper,
            borderTopLeftRadius: 0,
          }}
          onClick={onDropdownClick}
          {...attributes.popper}
        >
          {selectionMenu}
        </div>
      )}
    </div>
  );
}

export default React.memo(SelectedItemHeader) as typeof SelectedItemHeader;
