import { gql, useMutation } from '@apollo/client';
import React, { ReactNode, useCallback, useContext, useState } from 'react';
import { RecalcContext } from '../../../contexts';
import { DuplicateTxnsMutation, DuplicateTxnsMutationVariables } from '../../../graphql/types';
import { getApolloErrorMessages } from '../../../lib/errors';
import Button from '../../Button';
import { showFlash } from '../../Flash';
import Input from '../../Input';
import Modal, { ModalProps, showModal } from '../../Modal';
import Spinner from '../../Spinner';
import { ErrorToast } from '../../Toast';

interface DuplicateModalProps extends ModalProps {
  txnIds: string[];
  onTxnDuplicated: () => void;
}

const DUPLICATE_TRANSACTION = gql`
  mutation duplicateTxns($ids: [String!]!, $count: Int!) {
    duplicateTxns(ids: $ids, count: $count)
  }
`;

function DuplicateModal({ onTxnDuplicated, onClose, txnIds }: DuplicateModalProps) {
  const { refetchNeedsRecalc } = useContext(RecalcContext);
  const [error, setError] = useState<ReactNode>(null);
  const [count, setCount] = useState('1');

  const [duplicateTxn, { loading }] = useMutation<DuplicateTxnsMutation, DuplicateTxnsMutationVariables>(
    DUPLICATE_TRANSACTION,
    {
      onCompleted: () => {
        showFlash({
          message: `Transaction duplicated`,
          type: 'success',
        });
        refetchNeedsRecalc();
        onTxnDuplicated();

        // currently need to do this async as there seems to be a bug with reactiveVar's not committing two subsequent changes in the same sync stack
        setTimeout(onClose as () => void);
      },
    },
  );

  const handleSubmit = useCallback(async () => {
    try {
      await duplicateTxn({
        variables: { ids: txnIds, count: +count },
      });
    } catch (error) {
      setError(<>The transaction could not be duplicated. {getApolloErrorMessages(error) || `${error}`}</>);
    }
  }, [duplicateTxn, txnIds, count]);

  const message = `How many duplicates do you want to make of the selected transaction?`;

  return (
    <Modal title={`Duplicate transactions`} subtitle={message} onClose={onClose}>
      {error && <ErrorToast>{error}</ErrorToast>}
      <Input
        className="mb-8"
        type="number"
        min="1"
        max="10"
        value={+count}
        onChange={(e) => setCount(e.target.value)}
      />
      <Button disabled={loading} onClick={handleSubmit} type="submit">
        {loading && <Spinner className="mr-2" size="sm" />}Duplicate
      </Button>
    </Modal>
  );
}

export default React.memo(DuplicateModal);

export function showDuplicateTransactionModal(props: DuplicateModalProps) {
  showModal(<DuplicateModal {...props} />);
}
