import { useReactiveVar } from '@apollo/client';
import { sortBy } from 'lodash';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { PlansContext, UserContext } from '../../contexts';
import { FetchUserQuery, UserTaxMethodsQuery } from '../../graphql/types';
import Auth, { tokenDataVar, TOKENTAX_ADMIN_ROLE } from '../../lib/Auth';
import { useObject, useQueryAndRefetchOnUserChange } from '../../lib/hooks';
import { getCurrentUserRole } from './currentUserRole';
import { USER_QUERY, USER_TAX_METHODS } from './queries';
import { useBatchJobsStatus } from './useBatchJobsStatus';

interface Props {
  children: ReactNode;
}

export default function UserProvider({ children }: Props) {
  const userQueryRes = useQueryAndRefetchOnUserChange<FetchUserQuery>(USER_QUERY, {
    fetchPolicy: 'network-only',
  });

  const taxMethodsQueryRes = useQueryAndRefetchOnUserChange<UserTaxMethodsQuery>(USER_TAX_METHODS, {
    fetchPolicy: 'network-only',
  });

  const { data, refetch, loading } = userQueryRes;
  const tokenData = useReactiveVar(tokenDataVar);
  const isLoggedIn = Boolean(tokenData);

  const refetchIfLoggedIn = useCallback(() => {
    if (!isLoggedIn) return;
    refetch();
  }, [isLoggedIn, refetch]);

  // Refetch user data when the window regains focus
  // Many times TT employes will have multiple tabs open and will update user data in one tab
  useEffect(() => {
    window.addEventListener('focus', refetchIfLoggedIn);
    return () => {
      window.removeEventListener('focus', refetchIfLoggedIn);
    };
  }, [refetchIfLoggedIn]);

  const user = data?.user;
  const batchJobsStatus = useBatchJobsStatus();
  const hijackedUserId = Auth.getHijackedUserId();
  const isHijacked = Boolean(hijackedUserId);

  const taxMethods = taxMethodsQueryRes.data?.userTaxMethods?.methods ?? [];

  const tokenDataRoles = useMemo(() => tokenData?.roles ?? [], [tokenData?.roles]);
  const roles = useMemo(
    () => (loading ? tokenDataRoles : user?.orgRoles?.map(({ role: { name } }) => name) ?? []),
    [user?.orgRoles, loading, tokenDataRoles],
  );
  const isTokenTaxAdmin = tokenDataRoles.includes(TOKENTAX_ADMIN_ROLE);

  const currentUserRole = getCurrentUserRole(roles);
  const organizationId = user?.orgRoles.find((orgRole) => orgRole.organizationId)?.organizationId || null;

  const { taxDetails = [] } = user ?? {};
  const { cryptoOnlyPlans } = useContext(PlansContext);
  const rankedPlansFromHighest = sortBy(cryptoOnlyPlans, 'rank').reverse();
  const highestPlan = rankedPlansFromHighest.find((plan) =>
    taxDetails.some((taxDetail) => taxDetail.plan === plan.id),
  )?.id;

  const value = useObject({
    isHijacked,
    refetch,
    user,
    isLoggedIn,
    taxMethods,
    isTokenTaxAdmin,
    roles,
    currentUserRole,
    organizationId,
    ...batchJobsStatus,
    highestPlan,
  });

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
