import { LineItemCounts, LineItemData } from './types';
import { LineItemsFilterQuery } from '../../../graphql/types';

const getIsEmptyFilterQuery = (
  counts: LineItemCounts,
  page: number,
  data: LineItemData[],
  filterQuery: LineItemsFilterQuery,
): boolean => {
  const hasFiltersOrIsPaginated = !!(
    page > 1 ||
    filterQuery?.currency ||
    filterQuery?.date ||
    filterQuery?.boolean
  );
  const dataIsEmpty = data.length === 0;
  const hasLineItems = counts.lineItemCount > 0;
  return hasFiltersOrIsPaginated && dataIsEmpty && hasLineItems;
};

export default {
  getIsEmptyFilterQuery,
};
