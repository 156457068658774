import React from 'react';
import { FormStatus } from '../FormStatus';
import { PLAN_ACTIVATION_DATA_LOCALSTORAGE_KEY } from './constants';

function ActivatePlanNotice() {
  const hasPendingActivation = Boolean(localStorage.getItem(PLAN_ACTIVATION_DATA_LOCALSTORAGE_KEY));

  if (hasPendingActivation) {
    return <FormStatus type="success">Login or signup to activate your plan</FormStatus>;
  }

  return null;
}

export default ActivatePlanNotice;
