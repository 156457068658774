import React, { PropsWithChildren } from 'react';
import { DropdownIndicatorProps, GroupBase, components } from 'react-select';
import { OptionType } from './types';

function DropdownIndicatorFunc<
  Option extends OptionType = OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ children, ...props }: PropsWithChildren<DropdownIndicatorProps<Option, IsMulti, Group>>) {
  return (
    <components.DropdownIndicator {...props}>
      {children}
      <span className="material-icons text-[20px]">arrow_drop_down</span>
    </components.DropdownIndicator>
  );
}

export const DropdownIndicator = React.memo(DropdownIndicatorFunc) as unknown as typeof DropdownIndicatorFunc;
