import { noop } from 'lodash';
import React from 'react';

import { TimeUnitsObject } from '../lib/formatters';

export interface RecalcData {
  isRecalculating?: boolean;
  setForcedIsRecalculatingState: (value: boolean) => void;
  needsRecalc: boolean;
  refetchNeedsRecalc: () => void;
  recalculate: (options?: { allYearOverride?: boolean }) => Promise<void>;
  noTaxYearsToRecalculate: boolean;
  latestJobState?: 'completed' | 'created' | 'failed';
  currentJobDurationObject: TimeUnitsObject | null;
}

export const RecalcContext = React.createContext<RecalcData>({
  isRecalculating: undefined,
  setForcedIsRecalculatingState: noop,
  needsRecalc: false,
  refetchNeedsRecalc: noop,
  recalculate: () => Promise.resolve(),
  noTaxYearsToRecalculate: false,
  latestJobState: undefined,
  currentJobDurationObject: null,
});
RecalcContext.displayName = 'RecalcContext';

export const useRecalcContext = () => React.useContext(RecalcContext);
