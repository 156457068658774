import React, { useCallback } from 'react';
import cx from 'classnames';
import { flatten } from 'lodash';
import { ColumnInstance, UseSortByColumnProps, UseSortByInstanceProps, IdType } from 'react-table';

import { TableChildrenProps } from '../types';
import { useTableContext } from '../context';
import HeaderCell from '../Cells/HeaderCell';
import { SortDirectionOptions } from '../../../graphql/types';

function StaticHeader<T extends Record<string, unknown>>({
  data,
}: TableChildrenProps<T>): React.ReactElement {
  const { instance, gridStyle, headerClassName } = useTableContext<T>();

  if (instance === undefined) {
    throw Error('table instance should not be undefined for StaticHeader');
  }

  const { headerGroups } = instance;

  const sortedInstance = instance as unknown as UseSortByInstanceProps<T>;

  // TODO: this should use same HeaderCell component as ControlledHeader

  const setSortBy = useCallback(
    (columnId: IdType<T>, currentSortedState: SortDirectionOptions | null) => {
      let newSortedDesc = false;
      if (currentSortedState === 'asc') {
        newSortedDesc = true;
      }

      sortedInstance.setSortBy([{ id: columnId, desc: newSortedDesc }]);
    },
    [sortedInstance],
  );

  // we don't use/support headerGroups as shown in https://react-table.tanstack.com/docs/examples/grouping-column
  // no need to complicate the markup for now
  const allHeaders = flatten(headerGroups.map(({ headers }) => headers));

  return (
    <div
      className={cx(
        'sticky bg-light-shade dark:bg-dark-shade rounded-t z-50 border-solid border-b',
        headerClassName,
      )}
      style={{ ...gridStyle }}
    >
      {allHeaders.map((column: ColumnInstance<T>) => {
        const sortedColumn = column as unknown as UseSortByColumnProps<T>;
        const headerProps = column.getHeaderProps();
        const sorted: SortDirectionOptions | null = sortedColumn.isSorted
          ? sortedColumn.isSortedDesc
            ? ('desc' as SortDirectionOptions)
            : ('asc' as SortDirectionOptions)
          : null;

        return (
          <HeaderCell
            data={data}
            key={headerProps.key}
            column={column}
            sorted={sorted}
            onSort={() => setSortBy(column.id, sorted)}
            tableType="controlled"
          />
        );
      })}
    </div>
  );
}

export default React.memo(StaticHeader);
