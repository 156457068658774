import { gql } from '@apollo/client';

export const DOCUMENT = gql`
  fragment Document on Report {
    id
    reportType
    createdAt
    createdById
    createdByAdmin
    status
    taxYear
    method
    url
    failedReason
    hidden
    margin
    extraCols
  }
`;
