import React from 'react';
import { TableChildrenProps } from '../types';
import { useTableContext } from '../context';

import Row from '../Row';

function StaticBody<T extends Record<string, unknown>>(props: TableChildrenProps<T>): React.ReactElement {
  const { instance } = useTableContext<T>();
  const { onRowClicked, isRowClickable } = props;

  if (instance === undefined) {
    throw Error('table instance should not be undefined for StaticBody');
  }

  const { getTableBodyProps, prepareRow, rows } = instance;

  return (
    <div {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        const { key, role } = row.getRowProps();
        return (
          <Row<T>
            key={String(key)}
            row={row}
            role={role}
            onClick={onRowClicked}
            isRowClickable={isRowClickable}
          />
        );
      })}
    </div>
  );
}

export default React.memo(StaticBody) as unknown as typeof StaticBody;
