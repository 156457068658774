import { useApolloClient } from '@apollo/client';
import { useCallback, useContext, useState } from 'react';
import { RecalcContext } from '../../../contexts';
import { updateTxns } from '../../../graphql/mutations';
import { TxnFragment } from '../../../graphql/types';
import {
  TRANSACTION_TYPES_WITH_BOTH_BUY_AND_SELL_PRICES,
  calculateBuyPrice,
  calculateSellPrice,
} from '../../../utils/txnPrices';
import { showErrorFlash } from '../../Flash';
import { useUndo } from '../../hooks';
import { AllTransactionsContextType } from './context';

export const useOnEditCell = ({
  refetch,
  txns,
  checkSpecIdDelinkRequirement,
}: Required<Pick<AllTransactionsContextType, 'refetch' | 'txns' | 'checkSpecIdDelinkRequirement'>>) => {
  const apolloClient = useApolloClient();
  const { refetchNeedsRecalc } = useContext(RecalcContext);
  const [previousValueOfLastEditedTxn, setPreviousValueOfLastEditedTxn] = useState<TxnFragment>();

  useUndo(async () => {
    if (!previousValueOfLastEditedTxn) return;
    await updateTxns(apolloClient, [previousValueOfLastEditedTxn], refetchNeedsRecalc);
    refetch?.();
    setPreviousValueOfLastEditedTxn(undefined);
  });

  const onEditCell = useCallback(
    async (editedTxnId: string, column: string, value: string) => {
      const txn = txns?.find(({ id }) => id === editedTxnId);
      if (!txn) throw new Error(`Could not find transaction "${editedTxnId}"`);

      await checkSpecIdDelinkRequirement(editedTxnId); // this will throw if the operation is canceled

      const { sellPrice, buyPrice, buyQuantity, sellQuantity, txnType } = txn;
      const newValues = {
        [column]: value,
        ...([
          'income', // https://linear.app/tokentax/issue/TOK-1002/inline-transaction-editing-allow-admins-to-edit-sell-side-for-income
          ...TRANSACTION_TYPES_WITH_BOTH_BUY_AND_SELL_PRICES,
        ].includes(txnType) && column === 'sellQuantity'
          ? {
              buyPrice: calculateBuyPrice({ sellPrice, buyQuantity, sellQuantity: value }),
            }
          : {}),
        ...([
          'spend', // https://linear.app/tokentax/issue/TOK-2041/allow-inline-editing-for-fiat-side-of-spend-txns
          ...TRANSACTION_TYPES_WITH_BOTH_BUY_AND_SELL_PRICES,
        ].includes(txnType) && column === 'buyQuantity'
          ? {
              sellPrice: calculateSellPrice({ buyPrice, sellQuantity, buyQuantity: value }),
            }
          : {}),
        ...(column === 'sellPrice'
          ? {
              buyPrice: calculateBuyPrice({ sellPrice: value, buyQuantity, sellQuantity }),
            }
          : {}),
        ...(column === 'buyPrice'
          ? {
              sellPrice: calculateSellPrice({ buyPrice: value, buyQuantity, sellQuantity }),
            }
          : {}),
        ...(column === 'buyCurrency' ? { buyTokenId: null } : {}),
        ...(column === 'sellCurrency' ? { sellTokenId: null } : {}),
        ...(column === 'feeCurrency' ? { feeTokenId: null } : {}),
      };

      try {
        const data = await updateTxns(apolloClient, [{ ...txn, ...newValues }], refetchNeedsRecalc);
        setPreviousValueOfLastEditedTxn(txn);
        refetch?.();
        if (data?.updateTxns.updatedTxns.length === 0) {
          throw new Error(
            data?.updateTxns.errors?.[0]?.error || 'There was a problem editing the cell. Please try again.',
          );
        }
      } catch (error) {
        showErrorFlash('Could not update transaction.', { error });
        throw error;
      }
    },
    [txns, checkSpecIdDelinkRequirement, apolloClient, refetchNeedsRecalc, refetch],
  );

  return onEditCell;
};
