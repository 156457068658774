import { GoogleOAuthProvider } from '@react-oauth/google';
import noop from 'lodash/noop';
import { HTML5toTouch } from 'rdndmb-html5-to-touch'; // or any other pipeline
import React, { Component, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { useLocation } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { ThemeProvider } from 'styled-components';
import HeaderV2 from './components-v2/Header';
import Routes from './components/Routes';
import './css/styles.css';
import './css/tailwind.pcss';
import theme from './css/theme';
import { stripeKey } from './lib/constants';
import ThemedIntercomLauncher from './theme/ThemedIntercomLauncher';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

class AppComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { stripe: null };
  }

  componentDidMount() {
    this.setState({
      stripe: window.Stripe(stripeKey),
    });

    if (!window.analytics) {
      window.analytics = { track: noop, identify: noop };
    }
    // trigger google optimize
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'optimize.activate',
      });
    }
  }

  render() {
    return (
      <GoogleOAuthProvider clientId={process.env.RAZZLE_APP_GOOGLE_CLIENT_ID || ''}>
        <StripeProvider stripe={this.state.stripe}>
          <ThemeProvider theme={theme}>
            <ScrollToTop />
            <HeaderV2 />
            <ThemedIntercomLauncher />
            <DndProvider backend={MultiBackend} options={HTML5toTouch}>
              <Routes />
            </DndProvider>
          </ThemeProvider>
        </StripeProvider>
      </GoogleOAuthProvider>
    );
  }
}

AppComponent.defaultProps = {
  needsFetching: false,
};

export default AppComponent;
