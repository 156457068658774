import React from 'react';

import Modal, { ModalProps, showModal } from '../../Modal';
import TextLink from '../../TextLink';
import Text from '../../Text';

export type Content = 'gains' | 'income' | 'report' | 'calculate' | undefined;

interface Props {
  onClose?: () => void;
}

const ImportLink = ({ onClose }: Props) => {
  return (
    <div className="mt-6">
      <TextLink to="/import" onClick={onClose}>
        Import more data
      </TextLink>
    </div>
  );
};

const GainsContent = ({ onClose }: Props) => (
  <div className="pb-4">
    <p className="pt-4">
      Pricing of tokens could be greatly overstated at the moment, and reconciliation still needs to be done.
      It can also mean you are missing data. Essentially, we don't know where you acquired your crypto from.
      With this data missing, we must assume that you acquired your crypto at no cost.
    </p>
    <p className="pt-4">To fix this please upload the rest of your cryptocurrency data.</p>
    <ImportLink onClose={onClose} />
  </div>
);

const IncomeContent = ({ onClose }: Props) => (
  <div className="pb-4">
    <p className="pt-4">This can happen for a few reasons</p>
    <ul className="list-inside pt-4">
      <li className="list-disc">We're missing staking or income transactions in your dataset</li>
      <li className="list-disc">You are using a defi yield farming protocol that we do not support</li>
    </ul>
    <p className="pt-4">To fix this please upload the rest of your cryptocurrency data.</p>
    <ImportLink onClose={onClose} />
  </div>
);

const ReportContent = ({ onClose }: Props) => (
  <div className="pb-4">
    <p className="pt-4">
      This most likely means you are missing data. Essentially, we don't know where you acquired your crypto
      from. With this data missing, we must assume that you acquired your crypto at no cost.
    </p>
    <p className="pt-4">To fix this please upload the rest of your cryptocurrency data.</p>
    <ImportLink onClose={onClose} />
  </div>
);

const CalculateContent = () => (
  <div className="pb-4">
    <p className="pt-4">
      The next step is to reconcile your numbers. Please review your tax liability and gain loss to see if
      everything looks accurate. If the numbers appear to be too high, this means you most likely have missing
      cost basis.
    </p>
  </div>
);

interface HelpModalProps extends ModalProps {
  content: Content;
}

const HelpModal = ({ content, title, onClose }: HelpModalProps) => {
  return (
    <Modal title={title} onClose={onClose}>
      <Text variant="muted">
        {content === 'gains' && <GainsContent onClose={onClose} />}
        {content === 'income' && <IncomeContent onClose={onClose} />}
        {content === 'report' && <ReportContent onClose={onClose} />}
        {content === 'calculate' && <CalculateContent />}
      </Text>
    </Modal>
  );
};

export default HelpModal;

export function showHelpModal(props: HelpModalProps) {
  showModal(<HelpModal {...props} />);
}
