import { sum } from 'lodash';
import { TxnFragment } from '../../graphql/types';

export const getSpecIdQuantities = ({
  txn,
  specIdSellId,
}: {
  txn: Pick<
    TxnFragment,
    'buyQuantity' | 'buyCurrency' | 'feeQuantity' | 'feeCurrency' | 'specIdMatchesAsComponent'
  >;
  specIdSellId: string;
}) => {
  const { buyQuantity, feeCurrency, buyCurrency, feeQuantity, specIdMatchesAsComponent } = txn;

  let fullAvailableQuantity = parseFloat(buyQuantity);
  if (feeCurrency === buyCurrency) {
    fullAvailableQuantity -= feeQuantity ?? 0;
  }

  const amountsUsedForTxnsOtherThanCurrentSpecId = specIdMatchesAsComponent
    .filter(({ sellId }) => sellId !== specIdSellId)
    .map(({ componentQuantity }) => componentQuantity);

  const quantityUsedInOtherSpecs = sum(amountsUsedForTxnsOtherThanCurrentSpecId);

  return {
    fullAvailableQuantity,
    quantityUsedInOtherSpecs,
  };
};
