import React from 'react';
import { useUserContext } from '../../../contexts';
import { useAllAccounts } from '../../../graphql/queries/accounts';
import { LineItemsFilterQuery, MultiSelectIntFilter } from '../../../graphql/types';
import FilterBuilder, { DropdownPill } from '../../FilterBuilder';
import CurrencyFilter, { CurrencyInput } from '../../FilterBuilder/CurrencyFilter';
import DateRangeFilter, { DateRangeInput } from '../../FilterBuilder/DateRangeFilter';
import MultiSearchFilter, { MultiSearchInput } from '../../FilterBuilder/MultiSearchFilter';
import { actions } from './state';
import { useFiltersConfig } from './useFiltersConfig';

// {key: [affirmativeTerm, negativeTerm]}
const booleanOperatorMap: { [key: string]: string[] } = {
  missingCostBasis: ['Is missing', 'Has'],
  zeroProceeds: ['Has zero', 'Has'],
};

const booleanLabelMap: { [key: string]: string } = {
  missingCostBasis: 'Cost Basis',
  zeroProceeds: 'Proceeds',
};

function Filters({ filterQuery }: { filterQuery: LineItemsFilterQuery }) {
  const { currency, boolean, date } = filterQuery;
  const [firstCurrencyFilter, ...restCurrencyFilters] = currency || [];
  const filtersMap = useFiltersConfig();
  const { user } = useUserContext();

  const { data: accounts } = useAllAccounts();

  const accountOptions = accounts?.map((account) => ({ label: account.name, id: account.id })) || [];

  return (
    <FilterBuilder
      filterQuery={filterQuery}
      filtersMap={filtersMap}
      inputMenuWidth="160px"
      renderSelectedInput={({ selectedInput: key }) => {
        switch (key) {
          case 'date':
            return (
              <DateRangeInput {...filtersMap[key]} onChange={(filter) => actions.setDateFilter(filter)} />
            );
          case 'currency':
            return (
              <CurrencyInput
                {...filtersMap.currency}
                onChange={(filter) => actions.addCurrencyFilter(filter)}
              />
            );
          case 'account':
            if (!filtersMap.account) return null;
            return (
              <MultiSearchInput<MultiSelectIntFilter, number>
                {...filtersMap.account}
                multiSearchOptions={accountOptions}
                onChange={(filter) => actions.setAccountFilter(filter as MultiSelectIntFilter)}
              />
            );
          default:
            return null;
        }
      }}
    >
      <DateRangeFilter
        {...filtersMap.date}
        filter={date}
        onChange={(filter) => actions.setLineItemFilterQuery({ date: filter })}
        onDelete={() => actions.setLineItemFilterQuery(undefined)}
      />
      {user?.recalcByAccount && filtersMap.account && (
        <MultiSearchFilter
          {...filtersMap.account}
          filter={filterQuery.account}
          multiSearchOptions={accountOptions}
          onChange={(filter) => actions.setAccountFilter(filter)}
          onDelete={() => actions.clearAccoutFilter()}
        />
      )}
      {[firstCurrencyFilter, ...restCurrencyFilters].map((filter, idx) => (
        <CurrencyFilter
          {...filtersMap.currency}
          key={idx}
          filter={filter}
          onChange={(filter) => actions.editCurrencyFilter(idx, filter)}
          onDelete={() => actions.removeFilter('currency', idx)}
        />
      ))}
      {boolean?.map((filter, idx) => (
        <DropdownPill<boolean>
          key={idx}
          filter={filter.value}
          options={[
            { label: booleanOperatorMap[filter.key][0], value: true },
            { label: booleanOperatorMap[filter.key][1], value: false },
          ]}
          label={booleanLabelMap[filter.key]}
          pillCopy={booleanLabelMap[filter.key]}
          onChange={(value) => actions.editBooleanFilter(idx, { ...filter, value })}
          onDelete={() => actions.removeFilter('boolean', idx)}
        />
      ))}
    </FilterBuilder>
  );
}

export default React.memo(Filters) as typeof Filters;
