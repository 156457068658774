import { SetRequired } from 'type-fest';
import { BUY_PRICE_ONLY_TYPES, SELL_PRICE_ONLY_TYPES } from '../../../../lib/constants';
import { TWO_SIDED_CATEGORIES } from '../Wizard/constants';
import { SideOrBoth } from '../Wizard/types';
import { buildDraftFromTransfers } from './buildDraftFromTransfers';
import { getComplexTreatment } from './complexTreatments';
import { applyNetMergeWherePossible } from './netMerge';
import { Category, Draft, UnreconciledTransaction } from './types';

const { received, sent } = SideOrBoth;

type Props = {
  category: Category;
  txn: SetRequired<UnreconciledTransaction, 'txns'>;
  lpTokenTickersByAddress: Record<string, string>;
};

export const getInitialDraftsForTxn = (props: Props): Draft[] => {
  let { category, txn, lpTokenTickersByAddress } = props; // eslint-disable-line prefer-const
  const { txns } = txn;
  const sentTxns = txns.filter((txn) => SELL_PRICE_ONLY_TYPES.includes(txn.txnType));
  const receivedTxns = txns.filter((txn) => BUY_PRICE_ONLY_TYPES.includes(txn.txnType));
  const isTradeLike = TWO_SIDED_CATEGORIES.includes(category);

  const { drafts: complexTreatmentDrafts, replacementCategory } = getComplexTreatment({
    category,
    txn,
    sentTxns,
    receivedTxns,
    lpTokenTickersByAddress,
  });
  if (complexTreatmentDrafts) return complexTreatmentDrafts;
  if (replacementCategory) {
    // we found a matching complex treatment, but it's not applicable
    // the treatment function instructed to treat this txn as if a different category had been chosen
    category = replacementCategory;
  }

  if (!isTradeLike) {
    const txnsMap = { sent: sentTxns, received: receivedTxns };

    // break out each txn ("transfer") into its own draft
    return [sent, received].flatMap((side) =>
      buildDraftFromTransfers({ category, lpTokenTickersByAddress, txns: txnsMap[side] }),
    );
  }

  // if we made it thus far, the transaction is trade-like (two-sided)
  // for these transactions, the default case is an N-1 merge (N >=1 withdrawals, 1 deposit - or viceversa)
  // additionally, if any component txns have the same currency, they need to be net-merged
  // the end result of this may not be a "trade" transaction
  // see details in https://linear.app/tokentax/issue/TOK-2345/sum-matching-currencies-in-merge-to-trade-aka-net-merge

  // try to net-merge txns but only if they're two-sided https://linear.app/tokentax/issue/TOK-2657/unable-to-mark-deposited-token-as-income-when-it-is-withdrawn-in-the
  const allTxnsForDraft = TWO_SIDED_CATEGORIES.includes(category) ? applyNetMergeWherePossible(txns) : txns;

  return buildDraftFromTransfers({
    category,
    lpTokenTickersByAddress,
    txns: allTxnsForDraft,
  });
};
