import { BUY_PRICE_ONLY_TYPES, SELL_PRICE_ONLY_TYPES } from '../../../../lib/constants';
import { CATEGORY_DATA, TWO_SIDED_CATEGORIES } from '../Wizard/constants';
import { Side } from '../Wizard/types';
import { Category, CoreTxn, Draft, TransferReceived, TransferSent, TransfersGroup } from './types';

const getTransferTypeForCategory = (category: Category, side: Side, isPartOfTrade: boolean) => {
  const categoryData = CATEGORY_DATA[category];
  const type = categoryData[`${side}Type`];

  if (type) return type;
  if (isPartOfTrade) return side === Side.sent ? 'sold' : 'bought';

  return side === Side.sent ? 'sent' : 'received';
};

const buildTransfer = ({
  txn,
  type,
  side,
  lpTokenTickersByAddress,
}: {
  txn: CoreTxn;
  type: TransferSent | TransferReceived;
  side: Side;
  lpTokenTickersByAddress: Record<string, string>;
}) => ({
  id: txn.id,
  type,
  currency:
    side === 'sent'
      ? lpTokenTickersByAddress[txn.sellAddress!] ?? txn.sellCurrency // eslint-disable-line @typescript-eslint/no-non-null-assertion
      : lpTokenTickersByAddress[txn.buyAddress!] ?? txn.buyCurrency, // eslint-disable-line @typescript-eslint/no-non-null-assertion
  tokenId: side === 'sent' ? txn.sellTokenId : txn.buyTokenId,
  quantity: side === 'sent' ? txn.sellQuantity : txn.buyQuantity,
  price: side === 'sent' ? txn.sellPrice : txn.buyPrice,
});

export const buildDraftFromTransfers = (props: {
  category: Category;
  lpTokenTickersByAddress: Record<string, string>;
  txns: CoreTxn[];
}): Draft[] => {
  const { category, lpTokenTickersByAddress, txns } = props;
  const sent = txns.filter((txn) => SELL_PRICE_ONLY_TYPES.includes(txn.txnType));
  const received = txns.filter((txn) => BUY_PRICE_ONLY_TYPES.includes(txn.txnType));
  const isTradeLike =
    [sent, received].every((txns) => txns.length > 0) || TWO_SIDED_CATEGORIES.includes(category);

  if (isTradeLike) {
    const draft: Draft = {};
    if (sent.length) {
      const type = getTransferTypeForCategory(category, Side.sent, isTradeLike);
      draft.sent = {
        grouped: false,
        txns: sent.map((txn) => buildTransfer({ txn, type, side: Side.sent, lpTokenTickersByAddress })),
      };
    }
    if (received.length) {
      const type = getTransferTypeForCategory(category, Side.received, isTradeLike);
      draft.received = {
        grouped: false,
        txns: received.map((txn) =>
          buildTransfer({ txn, type, side: Side.received, lpTokenTickersByAddress }),
        ),
      };
    }
    // add placeholder if necessary
    for (const side of [Side.sent, Side.received]) {
      if (!draft[side]) {
        draft[side] = {
          grouped: false,
          txns: [null],
        };
      }
    }

    return [draft];
  }

  // if we made it thus far, the transaction is not trade-like (it's one-sided)
  // return a draft for each transfer
  const side = sent.length ? Side.sent : Side.received;
  const type = getTransferTypeForCategory(category, side, isTradeLike);

  return txns.map((txn) => {
    const group: TransfersGroup = {
      grouped: false,
      txns: [buildTransfer({ txn, type, side, lpTokenTickersByAddress })],
    };
    return {
      [side]: group,
    };
  });
};
