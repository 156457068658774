import { range } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import ArrowButton from './ArrowButton';
import PageButton, { SHOW_SPINNER_PAGE_NUMBER } from './PageButton';

type Props = {
  currentPage?: number;
  totalPages?: number;
  onPageChange: (page: number) => void;
  loading?: boolean;
};

function Pagination({ currentPage = 1, totalPages = 1, onPageChange, loading }: Props) {
  const pageButtons = useMemo(() => {
    if (loading) {
      return [SHOW_SPINNER_PAGE_NUMBER];
    }
    if (totalPages === 1) {
      // everything fits in one page -> just show page 1
      return [1];
    }

    if (totalPages <= 5) {
      // there are multiple pages, but fewer than 6 -> show all pages
      return range(1, totalPages + 1);
    }

    if (currentPage <= 3) {
      // there are more than 5 pages and the current page is in the first 3 pages
      // -> show the first three pages, an ellipsis, and the last page
      return [1, 2, 3, null, totalPages];
    }

    if (currentPage <= totalPages - 3) {
      // there are more than 5 pages and the current page is not within the first or last 3 pages
      // show the first page, an ellipsis, the current page, an ellipsis, and the last page
      return [1, null, currentPage, null, totalPages];
    }

    // there are more than 5 pages and the current page is within the last 3 pages
    // show the first page, an ellipsis, and the last three pages
    return [1, null, totalPages - 2, totalPages - 1, totalPages];
  }, [currentPage, loading, totalPages]);

  const hasPrevious = currentPage !== 1;
  const hasNext = currentPage !== totalPages;

  const onPrevious = useCallback(() => {
    if (!hasPrevious) return;
    onPageChange(currentPage - 1);
  }, [hasPrevious, onPageChange, currentPage]);

  const onNext = useCallback(() => {
    if (!hasNext) return;
    onPageChange(currentPage + 1);
  }, [hasNext, currentPage, onPageChange]);

  return (
    <div className="inline-flex items-center">
      <ArrowButton direction="left" disabled={!hasPrevious} onClick={onPrevious} />
      <ul className="mr-2 border border-light-control dark:border-dark-control rounded inline-flex overflow-hidden">
        {pageButtons.map((page, buttonIndex) => (
          <PageButton
            key={page ? `page-${page}` : `ellipsis-${buttonIndex}`}
            page={page}
            isActivePage={page === currentPage}
            isLastButton={buttonIndex === pageButtons.length - 1}
            handlePageChange={onPageChange}
          />
        ))}
      </ul>

      <ArrowButton direction="right" disabled={!hasNext} onClick={onNext} />
    </div>
  );
}

export default React.memo(Pagination);
