import PropTypes from 'prop-types';
import React, { Suspense, lazy, useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import ActivateAccount from '../components-v2/ActivateAccount';
import ActivatePlan from '../components-v2/ActivatePlan';
import AdminProvider from '../components-v2/Admin/AdminProvider';
import { default as AllTransactionsV2 } from '../components-v2/AllTransactions';
import CheckoutSuccessful from '../components-v2/CheckoutSuccessful';
import CreateInvoice from '../components-v2/CreateInvoice';
import ErrorBoundary, { ErrorSlate } from '../components-v2/ErrorBoundary';
import ForgotPassword from '../components-v2/ForgotPassword';
import IncomeDashboard from '../components-v2/IncomeDashboard';
import Invoice from '../components-v2/Invoice';
import Page from '../components-v2/Layout/Page';
import LoadingComponent from '../components-v2/LoadingComponent';
import Login from '../components-v2/Login';
import { showMetamaskPromoModal } from '../components-v2/MetamaskPromo/MetamaskPromoModal';
import { METAMASK_PROMO_COOKIE } from '../components-v2/MetamaskPromo/consts';
import ResetPassword from '../components-v2/ResetPassword';
import Signup from '../components-v2/Signup';
import { default as TaxDashboardV2 } from '../components-v2/TaxDashboard/container';
import TaxGuide from '../components-v2/TaxGuide';
import { UserContext } from '../contexts';
import { usePrevious } from '../hooks/usePrevious';
import Auth from '../lib/Auth';
import { getCookie } from '../lib/Auth/getCookie';
import { ROUTES } from '../routes';
import { Container } from './General';
import PageNotFound from './PageNotFound/components';

const ReconProfile = lazy(() => import('../components-v2/ReconProfile'));
const SelfRecon = lazy(() => import('../components-v2/SelfRecon'));
const TaxLossHarvesting = lazy(() => import('../components-v2/TaxLossHarvesting'));
const GettingStartedWithVIP = lazy(() => import('../components-v2/GettingStartedWithVIP'));
const Wizard = lazy(() => import('./Wizard'));
const Purchase = lazy(() => import('../components-v2/Purchase'));
const Documents = lazy(() => import('../components-v2/DocumentCenter'));
const Settings = lazy(() => import('../components-v2/Settings'));
const ClientDashboard = lazy(() => import('./Organization/Clients'));
const Import = lazy(() => import('../components-v2/Import'));
const Admin = lazy(() => import('../components-v2/Admin'));

const isOrgOwnerOrAdmin = (role) => role === 'org-owner' || role === 'org-admin';

const ProtectedRoute = ({ permit, ...props }) => {
  if (permit) return <Route {...props} />;

  return <Redirect to="/" />;
};

ProtectedRoute.propTypes = {
  permit: PropTypes.bool,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  children: PropTypes.node,
};

const Routes = () => {
  const history = useHistory();
  const { currentUserRole, isHijacked, isLoggedIn } = useContext(UserContext);
  const prevUserRole = usePrevious(currentUserRole);

  // when we hijack a user, we don't know from their userId alone if they're an org admin
  // so by default we redirect them from `/home` to the tax dashboard
  // once we're done fetching the user (which happens asynchronously while the dashboard is loaded)
  // if we find out the user is actually an org admin, redirect them to the clients page.
  // this is an acceptable tradeoff between speed in most cases (loading the user async)
  // and a quick "flash" in the rare scenario where admins hijack org admins
  useEffect(() => {
    if (prevUserRole === 'user' && isOrgOwnerOrAdmin(currentUserRole)) {
      history.replace('/clients');
    }
  }, [currentUserRole, history, prevUserRole]);

  const tokenIsForAdmin = Auth.tokenIsForAdmin();
  const userIsUser = (isLoggedIn && currentUserRole === 'user') || currentUserRole === 'org-user';
  const userIsOrgOwnerOrAdmin = isLoggedIn && isOrgOwnerOrAdmin(currentUserRole);

  useEffect(() => {
    let isModalOpen = false;
    const cookieValue = getCookie(METAMASK_PROMO_COOKIE);
    if (cookieValue && !isModalOpen) {
      showMetamaskPromoModal({ cookieValue });
      isModalOpen = true;
    }
    return () => {
      isModalOpen = false;
    };
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return (
      <Route
        path="/*"
        render={() => {
          if (Auth.getHijackedUserId()) {
            // we're not logged in but there is a hijackedUserId in the URL
            // most likely an error caused by pasting a hijacked-state URL while logged out
            // remove the parameter before continuing
            setTimeout(Auth.unsetHijackedUserIdSearchParam); // setTimeout as this is a side-effect which render in theory shouldn't have
            return;
          }

          return (
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route exact path="/signup">
                <Signup />
              </Route>
              <Route exact path="/forgot">
                <ForgotPassword />
              </Route>
              <Route exact path="/reset/:token">
                <ResetPassword />
              </Route>
              <Route exact path="/invoice">
                <Invoice />
              </Route>
              <Route exact path="/activate">
                <ActivateAccount />
              </Route>
              <Route exact path="/activate-plan">
                <ActivatePlan />
              </Route>
              <Route exact path="/checkout-successful">
                <CheckoutSuccessful />
              </Route>
              <Route path="/*">
                <Redirect to="/login" />
              </Route>
            </Switch>
          );
        }}
      />
    );
  }

  return (
    <Switch>
      <Route exact path="/invoice">
        <Invoice />
      </Route>

      <Route exact path="/activate">
        <ActivateAccount />
      </Route>

      <Route>
        <ErrorBoundary
          fallback={
            <Container>
              <ErrorSlate linkTo="/import" message="Issue loading component" />
            </Container>
          }
        >
          <Suspense
            fallback={
              <Page>
                <LoadingComponent />
              </Page>
            }
          >
            <Switch>
              <Route path="/*">
                <Switch>
                  <Route path="/login">
                    <Redirect to="/" />
                  </Route>
                  <Route path="/signup">
                    <Redirect to="/" />
                  </Route>
                  <Route exact path="/forgot">
                    <Redirect to="/" />
                  </Route>
                  <Route exact path="/reset/:token">
                    <Redirect to="/" />
                  </Route>
                  <Route exact path="/activate-plan">
                    <ActivatePlan />
                  </Route>
                  <Route exact path="/checkout-successful">
                    <CheckoutSuccessful />
                  </Route>

                  <ProtectedRoute
                    exact
                    path={['/home', '/']}
                    permit={isLoggedIn}
                    render={() => {
                      switch (currentUserRole) {
                        case 'tokentax-admin':
                          return <Redirect to="/tokentax-admin" />;
                        case 'org-owner':
                        case 'org-admin':
                          return <Redirect to="/clients" />;
                        case 'user':
                        default:
                          return <Redirect to="/dashboard" />;
                      }
                    }}
                  />
                  {/* TOKENTAX ADMIN ROUTES (/invoice/admin is a nested route, look for uses of <InvoiceAdmin />) */}
                  <ProtectedRoute path={['/tokentax-admin', '/invoice/admin']} permit={tokenIsForAdmin}>
                    <AdminProvider>
                      <Admin />
                    </AdminProvider>
                  </ProtectedRoute>
                  <ProtectedRoute path="/clients" permit={userIsOrgOwnerOrAdmin}>
                    <ClientDashboard />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/invoice/create/:userId?" permit={tokenIsForAdmin}>
                    <CreateInvoice />
                  </ProtectedRoute>
                  {/* MAIN USER ROUTES*/}
                  <ProtectedRoute path="/*" permit={userIsUser}>
                    <Switch>
                      <Route exact path="/dashboard">
                        <TaxDashboardV2 />
                      </Route>
                      <Route path="/all-transactions">
                        <AllTransactionsV2 />
                      </Route>

                      <Route path="/tax-loss-harvesting">
                        <TaxLossHarvesting />
                      </Route>
                      <Route path="/income">
                        <IncomeDashboard />
                      </Route>
                      <Route path="/tax-guide">
                        <TaxGuide />
                      </Route>
                      <Route path="/profile">
                        <ReconProfile />
                      </Route>

                      <Route path="/getting-started-with-vip">
                        <GettingStartedWithVIP />
                      </Route>
                      <Route path="/start">
                        <Container>
                          <Wizard />
                        </Container>
                      </Route>
                      <Route path="/import" exact>
                        <Import />
                      </Route>
                      <Route path={['/import/*']}>
                        <Import />
                      </Route>
                      <Route path={ROUTES.documents}>
                        <Documents />
                      </Route>
                      <Route path="/purchase">
                        <Purchase />
                      </Route>
                      <Route path="/pay">
                        <Redirect to="/purchase" />
                      </Route>
                      <Route path="/settings">
                        <Settings isHijacked={isHijacked} />
                      </Route>
                      <Route path="/recon*">
                        <SelfRecon />
                      </Route>
                      <Route path="*">
                        <PageNotFound />
                      </Route>
                    </Switch>
                  </ProtectedRoute>
                  <Route path="*">{isLoggedIn ? <PageNotFound /> : <></>}</Route>
                </Switch>
              </Route>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Route>
    </Switch>
  );
};

export default Routes;
