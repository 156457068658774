import { useApolloClient, useReactiveVar } from '@apollo/client';
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react';

import { SortDirectionOptions } from '../../graphql/types';
import { useObject } from '../../lib/hooks';
import {
  Body,
  Column,
  ControlledTable,
  Footer,
  HandlePageChange,
  HandleSort,
  Header,
  TablePagination,
} from '../Table';
import ColumnSelector from './ColumnSelector';
import { SelectionColumn, getSelectionColumn } from './columns';
import type { Actions, Item, QueryParamsConf, Response, Vars } from './types';
import { useUrlQuery } from './useUrlQuery';
export { usePageCounts } from './usePageCounts';

export * from './actions';
export * from './queryState';
export * from './types';
export { buildVars } from './vars';

interface Props<
  Data extends Item<IdType>,
  SortOptions,
  Filters,
  FilteringQueryParams,
  IdType extends string | number,
> {
  columns: Column<Data>[];
  tableData?: Response<Data>;
  loading: boolean;
  countsLoading?: boolean;
  renderToolbar: (columnSelector: ReactElement) => ReactElement | null;
  externalToolbar?: boolean; // Render the toolbar outside of the table so it's responsive to window size
  filterParamsConf: QueryParamsConf;
  vars: Vars<SortOptions, Filters, IdType>;
  totalPages: number;
  actions: Actions<SortOptions, FilteringQueryParams, IdType>;
  gridTemplateColumns?: string;
  blankslate?: ReactElement | null;
  onRowClicked?: (id: string) => void;
  isRowClickable?: (data: Data) => boolean;
  paddingLeft?: string;
  paddingRight?: string;
  selectable?: boolean;
  showFooter?: boolean;
  className?: string;
  gridGapSize?: string;
  disabledIds?: IdType[] | 'all';
  selectionMenu?: ReactNode; // optional menu to show below "select" checkbox in the header
  hiddenColumnsStorageKey?: string;
  selectionColumnOverrides?: Partial<SelectionColumn<IdType, Data, SortOptions, FilteringQueryParams>>;
  headerOverlay?: ReactNode;
}

export default function FilteredTable<
  IdType extends string | number,
  Data extends Item<IdType>,
  SortOptions extends string,
  Filters,
  FilteringQueryParams,
>(props: Props<Data, SortOptions, Filters, FilteringQueryParams, IdType>) {
  const {
    columns,
    tableData,
    loading,
    countsLoading,
    renderToolbar,
    externalToolbar,
    filterParamsConf,
    vars,
    totalPages,
    actions,
    onRowClicked,
    isRowClickable,
    paddingLeft,
    paddingRight,
    selectable = true,
    showFooter,
    blankslate,
    className,
    gridGapSize,
    disabledIds,
    selectionMenu,
    hiddenColumnsStorageKey,
    headerOverlay,
  } = props;
  const { sortVar, initialLoadingVar, hiddenColumnsVar, multiSelectVar, paginationVar } = vars;

  const sortState = useReactiveVar(sortVar);

  const initialLoadingState = useReactiveVar(initialLoadingVar);

  useEffect(() => {
    if (tableData !== undefined && initialLoadingState) {
      actions.setInitialLoading(false);
    }
  }, [actions, tableData, initialLoadingState]);

  // we are in 'initialLoad' state if we do not yet have data and haven't had data before
  const initialLoad = initialLoadingState && tableData === undefined;
  const { sortBy, sortDir: sortDirection } = sortState;
  const { page } = useReactiveVar(paginationVar);
  const controlledState = useObject({
    page,
    totalPages,
    loading,
    countsLoading,
    initialLoad,
    sortBy,
    sortDirection,
  });

  const data = useMemo(() => tableData?.edges ?? [], [tableData?.edges]);

  const handleSort: HandleSort<Data> = useCallback(
    ({ id }, sorted) => {
      if (!id) return;
      actions.setSortBy(id as SortOptions);
      const newSorted = sorted === 'asc' ? 'desc' : 'asc';
      actions.setSortDir(newSorted as SortDirectionOptions);
      actions.resetPagination();
    },
    [actions],
  );

  const handlePageChange: HandlePageChange = useCallback(
    (desiredPage) => {
      actions.setPage(desiredPage);
    },
    [actions],
  );

  useUrlQuery<SortOptions, Filters, FilteringQueryParams, IdType>({ vars, filterParamsConf, actions });

  const client = useApolloClient();

  useEffect(() => {
    client.onResetStore(async () => {
      actions.resetState();
    });
  }, [actions, client]);

  const hiddenColumnIds = useReactiveVar(hiddenColumnsVar);
  const selectedColumns = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => columns.filter(({ id }) => !hiddenColumnIds?.includes(id!)),
    [columns, hiddenColumnIds],
  );

  useEffect(() => {
    const newState = hiddenColumnIds?.length === 0 ? undefined : hiddenColumnIds;
    if (!hiddenColumnsStorageKey) return;
    if (newState) {
      localStorage.setItem(hiddenColumnsStorageKey, JSON.stringify(newState));
    } else {
      localStorage.removeItem(hiddenColumnsStorageKey);
    }
  }, [hiddenColumnIds, hiddenColumnsStorageKey]);

  const selectionColumn = useMemo(
    () =>
      ({
        ...getSelectionColumn<IdType, Data, SortOptions, FilteringQueryParams>({
          multiSelectVar,
          items: data,
          actions,
          selectionMenu,
        }),
        ...props.selectionColumnOverrides,
      }) as Column<Data>,
    [props.selectionColumnOverrides, multiSelectVar, data, actions, selectionMenu],
  );

  const allColumns = useMemo(
    () => [...(selectable ? [selectionColumn] : []), ...selectedColumns],
    [selectable, selectionColumn, selectedColumns],
  );

  const columnSelector = useMemo(
    () => (
      <ColumnSelector<SortOptions, FilteringQueryParams, IdType>
        dropDownPlacement={externalToolbar ? 'bottom-end' : 'bottom-start'} // If the toolbar is external, we want the menu to align to the right to avoid overflow issues
        {...{ hiddenColumnsVar, actions, columns }}
      />
    ),
    [actions, columns, hiddenColumnsVar, externalToolbar],
  );

  return (
    <>
      {externalToolbar && renderToolbar(columnSelector)}

      <ControlledTable<Data>
        name="AllTransactionTable"
        columns={allColumns}
        controlledState={controlledState}
        data={data}
        handleSort={handleSort}
        handlePageChange={handlePageChange}
        {...{
          paddingLeft,
          paddingRight,
        }}
        blankslate={blankslate}
        className={className}
        gridGapSize={gridGapSize}
        disabledIds={useMemo(() => {
          // TODO we shouldn't have to cast IdType to string here
          // ControlledTable and all sub-components should be generic with respect to IdType
          if (disabledIds === 'all') return data.map((d) => String(d.id));
          return disabledIds as string[];
        }, [data, disabledIds])}
        headerOverlay={headerOverlay}
      >
        {!externalToolbar && <>{renderToolbar(columnSelector)}</>}
        {!blankslate && (
          <>
            <Header data={data} />
            <Body<Data> data={data} onRowClicked={onRowClicked} isRowClickable={isRowClickable} />
            {showFooter && <Footer />}
            <TablePagination />
          </>
        )}
      </ControlledTable>
    </>
  );
}
