import { gql } from '@apollo/client';

export const SPEC_ID_MATCH = gql`
  fragment SpecIdMatch on SpecIdMatch {
    componentId
    sellId
    componentQuantity
    componentOrder
  }
`;
