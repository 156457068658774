import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql/error';
import { get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { closeModals } from '../components-v2/Modal';
import { clearFlash } from '../variables';

const getErrorCode = (graphQLError: GraphQLError) => get(graphQLError, 'extensions.code') as string | null;

const getMessageForGraphQLError = (graphQLError: GraphQLError) => {
  const code = getErrorCode(graphQLError);
  switch (code) {
    case 'YEAR_LOCKED': {
      return (
        <>
          Tax years are locked until the year {get(graphQLError, 'extensions.lockedTilYear')}. You can edit
          your tax details in the{' '}
          <Link
            className="underline"
            to="/settings/tax-details"
            onClick={() => {
              closeModals();
              clearFlash();
            }}
          >
            settings
          </Link>
          .
        </>
      );
    }
    case 'BATCH_UPDATE_IN_PROGRESS': {
      return <>Batch transaction update is in progress. Please try again in a few minutes.</>;
    }
    case 'BATCH_DELETE_IN_PROGRESS': {
      return <>Batch transaction delete is in progress. Please try again in a few minutes.</>;
    }
    case 'RECONCILIATION_IN_PROGRESS': {
      return <>Reconciliation is in progress. Please try again in a few minutes.</>;
    }
  }
  return [];
};

export const getApolloErrorMessageCodes = (error: unknown) => {
  if (!(error instanceof ApolloError)) return null;
  const { graphQLErrors } = error;

  return graphQLErrors.flatMap(getErrorCode);
};

export const getApolloErrorMessages = (error: unknown) => {
  if (!(error instanceof ApolloError)) return null;
  const { graphQLErrors } = error;

  const messages = graphQLErrors.flatMap(getMessageForGraphQLError);
  return messages.length > 0 ? messages : null;
};

export const showFlashIfKnownError = (error: unknown) => {
  if (!(error instanceof ApolloError)) return;
};
