import cx from 'classnames';
import React, { FocusEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { formatNumber } from '../../NumberFormat';
import { CellProps } from '../types';

const testDiv = document.createElement('div');
testDiv.setAttribute('contenteditable', 'PLAINTEXT-ONLY');
const supportsPlaintextEditables = testDiv.contentEditable === 'plaintext-only';

function AmountCell<T extends Record<string, unknown>, Prop extends keyof T>(
  props: CellProps<T, T[Prop]>,
): React.ReactElement {
  const {
    cell: { value: cellValue },
    column: { rightAligned },
    onEditCell,
    onBlur,
    onFocus,
    rowIsDisabled,
  } = props;

  const value = typeof cellValue === 'number' ? cellValue : parseFloat(cellValue as string);

  const [renderCounter, setRenderCounter] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const formattedValue = formatNumber(value);

  // update the innerText whenever it changes (or a re-render is forced)
  useEffect(() => {
    if (!ref.current) return;
    ref.current.innerText = formattedValue;
  }, [renderCounter, formattedValue]);

  let skipSubmit = false;

  const onKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLDivElement>) => {
      const { currentTarget, key, ctrlKey, metaKey } = evt;
      const currentValue = currentTarget?.innerText as string;
      const isUsingModifier = ctrlKey || metaKey; // e.g. copying

      if (key === 'Enter') {
        evt.preventDefault();
        currentTarget.blur();
        return;
      }

      if (key === 'Escape') {
        skipSubmit = true; // eslint-disable-line react-hooks/exhaustive-deps
        currentTarget.blur();
        skipSubmit = false;
        setRenderCounter((count: number) => count + 1); // forces re-render
        return;
      }

      // allow dots (but not duplicates)
      if (key === '.') {
        if (currentValue.includes('.')) {
          evt.preventDefault();
        }
        return;
      }

      if (
        isFinite(Number(key)) || // allow numbers
        key === 'Backspace' ||
        key.startsWith('Arrow') || // navigation
        isUsingModifier
      ) {
        return;
      }

      evt.preventDefault(); // disallow any other character
    },
    [onEditCell],
  );

  const onBlurCallback = useCallback<FocusEventHandler<HTMLDivElement>>(
    async (evt) => {
      onBlur?.();

      const textValue = evt.currentTarget.innerText
        .replaceAll(',', '') // strip thousands separator https://linear.app/tokentax/issue/TOK-2039/qol-let-inline-edits-accept-numbers-with-commas
        .replaceAll('\n', ''); // strip newlines - some websites such as etherscan will insert them in the markup so they get carreid over when copy/pasting

      if (textValue === '' && !Number.isFinite(value)) {
        ref.current!.innerText = '-'; // eslint-disable-line @typescript-eslint/no-non-null-assertion
        return;
      }
      if (skipSubmit || textValue === formattedValue) return;

      try {
        // onEditCell must be defined as otherwise onBlur is not set at all
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await onEditCell!(textValue);
      } catch (e) {
        ref.current!.innerText = formattedValue; // eslint-disable-line @typescript-eslint/no-non-null-assertion
      }
    },
    [onEditCell, skipSubmit, onBlur, formattedValue, value],
  );

  const onMouseDownCallback = useCallback(() => {
    if (!Number.isFinite(value)) {
      ref.current!.innerText = ''; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }
  }, [value]);

  return (
    <div
      ref={ref}
      className={cx(
        'flex outline-none',
        rightAligned ? 'justify-end' : 'justify-start',
        onEditCell && 'py-3',
      )}
      style={{ paddingRight: 16 }}
      {...(onEditCell && !rowIsDisabled
        ? {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            contentEditable: supportsPlaintextEditables ? ('plaintext-only' as any) : true,
            onKeyDown,
            onBlur: onBlurCallback,
            onFocus,
            onMouseDown: onMouseDownCallback,
          }
        : {})}
    />
  );
}

export default React.memo(AmountCell) as unknown as typeof AmountCell;
