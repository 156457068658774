import React from 'react';
import { Helmet } from 'react-helmet';

import { useTheme } from './useTheme';
import { colors } from './colors';

function ThemedIntercomLauncher() {
  const { theme } = useTheme();
  const color = theme === 'dark' ? colors.grey.dark[7] : colors.grey.light[9];
  return (
    <Helmet>
      <style>{`.intercom-launcher { background-color: ${color} !important;}`}</style>
    </Helmet>
  );
}

export default React.memo(ThemedIntercomLauncher);
