/**
 * Refer to the design system Figma file
 * https://www.figma.com/file/IsoYtCgTJgihhqcEiU9keJ/Design-Library?node-id=758%3A29716&t=h4nxLxK1Y42XzJ7X-1
 */
const { colors, classMap } = require('./src/theme/colors');

module.exports = {
  mode: 'jit',
  content: [
    './src/**/*.html',
    './src/**/*.pug',
    './src/**/*.js',
    './src/**/*.jsx',
    './src/**/*.ts',
    './src/**/*.tsx',
    './src/**/*.html',
    './.storybook/**/*.pug',
    './.storybook/**/*.js',
    './.storybook/**/*.jsx',
    './.storybook/**/*.ts',
    './.storybook/**/*.tsx',
  ],
  theme: {
    extend: {
      keyframes: {
        slideAbsolute: {
          '0%': { left: '-20%' },
          '50%': { left: '80%' },
          '100%': { left: '-20%' },
        },
      },
      animation: {
        slideAbsolute: 'slideAbsolute 2s ease-in-out infinite',
      },
      inset: {
        1: '1rem',
      },
      zIndex: {
        '-1': '-1',
        999: '999',
        9999: '9999',
        99999: '99999',
        max: '2147483647',
      },
      opacity: {
        60: '.6',
        80: '.8',
        90: '.9',
      },
      colors: {
        ...classMap, // Map of colors to use arbitrarily in the app { 'color-shade-level': '#hex', }
        'light-brand': colors.primary.light[12],
        'light-control-primary-label': colors.grey.light[1],
        'light-control-secondary-label': colors.grey.light[12],
        'light-alert-success': colors.green.light[10],
        'light-alert-warning': colors.yellow.light[9],
        'light-alert-critical': colors.red.light[11],
        'dark-brand': colors.grey.dark[12],
        'dark-brand-alt': colors.primary.dark[11], // for border based icons
        'dark-control-primary-label': colors.grey.dark[12],
        'dark-control-secondary-label': colors.grey.dark[12],
        'dark-alert-success': colors.green.dark[2],
        'dark-alert-warning': colors.yellow.dark[9],
        'dark-alert-critical': colors.red.dark[10],
        'light-muted': colors.grey.light[11],
        'dark-muted': colors.grey.dark[11],
        'light-faint': colors.grey.light[8],
        'dark-faint': colors.grey.dark[8],
      },
      textColor: {
        'light-base': colors.grey.light[12],
        'light-muted': colors.grey.light[11],
        'light-faint': colors.grey.light[8],
        'light-link': colors.primary.light[12],
        'light-link-hover': colors.primary.light[9],
        'dark-base': colors.grey.dark[12],
        'dark-muted': colors.grey.dark[11],
        'dark-faint': colors.grey.dark[8],
        'dark-link': colors.primary.dark[11],
        'dark-link-hover': colors.primary.dark[10],

        'light-alert-success': colors.green.light[10],
        'light-alert-warning': colors.yellow.light[9],
        'light-alert-critical': colors.red.light[11],
        'dark-alert-success': colors.green.dark[9],
        'dark-alert-warning': colors.yellow.dark[9],
        'dark-alert-critical': colors.red.dark[10],
      },
      maxWidth: {
        cell: '8.2rem',
        '7xl': '80rem',
        '8xl': '88rem',
        '9xl': '96rem',
      },
      width: {
        44: '11rem',
        48: '12rem',
        52: '13rem',
        56: '14rem',
        60: '15rem',
        64: '16rem',
        72: '18rem',
        80: '20rem',
        88: '22rem',
        96: '24rem',
        112: '28rem',
        136: '34rem',
        152: '38rem',
        184: '46rem',
        208: '52rem',
        240: '58rem',
      },
      spacing: {
        28: '7rem',
        72: '18rem',
        84: '21rem',
        96: '24rem',
      },
      screens: {
        xs: '480px',
        short: { raw: '(max-height: 850px)' },
      },
      borderColor: {
        'light-base': colors.grey.light[6],
        'light-faint': colors.grey.light[5],
        'light-control': colors.grey.light[8],
        'light-control-hover': colors.grey.light[9],
        'light-control-focus': colors.primary.light[12],
        'light-control-critical': colors.red.light[11],
        'light-alert': colors.overlay.light[3],
        'dark-base': colors.grey.dark[6],
        'dark-faint': colors.grey.dark[5],
        'dark-control': colors.grey.dark[8],
        'dark-control-hover': colors.grey.dark[9],
        'dark-control-focus': colors.primary.dark[11],
        'dark-control-critical': colors.red.dark[10],
        'dark-alert': colors.overlay.dark[4],

        // these border colors are not in the styleguide _as borders_, but rather as background colors
        // nevertheless we have a need for gree/yellow/red borders in the app, so we're adding them here
        'light-alert-success': colors.green.light[10],
        'light-alert-warning': colors.yellow.light[9],
        'light-alert-critical': colors.red.light[11],
        'dark-alert-success': colors.green.dark[9],
        'dark-alert-warning': colors.yellow.dark[9],
        'dark-alert-critical': colors.red.dark[10],
      },
      boxShadow: { focus: 'inset 0 0 0 3px rgba(195, 218, 254, 1)' },
      backgroundColor: {
        'light-base': colors.grey.light[1],
        'light-hover': colors.grey.light[3],
        'light-hover-faint': colors.grey.light[2],
        'light-selected': colors.primary.light[3],
        'light-selected-faint': colors.primary.light[1],
        'light-selected-hover-faint': colors.primary.light[2],
        'light-shade': colors.grey.light[3],
        'light-shade-hover': colors.grey.light[6],
        'light-sub': colors.grey.light[2],
        'dark-base': colors.grey.dark[2],
        'dark-hover': colors.grey.dark[4],
        'dark-hover-faint': colors.grey.dark[3],
        'dark-selected': colors.primary.dark[6],
        'dark-selected-faint': colors.primary.dark[2],
        'dark-selected-hover-faint': colors.primary.dark[3],
        'dark-shade': colors.grey.dark[3],
        'dark-shade-hover': colors.grey.dark[6],
        'dark-sub': colors.grey.dark[1],

        'light-site-bg': colors.grey.light[3],
        'dark-site-bg': colors.grey.dark[1],

        'light-header-bg': `#252441`,
        'dark-header-bg': colors.grey.dark[2],

        'light-control-primary': colors.primary.light[12],
        'light-control-primary-hover': colors.primary.light[11],
        'light-control-active': colors.primary.light[6],
        'light-control-secondary': colors.grey.light[2],
        'light-control-secondary-hover': colors.grey.light[3],
        'dark-control-primary': colors.primary.dark[9],
        'dark-control-primary-hover': colors.primary.dark[8],
        'dark-control-active': colors.primary.dark[9],
        'dark-control-secondary': colors.grey.dark[6],
        'dark-control-secondary-hover': colors.grey.dark[7],

        'light-alert-success': colors.green.light[4],
        'light-alert-warning': colors.yellow.light[4],
        'light-alert-critical': colors.red.light[4],
        'dark-alert-success': colors.green.dark[6],
        'dark-alert-warning': colors.yellow.dark[8],
        'dark-alert-critical': colors.red.dark[5],
      },
    },
  },
  variants: {
    backgroundColor: ['responsive', 'hover', 'focus', 'last', 'checked'],
    borderRadius: ['responsive', 'hover', 'focus'],
    padding: ['responsive', 'hover', 'focus', 'last', 'checked'],
  },
  plugins: [],
  corePlugins: {
    outline: true,
  },
  darkMode: 'class',
};
