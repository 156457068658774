import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { SolanaGuideInstructionQuery, SolanaGuideInstructionQueryVariables } from '../types';

const SOLANA_GUIDE_INSTRUCTION = gql`
  query SolanaGuideInstruction($identifier: String!) {
    solanaGuideInstruction(identifier: $identifier) {
      id
      identifier
      treatmentId
      treatment {
        id
        name
        description
        reconOrder
      }
      notes
      publicName
    }
  }
`;

export function useGetSolanaGuideInstruction(
  options?: QueryHookOptions<SolanaGuideInstructionQuery, SolanaGuideInstructionQueryVariables>,
) {
  return useQuery(SOLANA_GUIDE_INSTRUCTION, options);
}
