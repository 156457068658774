import { makeVar } from '@apollo/client';
import { TxnSortOptions } from '../../graphql/types';
import { buildVars } from '../FilteredTable';
import { defaultSortState, getDefaultHiddenColumns, pageSize } from './defaults';
import { FiltersState, IdType } from './types';

export const vars = {
  ...buildVars<TxnSortOptions, FiltersState, IdType>({
    defaultSortState,
    defaultHiddenColumns: getDefaultHiddenColumns(),
    pageSize,
  }),
  txnIdsLinkedToSpecIdVar: makeVar<IdType[]>([]),
};
