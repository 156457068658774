import cx from 'classnames';
import React, { useCallback, useContext } from 'react';
import { UserContext } from '../../../contexts';
import { EnumTaxMethod } from '../../../graphql/types';
import { useTheme } from '../../../theme/useTheme';
import NumberFormat from '../../NumberFormat';
import Spinner from '../../Spinner';
import TextLink from '../../TextLink';
import { SettingsType } from '../Settings';
import { TaxLiabilityTooltip } from '../TooltipContent';
import { ReportValuesType } from '../types';

interface ReportProps {
  /** currency to display values in */
  baseCurrency?: string;
  /** selected method */
  method: EnumTaxMethod;
  /** reportValues to display */
  reportValues?: ReportValuesType;
  /** details related to the report */
  settings: SettingsType;
  /* Status to display above the report */
  toast: React.ReactNode;
  /** true if the data is loading */
  loading: boolean;
}

function Report({ baseCurrency, method, reportValues, settings, toast, loading }: ReportProps) {
  const { user } = useContext(UserContext);
  const { theme } = useTheme();

  const secretModeClass = theme === 'light' ? 'text-blur-black' : 'text-blur-white';

  const formatValue = useCallback(
    (key: keyof Omit<ReportValuesType, '__typename'>) => {
      const value = reportValues?.[key];
      if (loading && value === undefined) {
        return <Spinner size="sm" />;
      }
      return (
        <div className="relative">
          <NumberFormat value={value} currency={baseCurrency || 'USD'} commas={true} />
          {loading && <Spinner size="sm" className="absolute top-[8px] right-[-12px]" />}
        </div>
      );
    },
    [baseCurrency, loading, reportValues],
  );

  return (
    <div className="px-8 pt-8">
      {toast}

      <div className="grid grid-cols-4 grid-rows-6 gap-2 pb-8 text-xl">
        <div className="col-span-3 font-medium">
          Estimated Tax Liability
          <TaxLiabilityTooltip shortTermRate={settings.shortTerm} longTermRate={settings.longTerm} />
        </div>
        <div className={cx('text-right col-span-1 font-medium', user?.secretMode && secretModeClass)}>
          {formatValue('taxLiability')}
        </div>
        <div className="col-span-3">Capital Gains/P&L</div>
        <div className={cx('text-right col-span-1', user?.secretMode && secretModeClass)}>
          {formatValue('gainLoss')}
        </div>
        {method === 'AVERAGE_COST' ? null : (
          <>
            <div className="col-span-3">Short Term Gains</div>
            <div className={cx('text-right col-span-1', user?.secretMode && secretModeClass)}>
              {formatValue('shortTermGainLoss')}
            </div>
            <div className="col-span-3">Long Term Gains</div>
            <div className={cx('text-right col-span-1', user?.secretMode && secretModeClass)}>
              {formatValue('longTermGainLoss')}
            </div>
          </>
        )}
        <div className="col-span-3 block lg:hidden">Income</div>
        <div className="col-span-3 hidden lg:block">Mining, Staking, & Farming Income</div>
        <div className={cx('text-right col-span-1', user?.secretMode && secretModeClass)}>
          {formatValue('income')}
        </div>

        <div className="col-span-4">
          <TextLink className="text-base" to="/start">
            Edit Tax and Filing Details
          </TextLink>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Report);
