import { useReactiveVar } from '@apollo/client';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import Button from '../Button';
import { actions } from './actions';
import { vars } from './vars';

function AllTransactionsHeader() {
  const { filtersVar } = vars;
  const filtersState = useReactiveVar(filtersVar);
  const hideClearFiltersButton = useMemo(
    () => isEmpty(JSON.parse(filtersState.filterQuery || '{}')),
    [filtersState],
  );

  return (
    <div className="flex flex-wrap items-center mb-6 gap-4">
      <span className="text-4xl font-light "> All Transactions</span>
      <div>
        <Button
          variant="secondary"
          onClick={() => actions.resetFilters()}
          className={classNames(`relative top-[3.5px] text-sm px-2 p-1`, {
            ['hidden']: hideClearFiltersButton,
          })}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
}

export default React.memo(AllTransactionsHeader);
