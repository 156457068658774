import React, { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Button from '../Button';
import Card from '../Card';
import Text from '../Text';

interface BlankslateProps {
  title: string;
  subtitle?: ReactNode;
  cta?: string;
  linkTo?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  card?: boolean;
  extraNode?: ReactNode;
}

const Div = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div {...props} />
);

const getLink = (to: string) =>
  function LinkWrapper({ children }: { children: ReactNode }) {
    return <Link {...{ to, children }} />;
  };

function Blankslate({
  title,
  subtitle,
  cta,
  onClick,
  linkTo,
  loading,
  disabled,
  card = true,
  extraNode,
}: BlankslateProps) {
  const Wrapper = card ? Card : Div;
  const ContentWrapper = useMemo(() => (linkTo ? getLink(linkTo) : React.Fragment), [linkTo]);

  return (
    <Wrapper className="h-96 text-center flex items-center justify-center">
      <span>
        <h1 className="text-3xl font-light mb-2 mt-0">{title}</h1>
        <Text as="p" variant="muted" className="mb-8 max-w-2xl">
          {subtitle}
        </Text>
        {cta && (
          <Button {...{ onClick, loading, disabled }}>
            <ContentWrapper>{cta}</ContentWrapper>
          </Button>
        )}
        {extraNode && <>{extraNode}</>}
      </span>
    </Wrapper>
  );
}

export default React.memo(Blankslate);
