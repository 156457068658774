import React from 'react';
import cx from 'classnames';
import Spinner, { SpinnerProps } from './Spinner';

interface Props {
  className?: string;
  color?: SpinnerProps['color'];
  pastDelay: boolean;
  size?: SpinnerProps['size'];
}

function LoadingComponent({ className, color, size = 'lg' }: Props) {
  return (
    <div className={cx('flex flex-grow w-full items-center justify-center', className)}>
      <Spinner color={color} size={size} />
    </div>
  );
}

export default React.memo(LoadingComponent);
