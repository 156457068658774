import cx from 'classnames';
import React, { MouseEvent, useCallback } from 'react';
import { showSuccessFlash } from '../Flash';
import Icon from '../Icon';

interface Props {
  children: React.ReactNode;
  value: string;
  disabled?: boolean;
  className?: string;
}

export default function Copyable({ children, value, disabled, className }: Props) {
  const copyToClipboard = useCallback(
    (evt: MouseEvent) => {
      evt.stopPropagation();
      navigator.clipboard.writeText(value);

      showSuccessFlash('Copied to clipboard');
    },
    [value],
  );

  return (
    <span className={className}>
      {children}
      <span className={cx('ml-1 row_visible_if_hovered', disabled && 'hidden', 'inline')}>
        <Icon
          type="copy"
          className="cursor-pointer inline-block px-1"
          onClick={copyToClipboard}
          title="Copy address to clipboard"
        />
      </span>
    </span>
  );
}
