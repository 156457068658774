import { QueryResult } from '@apollo/client';
import { constant } from 'lodash';
import { createContext } from 'react';
import { SpecIdTxnQuery, SpecIdTxnQueryVariables, TxnCountQuery, TxnsQuery } from '../../../graphql/types';
import { TxnWithOptionalSpecIdQuantities } from './types';

type Counts = TxnCountQuery['userTxnCount'];
type Txn = TxnsQuery['txns']['edges'][0];

export interface AllTransactionsContextType {
  totalPages?: number;
  visibleCount?: number;
  counts?: Counts;
  txns?: TxnWithOptionalSpecIdQuantities[];
  tableData?: TxnsQuery['txns'];
  refetch?: () => void;
  onCreateMovement?: (txns: Txn[]) => Promise<void>;
  onDelete?: (txnIds: string[], triggeredByActionIcon?: boolean) => Promise<void>;
  onEdit?: (txns: Txn[]) => void;
  onReview?: (txns: Txn[], reviewed: boolean) => Promise<void>;
  onMarkSpam?: (txns: Txn[], isSpam: boolean) => Promise<void>;
  onEditCell?: (editedTxnId: string, column: string, value: string) => Promise<void>;
  onDuplicate?: (ids: string[]) => void;
  loading: boolean;
  countsLoading: boolean;
  txnsBeingProcessed?: string[];
  onMerge?: (txns: Txn[]) => Promise<void>;
  selectedTxns: TxnWithOptionalSpecIdQuantities[];
  specIdTxnResponse?: QueryResult<SpecIdTxnQuery, SpecIdTxnQueryVariables>;
  specIdSellId?: string | null;
  checkSpecIdDelinkRequirement: (txnId: string) => Promise<void>;
  credentialsBeingReconciled: number[];
}

export const AllTransactionsContext = createContext<AllTransactionsContextType>({
  selectedTxns: [],
  loading: false,
  countsLoading: false,
  specIdSellId: null,
  checkSpecIdDelinkRequirement: constant(Promise.resolve()),
  credentialsBeingReconciled: [],
});
