import { lineItemsTableState } from './types';
import {
  expandedIdVar,
  filterQueryVar,
  initialLoadingVar,
  initialOptionsSetVar,
  initialQuerySetVar,
  paginationVar,
  sortVar,
} from './vars';

export const lineItemsTableCache = {
  lineItemsTable: {
    read: (): lineItemsTableState => ({
      sort: sortVar(),
      pagination: paginationVar(),
      filterQuery: filterQueryVar(),
      initialLoading: initialLoadingVar(),
      initialOptionsSet: initialOptionsSetVar(),
      initialQuerySet: initialQuerySetVar(),
      expandedId: expandedIdVar(),
    }),
  },
};
