import Auth from '../../lib/Auth';

export function getCurrentUserRole(roles: string[]) {
  const isHijacking = Boolean(Auth.getHijackedUserId());

  // return highest permission role
  if (roles.includes('tokentax-admin')) return isHijacking ? 'user' : 'tokentax-admin';
  if (roles.includes('org-owner')) return !Auth.tokenIsForAdmin() && isHijacking ? 'user' : 'org-owner';
  if (roles.includes('org-admin')) return !Auth.tokenIsForAdmin() && isHijacking ? 'user' : 'org-admin';
  if (roles.includes('org-user')) return 'org-user';
  return 'user';
}
