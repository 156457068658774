import { once } from 'lodash';
import React, { CSSProperties, createContext, useContext } from 'react';
import { ExpandedRowProps } from './Body/types';
import { ControlledState } from './ControlledTable/types';
import { HandlePageChange, HandleSort, PaginationInstance, TableInstance, TableType } from './types';

/**
 * TableContext is the data store of the Table component
 * All children components that make up the table will rely on this store
 * Handlers and controlledState are optional, but will cause errors if not present for ControlledTable
 */
export type TableContext<T extends Record<string, unknown>> = {
  instance: TableInstance<T>;
  paginationInstance: PaginationInstance<T>;
  handlers?: {
    handleSort?: HandleSort<T>;
    handlePageChange?: HandlePageChange;
  };
  controlledState?: ControlledState;
  baseCurrency?: string;
  tableType: TableType;
  expandedRowIds?: number[] | string[];
  windowedScrollBarPadding?: number | undefined;
  setWindowedScrollBarPadding: (padding: number) => void;
  ExpandedRowComponent?: React.FC<ExpandedRowProps<Record<string, unknown>>>;
  gridStyle?: CSSProperties;
  rowClassName?: string;
  headerClassName?: string;
  disabledIds?: string[];
};

/**
 * creates the tableContext object used for a table
 * uses `once` so that we can use generics
 */
export const createTableContext = once(() => createContext({}));

/**
 * hook for using table context within the children components of a table
 */
export const useTableContext = <T extends Record<string, unknown>>(): Partial<TableContext<T>> => {
  const context = useContext(createTableContext());
  if (!context) {
    throw new Error(
      `Table children components cannot be rendered outside a ControlledTable or StaticTable component`,
    );
  }
  return context;
};
