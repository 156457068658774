import { useReactiveVar } from '@apollo/client';
import classNames from 'classnames';
import { chain } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { Txn } from '../../../graphql/types';
import { useTableContext } from '../../Table/context';
import { AllTransactionsContext } from '../Provider/context';
import { actions } from '../actions';
import { vars } from '../vars';

const buttonClasses = 'py-1 px-2 hover:bg-light-hover dark:hover:bg-dark-hover text-left';

const hasAnyValueSet = (filterSet: Record<string, unknown>) =>
  chain(filterSet).values().some(Boolean).value();

function SelectionMenu() {
  const { filtersVar } = vars;
  const { instance } = useTableContext<Txn>();
  const { totalPages } = useContext(AllTransactionsContext);
  const allTxns = useMemo(() => instance?.data ?? [], [instance?.data]);
  const { filterQuery } = useReactiveVar(filtersVar);
  const hasAnyFilterApplied = hasAnyValueSet(JSON.parse(filterQuery ?? '{}'));
  const resultsSpanMoreThanOnePage = Boolean(totalPages && totalPages > 1);

  return (
    <div className="font-normal text-sm flex flex-col">
      <button
        className={buttonClasses}
        onClick={useCallback(() => {
          actions.selectMany([], null);
        }, [])}
      >
        None
      </button>
      <button
        className={buttonClasses}
        onClick={useCallback(() => {
          actions.selectMany(allTxns, 'Page');
        }, [allTxns])}
      >
        This page
      </button>
      <button
        className={classNames(
          buttonClasses,
          !(hasAnyFilterApplied && resultsSpanMoreThanOnePage) && 'hidden',
        )}
        onClick={useCallback(() => {
          actions.selectMany(allTxns, 'FilterSet');
        }, [allTxns])}
      >
        Everything for this filter
      </button>
    </div>
  );
}

export default React.memo(SelectionMenu);
